import { getCookieByName } from '@lego/b2b-unicorn-ui-utils';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateStateCookie = (cookieDomain: string, cookieName: string, stateProperty: any) => {
  Cookies.set(
    cookieName,
    JSON.stringify({
      ...getCookieByName(cookieName),
      ...stateProperty,
    }),
    {
      domain: cookieDomain,
    }
  );
};

export const makeStateWithCookieHook = (cookieDomain: string) => {
  return <T, K extends keyof T>(
    cookieName: string,
    cookieProperty: K
  ): [T[K] | undefined, React.Dispatch<React.SetStateAction<T[K] | undefined>>] => {
    const cookie = getCookieByName<T>(cookieName);
    let defaultValue;
    if (cookie && cookie[cookieProperty]) {
      defaultValue = cookie[cookieProperty];
    }

    const [value, setValue] = useState<T[K] | undefined>(defaultValue);
    useEffect(() => {
      updateStateCookie(cookieDomain, cookieName, {
        [cookieProperty]: value,
      });
    }, [cookieName, cookieProperty, value]);

    return [value, setValue];
  };
};
