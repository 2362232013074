import { gql } from '@apollo/client';

export const UPCOMING_DELIVERIES_LIVE_SEARCH_FIELD = gql`
  query UpcomingDeliveriesLiveSearchField(
    $customerId: Int!
    $filter: UpcomingDeliverySearchFilter
  ) {
    upcomingDeliveries(customerId: $customerId, filter: $filter) {
      totalCount
      upcomingDeliveries {
        itemNumber
        itemName
        orderNumber
        orderName
      }
    }
  }
`;
