import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartReferenceCartType } from '@lego/b2b-unicorn-data-access-layer';
import { LinkWithArrow } from '@lego/b2b-unicorn-shared/components/LinkWithArrow';
import { designToken, Heading, Spacer } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';
import { useHistory } from 'react-router-dom';

import rocket from '../public/rocket.svg';
import { OrderInlineUsersnapWidget } from './OrderInlineUsersnapWidget';

const bodyStyle = css({
  minHeight: 'calc(100vh - 70px)',
  width: '100vw',
  margin: 0,
  paddingRight: baseSpacing * 2,
  paddingLeft: baseSpacing * 2,
  backgroundColor: designToken.background.offWhite,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  backgroundImage: `url(${rocket})`,
  backgroundPosition: `bottom right ${baseSpacing * 2}px`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 30vh',
  '> h2 button': {
    display: 'inline',
  },
  [media.small]: {
    height: 'calc(100vh - 100px)',
    backgroundPosition: `bottom right ${baseSpacing * 3}px`,
    backgroundSize: 'auto 50vh',
  },
  [media.medium]: {
    backgroundSize: 'auto clamp(60vh, 60vh, 730px)',
  },
  [media.large]: {
    backgroundPosition: `bottom right calc(((100vw - ${maxDesktopWidth}px)/2))`,
  },
});

type OrderConfirmationProps = {
  orderNumber?: number;
  orderType?: CartReferenceCartType;
  children: React.ReactNode;
};
const OrderConfirmation = ({ orderNumber, orderType, children }: OrderConfirmationProps) => {
  const { order, order_confirmation_header, order_confirmation_subheader } = useLabels();
  const history = useHistory();

  return (
    <div css={bodyStyle}>
      <Spacer multiplier={15} />
      <Heading
        level={1}
        noMargin
      >
        {order_confirmation_header}
      </Heading>
      <Spacer multiplier={3} />
      <Heading
        level={2}
        noMargin
        noPadding
      >
        {order_confirmation_subheader}{' '}
        <LinkWithArrow onClick={() => history.push(`/manage/order/${orderNumber}`)}>
          {order} #{orderNumber}
        </LinkWithArrow>
      </Heading>
      <Spacer multiplier={6} />
      {orderNumber && orderType && (
        <OrderInlineUsersnapWidget
          orderNumber={orderNumber}
          orderType={orderType}
        />
      )}
      {children}
    </div>
  );
};

export default OrderConfirmation;
