import { css } from '@emotion/react';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';

export const inputContainerStyle = css({
  position: 'relative',
  marginBottom: baseSpacing * 3,
  width: '100%',
  fontSize: font.size.small,
  fontWeight: font.weight.normal,
  [media.medium]: {
    marginBottom: 0,
  },
});
