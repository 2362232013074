import { parseDateStringToLocalDate } from './parseDateStringToLocalDate';

export const formatDate = (
  locale: string,
  date: string | Date,
  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }
) => {
  if (date instanceof Date) {
    return new Intl.DateTimeFormat(locale, options).format(date);
  }

  const { year, month, day } = parseDateStringToLocalDate(date);

  return new Intl.DateTimeFormat(locale, options).format(new Date(year, month - 1, day));
};
