import { NetworkStatus } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { ObservableResult, PayloadArguments, QueryObservable } from '../../context/ContextAbstract';

export const useDataAccessLayerQuery = <
  TQuery extends object,
  TFetchPayload = PayloadArguments<unknown>,
>(
  dataAccessLayerObservable: QueryObservable<TQuery, TFetchPayload>
) => {
  const [refetch, fetchMore, apolloObservable] = dataAccessLayerObservable;

  const [result, setResult] = useState<ObservableResult<TQuery>>(() => {
    return {
      initialLoading: false,
      loading: false,
      data: undefined,
      error: null,
      status: NetworkStatus.ready,
    };
  });

  useEffect(() => {
    const subscription = apolloObservable.subscribe({
      next: (value) => {
        setResult(value);
      },
    });

    return () => {
      setTimeout(() => subscription.unsubscribe());
    };
  }, [apolloObservable, refetch]);

  return useMemo(() => {
    return {
      ...result,
      refetch,
      fetchMore,
    };
  }, [fetchMore, refetch, result]);
};
