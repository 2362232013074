import { css, keyframes } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { colors } from '@lego/b2b-unicorn-ui-constants';

const pulse = keyframes`
  100% { left: 100%; }
`;

export const skeletonGradientAnimation = css({
  position: 'relative',
  overflow: 'hidden',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    width: '100%',
    height: '100%',
    top: 0,
    left: '-100%',
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${colors.klik.slate10} 50%, ${designToken.transparent.default} 100%)`,
    backgroundRepeat: 'no-repeat',
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  },
});
