import { css } from '@emotion/css';
import type { CSSInterpolation } from '@emotion/serialize';

import { baseSpacing, colors, designToken } from '../../ui/theme';

export const baseCellCss: CSSInterpolation = {
  padding: baseSpacing,
  minHeight: baseSpacing * 2.5,
  height: baseSpacing * 2.5,
  maxHeight: baseSpacing * 2.5,
  fontVariantNumeric: 'tabular-nums',
  fontFamily: 'Cera Pro',
  width: 'initial',
  minWidth: 'initial',
  textOverflow: 'ellipsis',

  color: designToken.text.default,
  border: `2px solid ${colors.klik.slate100}`,
  boxSizing: 'unset',
};

export const previewCellCss: CSSInterpolation = {
  ...baseCellCss,
  color: colors.klik.slate500,
  backgroundColor: colors.klik.slate50,
  border: `2px solid ${colors.klik.slate100}`,
  borderLeftWidth: 0,
  borderRightWidth: 0,

  // Name Column
  '&:nth-child(1)': {
    textAlign: 'left',
    borderLeft: `2px solid ${colors.klik.slate100}`,
  },
  // Theme Column
  '&:nth-child(2)': {
    textAlign: 'left',
  },
  // Availability Column
  '&:nth-child(3)': {
    textAlign: 'center',
    padding: baseSpacing / 4,
  },
  // Total pieces Column
  '&:nth-child(4)': {
    textAlign: 'center',
  },
  // PPP Column
  '&:nth-child(5)': {
    textAlign: 'right',
  },
  // Your Price Column
  '&:nth-child(6)': {
    textAlign: 'right',

    '> span': {
      display: 'inline-block',
      maxWidth: baseSpacing * 16,
    },
  },

  '&:last-of-type': {
    borderRight: `2px solid ${colors.klik.slate100}`,
  },

  // Disable span margins in all cells
  '> span': {
    margin: 0,
  },
};

export const errorAvailabilityCellCss: CSSInterpolation = {
  backgroundColor: `${designToken.error.subdued} !important`,
};

export const errorEditCellCss: CSSInterpolation = {
  backgroundColor: `${designToken.error.muted} !important`,
};

const bulkQuickAddReset: CSSInterpolation = {
  '*:not(.Spreadsheet__active-cell)': {
    boxSizing: 'unset',
    lineHeight: 1,
  },
};

// Global styles
export const bulkAddEditableSpreadsheetMainClassName = css({
  ...bulkQuickAddReset,
});

const bulkAddEditableSpreadsheetDataBaseCssSelector = `.${bulkAddEditableSpreadsheetMainClassName} .Spreadsheet__cell`;
export const bulkAddEditableSpreadsheetDataBaseCss: CSSInterpolation = {
  [bulkAddEditableSpreadsheetDataBaseCssSelector]: {
    ...baseCellCss,
    border: `2px solid ${colors.klik.slate100}`,
    '&:first-of-type': {
      border: 0,
      minWidth: 36,
    },
  },
};

const bulkAddEditableSpreadsheetDataEditorCssSelector = `.${bulkAddEditableSpreadsheetMainClassName} .Spreadsheet__data-editor`;
export const bulkAddEditableSpreadsheetDataEditorCss: CSSInterpolation = {
  [bulkAddEditableSpreadsheetDataEditorCssSelector]: {
    input: {
      ...baseCellCss,
      width: '100%',
      boxSizing: 'border-box',
      maxHeight: '100%',
      height: '100%',
    },
  },
};

const bulkAddEditableSpreadsheetHeaderCellSelector = `.${bulkAddEditableSpreadsheetMainClassName} .Spreadsheet__header`;
export const bulkAddEditableSpreadsheetHeaderCellCss: CSSInterpolation = {
  [bulkAddEditableSpreadsheetHeaderCellSelector]: {
    ...baseCellCss,
    border: 0,
    fontWeight: 700,
    backgroundColor: colors.klik.slate30,
    color: colors.klik.slate900,

    // ItemID Column
    '&:nth-child(2)': {
      width: baseSpacing * 16,
      minWidth: baseSpacing * 16,
      textAlign: 'left',
    },
  },
};

const bulkAddEditableSpreadsheetRemoveFirstColumnHeaderSelector = `.${bulkAddEditableSpreadsheetMainClassName} .Spreadsheet__header:first-of-type`;
export const bulkAddEditableSpreadsheetRemoveFirstColumnHeaderCss: CSSInterpolation = {
  [bulkAddEditableSpreadsheetRemoveFirstColumnHeaderSelector]: {
    border: 0,
    visibility: 'hidden',
  },
};

const bulkAddEditableSpreadsheetProductColumnHeaderSelector = `.${bulkAddEditableSpreadsheetMainClassName} .Spreadsheet__header:nth-child(2)`;
export const bulkAddEditableSpreadsheetProductColumnHeaderCss: CSSInterpolation = {
  [bulkAddEditableSpreadsheetProductColumnHeaderSelector]: {
    '> span:first-of-type': {
      display: 'inline-block',
      transform: 'translateY(-3px)',
    },
    '> span:last-of-type': {
      width: baseSpacing * 2,
      height: baseSpacing * 2,
    },
    svg: {
      width: baseSpacing * 2,
      height: baseSpacing * 2,
      fill: designToken.interactive.default,
      display: 'inline-block',
      marginLeft: baseSpacing / 2,
    },
  },
};

export const bulkQuickAddPreviewClassName = css({
  width: '100%',

  ...bulkQuickAddReset,

  '.Spreadsheet__table': {
    width: '100%',
  },
});

const previewHeaderCellSelector = `.${bulkQuickAddPreviewClassName} .Spreadsheet__header`;
export const previewHeaderCellCss: CSSInterpolation = {
  [previewHeaderCellSelector]: {
    ...baseCellCss,
    border: 0,
    fontWeight: 700,
    backgroundColor: colors.klik.slate30,

    // Name Column
    '&:nth-child(1)': {
      textAlign: 'left',
      minWidth: baseSpacing * 24,
    },
    // Theme Column
    '&:nth-child(2)': {
      textAlign: 'left',
      minWidth: baseSpacing * 20,
    },
    // Availability Column
    '&:nth-child(3)': {
      minWidth: baseSpacing * 26,
    },
    // Total pieces Column
    '&:nth-child(4)': {
      textAlign: 'center',
      width: baseSpacing * 12,
    },
    // PPP Column
    '&:nth-child(5)': {
      textAlign: 'right',
      width: baseSpacing * 16,
    },
    // Your Price Column
    '&:nth-child(6)': {
      textAlign: 'right',
      minWidth: baseSpacing * 16,
    },
  },
};

const previewCellSelector = `.${bulkQuickAddPreviewClassName} .Spreadsheet__cell`;
export const globalPreviewCellCss: CSSInterpolation = {
  [previewCellSelector]: {
    ...previewCellCss,
  },
};

const lastColSelector = `.${bulkQuickAddPreviewClassName} > .Spreadsheet__table > colgroup col:last-of-type`;
export const lastColCss: CSSInterpolation = {
  [lastColSelector]: {
    display: 'none',
  },
};

export const deleteTableClassName = css({
  ...bulkQuickAddReset,

  '.Spreadsheet__cell, .Spreadsheet__header': {
    ...baseCellCss,
    border: 0,
    width: 'initial',
    minWidth: baseSpacing * 4.5,
    padding: 0,
    height: baseSpacing * 4.5,
    maxHeight: baseSpacing * 4.5,
  },

  '.Spreadsheet__cell': {
    border: `2px solid transparent`,
    svg: {
      padding: baseSpacing,
      cursor: 'pointer',
      fill: designToken.interactive.default,
    },
    '> div svg:last-of-type': {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      transition: 'opacity 125ms',
    },

    '&:hover': {
      '> div svg:last-of-type': {
        opacity: 1,
      },
    },
  },

  '.Spreadsheet__header': {
    visibility: 'hidden',
  },
});
