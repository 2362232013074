/*
 * Extracts the element type in an array
 * Eg.
 *  Input: string[]
 *  Output: string
 */
export type ExtractElementType<T> = T extends readonly (infer ElementType)[] ? ElementType : never;
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/*
 * Removes undefined and null from properties
 * Eg.
 *  Input: { name?: string | null, age: number | null }
 *  Output: { name: string, age: number }
 */
export type MarkRequired<P> = {
  [K in keyof P]-?: NonNullable<P[K]>;
};

export function exhaustiveSwitchCheck(input: never | undefined): never {
  throw new Error(`Didn't expect ${input} in this switch statement`);
}

export function isNullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}
