import type { CSSInterpolation } from '@emotion/serialize';
import React from 'react';

type MenuListProps = {
  children: React.ReactNode;
  cssStyle?: CSSInterpolation;
};

export const MenuList: React.FC<MenuListProps> = ({ children, cssStyle }) => {
  return <ul css={cssStyle}>{children}</ul>;
};
