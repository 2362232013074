import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';

export const linkStyle = css({
  color: designToken.interactive.default,
  textDecoration: 'none',
  fontSize: font.size.small,
  padding: 0,
  marginLeft: 0,
  cursor: 'pointer',
  overflowWrap: 'anywhere',
  lineHeight: 1.3,
  '&:hover': {
    color: designToken.interactive.hover,
    outline: 'none',
    textDecorationLine: 'underline',
    textUnderlineOffset: 1,
  },
  '&:visited': {
    color: designToken.interactive.active,
  },
});

export const footerStyle = css({
  zIndex: 1,
  boxSizing: 'border-box',
  width: '100%',
  backgroundColor: tokens.color.core.azur[1300],
  color: colors.white,
  fontWeight: font.weight.medium,
  paddingTop: baseSpacing * 3,
  paddingBottom: baseSpacing * 3,
  paddingRight: baseSpacing * 2,
  paddingLeft: baseSpacing * 2,
});

export const containerStyle = css({
  maxWidth: maxDesktopWidth,
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const contentStyle = css({
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column-reverse',
  justifyContent: 'space-between',
  [media.medium]: {
    flexDirection: 'row',
  },
});

export const legalsStyle = css({
  fontSize: font.size.tiny,
  marginRight: baseSpacing * 7,
  '> p a': {
    color: colors.klik.lightBlue100,
    fontSize: font.size.tiny,
    marginLeft: 5,
    overflowWrap: 'normal',
  },
  '> p': {
    marginBottom: baseSpacing * 2,
  },
});

export const noDefaultListStyle = css({
  listStyleType: 'none',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  paddingInlineStart: 0,
});

export const legalLinkListStyle = css(noDefaultListStyle, {
  marginBottom: -baseSpacing,
  display: 'flex',
  flexWrap: 'wrap',
  '> *:not(first-of-type)': {
    paddingRight: 14,
    paddingBottom: baseSpacing / 2,
    paddingLeft: 0,
    paddingTop: baseSpacing / 2,
    marginRight: baseSpacing * 2,
    marginBottom: baseSpacing,
  },
  '> *:not(:last-of-type)': {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: colors.white,
  },
});

export const listStyle = css(noDefaultListStyle, {
  maxWidth: 660,
  columns: 1,
  columnRuleWidth: 160,
  columnGap: baseSpacing * 3,
  margin: 0,

  '> li': {
    display: 'block',
    pageBreakInside: 'avoid',
    breakInside: 'avoid',
    '> *': {
      paddingBottom: baseSpacing / 2,
      paddingTop: baseSpacing / 2,
    },
  },

  [media.medium]: {
    columns: 2,
    maxWidth: 660,
  },
});
