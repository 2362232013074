import { gql } from '@apollo/client';

export const CART = gql`
  query cart(
    $customerId: Int!
    $cartReference: CartReferenceInput!
    $removeObsoleteItems: Boolean = false
  ) {
    getCart(
      customerId: $customerId
      cartReference: $cartReference
      removeObsoleteItems: $removeObsoleteItems
    ) {
      id
      ...cartKey
      deliveryDates {
        earliestDate
        latestDate
      }
      items {
        ...cartItemWithOpenOrderInfo
      }
      removedItems {
        ...removedCartItem
      }
      minimumOrderValue {
        amount
        currency
      }
    }
  }
`;
