import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { IMenuLink } from './MenuItem';

const menuTextStyle = css({
  color: colors.klik.slate10,
  textDecoration: 'none',
  padding: baseSpacing,
  paddingLeft: baseSpacing * 3,
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  fontWeight: font.weight.medium,
  [media.large]: {
    padding: baseSpacing,
  },
  '&:hover, :focus': {
    backgroundColor: designToken.primary.hover,
    outline: 'none',
  },
});

const activeMenuTextStyle = css(menuTextStyle, {
  position: 'relative',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  ':after': {
    content: '""',
    position: 'absolute',
    height: '100%',
    width: 4,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: designToken.background.offWhite,
    [media.large]: {
      top: 'auto',
      bottom: -1,
      right: 0,
      width: '100%',
      height: 4,
    },
  },
});

const MenuTextLink: FunctionComponent<IMenuLink> = ({ children, isActive, path, dataE2e }) => {
  return (
    <Link
      to={path}
      data-e2e={dataE2e}
      css={isActive ? activeMenuTextStyle : menuTextStyle}
      tabIndex={0}
    >
      {children}
    </Link>
  );
};

export default MenuTextLink;
