import { gql } from '@apollo/client';

export const UPDATE_PROMOTION_WITH_MULTIPLE_ITEMS = gql`
  mutation updatePromotionWithMultipleItems(
    $customerId: Int!
    $promotionId: Int!
    $items: [UpdateCartItemInput!]!
  ) {
    updateCartWithMultipleItems(
      customerId: $customerId
      items: $items
      cartReference: { cartType: PROMOTION, promotion: { promotionId: $promotionId } }
    ) {
      items {
        ...minimumCartItem
        product {
          materialId
          name
          theme
          ean
          piecesPerCasePack
          price {
            currency
            netInvoicedPrice
            estimatedNetInvoicedPrice
            grossPrice
            listPrice
          }
          ... on Box {
            itemNumber
          }
        }
      }
      ...cartKey
    }
  }
`;
