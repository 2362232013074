import { css } from '@emotion/react';
import type { ObjectPathLeavesOnly } from '@lego/b2b-unicorn-shared/utils';
import React, { useMemo } from 'react';

import { baseSpacing, designToken, font } from '../theme';

type MediaInput = {
  [media: string]: number;
};

type HeadingProps = {
  children: React.ReactNode;
  designToken?: ObjectPathLeavesOnly<typeof designToken>;
  level?: 1 | 2 | 3;
  fontSize?: number;
  fontSizeMedia?: MediaInput;
  noMargin?: boolean;
  noPadding?: boolean;
};

const headingStyle = {
  [1]: {
    fontSize: font.size.large,
    fontWeight: font.weight.bold,
    marginBottom: baseSpacing * 2,
  },
  [2]: {
    fontSize: font.size.medium,
    fontWeight: font.weight.bold,
    lineHeight: 2,
    paddingTop: baseSpacing,
    paddingBottom: baseSpacing,
  },
  [3]: {
    fontSize: font.size.medium,
  },
};

export const Heading: React.FC<HeadingProps> = ({
  children,
  level = 1,
  designToken = 'text.default',
  fontSize,
  fontSizeMedia,
  noMargin,
  noPadding,
}) => {
  const Tag: string = `h${level}` as const;

  const customStyle = useMemo(() => {
    const myDesignToken = designToken
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - type inferring of the split path is not working
        (designToken.split('.').reduce((o, k) => (o || {})[k], designToken) as string)
      : undefined;

    const cssObject: Parameters<typeof css>[0] = {
      fontSize,
      color: myDesignToken,
    };
    for (const media in fontSizeMedia) {
      Object.assign(cssObject, {
        [media]: {
          fontSize: fontSizeMedia[media],
        },
      });
    }

    if (noMargin) {
      Object.assign(cssObject, {
        margin: 0,
      });
    }

    if (noPadding) {
      Object.assign(cssObject, {
        padding: 0,
      });
    }

    return css(cssObject);
  }, [designToken, fontSize, fontSizeMedia, noMargin, noPadding]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Tag css={[headingStyle[level], customStyle]}>{children}</Tag>;
};
