import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, mediaSizes } from '@lego/b2b-unicorn-ui-constants';
import { Fragment } from 'react';

import { TILE_WIDTH } from '../../../../constants';
import { fourColMedia, threeColMedia, twoColMedia } from '../../../../constants';
import { SkeletonShopTile } from './';

const skeletonListStyle = css({
  maxWidth: mediaSizes.large,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: baseSpacing * 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: baseSpacing,
  paddingRight: baseSpacing,
  display: 'grid',
  gridGap: baseSpacing * 2,
  justifyContent: 'center',
  gridTemplateColumns: `repeat(auto-fill, ${TILE_WIDTH}px)`,
  '> article:not(:first-of-type)': {
    display: 'none',
  },
  [`@media (min-width: ${twoColMedia}px)`]: {
    '> article:nth-of-type(2)': {
      display: 'flex',
    },
  },
  [`@media (min-width: ${threeColMedia}px)`]: {
    '> article:nth-of-type(3)': {
      display: 'flex',
    },
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    '> article:last-of-type': {
      display: 'flex',
    },
  },
});

const fadingSkeletonListStyle = css(skeletonListStyle, {
  position: 'relative',
  '&:after': {
    zIndex: 10,
    position: 'absolute',
    display: 'block',
    content: '""',
    width: '100%',
    height: '102%',
    background: `linear-gradient(${designToken.transparent.default} 0%, ${designToken.transparent.default} 20%, ${colors.klik.slate30} 60%)`,
  },
});

export const SkeletonProductList = () => {
  return (
    <Fragment>
      <div css={skeletonListStyle}>
        {[...Array(4)].map((_, i) => {
          return <SkeletonShopTile key={i} />;
        })}
      </div>
      <div css={fadingSkeletonListStyle}>
        {[...Array(4)].map((_, i) => {
          return <SkeletonShopTile key={i} />;
        })}
      </div>
    </Fragment>
  );
};
