import { css } from '@emotion/react';
import { colors, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useEffect, useRef, useState } from 'react';

import { skeletonGradientAnimation } from '../../../common/styles';

const cardsSkeletonStyle = css([
  {
    height: 40,
    borderRadius: 4,
    backgroundColor: colors.klik.slate100,
    marginTop: 24,
    [media.medium]: {
      marginTop: 30,
    },
    lineHeight: '40px',
    paddingLeft: 10,
  },
  skeletonGradientAnimation,
]);

interface CardPaymentSkeletonProps {
  takesLongerText: string;
}
const CardPaymentSkeleton: React.FC<CardPaymentSkeletonProps> = (props) => {
  const [takesLongerText, setTakesLongerText] = useState('');
  const timerRef = useRef<number>();

  useEffect(() => {
    timerRef.current = window.setTimeout(() => {
      setTakesLongerText(props.takesLongerText);
    }, 5000);

    return () => {
      window.clearTimeout(timerRef.current);
    };
  }, []);

  return <div css={cardsSkeletonStyle}>{takesLongerText}</div>;
};

export default CardPaymentSkeleton;
