import { NetworkStatus } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { MutationObservable, ObservableResult } from '../../context/ContextAbstract';

export const useDataAccessLayerMutation = <TMutation extends object, TMutationVariables = unknown>(
  dataAccessLayerObservable: MutationObservable<TMutation, TMutationVariables>
) => {
  const [mutate, reset, apolloObservable] = dataAccessLayerObservable;

  const [result, setResult] = useState<ObservableResult<TMutation>>({
    initialLoading: false,
    loading: false,
    data: undefined,
    error: null,
    status: NetworkStatus.ready,
  });

  useEffect(() => {
    const subscription = apolloObservable.subscribe((value) => {
      setResult(value);
    });

    return () => {
      setTimeout(() => subscription.unsubscribe());
    };
  }, [apolloObservable, mutate]);

  return useMemo(() => {
    return {
      ...result,
      reset,
      mutate,
    };
  }, [mutate, reset, result]);
};
