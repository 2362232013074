import { css } from '@emotion/react';
import { UpdateCartInMultipleWindowsResult } from '@lego/b2b-unicorn-data-access-layer/context/LaunchWindows/LaunchWindowsDataContext';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { ExtractElementType } from '@lego/b2b-unicorn-shared/helpers';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font } from '@lego/b2b-unicorn-ui-constants';
import { useState } from 'react';

import { localizedWindowName } from '../../../../utils/localizedWindowName';
import { SuccessfulBulkCartUpdateSummaryItemRow } from './SuccessfulBulkCartUpdateSummaryItemRow';

const windowIndicatorRowStyle = css({
  borderBottomWidth: 2,
  borderBottomColor: colors.klik.lightBlue30,
  backgroundColor: 'transparent',
});

const buttonStyle = css({
  height: 50,
  width: '100%',
  color: designToken.interactive.default,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  border: 'none',
  padding: 0,
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
  cursor: 'pointer',
  transitionDuration: '100ms',
  transitionProperty: 'all',
  '> span': {
    fontSize: font.size.medium,
    fontWeight: font.weight.bold,
    margin: 0,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  '> svg': {
    fill: designToken.interactive.default,
    height: 20,
    width: 20,
    marginRight: baseSpacing * 2,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    borderColor: designToken.interactive.default,
  },
  '&:focus': {
    outline: 'none',
  },
  '&:hover': {
    color: designToken.interactive.hover,
    '> svg': { fill: designToken.interactive.hover, borderColor: designToken.interactive.hover },
  },
});

interface SuccessfulBulkCartUpdateSummarySubtableProps {
  items: NonNullable<
    ExtractElementType<Awaited<UpdateCartInMultipleWindowsResult>>['data']
  >['updateCartWithMultipleItems']['items'];
  month: number;
  year: number;
}

export const SuccessfulBulkCartUpdateSummarySubtable = ({
  items,
  month,
  year,
}: SuccessfulBulkCartUpdateSummarySubtableProps) => {
  const [expanded, setExpanded] = useState(true);
  const { locale } = useSelectedCustomer();
  const windowName = localizedWindowName(month, year, locale);

  return (
    <>
      <tr css={windowIndicatorRowStyle}>
        <td colSpan={5}>
          <button
            onClick={() => setExpanded(!expanded)}
            css={buttonStyle}
          >
            <Icon type={expanded ? IconType.MINUS : IconType.PLUS} />
            <span>{windowName}</span>
          </button>
        </td>
      </tr>
      {items.map((item) => (
        <SuccessfulBulkCartUpdateSummaryItemRow
          key={item.product.materialId}
          product={item.product}
          quantity={item.quantity}
          showRow={expanded}
        />
      ))}
    </>
  );
};
