import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useOnCustomerChangeRedirect } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { useCallback } from 'react';
import { useParams } from 'react-router';

import ProductDetailsLayout from '../layouts/ProductDetailsLayout';

interface ProductDetailsRouteParams {
  materialId: string;
}

const ProductDetailsRoute = () => {
  useOnCustomerChangeRedirect('/');
  const { materialId } = useParams<ProductDetailsRouteParams>();
  const { endTransaction } = useApmTransaction();

  const handleOnProductLoaded = useCallback(() => {
    endTransaction && endTransaction();
  }, [endTransaction]);

  return (
    <ProductDetailsLayout
      materialId={parseInt(materialId, 10)}
      onProductLoaded={handleOnProductLoaded}
    />
  );
};

export default ProductDetailsRoute;
