import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { ContentSystemFeedback, SystemFeedbackType } from '@lego/b2b-unicorn-shared/ui';
import { GoToTop, LoadMoreButton } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, colors, mediaSizes } from '@lego/b2b-unicorn-ui-constants';
import React, { useCallback } from 'react';

import {
  fourColMedia,
  fourColWidth,
  threeColMedia,
  threeColWidth,
  TILE_WIDTH,
  twoColMedia,
  twoColWidth,
} from '../../constants';
import { ProductListFiltersAndSorting, ProductListTile, SkeletonProductList } from './components';
import { useProductListContext } from './ProductListContext';

const wrapperStyle = css({
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 2,
  width: '100%',
  background: colors.klik.slate30,
});

const productListStyle = css({
  maxWidth: mediaSizes.large,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: baseSpacing * 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: baseSpacing,
  paddingRight: baseSpacing,
  display: 'grid',
  gap: baseSpacing * 2,
  justifyContent: 'center',
  gridTemplateColumns: `repeat(auto-fill, ${TILE_WIDTH}px)`,
});

const productListContainerStyle = (loadMoreVisible: boolean) =>
  css({
    marginTop: baseSpacing * 2,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: loadMoreVisible ? 50 : 0,
  });

const loadButtonMediaAdjustmentStyle = css({
  width: TILE_WIDTH,
  maxWidth: 1420,
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  [`@media (min-width: ${twoColMedia}px)`]: {
    width: twoColWidth,
  },
  [`@media (min-width: ${threeColMedia}px)`]: {
    width: threeColWidth,
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    width: fourColWidth,
  },
});

const ProductList: React.FC = () => {
  const selectedCustomer = useSelectedCustomer();
  const locale = selectedCustomer?.locale;

  const { content_system_feedback_error, content_system_feedback_no_data, button_load_more } =
    useLabels();
  const {
    productsResponse,
    productsError,
    productsLoading,
    productListItems,
    loadMoreVisible,
    materialIdMarker,
    loadMore,
  } = useProductListContext();

  const scrollRestorationRef = useCallback((node: null | HTMLDivElement) => {
    if (node !== null) {
      // If the user navigated back from PDP, scroll down to that product
      node.scrollIntoView({ block: 'center' });
    }
  }, []);

  return (
    <>
      <div css={wrapperStyle}>
        <ProductListFiltersAndSorting />
        <div css={productListContainerStyle(loadMoreVisible)}>
          {!productsError && productsLoading && <SkeletonProductList />}
          {productsResponse &&
            !productsLoading &&
            (productListItems.length > 0 ? (
              <div css={productListStyle}>
                {productListItems.map((product) => {
                  return (
                    <ProductListTile
                      key={product.materialId}
                      product={product}
                      locale={locale}
                      onRendered={
                        product.materialId === materialIdMarker ? scrollRestorationRef : undefined
                      }
                    />
                  );
                })}
              </div>
            ) : (
              <ContentSystemFeedback text={content_system_feedback_no_data} />
            ))}
          {loadMoreVisible && (
            <LoadMoreButton
              handleLoadMoreClick={loadMore}
              text={button_load_more}
              customStyle={loadButtonMediaAdjustmentStyle}
            />
          )}
          {productsError && (
            <ContentSystemFeedback
              type={SystemFeedbackType.ERROR}
              text={content_system_feedback_error}
            />
          )}
        </div>
        {productsResponse && <GoToTop loadMoreVisible={loadMoreVisible} />}
      </div>
    </>
  );
};

export default ProductList;
