export enum NotificationType {
  DEFAULT = 'default',
  WARNING = 'warning',
  POSITIVE = 'positive',
  ERROR = 'error',
}

export interface ILink {
  title: string;
  url?: string;
  assetUid?: string;
}
