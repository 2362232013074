import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { WindowType } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';
import { DateTime } from '@lego/b2b-unicorn-shared/components/Date';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  Container,
  designToken,
  Disclaimer,
  GoBackLink,
  Paragraph,
  Section,
  Spacer,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';
import { useHistory } from 'react-router-dom';

const orderDeadlineStyle = css({
  fontWeight: font.weight.bold,
  color: designToken.text.default,
});

const launchDateStyle = (isInactive: boolean) =>
  css({
    minWidth: 300,
    maxWidth: 320,
    height: 230,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 8,
    textAlign: 'center',
    backgroundColor: designToken.background.offWhite,
    '> span': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: baseSpacing,
    },
    '> span:first-of-type': {
      height: 50,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      color: colors.klik.slate10,
      backgroundColor: isInactive ? colors.klik.slate400 : designToken.primary.active,
      fontSize: font.size.medium,
      position: 'relative',
    },
    '> span:last-of-type': {
      height: 170,
      width: '100%',
      fontSize: 48,
      lineHeight: 1.1,
      fontWeight: font.weight.bold,
      color: isInactive ? colors.klik.slate500 : designToken.primary.default,
      textTransform: 'uppercase',
    },
    [media.medium]: {
      marginRight: 100,
      marginLeft: baseSpacing * 2,
    },
  });

interface LaunchWindowHeaderProps {
  launchDate?: string;
  orderDeadlineDate?: string | null;
  windowType?: WindowType;
}

export const LaunchWindowHeader: React.FC<LaunchWindowHeaderProps> = ({
  launchDate,
  orderDeadlineDate,
  windowType = WindowType.Previous,
}) => {
  const {
    launch_window_intro,
    launch_window_inactive_intro,
    launch_window_deadline_text,
    launch_window_confidentiality_disclaimer,
    launch_window_future_disclaimer,
    launch_window,
    launch_window_go_back,
  } = useLabels();
  const history = useHistory();
  const { locale } = useSelectedCustomer();

  return (
    <Container
      padding={{
        paddingTop: baseSpacing * 4,
        paddingBottom: baseSpacing * 4,
        paddingRight: baseSpacing * 2,
        paddingLeft: baseSpacing * 2,
      }}
      flex={{
        alignItems: 'center',
        flexDirection: 'column-reverse',
      }}
      flexMedium={{
        [media.medium]: {
          justifyContent: 'space-between',
          flexDirection: 'row',
        },
      }}
    >
      <Section>
        <GoBackLink
          text={launch_window_go_back}
          onClick={() => history.push('/launch')}
        />
        <Spacer multiplier={2} />
        <Paragraph
          designToken={'text.muted'}
          fontSize={'medium'}
        >
          {windowType === WindowType.Open && launch_window_intro}
          {windowType === WindowType.Previous && launch_window_inactive_intro}
          {windowType === WindowType.Future && launch_window_future_disclaimer}
        </Paragraph>
        {windowType === WindowType.Open && (
          <Paragraph designToken={'text.muted'}>
            {launch_window_deadline_text}{' '}
            <span css={orderDeadlineStyle}>
              <DateTime locale={locale}>{orderDeadlineDate}</DateTime>
            </span>
          </Paragraph>
        )}
        {windowType === WindowType.Open && (
          <Disclaimer text={launch_window_confidentiality_disclaimer} />
        )}
      </Section>
      <div css={launchDateStyle(windowType !== WindowType.Open)}>
        <span>{launch_window}</span>
        <span>{launchDate}</span>
      </div>
    </Container>
  );
};
