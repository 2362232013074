import { css } from '@emotion/react';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { BulkQuickAddTriggerButton } from '@lego/b2b-unicorn-shared/components/BulkQuickAdd';
import { designToken, Spacer } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { media } from '@lego/b2b-unicorn-ui-constants';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import React, { Fragment, useCallback, useRef, useState } from 'react';

import { Banner } from '../../shared/Components/Notifications';
import { ReplenishBulkQuickAddConsumer } from '../components/BulkQuickAdd';
import { Themes } from '../components/ProductList/components';
import ProductList from '../components/ProductList/ProductList';
import { ProductListProvider } from '../components/ProductList/ProductListContext';
import QuickAdd from '../components/QuickAdd/QuickAdd';

const buttonWrapperStyle = css({
  display: 'none',
  marginRight: 'auto',
  marginLeft: 'auto',
  [media.medium]: {
    display: 'block',
  },
});

const bluebarStyle = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: designToken.secondary.default,
  height: 280,
  [media.small]: {
    height: 'auto',
  },
});

const callCallbackIfArrayEmpty = (reduceBy: string, array: string[], callback?: () => void) => {
  const nextArray = array.filter((c) => c !== reduceBy);
  if (nextArray.length === 0) {
    callback && callback();
  }

  return nextArray;
};

const ProductListLayout = () => {
  const { endTransaction } = useApmTransaction();
  const { trackEvent } = useAnalytics();
  const notLoadedData = useRef(['ProductList']);
  const { button_bulk_quick_add } = useLabels();
  const [showBulkQuickAdd, setShowBulkQuickAdd] = useState(false);

  const handleOnProductListLoaded = useCallback(() => {
    notLoadedData.current = callCallbackIfArrayEmpty(
      'ProductList',
      notLoadedData.current,
      endTransaction
    );
  }, [endTransaction]);

  const handleOnBulkQuickAddOpenClick = useCallback(() => {
    trackEvent({
      event: 'openBulkQuickAddReplenishment',
      name: `Open replenishment bulk quick add modal`,
    });
    setShowBulkQuickAdd(true);
  }, [trackEvent]);

  const handleOnBulkQuickAddClose = useCallback(() => {
    setShowBulkQuickAdd(false);
  }, []);

  const handleOnBulkQuickAddHasPreviousSession = useCallback(() => {
    trackEvent({
      event: 'reopenBulkQuickAddReplenishment',
      name: `Open replenishment bulk quick add modal with previous session`,
    });
    setShowBulkQuickAdd(true);
  }, [trackEvent]);

  return (
    <Fragment>
      <TopbarImplementation />
      <Banner />
      <section css={bluebarStyle}>
        <QuickAdd />
        <div css={buttonWrapperStyle}>
          <BulkQuickAddTriggerButton
            handleOnBulkQuickAddOpenClick={handleOnBulkQuickAddOpenClick}
            translations={{ button_bulk_quick_add }}
          />
        </div>
        <Spacer
          direction={'horizontal'}
          multiplier={3}
        />
      </section>
      <ReplenishBulkQuickAddConsumer
        onClose={handleOnBulkQuickAddClose}
        open={showBulkQuickAdd}
        onHasPreviousSession={handleOnBulkQuickAddHasPreviousSession}
      />
      <ProductListProvider onProductsLoaded={handleOnProductListLoaded}>
        <Themes />
        <ProductList />
      </ProductListProvider>
    </Fragment>
  );
};

export default ProductListLayout;
