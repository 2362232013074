import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { BulkQuickAdd } from '@lego/b2b-unicorn-shared/components/BulkQuickAdd';
import {
  FinalRowItem,
  RowStatusReason,
} from '@lego/b2b-unicorn-shared/components/BulkQuickAdd/types';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  Button,
  ButtonType,
  designToken,
  Disclaimer,
  DisclaimerType,
  Icon,
  IconType,
  Modal,
  ModalActions,
  ModalHeader,
  Spinner,
} from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { BulkQuickAddRemovedItems } from './BulkQuickAddRemovedItems';

const bulkQuickAddWrapper = css({
  height: 'calc(100vh - 420px)',
  overflowY: 'scroll',
  paddingTop: 20,
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: -2,
    background: `linear-gradient(to bottom, ${designToken.transparent.default}, ${colors.white})`,
    height: 30,
    width: '100%',
    pointerEvents: 'none',
    zIndex: 10,
    borderBottom: `1px solid ${colors.klik.lightBlue50}`,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: -2,
    background: `linear-gradient(to top, ${designToken.transparent.default}, ${colors.white})`,
    height: 30,
    width: '100%',
    pointerEvents: 'none',
  },
});

const bulkQuickAddSubheaderStyle = css({
  display: 'flex',
  alignItems: 'center',
  color: colors.klik.lightBlue500,
  '> svg': {
    width: 28,
    height: 28,
    fill: colors.klik.lightBlue500,
    marginRight: baseSpacing,
    zIndex: 1,
  },
});

type BaseBulkQuickAddProps = {
  sessionStorageKey: string;
  onHasPreviousSession: () => void;
  open: boolean;
  products: React.ComponentProps<typeof BulkQuickAdd>['products'];
  modalHeaderContent: React.ReactNode;
  isLoading: boolean;
  children: React.ReactNode;
  onItemsChange: (items: FinalRowItem[]) => void;
  submittingError?: boolean;
  addToCartDisabled?: boolean;
  context: React.ComponentProps<typeof BulkQuickAdd>['context'];
};
export const BaseBulkQuickAdd: React.FC<BaseBulkQuickAddProps> = ({
  sessionStorageKey,
  onHasPreviousSession,
  open,
  products,
  modalHeaderContent,
  isLoading: externalIsLoading,
  children,
  submittingError,
  addToCartDisabled,
  onItemsChange,
  context,
}) => {
  const { locale } = useSelectedCustomer();
  const { trackEvent } = useAnalytics();

  // Internal state
  const savedItemsInStorage = useRef(sessionStorage.getItem(sessionStorageKey));
  const [items, setItems] = useState<FinalRowItem[]>([]);
  const initialData = useRef<{ itemId: number; quantity?: number }[]>(
    savedItemsInStorage.current ? JSON.parse(savedItemsInStorage.current) : []
  );
  const [internalIsLoading, setInternalIsLoading] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [internalOpen, setInternalOpen] = useState(open);

  const {
    product,
    product_column_info,
    quantity,
    name,
    theme,
    filter_availability,
    total_pieces,
    price_per_piece,
    your_price,
    available,
    low_stock,
    expected_back_in_stock,
    out_of_stock,
    not_available,
    bulk_add_blocked_continue_disclaimer,
    button_remove_invalid_rows,
    bulk_add_product_not_found,
    not_in_window,
    not_in_promotion,
    bulk_quick_add_modal_subheader,
    snackbar_excel_upload_add_error,
    bulk_quick_add_right_click_tooltip,
  } = useLabels();

  useEffect(() => {
    if (open) {
      setInternalIsLoading(true);
      const sessionStorageData = sessionStorage.getItem(sessionStorageKey);
      initialData.current = sessionStorageData ? JSON.parse(sessionStorageData) : [];
      setTimeout(() => {
        setInternalIsLoading(false);
      }, 50);
    }
    setInternalOpen(open);
  }, [open, sessionStorageKey]);

  useEffect(() => {
    if (initialData.current.length > 0) {
      onHasPreviousSession();
    }
  }, []);

  const handleOnChange = useCallback(
    (items: FinalRowItem[]) => {
      setItems(items);
      onItemsChange(items);
    },
    [onItemsChange]
  );

  const handleOnRawChange = useCallback(
    (items: Array<{ itemId: number; quantity?: number }>) => {
      if (!open) {
        return;
      }
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(items));
      initialData.current = items;
    },
    [open, sessionStorageKey]
  );

  const handleOnRemoveInvalidItemsClick = () => {
    setConfirmModalOpen(true);
  };

  const handleRemoveInvalidItems = () => {
    setConfirmModalOpen(false);
    setInternalIsLoading(true);
    initialData.current = items.reduce<Array<{ itemId: number; quantity: number }>>(
      (result, item) => {
        if (item.rowStatusReason === RowStatusReason.Valid) {
          result.push({
            itemId: item.productLookupValue,
            quantity: item.quantity,
          });
        }

        return result;
      },
      []
    );
    setTimeout(() => {
      setInternalIsLoading(false);
    }, 250);
  };

  const handleOnContextMenu = useCallback(() => {
    trackEvent({
      event: 'rightClickBulkQuickAdd',
      name: 'BulkQuickAddContextMenu',
    });
  }, [trackEvent]);

  const isLoading = externalIsLoading || internalIsLoading;
  const isRemoveInvalidItemsVisible = items.some(
    (item) => item.rowStatusReason !== RowStatusReason.Valid
  );
  const showDisclaimer = items.length > 0 && addToCartDisabled;

  return (
    <>
      <Modal
        isOpen={internalOpen}
        width={'98vw'}
      >
        <ModalHeader
          subHeader={
            <span css={bulkQuickAddSubheaderStyle}>
              <Icon type={IconType.LIST_PLUS} />
              {bulk_quick_add_modal_subheader}
            </span>
          }
        >
          {modalHeaderContent}
        </ModalHeader>
        {isLoading && <Spinner expandToParentContainer />}
        <div style={{ position: 'relative' }}>
          <div css={bulkQuickAddWrapper}>
            {!isLoading && (
              <BulkQuickAdd
                context={context}
                products={products}
                columnLabels={{
                  product,
                  product_column_info,
                  quantity,
                  name,
                  theme,
                  filter_availability,
                  total_pieces,
                  price_per_piece,
                  your_price,
                }}
                availabilityTranslations={{
                  available,
                  low_stock,
                  expected_back_in_stock,
                  out_of_stock,
                  not_available,
                  bulk_add_product_not_found,
                  not_in_window,
                  not_in_promotion,
                }}
                locale={locale}
                onChange={handleOnChange}
                initialData={initialData.current}
                onRawChange={handleOnRawChange}
                onEditContextMenu={handleOnContextMenu}
                contextMenuToolTipLabel={bulk_quick_add_right_click_tooltip}
              />
            )}
          </div>
        </div>
        <div
          css={{
            width: '100%',
            height: baseSpacing * 3,
            marginTop: baseSpacing * 2,
          }}
        >
          {showDisclaimer && (
            <Disclaimer
              text={bulk_add_blocked_continue_disclaimer}
              type={DisclaimerType.ERROR}
              align={'right'}
            />
          )}
          {submittingError && (
            <Disclaimer
              text={snackbar_excel_upload_add_error}
              type={DisclaimerType.WARNING}
              align={'right'}
            />
          )}
        </div>
        <ModalActions>
          {isRemoveInvalidItemsVisible && (
            <Button
              type={ButtonType.GHOST}
              onClick={handleOnRemoveInvalidItemsClick}
            >
              {button_remove_invalid_rows}
            </Button>
          )}
          {children}
        </ModalActions>
      </Modal>
      <BulkQuickAddRemovedItems
        confirmModalOpen={confirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        items={items}
        handleRemoveInvalidItems={handleRemoveInvalidItems}
      />
    </>
  );
};
