import { Money } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';

export const mapToMoney = (amount: number, currency: string): Money => ({
  amount,
  currency,
  __typename: 'Money',
});

export const mapToMoneyOrNull = (amount?: number | null, currency?: string): Money | null => {
  if (!amount || !currency) {
    return null;
  }
  return mapToMoney(amount, currency);
};
