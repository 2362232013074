import React, { createContext, useContext, useMemo, useState } from 'react';

type ModalContextType = {
  modalContainerRef: React.RefObject<HTMLDivElement>;
  setModalHeaderRef: (elm: HTMLDivElement) => void;
  setModalActionsRef: (elm: HTMLDivElement) => void;
  modalBodyMaxHeight: string;
  condensed: boolean;
};

const ModalContext = createContext<ModalContextType>({
  modalContainerRef: { current: null },
  setModalHeaderRef: () => {},
  setModalActionsRef: () => {},
  modalBodyMaxHeight: 'auto',
  condensed: false,
} as ModalContextType);

type ModalContextProviderProps = {
  children: React.ReactNode;

  modalContainerRef: React.RefObject<HTMLDivElement>;
  condensed?: boolean;
};

export const ModalContextProvider: React.FC<ModalContextProviderProps> = ({
  children,
  modalContainerRef,
  condensed = false,
}) => {
  const [modalHeaderRef, setModalHeaderRef] = useState<HTMLDivElement | null>(null);
  const [modalActionsRef, setModalActionsRef] = useState<HTMLDivElement | null>(null);

  const modalBodyMaxHeight = useMemo(() => {
    if (!modalContainerRef.current) {
      return 'auto';
    }

    const modalHeaderHeight = modalHeaderRef?.offsetHeight ?? 0;
    const modalActionsHeight = modalActionsRef?.offsetHeight ?? 0;

    const containerComputedStyle = window.getComputedStyle(modalContainerRef.current);
    const containerPaddingTop = parseInt(containerComputedStyle.paddingTop, 10);
    const containerPaddingBottom = parseInt(containerComputedStyle.paddingBottom, 10);
    const containerBorderTop = parseInt(containerComputedStyle.borderTopWidth, 10);
    const containerBorderBottom = parseInt(containerComputedStyle.borderBottomWidth, 10);
    const containerMarginTop = parseInt(containerComputedStyle.marginTop, 10);
    const containerMarginBottom = parseInt(containerComputedStyle.marginBottom, 10);

    return modalHeaderHeight || modalActionsHeight
      ? `calc(60vh - ${modalHeaderHeight + modalActionsHeight + containerPaddingTop + containerPaddingBottom + containerBorderTop + containerBorderBottom + containerMarginTop + containerMarginBottom}px)`
      : 'auto';
  }, [modalContainerRef, modalHeaderRef, modalActionsRef]);

  return (
    <ModalContext.Provider
      value={{
        modalContainerRef,
        setModalActionsRef,
        setModalHeaderRef,
        modalBodyMaxHeight,
        condensed,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
