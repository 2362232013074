import { css, cx } from '@emotion/css';
import { deepEqual } from 'fast-equals';
import React from 'react';

import { Icon, IconType } from '../../../../ui/Icon';
import { designToken } from '../../../../ui/theme';
import { useBulkQuickAddContext } from '../../BulkQuickAddContext';
import { RowStatusReason } from '../../types';

const statusRowIndicatorStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    height: 20,
    width: 20,
    fill: designToken.error.default,
  },
});

type StatusRowIndicatorProps = {
  // Row number, not row index in array on rows
  row: number;
};

export const StatusRowIndicator: React.FC<StatusRowIndicatorProps> = React.memo(
  ({ row }) => {
    const { rowStatuses } = useBulkQuickAddContext();

    const status = rowStatuses[row];
    const rowInvalid = status !== RowStatusReason.Valid && status !== RowStatusReason.None;

    return (
      <td className={cx('Spreadsheet__cell', statusRowIndicatorStyle)}>
        {rowInvalid && <Icon type={IconType.ALERT_OUTLINE} />}
      </td>
    );
  },
  (prevProps, nextProps) => deepEqual(prevProps, nextProps)
);
StatusRowIndicator.displayName = 'StatusRowIndicator';
