import { useLazyQuery } from '@apollo/client';
import { deepEqual } from 'fast-equals';
import { useEffect, useMemo, useState } from 'react';

import { CartReferenceInput } from '../../';
import { OrdersDataContext } from '../../context/Orders/OrdersDataContext';
import {
  OrdersProductsQueryVariables,
  PdfOrderQuery,
  PdfOrderQueryVariables,
} from '../../generated-types/graphql';
import { useDataAccessLayer } from '../DataAccessLayerProvider';
import { useDataAccessLayerMutation } from '../helpers/useDataAccessLayerMutation';
import { useDataAccessLayerQuery } from '../helpers/useDataAccessLayerQuery';

type GetAllParameters = Parameters<typeof OrdersDataContext.prototype.getAll>;
export const useOrders = (customerId: GetAllParameters[0], filter: GetAllParameters[1]) => {
  const dataAccessLayer = useDataAccessLayer();
  const [filterState, setFilterState] = useState(filter);

  useEffect(() => {
    if (!deepEqual(filterState, filter)) {
      setFilterState(filter);
    }
  }, [filter, filterState]);

  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.orders.getAll(customerId, filterState);
  }, [customerId, dataAccessLayer.orders, filterState]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};

export const useOrdersProducts = (customerId: OrdersProductsQueryVariables['customerId']) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.orders.products(customerId);
  }, [customerId, dataAccessLayer.orders]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};

type GetByIdParameters = Parameters<typeof OrdersDataContext.prototype.getById>;
export const useOrder = (orderNumber: GetByIdParameters[0], customerId: GetByIdParameters[1]) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.orders.getById(orderNumber, customerId);
  }, [customerId, dataAccessLayer.orders, orderNumber]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};

export const usePdfOrder = () => {
  const dataAccessLayer = useDataAccessLayer();
  const lazyQuery = useMemo(() => {
    return dataAccessLayer.orders.getPdfOrderLazy();
  }, [dataAccessLayer.orders]);

  return useLazyQuery<PdfOrderQuery, PdfOrderQueryVariables>(lazyQuery.query, {
    client: dataAccessLayer.apolloClient,
    fetchPolicy: 'no-cache',
  });
};

export const useValidateProducts = (customerId: number, cartReference: CartReferenceInput) => {
  const dataAccessLayer = useDataAccessLayer();

  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.orders.validateItems(customerId, cartReference);
  }, [cartReference, customerId, dataAccessLayer.orders]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};
