import { assign, clone } from 'lodash';

import { parseDateStringToLocalDate } from './parseDateStringToLocalDate';

const defaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const formatDateTime = (
  locale: string,
  date: string,
  options: Intl.DateTimeFormatOptions = defaultOptions
) => {
  const { year, month, day, hour, minute } = parseDateStringToLocalDate(date);

  const _options = assign(clone(defaultOptions), options);

  if (hour === 0 && minute === 0) {
    delete _options.hour;
    delete _options.minute;
  }

  return new Intl.DateTimeFormat(locale, _options).format(
    new Date(year, month - 1, day, hour, minute)
  );
};
