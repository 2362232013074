import { addBusinessDays, addDays, isAfter, isWeekend } from 'date-fns';

export const todayPlusLeadTime = (leadTime: number) => {
  const today = new Date().setHours(0, 0, 0, 0);
  // Whether lead time in SAP is business days or not depends on the transportation type for the customer. For
  // example, truck is (usually) business days while shipping is not. We don't get the transportation type from SAP,
  // so we have a very simple rule of thumb: If lead time >= 20, it's shipping and thus not business days.
  if (leadTime >= 20) {
    return addDays(today, leadTime);
  } else {
    return addBusinessDays(today, leadTime);
  }
};

/**
 * Returns the first upcoming business day after `date`
 * or last business day before `date` if the first upcoming business day is after `notAfter`
 * or `date` itself if it is already a business day.
 */
export const ensureBusinessDay = (date: Date, notAfter?: Date): Date => {
  if (isWeekend(date)) {
    const dateWithAddedBusinessDay = addBusinessDays(date, 1);
    if (notAfter && isAfter(dateWithAddedBusinessDay, notAfter)) {
      return addBusinessDays(date, -1);
    }

    return dateWithAddedBusinessDay;
  } else {
    return date;
  }
};
