import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useTopbar } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import { useHasFeature } from '@lego/b2b-unicorn-ui-featuretogglecontext';
import { useMemo } from 'react';

import CartIndicatorImplementation from '../../implementations/CartIndicatorImplementation';
import TopbarSearchAndUsers from '../common/TopbarSearchAndUsers';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import Logo from '../Logo/Logo';
import Menu from '../Menu/Menu';
import Topbar from '../Topbar/Topbar';

const desktopLanguageSelectorStyle = css({
  display: 'none',
  [media.large]: {
    display: 'contents',
  },
});

const logoMenuStyles = css({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  [media.large]: {
    flexDirection: 'row',
  },
});

const buttonWrapperStyle = css({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
  height: baseSpacing * 5,
  zIndex: 500,
});

const TopbarImplementation = () => {
  const launchOrdersEnabled = useHasFeature('launchorders', false);

  const { id: customerId } = useSelectedCustomer();
  const { loading, data, error } = useTopbar(customerId);
  const isReady = !loading && (data !== undefined || !!error);

  const availableForOrderPromotions = useMemo(
    () => data?.promotions?.availableForOrderPromotions || [],
    [data]
  );
  const noLongerAvailableForOrderPromotions = useMemo(
    () => data?.promotions?.noLongerAvailableForOrderPromotions || [],
    [data]
  );

  const promotionOrdersEnabled =
    isReady &&
    (availableForOrderPromotions.length > 0 || noLongerAvailableForOrderPromotions.length > 0);

  const {
    navbar_link_shop,
    navbar_link_manage,
    navbar_link_info,
    navbar_link_launch,
    navbar_link_promo,
    navbar_menu_label,
  } = useLabels();

  return (
    <Topbar>
      <div css={logoMenuStyles}>
        <Logo />
        <Menu
          translations={{
            navbar_link_shop,
            navbar_link_manage,
            navbar_link_info,
            navbar_link_launch,
            navbar_link_promo,
            navbar_menu_label,
          }}
          launchOrdersEnabled={launchOrdersEnabled}
          promotionOrdersFeatureEnabled={promotionOrdersEnabled}
        />
      </div>
      <div css={buttonWrapperStyle}>
        <TopbarSearchAndUsers />
        <div css={desktopLanguageSelectorStyle}>
          <LanguageSelector />
        </div>
        <CartIndicatorImplementation />
      </div>
    </Topbar>
  );
};

export default TopbarImplementation;
