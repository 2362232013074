import { gql } from '@apollo/client';

export const GET_TERMS_OF_SALES = gql`
  query getTermsOfSales($customerId: Int!) {
    getTermsOfSales(customerId: $customerId) {
      title
      description
      checkboxText
      buttonText
      version
    }
  }
`;
