import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';

import { AssetTokenQuery, AssetTokenQueryVariables } from '../../generated-types/graphql';
import { ContextAbstract } from '../ContextAbstract';
import { ASSET_TOKEN } from './queries/assetToken';

export type TokenAsset = AssetTokenQuery['getAssetToken'];

export class UtilsDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {};

  public getAssetToken(customerId: number, assetUid: string) {
    return this._apolloClient.query<TokenAsset, AssetTokenQueryVariables>({
      query: ASSET_TOKEN,
      variables: {
        customerId,
        assetUid,
      },
      fetchPolicy: 'no-cache',
    });
  }

  public getAssetTokenLazy() {
    const query = ASSET_TOKEN;
    const execute = (customerId: number, assetUid: string) =>
      this.getAssetToken(customerId, assetUid);

    return {
      execute,
      query,
    };
  }
}
