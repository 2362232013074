import type { ApolloError } from '@apollo/client';
import { usePdfInvoice, usePdfOrder } from '@lego/b2b-unicorn-data-access-layer/react';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { AnimatedDots, Button, ButtonType, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { downloadFile } from '../../utils/downloadFile';

type ButtonProps = Pick<React.ComponentProps<typeof Button>, 'type' | 'size'>;

type DownloadPdfButtonBaseProps = ButtonProps & {
  customerId: number;
  onError?: (e: ApolloError) => void;
};
type DownloadPdfButtonInvoiceProps = {
  invoiceNumber: number;
  orderNumber?: never;
};
type DownloadPdfButtonOrderProps = {
  invoiceNumber?: never;
  orderNumber: number;
};
type DownloadPdfButtonConditionalProps =
  | DownloadPdfButtonInvoiceProps
  | DownloadPdfButtonOrderProps;
type DownloadPdfButtonProps = DownloadPdfButtonBaseProps & DownloadPdfButtonConditionalProps;
const DownloadPdfButton: React.FC<DownloadPdfButtonProps> = ({
  invoiceNumber,
  orderNumber,
  customerId,
  onError,
  type = ButtonType.PRIMARY,
  size = 'medium',
}) => {
  const [pdfInvoice, { loading: pdfInvoiceLoading }] = usePdfInvoice();
  const [pdfOrder, { loading: pdfOrderLoading }] = usePdfOrder();

  const handleOnClick = () => {
    if (invoiceNumber) {
      pdfInvoice({
        variables: {
          invoiceNumber,
          customerId,
        },
      }).then((result) => {
        if (result.error && onError) {
          onError(result.error);
        }

        if (result.data) {
          downloadFile(
            result.data.getInvoicePdf.fileDataAsBase64,
            result.data.getInvoicePdf.fileName
          );
        }
      });
    }

    if (orderNumber) {
      pdfOrder({
        variables: {
          orderNumber,
          customerId,
        },
      }).then((result) => {
        if (result.error && onError) {
          onError(result.error);
        }

        if (result.data) {
          downloadFile(result.data.getOrderPdf.fileDataAsBase64, result.data.getOrderPdf.fileName);
        }
      });
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      handleOnClick();
    }
  };

  const loading = pdfInvoiceLoading || pdfOrderLoading;

  return (
    <Button
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      type={type}
      size={size}
      disabled={loading}
    >
      {loading && <AnimatedDots />}
      {!loading && (
        <>
          <Icon type={IconType.DOWNLOAD} />
          <span>PDF</span>
        </>
      )}
    </Button>
  );
};

export default DownloadPdfButton;
