import { gql } from '@apollo/client';

export const UPDATE_CART_IN_WINDOW = gql`
  mutation updateCartInWindow(
    $customerId: Int!
    $items: [UpdateCartItemInput!]!
    $cartReference: CartReferenceInput!
  ) {
    updateCartWithMultipleItems(
      customerId: $customerId
      items: $items
      cartReference: $cartReference
    ) {
      items {
        quantity
        product {
          materialId
          ... on Box {
            itemNumber
            recommendedRetailPrice {
              amount
              currency
            }
          }
          name
          theme
          piecesPerCasePack
          price {
            currency
            grossPrice
            estimatedNetInvoicedPrice
          }
        }
      }
      ...cartKey
    }
  }
`;
