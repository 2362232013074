import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { StockStatusTag } from '@lego/b2b-unicorn-data-access-layer';
import { AvailabilityStatusType, SelectBox } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { inputContainerStyle } from '../../../../styles';
import { useProductListContext } from '../../ProductListContext';

export const AvailabilityFilter: React.FC = () => {
  const labelsContext = useLabels();
  const { getLabelByKey } = labelsContext;
  const {
    filtersList: { availabilities },
    selectedFilters,
    setSelectedFilters,
  } = useProductListContext();

  const handleAvailabilityStatusSelect = (value: string | string[]) => {
    if (Array.isArray(value)) {
      let updatedFilters = { ...selectedFilters };
      if (value.length) {
        updatedFilters = {
          ...selectedFilters,
          availabilities: value as StockStatusTag[],
        };
      } else {
        delete updatedFilters.availabilities;
      }
      setSelectedFilters(updatedFilters);
    }
  };

  return (
    <div css={inputContainerStyle}>
      <SelectBox
        label={labelsContext.filter_availability}
        values={Object.keys(AvailabilityStatusType).map((status) => {
          const foundOption = availabilities.find((option) => option.availability === status);
          const hitsCount = `[${foundOption?.hitsCount}]`;
          return {
            displayText: `${getLabelByKey(status.toLowerCase())} ${
              foundOption && foundOption.hitsCount > 0 ? hitsCount : ''
            }`,
            value: status,
          };
        })}
        selectedValues={selectedFilters?.availabilities || []}
        optionsChangeHandler={handleAvailabilityStatusSelect}
        allowMultiple={true}
      />
    </div>
  );
};
