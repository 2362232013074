import React, { useMemo } from 'react';

import { formatDate } from './helpers/formatDate';

interface DateProps {
  children: string | null | undefined;
  locale: string;
  options?: Intl.DateTimeFormatOptions;
  fallbackText?: string;
}

export const Date: React.FC<DateProps> = ({ children, locale, options, fallbackText = '-' }) => {
  const formattedDate = useMemo(
    () => (children ? formatDate(locale, children, options) : fallbackText),
    [children, fallbackText, locale, options]
  );

  return <>{formattedDate}</>;
};
