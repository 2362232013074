import {
  Icon,
  IconType,
  Tag,
  TagType,
  ToolTip,
  ToolTipDirection,
} from '@lego/b2b-unicorn-shared/ui';

interface ITranslations {
  rare_product_tag: string;
}

interface RareItemTagProps {
  direction?: ToolTipDirection;
  translations: ITranslations;
  locale?: string;
}

export const RareItemTag = (props: RareItemTagProps) => {
  const { direction = ToolTipDirection.Up, translations: { rare_product_tag } = {} } = props;

  return (
    <ToolTip
      direction={direction}
      message={rare_product_tag}
    >
      <Tag type={TagType.ORANGE_BOLD}>
        <Icon type={IconType.DIAMOND} />
      </Tag>
    </ToolTip>
  );
};
