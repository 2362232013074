import { css } from '@emotion/react';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import { FunctionComponent, ReactNode } from 'react';

interface IStatusSummaryItem {
  label: string;
  children: ReactNode;
}

const containerStyle = css({
  paddingBottom: baseSpacing,
  paddingRight: baseSpacing,
  boxSizing: 'border-box',
  minWidth: '45%',
  [media.medium]: {
    paddingBottom: 0,
    paddingRight: 0,
    minWidth: 0,
  },
});

const labelStyle = css({
  fontSize: 11,
  [media.medium]: {
    fontSize: 14,
    paddingBottom: baseSpacing / 2,
  },
});

const valueStyle = css({
  fontSize: 18,
  fontWeight: 700,
});

const StatusSummaryItem: FunctionComponent<IStatusSummaryItem> = ({ children, label }) => {
  return (
    <div css={containerStyle}>
      <div css={labelStyle}>{label}</div>
      <div css={valueStyle}>{children || '-'}</div>
    </div>
  );
};

export default StatusSummaryItem;
