import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { LaunchCartProduct } from '@lego/b2b-unicorn-data-access-layer';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  TableCell,
  TableCellName,
  TableCellNumber,
  TableCellQuantityButton,
  TableRow,
  Typography,
} from '@lego/b2b-unicorn-shared/ui';
import { useCartItemRow } from '@lego/b2b-unicorn-ui-checkout-flow';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import { deepEqual } from 'fast-equals';
import React, { useMemo } from 'react';

import { ProductName } from '../../../../../components/ProductName/ProductName';
import { CartPreviewItemCounterWithButton } from '../../../../../components/ReplenishmentCartPreview/CartPreviewItemCounterWithButton';

interface LaunchItemRowProps {
  product: LaunchCartProduct;
  quantity: number;
  onUpdate: (product: LaunchCartProduct, newQuantity: number) => void;
  updateError: boolean;
  inactive?: boolean;
}

const launchTableRowOrderStyle = css({
  '> td:nth-of-type(n+3)': {
    minHeight: 25,
    paddingLeft: `calc(30% + ${baseSpacing * 2}px)`,
    [media.medium]: {
      paddingLeft: baseSpacing,
    },
  },
  '> td:nth-of-type(1)': {
    order: 1,
    position: 'absolute',
    top: 20,
    right: '70%',

    [media.medium]: {
      position: 'static',
    },
  },
  '> td:nth-of-type(2)': {
    order: 2,
  },
  '> td:nth-of-type(3)': {
    order: 3,
  },
  '> td:nth-of-type(4)': {
    order: 9,
  },
  '> td:nth-of-type(5)': {
    order: 8,
  },
  '> td:nth-of-type(6)': {
    order: 4,
  },
  '> td:nth-of-type(7)': {
    order: 5,
  },
  '> td:nth-of-type(8)': {
    order: 6,
  },
  '> td:last-of-type': {
    order: 7,
  },
});

export const LaunchItemRow: React.FC<LaunchItemRowProps> = React.memo(
  ({ onUpdate, updateError, quantity, product, inactive = false }) => {
    const {
      total_pieces,
      your_price,
      snackbar_update_product_error,
      theme,
      recommended_retail_price,
      price_per_piece,
      launch_date,
    } = useLabels();
    const selectedCustomer = useSelectedCustomer();

    const { handleCounterUpdate, totalPieces } = useCartItemRow<LaunchCartProduct>({
      onUpdate,
      updateError,
      quantity,
      product,
      snackbar_error_message: snackbar_update_product_error,
    });

    const itemNumber = product.__typename === 'Box' ? product.itemNumber : null;
    const recommendedRetailPrice = useMemo(() => {
      if (product.__typename !== 'Box') {
        return null;
      }

      return product.recommendedRetailPrice;
    }, [product]);
    const launchDate =
      product.__typename === 'Box' || product.__typename === 'BillOfMaterial'
        ? product.launchDate
        : null;

    return (
      <TableRow
        highlight={quantity > 0 && !inactive}
        extraCss={launchTableRowOrderStyle}
      >
        <TableCellNumber align={'left'}>
          <Typography weight={'bold'}>{itemNumber || product.materialId}</Typography>
        </TableCellNumber>
        <TableCellName>
          <ProductName name={product.name} />
        </TableCellName>
        <TableCell colName={theme}>{product.theme}</TableCell>
        {!inactive && (
          <>
            <TableCellQuantityButton>
              <CartPreviewItemCounterWithButton
                quantity={quantity}
                onChange={handleCounterUpdate}
              />
            </TableCellQuantityButton>
            <TableCellNumber
              colName={total_pieces}
              align={'center'}
            >
              {totalPieces}
            </TableCellNumber>
          </>
        )}

        <TableCellNumber colName={recommended_retail_price}>
          <Price locale={selectedCustomer.locale}>{recommendedRetailPrice}</Price>
        </TableCellNumber>
        <TableCellNumber colName={price_per_piece}>
          <Price locale={selectedCustomer.locale}>{product.price}</Price>
        </TableCellNumber>

        {!inactive && (
          <TableCellNumber
            colName={your_price}
            align={'right'}
          >
            <Price
              locale={selectedCustomer.locale}
              multiplier={totalPieces}
            >
              {product.price}
            </Price>
          </TableCellNumber>
        )}

        <TableCellNumber colName={launch_date}>
          <Date locale={selectedCustomer.locale}>{launchDate}</Date>
        </TableCellNumber>
      </TableRow>
    );
  },
  (prevProps, nextProps) => {
    return deepEqual(prevProps, nextProps);
  }
);
LaunchItemRow.displayName = 'LaunchWindowItemRow';
