import { css, cx } from '@emotion/css';
import type { CellBase } from 'react-spreadsheet';

import {
  AvailabilityCell,
  NameCell,
  PricePerPieceCell,
  TotalPiecesCell,
  YourPriceCell,
} from '../../components/cells';
import { errorAvailabilityCellCss, errorEditCellCss } from '../../styles';
import {
  DataStructure,
  InternalDataRow,
  IProduct,
  RowStatusReason,
  StockStatusTag,
} from '../../types';

const basicReadonlyCell: CellBase = {
  readOnly: true,
  value: '',
};

export const rawDataReducer =
  (products: IProduct[], product_not_found: string) =>
  (result: DataStructure, currentRow: Array<CellBase | undefined>) => {
    const [rawItemIdCell, rawQuantityCell] = currentRow;

    const itemId =
      rawItemIdCell && !isNaN(rawItemIdCell.value) ? parseInt(rawItemIdCell.value) : undefined;
    const product =
      rawItemIdCell && rawItemIdCell.value
        ? products.find((product) => {
            if (!itemId) {
              return false;
            }

            return (
              product.itemNumber === itemId ||
              product.ean === itemId.toString() ||
              product.materialId === itemId
            );
          })
        : undefined;
    /*
     * First we set base values of the row
     */
    // ItemID cell
    const itemIdCell: InternalDataRow[0] = {
      value: rawItemIdCell?.value || '',
    };

    // Quantity cell
    const quantityCell: InternalDataRow[1] = {
      value: rawQuantityCell?.value || '',
    };
    if (rawQuantityCell?.value) {
      quantityCell.value = isNaN(rawQuantityCell.value) ? '' : parseInt(rawQuantityCell.value);
    }

    // Product name cell
    const productNameCell: InternalDataRow[2] = {
      ...basicReadonlyCell,
    };
    if (itemIdCell?.value && !product) {
      productNameCell.value = product_not_found;
    } else {
      productNameCell.value = product ? product.name ?? '' : '';
    }

    // Theme cell
    const themeCell: InternalDataRow[3] = {
      ...basicReadonlyCell,
    };
    if (product && product.theme) {
      themeCell.value = product.theme;
    }

    // Availability cell
    const availabilityCell: InternalDataRow[4] = {
      ...basicReadonlyCell,
    };
    if (product && product.stockStatus) {
      availabilityCell.value = product.stockStatus;
    }

    // Total pieces cell
    const totalPiecesCell: InternalDataRow[5] = {
      ...basicReadonlyCell,
    };
    if (product && product.piecesPerCasePack) {
      totalPiecesCell.value = {
        casePackSize: product.piecesPerCasePack,
        quantity: quantityCell?.value || undefined,
      };
    }

    // Price per piece cell
    const pricePerPieceCell: InternalDataRow[6] = {
      ...basicReadonlyCell,
    };
    if (product && product.price) {
      pricePerPieceCell.value = product.price;
    }

    // Your price cell
    const yourPriceCell: InternalDataRow[7] = {
      ...basicReadonlyCell,
    };
    if (product) {
      yourPriceCell.value = {
        product,
        quantity: quantityCell?.value || undefined,
      };
    }

    // Secondly do the validation on cells
    // Enum indicator if there is an error somewhere in the row or if the row is valid
    let rowStatusReason = RowStatusReason.None;
    const rowHasData = rawItemIdCell?.value || rawQuantityCell?.value;

    // ItemId cell validation
    if (
      itemIdCell &&
      itemIdCell.value &&
      (!product || isNaN(itemIdCell.value) || itemIdCell.value < 1)
    ) {
      itemIdCell.className = cx(css(errorEditCellCss));
      rowStatusReason = RowStatusReason.NotInCatalog;
    }
    if (!itemIdCell.value && quantityCell.value) {
      itemIdCell.className = cx(css(errorEditCellCss));
      rowStatusReason = RowStatusReason.ItemIdInvalid;
    }

    // Quantity cell validation
    if (
      quantityCell &&
      quantityCell.value &&
      (isNaN(quantityCell.value as number) || quantityCell.value < 1 || quantityCell.value > 9999)
    ) {
      quantityCell.className = cx(css(errorEditCellCss));
      if (rowStatusReason === RowStatusReason.None) {
        rowStatusReason = RowStatusReason.QuantityInvalid;
      }
    }
    if (itemIdCell.value && !quantityCell.value) {
      quantityCell.className = cx(css(errorEditCellCss));
      if (rowStatusReason === RowStatusReason.None) {
        rowStatusReason = RowStatusReason.QuantityInvalid;
      }
    }

    // Set data viewers on cells
    productNameCell.DataViewer = NameCell;
    availabilityCell.DataViewer = AvailabilityCell;
    totalPiecesCell.DataViewer = TotalPiecesCell;
    pricePerPieceCell.DataViewer = PricePerPieceCell;
    yourPriceCell.DataViewer = YourPriceCell;

    // Availability validation
    const errorStatusValues = [StockStatusTag.NotAvailable, StockStatusTag.OutOfStock];
    if (product && errorStatusValues.some((status) => product.stockStatus?.status === status)) {
      rowStatusReason =
        product.stockStatus?.status === StockStatusTag.NotAvailable
          ? RowStatusReason.NotAvailable
          : RowStatusReason.OutOfStock;
    }

    if (
      rowStatusReason === RowStatusReason.NotAvailable ||
      rowStatusReason === RowStatusReason.OutOfStock
    ) {
      availabilityCell.className = cx(availabilityCell.className, css(errorAvailabilityCellCss));
    }

    rowStatusReason = rowHasData
      ? rowStatusReason === RowStatusReason.None
        ? RowStatusReason.Valid
        : rowStatusReason
      : RowStatusReason.None;

    // Finally add the cells to the result arrays
    result.statusRows.push(rowStatusReason);
    result.dataRows.push([itemIdCell, quantityCell]);
    result.productRows.push([
      productNameCell,
      themeCell,
      availabilityCell,
      totalPiecesCell,
      pricePerPieceCell,
      yourPriceCell,
    ]);

    if (rowHasData) {
      result.finalRows.push({
        product: product ?? null,
        productLookupValue: rawItemIdCell?.value,
        quantity: !isNaN(quantityCell.value as number) ? (quantityCell.value as number) : 0,
        rowStatusReason,
      });
    }

    return result;
  };
