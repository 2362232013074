export const DOCUMENT_TITLE = {
  MAIN: 'Shop',
  PRODUCT_LIST: 'Product list',
  PRODUCT_DETAILS: 'Product details',
  CART: {
    PREVIEW: 'Cart preview',
    CHECKOUT: 'Checkout',
    ORDER_CONFIRMATION: 'Order confirmation',
  },
  EXCEL_UPLOAD: 'Excel upload',
  PAGE_NOT_FOUND: 'Page not found',
  LAUNCH: {
    MAIN: 'Launch orders',
    OVERVIEW: 'Windows overview',
  },
  MANAGE: {
    MAIN: 'Orders overview',
    ORDER_DETAILS: 'Order details',
    INVOICES: 'Invoices overview',
    INVOICE_DETAILS: 'Invoice details',
    UPCOMING_DELIVERIES: 'Upcoming deliveries',
  },
  INFO: 'Info',
};
