import React from 'react';

import { assignDesignToken, ObjectPathLeavesOnly } from '../../utils';
import { designToken, font } from '../theme';

type TypographyProps = {
  children: React.ReactNode;
  size?: keyof typeof font.size;
  weight?: keyof typeof font.weight;
  id?: string;
  designToken?: ObjectPathLeavesOnly<typeof designToken>;
};

export const Typography: React.FC<TypographyProps> = ({
  children,
  size,
  weight,
  designToken,
  id,
}) => {
  const myDesignToken = designToken ? assignDesignToken(designToken) : undefined;

  return (
    <span
      css={{
        fontSize: size ? font.size[size] : undefined,
        fontWeight: weight ? font.weight[weight] : undefined,
        color: myDesignToken,
        '> svg': {
          display: 'inline-block',
          verticalAlign: 'text-bottom',
          height: size ? font.size[size] : '1em',
          width: size ? font.size[size] : '1em',
          fill: myDesignToken,
        },
      }}
      id={id}
    >
      {children}
    </span>
  );
};
