import prettyBytes from 'pretty-bytes';
import React, { useEffect, useState } from 'react';

import { Button, ButtonType } from '../../ui/Button';
import { FlexBox } from '../../ui/FlexBox';
import { Icon, IconType } from '../../ui/Icon';
import { LinearProgress } from '../../ui/LinearProgress';
import { ListItem } from '../../ui/List';
import { Spacer } from '../../ui/Spacer';
import { designToken, font } from '../../ui/theme';
import { Typography } from '../../ui/Typography';
import { FileUpload } from '../FileUpload';
import { FileUploadItem, FileUploadItemStatus } from './service/FileUploadItem';

type FileUploadListItemProps = {
  fileItem: FileUploadItem;
  translations: React.ComponentProps<typeof FileUpload>['translations'];
  onRemove?: (file: FileUploadItem) => void;
};

export const FileUploadListItem: React.FC<FileUploadListItemProps> = ({
  fileItem,
  onRemove,
  translations,
}) => {
  const [fileState, setFileState] = useState<typeof FileUploadItem.prototype.state.value>({
    bytesUploaded: 0,
    progress: 0,
    sizeBytes: 0,
    status: FileUploadItemStatus.Idle,
  });

  useEffect(() => {
    const subscription = fileItem.state.subscribe((state) => {
      setFileState(state);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <ListItem>
      <FlexBox
        fullWidth
        flexDirection={'column'}
      >
        <FlexBox justifyContent={'space-between'}>
          <FlexBox
            alignItems={'center'}
            height={font.size.large}
          >
            <Typography weight={'bold'}>{fileItem.file.name}</Typography>
            <Spacer
              direction={'vertical'}
              multiplier={0.5}
            />
            {fileState.status === FileUploadItemStatus.Uploading && (
              <>
                <Button
                  fullWidth={false}
                  type={ButtonType.GHOST}
                  size={'small'}
                  onClick={(e) => {
                    e.preventDefault();
                    fileItem.cancel();
                  }}
                >
                  <Icon
                    type={IconType.CLOSE}
                    height={font.size.medium}
                  />
                </Button>
              </>
            )}
            {fileState.status === FileUploadItemStatus.Success && (
              <Icon
                type={IconType.CHECK}
                color={designToken.success.default}
                height={font.size.medium}
              />
            )}
            {fileState.status === FileUploadItemStatus.Cancelled && (
              <FlexBox alignItems={'center'}>
                <Icon
                  type={IconType.INFO_OUTLINE}
                  height={font.size.medium}
                  color={designToken.warning.default}
                />
                <Spacer
                  direction={'vertical'}
                  multiplier={0.5}
                />
                <Typography designToken={'warning.default'}>
                  {translations.status.cancelled}
                </Typography>
              </FlexBox>
            )}
            {fileState.status === FileUploadItemStatus.Failed && (
              <FlexBox alignItems={'center'}>
                <Icon
                  type={IconType.ALERT}
                  height={font.size.medium}
                  color={designToken.error.default}
                />
                <Spacer
                  direction={'vertical'}
                  multiplier={0.5}
                />
                <Typography designToken={'error.default'}>{translations.status.failed}</Typography>
              </FlexBox>
            )}
            {fileState.status === FileUploadItemStatus.Unsupported && (
              <FlexBox alignItems={'center'}>
                <Icon
                  type={IconType.INFO_OUTLINE}
                  height={font.size.medium}
                  color={designToken.error.default}
                />
                <Spacer
                  direction={'vertical'}
                  multiplier={0.5}
                />
                <Typography designToken={'warning.default'}>
                  {translations.status.unsupported}
                </Typography>
              </FlexBox>
            )}
            {(fileState.status === FileUploadItemStatus.FileSizeExceeded ||
              fileState.status === FileUploadItemStatus.TotalFileSizeExceeded) && (
              <FlexBox alignItems={'center'}>
                <Icon
                  type={IconType.INFO_OUTLINE}
                  height={font.size.medium}
                  color={designToken.error.default}
                />
                <Spacer
                  direction={'vertical'}
                  multiplier={0.5}
                />
                <Typography designToken={'warning.default'}>
                  {translations.status.fileSizeExceeded}
                </Typography>
              </FlexBox>
            )}
          </FlexBox>
          {fileState.status !== FileUploadItemStatus.Uploading &&
            fileState.status !== FileUploadItemStatus.Idle &&
            onRemove && (
              <Button
                type={ButtonType.GHOST}
                size={'small'}
                onClick={(e) => {
                  e.preventDefault();
                  onRemove?.(fileItem);
                }}
              >
                <Icon
                  type={IconType.DELETE}
                  height={font.size.medium}
                  color={designToken.interactive.default}
                />
              </Button>
            )}
        </FlexBox>
        {(fileState.status === FileUploadItemStatus.Idle ||
          fileState.status === FileUploadItemStatus.Uploading) && (
          <LinearProgress value={fileState.progress ?? undefined} />
        )}
        {fileState.status === FileUploadItemStatus.Uploading && fileState.progress > 0 && (
          <Typography designToken={'text.secondary'}>
            {prettyBytes(fileState.bytesUploaded ?? 0, { space: false })} of{' '}
            {prettyBytes(fileState.sizeBytes, { space: false })}
          </Typography>
        )}
      </FlexBox>
    </ListItem>
  );
};
