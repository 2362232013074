import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Typography } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const invoiceDetailsItemStyle = css({
  '*': {
    marginRight: baseSpacing,
  },
  marginBottom: baseSpacing * 2,
  [media.medium]: {
    marginBottom: 0,
  },
});
type CreateClaimInvoiceInformationProps = {
  invoiceNumber: number;
};
export const CreateClaimInvoiceInformation: React.FC<CreateClaimInvoiceInformationProps> = ({
  invoiceNumber,
}) => {
  const { invoice_number } = useLabels();

  return (
    <div css={invoiceDetailsItemStyle}>
      <Typography
        size={'large'}
        weight={'light'}
        designToken={'text.subdued'}
      >
        {invoice_number}:
      </Typography>
      <Typography
        size={'large'}
        weight={'normal'}
        designToken={'text.informative'}
      >
        {invoiceNumber}
      </Typography>
    </div>
  );
};
