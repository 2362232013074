import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  ReplenishmentProductBox,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { AvailabilityStatus, designToken, ToolTipDirection } from '@lego/b2b-unicorn-shared/ui';
import { OpenOrderIndicator } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';

import { formCommonInputStyle } from './common/quickAddStyles';

const selectedItemOverlayStyle = [
  formCommonInputStyle,
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingRight: baseSpacing * 3,
    boxShadow: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    lineHeight: `${baseSpacing * 5}px`,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '&:focus,&:hover': {
      backgroundColor: designToken.background.blue,
    },
    [media.medium]: {
      lineHeight: `${baseSpacing * 7}px`,
    },
  }),
];

const nameStyle = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

interface IQuickAddSelectedProduct {
  selectedProduct?: ReplenishmentProductsProduct;
  reopenResults: () => void;
}

const QuickAddSelectedProduct = ({ selectedProduct, reopenResults }: IQuickAddSelectedProduct) => {
  const {
    open_order_dialog_header,
    delivery_date_expected_next,
    total_pending,
    pcs,
    pcs_by,
    available,
    low_stock,
    expected_back_in_stock,
    out_of_stock,
    not_available,
  } = useLabels();
  const selectedCustomer = useSelectedCustomer();

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      reopenResults();
    }
  };

  return selectedProduct ? (
    <div
      role="button"
      css={selectedItemOverlayStyle}
      onClick={reopenResults}
      onKeyDown={onKeyDownHandler}
      tabIndex={0}
    >
      {!!selectedProduct.openOrderInfo && (
        // this onClick allows opening open order indicator without calling 'reopenResults' fn
        <span
          css={{ marginRight: 8, marginTop: 4 }}
          onClick={(e) => e.stopPropagation()}
        >
          <OpenOrderIndicator
            isSmall
            direction={ToolTipDirection.Down}
            shippingDate={selectedProduct.openOrderInfo.shippingDate}
            shippingQuantity={selectedProduct.openOrderInfo.shippingQuantity}
            totalPendingQuantity={selectedProduct.openOrderInfo.totalPendingQuantity}
            locale={selectedCustomer.locale}
            translations={{
              open_order_dialog_header,
              delivery_date_expected_next,
              total_pending,
              pcs,
              pcs_by,
            }}
          />
        </span>
      )}
      <span css={nameStyle}>
        {selectedProduct instanceof ReplenishmentProductBox && selectedProduct.itemNumber}{' '}
        {selectedProduct.name}
      </span>
      {selectedProduct.stockStatus && (
        <span css={{ marginLeft: 5 }}>
          <AvailabilityStatus
            status={selectedProduct.stockStatus.status}
            expectedBackDate={selectedProduct.stockStatus.expectedBackDate}
            translations={{
              available,
              low_stock,
              expected_back_in_stock,
              out_of_stock,
              not_available,
            }}
            locale={selectedCustomer.locale}
            type="simple"
          />
        </span>
      )}
    </div>
  ) : null;
};

export default QuickAddSelectedProduct;
