import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  Lifecycle,
  ProductTag,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';
import { LifecycleTag, OpenOrderIndicator, RareItemTag } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { useHistory } from 'react-router-dom';

import { FoundInCartTag } from '../../../../shared/Components/ProductTags';

const tagsRowStyle = css({
  display: 'flex',
  flexWrap: 'nowrap',
  width: '100%',
  height: 42,
  alignItems: 'center',
  marginTop: 0,
  img: {
    verticalAlign: 'baseline',
  },
});

interface IProductTags {
  lifecycles?: Lifecycle[];
  tags: ProductTag[];
  openOrderInfo?: ReplenishmentProductsProduct['openOrderInfo'];
  foundInCart?: boolean;
  locale: string;
  itemNumber?: number | null;
}

export const ProductTileTags = ({
  lifecycles = [],
  tags,
  openOrderInfo,
  foundInCart = false,
  locale,
  itemNumber,
}: IProductTags) => {
  const {
    open_order_dialog_header,
    delivery_date_expected_next,
    total_pending,
    pcs,
    pcs_by,
    rare_product_tag,
  } = useLabels();

  const history = useHistory();

  const handleOOIClick = () => {
    if (!itemNumber) {
      return;
    }
    const upcomingDeliveryUrl = new URL('/manage/upcoming-deliveries', window.location.origin);
    upcomingDeliveryUrl.searchParams.append('itemQuery', itemNumber.toString());

    history.push(`${upcomingDeliveryUrl.pathname}${upcomingDeliveryUrl.search}`);
  };

  return (
    <section css={tagsRowStyle}>
      {lifecycles?.map((tag) => {
        return (
          <LifecycleTag
            key={tag}
            type={tag}
          />
        );
      })}
      {tags.includes(ProductTag.RareItem) && <RareItemTag translations={{ rare_product_tag }} />}
      <span css={{ display: 'inline-flex', marginLeft: 'auto', gap: baseSpacing / 2 }}>
        {openOrderInfo && (
          <OpenOrderIndicator
            shippingDate={openOrderInfo.shippingDate}
            shippingQuantity={openOrderInfo.shippingQuantity}
            totalPendingQuantity={openOrderInfo.totalPendingQuantity}
            locale={locale}
            translations={{
              open_order_dialog_header,
              delivery_date_expected_next,
              total_pending,
              pcs,
              pcs_by,
            }}
            onClick={handleOOIClick}
          />
        )}
        {foundInCart && <FoundInCartTag />}
      </span>
    </section>
  );
};
