import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';

import {
  SearchProductsQuery,
  SearchProductsQueryVariables,
  TopbarQuery,
  TopbarQueryVariables,
} from '../../generated-types/graphql';
import { CartReferenceCartType } from '../../generated-types/types';
import { ContextAbstract } from '../ContextAbstract';
import { ExtraOptions } from '../GenericContextTypes';
import { SEARCH_PRODUCTS } from './queries/searchProducts';
import { TOPBAR } from './queries/topbar';

type SearchProductsPayload = Omit<SearchProductsQueryVariables, 'customerId'>;

export class TopbarDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {};

  public getTopbar(customerId: number, extraOptions?: ExtraOptions) {
    return this._apolloClient.watchQuery<TopbarQuery, TopbarQueryVariables>({
      query: TOPBAR,
      variables: {
        customerId,
        cartReference: { cartType: CartReferenceCartType.Replenish },
      },
      ...extraOptions,
    });
  }

  public searchProducts(customerId: number, query?: string) {
    const observableKey = `searchProducts-${customerId}`;

    return this.queryObservable<
      SearchProductsQuery,
      SearchProductsQueryVariables,
      SearchProductsPayload
    >(
      observableKey,
      SEARCH_PRODUCTS,
      { customerId, query: query || '' },
      { fetchPolicy: 'no-cache', noInitialTrigger: true }
    );
  }
}
