import { css } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const selectorButtonStyle = css({
  position: 'relative',
  height: 60,
  width: '100%',
  padding: baseSpacing,
  paddingLeft: baseSpacing * 3,
  svg: {
    fill: colors.klik.slate10,
  },
  '&:hover': {
    backgroundColor: designToken.primary.hover,
    outline: 'none',
  },
  [media.large]: {
    height: 72,
    width: 'auto',
    paddingLeft: baseSpacing * 2,
    outline: 'none',
    '&:hover, :focus': {
      backgroundColor: 'initial',
      color: colors.klik.lightBlue100,
      svg: {
        fill: colors.klik.lightBlue100,
      },
    },
  },
});

const contentStyle = css({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  fontWeight: font.weight.medium,
  '> div:first-of-type': {
    fontSize: font.size.small,
    margin: 0,
    marginRight: baseSpacing * 3,
    [media.large]: {
      fontSize: font.size.tiny,
    },
  },
  '> div:last-of-type': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    '> svg': {
      width: 12,
      height: 12,
      transform: 'translateY(1px)',
      marginLeft: baseSpacing / 2,
    },
    [media.large]: {
      fontSize: font.size.small,
      // marginTop: -4,
    },
  },
  [media.large]: {
    flexDirection: 'column',
    fontSize: font.size.tiny,
    height: baseSpacing * 4,
  },
});

interface ILanguageSelectorButtonProps {
  preferredLanguageName: string;
  translations: { buttonLabel: string };
  toggleCustomerSelection?: () => void;
}

const LanguageSelectorButton = ({
  preferredLanguageName,
  translations,
  toggleCustomerSelection,
}: ILanguageSelectorButtonProps) => {
  const { buttonLabel } = translations;

  return (
    <button
      css={selectorButtonStyle}
      type="button"
      aria-label="Open menu to change preffered language."
      onClick={toggleCustomerSelection}
    >
      <div css={contentStyle}>
        <div>{buttonLabel}</div>
        <div>
          <span>{preferredLanguageName}</span>
          &nbsp;
          <Icon type={IconType.CHEVRON_DOWN} />
        </div>
      </div>
    </button>
  );
};

export default LanguageSelectorButton;
