import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ContentSystemFeedback, SystemFeedbackType } from '@lego/b2b-unicorn-shared/ui';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import React from 'react';

type PageNotFoundHandlerProps = {
  noTopbar?: boolean;
  children?: React.ReactNode;
  text?: string;
};
const PageNotFound: React.FC<PageNotFoundHandlerProps> = ({ noTopbar, children, text }) => {
  const { page_not_found } = useLabels();

  return (
    <>
      {!noTopbar && <TopbarImplementation />}
      <ContentSystemFeedback
        type={SystemFeedbackType.NOT_FOUND}
        text={text || page_not_found}
      >
        {children}
      </ContentSystemFeedback>
    </>
  );
};

export default PageNotFound;
