import { css } from '@emotion/react';
import React from 'react';

import { Icon, IconType } from '../Icon';
import { baseSpacing, designToken } from '../theme';

const tileButtonStyle = (withAlert: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
    color: withAlert ? designToken.warning.emphasis : designToken.interactive.default,
    borderWidth: 2,
    borderRadius: baseSpacing * 2,
    borderColor: withAlert ? designToken.warning.subdued : designToken.information.subdued,
    backgroundColor: withAlert ? designToken.warning.subdued : designToken.transparent.default,
    height: baseSpacing * 3,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: baseSpacing * 1.5,
    paddingLeft: baseSpacing * 1.5,
    marginTop: 'auto',
    '> svg': {
      height: 17,
      width: 17,
      fill: withAlert ? designToken.warning.emphasis : designToken.interactive.default,
      marginLeft: 2,
    },
  });

export const TileButton = ({
  withAlert,
  children,
}: {
  withAlert: boolean;
  children: React.ReactNode;
}) => {
  return (
    <span css={tileButtonStyle(withAlert)}>
      {children} {withAlert ? <Icon type={IconType.BELL} /> : <Icon type={IconType.ARROW_RIGHT} />}
    </span>
  );
};
