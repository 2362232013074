import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing } from '../theme';
import { SkeletonTile } from './SkeletonTile';

const tileWidth = 340;
const oneColWidth = tileWidth;
export const oneColMedia = oneColWidth + 36;
export const twoColMedia = tileWidth * 2 + baseSpacing * 2 + 36;
export const threeColMedia = tileWidth * 3 + baseSpacing * 4 + 36;
export const fourColMedia = tileWidth * 4 + baseSpacing * 6 + 36;

const skeletonListStyle = css({
  display: 'contents',
  '> div:not(:first-of-type)': {
    display: 'none',
  },
  [`@media (min-width: ${twoColMedia}px)`]: {
    '> div:nth-of-type(2)': {
      display: 'flex',
    },
  },
  [`@media (min-width: ${threeColMedia}px)`]: {
    '> div:nth-of-type(3)': {
      display: 'flex',
    },
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    '> div:last-of-type': {
      display: 'flex',
    },
  },
});

type SkeletonListTileProps = {
  count?: number;
};
export const SkeletonListTile: React.FC<SkeletonListTileProps> = ({ count = 3 }) => {
  return (
    <div css={skeletonListStyle}>
      {[...Array(count)].map((_, i) => {
        return <SkeletonTile key={i} />;
      })}
    </div>
  );
};
