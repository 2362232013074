import React, { FunctionComponent } from 'react';

import { Portal } from '../Portal';
import { animateInStyle, animateOutStyle, toolTipStyle } from './styles';

export enum ToolTipDirection {
  Left = 'left',
  Right = 'right',
  Up = 'up',
  UpLeft = 'up_left',
  UpRight = 'up_right',
  Down = 'down',
  DownLeft = 'down_left',
  DownRight = 'down_right',
}

export type TriggerPosition = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
};

type TooltipContentProps = {
  /** Message to show in the tooltip. */
  message: React.ReactNode;
  /** Direction the tooltip will point, relative to the child element. Default is up and to the right. */
  direction?: ToolTipDirection;
  triggerPosition?: TriggerPosition;
  unmounting: boolean;
};

/** Wrap this component around any element that should show a tooltip when the mouse touches it. */
export const TooltipContent: FunctionComponent<TooltipContentProps> = ({
  message,
  direction = ToolTipDirection.UpRight,
  triggerPosition,
  unmounting,
}) => {
  if (!triggerPosition) return null;

  return (
    <Portal portalId={'tooltip'}>
      <span
        role="tooltip"
        css={[
          toolTipStyle(direction, triggerPosition),
          unmounting ? animateOutStyle(direction) : animateInStyle(direction),
        ]}
      >
        {message}
      </span>
    </Portal>
  );
};
