import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { colors } from '@lego/b2b-unicorn-ui-constants';

export const optionsContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  margin: 0,
  borderRadius: 6,
  backgroundColor: colors.white,
  boxShadow:
    '0px 8px 15px 5px rgb(0 0 0 / 10%), 8px 15px 15px 5px rgb(0 0 0 / 5%), -8px 15px 15px 5px rgb(0 0 0 / 5%)',
  zIndex: 20,
  position: 'absolute',
  maxHeight: 400,
  overflow: 'auto',
});

export const optionStyle = css({
  margin: 0,
  padding: '12px',
  transitionProperty: 'all',
  transitionDuration: '150ms',
  lineHeight: 1.3,
  scrollBehavior: 'smooth',
  fontSize: 'inherit',
  '&:hover, :focus': {
    backgroundColor: designToken.background.blue,
    outline: 'none',
  },
});

export const optionWithLinkStyle = css([
  optionStyle,
  {
    padding: 0,
    'a, span': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'inline-block',
      width: '100%',
      lineHeight: '20px',
      padding: '12px',
      cursor: 'default',
    },
    a: {
      cursor: 'pointer',
    },
  },
]);
