import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { OrderPromotionsOverviewItem } from '@lego/b2b-unicorn-data-access-layer/context/Promotions/PromotionsDataContext';
import { usePromotions } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  BodyWrapper,
  Container,
  EmptyFeedback,
  Paragraph,
  Section,
  SectionTitle,
  SkeletonListTile,
  TileGrid,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Banner } from '../../../shared/Components/Notifications';
import { AvailableForOrderPromotionTile } from './components/PromotionsOverview/AvailableForOrderPromotionTile';
import { NoLongerAvailableForOrderPromotionTile } from './components/PromotionsOverview/NoLongerAvailableForOrderPromotionTile';

const PromotionsOverview = () => {
  const {
    promotions_overview_available,
    promotions_overview_not_available,
    promotions_overview_no_available_promotions,
  } = useLabels();
  const { id: customerId } = useSelectedCustomer();
  const { endTransaction } = useApmTransaction();
  const { loading, data, error } = usePromotions(customerId);

  useEffect(() => {
    if (data || error) {
      endTransaction && endTransaction();
    }
  }, [data, endTransaction, error]);

  const availableForOrderPromotions = useMemo(
    () => data?.promotions.availableForOrderPromotions || [],
    [data]
  );
  const noLongerAvailableForOrderPromotions = useMemo(
    () => data?.promotions.noLongerAvailableForOrderPromotions || [],
    [data]
  );
  const history = useHistory();

  const handleOnTileClick = useCallback(
    (promotion: OrderPromotionsOverviewItem) => {
      history.push(`/promotion/${promotion.id}`);
    },
    [history]
  );

  const isLoading = loading || (data === undefined && !error);

  return (
    <BodyWrapper>
      <TopbarImplementation />
      <Banner />
      <Container padding={{ paddingBottom: baseSpacing * 15 }}>
        <Section main>
          <SectionTitle>{promotions_overview_available}</SectionTitle>

          {isLoading && (
            <TileGrid>
              <SkeletonListTile />
            </TileGrid>
          )}

          {!isLoading && (
            <TileGrid>
              {availableForOrderPromotions.map((promotion) => (
                <AvailableForOrderPromotionTile
                  key={promotion.id}
                  promotion={promotion}
                  onClick={handleOnTileClick}
                />
              ))}
            </TileGrid>
          )}

          {!isLoading && availableForOrderPromotions.length === 0 && (
            <EmptyFeedback type={'construction'}>
              <Paragraph>{promotions_overview_no_available_promotions}</Paragraph>
            </EmptyFeedback>
          )}
        </Section>
        {!isLoading && noLongerAvailableForOrderPromotions.length > 0 && (
          <Section>
            <SectionTitle>{promotions_overview_not_available}</SectionTitle>
            <TileGrid>
              {noLongerAvailableForOrderPromotions.map((promotion) => (
                <NoLongerAvailableForOrderPromotionTile
                  key={promotion.id}
                  promotion={promotion}
                  onClick={handleOnTileClick}
                />
              ))}
            </TileGrid>
          </Section>
        )}
      </Container>
    </BodyWrapper>
  );
};

export default PromotionsOverview;
