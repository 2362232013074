import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { tagStyle } from './styles';

interface IFilterTagProps {
  onClickHandler: (option: string, value: number | string) => void;
  option: string;
  value: number | string;
  text: string;
}

const FilterTag = (props: IFilterTagProps) => {
  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    if (e.code === KEYCODE_STRING.ENTER) {
      props.onClickHandler(props.option, props.value);
    }
  };

  return (
    <span
      onClick={() => props.onClickHandler(props.option, props.value)}
      onKeyDown={(e) => onKeyDownHandler(e)}
      aria-label={`Remove ${props.text} filter`}
      role="button"
      css={tagStyle}
      tabIndex={0}
    >
      {props.text}
      <Icon type={IconType.CLOSE} />
    </span>
  );
};

export default FilterTag;
