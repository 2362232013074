export const stringifyValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return stringifyArray(value);
  }

  return String(value);
};

export const stringifyArray = (value: Array<unknown>) => {
  return `[${String(value)}]`;
};

export const stringifyObjectValues = (object: object) => {
  const objectWithStringValues = {};
  for (const [key, value] of Object.entries(object)) {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(objectWithStringValues, {
        [key]: stringifyObjectValues(value),
      });
    } else {
      Object.assign(objectWithStringValues, {
        [key]: stringifyValue(value),
      });
    }
  }

  return objectWithStringValues;
};
