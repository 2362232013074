import { Fragment, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { css } from '@emotion/react';
import { font, baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import {
  RESULTS_LIMIT,
  CUSTOM_MIN_DROPDOWN_MEDIA,
  MIN_CHARS,
  SearchTranslationsContext,
} from './searchCommons';

const searchResultFeedback = (searchInput, numberOfResults) =>
  css({
    display: searchInput ? 'initial' : 'none',
    fontSize: font.size.small,
    padding: baseSpacing,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    pointerEvents: 'none',
    order: -1,
    minHeight: baseSpacing * 5,
    position: 'absolute',
    top: baseSpacing * 11,
    '> span': {
      color: colors.klik.slate500,
    },
    [CUSTOM_MIN_DROPDOWN_MEDIA]: {
      top: baseSpacing * 5,
      span: {
        display: searchInput ? 'initial' : 'none',
      },
    },
    left: 0,
    right: 0,
    backgroundColor: colors.white,
    borderBottomLeftRadius: numberOfResults ? 0 : 3,
    borderBottomRightRadius: numberOfResults ? 0 : 3,
  });

const SearchFeedback = ({ searchInput, loading, numberOfResults, error }) => {
  const {
    search_loading,
    search_showing_results,
    search_no_results,
    search_min_chars,
    search_empty_mobile_hint,
    search_error,
  } = useContext(SearchTranslationsContext);

  let feedbackLabel = '';

  if (!searchInput) {
    feedbackLabel = search_empty_mobile_hint;
  } else if (searchInput.length < MIN_CHARS) {
    feedbackLabel = search_min_chars;
  } else if (error) {
    feedbackLabel = search_error;
  } else if (loading) {
    feedbackLabel = search_loading;
  } else if (numberOfResults === 0) {
    feedbackLabel = (
      <Fragment>
        {search_no_results} <span css={{ fontWeight: font.weight.bold }}>{searchInput}</span>
      </Fragment>
    );
  } else {
    feedbackLabel = `${search_showing_results} ${Math.min(
      numberOfResults,
      RESULTS_LIMIT
    )}/${numberOfResults}`;
  }

  return (
    <div css={searchResultFeedback(searchInput, numberOfResults)}>
      <span id="searchProductsFeedback">{feedbackLabel}</span>
    </div>
  );
};

SearchFeedback.propTypes = {
  searchInput: PropTypes.string,
  loading: PropTypes.bool,
  numberOfResults: PropTypes.number,
  error: PropTypes.bool,
};

export default SearchFeedback;
