import { gql } from '@apollo/client';

export const FRAGMENT_CART_ITEM_WITH_OPEN_ORDER_INFO = gql`
  fragment cartItemWithOpenOrderInfo on CartItem {
    ...cartItem
    product {
      ... on Box {
        itemNumber
      }
      openOrderInfo {
        totalPendingQuantity
        shippingQuantity
        shippingDate
      }
    }
  }
`;
