import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

const wrapperStyle = css({
  position: 'relative',
  cursor: 'pointer',
  outline: 'none',

  '&:focus div': {
    display: 'block',
  },
});

interface IDialogWrapper {
  children: React.ReactNode;
  styles?: SerializedStyles;
}

const DialogWrapper = ({ children, styles }: IDialogWrapper) => {
  return (
    <span
      role="button"
      css={[wrapperStyle, styles]}
      tabIndex={0}
    >
      {children}
    </span>
  );
};

export default DialogWrapper;
