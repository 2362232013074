import { css } from '@emotion/react';
import { ContentstackAsset } from '@lego/b2b-unicorn-shared/components/ContentstackAsset';
import { ButtonType, designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { colors } from '@lego/b2b-unicorn-ui-constants';

import { infoListItemHoverStyle, infoListItemStyle } from '../../../styles/infoListStyles';

const linkStyle = (active: boolean) =>
  css(infoListItemStyle, {
    color: active ? designToken.interactive.default : colors.klik.slate300,
    cursor: active ? 'pointer' : 'default',
    textDecoration: 'none',
    '> svg': {
      fill: active ? designToken.interactive.default : colors.klik.slate300,
      height: 18,
      width: 18,
    },
  });

const InfoListLink = ({
  title,
  url,
  assetUid,
}: {
  title: string;
  url?: string | null;
  assetUid?: string | null;
}) => {
  if (!url) {
    return (
      <span css={linkStyle(false)}>
        <Icon type={IconType.LINK} />
        <span>{title}</span>
      </span>
    );
  } else if (assetUid && assetUid !== null) {
    return (
      <ContentstackAsset
        url={url}
        assetUid={assetUid}
        type={ButtonType.GHOST}
        size={'small'}
      >
        <>
          <Icon type={IconType.LINK} />
          {title}
        </>
      </ContentstackAsset>
    );
  } else {
    return (
      <a
        css={[linkStyle(true), infoListItemHoverStyle]}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon type={IconType.LINK} />
        <span>{title}</span>
      </a>
    );
  }
};

export default InfoListLink;
