import { logger } from '@lego/b2b-unicorn-shared/logger';
import React from 'react';

const fatalErrorLogger = logger.createLogger('FatalErrorBoundary');

export class FatalErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    fatalErrorLogger.fatal(error, { ...errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Something went wrong.</h1>
          <p>Please try to reload the page. If the problem persists, please contact our support.</p>
          <p>
            Session ID: <b>{window.__session_id__}</b>
          </p>
        </>
      );
    }

    return this.props.children;
  }
}
