import type {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  EventMessage,
  RedirectRequest,
} from '@azure/msal-browser';
import {
  BrowserAuthError,
  EventMessageUtils,
  InteractionStatus,
  Logger,
  PublicClientApplication,
  ServerError,
} from '@azure/msal-browser';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  filter,
  from,
  lastValueFrom,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';

export class Authentication {
  private static _instance: Authentication | undefined;
  private readonly _msalInstance: PublicClientApplication | null = null;
  private _inProgress: BehaviorSubject<InteractionStatus> = new BehaviorSubject<InteractionStatus>(
    InteractionStatus.Startup
  );
  private _config: ConstructorParameters<typeof PublicClientApplication>[0];
  private _logger: Logger;
  private _account: AccountInfo | null = null;

  public inProgressObs: Observable<InteractionStatus>;

  public static createInstance(config: ConstructorParameters<typeof PublicClientApplication>[0]) {
    this._instance = new Authentication(config);
  }

  public constructor(config: ConstructorParameters<typeof PublicClientApplication>[0]) {
    this._msalInstance = new PublicClientApplication(config);
    this._msalInstance.addEventCallback(this.handleEvents.bind(this));
    this.inProgressObs = this._inProgress.asObservable();
    this._config = config;
    this._logger = this._msalInstance.getLogger();
  }

  public static get instance() {
    if (!Authentication._instance) {
      throw new Error('Authentication instance not created');
    }

    return Authentication._instance;
  }

  public async initialize() {
    if (!this._msalInstance) {
      throw new Error('Authentication instance not created');
    }

    try {
      await this._msalInstance.initialize();
      const authResult = await this._msalInstance.handleRedirectPromise();
      await this.handleRedirectResponse(authResult);

      // Clear the retry flag if the initialization was successful
      sessionStorage.removeItem('authenticationRetry');
    } catch (error) {
      if (error instanceof ServerError) {
        const isRetry = sessionStorage.getItem('authenticationRetry');
        if (!isRetry) {
          this._logger.verbose('Retrying authentication initialization');

          sessionStorage.clear();
          localStorage.clear();

          // Set a flag to prevent an infinite loop of retries
          sessionStorage.setItem('authenticationRetry', 'true');

          window.location.reload();
        } else {
          throw error;
        }
      } else if (
        error instanceof BrowserAuthError &&
        error.errorCode === 'no_cached_authority_error'
      ) {
        const isRetry = sessionStorage.getItem('authenticationRetry');
        if (!isRetry) {
          this._logger.verbose('No cached authority, invoking interaction to resolve.');

          sessionStorage.clear();
          localStorage.clear();

          // Set a flag to prevent an infinite loop of retries
          sessionStorage.setItem('authenticationRetry', 'true');

          window.location.reload();
        }
      } else {
        throw error;
      }
    }
  }

  private handleEvents(message: EventMessage) {
    const status = EventMessageUtils.getInteractionStatusFromEvent(message, this._inProgress.value);
    if (status !== null) {
      this._inProgress.next(status);
    }
  }

  public async login(request?: RedirectRequest) {
    if (!this._msalInstance) {
      throw new Error('Authentication instance not created');
    }

    if (this._inProgress.value !== InteractionStatus.None) {
      return;
    }

    return this._msalInstance.loginRedirect(request);
  }

  public async logout(request?: EndSessionRequest) {
    if (!this._msalInstance) {
      throw new Error('Authentication instance not created');
    }

    if (this._inProgress.value !== InteractionStatus.None) {
      return;
    }

    return this._msalInstance.logoutRedirect(request);
  }

  private get redirectRequest(): RedirectRequest {
    return {
      scopes: ['openid', 'email'],
      authority: this._config.auth.authority,
      account: this.account ?? undefined,
      loginHint: this._account?.idTokenClaims?.preferred_username,
    };
  }

  private acquireToken(): Observable<AuthenticationResult> {
    if (!this._msalInstance) {
      throw new Error('Authentication instance not created');
    }

    return from(
      this._msalInstance.acquireTokenSilent({
        ...this.redirectRequest,
        redirectUri: `${this._config.auth.redirectUri}/blank.html`,
      })
    ).pipe(
      catchError(() => {
        this._logger.warning('Failed to acquire token silently, invoking interaction to resolve.');

        return this.inProgressObs.pipe(
          take(1),
          switchMap((status: InteractionStatus) => {
            if (status === InteractionStatus.None) {
              this._msalInstance?.acquireTokenRedirect(this.redirectRequest);

              return EMPTY;
            }

            return this.inProgressObs.pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None),
              take(1),
              switchMap(() => this.acquireToken())
            );
          })
        );
      }),
      switchMap((result: AuthenticationResult) => {
        if (!result.idToken) {
          this._logger.verbose(
            'acquireTokenSilent resolved with null id token. Known issue with B2C tenants, invoking interaction to resolve.'
          );

          return this.inProgressObs.pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            take(1),
            switchMap(() => {
              this._msalInstance?.acquireTokenRedirect(this.redirectRequest);

              return EMPTY;
            })
          );
        }

        return of(result);
      })
    );
  }

  public async getIdToken(): Promise<string> {
    if (!this._msalInstance) {
      throw new Error('Authentication instance not created');
    }

    // Before we acquire a token, we check if the current token is still valid
    if (this._account?.idTokenClaims?.exp) {
      const expiresAfter = this._account?.idTokenClaims.exp - 300; // We refresh the token 5 minutes before it expires
      if (expiresAfter > Date.now() / 1000) {
        return this._account.idToken!;
      }
    }

    const { idToken, account } = await lastValueFrom(this.acquireToken());
    this._account = account;

    return idToken;
  }

  public get account() {
    if (!this._msalInstance) {
      throw new Error('Authentication instance not created');
    }

    if (this._account) {
      return this._account;
    }

    const accounts = this._msalInstance.getAllAccounts();
    if (accounts === null) {
      this._logger.verbose('No accounts found');
      return null;
    }

    // During development, we switch between multiple environments, so we need to filter the accounts
    // to only use the ones from the known authorities
    const authorityAccounts = this._config.auth.knownAuthorities
      ? accounts.filter((account) =>
          this._config.auth.knownAuthorities?.includes(account.environment)
        )
      : accounts;
    if (authorityAccounts.length > 1) {
      this._logger.verbose('Multiple accounts found, using first one');
      return authorityAccounts[0];
    } else if (authorityAccounts.length === 1) {
      return authorityAccounts[0];
    }

    return null;
  }

  private async handleRedirectResponse(result: AuthenticationResult | null) {
    this._logger.trace('Handling redirect response', JSON.stringify(result));

    if (result) {
      this._account = result.account;
    } else {
      this._account = null;
    }
  }
}
