/**
 * Check if the given component is class based component
 * ex: class Component extends React.Component
 *
 * React internally uses this logic to check if it has to call new Component or Component
 * to decide between functional and class based components
 * https://github.com/facebook/react/blob/769b1f270e1251d9dbdce0fcbd9e92e502d059b8/packages/react-reconciler/src/ReactFiber.js#L297-L300
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isReactClassComponent(Component: any) {
  const prototype = Component.prototype;
  return !!(prototype && prototype.isReactComponent);
}
