import { gql } from '@apollo/client';

export const PRE_BOOTSTRAP = gql`
  query preBootstrap {
    getUser {
      email
      customers {
        id
        name
        city
        language
        country
        salesOrg
        locale
        currency
        paymentMethod
        showSimulationDetails
        availableLanguages {
          id
          code
        }
        parentC6Id
      }
      preferencesForCustomers {
        customerId
        preferredLanguage {
          id
          code
        }
      }
    }
  }
`;
