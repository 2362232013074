import { css } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { colors, ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';

const sortButtonStyle = (disabled: boolean) =>
  css({
    backgroundColor: 'rgba(100, 100, 100, 0)',
    border: 'none',
    cursor: disabled ? 'default' : 'pointer',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    height: 28,
    width: 28,
    borderRadius: '50%',
    transitionProperty: 'background-color',
    transitionDuration: '150ms',
    '> svg': {
      height: 24,
      width: 24,
      fill: colors.klik.slate400,
    },
    '&:focus, :hover': {
      backgroundColor: 'rgba(100, 100, 100, 0.1)',
    },
  });

const activeSortButtonStyle = (disabled: boolean) =>
  css(sortButtonStyle(disabled), {
    '> svg': { fill: designToken.interactive.default },
  });

interface ISortingButton {
  isActive: boolean;
  isAscending: boolean;
  columnName: ProductTableColumnType;
  onClickHandler: (columnName: ProductTableColumnType, isAscending: boolean) => void;
  disabled?: boolean;
}

const SortingButton = ({
  isActive,
  isAscending,
  columnName,
  onClickHandler,
  disabled = false,
}: ISortingButton) => {
  const renderActiveButton = () => {
    return (
      <button
        css={activeSortButtonStyle(disabled)}
        onClick={() => onClickHandler(columnName, !isAscending)}
        aria-label={isAscending ? 'Sorting descending.' : 'Sorting ascending.'}
        disabled={disabled}
      >
        <Icon type={isAscending ? IconType.SORT_DESC : IconType.SORT_ASC} />
      </button>
    );
  };

  const renderInactiveButton = () => {
    return (
      <button
        css={sortButtonStyle(disabled)}
        onClick={() => onClickHandler(columnName, true)}
        aria-label="No sorting applied."
        disabled={disabled}
      >
        <Icon type={IconType.SORT_DEFAULT} />
      </button>
    );
  };

  return isActive ? renderActiveButton() : renderInactiveButton();
};

export default SortingButton;
