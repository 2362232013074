import Cookies from 'js-cookie';

const getCookieByName = <T>(cookieName: string): T | undefined => {
  const cookie = Cookies.get(cookieName);

  if (cookie) {
    return JSON.parse(cookie) as T;
  }
};

export default getCookieByName;
