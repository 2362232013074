import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { TileHeader } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { localizedWindowName } from '../../../../../utils/localizedWindowName';

type LaunchWindowTileHeaderProps = {
  month: number;
  year: number;
  productsCount: number;
  isActive: boolean;
};

export const LaunchWindowTileHeader: React.FC<LaunchWindowTileHeaderProps> = ({
  month,
  year,
  productsCount,
  isActive,
}) => {
  const { products } = useLabels();
  const selectedCustomer = useSelectedCustomer();

  return (
    <TileHeader active={isActive}>
      <h1>{localizedWindowName(month, year, selectedCustomer.locale)}</h1>
      <span>
        {productsCount} {products}
      </span>
    </TileHeader>
  );
};
