import { css, keyframes } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import { Fragment } from 'react';

import { bigTextStyle, commonSubHeaderStyle, pagePaddingStyle } from '../../common/styles';
import { CheckoutStep } from '../../common/types';
import CheckoutSteps from '../CheckoutSteps';

const bodyStyle = css(pagePaddingStyle, {
  display: 'flex',
  flexDirection: 'column',
  '> div:first-of-type': {
    order: 2,
  },
  '> div:nth-of-type(2)': {
    order: 1,
  },
  '> div:nth-of-type(3)': {
    order: 3,
  },
  [media.medium]: {
    display: 'block',
  },
});

const summaryBackgroundStyle = css({
  backgroundColor: colors.klik.slate50,
  height: 210,
  margin: 0,
  borderRadius: 6,
  [media.small]: {
    height: 230,
  },
  [media.medium]: {
    height: 132,
  },
});

const pulse = keyframes`
  100% {transform: translateX(100%)};
`;

const formAnimationStyle = css({
  display: 'block',
  width: '100%',
  margin: 0,
  marginTop: 63,
  paddingBottom: baseSpacing * 2,
  borderBottomColor: colors.klik.slate100,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  [media.small]: {
    paddingLeft: baseSpacing * 4,
    paddingRight: baseSpacing * 4,
  },
  [media.medium]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 220,
    marginTop: 0,
  },
  position: 'relative',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    top: 0,
    width: '100%',
    height: '100%',
    transform: 'translateX(-100%)',
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${designToken.transparent.default} 30%, ${colors.white} 50%, ${designToken.transparent.default} 70%, ${designToken.transparent.default} 100%)`,
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    [media.medium]: {
      animationDuration: '1.8s',
    },
  },
});

const inputsContainerStyle = css({
  display: 'flex',
  width: '100%',
  margin: 0,
  flexDirection: 'column',
  flexWrap: 'nowrap',
  marginBottom: baseSpacing * 4,
  [media.medium]: {
    width: '25%',
  },
});

const subHeaderStyle = css(bigTextStyle, {
  paddingBottom: baseSpacing * 2,
  [media.medium]: {
    paddingBottom: baseSpacing * 3,
  },
});

const labelSpaceStyle = css({
  height: baseSpacing,
  [media.medium]: {
    height: 0,
  },
});

const inputStyle = css({
  height: 40,
  width: '100%',
  borderRadius: 6,
  paddingLeft: 10,
  paddingRight: 10,
  marginTop: 30,
  marginBottom: 0,
  backgroundColor: colors.klik.slate100,
});

const tableAnimationStyle = css({
  position: 'relative',
  overflow: 'hidden',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    top: 0,
    width: '100%',
    height: '100%',
    transform: 'translateX(-100%)',
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${designToken.transparent.default} 30%, ${colors.white} 50%,${designToken.transparent.default} 70%, ${designToken.transparent.default} 100%)`,
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    [media.medium]: {
      animationDuration: '1.8s',
    },
  },
});

const tableHeaderStyle = css({
  width: '100%',
  height: 65,
  backgroundColor: colors.klik.slate100,
  borderBottomWidth: 2,
  borderBottomColor: colors.white,
  borderBottomStyle: 'solid',
  [media.medium]: {
    height: 50,
  },
});

const rowsContainerStyle = css({
  paddingTop: baseSpacing,
  [media.medium]: {
    display: 'contents',
  },
});

const productRowStyle = css({
  width: '50%',
  marginLeft: '20%',
  marginTop: 10,
  marginBottom: 10,
  height: baseSpacing * 2,
  backgroundColor: colors.klik.slate50,

  [media.medium]: {
    width: '100%',
    height: 57,
    margin: 0,
    borderBottomWidth: 2,
    borderBottomColor: colors.white,
    borderBottomStyle: 'solid',
  },
});

const secondaryTableStyle = css({
  width: '100%',
  display: 'table',
  marginBottom: baseSpacing * 4,
  paddingLeft: 0,
  paddingRight: 0,
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    [media.medium]: {
      zIndex: 10,
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '100vw',
      height: 335,
      top: 0,

      background: `linear-gradient(${designToken.transparent.default} 0%, ${colors.white} 80%, ${colors.white} 100%)`,
    },
  },

  [media.small]: {
    paddingLeft: baseSpacing * 4,
    paddingRight: baseSpacing * 4,
  },
  [media.medium]: {
    padding: 0,
  },
});

const backlinkSpaceStyle = css({
  [media.medium]: {
    height: 80,
  },
});

const SkeletonCheckoutPage = () => {
  const { order_delivery_information, order_payment, order_name, order_details } = useLabels();

  return (
    <Fragment>
      <CheckoutSteps checkoutStep={CheckoutStep.CHECKOUT} />
      <div css={bodyStyle}>
        <div css={summaryBackgroundStyle} />
        <div>
          <div css={backlinkSpaceStyle} />
          <div css={formAnimationStyle}>
            <div css={inputsContainerStyle}>
              <div css={subHeaderStyle}>{order_delivery_information}</div>
              <div css={labelSpaceStyle} />
              <div css={inputStyle} />
              <div css={labelSpaceStyle} />
              <div css={inputStyle} />
            </div>
            <div css={inputsContainerStyle}>
              <div css={subHeaderStyle}>{order_payment}</div>
              <div css={inputStyle} />
            </div>
            <div css={inputsContainerStyle}>
              <div css={subHeaderStyle}>{order_name}</div>
              <div css={inputStyle} />
            </div>
          </div>
        </div>
        <div>
          <div css={backlinkSpaceStyle} />
          <div css={commonSubHeaderStyle}>{order_details}</div>
          <div css={tableAnimationStyle}>
            <div css={secondaryTableStyle}>
              <div css={tableHeaderStyle} />
              <div css={rowsContainerStyle}>
                {[...Array(5)].map((_, i) => {
                  return (
                    <div
                      css={productRowStyle}
                      key={i}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SkeletonCheckoutPage;
