import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import {
  Icon,
  IconType,
  Tag,
  TagType,
  ToolTip,
  ToolTipDirection,
} from '@lego/b2b-unicorn-shared/ui';
import { font } from '@lego/b2b-unicorn-ui-constants';

interface ITranslations {
  open_order_dialog_header: string;
  delivery_date_expected_next: string;
  total_pending: string;
  pcs: string;
  pcs_by: string;
}

type OpenOrderIndicatorProps = {
  isSmall?: boolean;
  direction?: ToolTipDirection;
  shippingDate?: string | null;
  shippingQuantity?: number | null;
  totalPendingQuantity?: number | null;
  translations?: ITranslations;
  locale?: string;
  onClick?: () => void;
};

export const OpenOrderIndicator = (props: OpenOrderIndicatorProps) => {
  const {
    isSmall = false,
    direction = ToolTipDirection.UpLeft,
    shippingDate,
    shippingQuantity,
    totalPendingQuantity,
    locale,
    onClick,
    translations: {
      open_order_dialog_header,
      delivery_date_expected_next,
      total_pending,
      pcs,
      pcs_by,
    } = {},
  } = props;

  const hasAllTranslations =
    !!open_order_dialog_header &&
    !!delivery_date_expected_next &&
    !!total_pending &&
    !!pcs &&
    !!pcs_by;
  const hasRequiredPopupData = !!totalPendingQuantity && !!locale && hasAllTranslations;
  const confirmedUpcomingDelivery = !!shippingDate && !!shippingQuantity;

  return (
    <ToolTip
      direction={direction}
      message={
        hasRequiredPopupData ? (
          <>
            <span css={{ fontWeight: font.weight.bold }}>{open_order_dialog_header}</span>
            {confirmedUpcomingDelivery && (
              <p>
                <span css={{ fontSize: font.size.tiny }}>{delivery_date_expected_next}</span>
                <br />
                {locale && (
                  <span>
                    {shippingQuantity} {pcs_by} <Date locale={locale}>{shippingDate}</Date>
                  </span>
                )}
              </p>
            )}
            <p>
              <span css={{ fontSize: font.size.tiny }}>{total_pending}</span>
              <br />
              <span>
                {totalPendingQuantity} {pcs}
              </span>
            </p>
          </>
        ) : undefined
      }
    >
      <span
        tabIndex={0}
        role="button"
        onClick={onClick}
      >
        <Tag
          type={TagType.BLUE}
          small={isSmall}
        >
          <Icon type={IconType.TRUCK} />
        </Tag>
      </span>
    </ToolTip>
  );
};
