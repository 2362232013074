import { filetypemime } from 'magic-bytes.js';

export const getFileType = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (f) => {
      if (!f.target?.result) {
        throw new Error('Failed to read file');
      }

      const bytes = new Uint8Array(f.target.result.slice(0, 50) as ArrayBuffer);
      const mostLikelyType = filetypemime(bytes).shift();

      resolve(mostLikelyType ?? file.type);
    };
    fileReader.onerror = () => {
      reject(new Error('Failed to determine file type'));
    };

    fileReader.readAsArrayBuffer(file);
  });
};
