import { baseSpacing } from '../theme';
import { ToolTipDirection, TriggerPosition } from './TooltipContent';

interface IToolTipPosition {
  left: string;
  top: string;
}
type ToolTipPosition = {
  top: number | string;
  left: number | string;
};

export const getTooltipPosition = (
  direction: ToolTipDirection,
  triggerPosition: TriggerPosition
) => {
  const position: ToolTipPosition = {
    left: direction.includes('_right')
      ? triggerPosition.left + triggerPosition.width / 2
      : triggerPosition.right - triggerPosition.width / 2,
    top: direction.includes('down')
      ? triggerPosition.top + triggerPosition.height + baseSpacing * 1.5
      : triggerPosition.top - baseSpacing * 1.5,
  };
  if (direction === ToolTipDirection.Left) {
    position.left = triggerPosition.left - baseSpacing * 1.5;
    position.top = triggerPosition.top + triggerPosition.height / 2;
  }
  if (direction === ToolTipDirection.Right) {
    position.top = triggerPosition.top + triggerPosition.height / 2;
    position.left = triggerPosition.right + baseSpacing * 1.5;
  }
  if (direction === ToolTipDirection.Up || direction === ToolTipDirection.Down) {
    position.left = triggerPosition.left + triggerPosition.width / 2;
  }
  return position;
};

export const getTransformFromDirection = (direction: ToolTipDirection) => {
  let xTranslation = '0';
  let yTranslation = '0';
  if (direction.includes('up')) {
    yTranslation = 'calc(-100% + 12px)';
  }
  if (direction.includes('down')) {
    yTranslation = 'calc(-12px)';
  }
  if (direction.includes('left')) {
    xTranslation = 'calc(-100% + 24px)';
  }
  if (direction.includes('_right')) {
    xTranslation = '-24px';
  }
  if (direction === ToolTipDirection.Left || direction === ToolTipDirection.Right) {
    yTranslation = '-50%';
  }
  if (direction === ToolTipDirection.Up || direction === ToolTipDirection.Down) {
    xTranslation = '-50%';
  }
  if (direction === ToolTipDirection.Right) {
    xTranslation = '-24px';
  }
  return `translate(${xTranslation}, ${yTranslation})`;
};

export const getHoveredTransformFromDirection = (direction: ToolTipDirection) => {
  let xTranslation = '0';
  let yTranslation = '0';
  if (direction.includes('up')) {
    yTranslation = '-100%';
  }
  if (direction.includes('_left')) {
    xTranslation = 'calc(-100% + 24px)';
  }
  if (direction.includes('_right')) {
    xTranslation = '-24px';
  }
  if (direction === ToolTipDirection.Left || direction === ToolTipDirection.Right) {
    yTranslation = '-50%';
  }
  if (direction === ToolTipDirection.Up || direction === ToolTipDirection.Down) {
    xTranslation = '-50%';
  }
  if (direction === ToolTipDirection.Left) {
    xTranslation = '-100%';
  }

  return `translate(${xTranslation}, ${yTranslation})`;
};

export const getTooltipArrowPosition = (direction: ToolTipDirection) => {
  const position: IToolTipPosition = {
    left: direction === ToolTipDirection.Left ? '100%' : '0%',
    top: direction.includes('up') ? '100%' : '0%',
  };
  if (direction === ToolTipDirection.Left || direction === ToolTipDirection.Right) {
    position.top = '50%';
  }
  if (direction === ToolTipDirection.Up || direction === ToolTipDirection.Down) {
    position.left = '50%';
  }
  if (direction.includes('_left')) {
    position.left = 'calc(100% - 24px)';
  }
  if (direction.includes('_right')) {
    position.left = '24px';
  }
  return position;
};

export const getArrowTransformFromDirection = (direction: ToolTipDirection) => {
  let skewAngle = 10;
  let moveLeft = '-50%';
  let moveUp = '-50%';
  if (direction === ToolTipDirection.Left) {
    moveLeft = 'calc(-50% - 4px)';
    skewAngle = -10;
  }
  if (direction === ToolTipDirection.Right) {
    moveLeft = 'calc(-50% + 4px)';
    skewAngle = -10;
  }
  if (direction.includes('up')) {
    moveUp = 'calc(-50% - 4px)';
  }
  if (direction.includes('down')) {
    moveUp = 'calc(-50% + 4px)';
  }
  return `translate(${moveLeft}, ${moveUp}) rotate(45deg) skew(${skewAngle}deg, ${skewAngle}deg)`;
};
