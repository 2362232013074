import { css } from '@emotion/react';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import React, { useRef, useState } from 'react';

import { optionsContainerStyle, optionStyle } from '../../styles/selectoptions';
import SearchField, { ISearchFieldBaseProps } from '../SearchField/SearchField';

const resultStyle = css([
  optionStyle,
  {
    all: 'unset',
    display: 'block',
    padding: baseSpacing * 1.5,
    cursor: 'pointer',
  },
]);

const messageStyle = css([
  resultStyle,
  {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
]);

export interface ILiveSearchResult {
  /** What to display in the live search result list. */
  displayElement: string;
  /** Function to call when clicking / selecting the result. */
  onSelectFunction?: () => void;
}

interface ILiveSearchFieldProps extends ISearchFieldBaseProps {
  /** Function will be called after [delaySeconds] seconds. Should return a promise with an array of results. */
  liveSearchFunction: (value: string) => Promise<ILiveSearchResult[]>;
  /** UI labels */
  labels: {
    search_loading: string;
    search_min_chars: string;
    search_no_results: string;
  };
  /** Number of seconds to wait after keyup to call 'searchFunction'. Defaults to 1 second. */
  delaySeconds?: number;
  /** 'liveSearchFunction' will not be called before the user has entered at least this many characters. Defaults to 3. */
  minimumCharacters?: number;
}

const LiveSearchField = ({
  placeholder,
  searchValueSubmitHandler,
  clearAfterSubmit,
  liveSearchFunction,
  labels,
  delaySeconds = 1,
  minimumCharacters = 3,
}: ILiveSearchFieldProps) => {
  const [resultItems, setResultItems] = useState<ILiveSearchResult[]>([]);
  const [message, setMessage] = useState('');
  const [query, setQuery] = useState('');

  const debounceRef = useRef<number>();
  const firstResultRef = useRef<HTMLButtonElement>(null);

  const noResultsMessage = (searchString: string) => [
    { displayElement: `${labels.search_no_results} '${searchString}'` },
  ];

  const handleLiveSearch = async (value: string, keyEvent?: React.KeyboardEvent) => {
    const ignoreKeyPress =
      keyEvent?.code === KEYCODE_STRING.ARROW_DOWN || keyEvent?.code === KEYCODE_STRING.ENTER;

    setQuery(value);

    if (value.length >= minimumCharacters) {
      if (!ignoreKeyPress) {
        // Avoid triggering unnecessary searches
        setResultItems([]);
        setMessage(labels.search_loading);
        const result = await debounceSearchFunction(value);
        setResultItems(result);
        setMessage('');
      }
    } else if (value.length > 0) {
      setResultItems([]);
      setMessage(labels.search_min_chars);
    } else {
      setResultItems([]);
      setMessage('');
    }

    /** Arrow down moves focus to first result: */
    if (keyEvent?.code === KEYCODE_STRING.ARROW_DOWN && resultItems.length > 0) {
      firstResultRef.current?.focus();
    }
  };

  const debounceSearchFunction = async (value: string): Promise<ILiveSearchResult[]> => {
    const delay = delaySeconds * 1000;
    window.clearTimeout(debounceRef.current);
    return new Promise((resolve) => {
      debounceRef.current = window.setTimeout(async () => {
        const result = await liveSearchFunction(value);
        if (result.length > 0) {
          resolve(result);
        } else {
          resolve(noResultsMessage(value));
        }
      }, delay);
    });
  };

  const handleLiveResultClick = (item: ILiveSearchResult) => {
    if (item.onSelectFunction) {
      item.onSelectFunction();
    }
    resetLiveSearch();
  };

  const handleLiveResultKeyDown = (e: React.KeyboardEvent) => {
    const currentActive = document.activeElement;
    if (e.key === KEYCODE_STRING.ARROW_DOWN) {
      (currentActive?.nextElementSibling as HTMLButtonElement)?.focus();
      e.preventDefault();
    }
    if (e.key === KEYCODE_STRING.ARROW_UP) {
      (currentActive?.previousElementSibling as HTMLButtonElement)?.focus();
      e.preventDefault();
    }
  };

  const handleSubmitFromSearchField = (value: string) => {
    searchValueSubmitHandler(value);
    resetLiveSearch();
  };

  const resetLiveSearch = () => {
    window.clearTimeout(debounceRef.current);
    handleLiveSearch('');
  };

  const containerBlurHandler = (e: React.FocusEvent<HTMLDivElement>) => {
    /**
     * When container loses focus, e.g. by clicking outside, close the dropdown.
     * UNLESS it loses focus to one of its children. */
    if (!e.currentTarget.contains(e.relatedTarget)) {
      resetLiveSearch();
    }
  };

  const containerKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KEYCODE_STRING.ESCAPE) {
      resetLiveSearch();
    }
  };

  return (
    <div
      onBlur={containerBlurHandler}
      onKeyDown={containerKeyDownHandler}
      role="listbox"
      tabIndex={-1}
    >
      <SearchField
        placeholder={placeholder}
        searchValueUpdateHandler={handleLiveSearch}
        searchValueSubmitHandler={handleSubmitFromSearchField}
        clearAfterSubmit={clearAfterSubmit}
        initValue={query}
      />
      {(resultItems.length > 0 || message) && (
        <div css={optionsContainerStyle}>
          {message && (
            <button
              css={messageStyle}
              tabIndex={-1}
            >
              {message}
            </button>
          )}
          {resultItems.map((item, idx) => {
            return (
              <button
                css={resultStyle}
                key={`live-search-${idx}`}
                onClick={() => {
                  handleLiveResultClick(item);
                }}
                ref={idx === 0 ? firstResultRef : null}
                onKeyDown={handleLiveResultKeyDown}
                tabIndex={-1}
              >
                {item.displayElement}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LiveSearchField;
