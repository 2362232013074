import type { Customer, DataAccessLayer, User } from '@lego/b2b-unicorn-data-access-layer';
import { Logger } from '@lego/b2b-unicorn-shared/logger';
import { nanoid } from 'nanoid';
import type { Subscription } from 'zen-observable-ts';

type UserServiceListener<T> = (payload: T) => void;
type EventName = 'user-loading' | 'user-loaded' | 'user-updated' | 'customer-selected';

export class UserService {
  private _listeners: {
    [key in EventName]: { [key: string]: UserServiceListener<unknown> };
  } = {
    'user-loading': {},
    'user-loaded': {},
    'user-updated': {},
    'customer-selected': {},
  };
  private _dataAccessLayer: DataAccessLayer;
  private _loadedUser: User | null = null;
  private _observableSubscription: Subscription | null = null;
  private _selectedCustomer: Customer | null = null;
  private _selectedCustomerLocalStorageKey = 'selected-customer-id';
  private readonly _logger?: Logger;
  private static instance: UserService | null = null;

  constructor(dataAccessLayerClient: DataAccessLayer, logger?: Logger) {
    this._dataAccessLayer = dataAccessLayerClient;
    this._logger = logger;
  }

  public static createInstance(dataAccessLayerClient: DataAccessLayer, logger?: Logger) {
    if (UserService.instance) {
      logger?.warning(`Recreating UserService`);
      UserService.instance.destroy();
    }

    UserService.instance = new UserService(dataAccessLayerClient, logger);

    return UserService.instance;
  }

  public static getInstance() {
    if (!UserService.instance) {
      throw Error(`No instance created.`);
    }

    return UserService.instance;
  }

  public addEventListener(
    event: 'user-loading',
    eventListener: UserServiceListener<boolean>
  ): string;
  public addEventListener(event: 'user-updated', eventListener: UserServiceListener<User>): string;
  public addEventListener(event: 'user-loaded', eventListener: UserServiceListener<User>): string;
  public addEventListener(
    event: 'customer-selected',
    eventListener: UserServiceListener<Customer>
  ): string;
  public addEventListener(event: EventName, eventListener: UserServiceListener<never>) {
    const listenerId = nanoid();
    Object.assign(this._listeners[event], {
      [`${listenerId}`]: eventListener,
    });

    return listenerId;
  }

  public removeListener(event: EventName, listenerId: string) {
    delete this._listeners[event][`${listenerId}`];
  }

  public destroy() {
    this._observableSubscription?.unsubscribe();
  }

  public get user() {
    if (!this._observableSubscription) {
      this.createSubscription();
    }
    this._logger?.info(`Getting user from cache`);
    return this._loadedUser;
  }

  public setUser(user: User) {
    this._logger?.info(`Setting user in cache`);
    this._loadedUser = user;

    return this._loadedUser;
  }

  public get customers() {
    if (!this._loadedUser) {
      this._logger?.info(`No customers in cache, as there is no user loaded`);
      return null;
    }

    this._logger?.info(`Getting customers from cache`);
    return this._loadedUser.customers;
  }

  public get selectedCustomer() {
    let customerSelectedEventListeners = false;

    if (!this._selectedCustomer && this.customers) {
      this._logger?.info(`No customer selected, trying to select customer from localstorage`);
      const customerId = parseInt(
        localStorage.getItem(this._selectedCustomerLocalStorageKey) || '0',
        10
      );
      if (customerId) {
        this._logger?.info(`Selecting customer from localstorage`);
        this._selectedCustomer = this.customers?.find((c) => c.id === customerId) || null;
        if (this._selectedCustomer) {
          customerSelectedEventListeners = true;
        }
      }
    }

    if (!this._selectedCustomer && this.customers?.length === 1) {
      this._logger?.info(
        `No customer selected and only one customer on user, selecting that one customer`
      );
      this._selectedCustomer = this.customers[0];
      customerSelectedEventListeners = true;
    }

    if (this._selectedCustomer) {
      this._logger?.info(`Getting selected customer from cache`);

      if (customerSelectedEventListeners) {
        this.callListeners('customer-selected', this._selectedCustomer);
      }
    }

    return this._selectedCustomer;
  }

  public get selectedCustomerPreferences() {
    if (!this._loadedUser || !this.selectedCustomer) {
      return null;
    }

    return (
      this._loadedUser.preferencesForCustomers?.find(
        (preference) => preference.customerId === this.selectedCustomer?.id
      ) || null
    );
  }

  public selectCustomer(customer: Customer) {
    if (!this._loadedUser) {
      throw Error(`Failed to select customer, no user loaded`);
    }

    if (this._loadedUser.customers.find((c) => c.id === customer.id) === undefined) {
      throw Error(`Requested customer, ${customer.id}, does not exist on loaded user`);
    }

    localStorage.setItem(this._selectedCustomerLocalStorageKey, customer.id.toString());
    this._selectedCustomer = customer;
    this.callListeners('customer-selected', customer);
  }

  public callListeners(event: EventName, payload: User | Customer | boolean): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_listenerId, listener] of Object.entries(this._listeners[event])) {
      listener(payload);
    }
  }

  private createSubscription() {
    const logger = this._logger;

    this._observableSubscription = this._dataAccessLayer.bootstrap
      .preBootstrap()
      .subscribe((queryResult) => {
        if (queryResult.loading) {
          logger?.info(`Loading user`);
        }
        const userService = UserService.getInstance();
        userService.callListeners('user-loading', queryResult.loading);
        if (queryResult.data.getUser) {
          if (!userService._loadedUser) {
            userService.setUser(queryResult.data.getUser);
            logger?.info(`User loaded`);
            userService.callListeners('user-loaded', queryResult.data.getUser);
          } else {
            userService.setUser(queryResult.data.getUser);
            logger?.info(`User updated`);
            userService.callListeners('user-updated', queryResult.data.getUser);
          }
        }
      });
  }
}
