import { gql } from '@apollo/client';

export const UPDATE_LAUNCH_WINDOW_WITH_MULTIPLE_ITEMS = gql`
  mutation updateLaunchWindowWithMultipleItems(
    $customerId: Int!
    $items: [UpdateCartItemInput!]!
    $year: Int!
    $month: Int!
  ) {
    updateCartWithMultipleItems(
      customerId: $customerId
      items: $items
      cartReference: { cartType: LAUNCH, launch: { year: $year, month: $month } }
    ) {
      items {
        ...minimumCartItem
      }
      ...cartKey
    }
  }
`;
