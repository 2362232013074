import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartItemInputWithProduct } from '@lego/b2b-unicorn-data-access-layer';
import {
  useReplenishmentProducts,
  useReplenishmentUpdateCartWithMultipleItems,
} from '@lego/b2b-unicorn-data-access-layer/react';
import {
  BulkQuickAddContext,
  FinalRowItem,
  RowStatusReason,
} from '@lego/b2b-unicorn-shared/components/BulkQuickAdd/types';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { pasteEventListener } from '../../../shared/Utils/functions/pasteEventListener';
import { BaseBulkQuickAdd } from './BaseBulkQuickAdd';

const replenishmentConsumerPasteEventListener = (event: HTMLElementEventMap['paste']) =>
  pasteEventListener(event, BulkQuickAddContext.REPLENISHMENT);

type ReplenishBulkQuickAddConsumerProps = {
  onClose: () => void;
  open: boolean;
  onHasPreviousSession: () => void;
};

export const ReplenishBulkQuickAddConsumer: React.FC<ReplenishBulkQuickAddConsumerProps> = ({
  onClose,
  open,
  onHasPreviousSession,
}) => {
  const { id: customerId } = useSelectedCustomer();
  const storageKey = `bulkQuickAdd-${customerId}`;
  const { trackEvent } = useAnalytics();

  // Internal state
  const [items, setItems] = useState<FinalRowItem[]>([]);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { button_cancel, button_add_to_cart, replenish_bulk_quick_add_modal_header } = useLabels();

  const {
    refetch: loadProducts,
    data: productsResponse,
    loading: productsLoading,
  } = useReplenishmentProducts(customerId, undefined, true, {
    fetchPolicy: 'cache-first',
  });
  const {
    mutate: addItemsToCart,
    reset: addToCartReset,
    error: submittingError,
  } = useReplenishmentUpdateCartWithMultipleItems(customerId, true);

  const handleOnAddToCartClick = () => {
    setIsLoading(true);
    trackEvent({
      event: 'addToCartFromBulkQuickAddReplenishment',
      name: `Adding ${validItems.length} items to cart`,
      value: validItems.length,
    });
    addItemsToCart({
      items: validItems,
    })
      .then(() => {
        sessionStorage.removeItem(storageKey);
        history.push('/checkout');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(productsLoading);
  }, [productsLoading]);

  useEffect(() => {
    if (submittingError) {
      addButtonRef.current?.blur();
    }
  }, [submittingError]);

  useEffect(() => {
    if (open) {
      document.addEventListener('paste', replenishmentConsumerPasteEventListener);
      loadProducts({ customerId });
    }

    return () => {
      document.removeEventListener('paste', replenishmentConsumerPasteEventListener);
    };
  }, [open, loadProducts, customerId]);

  const validItems = useMemo<CartItemInputWithProduct[]>(() => {
    return items.reduce<CartItemInputWithProduct[]>((result, item) => {
      if (!item.rowStatusReason || !item.product || item.quantity < 1) {
        return result;
      }

      result.push({
        product: item.product,
        quantity: item.quantity,
      });

      return result;
    }, []);
  }, [items]);

  const handleOnCancelClick = useCallback(() => {
    sessionStorage.removeItem(storageKey);
    addToCartReset();
    onClose();
  }, [addToCartReset, onClose, storageKey]);

  const customerCatalog = productsResponse?.products.products || [];
  const isAddToCartDisabled =
    validItems.length === 0 ||
    items.some((item) => item.rowStatusReason !== RowStatusReason.Valid || item.quantity < 1);

  return (
    <BaseBulkQuickAdd
      context={BulkQuickAddContext.REPLENISHMENT}
      sessionStorageKey={storageKey}
      onHasPreviousSession={onHasPreviousSession}
      open={open}
      products={customerCatalog}
      modalHeaderContent={replenish_bulk_quick_add_modal_header}
      isLoading={productsLoading || isLoading}
      submittingError={!!submittingError}
      addToCartDisabled={isAddToCartDisabled}
      onItemsChange={setItems}
    >
      <Button
        type={ButtonType.SECONDARY}
        onClick={handleOnCancelClick}
      >
        {button_cancel}
      </Button>
      <Button
        ref={addButtonRef}
        onClick={handleOnAddToCartClick}
        disabled={isAddToCartDisabled}
        warning={!!submittingError}
      >
        {button_add_to_cart}
      </Button>
    </BaseBulkQuickAdd>
  );
};
