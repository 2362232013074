import { ReplenishmentProductsQuery_products_ProductList_products_BillOfMaterial } from '../../../generated-types/graphql';
import { Lifecycle, ProductTag } from '../../../generated-types/types';
import { BillOfMaterial } from '../../../type-policies/ProductPolicy/entities';

export class ReplenishmentProductsBillOfMaterial
  extends BillOfMaterial
  implements ReplenishmentProductsQuery_products_ProductList_products_BillOfMaterial
{
  ean: string;
  name?: string | null;
  theme: string;
  materialId: number;
  piecesPerCasePack: number;
  tags: ProductTag[];
  lifecycles: Lifecycle[];
  price: ReplenishmentProductsQuery_products_ProductList_products_BillOfMaterial['price'];
  stockStatus: ReplenishmentProductsQuery_products_ProductList_products_BillOfMaterial['stockStatus'];
  openOrderInfo?: ReplenishmentProductsQuery_products_ProductList_products_BillOfMaterial['openOrderInfo'];

  constructor(
    billOfMaterial: ReplenishmentProductsQuery_products_ProductList_products_BillOfMaterial
  ) {
    super();
    this.ean = billOfMaterial.ean;
    this.name = billOfMaterial.name;
    this.theme = billOfMaterial.theme;
    this.materialId = billOfMaterial.materialId;
    this.piecesPerCasePack = billOfMaterial.piecesPerCasePack;
    this.tags = billOfMaterial.tags;
    this.lifecycles = billOfMaterial.lifecycles;
    this.price = billOfMaterial.price;
    this.stockStatus = billOfMaterial.stockStatus;
    this.openOrderInfo = billOfMaterial.openOrderInfo;
  }
}
