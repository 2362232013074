import { nanoid } from 'nanoid';

class GlobalLoadingService {
  private static _instance: GlobalLoadingService | null = null;
  private _queue: Set<string> = new Set<string>();
  /*
   * The dom element's id which the loader lives in.
   */
  private _domElementId = 'bootloader';
  private _loaderVisibleClass = 'visible';
  /*
   * Holds the setTimeout id that will remove the loader.
   */
  private _removeTimeoutId: number = 0;
  /*
   * Once the queue reaches size 0, the loader will disappear after this timeout.
   * This is to prevent flickering if there is another loader added to the queue, right after the last one is removed.
   */
  private _hideTimeout = 150;

  public static getInstance() {
    if (!GlobalLoadingService._instance) {
      GlobalLoadingService._instance = new GlobalLoadingService();
    }

    // For debugging purposes the current loading queue is appended to window
    if (process.env['NODE_ENV'] === 'development') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).loaderQueue = GlobalLoadingService._instance._queue;
    }

    return GlobalLoadingService._instance;
  }

  public add(prefix?: string) {
    if (this._removeTimeoutId) {
      window.clearTimeout(this._removeTimeoutId);
      this._removeTimeoutId = 0;
    }

    const loaderId = prefix ? `${prefix}${nanoid()}` : nanoid();
    this._queue.add(loaderId);
    this.showLoader();

    return loaderId;
  }

  public remove(loaderId: string) {
    this._queue.delete(loaderId);

    if (this._queue.size === 0) {
      this._removeTimeoutId = window.setTimeout(() => {
        const domElement = document.getElementById(this._domElementId);
        if (!domElement) {
          return;
        }

        if (domElement.classList.contains(this._loaderVisibleClass)) {
          domElement.classList.remove(this._loaderVisibleClass);
        }
      }, this._hideTimeout);
    }
  }

  private showLoader() {
    const domElement = document.getElementById(this._domElementId);
    if (!domElement) {
      return;
    }
    if (!domElement.classList.contains(this._loaderVisibleClass)) {
      domElement.classList.add(this._loaderVisibleClass);
    }
  }
}

const instance = GlobalLoadingService.getInstance();

// Exports an instance of the GlobalLoadingService directly as there is no need for multiple instances of this
export { instance as GlobalLoadingService };
