import { CartReferenceInput } from '@lego/b2b-unicorn-data-access-layer';

export const createCheckoutCookieName = (customerId: number, cartReference: CartReferenceInput) => {
  let cookieName = `checkout-${customerId}-${cartReference.cartType}`;
  if (cartReference.launch) {
    cookieName += `-${cartReference.launch.year}-${cartReference.launch.month}`;
  }

  return cookieName;
};
