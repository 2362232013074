import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { BootstrapDataContext } from '../../context/Bootstrap/BootstrapDataContext';
import { useDataAccessLayer } from '../DataAccessLayerProvider';

export const usePreBootstrap = () => {
  const dataAccessLayer = useDataAccessLayer();
  const queryResult = useMemo(() => {
    return dataAccessLayer.bootstrap.preBootstrap();
  }, [dataAccessLayer.bootstrap]);

  return useQuery(queryResult.query, {
    client: dataAccessLayer.apolloClient,
    notifyOnNetworkStatusChange: true,
  });
};

type TermsOfSalesAcceptanceStateParameters = Parameters<
  typeof BootstrapDataContext.prototype.getTermsOfSalesAcceptanceState
>;
export const useTermsOfSalesAcceptanceState = (
  ...parameters: TermsOfSalesAcceptanceStateParameters
) => {
  const dataAccessLayer = useDataAccessLayer();
  const queryResult = useMemo(() => {
    return dataAccessLayer.bootstrap.getTermsOfSalesAcceptanceState(parameters[0]);
  }, [dataAccessLayer.bootstrap, parameters]);

  return useQuery(queryResult.query, {
    variables: queryResult.variables,
    notifyOnNetworkStatusChange: true,
    client: dataAccessLayer.apolloClient,
    fetchPolicy: 'network-only',
  });
};

type TermsOfSalesParameters = Parameters<typeof BootstrapDataContext.prototype.getTermsOfSales>;
export const useTermsOfSalesContent = (...parameters: TermsOfSalesParameters) => {
  const dataAccessLayer = useDataAccessLayer();
  const queryResult = useMemo(() => {
    return dataAccessLayer.bootstrap.getTermsOfSales(parameters[0]);
  }, [dataAccessLayer.bootstrap, parameters]);

  return useQuery(queryResult.query, {
    variables: queryResult.variables,
    notifyOnNetworkStatusChange: true,
    client: dataAccessLayer.apolloClient,
    fetchPolicy: 'no-cache',
  });
};
