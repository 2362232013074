/* eslint-disable @typescript-eslint/ban-ts-comment,@typescript-eslint/no-explicit-any */

import type { DataProxy } from '@apollo/client/cache/core/types/DataProxy';
import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';
import { mergeDeep } from '@apollo/client/utilities';
import type { AsStoreObject } from '@apollo/client/utilities/graphql/storeUtils';

import {
  CartWithOpenOrderInfoQuery,
  OptimisticCartQuery,
  OptimisticUpdateCartWithMultipleItemsMutationVariables,
} from '../../generated-types/graphql';
import { Cart, CartReferenceCartType } from '../../generated-types/types';
import { exhaustiveSwitchCheck, ExtractElementType } from '../../utils/TypeScriptHelpers';
import { TypePolicyAbstract } from '../TypePolicyAbstract';
import {
  FRAGMENT_CART_ITEM,
  FRAGMENT_CART_ITEM_WITH_OPEN_ORDER_INFO,
  FRAGMENT_CART_KEY,
  FRAGMENT_MINIMUM_CART_ITEM,
  FRAGMENT_REMOVED_CART_ITEM,
} from './fragments';

type CartCacheObj = Readonly<AsStoreObject<Cart>>;
type CartItem = ExtractElementType<OptimisticCartQuery['getCart']['items']>;
export type Product = CartItem['product'];
export type ProductWithOpenOrderInfo = ExtractElementType<
  CartWithOpenOrderInfoQuery['getCart']['items']
>['product'];
type CartItemInput = ExtractElementType<
  OptimisticUpdateCartWithMultipleItemsMutationVariables['items']
>;

/*
 When doing optimistic UI updates, a CartItem must contain a product.
 To distinguish between a normal CartItem and this, we omit 'materialId'
 */
export type CartItemInputWithProduct = Omit<CartItemInput, 'materialId'> & { product: Product };

export class CartPolicy extends TypePolicyAbstract {
  static TypePolicies: TypePolicies = {
    Cart: {
      keyFields: (obj) => {
        const cartObj = obj as CartCacheObj;

        return CartPolicy.CartCacheKey(cartObj);
      },
      fields: {
        items: {
          merge: (existing: CartCacheObj['items'] = [], incoming: CartCacheObj['items']) => {
            return mergeDeep(existing, incoming).filter((item) => {
              const inIncoming =
                incoming.findIndex((i) => {
                  return i.product.materialId === item.product.materialId;
                }) > -1;
              return item.quantity > 0 && inIncoming;
            });
          },
        },
      },
    },
  };

  static Fragments = [
    FRAGMENT_CART_KEY,
    FRAGMENT_MINIMUM_CART_ITEM,
    FRAGMENT_CART_ITEM,
    FRAGMENT_CART_ITEM_WITH_OPEN_ORDER_INFO,
    FRAGMENT_REMOVED_CART_ITEM,
  ];

  private static CartCacheKey(cartObj: CartCacheObj) {
    if (!cartObj.cartReference || !cartObj.customerId) {
      return `Cart_${cartObj.id}`;
    }

    switch (cartObj.cartReference.cartType) {
      case CartReferenceCartType.Launch:
        return `Cart_${cartObj.customerId}_${CartReferenceCartType.Launch}_${cartObj.cartReference.cartLaunchYear}_${cartObj.cartReference.cartLaunchMonth}`;
      case CartReferenceCartType.Replenish:
        return `Cart_${cartObj.customerId}_${CartReferenceCartType.Replenish}`;
      case CartReferenceCartType.Promotion:
        return `Cart_${cartObj.customerId}_${CartReferenceCartType.Promotion}_${cartObj.cartReference.cartPromotionId}`;
      default:
        exhaustiveSwitchCheck(cartObj.cartReference.cartType);
    }
  }

  public updateCartOptimistic<V>(
    product: Product,
    quantity: number,
    cacheSelectionOptions: DataProxy.Query<V, unknown>
  ) {
    const cartInApolloCache = this._apolloClient.cache.readQuery<OptimisticCartQuery, V>(
      cacheSelectionOptions
    );
    if (!cartInApolloCache) {
      return;
    }
    const cart = JSON.parse(JSON.stringify(cartInApolloCache)) as typeof cartInApolloCache;
    const itemInCart = cart.getCart.items.find((i) => i.product.materialId === product.materialId);
    if (itemInCart) {
      itemInCart.quantity = quantity;
    } else if (cart) {
      const newItem: CartItem = {
        __typename: 'CartItem',
        quantity,
        product,
      };
      cart.getCart.items.push(newItem);
    }
    this._apolloClient.writeQuery<OptimisticCartQuery, V>({
      ...cacheSelectionOptions,
      data: cart,
    });
  }

  public emptyCartOptimistic<V>(cacheSelectionOptions: DataProxy.Query<V, unknown>) {
    const cartInApolloCache = this._apolloClient.cache.readQuery<OptimisticCartQuery, V>(
      cacheSelectionOptions
    );
    if (!cartInApolloCache) {
      return;
    }
    const cart = JSON.parse(JSON.stringify(cartInApolloCache)) as typeof cartInApolloCache;
    cart.getCart.items = [];
    this._apolloClient.writeQuery<OptimisticCartQuery, V>({
      ...cacheSelectionOptions,
      data: cart,
    });
  }

  public updateCartWithMultipleOptimistic<V>(
    items: CartItemInputWithProduct[],
    cacheSelectionOptions: DataProxy.Query<V, unknown>
  ) {
    const cartInApolloCache = this._apolloClient.cache.readQuery<OptimisticCartQuery, V>(
      cacheSelectionOptions
    );
    if (!cartInApolloCache) {
      return;
    }
    const cart = JSON.parse(JSON.stringify(cartInApolloCache)) as typeof cartInApolloCache;
    for (const item of items) {
      const itemInCart = cart.getCart.items.find(
        (i) => i.product.materialId === item.product.materialId
      );
      if (itemInCart) {
        // When updating multiple items in a cart, we are doing addition instead of replacing the quantity
        itemInCart.quantity = itemInCart.quantity + item.quantity;
      } else {
        const newItem: CartItem = {
          __typename: 'CartItem',
          quantity: item.quantity,
          product: item.product,
        };
        cart.getCart.items.push(newItem);
      }
    }

    this._apolloClient.writeQuery({
      ...cacheSelectionOptions,
      data: cart,
    });
  }
}
