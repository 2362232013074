import { gql } from '@apollo/client';

export const UPDATE_CART = gql`
  mutation updateCart(
    $customerId: Int!
    $materialId: Int!
    $quantity: Int!
    $cartReference: CartReferenceInput!
  ) {
    updateCart(
      customerId: $customerId
      materialId: $materialId
      quantity: $quantity
      cartReference: $cartReference
    ) {
      id
      ...cartKey
    }
  }
`;
