import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ProductTag } from '@lego/b2b-unicorn-data-access-layer';
import { Switch } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { useProductListContext } from '../../ProductListContext';

export const RareItemsSwitch: React.FC = () => {
  const { toggle_label_rare_items } = useLabels();
  const { selectedFilters, showRareItemsSwitch, handleProductTagsChange } = useProductListContext();

  return showRareItemsSwitch ? (
    <Switch
      onToggle={() => handleProductTagsChange(ProductTag.RareItem)}
      isOn={selectedFilters?.tags?.includes(ProductTag.RareItem) || false}
      label={toggle_label_rare_items}
      type={'orange'}
    />
  ) : null;
};
