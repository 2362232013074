import { css } from '@emotion/react';
import React from 'react';

import { media } from '../theme';

type TableHeadProps = {
  children: React.ReactNode;
};

const tableHeadStyle = css({
  display: 'none',
  [media.medium]: {
    display: 'table-header-group',
  },
});

export const TableHead: React.FC<TableHeadProps> = ({ children }) => {
  return <thead css={tableHeadStyle}>{children}</thead>;
};
