import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  OrderFulfillmentStatus,
  OrdersFilters,
  OrdersShipTo,
  OrderStatus,
} from '@lego/b2b-unicorn-data-access-layer';
import { ClearAllTag, FilterTag } from '@lego/b2b-unicorn-ui-components';
import { Fragment } from 'react';

import SearchField from '../../../components/SearchField/SearchField';
import SelectBox from '../../../components/SelectBox/SelectBox';
import { filterContainerStyle, tagContainerstyle } from '../../../styles/filtering';
import { FiltersInURL, HumanReadableDateRange } from '../../../utils/DateRanges';

interface ISearchAndFilterHandlerProps {
  updateCallback: (model: FiltersInURL<OrdersFilters> | undefined) => void;
  selectedFilters?: FiltersInURL<OrdersFilters>;
  customerId: number;
  locale: string;
  shipTos: OrdersShipTo[];
}

const OrderSearchAndFilters = (props: ISearchAndFilterHandlerProps) => {
  const labelsContext = useLabels();
  const { getLabelByKey } = labelsContext;

  const handleSearchQuery = (value: string) => {
    props.updateCallback({
      ...props.selectedFilters,
      query: value,
    });
  };

  const handleShipToSelect = (value: number | number[]) => {
    if (Array.isArray(value)) {
      props.updateCallback({
        ...props.selectedFilters,
        shipToIds: value,
      });
    }
  };

  const handleDateRangeSelect = (
    humanReadableDateRange: HumanReadableDateRange | HumanReadableDateRange[]
  ) => {
    if (Array.isArray(humanReadableDateRange)) {
      throw Error(`DateRange cannot be an array!`);
    }

    props.updateCallback({
      ...props.selectedFilters,
      dateRange: humanReadableDateRange,
    });
  };

  const handleOrderStatusSelect = (value: OrderStatus | OrderStatus[]) => {
    if (Array.isArray(value)) {
      props.updateCallback({
        ...props.selectedFilters,
        orderStatuses: value,
      });
    }
  };

  const handleOrderFulfillmentStatusSelect = (
    value: OrderFulfillmentStatus | OrderFulfillmentStatus[]
  ) => {
    if (Array.isArray(value)) {
      props.updateCallback({
        ...props.selectedFilters,
        fulfillmentStatuses: value,
      });
    }
  };

  const removeAllTags = () => {
    props.updateCallback(
      props.selectedFilters?.sort ? { sort: props.selectedFilters.sort } : undefined
    );
  };

  const removeQueryTag = () => {
    const updatedFilters = { ...props.selectedFilters };
    updatedFilters.query = '';
    props.updateCallback(updatedFilters);
  };

  const removeShipToTag = (value: number) => {
    const updatedFilters = { ...props.selectedFilters };
    updatedFilters.shipToIds = updatedFilters?.shipToIds?.filter((id) => id !== value);
    props.updateCallback(updatedFilters);
  };

  const removeDateRangeTag = () => {
    const updatedFilters = { ...props.selectedFilters };
    delete updatedFilters.dateRange;
    props.updateCallback(updatedFilters);
  };

  const removeOrderStatusTag = (value: string) => {
    const updatedFilters = { ...props.selectedFilters };
    updatedFilters.orderStatuses = updatedFilters?.orderStatuses?.filter(
      (status) => status !== value
    );
    props.updateCallback(updatedFilters);
  };

  const removeFulfillmentStatusTag = (value: string) => {
    const updatedFilters = { ...props.selectedFilters };
    updatedFilters.fulfillmentStatuses = updatedFilters?.fulfillmentStatuses?.filter(
      (status) => status !== value
    );
    props.updateCallback(updatedFilters);
  };

  const shipToValues = props.shipTos.map((shipto) => ({
    displayText: `${shipto.id} ${shipto.city}`,
    value: shipto.id,
  }));

  const tagCount = props.selectedFilters
    ? (props.selectedFilters.query !== '' ? 1 : 0) +
      (props.selectedFilters.dateRange ? 1 : 0) +
      (props.selectedFilters.fulfillmentStatuses
        ? props.selectedFilters.fulfillmentStatuses.length
        : 0) +
      (props.selectedFilters.orderStatuses ? props.selectedFilters.orderStatuses.length : 0) +
      (props.selectedFilters.shipToIds ? props.selectedFilters.shipToIds.length : 0)
    : 0;

  return (
    <Fragment>
      <div css={filterContainerStyle}>
        <SearchField
          placeholder={labelsContext.orders_search_placeholder}
          searchValueSubmitHandler={handleSearchQuery}
          clearAfterSubmit={true}
        />
        <SelectBox
          label={labelsContext.shipping_address}
          values={shipToValues}
          selectedValues={props.selectedFilters?.shipToIds || []}
          optionsChangeHandler={handleShipToSelect}
          allowMultiple={true}
        />
        <SelectBox
          label={labelsContext.order_date}
          values={Object.values(HumanReadableDateRange).map((dateRange) => ({
            displayText: getLabelByKey(dateRange.toLowerCase()),
            value: dateRange,
          }))}
          selectedValues={props.selectedFilters?.dateRange || undefined}
          optionsChangeHandler={handleDateRangeSelect}
        />
        <SelectBox
          label={labelsContext.order_status}
          values={Object.values(OrderStatus)
            .filter((status) => {
              /** Filter out STATUS_PROCESSING because it doesn't make sense to end users. */
              return status !== OrderStatus.StatusProcessing;
            })
            .map((status) => ({
              displayText: getLabelByKey(status.toLowerCase()),
              value: status,
            }))}
          selectedValues={props.selectedFilters?.orderStatuses || []}
          optionsChangeHandler={handleOrderStatusSelect}
          allowMultiple={true}
        />
        <SelectBox
          label={labelsContext.fulfillment_status}
          values={Object.values(OrderFulfillmentStatus).map((status) => ({
            displayText: getLabelByKey(status.toLowerCase()),
            value: status,
          }))}
          selectedValues={props.selectedFilters?.fulfillmentStatuses || []}
          optionsChangeHandler={handleOrderFulfillmentStatusSelect}
          allowMultiple={true}
        />
      </div>
      <div css={tagCount > 0 ? tagContainerstyle : null}>
        {tagCount > 1 && (
          <ClearAllTag
            onClickHandler={removeAllTags}
            text={labelsContext.filter_clear_all}
          />
        )}
        {props.selectedFilters?.query && (
          <FilterTag
            onClickHandler={removeQueryTag}
            text={props.selectedFilters.query}
            option={'order'}
            value={props.selectedFilters.query}
          />
        )}
        {shipToValues
          .filter((shipto) => props.selectedFilters?.shipToIds?.includes(shipto.value))
          .map((shipto) => (
            <FilterTag
              onClickHandler={() => {
                removeShipToTag(shipto.value);
              }}
              text={shipto.displayText}
              option={'shipToIds'}
              value={shipto.value}
              key={`tag-shipto-${shipto.value}`}
            />
          ))}
        {props.selectedFilters?.dateRange && (
          <FilterTag
            onClickHandler={removeDateRangeTag}
            text={getLabelByKey(props.selectedFilters.dateRange.toLowerCase())}
            option={'dateRange'}
            value={props.selectedFilters.dateRange}
          />
        )}
        {props.selectedFilters?.orderStatuses?.map((status) => (
          <FilterTag
            onClickHandler={() => {
              removeOrderStatusTag(status);
            }}
            text={`${labelsContext.order_status}: ${getLabelByKey(status.toLowerCase())}`}
            option={'orderStatuses'}
            value={status}
            key={`tag-orderstatus-${status}`}
          />
        ))}
        {props.selectedFilters?.fulfillmentStatuses?.map((status) => (
          <FilterTag
            onClickHandler={() => {
              removeFulfillmentStatusTag(status);
            }}
            text={`${labelsContext.fulfillment_status}: ${getLabelByKey(status.toLowerCase())}`}
            option={'fulfillmentStatuses'}
            value={status}
            key={`tag-fulfillmentstatus-${status}`}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default OrderSearchAndFilters;
