export const TILE_WIDTH = 344;

export enum LifecycleStatus {
  COMING_SOON = 'COMING_SOON',
  NEW = 'NEW',
  RETIRING_SOON = 'RETIRING_SOON',
  RETIRED = 'RETIRED',
  CARRY_OVER = 'CARRY_OVER',
}

export enum ProductType {
  SUPLEMENTARY = 'Suplementary',
  BOM = 'BillOfMaterial',
  BOX = 'Box',
}
