import { BootstrapAuthentication } from '@lego/b2b-unicorn-bootstrap/components/BootstrapAuthentication';
import React, { ReactNode } from 'react';

type BootstrapPhase1Props = {
  children: ReactNode;
  errorBoundary: React.ComponentType<{ children: ReactNode }>;
};
export const BootstrapPhase1: React.FC<BootstrapPhase1Props> = ({
  children,
  errorBoundary: ErrorBoundary,
}) => {
  return (
    <ErrorBoundary>
      <BootstrapAuthentication>{children}</BootstrapAuthentication>
    </ErrorBoundary>
  );
};
