export enum ImageType {
  MODEL_IMAGE = 1,
  MAIN_BOX = 3,
  MAIN_BOX_BACK = 7,
  ALT_MODEL_LINE_UP = 14,
  ALT_MODEL_PRIMARY = 15,
  ALT_MODEL_SECONDARY = 17,
}

const baseImageUrl = 'https://image.content.lego.com/public/image/';

export const getImageUrls = (materialId: number, imagesTypes: ImageType[], height = 120) => {
  return imagesTypes.map((imageType) => getImageUrl(materialId, imageType, height));
};

export const getImageUrl = (materialId: number, imageType: ImageType, height = 120) => {
  return baseImageUrl + `${materialId}/${imageType}.jpg?height=${height}`;
};
