import React, { ReactNode, useContext } from 'react';

import DataAccessLayer from '../DataAccessLayer';

interface IDataAccessLayerContext {
  client: DataAccessLayer | null;
}

const DataAccessLayerContext = React.createContext<IDataAccessLayerContext>({ client: null });

type DataAccessLayerProviderProps = {
  client: DataAccessLayer;
  children: ReactNode;
};

export const DataAccessLayerProvider: React.FC<DataAccessLayerProviderProps> = ({
  children,
  client,
}) => {
  return (
    <DataAccessLayerContext.Provider value={{ client }}>{children}</DataAccessLayerContext.Provider>
  );
};

export const useDataAccessLayer = () => {
  const context = useContext(DataAccessLayerContext);
  if (!context.client) {
    throw new Error(
      `DataAccessLayer context was not found, did you remember to wrap the application with <DataAccessLayerProvider /> ?`
    );
  }

  return context.client;
};
