import React, { createContext, useCallback, useContext } from 'react';

import { SiteId, trackerBaseUrl } from './constants';
import { pushInstruction, useMatomo } from './hooks/useMatomo';
import { checkUserConsent } from './utils/checkUserConsent';

enum EventCategory {
  'Recommendations' = 'Recommendations',
  'RecommendationsCart' = 'RecommendationsCart',
  'ReplenishCatalog' = 'ReplenishCatalog',
  'ProductDetails' = 'ProductDetails',
  'Navigation' = 'Navigation',
  'BulkQuickAdd' = 'BulkQuickAdd',
  'ContentHub' = 'ContentHub',
  'ThemeTiles' = 'ThemeTiles',
}

enum EventAction {
  'AddToCart' = 'AddToCart',
  'AddToCartLaunchWindow' = 'AddToCartLaunchWindow',
  'AddToCartLaunchWindowMultiple' = 'AddToCartLaunchWindowMultiple',
  'AddToCartPromotion' = 'AddToCartPromotion',
  'AddToCartReplenish' = 'AddToCartReplenish',
  'OpenLaunchWindow' = 'OpenLaunchWindow',
  'OpenReplenish' = 'OpenReplenish',
  'OpenPromotion' = 'OpenPromotion',
  'ReopenLaunchWindow' = 'ReopenLaunchWindow',
  'ReopenLaunchWindowMultiple' = 'ReopenLaunchWindowMultiple',
  'ReopenReplenish' = 'ReopenReplenish',
  'ReopenPromotion' = 'ReopenPromotion',
  'PasteLaunchWindow' = 'PasteLaunchWindow',
  'PasteLaunchWindowMultiple' = 'PasteLaunchWindowMultiple',
  'PasteReplenish' = 'PasteReplenish',
  'PastePromotion' = 'PastePromotion',
  'PasteOneColumnLaunchWindow' = 'PasteOneColumnLaunchWindow',
  'PasteOneColumnLaunchWindowMultiple' = 'PasteOneColumnLaunchWindowMultiple',
  'PasteOneColumnReplenish' = 'PasteOneColumnReplenish',
  'PasteOneColumnPromotion' = 'PasteOneColumnPromotion',
  'QuickAddToCart' = 'QuickAddToCart',
  'Swipe' = 'Swipe',
  'GotoProductDetails' = 'GotoProductDetails',
  'MainBrandLogoClick' = 'MainBrandLogoClick',
  'ContentHubLinkClick' = 'ContentHubLinkClick',
  'OpenContentHubOrderDetails' = 'OpenContentHubOrderDetails',
  'OpenContentHubProductDetails' = 'OpenContentHubProductDetails',
  'RightClick' = 'RightClick',
  'Select' = 'Select',
  'Deselect' = 'Deselect',
  'ShowMore' = 'ShowMore',
  'ShowLess' = 'ShowLess',
}

export type ITrackEventParams = {
  event: keyof typeof EventMap;
  name: string;
  value?: number;
};

// https://matomo.org/docs/event-tracking/
export const EventMap = {
  // Recommendation events
  swipeRecommendationsView: { category: EventCategory.Recommendations, action: EventAction.Swipe },
  addToCartFromRecommendations: {
    category: EventCategory.Recommendations,
    action: EventAction.AddToCart,
  },
  gotoProductDetailsFromRecommendations: {
    category: EventCategory.Recommendations,
    action: EventAction.GotoProductDetails,
  },
  addToCartFromRecommendationsCart: {
    category: EventCategory.RecommendationsCart,
    action: EventAction.AddToCart,
  },
  swipeRecommendationsCart: {
    category: EventCategory.RecommendationsCart,
    action: EventAction.Swipe,
  },
  gotoProductDetailsFromRecommendationsCart: {
    category: EventCategory.RecommendationsCart,
    action: EventAction.GotoProductDetails,
  },
  // End recommendation events

  // Replenish catalog events
  addToCartFromReplenishCatalog: {
    category: EventCategory.ReplenishCatalog,
    action: EventAction.AddToCart,
  },
  addToCartFromReplenishCatalogUsingQuickAdd: {
    category: EventCategory.ReplenishCatalog,
    action: EventAction.QuickAddToCart,
  },
  addToCartFromProductDetails: {
    category: EventCategory.ProductDetails,
    action: EventAction.AddToCart,
  },
  // End Replenish catalog events

  // General navigation events
  clickOnMainBrandLogo: {
    category: EventCategory.Navigation,
    action: EventAction.MainBrandLogoClick,
  },
  clickOnContentHubLink: {
    category: EventCategory.Navigation,
    action: EventAction.ContentHubLinkClick,
  },
  // End general navigation events

  // Bulk quick add events
  //// Replenishment
  addToCartFromBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.AddToCartReplenish,
  },
  openBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.OpenReplenish,
  },
  reopenBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.ReopenReplenish,
  },
  pasteBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteReplenish,
  },
  pasteOneColumnBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteOneColumnReplenish,
  },
  //// Launch window
  addToCartFromBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.AddToCartLaunchWindow,
  },
  openBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.OpenLaunchWindow,
  },
  reopenBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.ReopenLaunchWindow,
  },
  pasteBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteLaunchWindow,
  },
  pasteOneColumnBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteOneColumnLaunchWindow,
  },
  //// Launch windows overview
  reopenBulkQuickAddLaunchWindowMultiple: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.ReopenLaunchWindowMultiple,
  },
  addToCartFromBulkQuickAddLaunchWindowMultiple: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.AddToCartLaunchWindowMultiple,
  },
  pasteBulkQuickAddLaunchWindowMultiple: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteLaunchWindowMultiple,
  },
  pasteOneColumnBulkQuickAddLaunchWindowMultiple: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteOneColumnLaunchWindowMultiple,
  },
  //// Promotion
  addToCartFromBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.AddToCartPromotion,
  },
  openBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.OpenPromotion,
  },
  reopenBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.ReopenPromotion,
  },
  pasteBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PastePromotion,
  },
  pasteOneColumnBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteOneColumnPromotion,
  },
  rightClickBulkQuickAdd: { category: EventCategory.BulkQuickAdd, action: EventAction.RightClick },
  // End bulk quick add events

  // Content hub events
  contentHubLinkOrderDetails: {
    category: EventCategory.ContentHub,
    action: EventAction.OpenContentHubOrderDetails,
  },
  contentHubLinkProductDetails: {
    category: EventCategory.ContentHub,
    action: EventAction.OpenContentHubProductDetails,
  },
  // End content hub events

  // Theme tile events
  themeTileSelecting: { category: EventCategory.ThemeTiles, action: EventAction.Select },
  themeTileDeselecting: { category: EventCategory.ThemeTiles, action: EventAction.Deselect },
  themeTilesShowMore: { category: EventCategory.ThemeTiles, action: EventAction.ShowMore },
  themeTilesShowLess: { category: EventCategory.ThemeTiles, action: EventAction.ShowLess },
  // End theme tile events
} as const;

interface IAnalyticsContext {
  customTrackPageView: (title: string) => void;
  trackEvent: (params: ITrackEventParams) => void;
}

interface IAnalyticsProvider {
  children: React.ReactNode;
  cookieDomain: string;
  env: string;
  userConsent?: boolean;
}

const getSiteId = (env: string) => {
  return env === 'prod' ? SiteId.PROD : SiteId.DEV;
};

export const AnalyticsContext = createContext<IAnalyticsContext | undefined>(undefined);

const AnalyticsProvider = ({ children, cookieDomain, env }: IAnalyticsProvider): JSX.Element => {
  const { initializeMatomo, isScriptLoaded } = useMatomo();
  const userConsent = checkUserConsent();

  initializeMatomo({
    siteId: getSiteId(env),
    cookieDomain,
    trackerBaseUrl,
  });

  const contextValue: IAnalyticsContext = {
    customTrackPageView: useCallback(
      (title) => {
        // Need to set the URL before sending `trackPageView`
        // See: https://matomo.org/faq/how-to/how-do-i-set-a-custom-url-using-the-matomo-javascript-tracker/
        if (isScriptLoaded) {
          if (userConsent) {
            pushInstruction('requireCookieConsent');
          } else {
            pushInstruction('disableCookies');
          }
          pushInstruction('setCustomUrl', document.location.href);
          pushInstruction('setDocumentTitle', title);
          pushInstruction('trackPageView');
        }
      },
      [userConsent, isScriptLoaded]
    ),
    trackEvent: ({ event, name, value }) => {
      if (env !== 'prod') {
        console.info('AnalyticsEvent:', event, 'Name:', name, 'Value:', value); // eslint-disable-line no-console
      }
      const { category, action } = EventMap[event];
      pushInstruction('trackEvent', category, action, name, value);
    },
  };

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};

const useAnalytics = () => {
  const contextValue = useContext(AnalyticsContext);
  if (contextValue === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return contextValue;
};

export { AnalyticsProvider, useAnalytics };
