import { Logger } from '@lego/b2b-unicorn-shared/logger';
import { nanoid } from 'nanoid';

import { LabelsInContentStack } from './generatedType';

type LabelsServiceListener<T> = (payload: T) => void;
type EventName = 'loading' | 'error' | 'done';

type Label = {
  text: string;
  locale: string;
};
type LabelKey = Extract<keyof LabelsInContentStack, string>;
type LabelsDict = {
  [key in LabelKey]: string;
} & {
  getLabelByKey: (key: LabelKey | string) => string;
};
type RawLabelsDict = {
  [key in LabelKey]: Label;
};
type LabelsServiceOptions = {
  baseUrl: string;
  keysOnly?: boolean;
};

interface ILabelsService {
  labels: LabelsDict;
  setLocale(locale: string): void;
  addListener(event: 'loading', listener: LabelsServiceListener<boolean>): string;
  addListener(event: EventName, listener: LabelsServiceListener<unknown>): string;
  removeListener(event: EventName, listenerId: string): void;
}

export class LabelsService implements ILabelsService {
  private _listeners: { [key in EventName]: { [key: string]: LabelsServiceListener<unknown> } } = {
    loading: {},
    error: {},
    done: {},
  };
  private _locale: string | null | undefined = null;
  private _logger: Logger;
  private _labelsProxy: LabelsDict | null = null;
  private _rawLabels: RawLabelsDict = {} as RawLabelsDict;
  private readonly _opts: LabelsServiceOptions;

  constructor(logger: Logger, opts: LabelsServiceOptions) {
    this._logger = logger;
    this._opts = opts;
  }

  public get labels() {
    if (!this._labelsProxy) {
      this._labelsProxy = this.createLabelsProxy();
    }

    return this._labelsProxy;
  }

  public setLocale(locale: string | null | undefined) {
    if (this._locale === locale) {
      return;
    }

    this._locale = locale;

    if (!locale || this._opts.keysOnly) {
      this._rawLabels = {} as RawLabelsDict;
      this.callListeners('done');

      return;
    }

    this.callListeners('loading', true);
    this.fetchLabelsOrFallback(locale)
      .then(() => {
        this.callListeners('done');
      })
      .catch(() => {
        this.callListeners('error');
      })
      .finally(() => {
        this.callListeners('loading', false);
      });
  }

  public addListener(event: 'loading', listener: LabelsServiceListener<boolean>): string;
  public addListener(event: 'done', listener: LabelsServiceListener<never>): string;
  public addListener(event: EventName, listener: LabelsServiceListener<never>): string {
    const listenerId = nanoid();
    Object.assign(this._listeners[event], {
      [`${listenerId}`]: listener,
    });

    return listenerId;
  }

  public removeListener(event: EventName, listenerId: string) {
    delete this._listeners[event][`${listenerId}`];
  }

  private callListeners(event: EventName, payload?: boolean): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_listenerId, listener] of Object.entries(this._listeners[event])) {
      listener(payload);
    }
  }

  private labelsProxyGetHandler(target: RawLabelsDict, labelKey: LabelKey | 'getLabelByKey') {
    switch (labelKey) {
      case 'getLabelByKey':
        return (key: Exclude<typeof labelKey, 'getLabelByKey'>) => {
          if (!target) {
            return key;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return this.get(target, key);
        };
      default:
        if (!target) {
          return labelKey;
        }
        return target[labelKey]?.text || labelKey;
    }
  }

  private createLabelsProxy() {
    return new Proxy<RawLabelsDict>(this._rawLabels || {}, {
      get: this.labelsProxyGetHandler,
    }) as unknown as LabelsDict;
  }
  private async fetchLabels(locale: string) {
    try {
      const { baseUrl } = this._opts;
      const labelsUrl = new URL(locale, baseUrl);
      this._logger.info(`Fetching labels for '${locale}'`);

      const fetchResult = await fetch(labelsUrl);
      if (fetchResult.status === 404) {
        this._logger.error(`'${labelsUrl}' was not found`);

        return null;
      }

      if (!fetchResult.ok) {
        this._logger.error(
          `Unknown error trying to fetch labels. Failed with: ${fetchResult.status} ${fetchResult.statusText}`
        );
        this.callListeners('error');
        return null;
      }

      return (await fetchResult.json()) as RawLabelsDict;
    } catch (error: unknown) {
      this._logger.error('Unknown error', { stackTrace: error as Error });
      this.callListeners('error');
      return null;
    }
  }

  private async fetchLabelsOrFallback(locale: string) {
    let localeResult = await this.fetchLabels(locale);
    if (!localeResult) {
      this._logger.error(`'${locale}' was not found, falling back to English`);
      localeResult = await this.fetchLabels('EN-US');
      if (!localeResult) {
        this._logger.error(`'EN-US' was not found, falling back to label keys`);
        return;
      }
    }

    this._labelsProxy = null;
    this._rawLabels = localeResult;
  }
}
