import { ReplenishmentProductsQuery_products_ProductList_products_Box } from '../../../generated-types/graphql';
import { Lifecycle, ProductTag } from '../../../generated-types/types';
import { Box } from '../../../type-policies/ProductPolicy/entities';

export class ReplenishmentProductsBox
  extends Box
  implements ReplenishmentProductsQuery_products_ProductList_products_Box
{
  itemNumber?: number | null;
  ean: string;
  launchDate?: string | null;
  exitDate?: string | null;
  name?: string | null;
  theme: string;
  materialId: number;
  piecesPerCasePack: number;
  tags: ProductTag[];
  lifecycles: Lifecycle[];
  price: ReplenishmentProductsQuery_products_ProductList_products_Box['price'];
  stockStatus: ReplenishmentProductsQuery_products_ProductList_products_Box['stockStatus'];
  openOrderInfo?:
    | ReplenishmentProductsQuery_products_ProductList_products_Box['openOrderInfo']
    | null;

  constructor(box: ReplenishmentProductsQuery_products_ProductList_products_Box) {
    super();
    this.itemNumber = box.itemNumber;
    this.ean = box.ean;
    this.launchDate = box.launchDate;
    this.exitDate = box.exitDate;
    this.name = box.name;
    this.theme = box.theme;
    this.materialId = box.materialId;
    this.piecesPerCasePack = box.piecesPerCasePack;
    this.tags = box.tags;
    this.lifecycles = box.lifecycles;
    this.price = box.price;
    this.stockStatus = box.stockStatus;
    this.openOrderInfo = box.openOrderInfo;
  }
}
