import type { GraphQLFormattedError } from 'graphql';

type NonComplaintAppSyncError = {
  message: GraphQLFormattedError['message'];
  errorType?: string;
  data?: unknown;
  errorInfo?: {
    extensions?: GraphQLFormattedError['extensions'];
  };
  path: GraphQLFormattedError['path'];
  locations: GraphQLFormattedError['locations'];
};

export const normalizeGraphQLError = (graphQLFormattedError: GraphQLFormattedError) => {
  const maybeNonComplaintAppSyncError = graphQLFormattedError as NonComplaintAppSyncError;
  if (
    maybeNonComplaintAppSyncError.errorInfo &&
    maybeNonComplaintAppSyncError.errorInfo.extensions
  ) {
    Object.assign(graphQLFormattedError, {
      extensions: JSON.parse(JSON.stringify(maybeNonComplaintAppSyncError.errorInfo.extensions)),
    });
    delete maybeNonComplaintAppSyncError.errorInfo.extensions;
  }
};
