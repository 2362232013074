import React from 'react';

import { Icon, IconType } from '../Icon';
import { designToken, font } from '../theme';
import { DisclaimerType } from './DisclaimerType';

const disclaimerStyle = (type = DisclaimerType.INFO, align = 'left') => {
  return {
    fontSize: 12,
    fontWeight: font.weight.semibold,
    textAlign: {
      left: 'left' as const,
      center: 'center' as const,
      right: 'right' as const,
    }[align],
    marginRight: {
      left: 'auto',
      center: 'auto',
      right: 0,
    }[align],
    marginLeft: {
      left: 0,
      center: 'auto',
      right: 'auto',
    }[align],
    color: {
      INFO: designToken.information.emphasis,
      WARNING: designToken.warning.emphasis,
      ERROR: designToken.error.emphasis,
    }[type],
    maxWidth: 700,
    '> svg': {
      height: 16,
      width: 16,
      fill: {
        INFO: designToken.information.emphasis,
        WARNING: designToken.warning.default,
        ERROR: designToken.error.emphasis,
      }[type],
      display: 'inline-block',
      transform: 'translateY(4px)',
    },
  };
};

type DisclaimerProps = {
  text: string;
  type?: DisclaimerType;
  align?: 'left' | 'center' | 'right';
};

export const Disclaimer: React.FC<DisclaimerProps> = ({
  text,
  type = DisclaimerType.INFO,
  align = 'left',
}) => {
  return (
    <p css={disclaimerStyle(type, align)}>
      <Icon type={IconType.INFO} /> {text}
    </p>
  );
};
