import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import { FunctionComponent, ReactNode } from 'react';

const topbarStyle = css({
  height: 70,
  width: '100%',
  backgroundColor: designToken.primary.default,
  color: colors.klik.slate10,
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 10px',
  boxSizing: 'border-box',
  [media.medium]: {
    paddingTop: 0,
    paddingRight: baseSpacing * 2,
    paddingBottom: 0,
    paddingLeft: baseSpacing * 2,
    height: 72,
  },
});

interface TopbarProps {
  children: ReactNode;
}

const Topbar: FunctionComponent<TopbarProps> = ({ children }) => {
  return <div css={topbarStyle}>{children}</div>;
};

export default Topbar;
