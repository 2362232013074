import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing } from '../theme';

type SpacingType = 'margin' | 'padding';
const spacerStyle = (
  multiplier: number,
  spacingType: SpacingType,
  direction: 'horizontal' | 'vertical'
) =>
  css({
    marginBottom:
      direction === 'horizontal' && spacingType === 'margin' ? baseSpacing * multiplier : undefined,
    paddingBottom:
      direction === 'horizontal' && spacingType === 'padding'
        ? baseSpacing * multiplier
        : undefined,
    marginRight:
      direction === 'vertical' && spacingType === 'margin' ? baseSpacing * multiplier : undefined,
    paddingRight:
      direction === 'vertical' && spacingType === 'padding' ? baseSpacing * multiplier : undefined,
  });

interface SpacerProps {
  multiplier?: number;
  spacingType?: SpacingType;
  direction?: 'horizontal' | 'vertical';
}

export const Spacer: React.FC<SpacerProps> = ({
  multiplier = 4,
  spacingType = 'margin',
  direction = 'horizontal',
}) => {
  return <div css={spacerStyle(multiplier, spacingType, direction)} />;
};
