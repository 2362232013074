import { BulkQuickAddContext } from '@lego/b2b-unicorn-shared/components/BulkQuickAdd/types';
import { EventMap } from '@lego/b2b-unicorn-ui-analyticscontext';

import { pushInstruction } from '../../AnalyticsContext/hooks/useMatomo';

export const pasteEventListener = (
  event: HTMLElementEventMap['paste'],
  context: BulkQuickAddContext
) => {
  if (event.target instanceof HTMLInputElement) {
    return;
  }

  const clipboardData = event.clipboardData;
  if (clipboardData) {
    const pastedData = clipboardData.getData('text');
    const rows = pastedData.split('\n');
    if (rows.length > 0) {
      const columns = rows[0].split('\t');
      if (columns.length > 1) {
        const { category, action } = EventMap[`pasteBulkQuickAdd${context}`];
        pushInstruction('trackEvent', category, action, `Pasted ${rows.length} rows`);
      } else {
        const { category, action } = EventMap[`pasteOneColumnBulkQuickAdd${context}`];
        pushInstruction(
          'trackEvent',
          category,
          action,
          `Pasted ${rows.length} rows without quantity`
        );
      }
    }
  }
};
