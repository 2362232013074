import { ReplenishmentProductQuery_product_Supplementary } from '../../../generated-types/graphql';
import { Lifecycle, ProductTag } from '../../../generated-types/types';
import { Supplementary } from '../../../type-policies/ProductPolicy/entities';

export class ReplenishmentProductSupplementary
  extends Supplementary
  implements ReplenishmentProductQuery_product_Supplementary
{
  materialId: number;
  ean: string;
  available: boolean;
  name?: string | null | undefined;
  piecesPerCasePack: number;
  lifecycles: Lifecycle[];
  tags: ProductTag[];
  theme: string;
  recommendedRetailPrice?: ReplenishmentProductQuery_product_Supplementary['recommendedRetailPrice'];
  dimensions?: ReplenishmentProductQuery_product_Supplementary['dimensions'];
  weight?: ReplenishmentProductQuery_product_Supplementary['weight'];
  openOrderInfo?: ReplenishmentProductQuery_product_Supplementary['openOrderInfo'];
  price: ReplenishmentProductQuery_product_Supplementary['price'];
  stockStatus: ReplenishmentProductQuery_product_Supplementary['stockStatus'];

  constructor(product: ReplenishmentProductQuery_product_Supplementary) {
    super();
    this.materialId = product.materialId;
    this.ean = product.ean;
    this.available = product.available;
    this.name = product.name;
    this.piecesPerCasePack = product.piecesPerCasePack;
    this.lifecycles = product.lifecycles;
    this.tags = product.tags;
    this.theme = product.theme;
    this.recommendedRetailPrice = product.recommendedRetailPrice;
    this.dimensions = product.dimensions;
    this.weight = product.weight;
    this.openOrderInfo = product.openOrderInfo;
    this.price = product.price;
    this.stockStatus = product.stockStatus;
  }
}
