import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { assignDesignToken, ObjectPathLeavesOnly } from '../../utils';
import { baseSpacing, designToken, font } from '../theme';
import { TagType } from './TagType';

interface IStyleProps {
  small: boolean;
  fixedWidth: boolean;
  textColor?: ObjectPathLeavesOnly<typeof designToken>;
}

const tagStyle = (styleProps: IStyleProps) =>
  css({
    width: styleProps.fixedWidth ? (styleProps.small ? 30 : 42) : 'auto',
    height: styleProps.small ? 16 : 24,
    fontSize: font.size.tiny,
    borderRadius: baseSpacing * 2,
    paddingRight: baseSpacing,
    paddingLeft: baseSpacing,
    marginRight: baseSpacing / 2,
    '&:last-child': {
      marginRight: 0,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      height: styleProps.small ? 16 : 19,
    },
  });
const tagBlueStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.information.default,
    backgroundColor: designToken.background.blue,
    svg: {
      fill: designToken.information.default,
    },
  });
const tagInvertedBlueStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.information.emphasis,
    backgroundColor: designToken.background.white,
    borderColor: tokens.color.core.blue[200],
    borderWidth: 2,
    borderStyle: 'solid',
    svg: {
      fill: designToken.information.emphasis,
    },
  });
const tagBlueBoldStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.text.inverse,
    backgroundColor: designToken.secondary.default,
    svg: {
      fill: designToken.text.inverse,
    },
  });
const tagGreenStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.success.default,
    backgroundColor: designToken.success.subdued,
    svg: {
      fill: designToken.success.default,
    },
  });
const tagInvertedGreenStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.success.dark,
    backgroundColor: designToken.background.white,
    borderColor: designToken.success.subdued,
    borderWidth: 2,
    borderStyle: 'solid',
    svg: {
      fill: designToken.success.dark,
    },
  });
const tagGreenBoldStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.text.inverse,
    backgroundColor: designToken.success.default,
    svg: {
      fill: designToken.text.inverse,
    },
  });
const tagOrangeStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.warning.default,
    backgroundColor: designToken.warning.subdued,
    svg: {
      fill: designToken.warning.default,
    },
  });
const tagInvertedOrangeStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.warning.dark,
    backgroundColor: designToken.background.white,
    borderColor: designToken.warning.subdued,
    borderWidth: 2,
    borderStyle: 'solid',
    svg: {
      fill: designToken.warning.dark,
    },
  });
const tagOrangeBoldStyle = (styleProps: IStyleProps) =>
  css(tagStyle(styleProps), {
    color: styleProps.textColor
      ? assignDesignToken(styleProps.textColor)
      : designToken.text.inverse,
    backgroundColor: designToken.warning.default,
    svg: {
      fill: designToken.text.inverse,
    },
  });

interface SolidTagProps {
  type: TagType;
  fixedWidth?: boolean;
  small?: boolean;
  children?: React.ReactNode;
  textColor?: ObjectPathLeavesOnly<typeof designToken>;
}

export const Tag = ({
  type = TagType.BLUE,
  small = false,
  fixedWidth = true,
  children,
  textColor,
}: SolidTagProps) => {
  const styleProps: IStyleProps = { small, fixedWidth, textColor };
  return (
    <span
      css={
        {
          [TagType.BLUE]: tagBlueStyle(styleProps),
          [TagType.BLUE_INVERTED]: tagInvertedBlueStyle(styleProps),
          [TagType.BLUE_BOLD]: tagBlueBoldStyle(styleProps),
          [TagType.GREEN]: tagGreenStyle(styleProps),
          [TagType.GREEN_INVERTED]: tagInvertedGreenStyle(styleProps),
          [TagType.GREEN_BOLD]: tagGreenBoldStyle(styleProps),
          [TagType.ORANGE]: tagOrangeStyle(styleProps),
          [TagType.ORANGE_INVERTED]: tagInvertedOrangeStyle(styleProps),
          [TagType.ORANGE_BOLD]: tagOrangeBoldStyle(styleProps),
        }[type]
      }
    >
      {children}
    </span>
  );
};
