import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useEffect, useRef } from 'react';

import Snackbar from './components/Snackbar';
import { useNotifications } from './NotificationsProvider';

const Snackbars = () => {
  const { removeSnackbar, snackbar } = useNotifications();
  const { snackbar_dismiss } = useLabels();

  const timeout = useRef<number>();

  useEffect(() => {
    if (snackbar) {
      const timeoutSnackbar = () => {
        clearTimeout(timeout.current);
        timeout.current = window.setTimeout(() => {
          removeSnackbar();
        }, snackbar.timeout || 3500);
      };

      !!snackbar && !snackbar.showDismissButton && timeoutSnackbar();
    }
  }, [snackbar, removeSnackbar]);

  return snackbar ? (
    <Snackbar
      type={snackbar.type}
      content={snackbar.content}
      autoDismissTime={snackbar.timeout}
      showDismissButton={snackbar.showDismissButton}
      dismissActionTranslation={snackbar_dismiss}
      dismissActionHandler={removeSnackbar}
      isStacked={snackbar.isStacked}
    />
  ) : null;
};

export default Snackbars;
