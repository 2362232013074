import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';

import { User } from '../../';
import {
  UpdateUserPreferencesForCustomerMutation,
  UpdateUserPreferencesForCustomerMutationVariables,
  UserQuery,
  UserQueryVariables,
} from '../../generated-types/graphql';
import { DeepPartial } from '../../utils/TypeScriptHelpers';
import { ContextAbstract } from '../ContextAbstract';
import { UPDATE_USER_PREFERENCES_FOR_CUSTOMER } from './mutations/updateUserPreferencesForCustomer';
import { USER } from './queries/user';

export type UserPreferencesForCustomer =
  UpdateUserPreferencesForCustomerMutationVariables['preferences'];
export type CustomerId = UpdateUserPreferencesForCustomerMutationVariables['customerId'];

export class UserDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {
    User: {
      keyFields: ['email'],
    },
  };

  public updateUserPreferencesForCustomer(
    customerId: CustomerId,
    preferences: UserPreferencesForCustomer
  ) {
    return this._apolloClient.mutate<
      UpdateUserPreferencesForCustomerMutation,
      UpdateUserPreferencesForCustomerMutationVariables
    >({
      variables: { customerId, preferences },
      mutation: UPDATE_USER_PREFERENCES_FOR_CUSTOMER,
    });
  }

  public updateUserPreferencesForCustomerLazy() {
    const mutation = UPDATE_USER_PREFERENCES_FOR_CUSTOMER;
    const execute = (customerId: CustomerId, preferences: UserPreferencesForCustomer) =>
      this.updateUserPreferencesForCustomer(customerId, preferences);

    return {
      execute,
      mutation,
    };
  }

  public optimisticSetUser(partialUser: DeepPartial<User>) {
    if (!partialUser.email) {
      throw new Error(`Invalid User, must include an 'email'`);
    }

    const currentUser = this._apolloClient.cache.read<UserQuery>({
      query: USER,
      optimistic: true,
    });
    if (!currentUser || !currentUser.getUser) {
      throw new Error(`Cannot optimistic set User without a user in cache`);
    }

    const nextCustomers = currentUser.getUser.customers.map((c) => {
      const updatedCustomer = partialUser.customers?.find((pc) => pc?.id === c.id);
      return {
        ...c,
        ...updatedCustomer,
      };
    });

    const user: DeepPartial<User> = {
      ...currentUser.getUser,
      email: partialUser.email,
      ...partialUser,
      customers: partialUser.customers ? nextCustomers : currentUser.getUser.customers,
    };

    return this._apolloClient.writeQuery<DeepPartial<UserQuery>, UserQueryVariables>({
      query: USER,
      data: {
        getUser: {
          ...user,
        },
      },
    });
  }
}
