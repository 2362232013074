import { css } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';

const optionStyle = css({
  width: '100%',
  color: designToken.text.default,
  backgroundColor: designToken.background.blue,
  height: 50,
  textAlign: 'left',
  paddingLeft: '30%',
  '&:hover': {
    opacity: 0.9,
  },
  svg: {
    height: 20,
    width: 20,
    fill: designToken.secondary.default,
    marginLeft: baseSpacing,
    transform: 'translateY(3px)',
    display: 'inline-block',
  },
  [media.large]: {
    minWidth: 160,
    height: 40,
    backgroundColor: designToken.background.blue,
    paddingLeft: baseSpacing * 2,
    '&:hover, :focus': {
      opacity: 0.8,
      outline: 'none',
    },
  },
});
interface ILanguageSelectorOptionProps {
  languageName: string;
  isChecked: boolean;
  onClickHandler: () => void;
}

const LanguageSelectorOption = ({
  languageName,
  onClickHandler,
  isChecked,
}: ILanguageSelectorOptionProps) => {
  return (
    <button
      onClick={onClickHandler}
      css={optionStyle}
    >
      {languageName}
      {isChecked && <Icon type={IconType.CHECKMARK} />}
    </button>
  );
};

export default LanguageSelectorOption;
