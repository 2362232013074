import { useRef, useState } from 'react';

import { KEYCODE_STRING } from '../../constants/keyboardEvents';
import { Icon, IconType } from '../Icon';
import {
  disabledHideOverflowStyle,
  hideOverflowStyle,
  selectBoxOptionsContainerStyle,
  selectBoxStyle,
  selectContainerStyle,
  selectOptionStyle,
} from './styles';

interface ISelectBoxValues<T> {
  /** The visible text in the select option. */
  displayText: string;
  /** The value that gets returned to the 'optionsSelectHandler' function. */
  value: T;
  /** Indent the displayText a given number of steps (if e.g. an option is part of a group under another option). */
  indent?: number;
}

interface ISelectBoxProps<T> {
  /** All visible values for the select box. */
  values: ISelectBoxValues<T>[];
  /** The values that will show as selected. */
  selectedValues?: T | T[];
  /** Function that will receive the selected value(s) when they change. */
  optionsChangeHandler: (values: T | T[]) => void;
  /**
   * Text to show in select box.
   * (If multi is false and an option is selected, the option will be shown.)
   * */
  label: string;
  /**
   * Disabling the select box will change its appearance and make it unresponsive to user input.
   * Defaults to false. True if 'values' array is empty. */
  disabled?: boolean;
  /** Will give the select box a red border. (Border on focus will still be blue.) */
  invalid?: boolean;
  /** Select box will have no visible border. Option container will still have dropshadow.  */
  hideBorder?: boolean;
  /** Allow selection of multiple values. Default is false. */
  allowMultiple?: boolean;
}

export function SelectBox<T>({
  values,
  selectedValues,
  optionsChangeHandler,
  label,
  disabled,
  invalid,
  hideBorder,
  allowMultiple = false,
}: ISelectBoxProps<T>) {
  const [isOpen, setIsOpen] = useState(false);

  const triggerContainerRef = useRef<HTMLDivElement>(null);
  const firstOptionRef = useRef<HTMLDivElement>(null);

  const isDisabled = disabled || values.length === 0;

  const containerKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KEYCODE_STRING.ESCAPE) {
      setIsOpen(false);
    }
    if ((e.key === KEYCODE_STRING.ENTER || e.key === KEYCODE_STRING.ARROW_DOWN) && !isOpen) {
      setIsOpen(true);
      firstOptionRef.current?.focus();
      e.preventDefault();
    }
  };

  const optionKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>, value: T) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      optionSelectHandler(value);
    }
    const currentActive = document.activeElement;
    if (e.key === KEYCODE_STRING.ARROW_DOWN) {
      (currentActive?.nextElementSibling as HTMLDivElement)?.focus();
      e.preventDefault();
    }
    if (e.key === KEYCODE_STRING.ARROW_UP) {
      (currentActive?.previousElementSibling as HTMLDivElement)?.focus();
      e.preventDefault();
    }
  };

  const containerMouseUpHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!triggerContainerRef.current || !(e.target instanceof HTMLElement)) {
      return;
    }

    /** Toggles open state.
     *  Will only re-close when clicking container/header and not the options.  */
    if (
      triggerContainerRef.current.contains(e.target) ||
      e.target === triggerContainerRef.current
    ) {
      setIsOpen(!isOpen);
    }
  };

  const containerBlurHandler = (e: React.FocusEvent<HTMLDivElement>) => {
    /**
     * When container loses focus, e.g. by clicking outside, close the dropdown.
     * UNLESS it loses focus to one of its children. */
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  const optionSelectHandler = (value: T) => {
    if (allowMultiple && Array.isArray(selectedValues)) {
      const updatedValues = [...selectedValues];
      if (updatedValues.includes(value)) {
        updatedValues.splice(updatedValues.indexOf(value), 1);
      } else {
        updatedValues.push(value);
      }
      optionsChangeHandler(updatedValues);
    } else {
      optionsChangeHandler(value);
      setIsOpen(false);
    }
  };

  const isOptionSelected = (option: T) => {
    if (Array.isArray(selectedValues)) {
      return selectedValues.includes(option);
    } else {
      return option === selectedValues;
    }
  };

  const outputLabel = () => {
    if (!allowMultiple && selectedValues && !isOpen) {
      return values.find((options) => options.value === selectedValues)?.displayText;
    } else {
      return label;
    }
  };

  let overflowStyles = [hideOverflowStyle];
  if (isDisabled) {
    overflowStyles.push(disabledHideOverflowStyle);
  }
  if (hideBorder) {
    overflowStyles = [];
  }

  return (
    <div
      role="listbox"
      tabIndex={isDisabled ? -1 : 0}
      onMouseUp={containerMouseUpHandler}
      onBlur={containerBlurHandler}
      onKeyDown={containerKeyDownHandler}
      css={selectContainerStyle(isDisabled, hideBorder)}
    >
      <div
        ref={triggerContainerRef}
        css={selectBoxStyle(isDisabled, invalid, hideBorder)}
      >
        <span css={overflowStyles}>{outputLabel()}</span>
      </div>
      <div css={selectBoxOptionsContainerStyle(isOpen && !isDisabled)}>
        {values.map((option, idx) => {
          return (
            <div
              ref={idx === 0 ? firstOptionRef : null}
              css={selectOptionStyle(option.indent)}
              onClick={() => {
                optionSelectHandler(option.value);
              }}
              /** Using index for key, since the data model is unknown.
               *  Should not cause problems since order of options is unlikely to change. */
              key={`select-option-${idx}`}
              role="option"
              tabIndex={-1}
              aria-selected={isOptionSelected(option.value)}
              onKeyDown={(e) => {
                optionKeyDownHandler(e, option.value);
              }}
            >
              {option.displayText}
              {isOptionSelected(option.value) && <Icon type={IconType.CHECKMARK} />}
            </div>
          );
        })}
      </div>
      <Icon type={IconType.CHEVRON_DOWN} />
    </div>
  );
}
