// Matomo-specific code
// See: https://developer.matomo.org/guides/tracking-javascript-guide
// Code from Toyfair: https://github.com/LEGO/lac-toyfair-frontend/blob/master/src/services/tracking.service.tsx
import { useState } from 'react';

import { SiteId } from '../constants';

declare global {
  interface Window {
    _paq: [string, ...unknown[]][] | undefined;
  }
}

type Instruction =
  | 'disableCookies'
  | 'enableHeartBeatTimer'
  | 'enableLinkTracking'
  | 'forgetCookieConsentGiven'
  | 'setCookieConsentGiven'
  | 'setCookieDomain'
  | 'setCustomUrl'
  | 'setDocumentTitle'
  | 'setDomains'
  | 'setSecureCookie'
  | 'setSiteId'
  | 'setTrackerUrl'
  | 'setRequestMethod'
  | 'setUserId'
  | 'trackEvent'
  | 'trackPageView'
  | 'rememberCookieConsentGiven'
  | 'requireCookieConsent';

interface ImatomoConfig {
  siteId: string;
  cookieDomain: string;
  trackerBaseUrl: string;
}

export const pushInstruction = (
  instruction: Instruction,
  ...args: Array<string | number | boolean | undefined>
) => {
  if (window === undefined) {
    return;
  }
  window._paq?.push([instruction, ...args]);
};

export const useMatomo = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const insertScriptIntoDom = (scriptUrl: string): void => {
    const doc = document;
    const scriptElement = doc.createElement('script');
    const scripts = doc.getElementsByTagName('script')[0];

    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = scriptUrl;
    scriptElement.id = 'matomo';
    scriptElement.addEventListener('load', () => {
      setIsScriptLoaded(true);
    });

    if (scripts && scripts.parentNode) {
      scripts.parentNode.insertBefore(scriptElement, scripts);
    }

    scriptElement.removeEventListener('load', () => {
      setIsScriptLoaded(true);
    });
  };

  const initializeMatomo = (matomoConfig: ImatomoConfig) => {
    if (window === undefined) {
      return;
    }

    window._paq = window._paq || [];
    if (window._paq.length !== 0) {
      return;
    }
    pushInstruction('setCookieDomain', matomoConfig.cookieDomain);
    pushInstruction('setDomains', matomoConfig.cookieDomain);
    pushInstruction('setTrackerUrl', `${matomoConfig.trackerBaseUrl}/matomo.php`);
    pushInstruction('setSiteId', matomoConfig.siteId);
    pushInstruction('setSecureCookie', matomoConfig.siteId === SiteId.PROD);
    pushInstruction('setRequestMethod', 'POST');
    pushInstruction('enableHeartBeatTimer', 15);
    pushInstruction('enableLinkTracking', true);

    insertScriptIntoDom(`${matomoConfig.trackerBaseUrl}/matomo.js`);
  };

  return { isScriptLoaded, initializeMatomo };
};
