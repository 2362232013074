import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartItemRemoved } from '@lego/b2b-unicorn-data-access-layer';
import {
  Button,
  designToken,
  Icon,
  IconType,
  Modal,
  ModalActions,
  ModalHeader,
  ModalTableBody,
} from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { TableRowRemovedItem } from '../../../shop/components/TableRowRemovedItem';

type OrderRemovedItemsProps = {
  items: CartItemRemoved[];
  closePopup: () => void;
};

const OrderRemovedItems = ({ items, closePopup }: OrderRemovedItemsProps) => {
  const {
    popup_invalid_items_header,
    popup_invalid_items_content,
    button_ok,
    item_id,
    name,
    quantity,
    reason_message,
  } = useLabels();

  return (
    <Modal isOpen={true}>
      <ModalHeader subHeader={`${length} ${popup_invalid_items_content}`}>
        <Icon
          type={IconType.ALERT_OUTLINE}
          color={designToken.error.default}
        />
        {popup_invalid_items_header}
      </ModalHeader>
      <ModalTableBody headers={[item_id, name, quantity, reason_message]}>
        {items.map((item) => (
          <TableRowRemovedItem
            key={item.materialId}
            materialId={item.materialId}
            itemNumber={item.itemNumber}
            reason={item.reason}
            name={item.name}
            quantity={item.quantity}
          />
        ))}
      </ModalTableBody>
      <ModalActions>
        <Button
          size="small"
          onClick={closePopup}
        >
          {button_ok}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default OrderRemovedItems;
