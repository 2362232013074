import { css, keyframes } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const loaderContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: designToken.transparent.default,
  zIndex: 100,
  flexDirection: 'column',
});

const loaderStyle = css({
  borderWidth: 4,
  borderStyle: 'solid',
  borderColor: designToken.information.muted,
  borderTopColor: designToken.border.information,
  borderRadius: 60,
  width: 120,
  height: 120,
  animationName: spinAnimation,
  animationDuration: '1s',
  animationDirection: 'forwards',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
});

const capitalizeStyle = css({
  display: 'inline-block',
  '&::first-letter': {
    textTransform: 'capitalize',
  },
});

interface ILoader {
  translations: {
    loading: string;
  };
}

const Loader = ({ translations: { loading } }: ILoader) => {
  return (
    <div css={loaderContainerStyle}>
      <div css={loaderStyle}></div>
      <div>
        <br />
        &nbsp;&nbsp;<span css={capitalizeStyle}>{loading}</span>...
      </div>
    </div>
  );
};

export default Loader;
