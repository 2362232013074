import { Bootstrap } from '@lego/b2b-unicorn-bootstrap';

import { FatalErrorBoundary } from './components/FatalErrorBoundary';
import SelectCustomerPage from './pages/SelectCustomerPage';
import { TermsOfSalesPage } from './pages/TermsOfSalesPage';
import RootRouter from './RootRouter';

const App = () => {
  return (
    <Bootstrap
      customerSelectorComponent={SelectCustomerPage}
      termsOfSalesConsentComponent={TermsOfSalesPage}
      fatalErrorBoundaryComponent={FatalErrorBoundary}
    >
      <RootRouter />
    </Bootstrap>
  );
};

export default App;
