import '@adyen/adyen-web/dist/adyen.css';

import AdyenCheckout from '@adyen/adyen-web';
import type { DropinElementProps as AdyenDropinOptions } from '@adyen/adyen-web/dist/types/components/Dropin/types';
import type { CoreOptions as AdyenConfiguration } from '@adyen/adyen-web/dist/types/core/types';
import React, { useLayoutEffect, useRef } from 'react';

import adyenStyling from './adyenStyling';

interface CardPaymentBrickBankDropinProps {
  adyenConfiguration: AdyenConfiguration;
  dropinOptions: AdyenDropinOptions;
  onError: (e: string | Error) => void;
}
const CardPaymentBrickBankDropin: React.FC<CardPaymentBrickBankDropinProps> = (props) => {
  const { dropinOptions, adyenConfiguration, onError } = props;
  const dropinContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const initAdyenCheckout = async () => {
      const adyenCheckout = await AdyenCheckout(adyenConfiguration);
      const dropin = adyenCheckout.create('dropin', dropinOptions);

      if (!dropinContainerRef.current) {
        throw Error(`Unable to find DOM element for dropin!`);
      }

      dropin.mount(dropinContainerRef.current);
    };

    initAdyenCheckout().catch((e) => {
      onError(e);
    });
  }, []);

  return (
    <div css={adyenStyling}>
      <div ref={dropinContainerRef} />
    </div>
  );
};

export default CardPaymentBrickBankDropin;
