import { Global } from '@emotion/react';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Spreadsheet, { EmptySelection, RangeSelection, Selection } from 'react-spreadsheet';

import { Portal } from '../../../../ui/Portal';
import { baseSpacing } from '../../../../ui/theme';
import { ToolTip, ToolTipDirection } from '../../../../ui/ToolTip';
import {
  bulkAddEditableSpreadsheetDataBaseCss,
  bulkAddEditableSpreadsheetDataEditorCss,
  bulkAddEditableSpreadsheetHeaderCellCss,
  bulkAddEditableSpreadsheetMainClassName,
  bulkAddEditableSpreadsheetProductColumnHeaderCss,
  bulkAddEditableSpreadsheetRemoveFirstColumnHeaderCss,
} from '../../styles';
import type { DataRow } from '../../types';
import { BulkQuickAddDataEditor } from '../BulkQuickAddDataEditor';
import { BulkQuickAddEditableColumnIndicator } from '../BulkQuickAddEditableColumnIndicator';
import { StatusRowIndicator } from '../cells';

const findCell = (row: number, column: number): HTMLTableCellElement | null => {
  const rowElement = document.querySelector(
    `.${bulkAddEditableSpreadsheetMainClassName} tr[row="${row}"] td:nth-child(${column + 2})`
  );

  return rowElement instanceof HTMLTableCellElement ? rowElement : null;
};

type BulkAddEditableSpreadsheetProps = {
  dataRows: DataRow[];
  onChange: (data: DataRow[]) => void;
  onContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;
  contextMenuToolTipLabel: string;
};

export const BulkAddEditableSpreadsheet: React.FC<BulkAddEditableSpreadsheetProps> = ({
  onChange,
  dataRows,
  onContextMenu,
  contextMenuToolTipLabel,
}) => {
  const currentSelection = useRef<RangeSelection | EmptySelection | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [popoverPosition, setPopoverPosition] = useState<{ x: number; y: number } | null>(null);

  useLayoutEffect(() => {
    const firstCell = findCell(0, 0);
    if (!firstCell || currentSelection.current) {
      return;
    }

    // To allow the browser to paint the first cell before selecting it
    setTimeout(() => {
      // Make sure we only select a cell if the user hasn't already selected something
      if (currentSelection.current) {
        return;
      }

      const mouseEvent = new Event('mousedown', { bubbles: true });
      firstCell.dispatchEvent(mouseEvent);
    });
  }, []);

  useLayoutEffect(() => {
    const currentRef = ref.current;

    const onDocumentMousedownListener = () => {
      setPopoverPosition(null);

      document.removeEventListener('mousedown', onDocumentMousedownListener);
    };

    const onContextMenuListener = (event: MouseEvent) => {
      event.preventDefault();
      const currentSelectionValue = currentSelection.current;

      setPopoverPosition({ x: event.pageX, y: event.pageY });
      setTimeout(() => {
        if (currentSelectionValue && !(currentSelectionValue instanceof EmptySelection)) {
          const row = findCell(
            currentSelectionValue.range.start.row,
            currentSelectionValue.range.start.column
          );
          if (!row) {
            return;
          }
          const mouseEvent = new Event('mousedown', { bubbles: true });
          row.dispatchEvent(mouseEvent);
        }

        document.addEventListener('mousedown', onDocumentMousedownListener);
      }, 250);

      if (onContextMenu) {
        onContextMenu(event as never);
      }
    };

    if (currentRef) {
      currentRef.addEventListener('contextmenu', onContextMenuListener);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('contextmenu', onContextMenuListener);
        document.removeEventListener('mousedown', onDocumentMousedownListener);
      }
    };
  }, [onContextMenu]);

  const handleOnSelect = (selection: Selection) => {
    currentSelection.current = selection as RangeSelection;
  };

  return (
    <div ref={ref}>
      {popoverPosition && (
        <Portal portalId={'bulk-quick-add-popover'}>
          <div
            css={{
              position: 'absolute',
              top: popoverPosition.y - baseSpacing * 2,
              left: popoverPosition.x + baseSpacing * 1.5,
              zIndex: 1500,
              pointerEvents: 'none',
            }}
          >
            <ToolTip
              message={contextMenuToolTipLabel}
              direction={ToolTipDirection.Right}
              open
            />
          </div>
        </Portal>
      )}
      <Global
        styles={[
          bulkAddEditableSpreadsheetDataBaseCss,
          bulkAddEditableSpreadsheetDataEditorCss,
          bulkAddEditableSpreadsheetRemoveFirstColumnHeaderCss,
          bulkAddEditableSpreadsheetHeaderCellCss,
          bulkAddEditableSpreadsheetProductColumnHeaderCss,
        ]}
      />
      <Spreadsheet
        data={dataRows}
        RowIndicator={StatusRowIndicator}
        ColumnIndicator={BulkQuickAddEditableColumnIndicator}
        onChange={onChange as never}
        className={bulkAddEditableSpreadsheetMainClassName}
        DataEditor={BulkQuickAddDataEditor}
        onSelect={handleOnSelect}
      />
    </div>
  );
};
