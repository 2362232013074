import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';

export const tagStyle = css({
  display: 'inline-flex',
  background: designToken.background.white,
  paddingTop: 2,
  paddingRight: baseSpacing,
  paddingBottom: 2,
  paddingLeft: baseSpacing,
  marginRight: baseSpacing,
  marginBottom: baseSpacing,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: designToken.border.default,
  borderRadius: 16,
  fontSize: font.size.small,
  cursor: 'pointer',
  outline: 'none',
  '> svg': {
    width: 10,
    marginLeft: baseSpacing,
    fill: designToken.interactive.default,
  },
  '&:focus': {
    borderColor: designToken.interactive.hover,
    '> svg': {
      fill: designToken.interactive.hover,
    },
  },
  '&:hover svg': {
    fill: designToken.interactive.hover,
  },
});

export const clearAllTagStyle = css(tagStyle, {
  backgroundColor: designToken.interactive.default,
  color: designToken.text.inverse,
  borderColor: designToken.interactive.default,
  '&:focus, :hover': {
    backgroundColor: designToken.interactive.hover,
    borderColor: designToken.interactive.hover,
  },
});
