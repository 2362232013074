/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductAbstract } from './ProductAbstract';

export class Box extends ProductAbstract {
  public __typename: 'Box' | undefined;
  constructor() {
    super();
    this.__typename = 'Box';
  }
}
