import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { TableHead, TableHeaderCell } from '@lego/b2b-unicorn-shared/ui';
import { SortingButton } from '@lego/b2b-unicorn-ui-components';
import { ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

type PromotionTableHeaderProps = {
  activeSortingField: ProductTableColumnType;
  setActiveSortingField: (column: ProductTableColumnType) => void;
  isAscending: boolean;
  inactive: boolean;
  setIsAscending: (isAscending: boolean) => void;
};

export const PromotionTableHeader: React.FC<PromotionTableHeaderProps> = ({
  activeSortingField,
  setActiveSortingField,
  isAscending,
  inactive,
  setIsAscending,
}) => {
  const {
    item_id,
    name,
    theme,
    price_per_piece,
    quantity,
    total_pieces,
    your_price,
    price_pieces_per_case_pack,
  } = useLabels();

  const updateSorting = (column: ProductTableColumnType, isAscending: boolean) => {
    setActiveSortingField(column);
    setIsAscending(isAscending);
  };

  return (
    <TableHead>
      <tr>
        <TableHeaderCell
          sortable
          width={100}
        >
          <SortingButton
            isActive={ProductTableColumnType.ITEM_ID === activeSortingField}
            isAscending={isAscending}
            columnName={ProductTableColumnType.ITEM_ID}
            onClickHandler={updateSorting}
          />
          {item_id}
        </TableHeaderCell>
        <TableHeaderCell>{name}</TableHeaderCell>
        <TableHeaderCell sortable>
          <SortingButton
            isActive={ProductTableColumnType.THEME === activeSortingField}
            isAscending={isAscending}
            columnName={ProductTableColumnType.THEME}
            onClickHandler={updateSorting}
          />
          {theme}
        </TableHeaderCell>
        {!inactive && (
          <>
            <TableHeaderCell
              align={'center'}
              width={170}
            >
              {quantity}
            </TableHeaderCell>
            <TableHeaderCell align={'center'}>{total_pieces}</TableHeaderCell>
          </>
        )}
        <TableHeaderCell align={'right'}>{price_per_piece}</TableHeaderCell>
        <TableHeaderCell align={'right'}>{price_pieces_per_case_pack}</TableHeaderCell>
        {!inactive && (
          <TableHeaderCell
            align={'right'}
            sortable
          >
            <SortingButton
              isActive={ProductTableColumnType.YOUR_PRICE === activeSortingField}
              isAscending={isAscending}
              columnName={ProductTableColumnType.YOUR_PRICE}
              onClickHandler={updateSorting}
            />
            {your_price}
          </TableHeaderCell>
        )}
      </tr>
    </TableHead>
  );
};
