import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';

const tableHeaderStyle = css({
  fontWeight: font.weight.bold,
  background: colors.klik.slate50,
  height: 50,
  padding: baseSpacing,
  textAlign: 'left',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: colors.klik.slate100,
  'div.unicorn-tablehead-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.align-center': {
      justifyContent: 'center',
    },
    '&.align-right': {
      justifyContent: 'flex-end',
    },
  },
  display: 'none',
  [media.medium]: {
    display: 'table-header-group',
  },
  tr: {
    td: {
      lineHeight: 'inherit',
    },
  },
});

const tableRowStyle = css({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: colors.klik.slate100,
  paddingTop: 0,
  paddingBottom: baseSpacing,
  [media.medium]: {
    display: 'table-row',
    position: 'initial',
    borderBottomWidth: 0,
    paddingBottom: 0,
  },
});

const tableRowCellStyle = css({
  display: 'flex',
  width: '100%',
  lineHeight: 1.25,
  paddingBottom: baseSpacing,
  '&:before': {
    display: 'inline-block',
    position: 'relative',
    content: 'attr(data-col) ": "',
    width: '30%',
    flexShrink: 0,
    textAlign: 'right',
    paddingRight: '1em',
  },
  [media.medium]: {
    display: 'table-cell',
    verticalAlign: 'middle',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.klik.slate100,
    padding: baseSpacing,
    lineHeight: '38px',
    width: 'auto',
    '&:before': {
      display: 'none',
    },
  },
});

export const tableStyle = css({
  table: {
    width: '100%',
    fontSize: 14,
    color: designToken.text.default,
    thead: tableHeaderStyle,
    tr: tableRowStyle,
    td: tableRowCellStyle,
  },
});
