import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import { Fragment } from 'react';

import { Banner } from '../../shared/Components/Notifications';
import ProductDetailsPage from '../components/ProductDetailsPage/ProductDetailsPage';

interface IProductDetailsLayout {
  materialId: number;
  onProductLoaded?: () => void;
}

const ProductDetailsLayout = ({ materialId, onProductLoaded }: IProductDetailsLayout) => {
  return (
    <Fragment>
      <TopbarImplementation />
      <Banner />
      <ProductDetailsPage
        materialId={materialId}
        onProductLoaded={onProductLoaded}
      />
    </Fragment>
  );
};

export default ProductDetailsLayout;
