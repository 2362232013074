import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useCreateClaim, useInvoice } from '@lego/b2b-unicorn-data-access-layer/react';
import { ClaimProvider } from '@lego/b2b-unicorn-shared/components/Claim';
import { ClaimFromValidationSchema } from '@lego/b2b-unicorn-shared/components/Claim/validation';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  Heading,
  PageBody,
  PageHeader,
  Spacer,
  SubpageHeaderNav,
} from '@lego/b2b-unicorn-shared/ui';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';

import {
  AdditionalFields,
  ClaimSummary,
  ClaimWarningPrompt,
  InvoicedItemsList,
  SkeletonCreateClaim,
} from './components';
import { mapClaimFromSchemaToCreateClaimInput } from './helpers';
import { pageFlowStyle } from './styles';

type CreateClaimProps = {
  invoiceData: ReturnType<typeof useInvoice>['data'];
  customerId: number;
  isLoading: boolean;
};
export const CreateClaim: React.FC<CreateClaimProps> = ({ invoiceData, customerId, isLoading }) => {
  const history = useHistory();
  const { create_claim, button_go_to_overview } = useLabels();
  const { id: customerID } = useSelectedCustomer();

  const { mutate: createClaim } = useCreateClaim(customerID);

  const submitClaim = (claimValidationResult: ClaimFromValidationSchema | ValidationError[]) => {
    const claimData = Array.isArray(claimValidationResult)
      ? undefined
      : mapClaimFromSchemaToCreateClaimInput(claimValidationResult);
    if (claimData === undefined) {
      throw new Error('Claim data is not valid');
    }
    return createClaim({ claimData });
  };

  return (
    <Fragment>
      <PageHeader>
        <SubpageHeaderNav
          goBackText={button_go_to_overview}
          onGoBack={() => history.push('/manage/invoices')}
        >
          <Heading
            level={1}
            designToken={'text.inverse'}
          >
            {create_claim}
          </Heading>
        </SubpageHeaderNav>
      </PageHeader>

      <PageBody>
        {isLoading && <SkeletonCreateClaim />}
        {invoiceData && invoiceData.invoice && (
          <ClaimProvider
            invoiceData={invoiceData.invoice}
            customerId={customerId}
            onSubmitClaim={submitClaim}
          >
            <Spacer />
            <ClaimSummary />
            <Spacer />
            <div css={pageFlowStyle}>
              <AdditionalFields />
              <InvoicedItemsList />
            </div>
            <ClaimWarningPrompt />
          </ClaimProvider>
        )}
      </PageBody>
    </Fragment>
  );
};
