import { gql } from '@apollo/client';

export const UPDATE_REPLENISHMENT_CART = gql`
  mutation updateReplenishmentCart($customerId: Int!, $materialId: Int!, $quantity: Int!) {
    updateCart(
      customerId: $customerId
      materialId: $materialId
      quantity: $quantity
      cartReference: { cartType: REPLENISH }
    ) {
      ...cartKey
      items {
        ...minimumCartItem
      }
    }
  }
`;
