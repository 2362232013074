import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { designToken } from '../theme';

const backgroundTransitionProperties = {
  transitionDuration: '100ms',
  transitionProperty: 'background-color',
  transitionTimingFunction: 'ease-in-out',
};

const windowTileStyle = (active: boolean) =>
  css({
    all: 'unset',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    backgroundColor: tokens.color.core.gray[10],
    margin: 0,
    borderRadius: 8,
    ...backgroundTransitionProperties,
    '> div': {
      ...backgroundTransitionProperties,
      '> span': {
        transitionDuration: '100ms',
        transitionProperty: 'color',
        transitionTimingFunction: 'ease-in-out',
        '> svg': {
          transitionDuration: '150ms',
          transitionProperty: 'all',
          transitionTimingFunction: 'ease-in-out',
          transform: 'translate(0, 0)',
        },
      },
    },
    '&:hover, :focus': {
      cursor: 'pointer',
      '> div:first-of-type': {
        backgroundColor: active ? tokens.color.core.azur[800] : tokens.color.core.blue[100],
        '> span:last-of-type': {
          color: active ? tokens.color.core.blue[100] : designToken.interactive.hover,
        },
      },
      '> div:last-of-type span:last-of-type': {
        '> svg': {
          transform: 'translate(3px, 0)',
        },
      },
    },
  });

type TileProps = {
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

export const Tile: React.FC<TileProps> = ({ isActive, onClick, children }) => {
  return (
    <li>
      <article
        onClick={onClick}
        css={windowTileStyle(isActive)}
      >
        {children}
      </article>
    </li>
  );
};
