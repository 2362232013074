import type {
  CartReferenceInput,
  InvalidItem,
  ProductValidationInput,
  ValidItem,
} from '@lego/b2b-unicorn-data-access-layer';
import { useValidateProducts } from '@lego/b2b-unicorn-data-access-layer/react';
import { useCallback, useMemo } from 'react';

interface UseProductsValidationResults {
  validItems: ValidItem[];
  invalidItems: InvalidItem[];
}

type ReturnOfValidateItems = ReturnType<ReturnType<typeof useValidateProducts>['mutate']>;
type UseProductsValidationReturn = [
  (items: ProductValidationInput[]) => ReturnOfValidateItems,
  UseProductsValidationResults | undefined,
  boolean,
  unknown,
];

export { InvalidItem, ValidItem };

export const useProductsValidation = (
  customerId: number,
  cartReference: CartReferenceInput
): UseProductsValidationReturn => {
  const {
    mutate: validateItemsLazyQuery,
    data,
    loading,
    error,
  } = useValidateProducts(customerId, cartReference);

  const validateItems = useCallback(
    (items: ProductValidationInput[]) => {
      return validateItemsLazyQuery({
        customerId,
        items,
        cartReference,
      });
    },
    [cartReference, customerId, validateItemsLazyQuery]
  );

  const results = useMemo<UseProductsValidationResults | undefined>(() => {
    if (!data || loading) {
      return;
    }

    return {
      invalidItems: data.validateProducts.invalidItems,
      validItems: data.validateProducts.validItems,
    };
  }, [data, loading]);

  return useMemo(
    () => [validateItems, results, loading, error],
    [error, loading, results, validateItems]
  );
};
