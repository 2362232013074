import React from 'react';

import { Button, ButtonType, Icon, IconType } from '../../../ui';

type BulkQuickAddTriggerButtonProps = {
  handleOnBulkQuickAddOpenClick: () => void;
  translations: {
    button_bulk_quick_add: string;
  };
  disabled?: boolean;
  buttonType?: ButtonType;
  buttonSize?: 'small' | 'medium' | 'large';
};
export const BulkQuickAddTriggerButton: React.FC<BulkQuickAddTriggerButtonProps> = ({
  handleOnBulkQuickAddOpenClick,
  translations,
  disabled = false,
  buttonType = ButtonType.GHOST_INVERTED,
  buttonSize = 'medium',
}) => {
  return (
    <Button
      size={buttonSize}
      type={buttonType}
      onClick={handleOnBulkQuickAddOpenClick}
      disabled={disabled}
    >
      <Icon type={IconType.LIST_PLUS} />
      <span>{translations.button_bulk_quick_add}</span>
    </Button>
  );
};
