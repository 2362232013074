import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from '../AnalyticsProvider';
import { sanitizePathname } from '../utils/sanitizePathname';

interface IPageViewTracker {
  blacklistedRoutes?: string | string[];
}

export const PageViewTracker: FunctionComponent<IPageViewTracker> = ({ blacklistedRoutes }) => {
  const location = useLocation();
  const { customTrackPageView } = useAnalytics();

  const sanitizedPathname = sanitizePathname(location.pathname);

  useEffect(() => {
    if (
      (Array.isArray(blacklistedRoutes) && blacklistedRoutes.indexOf(sanitizedPathname) > -1) ||
      sanitizedPathname === blacklistedRoutes
    ) {
      return;
    }

    customTrackPageView(document.title);
  }, [customTrackPageView, sanitizedPathname, blacklistedRoutes]);

  return null;
};
