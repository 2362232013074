import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Button, ButtonType, designToken } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import React, { useState } from 'react';

import { fourColMedia, threeColMedia, TILE_WIDTH, twoColMedia } from '../../../../constants';
import { adjustedWidthStyle } from '../../../Recommendations/styles';
import { useProductListContext } from '../../ProductListContext';
import { ITheme } from '../../types';
import { removeBlacklistedThemes } from '../../utils';
import { SkeletonThemes } from './SkeletonThemes';
import { THEME_TILE_HEIGHT, ThemeTile } from './ThemeTile';

const TILES_ROWS = 2;
const paddingBottom = baseSpacing * 6;
const calculateNumberOfRows = (tilesCount: number, numberOfColumns: number) =>
  Math.ceil(tilesCount / numberOfColumns);
const numberOfRows = (showAll: boolean, numberOfColumns: number, tilesCount?: number) =>
  showAll && tilesCount ? calculateNumberOfRows(tilesCount, numberOfColumns) : TILES_ROWS;

const backgroundStyle = (showAll: boolean, tilesCount?: number) => {
  const localRowCalculation = (numberOfColumns: number) =>
    numberOfRows(showAll, numberOfColumns, tilesCount);
  const calculateHeightBasedOnColumns = (numberOfColumns: number) =>
    THEME_TILE_HEIGHT * localRowCalculation(numberOfColumns) +
    baseSpacing * 2 * localRowCalculation(numberOfColumns) +
    paddingBottom;
  return css({
    width: '100%',
    height: calculateHeightBasedOnColumns(1),
    paddingTop: baseSpacing * 3,
    background: designToken.background.blue,
    [`@media (min-width: ${twoColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(2),
    },
    [`@media (min-width: ${threeColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(3),
    },
    [`@media (min-width: ${fourColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(4),
    },
  });
};

const wrapperStyle = (showAll: boolean, tilesCount?: number) => {
  const localRowCalculation = (numberOfColumns: number) =>
    numberOfRows(showAll, numberOfColumns, tilesCount);
  const calculateHeightBasedOnColumns = (numberOfColumns: number) =>
    THEME_TILE_HEIGHT * localRowCalculation(numberOfColumns) +
    baseSpacing * 2 * (localRowCalculation(numberOfColumns) - 1);
  return css(adjustedWidthStyle, {
    height: calculateHeightBasedOnColumns(1),
    display: 'grid',
    gap: baseSpacing * 2,
    justifyContent: 'center',
    gridTemplateColumns: `repeat(auto-fill, ${TILE_WIDTH}px)`,
    overflow: 'hidden',
    [`@media (min-width: ${twoColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(2),
    },
    [`@media (min-width: ${threeColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(3),
    },
    [`@media (min-width: ${fourColMedia}px)`]: {
      height: calculateHeightBasedOnColumns(4),
    },
  });
};

const buttonWrapperStyle = css(adjustedWidthStyle, {
  '> button': {
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 10,
  },
});

const isThemeSelected = (theme: ITheme, themes: string[]) =>
  themes.includes(theme.title) ||
  theme.subThemes?.some((subTheme) => themes.includes(subTheme.title)) ||
  false;

export const Themes: React.FC = () => {
  const { trackEvent } = useAnalytics();
  const [showAllTiles, setShowAllTiles] = useState<boolean>(false);
  const { button_show_more, button_show_less } = useLabels();
  const {
    filtersList: { themes: rawThemes },
    selectedFilters,
    setSelectedFilters,
    productsError,
  } = useProductListContext();
  const themes = removeBlacklistedThemes(rawThemes);

  const handleThemesFilterChange = (themes: string[]) => {
    let updatedFilters = { ...selectedFilters };
    if (themes.length) {
      updatedFilters = {
        ...selectedFilters,
        themes: themes as string[],
      };
    } else {
      delete updatedFilters.themes;
    }
    setSelectedFilters(updatedFilters);
  };
  const handleThemeSelectionChange = (theme: ITheme) => {
    const updatedThemes = [...(selectedFilters?.themes || [])];
    const themeSelected = isThemeSelected(theme, selectedFilters?.themes || []);
    const titles = theme.subThemes?.length
      ? theme.subThemes.map((subTheme) => subTheme.title)
      : [theme.title];
    if (themeSelected) {
      trackEvent({
        event: 'themeTileDeselecting',
        name: theme.title,
      });
      titles.map((title) => {
        updatedThemes.splice(updatedThemes.indexOf(title), 1);
      });
    } else {
      trackEvent({
        event: 'themeTileSelecting',
        name: theme.title,
      });
      updatedThemes.push(...titles);
    }
    handleThemesFilterChange(updatedThemes);
  };
  const handleToggleShowAllTiles = () => {
    if (showAllTiles) {
      trackEvent({
        event: 'themeTilesShowLess',
        name: 'Show less',
      });
      window.scrollTo(0, 0);
    } else {
      trackEvent({
        event: 'themeTilesShowMore',
        name: 'Show more',
      });
    }
    setShowAllTiles(!showAllTiles);
  };
  return productsError ? null : (
    <section css={backgroundStyle(showAllTiles, themes?.length)}>
      <div css={wrapperStyle(showAllTiles, themes?.length)}>
        {themes?.length ? (
          themes.map((theme) => (
            <ThemeTile
              onChange={handleThemeSelectionChange}
              theme={theme}
              isHighlighted={
                selectedFilters?.themes?.length
                  ? isThemeSelected(theme, selectedFilters.themes)
                  : true
              }
              key={theme.title}
            />
          ))
        ) : (
          <SkeletonThemes />
        )}
      </div>
      <div css={buttonWrapperStyle}>
        <Button
          size={'tiny'}
          type={ButtonType.GHOST}
          onClick={handleToggleShowAllTiles}
        >
          {showAllTiles ? button_show_less : button_show_more}
        </Button>
      </div>
    </section>
  );
};
