import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { OrderPromotionsOverviewItem } from '@lego/b2b-unicorn-data-access-layer/context/Promotions/PromotionsDataContext';
import { Date as DateComponent } from '@lego/b2b-unicorn-shared/components/Date';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Tile, TileBottom, TileButton, TileHeader } from '@lego/b2b-unicorn-shared/ui';
import React, { useCallback } from 'react';

import { orderIndicatorStyle } from '../../../launch/components/LaunchWindowsOverview/styles';

type NoLongerAvailableForOrderPromotionTileProps = {
  promotion: OrderPromotionsOverviewItem;
  onClick: (promotion: OrderPromotionsOverviewItem) => void;
};
export const NoLongerAvailableForOrderPromotionTile = ({
  promotion,
  onClick,
}: NoLongerAvailableForOrderPromotionTileProps) => {
  const { promotions_execution_dates, see_details, launch_window_existing_orders } = useLabels();

  const selectedCustomer = useSelectedCustomer();

  const handleTileClick = useCallback(() => {
    onClick(promotion);
  }, [onClick, promotion]);
  const ordersCreatedCount = promotion?.ordersCreated?.length || 0;

  return (
    <Tile
      isActive={false}
      onClick={handleTileClick}
    >
      <TileHeader active={false}>
        <h1
          style={{ textTransform: 'none' }}
          title={promotion.description}
        >
          {promotion.description}
        </h1>
        <span>{promotion.productsCount} items</span>
      </TileHeader>
      <TileBottom>
        <div>
          <span>
            {promotions_execution_dates}:{' '}
            <DateComponent locale={selectedCustomer.locale}>
              {promotion?.executionDates?.startDate}
            </DateComponent>{' '}
            -{' '}
            <DateComponent locale={selectedCustomer.locale}>
              {promotion?.executionDates?.endDate}
            </DateComponent>
          </span>
        </div>
        <span css={[orderIndicatorStyle, { position: 'absolute', bottom: 12 }]}>
          {ordersCreatedCount > 0 && `${ordersCreatedCount} ${launch_window_existing_orders}`}
        </span>
        <TileButton withAlert={false}>{see_details}</TileButton>
      </TileBottom>
    </Tile>
  );
};
