import { Money as MoneyType, Price as PriceType } from '@lego/b2b-unicorn-data-access-layer';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import React from 'react';

import { AfterDiscountPrice } from './AfterDiscountPrice';
import { BeforeDiscountPrice } from './BeforeDiscountPrice';

type SummaryPriceProps = {
  children: PriceType | MoneyType;
  locale: string;
  multiplier?: number;
};

export const SummaryPrice: React.FC<SummaryPriceProps> = ({
  children: priceOrMoneyObject,
  locale,
  multiplier = 1,
}) => {
  return (
    <div>
      <Price
        locale={locale}
        multiplier={multiplier}
        wrappers={{
          nipPrice: AfterDiscountPrice,
          grossPrice: BeforeDiscountPrice,
        }}
        priceTypes={multiplier === 0 ? ['nipPrice'] : ['nipPrice', 'grossPrice']}
        fallbackValue={'0'}
      >
        {priceOrMoneyObject}
      </Price>
    </div>
  );
};
