import { css } from '@emotion/react';
import { baseSpacing, mediaSizes } from '@lego/b2b-unicorn-ui-constants';

import SkeletonRecommendationTile from './SkeletonRecommendationTile';
import { twoColMedia, threeColMedia, fourColMedia } from '../../../../constants';
import { adjustedWidthStyle } from '../../styles';

const skeletonListStyle = css(adjustedWidthStyle, {
  maxWidth: mediaSizes.large,
  paddingTop: 0,
  paddingBottom: 0,
  '> article:not(:first-of-type)': {
    display: 'none',
  },
  [`@media (min-width: ${twoColMedia}px)`]: {
    display: 'grid',
    gridGap: baseSpacing * 2,
    justifyContent: 'left',
    gridAutoFlow: 'column',
    '> article:nth-of-type(2)': {
      display: 'flex',
    },
  },
  [`@media (min-width: ${threeColMedia}px)`]: {
    '> article:nth-of-type(3)': {
      display: 'flex',
    },
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    '> article:last-of-type': {
      display: 'flex',
    },
  },
});

export const SkeletonRecommendations = () => {
  return (
    <div css={skeletonListStyle}>
      {[...Array(4)].map((_, i) => {
        return <SkeletonRecommendationTile key={i} />;
      })}
    </div>
  );
};
