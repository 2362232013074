import { css } from '@emotion/react';
import React, { useMemo } from 'react';

import { FlexBox } from '../FlexBox';
import { Spacer } from '../Spacer';
import { baseSpacing, media } from '../theme';
import { useModalContext } from './ModalContext';

type ModalActionsProps = {
  children: React.ReactNode;
};

export const ModalActions: React.FC<ModalActionsProps> = ({ children }) => {
  const modalContext = useModalContext();

  const styles = useMemo(
    () =>
      css({
        [media.medium]: {
          '> button': { minWidth: 124 },
        },
      }),
    []
  );

  return (
    <div>
      <Spacer multiplier={2} />
      <FlexBox
        flexMedium={{ [media.small]: { flexDirection: 'row' } }}
        flexDirection={'column'}
        justifyContent={'flex-end'}
        gap={baseSpacing * 2}
        ref={modalContext.setModalActionsRef}
        cssExtend={styles}
      >
        {children}
      </FlexBox>
    </div>
  );
};
