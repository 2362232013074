import React from 'react';

import { Typography } from '../../ui/Typography';

type ClaimFormFieldHelperTextProps = {
  children: React.ReactNode;
};
export const ClaimFormFieldHelperText: React.FC<ClaimFormFieldHelperTextProps> = ({ children }) => (
  <Typography
    size={'small'}
    designToken={'text.muted'}
  >
    {children}
  </Typography>
);
