import { UserPreferencesForCustomer } from '@lego/b2b-unicorn-data-access-layer';
import {
  useDataAccessLayer,
  useUpdateUserPreferencesForCustomer,
} from '@lego/b2b-unicorn-data-access-layer/react';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { useSelectedCustomer, useUser } from './UserProvider';

type DeepNonNullable<T> = {
  [K in keyof T]: DeepNonNullable<NonNullable<T[K]>>;
};
type Language = Required<
  Omit<NonNullable<UserPreferencesForCustomer['preferredLanguage']>, '__typename'>
>;

type UserPreferencesContext = Required<
  DeepNonNullable<Omit<UserPreferencesForCustomer, '__typename'>>
> & {
  availableLanguages?: Language[];
  updateUserPreferencesForCustomer: (preferences: UserPreferencesForCustomer) => void;
  preferencesUpdating: boolean;
};
const UserPreferencesContext = createContext<UserPreferencesContext | undefined>(undefined);

type UserPreferencesProviderProps = {
  children: React.ReactNode;
};
const UserPreferencesProvider: React.FC<UserPreferencesProviderProps> = ({ children }) => {
  const dataAccessLayer = useDataAccessLayer();
  const user = useUser();
  const selectedCustomer = useSelectedCustomer();

  const [updateUserPreferencesForCustomerMutation, { loading: updatePreferencesLoading }] =
    useUpdateUserPreferencesForCustomer();

  const updateUserPreferencesForCustomer = useCallback(
    (preferences: UserPreferencesForCustomer) => {
      updateUserPreferencesForCustomerMutation({
        variables: {
          customerId: selectedCustomer.id,
          preferences,
        },
      }).then((result) => {
        if (!result.data) {
          return;
        }
        dataAccessLayer.user.optimisticSetUser(result.data.updateUserPreferencesForCustomer);
      });
    },
    [dataAccessLayer.user, selectedCustomer.id, updateUserPreferencesForCustomerMutation]
  );

  const contextValue = useMemo<UserPreferencesContext>(() => {
    const preferencesForCustomer = user.preferencesForCustomers?.find(
      (p) => p.customerId === selectedCustomer.id
    );

    const availableLanguages = selectedCustomer.availableLanguages
      ? selectedCustomer.availableLanguages.map((a) => ({
          id: a.id,
          code: a.code,
        }))
      : [];

    if (!preferencesForCustomer || !preferencesForCustomer.preferredLanguage) {
      return {
        customerId: selectedCustomer.id,
        preferredLanguage: {
          code: selectedCustomer.language,
          id: `fallback-${selectedCustomer.language}`,
        },
        availableLanguages,
        updateUserPreferencesForCustomer,
        preferencesUpdating: updatePreferencesLoading,
      };
    }

    return {
      customerId: preferencesForCustomer.customerId,
      preferredLanguage: {
        id: preferencesForCustomer.preferredLanguage.id,
        code: preferencesForCustomer.preferredLanguage.code,
      },
      availableLanguages,
      updateUserPreferencesForCustomer,
      preferencesUpdating: updatePreferencesLoading,
    };
  }, [
    selectedCustomer.availableLanguages,
    selectedCustomer.id,
    selectedCustomer.language,
    updatePreferencesLoading,
    updateUserPreferencesForCustomer,
    user.preferencesForCustomers,
  ]);

  return (
    <UserPreferencesContext.Provider value={contextValue}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

const useUserPreferences = () => {
  const preferencesContext = useContext(UserPreferencesContext);
  if (!preferencesContext) {
    throw Error(
      `No UserPreferencesContext found - Forgot to wrap the application with an '<UserPreferencesProvider />'? `
    );
  }

  return preferencesContext;
};

export { UserPreferencesProvider, useUserPreferences };
