import { css } from '@emotion/react';
import React from 'react';

import { Date } from '../../components/Date';
import { baseSpacing, designToken, font } from '../theme';

enum StockStatusTag {
  Available = 'AVAILABLE',
  ExpectedBackInStock = 'EXPECTED_BACK_IN_STOCK',
  LowStock = 'LOW_STOCK',
  NotAvailable = 'NOT_AVAILABLE',
  OutOfStock = 'OUT_OF_STOCK',
}

const tagStyle = (withText: boolean, slim?: boolean) =>
  css({
    display: 'inline-block',
    fontSize: font.size.tiny,
    height: withText && !slim ? baseSpacing * 3 : !slim ? baseSpacing * 1.5 : undefined,
    width: withText ? 'auto' : baseSpacing * 1.5,
    lineHeight: !slim ? `${baseSpacing * 3}px !important` : undefined,
    borderRadius: baseSpacing * 2,
    color: designToken.text.default,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: withText ? baseSpacing : 0,
    paddingLeft: withText ? baseSpacing : 0,
    marginRight: !slim ? baseSpacing : undefined,
  });

const yellowStyle = (withText: boolean, slim?: boolean) =>
  css(tagStyle(withText, slim), {
    backgroundColor: designToken.warning.subdued,
  });

const redStyle = (withText: boolean, slim?: boolean) =>
  css(tagStyle(withText, slim), {
    backgroundColor: designToken.error.subdued,
  });

const greenStyle = (withText: boolean, slim?: boolean) =>
  css(tagStyle(withText, slim), {
    backgroundColor: designToken.success.subdued,
  });

const dateStyle = css({
  fontSize: font.size.tiny,
});

const wrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
});

interface ITranslations {
  available: string;
  low_stock: string;
  expected_back_in_stock: string;
  out_of_stock: string;
  not_available: string;
}

interface AvailabilityStatusProps {
  status: StockStatusTag;
  translations: ITranslations;
  locale: string;
  type?: 'full' | 'simple';
  expectedBackDate?: string | null;
  slim?: boolean;
}

export const AvailabilityStatus: React.FC<AvailabilityStatusProps> = ({
  status,
  translations,
  locale,
  type = 'full',
  expectedBackDate,
  slim = false,
}) => {
  const renderATP = () =>
    ({
      AVAILABLE: (
        <span
          css={greenStyle(type !== 'simple', slim)}
          aria-label="Available."
          data-availability={StockStatusTag.Available}
        >
          {type !== 'simple' && translations.available}
        </span>
      ),
      LOW_STOCK: (
        <span
          css={yellowStyle(type !== 'simple', slim)}
          aria-label="Low stock."
          data-availability={StockStatusTag.LowStock}
        >
          {type !== 'simple' && translations.low_stock}
        </span>
      ),
      EXPECTED_BACK_IN_STOCK:
        type == 'simple' ? (
          <span
            css={wrapperStyle}
            data-availability={StockStatusTag.ExpectedBackInStock}
          >
            <span
              css={yellowStyle(false, slim)}
              aria-label="Expected back."
            />
            <span css={dateStyle}>
              <Date locale={locale}>{expectedBackDate}</Date>
            </span>
          </span>
        ) : (
          <span
            css={yellowStyle(true, slim)}
            aria-label="Expected back."
            data-availability={StockStatusTag.ExpectedBackInStock}
          >
            {translations.expected_back_in_stock} <Date locale={locale}>{expectedBackDate}</Date>
          </span>
        ),
      OUT_OF_STOCK: (
        <span
          css={redStyle(type !== 'simple', slim)}
          aria-label="Out of stock."
          data-availability={StockStatusTag.OutOfStock}
        >
          {type !== 'simple' && translations.out_of_stock}
        </span>
      ),
      NOT_AVAILABLE: (
        <span
          css={redStyle(type !== 'simple', slim)}
          aria-label="Not available."
          data-availability={StockStatusTag.NotAvailable}
        >
          {type !== 'simple' && translations.not_available}
        </span>
      ),
    })[status];

  return status ? renderATP() : null;
};
