import { MenuItem, ToolTip, ToolTipDirection } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { useCopyOrderItemsToCartContext } from './index';

type CopyOrderItemsToCartMenuItemProps = {
  disabled: boolean;
  disabledTooltipMessage: string;
  buttonText: string;
};

export const CopyOrderItemsToCartMenuItem: React.FC<CopyOrderItemsToCartMenuItemProps> = ({
  disabled,
  disabledTooltipMessage,
  buttonText,
}) => {
  const { triggerCopyOrderItemsToCart } = useCopyOrderItemsToCartContext();

  return (
    <MenuItem
      disabled={disabled}
      onClick={triggerCopyOrderItemsToCart}
    >
      {disabled && (
        <ToolTip
          message={disabledTooltipMessage}
          direction={ToolTipDirection.Left}
        >
          <span css={{ display: 'inline-block', width: 194 }}>{buttonText}</span>
        </ToolTip>
      )}
      {!disabled && (
        <a
          href={'#'}
          onClick={(e) => e.preventDefault()}
        >
          {buttonText}
        </a>
      )}
    </MenuItem>
  );
};
