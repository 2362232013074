import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  CartReferenceCartType,
  CheckoutRecommendationItem,
} from '@lego/b2b-unicorn-data-access-layer';
import { useGetCart } from '@lego/b2b-unicorn-data-access-layer/react';
import {
  useCheckoutRecommendations,
  useOptimisticUpdateCart,
} from '@lego/b2b-unicorn-data-access-layer/react/hooks/checkout';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React, { useCallback, useEffect } from 'react';

import {
  RecommendationsProductsCarousel,
  ReplenishmentCartRecommendationProductTile,
} from './components';
import { RecommendationsProvider } from './components/RecommendationsProvider';
import { RecommendationsContainer } from './components/ui';

export const ReplenishmentCartRecommendations: React.FC = () => {
  const selectedCustomer = useSelectedCustomer();
  const { bestsellers, bestseller_info_text } = useLabels();
  const { trackEvent } = useAnalytics();

  const {
    data: recommendationsResponse,
    error: recommendationsError,
    loading: recommendationsLoading,
    refetch: recommendationsRefetch,
  } = useCheckoutRecommendations(selectedCustomer.id);
  const { mutate } = useOptimisticUpdateCart(selectedCustomer.id, {
    cartType: CartReferenceCartType.Replenish,
  });
  const { refetch: refetchCart } = useGetCart(
    selectedCustomer.id,
    { cartType: CartReferenceCartType.Replenish },
    true,
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleUpdateError = useCallback(() => {
    refetchCart();
    recommendationsRefetch();
  }, [recommendationsRefetch, refetchCart]);

  useEffect(() => {
    recommendationsError &&
      logger.error(
        Array.isArray(recommendationsError) ? recommendationsError[0] : recommendationsError
      );
  }, [recommendationsError]);

  const handleOnSwipeRight = () => {
    trackEvent({
      event: 'swipeRecommendationsCart',
      name: 'Right',
    });
  };

  const handleOnSwipeLeft = () => {
    trackEvent({
      event: 'swipeRecommendationsCart',
      name: 'Left',
    });
  };

  const handleOnAddToCart = useCallback(
    (product: CheckoutRecommendationItem) => {
      trackEvent({
        event: 'addToCartFromRecommendationsCart',
        name: `Adding product ${product.materialId} to cart`,
      });
    },
    [trackEvent]
  );

  const handleOnGotoProductDetails = useCallback(
    (product: CheckoutRecommendationItem) => {
      trackEvent({
        event: 'gotoProductDetailsFromRecommendationsCart',
        name: `Going to product details for ${product.materialId}`,
      });
    },
    [trackEvent]
  );

  if (
    recommendationsError ||
    (recommendationsResponse &&
      (!recommendationsResponse.recommendations || !recommendationsResponse.recommendations.length))
  ) {
    return null;
  }

  return (
    <RecommendationsProvider
      recommendations={recommendationsResponse?.recommendations}
      recommendationsLoading={recommendationsLoading}
      onAddToCart={handleOnAddToCart}
      onGotoProductDetails={handleOnGotoProductDetails}
      onSwipeLeft={handleOnSwipeLeft}
      onSwipeRight={handleOnSwipeRight}
      updateCart={mutate}
      onUpdateError={handleUpdateError}
    >
      <RecommendationsContainer
        header={bestsellers}
        info={bestseller_info_text}
      >
        <RecommendationsProductsCarousel
          tileComponent={ReplenishmentCartRecommendationProductTile}
        />
      </RecommendationsContainer>
    </RecommendationsProvider>
  );
};
