import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';

export const pageFlowStyle = css({
  display: 'flex',
  flexDirection: 'column',
  [media.medium]: {
    flexDirection: 'row-reverse',
    gap: baseSpacing * 2,
    section: {
      border: 'none',
    },
    'section:first-of-type': {
      width: 360,
    },
    'section:last-of-type': {
      width: 'calc(100% - 360px)',
    },
    [media.large]: {
      'section:first-of-type': {
        width: '35%',
      },
      'section:last-of-type': {
        width: '65%',
      },
    },
  },
});

export const imageWrapperStyle = css({
  width: 60,
  display: 'flex',
  justifyContent: 'center',
  marginLeft: baseSpacing,
  '> img, svg': {
    height: baseSpacing * 5,
    width: 'auto',
  },
});

const buttonWidth = 150;
const gap = baseSpacing;
export const productListRowStyle = (isHighlighted: boolean) =>
  css({
    height: baseSpacing * 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: gap,
    backgroundColor: isHighlighted ? designToken.background.blue : 'initial',
    '> button:first-of-type': {
      width: buttonWidth,
      marginLeft: 'auto',
      marginRight: baseSpacing * 8,
      backgroundColor: designToken.background.offWhite,
    },
  });

export const listContainerStyle = css({
  display: 'contents',
  [media.medium]: {
    display: 'block',
    height: 'calc(100vh - 440px)',
    overflowY: 'auto',
  },
});
