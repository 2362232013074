import { css } from '@emotion/react';
import type { CSSInterpolation } from '@emotion/serialize';
import React from 'react';

import { baseSpacing } from '../theme';

const listStyle = (extraCss?: CSSInterpolation) => {
  const listCss: CSSInterpolation = {
    position: 'relative',
    overflowY: 'auto',
    flexGrow: 1,
    padding: baseSpacing,
  };

  return extraCss ? css(listCss, extraCss) : css(listCss);
};
css();

type ListProps = {
  children: React.ReactNode;
  extraCss?: CSSInterpolation;
};

export const List: React.FC<ListProps> = ({ children, extraCss }) => {
  return <ul css={listStyle(extraCss)}>{children}</ul>;
};
