import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { BodyWrapper } from '@lego/b2b-unicorn-shared/ui';
import { Banner, GoToTop } from '@lego/b2b-unicorn-ui-components';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import React, { useCallback } from 'react';

import InfoList from './InfoList/InfoList';

const InfoPageLayout: React.FC = () => {
  const { endTransaction } = useApmTransaction();

  const handleInfoLoaded = useCallback(() => {
    endTransaction && endTransaction();
  }, [endTransaction]);

  return (
    <BodyWrapper>
      <TopbarImplementation />
      <Banner />
      <InfoList onInfoLoaded={handleInfoLoaded} />
      <GoToTop />
    </BodyWrapper>
  );
};

export default InfoPageLayout;
