import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';
import React, { FunctionComponent } from 'react';

interface IProductNameProps {
  materialId?: number;
  name?: string | null;
}

export const ProductName: FunctionComponent<IProductNameProps> = ({ materialId, name = '' }) => {
  return materialId ? (
    <AppLink
      to={`/catalog/${materialId}`}
      app={'shop'}
    >
      {name}
    </AppLink>
  ) : (
    <>{name}</>
  );
};
