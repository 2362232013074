import { deepEqual } from 'fast-equals';
import React from 'react';
import type { CellBase } from 'react-spreadsheet';

import { DiscountedPrice } from '../../../../ui/DiscountedPrice';
import { useBulkQuickAddContext } from '../../BulkQuickAddContext';
import { IProduct } from '../../types';

interface IYourPriceValue {
  product: IProduct;
  quantity?: number;
}

type YourPriceCellProps = {
  cell?: CellBase<IYourPriceValue | ''>;
};

export const YourPriceCell: React.FC<YourPriceCellProps> = React.memo(
  ({ cell }) => {
    const { locale } = useBulkQuickAddContext();

    if (!cell || !cell.value) {
      return null;
    }

    const { quantity, product } = cell.value;

    if (!quantity || quantity < 1) {
      return '-';
    }

    return (
      <span>
        <DiscountedPrice
          locale={locale}
          multiplier={quantity * product.piecesPerCasePack}
        >
          {product.price}
        </DiscountedPrice>
      </span>
    );
  },
  (prevProps, nextProps) => deepEqual(prevProps.cell, nextProps.cell)
);
YourPriceCell.displayName = 'YourPriceCell';
