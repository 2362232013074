import { Button, ButtonType, ToolTip, ToolTipDirection } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { useCopyOrderItemsToCartContext } from './CopyOrderItemsToCart';

type CopyOrderItemsToCartButtonProps = {
  disabled: boolean;
  disabledTooltipMessage: string;
  buttonText: string;
};

export const CopyOrderItemsToCartButton: React.FC<CopyOrderItemsToCartButtonProps> = ({
  disabled,
  disabledTooltipMessage,
  buttonText,
}) => {
  const { triggerCopyOrderItemsToCart } = useCopyOrderItemsToCartContext();

  if (disabled) {
    return (
      <ToolTip
        message={disabledTooltipMessage}
        direction={ToolTipDirection.UpLeft}
      >
        <Button
          type={ButtonType.SECONDARY}
          size="small"
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </ToolTip>
    );
  }

  return (
    <Button
      type={ButtonType.SECONDARY}
      size="small"
      onClick={triggerCopyOrderItemsToCart}
    >
      {buttonText}
    </Button>
  );
};
