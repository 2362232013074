import { useLazyQuery } from '@apollo/client';
import { useMemo, useRef } from 'react';

import { UpcomingDeliveriesDataContext } from '../../context/UpcomingDeliveries/UpcomingDeliveriesDataContext';
import {
  UpcomingDeliveriesLiveSearchFieldQuery,
  UpcomingDeliveriesLiveSearchFieldQueryVariables,
} from '../../generated-types/graphql';
import { useDataAccessLayer } from '../DataAccessLayerProvider';
import { useDataAccessLayerQuery } from '../helpers/useDataAccessLayerQuery';

type GetAllParameters = Parameters<typeof UpcomingDeliveriesDataContext.prototype.getAll>;

export const useUpcomingDeliveries = (
  customerId: GetAllParameters[0],
  filter: GetAllParameters[1]
) => {
  const initialFilters = useRef(filter);

  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.upcomingDeliveries.getAll(customerId, initialFilters.current);
  }, [dataAccessLayer.upcomingDeliveries, customerId]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};

export const useUpcomingDeliveriesLiveQuery = () => {
  const dataAccessLayer = useDataAccessLayer();
  const lazyQuery = useMemo(() => {
    return dataAccessLayer.upcomingDeliveries.getAllByLiveSearchFieldLazy();
  }, [dataAccessLayer.upcomingDeliveries]);

  return useLazyQuery<
    UpcomingDeliveriesLiveSearchFieldQuery,
    UpcomingDeliveriesLiveSearchFieldQueryVariables
  >(lazyQuery.query, {
    client: dataAccessLayer.apolloClient,
    fetchPolicy: 'no-cache',
  });
};
