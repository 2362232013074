import { css } from '@emotion/react';
import { LogoGraphic } from '@lego/b2b-unicorn-ui-topbar';
import React from 'react';

import { baseSpacing, colors, designToken, media } from '../../theme';

const backgroundStyle = css({
  color: designToken.text.default,
  width: '100%',
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
  backgroundColor: designToken.secondary.default,
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  [media.small]: {
    alignItems: 'center',
  },
});

const containerStyle = css({
  minHeight: '80vh',
  width: '100%',
  [media.small]: {
    width: 'auto',
    maxWidth: 945,
    margin: 'auto',
    paddingBottom: baseSpacing * 2,
  },
});

const wrapperBoxStyle = css({
  [':empty']: {
    display: 'none',
  },
  display: 'flex',
  flexDirection: 'column',
  background: designToken.background.offWhite,
  padding: baseSpacing,
  borderRadius: 8,
  maxHeight: `calc(100vh - ${baseSpacing * 10}px)`,
  [media.small]: {
    padding: baseSpacing * 4,
    paddingTop: baseSpacing * 3,
    maxHeight: `calc(100vh - ${baseSpacing * 13}px)`,
  },
});

const logoStyle = css({
  paddingTop: baseSpacing * 2,
  marginBottom: baseSpacing * 2,
  color: colors.klik.slate10,
});

export const SimpleLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div css={backgroundStyle}>
      <div css={containerStyle}>
        <div css={logoStyle}>
          <LogoGraphic />
        </div>
        <div css={wrapperBoxStyle}>{children}</div>
      </div>
    </div>
  );
};
