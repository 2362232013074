import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

const truncationStyle = css({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: 'inherit',
});

interface ITruncatedTextProps {
  children: string | null | undefined;
}

const TruncatedText: FunctionComponent<ITruncatedTextProps> = ({ children }) => {
  const content = children;
  return (
    <div
      title={content || ''}
      css={truncationStyle}
    >
      {content}
    </div>
  );
};

export default TruncatedText;
