import InvoiceList from '../../pages/manage/InvoiceList/InvoiceList';
import ManagePageLayout from './ManagePageLayout';

const InvoiceListLayout = () => {
  return (
    <ManagePageLayout>
      <InvoiceList />
    </ManagePageLayout>
  );
};

export default InvoiceListLayout;
