import { css } from '@emotion/react';
import { CartProduct } from '@lego/b2b-unicorn-data-access-layer';
import { NotificationType, useNotifications } from '@lego/b2b-unicorn-ui-components';
import { font } from '@lego/b2b-unicorn-ui-constants';
import { Fragment, useEffect } from 'react';

type MinimalProduct = Pick<
  CartProduct,
  '__typename' | 'name' | 'piecesPerCasePack' | 'materialId'
> & {
  itemNumber?: number | null;
};

interface UseCartItemRowOptions<P extends MinimalProduct> {
  onUpdate: (product: P, newQuantity: number) => void;
  product: P;
  snackbar_error_message: string;
  quantity: number;
  updateError?: boolean;
}

const snackbarProductNameStyle = css({
  fontWeight: font.weight.bold,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '1',
  overflow: 'hidden',
});

export const useCartItemRow = <P extends MinimalProduct>({
  onUpdate,
  snackbar_error_message,
  product,
  updateError,
  quantity,
}: UseCartItemRowOptions<P>) => {
  const { addSnackbar, snackbar } = useNotifications();
  useEffect(() => {
    const errorText = (
      <Fragment>
        <span css={snackbarProductNameStyle}>
          {product.__typename === 'Box' && product.itemNumber} — {product.name}
        </span>{' '}
        {snackbar_error_message}
      </Fragment>
    );
    if (updateError) {
      addSnackbar({
        type: NotificationType.WARNING,
        content: errorText,
        showDismissButton: false,
        isStacked: !!snackbar,
      });
    }
  }, [product, snackbar, snackbar_error_message, updateError]);

  const handleCounterUpdate = (quantity: number) => {
    onUpdate(product, quantity);
  };

  const totalPieces = quantity * product.piecesPerCasePack;

  return {
    handleCounterUpdate,
    totalPieces,
  };
};
