/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CartItem, CartReferenceInput } from '@lego/b2b-unicorn-data-access-layer';
import { CartItemWithSimulationDetails } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';
import { useCartWithSimulationDetails } from '@lego/b2b-unicorn-data-access-layer/react';
import { useCheckoutCart } from '@lego/b2b-unicorn-data-access-layer/react/hooks/checkout';
import { exhaustiveSwitchCheck } from '@lego/b2b-unicorn-data-access-layer/utils/TypeScriptHelpers';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { mapToMoney } from '@lego/b2b-unicorn-ui-utils';
import { deepEqual } from 'fast-equals';
import { useEffect, useMemo, useState } from 'react';

import type { ICheckoutCookie } from '../types';
import { convertDateStringToDate, convertDateStringToDateOrNull } from '../utils';
import { createCheckoutCookieName } from '../utils/createCheckoutCookieName';
import { makeStateWithCookieHook } from './stateWithCookie';

interface CheckoutOptions {
  removeObsoleteItems: boolean;
  cartReference: CartReferenceInput;
  cookieDomain: string;
  simulation: boolean;
  onSimulationQueryCompleted?: () => void;
  onCheckoutQueryCompleted?: (data: ReturnType<typeof useCheckoutCart>['data']) => void;
}

export const useCheckoutOperation = (customerId: number, options: CheckoutOptions) => {
  // Internal states
  const [internalOptions, setInternalOptions] = useState(() => options);
  const checkoutCookieName = createCheckoutCookieName(customerId, options.cartReference);
  const useStateWithCookie = useMemo(
    () => makeStateWithCookieHook(internalOptions.cookieDomain),
    [internalOptions.cookieDomain]
  );
  const [shippingAddressId, setShippingAddressId] = useStateWithCookie<ICheckoutCookie, 'shipToId'>(
    checkoutCookieName,
    `shipToId`
  );
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useStateWithCookie<
    ICheckoutCookie,
    'requestedDeliveryDate'
  >(checkoutCookieName, `requestedDeliveryDate`);

  useEffect(() => {
    if (!deepEqual(internalOptions, options)) {
      setInternalOptions(options);
    }
  }, [internalOptions, options]);

  // Queries
  const {
    data: checkoutData,
    error: checkoutError,
    loading: checkoutLoading,
  } = useCheckoutCart(
    customerId,
    internalOptions.cartReference,
    internalOptions.removeObsoleteItems
  );

  useEffect(() => {
    if (!checkoutData) {
      return;
    }

    const shippingAddressIdInShipTos = checkoutData.getShipTos.some(
      (s) => s.id === shippingAddressId
    );
    if (!shippingAddressIdInShipTos) {
      setShippingAddressId(undefined);
    }
    internalOptions.onCheckoutQueryCompleted &&
      internalOptions.onCheckoutQueryCompleted(checkoutData);
  }, [checkoutData, internalOptions, setShippingAddressId, shippingAddressId]);

  const [
    getSimulation,
    {
      data: simulationData,
      error: simulationError,
      loading: simulationLoading,
      networkStatus: simulationNetworkStatus,
    },
  ] = useCartWithSimulationDetails(customerId, internalOptions.cartReference);
  useOnPreferredLanguageChange(['Checkout', 'cartWithSimulationDetails']);

  useEffect(() => {
    if (!requestedDeliveryDate || !shippingAddressId || !internalOptions.simulation) {
      return;
    }

    getSimulation({
      variables: {
        customerId,
        cartReference: internalOptions.cartReference,
        requestedDeliveryDate,
        shipToId: shippingAddressId,
      },
    }).then(() => {
      internalOptions.onSimulationQueryCompleted && internalOptions.onSimulationQueryCompleted();
    });
  }, [customerId, getSimulation, internalOptions, requestedDeliveryDate, shippingAddressId]);

  const items = useMemo<CartItemWithSimulationDetails[] | CartItem[] | undefined>(() => {
    if (!checkoutData) {
      return;
    }

    if (simulationData) {
      switch (simulationData.cartWithSimulationDetails.__typename) {
        case 'CartWithSimulationDetails':
          return simulationData.cartWithSimulationDetails.items;
        case 'Cart':
          return simulationData.cartWithSimulationDetails.items;
        default:
          exhaustiveSwitchCheck(simulationData.cartWithSimulationDetails.__typename);
      }
    }

    return checkoutData.cart.items;
  }, [checkoutData, simulationData]);

  const removedItems = useMemo(() => {
    if (!checkoutData) {
      return;
    }

    if (simulationData && simulationData.cartWithSimulationDetails) {
      return simulationData.cartWithSimulationDetails.removedItems;
    }

    return checkoutData.cart.removedItems;
  }, [simulationData, checkoutData]);

  const deliveryDates = useMemo(() => {
    if (!checkoutData) {
      return;
    }

    if (simulationData && simulationData.cartWithSimulationDetails) {
      if (simulationData.cartWithSimulationDetails.deliveryDates) {
        return {
          earliestDate: convertDateStringToDateOrNull(
            simulationData.cartWithSimulationDetails.deliveryDates.earliestDate
          ),
          latestDate: convertDateStringToDateOrNull(
            simulationData.cartWithSimulationDetails.deliveryDates.latestDate
          ),
          rddNotAvailableDates:
            simulationData.cartWithSimulationDetails.deliveryDates.rddNotAvailableDates.map(
              (dates) => ({
                start: convertDateStringToDate(dates.startDate),
                end: convertDateStringToDate(dates.endDate),
              })
            ),
        };
      }
    }

    return {
      earliestDate: convertDateStringToDateOrNull(checkoutData.cart.deliveryDates.earliestDate),
      latestDate: convertDateStringToDateOrNull(checkoutData.cart.deliveryDates.latestDate),
      rddNotAvailableDates: checkoutData.cart.deliveryDates.rddNotAvailableDates.map((dates) => ({
        start: convertDateStringToDate(dates.startDate),
        end: convertDateStringToDate(dates.endDate),
      })),
    };
  }, [simulationData, checkoutData]);

  const shipTos = useMemo(() => {
    if (!checkoutData) {
      return;
    }

    return checkoutData.getShipTos;
  }, [checkoutData]);

  useEffect(() => {
    if (shipTos && shipTos.length === 1 && !shippingAddressId) {
      setShippingAddressId(shipTos[0].id);
    }
  }, [setShippingAddressId, shipTos, shippingAddressId]);

  const totalListPrice = useMemo(() => {
    if (!simulationData || !simulationData.cartWithSimulationDetails) {
      return;
    }

    if (simulationData.cartWithSimulationDetails.__typename === 'CartWithSimulationDetails') {
      return simulationData.cartWithSimulationDetails.totalListPrice;
    }
  }, [simulationData]);

  const totalSimulationPrice = useMemo(() => {
    if (!simulationData || !simulationData.cartWithSimulationDetails) {
      return;
    }

    if (simulationData.cartWithSimulationDetails.__typename === 'CartWithSimulationDetails') {
      return simulationData.cartWithSimulationDetails.totalSimulationPrice;
    }
  }, [simulationData]);

  const simulationDetails = useMemo(() => {
    if (!simulationData || !simulationData.cartWithSimulationDetails) {
      return;
    }

    if (simulationData.cartWithSimulationDetails.__typename === 'CartWithSimulationDetails') {
      return simulationData.cartWithSimulationDetails.details;
    }
  }, [simulationData]);

  const minimumOrderValue =
    (checkoutData && checkoutData.cart.minimumOrderValue) || mapToMoney(0, '');

  return {
    items,
    removedItems,
    deliveryDates,
    shipTos,
    checkoutLoading,
    checkoutError,
    simulationLoading,
    simulationError,
    simulationDetails,
    simulationNetworkStatus,
    requestedDeliveryDate,
    setRequestedDeliveryDate,
    shippingAddressId,
    setShippingAddressId,
    totalListPrice,
    totalSimulationPrice,
    minimumOrderValue,
  };
};
