import { css } from '@emotion/react';
import { colors, media } from '@lego/b2b-unicorn-ui-constants';
import { designToken } from '@lego/b2b-unicorn-shared/ui';

export const optionsContainerStyle = (showOptions) =>
  css({
    display: showOptions ? 'flex' : 'none',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    margin: 0,
    borderRadius: 6,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: designToken.background.blue,
    borderStyle: 'solid',
    boxShadow:
      '0px 8px 15px 5px rgb(0 0 0 / 10%), 8px 15px 15px 5px rgb(0 0 0 / 5%), -8px 15px 15px 5px rgb(0 0 0 / 5%)',
    [media.medium]: {
      zIndex: 20,
      position: 'absolute',
      maxHeight: 400,
      overflow: 'auto',
    },
  });

export const optionStyle = css({
  margin: 0,
  padding: 12,
  transitionProperty: 'all',
  transitionDuration: '150ms',
  lineHeight: 1.3,
  scrollBehavior: 'smooth',
  '&:hover, :focus': {
    backgroundColor: designToken.background.blue,
    outline: 'none',
  },
});
