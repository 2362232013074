import type { MutationOptions } from '@apollo/client';
import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';

import {
  AcceptTermsOfSalesMutation,
  AcceptTermsOfSalesMutationVariables,
  GetTermsOfSalesAcceptanceStateQuery,
  GetTermsOfSalesAcceptanceStateQueryVariables,
  GetTermsOfSalesQuery,
  GetTermsOfSalesQueryVariables,
  PreBootstrapQuery,
} from '../../generated-types/graphql';
import { ExtractElementType } from '../../utils/TypeScriptHelpers';
import { ContextAbstract } from '../ContextAbstract';
import { ACCEPT_TERMS_OF_SALES_FOR_CUSTOMER } from './mutations/acceptTermsOfSalesForCustomer';
import { GET_TERMS_OF_SALES } from './queries/getTermsOfSales';
import { GET_TERMS_OF_SALES_ACCEPTANCE_STATE } from './queries/getTermsOfSalesAcceptanceState';
import { PRE_BOOTSTRAP } from './queries/preBootstrap';

export type User = NonNullable<PreBootstrapQuery['getUser']>;
export type Customer = ExtractElementType<
  NonNullable<NonNullable<PreBootstrapQuery['getUser']>['customers']>
>;

export class BootstrapDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {};

  public preBootstrap() {
    return this._apolloClient.watchQuery<PreBootstrapQuery>({
      query: PRE_BOOTSTRAP,
    });
  }

  public getTermsOfSalesAcceptanceState(customerId: number) {
    return this._apolloClient.watchQuery<
      GetTermsOfSalesAcceptanceStateQuery,
      GetTermsOfSalesAcceptanceStateQueryVariables
    >({
      query: GET_TERMS_OF_SALES_ACCEPTANCE_STATE,
      variables: {
        customerId,
      },
    });
  }

  public getTermsOfSales(customerId: number) {
    return this._apolloClient.watchQuery<GetTermsOfSalesQuery, GetTermsOfSalesQueryVariables>({
      query: GET_TERMS_OF_SALES,
      variables: {
        customerId,
      },
    });
  }

  public acceptTermsOfSalesForCustomer(customerId: number, version: string, optimistic?: boolean) {
    const mutationOptions: MutationOptions<
      AcceptTermsOfSalesMutation,
      AcceptTermsOfSalesMutationVariables
    > = {
      variables: { customerId, version },
      mutation: ACCEPT_TERMS_OF_SALES_FOR_CUSTOMER,
    };

    if (optimistic) {
      Object.assign<typeof mutationOptions, typeof mutationOptions>(mutationOptions, {
        ...mutationOptions,
        update: () => {
          const termsOfSalesAcceptanceState =
            this.getTermsOfSalesAcceptanceState(customerId).getCurrentResult();
          if (termsOfSalesAcceptanceState.data) {
            this.getTermsOfSalesAcceptanceState(customerId).updateQuery((prev) => {
              return {
                ...prev,
                getTermsOfSalesAcceptanceState: true,
              };
            });
          }
        },
      });
    }

    return this._apolloClient.mutate<
      AcceptTermsOfSalesMutation,
      AcceptTermsOfSalesMutationVariables
    >(mutationOptions);
  }
}
