import { css, keyframes } from '@emotion/react';
import React from 'react';

import { assignDesignToken, ObjectPathLeavesOnly } from '../../utils';
import { baseSpacing, designToken, media } from '../theme';

const animateDot = (lightColor: string, darkColor: string) => keyframes`
  0%, 60%, 100% { background-color: ${lightColor}; }
  20%, 40% { background-color: ${darkColor}; }
`;

const dotsAnimationStyle = (lightColor: string, darkColor: string, mediumSize: boolean) =>
  css({
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    whiteSpace: 'nowrap',
    '> span': {
      display: 'inline-block',
      height: mediumSize ? 10 : 6,
      width: mediumSize ? 10 : 6,
      borderRadius: '50%',
      margin: baseSpacing / 2,
      padding: 0,
      paddingLeft: 2,
      animationName: animateDot(lightColor, darkColor),
      animationDuration: '1.4s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-in-out',
    },
    '> span:first-of-type': {
      animationDelay: '0.15s',
    },
    '> span:nth-of-type(2)': {
      animationDelay: '0.3s',
    },
    '> span:last-of-type': {
      animationDelay: '0.45s',
    },
    [media.small]: {
      '> span': {
        height: mediumSize ? 14 : 6,
        width: mediumSize ? 14 : 6,
      },
    },
    [media.medium]: {
      justifyContent: 'center',
    },
  });

interface AnimatedDotsProps {
  lightDesignToken?: ObjectPathLeavesOnly<typeof designToken>;
  darkDesignToken?: ObjectPathLeavesOnly<typeof designToken>;
  mediumSize?: boolean;
}

export const AnimatedDots: React.FC<AnimatedDotsProps> = ({
  lightDesignToken = 'white' as ObjectPathLeavesOnly<typeof designToken>,
  darkDesignToken = 'information.default' as ObjectPathLeavesOnly<typeof designToken>,
  mediumSize = false,
}) => {
  const lightColor = assignDesignToken(lightDesignToken);
  const darkColor = assignDesignToken(darkDesignToken);
  return (
    <div css={dotsAnimationStyle(lightColor, darkColor, mediumSize)}>
      <span />
      <span />
      <span />
    </div>
  );
};
