import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, designToken, font, media, mediaSizes } from '../theme';

type PageBodyProps = {
  children: React.ReactNode;
};

const pageBodyStyle = css({
  width: '100%',
  flexGrow: 1,
  maxWidth: mediaSizes.large,
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,

  paddingTop: 0,
  paddingBottom: baseSpacing * 4,
  color: designToken.text.default,
  [media.medium]: {
    fontSize: font.size.small,
  },
});

export const PageBody: React.FC<PageBodyProps> = ({ children }) => {
  return <div css={pageBodyStyle}>{children}</div>;
};
