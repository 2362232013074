import { gql } from '@apollo/client';

export const BRICK_BANK_PAYMENT_METHODS = gql`
  query brickBankPaymentMethods($customerId: Int!) {
    getBrickBankPaymentMethods(customerId: $customerId) {
      paymentMethods {
        brands
        name
        type
      }
      storedPaymentMethods {
        id
        brand
        expiryMonth
        expiryYear
        holderName
        lastFour
        name
        supportedShopperInteractions
        type
      }
    }
  }
`;
