import { gql } from '@apollo/client';

export const PROMOTION = gql`
  query promotion($customerId: Int!, $promotionId: Int!) {
    promotion(customerId: $customerId, promotionId: $promotionId) {
      id
      description
      availableForOrder
      orderDeadlineDate
      executionDates {
        startDate
        endDate
      }
      deliveryDates {
        earliestDate
        latestDate
      }
      products {
        materialId
        name
        theme
        ean
        piecesPerCasePack
        price {
          currency
          netInvoicedPrice
          estimatedNetInvoicedPrice
          grossPrice
          listPrice
        }
        ... on Box {
          itemNumber
        }
      }
      productConditions {
        materialId
        minimumCasePacks
      }
    }
    getCart(
      customerId: $customerId
      removeObsoleteItems: true
      cartReference: { cartType: PROMOTION, promotion: { promotionId: $promotionId } }
    ) {
      items {
        ...minimumCartItem
        product {
          materialId
          name
          theme
          ean
          piecesPerCasePack
          price {
            currency
            netInvoicedPrice
            estimatedNetInvoicedPrice
            grossPrice
            listPrice
          }
          ... on Box {
            itemNumber
          }
        }
      }
      ...cartKey
      minimumOrderValue {
        amount
        currency
      }
    }
  }
`;
