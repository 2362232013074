import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { OrderPromotionsOverviewItem } from '@lego/b2b-unicorn-data-access-layer/context/Promotions/PromotionsDataContext';
import { Date as DateComponent, DateTime } from '@lego/b2b-unicorn-shared/components/Date';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  designToken,
  Tile,
  TileBottom,
  TileButton,
  TileHeader,
  Typography,
} from '@lego/b2b-unicorn-shared/ui';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import { differenceInDays } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import { orderIndicatorStyle } from '../../../launch/components/LaunchWindowsOverview/styles';

type AvailableForOrderPromotionTileProps = {
  promotion: OrderPromotionsOverviewItem;
  onClick: (promotion: OrderPromotionsOverviewItem) => void;
};
const deadlineStyle = (warning: boolean) =>
  css({
    color: warning ? designToken.error.emphasis : colors.klik.slate500,
    lineHeight: 1.1,
  });
export const AvailableForOrderPromotionTile: React.FC<AvailableForOrderPromotionTileProps> = ({
  promotion,
  onClick,
}) => {
  const {
    promotions_execution_dates,
    launch_window_deadline_text,
    launch_window_order_now,
    launch_window_finish_order,
    launch_window_new_order,
    launch_window_existing_orders,
  } = useLabels();
  const selectedCustomer = useSelectedCustomer();

  const handleTileClick = useCallback(() => {
    onClick(promotion);
  }, [onClick, promotion]);

  const ordersCreatedCount = promotion?.ordersCreated?.length || 0;
  const isDeadlineDateClose = promotion?.orderDeadlineDate
    ? differenceInDays(new Date(promotion.orderDeadlineDate), new Date()) < 14
    : false;

  const openWindowLinkLabel = useMemo(() => {
    if (promotion.cartHasItems) {
      return launch_window_finish_order;
    } else {
      return ordersCreatedCount === 0 ? launch_window_new_order : launch_window_order_now;
    }
  }, [
    launch_window_finish_order,
    launch_window_new_order,
    launch_window_order_now,
    promotion.cartHasItems,
    ordersCreatedCount,
  ]);

  return (
    <Tile
      isActive={true}
      onClick={handleTileClick}
    >
      <TileHeader active={true}>
        <h1
          style={{ textTransform: 'none' }}
          title={promotion.description}
        >
          {promotion.description}
        </h1>
        <span>{promotion.productsCount} items</span>
      </TileHeader>
      <TileBottom>
        <div>
          <span>
            <Typography weight={'medium'}>{promotions_execution_dates}:</Typography>{' '}
            <DateComponent locale={selectedCustomer.locale}>
              {promotion?.executionDates?.startDate}
            </DateComponent>{' '}
            &ndash;{' '}
            <DateComponent locale={selectedCustomer.locale}>
              {promotion?.executionDates?.endDate}
            </DateComponent>
          </span>
        </div>
        <div>
          <span css={deadlineStyle(isDeadlineDateClose)}>
            {launch_window_deadline_text}:{' '}
            <DateTime locale={selectedCustomer.locale}>{promotion.orderDeadlineDate}</DateTime>
          </span>
        </div>
        <span css={[orderIndicatorStyle, { position: 'absolute', bottom: 12 }]}>
          {ordersCreatedCount > 0 && `${ordersCreatedCount} ${launch_window_existing_orders}`}
        </span>
        <TileButton withAlert={promotion.cartHasItems}>{openWindowLinkLabel}</TileButton>
      </TileBottom>
    </Tile>
  );
};
