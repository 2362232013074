import type { GraphQLFormattedError } from 'graphql';

type Extra = Record<string, unknown>;

enum SeverityLevel {
  'fatal',
  'error',
  'warning',
  'log',
  'info',
  'debug',
}

type LogFn = (
  loggerName: string,
  level: SeverityLevel,
  message: string | Error | GraphQLFormattedError,
  extra?: Extra
) => void;

interface ILoggerOpts {
  level?: SeverityLevel;
  onLog?: LogFn;
}

class Logger {
  private level: SeverityLevel = SeverityLevel.log;
  private name = 'default';
  private readonly opts?: ILoggerOpts;

  constructor(opts?: ILoggerOpts) {
    this.opts = opts;
    if (opts && opts.level) {
      this.level = opts.level;
    }
  }

  public fatal(message: string | Error | GraphQLFormattedError, extra?: Extra) {
    this.log(SeverityLevel.fatal, message, extra);
  }

  public error(message: string | Error | GraphQLFormattedError, extra?: Extra) {
    this.log(SeverityLevel.error, message, extra);
  }

  public warning(message: string | Error | GraphQLFormattedError, extra?: Extra) {
    this.log(SeverityLevel.warning, message, extra);
  }

  public info(message: string | Error | GraphQLFormattedError, extra?: Extra) {
    this.log(SeverityLevel.info, message, extra);
  }

  public debug(message: string | Error | GraphQLFormattedError, extra?: Extra) {
    this.log(SeverityLevel.debug, message, extra);
  }

  public setLevel(level: SeverityLevel) {
    this.level = level;
  }

  public setName(name: string) {
    this.name = name;
  }

  /*
   * Create a sub-logger with a specific name, it's created with the same options and level as the parent logger
   */
  public createLogger(name: string) {
    const logger = new Logger(this.opts);
    logger.setName(name);
    logger.setLevel(this.level);
    return logger;
  }

  private log(
    level: SeverityLevel,
    message: string | Error | GraphQLFormattedError,
    extra?: Extra
  ) {
    if (level > this.level) {
      return;
    }

    if (this.opts?.onLog) {
      this.opts.onLog(this.name, level, message, extra);
    }
  }
}

let defaultLogger: Logger;

const createLogger = (opts?: ILoggerOpts) => {
  if (defaultLogger) {
    // eslint-disable-next-line no-console
    console.warn('Default logger already exists, creating a new one will overwrite it');
  }
  defaultLogger = new Logger(opts);
  return defaultLogger;
};
export { createLogger, Logger, defaultLogger as logger, SeverityLevel };
