import React, { useMemo } from 'react';

import { Date } from './Date';
import { formatDateTime } from './helpers/formatDateTime';

type DateTimeProps = React.ComponentProps<typeof Date>;
export const DateTime: React.FC<DateTimeProps> = ({
  children,
  locale,
  options,
  fallbackText = '-',
}) => {
  const formattedDateTime = useMemo(
    () => (children ? formatDateTime(locale, children, options) : fallbackText),
    [children, fallbackText, locale, options]
  );

  return <>{formattedDateTime}</>;
};
