import { gql } from '@apollo/client';

export const FRAGMENT_CART_KEY = gql`
  fragment cartKey on Cart {
    id
    customerId
    cartReference {
      cartType
      cartLaunchYear
      cartLaunchMonth
      cartPromotionId
    }
  }

  fragment cartWithSimulationKey on CartWithSimulationDetails {
    id
    customerId
    cartReference {
      cartType
      cartLaunchYear
      cartLaunchMonth
    }
  }
`;
