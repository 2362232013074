import { css, keyframes } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  Card,
  designToken,
  FlexBox,
  Icon,
  IconType,
  Paragraph,
  Section,
  Spacer,
  Textarea,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import { ContainerHeader } from '../../../../components/ContainerHeader';
import { listContainerStyle, pageFlowStyle } from '../styles';
import { SkeletonInvoicedItem } from './SkeletonInvoicedItem';

const summaryBackgroundStyle = css({
  backgroundColor: designToken.skeleton.default,
  height: 242,
  margin: 0,
  borderRadius: 8,
  [media.medium]: {
    height: 136,
  },
});

const pulse = keyframes`
    100% { left: 100%; }
`;

const fadingSkeletonListStyle = css(listContainerStyle, {
  position: 'relative',
  overflowX: 'hidden',
  '&:after': {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    display: 'block',
    content: '""',
    width: '100%',
    height: '100%',
    background: `linear-gradient(${designToken.transparent.default} 0%, ${designToken.transparent.default} 20%, ${designToken.background.offWhite} 60%)`,
  },
  '&:before': {
    position: 'absolute',
    display: 'block',
    content: '""',
    width: '100%',
    height: '100%',
    top: 0,
    left: '-100%',
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${colors.klik.slate10} 50%, ${designToken.transparent.default} 100%)`,
    backgroundRepeat: 'no-repeat',
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  },
});

export const SkeletonCreateClaim: React.FC = () => {
  const {
    file_upload_drag_and_drop,
    file_upload_browse_files,
    upload_additional_documentation,
    optional,
  } = useLabels();
  return (
    <>
      <Spacer />
      <div css={summaryBackgroundStyle} />
      <Spacer />
      <div css={pageFlowStyle}>
        <Section>
          <ContainerHeader
            text={'Comment'}
            open={true}
            withLargeText={false}
            mobileOnlyToggle={true}
          />
          <Textarea
            value={''}
            onChange={() => {}}
            rows={6}
            disabled
          />

          <Spacer multiplier={6} />

          <ContainerHeader
            text={`${upload_additional_documentation} (${optional})`}
            open={true}
            withLargeText={false}
            mobileOnlyToggle={true}
          />
          <Card
            borderType={'dashed'}
            padding={{ left: baseSpacing * 2, right: baseSpacing * 2 }}
            centered
          >
            <FlexBox
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Icon
                type={IconType.UPLOAD}
                height={baseSpacing * 3}
                color={designToken.skeleton.primary}
              />
              <Paragraph
                fontSize={'medium'}
                designToken={'skeleton.primary'}
              >
                {file_upload_drag_and_drop}
              </Paragraph>
              <Paragraph
                fontSize={'small'}
                designToken={'skeleton.primary'}
              >
                {file_upload_browse_files}
              </Paragraph>
            </FlexBox>
          </Card>
        </Section>
        <Section>
          <ContainerHeader
            text={'Invoiced items'}
            open={true}
            withLargeText={false}
            mobileOnlyToggle={true}
          />
          <div css={fadingSkeletonListStyle}>
            {[...Array(8)].map((_, i) => {
              return <SkeletonInvoicedItem key={i} />;
            })}
          </div>
        </Section>
      </div>
    </>
  );
};
