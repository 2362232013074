import { css, keyframes } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { NotificationType } from '../NotificationType';
import NotificationIcon from './NotificationIcon';

const wrapperContainerStyle = css({
  zIndex: 2000,
  position: 'fixed',
  height: 154,
  width: '100%',
  top: 25,
  right: 0,
  overflow: 'hidden',
  [media.small]: {
    width: 488,
    top: baseSpacing * 7,
  },
});

const snackbarStyle = (animateIn: boolean, type: NotificationType) =>
  css({
    position: 'relative',
    boxSizing: 'border-box',
    width: 'calc(100% - 16px)',
    marginLeft: baseSpacing,
    marginRight: baseSpacing,
    padding: baseSpacing * 2,
    borderRadius: 4,
    background: {
      [NotificationType.DEFAULT]: `linear-gradient(to right, ${designToken.information.default} ${
        baseSpacing * 1.5
      }px, ${designToken.information.muted} ${baseSpacing * 1.5}px)`,
      [NotificationType.WARNING]: `linear-gradient(to right, ${designToken.warning.default} ${
        baseSpacing * 1.5
      }px, ${designToken.warning.muted} ${baseSpacing * 1.5}px)`,
      [NotificationType.ERROR]: `linear-gradient(to right, ${designToken.error.default} ${
        baseSpacing * 1.5
      }px, ${designToken.error.muted} ${baseSpacing * 1.5}px)`,
      [NotificationType.POSITIVE]: `linear-gradient(to right, ${designToken.success.default} ${
        baseSpacing * 1.5
      }px, ${designToken.success.muted} ${baseSpacing * 1.5}px)`,
    }[type],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: {
      [NotificationType.DEFAULT]: designToken.information.default,
      [NotificationType.WARNING]: designToken.warning.default,
      [NotificationType.ERROR]: designToken.error.default,
      [NotificationType.POSITIVE]: designToken.success.default,
    }[type],
    overflow: 'hidden',
    transitionProperty: 'transform',
    transitionDuration: '250ms',
    transitionTimingFunction: 'ease-in-out',
    transitionDelay: '500ms',
    transform: animateIn ? 'translate(0, 0)' : 'translate(500px, 0)',
    boxShadow: `0 10px 6px -6px ${colors.klik.lightBlue400}`,
    '&:focus': {
      outline: 'none',
    },
    [media.small]: {
      height: 90,
      maxWidth: 480,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: baseSpacing,
      paddingLeft: baseSpacing * 2,
      paddingRight: baseSpacing * 2,
    },
  });

const contentContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  marginRight: 'auto',
  [media.small]: {
    display: 'contents',
  },
});

const contentStyle = css({
  color: designToken.text.default,
  fontSize: font.size.small,
  margin: 0,
  marginRight: 20,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '3',
  overflow: 'hidden',
});

const closeStyle = css({
  backgroundColor: 'rgba(0, 100, 100, 0)',
  border: 'none',
  height: 30,
  width: 30,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: baseSpacing * 2,
  right: 0,
  transitionProperty: 'background-color',
  transitionDuration: '150ms',
  outline: 'none',
  cursor: 'pointer',
  [media.small]: {
    right: baseSpacing * 3,
  },
  '> svg': {
    height: baseSpacing * 2,
    width: baseSpacing * 2,
    fill: designToken.text.default,
    transitionProperty: 'fill',
    transitionDuration: '150ms',
  },
  '&:hover, :focus, :active': {
    backgroundColor: 'rgba(0, 100, 100, 0.1)',
  },
});

const wiggle = keyframes`
  10%, 90% { transform: translate(-1px, 0); }
  20%, 80% { transform: translate(2px, 0); }
  30%, 50%, 70% { transform: translate(-4px, 0); }
  40%, 60% { transform: translate(4px, 0); }
`;

const wiggleStyle = css({
  animationName: wiggle,
  animationDuration: '0.8s',
  animationFillMode: 'both',
  animationTimingFunction: 'cubic-bezier(.36,.07,.19,.97)',
  transform: 'translate(0, 0)',
});

interface ISnackbar {
  type?: NotificationType;
  content: string | ReactNode;
  autoDismissTime?: number;
  showDismissButton?: boolean;
  dismissActionTranslation: string;
  dismissActionHandler: () => void;
  isStacked: boolean;
}

const Snackbar = ({
  type = NotificationType.DEFAULT,
  content,
  autoDismissTime = 3000,
  showDismissButton = false,
  dismissActionTranslation,
  dismissActionHandler,
  isStacked,
}: ISnackbar) => {
  const [animateIn, setAnimateIn] = useState(false);
  const [wiggle, setWiggle] = useState(false);

  const timeout = useRef<number>();

  useEffect(() => {
    setAnimateIn(true);
    const timeoutSnackbar = () => {
      window.clearTimeout(timeout.current);
      timeout.current = window.setTimeout(() => {
        setAnimateIn(false);
      }, autoDismissTime - 500);
    };
    !showDismissButton && timeoutSnackbar();
  }, [showDismissButton, autoDismissTime]);

  useEffect(() => {
    setWiggle(isStacked);
  }, [content, isStacked]);

  return (
    <div css={wrapperContainerStyle}>
      <div
        css={[snackbarStyle(animateIn, type), wiggle && wiggleStyle]}
        role={!!dismissActionTranslation && !!dismissActionHandler ? 'alert-dialog' : 'alert'}
        onAnimationEnd={() => {
          wiggle && setWiggle(false);
        }}
      >
        <div css={contentContainerStyle}>
          <NotificationIcon type={type} />
          <p css={contentStyle}>{content}</p>
        </div>
        {showDismissButton && (
          <div
            role="button"
            tabIndex={0}
            css={closeStyle}
            onClick={dismissActionHandler}
            onKeyUp={dismissActionHandler}
          >
            <Icon type={IconType.CLOSE} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Snackbar;
