import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { quantityOnChangeHandler } from '@lego/b2b-unicorn-ui-utils';

import { formCommonInputStyle } from './common/quickAddStyles';

const quantityWrapperStyle = css({
  position: 'relative',
  boxShadow: `inset 0px 2px 0px rgba(0, 0, 0, 0.1)`,
  width: '100%',
  [media.small]: {
    flexBasis: `calc(50% - ${baseSpacing / 2}px)`,
  },
  [media.medium]: {
    display: 'flex',
    flexBasis: 'auto',
    width: 230,
    boxShadow: 'none',
    span: {
      lineHeight: `${baseSpacing * 7}px`,
    },
  },
});

const quantityInputStyle = [
  formCommonInputStyle,
  css({
    borderRadius: 4,
    boxShadow: `inset 0px 2px 0px rgba(0, 0, 0, 0.1), -13px 0 0 -12px ${colors.klik.slate400}`,
    opacity: 1 /* To prevent the input field from looking 'highlighted' in iOS Safari (0.4 opacity on top of blue background). */,
    backgroundColor: designToken.background.blue,
    '&::placeholder': {
      color: colors.klik.slate500,
    },
    [media.medium]: {
      borderRadius: 0,
    },
  }),
];

const quantitySuffixStyle = css({
  position: 'absolute',
  right: baseSpacing * 3,
  top: 0,
  bottom: 0,
  width: 150,
  lineHeight: `${baseSpacing * 5}px`,
  color: designToken.text.default,
  textAlign: 'right',
  fontSize: font.size.small,
  pointerEvents: 'none',
});

interface IQuickAddQuantity {
  disabled: boolean;
  pieces?: number;
  quantity: number;
  quantityInputRef: React.MutableRefObject<HTMLInputElement | null>;
  setQuantity: (quantity: number) => void;
  handleAddToCart: () => void;
}

const QuickAddQuantity = ({
  disabled,
  quantity,
  quantityInputRef,
  setQuantity,
  pieces,
  handleAddToCart,
}: IQuickAddQuantity) => {
  const { quantity: quantity_label, pcs, cases } = useLabels();

  const addToCartImmediatelyOnEnterKeypress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (quantity && e.key === KEYCODE_STRING.ENTER) {
      handleAddToCart();
    }
  };

  return (
    <div css={quantityWrapperStyle}>
      <input
        css={quantityInputStyle}
        disabled={disabled}
        readOnly={!pieces}
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        maxLength={4}
        placeholder={pieces ? '0' : quantity_label}
        value={quantity == 0 ? '' : quantity}
        ref={quantityInputRef}
        aria-label="Quick order quantity."
        tabIndex={0}
        onFocus={(e) => e.target.select()}
        onKeyDown={addToCartImmediatelyOnEnterKeypress}
        onChange={(e) => quantityOnChangeHandler(quantity, setQuantity, e)}
      />
      {pieces && (
        <span css={quantitySuffixStyle}>
          {cases} / {pieces * quantity} {pcs}
        </span>
      )}
    </div>
  );
};

export default QuickAddQuantity;
