import { ApmPageTransaction } from '@lego/b2b-unicorn-apm';
import { useHasFeature } from '@lego/b2b-unicorn-ui-featuretogglecontext';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PageNotFound } from '../../../shared/layouts/PageNotFound';
import { LaunchWindowCheckout } from './LaunchWindowCheckout';
import { LaunchWindowDetailsPage } from './LaunchWindowDetailsPage';
import LaunchWindowsOverview from './LaunchWindowsOverview';

export interface LaunchWindowDetailsParams {
  year: string;
  month: string;
}
export const LaunchRouter = () => {
  const hasLaunchOrders = useHasFeature('launchorders', false);

  return (
    <>
      {hasLaunchOrders && (
        <Switch>
          <Route
            exact
            path="/launch"
          >
            {({ match }) => (
              <ApmPageTransaction match={match}>
                <LaunchWindowsOverview />
              </ApmPageTransaction>
            )}
          </Route>
          <Route
            exact
            path={'/launch/:year/:month'}
          >
            {({ match }) => (
              <ApmPageTransaction match={match}>
                <LaunchWindowDetailsPage />
              </ApmPageTransaction>
            )}
          </Route>
          <Route
            exact
            path={'/launch/:year/:month/checkout'}
          >
            {({ match }) => (
              <ApmPageTransaction match={match}>
                <LaunchWindowCheckout />
              </ApmPageTransaction>
            )}
          </Route>
          <Route>
            {({ match }) => (
              <ApmPageTransaction match={match}>
                <PageNotFound />
              </ApmPageTransaction>
            )}
          </Route>
        </Switch>
      )}
      {!hasLaunchOrders && <PageNotFound />}
    </>
  );
};
