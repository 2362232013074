import { css } from '@emotion/react';
import React from 'react';

import { designToken } from '../theme';

type AfterPriceProps = {
  children: React.ReactNode;
};

const afterPriceCss = css({
  color: designToken.error.dark,
  marginLeft: `0.3rem`,

  '&:only-child': {
    color: 'inherit',
  },
});

export const AfterPrice: React.FC<AfterPriceProps> = ({ children }) => {
  return <span css={afterPriceCss}>{children}</span>;
};
