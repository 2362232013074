import { css } from '@emotion/react';
import React from 'react';

import { colors } from '../theme';

const pageBodyWrapperStyle = css({
  position: 'relative',
  minHeight: '100vh',
  backgroundColor: colors.klik.slate50,
});

type BodyWrapperProps = {
  children: React.ReactNode;
};
export const BodyWrapper: React.FC<BodyWrapperProps> = ({ children }) => {
  return <div css={pageBodyWrapperStyle}>{children}</div>;
};
