import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { baseSpacing, media } from '../theme';

export const tableRowCellStyle = (
  width?: number | string,
  align?: React.CSSProperties['textAlign'],
  noPadding?: boolean
) =>
  css({
    display: 'block',
    lineHeight: 1.7,
    width,
    [media.medium]: {
      display: 'table-cell',
      verticalAlign: 'middle',
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderBottomColor: tokens.color.core.gray[50],
      padding: noPadding ? 0 : baseSpacing,
      textAlign: align,
      '> svg': {
        height: 18,
        width: 18,
        marginBottom: -4,
      },
    },
    '&:before': {
      display: 'block',
      position: 'absolute',
      right: 'calc(70% + 4px)',
      left: baseSpacing,
      maxWidth: '30%',
      textAlign: 'right',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      content: 'attr(data-col)',
      overflow: 'hidden',
      [media.medium]: {
        display: 'none',
      },
    },
  });

export const tableRowCellNumberStyle = css({
  fontFeatureSettings: '"tnum"',
  fontVariantNumeric: 'tabular-nums',
  [media.medium]: {
    textAlign: 'right',
  },
});
