import { gql } from '@apollo/client';

export const REPLENISHMENT_UPDATE_CART_WITH_MULTIPLE_ITEMS = gql`
  mutation replenishmentUpdateCartWithMultipleItems(
    $customerId: Int!
    $items: [UpdateCartItemInput!]!
  ) {
    updateCartWithMultipleItems(
      customerId: $customerId
      items: $items
      cartReference: { cartType: REPLENISH }
    ) {
      items {
        ...minimumCartItem
      }
      ...cartKey
    }
  }
`;
