import type { SerializedStyles } from '@emotion/react';
import { Counter } from '@lego/b2b-unicorn-shared/components/Counter';
import { CART_MAX_ITEM_QUANTITY } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

interface CartItemCounterProps {
  quantity: number;
  onChange: (quantity: number) => void;
  customStyle: SerializedStyles;
  allowResetByZero?: boolean;
  minValue?: number;
  minValueHelpText?: string;
  isInvalid?: boolean;
}

export const CartItemCounter: React.FC<CartItemCounterProps> = ({
  quantity,
  onChange,
  customStyle,
  allowResetByZero = false,
  minValue,
  minValueHelpText,
  isInvalid,
}) => {
  const MIN_QUANTITY = minValue || (allowResetByZero ? 0 : 1);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value));
  };

  return (
    <Counter
      value={quantity}
      onChange={handleOnChange}
      maxValue={CART_MAX_ITEM_QUANTITY}
      minValue={MIN_QUANTITY}
      minValueHelpText={minValueHelpText}
      customStyle={customStyle}
      isInvalid={isInvalid}
    />
  );
};
