import { gql } from '@apollo/client';

export const FRAGMENT_PROMOTION_OVERVIEW_ITEM = gql`
  fragment promotionOverviewItem on PromotionsOverviewItem {
    id
    executionDates {
      startDate
      endDate
    }
    deliveryDates {
      latestDate
    }
    orderDeadlineDate
    description
    productsCount
    ordersCreated {
      orderNumber
    }
    cartHasItems
  }
`;
