import { createContext } from 'react';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';

const SearchTranslationsContext = createContext();

const arrowKeypressNavigation = (e) => {
  if (e.key === KEYCODE_STRING.ARROW_UP) {
    e.nativeEvent.target.previousElementSibling?.focus();
  } else if (e.key === KEYCODE_STRING.ARROW_DOWN) {
    e.nativeEvent.target.nextElementSibling?.focus();
  }
};

const arrowKeypressNavigationPreventingDefault = (e) => {
  if (e.key === KEYCODE_STRING.ARROW_UP) {
    e.preventDefault();
    e.nativeEvent.target.previousElementSibling?.focus();
  } else if (e.key === KEYCODE_STRING.ARROW_DOWN) {
    e.preventDefault();
    e.nativeEvent.target.nextElementSibling?.focus();
  }
};

const RESULTS_LIMIT = 20;
const MIN_CHARS = 3;
const CUSTOM_BREAKPOINT_PIXELS = 750; //@TODO: pixels need update when soldto selection ready
const CUSTOM_MIN_DROPDOWN_MEDIA = `@media (min-width: ${CUSTOM_BREAKPOINT_PIXELS}px)`;
const CUSTOM_MAX_DROPDOWN_MEDIA = `@media (max-width: ${CUSTOM_BREAKPOINT_PIXELS}px)`;
const SEARCH_TYPE = 'query';
const FILTER_TYPES = {
  PRICE_RANGES: 'priceRanges',
  THEMES: 'themes',
  AVAILABILITIES: 'availabilities',
  LIFECYCLES: 'lifecycles',
};

const getSearchFromUrl = (location) => {
  const queryParams = new URLSearchParams(location.search);
  const sorting = queryParams.get(SEARCH_TYPE);
  return sorting || '';
};

export {
  CUSTOM_BREAKPOINT_PIXELS,
  CUSTOM_MIN_DROPDOWN_MEDIA,
  CUSTOM_MAX_DROPDOWN_MEDIA,
  arrowKeypressNavigation,
  arrowKeypressNavigationPreventingDefault,
  MIN_CHARS,
  RESULTS_LIMIT,
  SEARCH_TYPE,
  SearchTranslationsContext,
  getSearchFromUrl,
  FILTER_TYPES,
};
