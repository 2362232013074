import { css } from '@emotion/react';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';

import {
  skeletonBottomContainerStyle,
  skeletonImageStyle,
  skeletonPlaceholderButtonStyle,
  skeletonPlaceholderStyle,
  skeletonProductTileStyle,
  skeletonStatusStyle,
} from '../../../../styles';
import EmptyBox from '../../../common/EmptyBox';

const productTileStyle = css(skeletonProductTileStyle, {
  height: 273,
  '&:after': {
    height: 273,
  },
});

const imageStyle = css(skeletonImageStyle, {
  height: 138,
  marginTop: -4,
  marginBottom: baseSpacing,
  '> svg': {
    height: 138,
  },
});

const SkeletonRecommendationTile = () => (
  <article css={productTileStyle}>
    <div css={skeletonStatusStyle} />
    <div css={imageStyle}>
      <EmptyBox />
    </div>
    <div css={[skeletonPlaceholderStyle, { height: 14, width: 205, marginBottom: 8 }]} />
    <div css={[skeletonPlaceholderStyle, { height: 24, width: 140, marginBottom: 8 }]} />
    <div css={skeletonBottomContainerStyle}>
      <div css={[skeletonPlaceholderStyle, { height: 42, width: '50%', marginRight: 8 }]} />
      <div css={skeletonPlaceholderButtonStyle} />
    </div>
  </article>
);

export default SkeletonRecommendationTile;
