import { css } from '@emotion/react';
import { CSSInterpolation } from '@emotion/serialize/dist/emotion-serialize.cjs';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { baseSpacing, designToken, font, media } from '../theme';

const listItemStyle = (extraCss?: CSSInterpolation) =>
  css(
    {
      display: 'flex',
      width: `calc(100% + ${baseSpacing * 4}px)`,
      alignItems: 'center',
      fontSize: font.size.small,
      color: designToken.text.default,
      textAlign: 'left',
      marginRight: -baseSpacing * 2,
      marginLeft: -baseSpacing * 2,
      padding: baseSpacing * 2,
      [media.medium]: {
        marginRight: 0,
        marginLeft: 0,
        padding: baseSpacing,
        width: '100%',
      },
      borderWidth: 0,
      borderBottomWidth: 1,
      borderBottomColor: tokens.color.core.slate[200],
      borderBottomStyle: 'solid',
      textDecoration: 'none',
      outline: 0,
      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },
    extraCss
  );

const listItemClickableStyle = (extraCss?: CSSInterpolation) =>
  css(
    listItemStyle(),
    {
      color: designToken.primary.default,
      '&:focus': {
        color: designToken.primary.active,
      },
      '&:hover': {
        color: designToken.primary.hover,
      },
      '&:hover, &:focus': {
        width: `calc(100% + ${baseSpacing * 4}px)`,
        background: designToken.background.blue,
        borderBottomColor: designToken.background.blue,
        [media.medium]: {
          width: '100%',
        },
      },
    },
    extraCss
  );

type ListItemProps = {
  children: React.ReactNode;
  extraCss?: CSSInterpolation;
  onClick?: () => void;
};

export const ListItem: React.FC<ListItemProps> = ({ children, onClick, extraCss }) => {
  const ElementType = onClick ? 'button' : 'li';

  return (
    <ElementType
      css={onClick ? listItemClickableStyle(extraCss) : listItemStyle(extraCss)}
      onClick={onClick}
    >
      {children}
    </ElementType>
  );
};
