import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation createOrder(
    $customerId: Int!
    $shipToId: Int!
    $requestedDeliveryDate: String!
    $orderName: String
    $cartReference: CartReferenceInput!
  ) {
    createOrder(
      customerId: $customerId
      shipToId: $shipToId
      requestedDeliveryDate: $requestedDeliveryDate
      orderName: $orderName
      cartReference: $cartReference
    ) {
      orderNumber
    }
  }
`;
