import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartItem, CartSimulationDetails, Money } from '@lego/b2b-unicorn-data-access-layer';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { AnimatedDots, designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { pagePaddingStyle } from '@lego/b2b-unicorn-ui-checkout-flow';
import { DialogPopup, DialogWrapper } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import { CartMath, mapToMoneyOrNull } from '@lego/b2b-unicorn-ui-utils';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { SummaryPrice } from './SummaryPrice';

const summaryBackgroundStyle = (loading: boolean) =>
  css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: loading ? designToken.skeleton.secondary : designToken.background.blue,
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 8,
    minHeight: 160,
    padding: baseSpacing * 2,
  });

const summaryStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: 0,
  [media.medium]: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  '> button': {
    width: '100%',
    [media.medium]: {
      minWidth: 160,
      maxWidth: 200,
    },
  },
});

const valuesContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 2,
  [media.medium]: {
    justifyContent: 'center',
  },
});

const summaryItemStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: font.size.medium,
  fontWeight: font.weight.light,
  color: designToken.text.default,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: baseSpacing * 2,
  marginRight: baseSpacing,
  fontFeatureSettings: '"tnum"',
  fontVariantNumeric: 'tabular-nums',
  [media.small]: {
    fontSize: font.size.large,
    marginRight: baseSpacing * 2,
  },
  [media.medium]: {
    marginLeft: baseSpacing * 2.5,
    marginRight: baseSpacing * 2.5,
  },

  '> span:first-of-type': {
    fontSize: font.size.tiny,
    fontWeight: font.weight.normal,
    [media.small]: {
      fontSize: font.size.small,
    },
    [media.medium]: {
      marginRight: baseSpacing,
    },
  },
  '> span:nth-of-type(2)': {
    fontWeight: font.weight.bold,
    lineHeight: 1,
    marginTop: baseSpacing,
    [media.small]: {
      marginTop: 0,
    },
  },
});

const subHeaderStyle = css(summaryItemStyle, {
  [media.medium]: {
    maxWidth: '20%',
  },
});

const totalAmountStyle = css({
  color: designToken.text.muted,
  fontWeight: font.weight.bold,
  lineHeight: 1,
  marginTop: baseSpacing,
  [media.small]: {
    marginTop: 0,
  },

  '> span:first-of-type': {
    fontSize: font.size.tiny,
    fontWeight: font.weight.normal,
    border: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    marginTop: 2,
    padding: 0,
    display: 'block',
    '> span': {
      display: 'inline-block',
      zIndex: 10,
      transform: 'translateY(3px)',
    },
  },
});

const warningAmountStyle = css(totalAmountStyle, {
  color: designToken.warning.default,
  '> span': {
    color: designToken.text.muted,
  },
});

const finalAmountStyle = css(totalAmountStyle, {
  color: designToken.success.default,
  '> span:first-of-type': {
    color: designToken.text.muted,
    marginRight: baseSpacing,
  },
});

const dialogPopupContentStyle = css({
  fontSize: font.size.small,
  marginRight: 20,
});

const finalPriceDetailsPopupStyle = css({
  fontSize: font.size.small,
  width: '100%',
  '> h4': {
    fontWeight: font.weight.bold,
    margin: 0,
    padding: 0,
    paddingBottom: baseSpacing,
    paddingRight: 20,
  },
});

const detailsListItemStyle = css({
  width: '100%',
  margin: 0,
  padding: 0,
  paddingTop: baseSpacing / 2,
  overflow: 'auto',
  '> span:first-of-type': {
    marginRight: baseSpacing,
  },
  '> span:last-of-type': {
    float: 'right',
    textAlign: 'right',
    fontFeatureSettings: '"tnum"',
    fontVariantNumeric: 'tabular-nums',
  },
});

const withTopLineStyle = css({
  paddingTop: baseSpacing,
  marginTop: baseSpacing,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: tokens.color.core.gray[700],
});

const infoIconStyle = css({
  height: 14,
  width: 14,
  marginLeft: baseSpacing / 2,
  [media.small]: {
    marginTop: -4,
  },

  '> svg': {
    height: 14,
    width: 14,
    fill: tokens.color.core.gray[700],
  },
});

const warningStyle = css({
  color: designToken.warning.default,
  fontSize: font.size.tiny,
  fontWeight: font.weight.bold,
  margin: 0,
});

const calculatingStyle = css({
  maxHeight: 25,
  color: tokens.color.core.azur[800],
  fontWeight: font.weight.bold,
  letterSpacing: 0,
  display: 'flex',
  alignItems: 'center',
  marginTop: 3,
  [media.small]: {
    minWidth: 218,
    margin: 0,
    padding: 0,
  },
  '> div': {
    marginTop: 3,
  },
});

interface OrderSummaryProps {
  children: React.ReactNode;
  disclaimerRender?: React.ReactNode;
  simulationRunning?: boolean;
  simulationDetails?: CartSimulationDetails | null;
  simulationFinalPrice?: Money;
  simulationFinalListPrice?: Money;
  minimumOrderValue: Money;
  cartItems: CartItem[];
  loading?: boolean;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  children,
  disclaimerRender,
  simulationRunning,
  simulationDetails,
  simulationFinalPrice,
  simulationFinalListPrice,
  minimumOrderValue,
  cartItems,
  loading = false,
}) => {
  const selectedCustomer = useSelectedCustomer();

  // There can only be one currency in a cart, that's why we just take the first one
  const currency = cartItems.length > 0 && cartItems[0].product.price.currency;

  const sumPrice = CartMath.sumPrice(cartItems);
  const sumGrossPrice = CartMath.sumGrossPrice(cartItems, true);
  const sumNetPrice =
    CartMath.sumNetInvoicedPrice(cartItems, true) ||
    CartMath.sumEstimatedNetInvoicedPrice(cartItems);
  const isMinValueMet = sumNetPrice.netInvoicedPrice
    ? sumNetPrice.netInvoicedPrice > minimumOrderValue.amount
    : sumGrossPrice.grossPrice > minimumOrderValue.amount;

  const sumCases = CartMath.sumCases(cartItems);
  const sumUnits = CartMath.sumUnits(cartItems);

  const {
    order_summary,
    total_cases,
    total_pieces,
    total_order_price,
    reference_price,
    reference_price_dialog_content,
    total_order_price_minimum_value,
    final_price,
    final_price_dialog_header,
    final_price_dialog_list_price,
    final_price_dialog_discounts,
    final_price_dialog_surcharge,
    order_total,
    final_price_invoice_information,
    final_price_dialog_content,
  } = useLabels();

  const renderValue = (value?: number | null) =>
    selectedCustomer && (
      <Price
        locale={selectedCustomer.locale}
        fallbackValue={''}
      >
        {mapToMoneyOrNull(value, currency || undefined)}
      </Price>
    );
  const renderMinOrderValue = () => (
    <Price
      locale={selectedCustomer.locale}
      fallbackValue={''}
    >
      {minimumOrderValue}
    </Price>
  );

  const renderInitialPrice = () => {
    return (
      <div
        css={
          isMinValueMet ||
          sumPrice.grossPrice === 0 ||
          sumPrice.estimatedNetInvoicedPrice === 0 ||
          sumPrice.netInvoicedPrice === 0
            ? totalAmountStyle
            : warningAmountStyle
        }
      >
        <SummaryPrice locale={selectedCustomer.locale}>{sumPrice}</SummaryPrice>
        {selectedCustomer.showSimulationDetails && (
          <span>
            {reference_price}
            <DialogWrapper styles={infoIconStyle}>
              <Icon type={IconType.INFO} />
              <DialogPopup
                icon
                direction="bottom"
              >
                <p css={dialogPopupContentStyle}>{reference_price_dialog_content}</p>
              </DialogPopup>
            </DialogWrapper>
          </span>
        )}
        {(sumPrice.grossPrice !== 0 ||
          sumPrice.netInvoicedPrice !== 0 ||
          sumPrice.estimatedNetInvoicedPrice !== 0) &&
          !isMinValueMet && (
            <p css={warningStyle}>
              {total_order_price_minimum_value} {renderMinOrderValue()}
            </p>
          )}
      </div>
    );
  };

  const renderFinalPrice = () => {
    return (
      <div css={finalAmountStyle}>
        <Price
          locale={selectedCustomer.locale}
          fallbackValue={'0'}
          wrappers={{
            nipPrice: ({ children }) => <p css={{ lineHeight: 1 }}>{children}</p>,
            grossPrice: ({ children }) => <p css={{ lineHeight: 1 }}>{children}</p>,
          }}
        >
          {simulationFinalPrice}
        </Price>
        {selectedCustomer.showSimulationDetails ? (
          <span>
            {final_price}
            <DialogWrapper styles={infoIconStyle}>
              <Icon type={IconType.INFO} />
              <DialogPopup
                icon
                direction="bottom"
              >
                <div css={finalPriceDetailsPopupStyle}>
                  <h4>{final_price_dialog_header}</h4>
                  <p css={detailsListItemStyle}>
                    <span>{final_price_dialog_list_price}</span>
                    <span>
                      <Price
                        locale={selectedCustomer.locale}
                        fallbackValue={'-'}
                      >
                        {simulationFinalListPrice}
                      </Price>
                    </span>
                  </p>
                  {simulationDetails?.totalDiscount && (
                    <p css={detailsListItemStyle}>
                      <span>{final_price_dialog_discounts}</span>
                      <span>{renderValue(simulationDetails.totalDiscount.amount)}</span>
                    </p>
                  )}
                  {simulationDetails?.totalItemSurcharge && (
                    <p css={detailsListItemStyle}>
                      <span>{final_price_dialog_surcharge}</span>
                      <span>{renderValue(simulationDetails.totalItemSurcharge.amount)}</span>
                    </p>
                  )}
                  <p css={[detailsListItemStyle, withTopLineStyle]}>
                    <span>{order_total}</span>
                    <span>
                      <Price
                        locale={selectedCustomer.locale}
                        fallbackValue={'-'}
                      >
                        {simulationFinalPrice}
                      </Price>
                    </span>
                  </p>
                </div>
              </DialogPopup>
            </DialogWrapper>
          </span>
        ) : (
          <span>
            {final_price_invoice_information}
            <DialogWrapper styles={infoIconStyle}>
              <Icon type={IconType.INFO} />
              <DialogPopup
                icon
                direction="bottom"
              >
                <p css={dialogPopupContentStyle}>{final_price_dialog_content}</p>
              </DialogPopup>
            </DialogWrapper>
          </span>
        )}
        {!isMinValueMet && (
          <p css={warningStyle}>
            {total_order_price_minimum_value} {renderMinOrderValue()}
          </p>
        )}
      </div>
    );
  };

  return (
    <div css={pagePaddingStyle}>
      <section css={summaryBackgroundStyle(loading)}>
        <div css={summaryStyle}>
          {!loading && (
            <>
              <p css={subHeaderStyle}>{order_summary}</p>
              <div css={valuesContainerStyle}>
                <div css={summaryItemStyle}>
                  <span>{total_cases}</span>
                  <span>{sumCases}</span>
                </div>
                <div css={summaryItemStyle}>
                  <span>{total_pieces}</span>
                  <span>{sumUnits}</span>
                </div>
                <div css={summaryItemStyle}>
                  <span>{total_order_price}</span>
                  {!simulationRunning ? (
                    !simulationDetails ? (
                      renderInitialPrice()
                    ) : (
                      renderFinalPrice()
                    )
                  ) : (
                    <div css={calculatingStyle}>
                      Calculating
                      <AnimatedDots
                        lightDesignToken={'information.subdued'}
                        darkDesignToken={'information.default'}
                        mediumSize
                      />
                    </div>
                  )}
                </div>
              </div>
              {children}
            </>
          )}
        </div>
      </section>
      {disclaimerRender}
    </div>
  );
};

export default OrderSummary;
