import { ApmService } from '@lego/b2b-unicorn-apm';
import { Authentication } from '@lego/b2b-unicorn-authentication';
import { ASSETS_URL } from '@lego/b2b-unicorn-bootstrap/constants';
import { authorizedFetchCall } from '@lego/b2b-unicorn-shared/utils';

import type { IBanner } from '../Banner';

type Banner = IBanner;

const onError = (e: unknown) => {
  ApmService.instance?.captureError(e as Error);
};

export const getLatestBanner = async (customerId: number): Promise<Banner> => {
  const latestBanner = await authorizedFetchCall(
    `${ASSETS_URL}/router/notification-banner`,
    customerId,
    Authentication.instance.getIdToken.bind(Authentication.instance),
    onError,
    { cache: 'no-store' }
  );

  return latestBanner?.json();
};
