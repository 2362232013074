import type { SerializedStyles } from '@emotion/react';
import React from 'react';

import { Icon, IconType } from '../../../ui/Icon';

type CounterPlusButtonProps = {
  onClick: (e: React.UIEvent) => void;
  disabled: boolean;
  cssStyles: SerializedStyles;
  ref: React.Ref<HTMLButtonElement>;
};

export const CounterPlusButton: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<CounterPlusButtonProps> & React.RefAttributes<HTMLButtonElement>
> = React.forwardRef<HTMLButtonElement, CounterPlusButtonProps>(
  ({ onClick, disabled, cssStyles }, ref) => {
    return (
      <button
        css={cssStyles}
        onClick={onClick}
        aria-label="Add quantity of product."
        disabled={disabled}
        tabIndex={-1}
        ref={ref}
      >
        <Icon type={IconType.PLUS} />
      </button>
    );
  }
);
CounterPlusButton.displayName = 'CounterPlusButton';
