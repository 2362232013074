import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import { Fragment } from 'react';

import { Banner } from '../../shared/Components/Notifications';
import { PageNotFound } from '../../shared/layouts/PageNotFound';

const PageNotFoundLayout = () => {
  return (
    <Fragment>
      <TopbarImplementation />
      <Banner />
      <PageNotFound noTopbar />
    </Fragment>
  );
};

export default PageNotFoundLayout;
