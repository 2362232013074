import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { PreviousLaunchWindow } from '@lego/b2b-unicorn-data-access-layer';
import { Tile, TileBottom, TileButton } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { orderIndicatorStyle } from '../styles';
import { LaunchWindowTileHeader } from './LaunchWindowTileHeader';

export const PreviousLaunchWindowTile = ({
  previousLaunchWindow,
}: {
  previousLaunchWindow: PreviousLaunchWindow;
}) => {
  const { launch_window_open, launch_window_existing_orders } = useLabels();
  const ordersCreatedCount = previousLaunchWindow.ordersCreated.length || 0;
  const isActive = false;
  const history = useHistory();
  const handleClick = () => {
    history.push(`/launch/${previousLaunchWindow.year}/${previousLaunchWindow.month}`);
  };

  return (
    <Tile
      isActive={isActive}
      onClick={handleClick}
    >
      <LaunchWindowTileHeader
        month={previousLaunchWindow.month}
        year={previousLaunchWindow.year}
        productsCount={previousLaunchWindow.productsCount}
        isActive={isActive}
      />
      <TileBottom>
        <span css={orderIndicatorStyle}>
          {ordersCreatedCount > 0 && `${ordersCreatedCount} ${launch_window_existing_orders}`}
        </span>
        <TileButton withAlert={false}>{launch_window_open}</TileButton>
      </TileBottom>
    </Tile>
  );
};
