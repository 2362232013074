import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, designToken, media } from '../theme';

type TableRowProps = {
  children: React.ReactNode;
  extraCss?: Parameters<typeof css>[0];
  highlight?: boolean;
};

const tableRowStyle = (highlight: boolean) =>
  css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: highlight ? 0 : undefined,
    marginBottom: baseSpacing * 3,
    backgroundColor: highlight ? designToken.background.blue : designToken.background.offWhite,

    [media.medium]: {
      display: 'table-row',
      position: 'initial',
      height: 57,
    },

    '> td': {
      [media.medium]: {
        borderColor: highlight ? designToken.background.offWhite : undefined,
      },
    },
  });

export const TableRow: React.FC<TableRowProps> = ({ children, extraCss, highlight = false }) => {
  return <tr css={[tableRowStyle(highlight), extraCss]}>{children}</tr>;
};
