import { css, Global } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media, mediaSizes } from '@lego/b2b-unicorn-ui-constants';
import React, { Fragment, useState } from 'react';

import LanguageSelector from '../LanguageSelector/LanguageSelector';
import MenuItem from './MenuItem';

const burgerButtonStyle = css({
  border: 'none',
  background: 'transparent',
  height: 50,
  marginRight: baseSpacing / 2,
  padding: baseSpacing,
  lineHeight: 0,
  cursor: 'pointer',
  [media.large]: {
    display: 'none',
  },
  svg: {
    width: 24,
    height: 24,
    fill: colors.klik.slate10,
  },
});

const closeButtonStyle = css({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: baseSpacing,
  margin: 0,
  lineHeight: 0,
  svg: {
    width: 20,
    height: 20,
    fill: colors.klik.slate10,
  },
});

const linksTitleStyle = css({
  fontSize: font.size.large,
  fontWeight: font.weight.normal,
  display: 'flex',
  padding: baseSpacing,
  paddingTop: baseSpacing * 3,
  paddingBottom: baseSpacing * 2,
  justifyContent: 'space-between',
  [media.large]: {
    display: 'none',
  },
});

const linksWrapperStyle = (mobileVisible: boolean) =>
  css({
    display: mobileVisible ? 'block' : 'none',
    backgroundColor: designToken.primary.default,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
    zIndex: 600,
    height: '100%',
    '&:before': {
      position: 'absolute',
      zIndex: -1,
      content: 'none',
      width: '100vw',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      [media.small]: {
        content: '""',
        left: '50vw',
      },
      [media.medium]: {
        left: '40vw',
      },
      [media.large]: {
        content: 'none',
      },
    },
    '> a': {
      height: 60,
      [media.large]: {
        height: 72,
      },
    },
    [media.small]: {
      right: '50vw',
    },
    [media.medium]: {
      right: '60vw',
    },
    [media.large]: {
      backgroundColor: 'transparent',
      position: 'static',
      display: 'flex',
      flexGrow: 1,
      borderTop: 'none',
      height: 72,
    },
  });

const bodyNoScrollGlobalStyleElement = (
  <Global
    styles={css`
      @media (max-width: ${mediaSizes.klik.xl}) {
        body,
        html {
          height: 100vh;
          overflow: hidden;
        }
      }
    `}
  />
);

const mobileLanguageSelectorStyle = css({
  display: 'contents',
  [media.large]: {
    display: 'none',
  },
});

interface IMenuProps {
  translations: {
    navbar_link_shop: string;
    navbar_link_manage: string;
    navbar_link_info: string;
    navbar_link_launch: string;
    navbar_link_promo: string;
    navbar_menu_label: string;
  };
  launchOrdersEnabled: boolean;
  promotionOrdersFeatureEnabled: boolean;
}

const Menu = (props: IMenuProps) => {
  const {
    translations: {
      navbar_link_shop,
      navbar_link_manage,
      navbar_link_info,
      navbar_link_launch,
      navbar_link_promo,
      navbar_menu_label,
    },
    launchOrdersEnabled,
    promotionOrdersFeatureEnabled,
  } = props;

  const [mobileSidebarVisible, setMobileSidebarVisibility] = useState(false);

  const toggleSidebar = () => {
    setMobileSidebarVisibility(!mobileSidebarVisible);
  };

  return (
    <Fragment>
      {mobileSidebarVisible && bodyNoScrollGlobalStyleElement}
      <button
        css={burgerButtonStyle}
        onClick={toggleSidebar}
        aria-label="Open mobile menu."
      >
        <Icon type={IconType.MENU} />
      </button>
      <div css={linksWrapperStyle(mobileSidebarVisible)}>
        <div css={linksTitleStyle}>
          {navbar_menu_label}
          <button
            css={closeButtonStyle}
            type="button"
            onClick={toggleSidebar}
            aria-label="Close mobile menu."
          >
            <Icon type={IconType.CLOSE} />
          </button>
        </div>
        <MenuItem path="/">{navbar_link_shop}</MenuItem>
        <MenuItem path="/manage">{navbar_link_manage}</MenuItem>
        <MenuItem path="/info">{navbar_link_info}</MenuItem>
        {launchOrdersEnabled && <MenuItem path="/launch">{navbar_link_launch}</MenuItem>}
        {promotionOrdersFeatureEnabled && (
          <MenuItem path="/promotion">{navbar_link_promo}</MenuItem>
        )}
        <div css={mobileLanguageSelectorStyle}>
          <LanguageSelector />
        </div>
      </div>
    </Fragment>
  );
};

export default Menu;
