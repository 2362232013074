import { ReplenishmentProductsQuery_products_ProductList_products_Supplementary } from '../../../generated-types/graphql';
import { Lifecycle, ProductTag } from '../../../generated-types/types';
import { Supplementary } from '../../../type-policies/ProductPolicy/entities';

export class ReplenishmentProductsSupplementary
  extends Supplementary
  implements ReplenishmentProductsQuery_products_ProductList_products_Supplementary
{
  ean: string;
  lifecycles: Lifecycle[];
  materialId: number;
  name?: string | null;
  piecesPerCasePack: number;
  tags: ProductTag[];
  theme: string;
  openOrderInfo?: ReplenishmentProductsQuery_products_ProductList_products_Supplementary['openOrderInfo'];
  price: ReplenishmentProductsQuery_products_ProductList_products_Supplementary['price'];
  stockStatus: ReplenishmentProductsQuery_products_ProductList_products_Supplementary['stockStatus'];

  constructor(
    supplementary: ReplenishmentProductsQuery_products_ProductList_products_Supplementary
  ) {
    super();
    this.ean = supplementary.ean;
    this.lifecycles = supplementary.lifecycles;
    this.materialId = supplementary.materialId;
    this.name = supplementary.name;
    this.piecesPerCasePack = supplementary.piecesPerCasePack;
    this.tags = supplementary.tags;
    this.theme = supplementary.theme;
    this.openOrderInfo = supplementary.openOrderInfo;
    this.price = supplementary.price;
    this.stockStatus = supplementary.stockStatus;
  }
}
