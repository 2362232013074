import { gql } from '@apollo/client';

export const USER = gql`
  query User {
    getUser {
      email
      customers {
        id
        name
        city
        language
        country
        salesOrg
        locale
        currency
        paymentMethod
        showSimulationDetails
        availableLanguages {
          id
          code
        }
      }
      preferencesForCustomers {
        customerId
        preferredLanguage {
          id
          code
        }
      }
    }
  }
`;
