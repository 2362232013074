import {
  CreateClaimFileResourceMutation,
  CreateClaimFileResourceMutationVariables,
  CreateClaimMutation,
  CreateClaimMutationVariables,
} from '../../generated-types/graphql';
import { ContextAbstract, MutationObservable } from '../ContextAbstract';
import { CREATE_CLAIM } from './mutations/createClaim';
import { CREATE_CLAIM_FILE_RESOURCE } from './mutations/createClaimFileResource';

export class ClaimsDataContext extends ContextAbstract {
  public createClaimFileResource(customerId: number) {
    const observableKey = `createClaimFileResource-${customerId}`;

    const [mutate, reset, results] = this.mutationObservable<
      CreateClaimFileResourceMutation,
      CreateClaimFileResourceMutationVariables
    >(observableKey, CREATE_CLAIM_FILE_RESOURCE, 'input');

    return [
      (variables) => {
        return mutate({
          customerId,
          ...variables,
        });
      },
      reset,
      results,
    ] as MutationObservable<
      CreateClaimFileResourceMutation,
      Omit<CreateClaimFileResourceMutationVariables, 'customerId'>
    >;
  }

  public createClaim(customerId: number) {
    const observableKey = `createClaim-${customerId}`;

    const [mutate, reset, results] = this.mutationObservable<
      CreateClaimMutation,
      CreateClaimMutationVariables
    >(observableKey, CREATE_CLAIM);

    return [
      (variables) => {
        return mutate({
          customerId,
          ...variables,
        });
      },
      reset,
      results,
    ] as MutationObservable<CreateClaimMutation, Omit<CreateClaimMutationVariables, 'customerId'>>;
  }
}
