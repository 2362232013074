import { ApmPageTransaction } from '@lego/b2b-unicorn-apm';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PageNotFound } from '../../../shared/layouts/PageNotFound';
import { PromotionCheckout } from './PromotionCheckout';
import { PromotionDetailsPage } from './PromotionDetailsPage';
import PromotionsOverview from './PromotionsOverview';

export interface PromotionRouterDetailsParams {
  promotionId: string;
}

export const PromotionRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/promotion"
      >
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <PromotionsOverview />
          </ApmPageTransaction>
        )}
      </Route>
      <Route
        exact
        path={'/promotion/:promotionId(\\d+)'}
      >
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <PromotionDetailsPage />
          </ApmPageTransaction>
        )}
      </Route>
      <Route
        exact
        path={'/promotion/:promotionId(\\d+)/checkout'}
      >
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <PromotionCheckout />
          </ApmPageTransaction>
        )}
      </Route>
      <Route>
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <PageNotFound />
          </ApmPageTransaction>
        )}
      </Route>
    </Switch>
  );
};
