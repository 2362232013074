import { Fragment, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { css } from '@emotion/react';
import { font, colors, baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import {
  MIN_CHARS,
  CUSTOM_MIN_DROPDOWN_MEDIA,
  SEARCH_TYPE,
  arrowKeypressNavigation,
  SearchTranslationsContext,
  FILTER_TYPES,
} from './searchCommons';
import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';

const searchBorderStyle = css({
  position: 'absolute',
  width: '100%',
  height: 40,
  pointerEvents: 'none',
  top: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: designToken.border.information,
    borderRadius: 4,
    backgroundColor: designToken.background.offWhite,
    zIndex: -1,
  },
});

const searchInputResultsShownStyle = css({
  top: baseSpacing * 6,
  [CUSTOM_MIN_DROPDOWN_MEDIA]: {
    top: 0,
  },
});

const searchInputStyle = css({
  [CUSTOM_MIN_DROPDOWN_MEDIA]: {
    width: 'calc(100% - 70px)',
  },
  color: designToken.text.default,
  width: 'calc(100% - 30px)',
  border: 'none',
  outline: 'none',
  fontSize: font.size.small,
  padding: baseSpacing,
  backgroundColor: 'transparent',
  order: -2,
  height: baseSpacing * 5,
  minHeight: baseSpacing * 5,
  position: 'absolute',
  top: 0,
});

const searchInputButtonStyle = css({
  position: 'absolute',
  top: 0,
  lineHeight: 0,
  margin: 0,
  border: 'none',
  background: designToken.background.white,
  padding: 0,
  order: -10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '> svg': {
    fill: colors.klik.slate600,
    width: 20,
    height: 20,
  },
  outline: 'none',
});

const searchResetStyle = (hasCharacters) =>
  css({
    right: baseSpacing * 4,
    margin: baseSpacing * 1.25,
    marginRight: 6,
    svg: {
      fill: !hasCharacters && colors.klik.slate300,
      transitionProperty: 'fill',
      transitionDuration: '150ms',
      '&:hover': {
        fill: hasCharacters && colors.klik.lightBlue500,
      },
    },
    pointerEvents: hasCharacters ? 'auto' : 'none',
    cursor: hasCharacters ? 'pointer' : 'none',
    display: hasCharacters ? 'block' : 'none',
  });

const searchNavigateStyle = (allowNavigation) =>
  css({
    width: baseSpacing * 3,
    height: baseSpacing * 3,
    right: 0,
    margin: baseSpacing,
    svg: !allowNavigation && {
      fill: colors.klik.slate400,
    },
    pointerEvents: allowNavigation ? 'auto' : 'none',
  });

const SearchInput = ({
  searchInput,
  inputOnChangeHandler,
  inputRef,
  closeSearch,
  numberOfResults,
  onBlur,
  onFocus,
  resultsShown,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [shouldGoToResults, goToResults] = useState(false);
  const isCurrentlyInProductList =
    location.pathname === '/' &&
    (window.location.host.includes('shop') || window.location.host.includes('localhost'));

  const { search_header_and_placeholder } = useContext(SearchTranslationsContext);

  const clearAndUpdateUrl = (value) => {
    if (value) {
      const queryParams = new URLSearchParams(location.search);
      Object.keys(FILTER_TYPES).forEach((filterType) =>
        queryParams.delete(FILTER_TYPES[filterType])
      );
      queryParams.set(SEARCH_TYPE, value);
      history.push({ search: encodeURI(queryParams.toString()) });
    }
  };

  const resetInput = () => {
    inputOnChangeHandler('');
    inputRef.current.focus();
  };

  const updateUrl = () => {
    closeSearch();
    if (isCurrentlyInProductList) {
      clearAndUpdateUrl(searchInput);
    }
  };

  const onKeyDownHandler = (e) => {
    arrowKeypressNavigation(e);
    if (e.key === KEYCODE_STRING.ENTER && !!numberOfResults) {
      !isCurrentlyInProductList && goToResults(true);
      updateUrl();
    }
  };

  const onBlurHandler = (e) => {
    onBlur(e);
  };

  const onFocusHandler = (e) => {
    onFocus(e);
  };

  const renderIconWithButton = () => (
    <button
      css={[
        searchInputButtonStyle,
        searchNavigateStyle(searchInput.length >= MIN_CHARS && numberOfResults),
      ]}
      onClick={updateUrl}
      aria-label="Show results."
      tabIndex={-1}
    >
      <Icon type={IconType.SEARCH} />
    </button>
  );

  const renderIconWithAppLink = () => (
    <AppLink
      redirectTriggerFlag={shouldGoToResults}
      app="shop"
      to={`/?${SEARCH_TYPE}=${encodeURIComponent(searchInput)}`}
      css={[
        searchInputButtonStyle,
        searchNavigateStyle(searchInput.length >= MIN_CHARS && numberOfResults),
      ]}
      aria-label="Go to results."
    >
      <Icon type={IconType.SEARCH} />
    </AppLink>
  );

  return (
    <Fragment>
      <div css={[searchBorderStyle, resultsShown && searchInputResultsShownStyle]}>
        <button
          css={[searchInputButtonStyle, searchResetStyle(searchInput), 'margin-right: 4px']}
          onClick={resetInput}
          aria-label="Clear search input."
          tabIndex={-1}
        >
          <Icon type={IconType.CLEAR} />
        </button>
        {isCurrentlyInProductList ? renderIconWithButton() : renderIconWithAppLink()}
      </div>
      <input
        css={[searchInputStyle, resultsShown && searchInputResultsShownStyle]}
        type="text"
        value={searchInput}
        ref={inputRef}
        tabIndex="0"
        placeholder={search_header_and_placeholder}
        aria-describedby="searchProductsFeedback"
        onKeyDown={onKeyDownHandler}
        onChange={(e) => inputOnChangeHandler(e.target.value)}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
      />
    </Fragment>
  );
};

SearchInput.propTypes = {
  searchInput: PropTypes.string,
  inputOnChangeHandler: PropTypes.func.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  closeSearch: PropTypes.func.isRequired,
  numberOfResults: PropTypes.number,
  resultsShown: PropTypes.bool.isRequired,
};

export default SearchInput;
