import { css } from '@emotion/react';
import React from 'react';

type TableImageWrapperInCellProps = {
  height?: number;
  children: React.ReactNode;
};

const tableImageWrapperInCellStyle = (height: number) =>
  css({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    '> img, svg': {
      height,
      width: 'auto',
    },
  });

export const TableImageWrapperInCell: React.FC<TableImageWrapperInCellProps> = ({
  children,
  height = 30,
}) => {
  return <div css={tableImageWrapperInCellStyle(height)}>{children}</div>;
};
