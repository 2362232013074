import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  ReplenishmentProductBox,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { AvailabilityStatus, ToolTipDirection } from '@lego/b2b-unicorn-shared/ui';
import { OpenOrderIndicator, ProductImage } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, colors, font } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';

import { isProductDisabled } from '../../utils/isProductDisabled';
import arrowKeypressNavigation from './common/arrowKeypressNavigation';

const productWrapStyle = (isDisabled: boolean) =>
  css({
    cursor: !isDisabled ? 'pointer' : 'default',
    fontSize: font.size.small,
    textAlign: 'left',
    display: 'flex',
    color: isDisabled ? colors.klik.slate500 : colors.klik.lightBlue400,
    padding: baseSpacing,
    marginTop: 0,
    marginRight: baseSpacing,
    marginBottom: 0,
    marginLeft: baseSpacing,
    border: 0,
    background: 'none',
    width: `calc(100% - ${baseSpacing * 2}px)`,
    borderBottomWidth: 1,
    borderBottomColor: colors.klik.slate100,
    borderBottomStyle: 'solid',
    minHeight: baseSpacing * 8,
    textDecoration: 'none',
    outline: 'none',
    scrollSnapAlign: 'start',
    scrollMarginTop: 62,
    position: 'relative',
    '&:first-of-type': {
      paddingTop: baseSpacing * 2,
    },
    '&:last-of-type': {
      borderBottomColor: 'transparent',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      backgroundColor: isDisabled ? colors.klik.slate500 : colors.klik.lightBlue400,
      top: 0,
      bottom: -1,
      left: -baseSpacing,
      right: -baseSpacing,
      opacity: 0,
    },

    '&:hover, &:focus': {
      '&:before': {
        opacity: 0.15,
      },
    },
  });

const productImgWrapperStyle = css({
  width: '20%',
  minWidth: 40,
  maxWidth: 60,
  display: 'flex',
  justifyContent: 'center',
  paddingRight: baseSpacing,
  overflow: 'hidden',
  svg: {
    width: '100%',
    height: 'auto',
  },
  img: {
    maxHeight: 40,
  },
});

const productDataStyle = css({
  width: '80%',
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: baseSpacing,
});

const productTextStyle = css({
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

const productNameStyle = css({
  width: '100%',
  marginTop: baseSpacing / 2,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const availabilityStatusStyle = css({
  marginLeft: baseSpacing / 2,
  lineHeight: 1.3,
});

interface IQuickAddSearchResultsItem {
  selectThisItem: () => void;
  numberOfResults: number;
  index: number;
  product: ReplenishmentProductsProduct;
}

const QuickAddSearchResultsItem = ({
  selectThisItem,
  numberOfResults,
  index,
  product,
}: IQuickAddSearchResultsItem) => {
  const {
    available,
    low_stock,
    expected_back_in_stock,
    out_of_stock,
    not_available,
    open_order_dialog_header,
    delivery_date_expected_next,
    total_pending,
    pcs,
    pcs_by,
  } = useLabels();
  const selectedCustomer = useSelectedCustomer();
  const isDisabled = isProductDisabled(product);
  const materialId = product.materialId;

  const imageUrl = getImageUrl(materialId, ImageType.MAIN_BOX, 40);

  return (
    <button
      aria-disabled={isDisabled}
      css={productWrapStyle(isDisabled)}
      onClick={() => {
        !isDisabled && selectThisItem();
      }}
      tabIndex={numberOfResults === 1 ? -1 : 0}
      onKeyDown={arrowKeypressNavigation}
      data-arrow-keypress-navigable-index={index + 1}
    >
      <div css={productImgWrapperStyle}>
        <ProductImage
          src={imageUrl}
          alt={product.name || ''}
        />
      </div>
      <div css={productDataStyle}>
        <div css={productTextStyle}>
          <span>{product instanceof ReplenishmentProductBox && product.itemNumber}</span>

          <span css={availabilityStatusStyle}>
            {product.stockStatus && (
              <AvailabilityStatus
                status={product.stockStatus?.status}
                expectedBackDate={product.stockStatus?.expectedBackDate}
                locale={selectedCustomer.locale}
                translations={{
                  available,
                  low_stock,
                  expected_back_in_stock,
                  out_of_stock,
                  not_available,
                }}
              />
            )}
          </span>

          {product.openOrderInfo?.totalPendingQuantity && (
            <OpenOrderIndicator
              direction={ToolTipDirection.Down}
              locale={selectedCustomer.locale}
              shippingDate={product.openOrderInfo.shippingDate}
              shippingQuantity={product.openOrderInfo.shippingQuantity}
              totalPendingQuantity={product.openOrderInfo.totalPendingQuantity}
              translations={{
                open_order_dialog_header,
                delivery_date_expected_next,
                total_pending,
                pcs,
                pcs_by,
              }}
            />
          )}
        </div>
        <div
          css={productNameStyle}
          dangerouslySetInnerHTML={{ __html: product.name || '' }}
        />
      </div>
    </button>
  );
};

export default QuickAddSearchResultsItem;
