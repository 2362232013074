import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import type { CartReferenceInput, ValidItem } from '@lego/b2b-unicorn-data-access-layer';
import {
  useIsReplenishmentCartEmpty,
  useOrdersProducts,
  useReplenishmentCartSummary,
  useReplenishmentUpdateCartWithMultipleItems,
} from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useProductsValidation } from '@lego/b2b-unicorn-shared/hooks';
import {
  AnimatedDots,
  Button,
  ButtonType,
  designToken,
  Disclaimer,
  Heading,
  Icon,
  IconType,
  Modal,
  ModalActions,
  ModalHeader,
  ModalTableBody,
  Spacer,
  Spinner,
} from '@lego/b2b-unicorn-shared/ui';
import { sleepFor } from '@lego/b2b-unicorn-shared/utils';
import { NotificationType, useNotifications } from '@lego/b2b-unicorn-ui-components';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TableRowRemovedItem } from '../../../../components/TableRowRemovedItem';
import { SuccessfulBulkCartUpdateSummaryItemRow } from '../../../launch/components/LaunchWindowsOverview';

type CopyOrderItemsToCartItem = {
  itemId: number;
  pieces: number;
  name: string;
};

type CopyOrderItemsToCartProps = {
  items: CopyOrderItemsToCartItem[];
  cartReference: CartReferenceInput;
  children: React.ReactNode;
};

type CopyOrderItemsToCartContext = {
  triggerCopyOrderItemsToCart: () => void;
  isLoading: boolean;
};
const CopyOrderItemsToCartContext = createContext<CopyOrderItemsToCartContext | undefined>(
  undefined
);
export const useCopyOrderItemsToCartContext = () => {
  const context = useContext(CopyOrderItemsToCartContext);
  if (!context) {
    throw Error(`No CopyOrderItemsToCartContext found`);
  }

  return context;
};

export const CopyOrderItemsToCart: React.FC<CopyOrderItemsToCartProps> = ({
  items,
  cartReference,
  children,
}) => {
  const {
    popup_invalid_items_header,
    popup_invalid_items_excel_content,
    button_cancel,
    button_continue,
    button_close,
    button_go_to_cart,
    copy_items_to_cart_success_header,
    copy_items_to_cart_confirm_disclaimer,
    copy_items_to_cart_confirm_header,
    copy_items_to_cart_empty,
    snackbar_excel_upload_add_error,
    item_id,
    name,
    theme,
    quantity,
    your_price,
  } = useLabels();
  const history = useHistory();
  const { id: customerId } = useSelectedCustomer();
  const { addSnackbar, snackbar } = useNotifications();
  const { refetch: refetchCart } = useReplenishmentCartSummary(customerId);
  const isCartEmpty = useIsReplenishmentCartEmpty(customerId);
  const [validateItems, validationResults, loading] = useProductsValidation(
    customerId,
    cartReference
  );
  const handleOnUpdateCartError = useCallback(() => {
    refetchCart();
    addSnackbar({
      type: NotificationType.WARNING,
      content: snackbar_excel_upload_add_error,
      showDismissButton: true,
      isStacked: !!snackbar,
    });
  }, [addSnackbar, customerId, refetchCart, snackbar, snackbar_excel_upload_add_error]);

  const { mutate: addItemsToCart } = useReplenishmentUpdateCartWithMultipleItems(
    customerId,
    true,
    handleOnUpdateCartError
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalStep, setModalStep] = useState<'validation' | 'confirm' | 'summary'>('validation');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { data: productsQueryResult, loading: productsLoading } = useOrdersProducts(customerId);

  const isLoading = loading || productsLoading;

  const copyOrderItemsToCart = useCallback(
    (items: ValidItem[]) => {
      addItemsToCart({
        items,
      });
      setModalStep('summary');
      setIsModalOpen(true);
    },
    [addItemsToCart]
  );

  const triggerCopyOrderItemsToCart = useCallback(() => {
    const itemsToValidate = items.map((item) => {
      const product = productsQueryResult?.products.products.find(
        (product) => product.materialId === item.itemId
      );
      const piecesPerCasePack = product?.piecesPerCasePack || 1;
      const quantity = item.pieces ? Math.ceil(item.pieces / piecesPerCasePack) : 0;

      return {
        itemId: item.itemId,
        quantity,
      };
    });

    const loaderId = GlobalLoadingService.add('validateOrderItems');
    validateItems(itemsToValidate)
      .then((result) => {
        if (!result.data) {
          return;
        }

        if (result.data.validateProducts.invalidItems.length > 0) {
          setModalStep('validation');
          setIsModalOpen(true);
        } else {
          setIsModalOpen(true);
          setModalStep('confirm');
        }
      })
      .finally(() => {
        GlobalLoadingService.remove(loaderId);
      });
  }, [items, productsQueryResult, validateItems]);

  const invalidItems = useMemo(() => {
    if (!validationResults) {
      return null;
    }

    return validationResults.invalidItems.map((invalidItem) => {
      const itemWithName = items.find((item) => item.itemId === invalidItem.itemId);
      if (!itemWithName) {
        return invalidItem;
      }

      return {
        ...invalidItem,
        name: itemWithName.name,
      };
    });
  }, [items, validationResults]);

  const handleOnValidationCancelClick = useCallback(() => {
    setIsModalOpen(false);
    setModalStep('validation');
  }, []);
  const handleOnValidationContinueClick = useCallback(() => {
    if (!validationResults) {
      return;
    }

    setModalStep('confirm');
  }, [validationResults]);

  const handleOnConfirmContinueClick = useCallback(async () => {
    if (!validationResults) {
      return;
    }

    setConfirmLoading(true);

    await sleepFor(500);

    copyOrderItemsToCart(validationResults.validItems);
    setConfirmLoading(false);
  }, [copyOrderItemsToCart, validationResults]);

  const handleOnSummaryGoToCartClick = useCallback(() => {
    history.push('/checkout');
  }, [history]);

  return (
    <>
      <CopyOrderItemsToCartContext.Provider value={{ triggerCopyOrderItemsToCart, isLoading }}>
        {children}
      </CopyOrderItemsToCartContext.Provider>
      <Modal isOpen={isModalOpen}>
        {modalStep === 'validation' && validationResults && invalidItems && (
          <>
            <ModalHeader
              subHeader={
                <span>
                  {validationResults.invalidItems.length} {popup_invalid_items_excel_content}
                </span>
              }
            >
              <Icon
                type={IconType.ALERT_OUTLINE}
                color={designToken.error.default}
              />
              {popup_invalid_items_header}
            </ModalHeader>
            <ModalTableBody headers={[item_id, name, quantity, '']}>
              {invalidItems.map((item) => {
                return (
                  <TableRowRemovedItem
                    key={item.materialId || item.itemNumber}
                    materialId={item.materialId}
                    itemNumber={item.itemNumber}
                    reason={item.reason}
                    name={item.name}
                    quantity={item.quantity}
                  />
                );
              })}
            </ModalTableBody>
            <ModalActions>
              <Button
                size={'small'}
                type={ButtonType.SECONDARY}
                onClick={handleOnValidationCancelClick}
              >
                {button_cancel}
              </Button>
              <Button
                size={'small'}
                onClick={handleOnValidationContinueClick}
              >
                {button_continue}
              </Button>
            </ModalActions>
          </>
        )}
        {modalStep === 'confirm' && validationResults && (
          <>
            <ModalHeader>
              <Icon
                type={IconType.QUESTION_OUTLINE}
                color={designToken.information.emphasis}
              />
              {copy_items_to_cart_confirm_header}
            </ModalHeader>
            {confirmLoading && <Spinner expandToParentContainer />}
            <ModalTableBody headers={[item_id, name, theme, quantity, your_price]}>
              {validationResults.validItems.length === 0 && (
                <tr>
                  <td
                    colSpan={5}
                    align={'center'}
                  >
                    <Spacer />
                    <Heading level={3}>{copy_items_to_cart_empty}</Heading>
                  </td>
                </tr>
              )}
              {!confirmLoading &&
                validationResults.validItems.map((item) => (
                  <SuccessfulBulkCartUpdateSummaryItemRow
                    key={item.product.materialId}
                    product={item.product}
                    quantity={item.quantity}
                    showRow={true}
                  />
                ))}
            </ModalTableBody>
            <ModalActions>
              {isCartEmpty ? null : (
                <>
                  <Disclaimer text={copy_items_to_cart_confirm_disclaimer} />{' '}
                  <Spacer direction={'vertical'} />
                </>
              )}
              <Button
                size={'small'}
                type={ButtonType.SECONDARY}
                onClick={() => setIsModalOpen(false)}
              >
                {button_cancel}
              </Button>
              <Button
                size={'small'}
                onClick={handleOnConfirmContinueClick}
                disabled={confirmLoading}
              >
                {confirmLoading && <AnimatedDots />}
                {!confirmLoading && button_continue}
              </Button>
            </ModalActions>
          </>
        )}
        {modalStep === 'summary' && validationResults && (
          <>
            <ModalHeader>
              <Icon
                type={IconType.CHECK_OUTLINE}
                color={designToken.success.default}
              />
              {copy_items_to_cart_success_header}
            </ModalHeader>
            <ModalTableBody headers={[item_id, name, theme, quantity, your_price]}>
              {validationResults.validItems.length === 0 && (
                <tr>
                  <td
                    colSpan={5}
                    align={'center'}
                  >
                    <Spacer />
                    <Heading level={3}>{copy_items_to_cart_empty}</Heading>
                  </td>
                </tr>
              )}
              {validationResults.validItems.map((item) => (
                <SuccessfulBulkCartUpdateSummaryItemRow
                  key={item.product.materialId}
                  product={item.product}
                  quantity={item.quantity}
                  showRow={true}
                />
              ))}
            </ModalTableBody>
            <ModalActions>
              <Button
                size={'small'}
                type={ButtonType.SECONDARY}
                onClick={() => setIsModalOpen(false)}
              >
                {button_close}
              </Button>
              <Button
                size={'small'}
                onClick={handleOnSummaryGoToCartClick}
              >
                {button_go_to_cart}
              </Button>
            </ModalActions>
          </>
        )}
      </Modal>
    </>
  );
};
