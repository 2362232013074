import { gql } from '@apollo/client';

export const VALIDATE_ITEMS = gql`
  query validateItems(
    $customerId: Int!
    $items: [ProductValidationInput!]!
    $cartReference: CartReferenceInput!
  ) {
    validateProducts(customerId: $customerId, items: $items, cartReference: $cartReference) {
      validItems {
        product {
          materialId
          ... on Box {
            itemNumber
          }
          name
          theme
          piecesPerCasePack
          price {
            currency
            grossPrice
            estimatedNetInvoicedPrice
          }
          stockStatus {
            status
            expectedBackDate
          }
          openOrderInfo {
            shippingDate
            shippingQuantity
            totalPendingQuantity
          }
        }
        quantity
      }
      invalidItems {
        itemId
        materialId
        itemNumber
        name
        quantity
        reason
      }
    }
  }
`;
