import { gql } from '@apollo/client';

export const INFO_ITEMS = gql`
  query infoItems($customerId: Int!) {
    getInfoItems(customerId: $customerId) {
      title
      description
      image
      files {
        filename
        size
        title
        updated
        url
        assetUid
      }
      links {
        title
        url
        assetUid
      }
    }
  }
`;
