import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { font, maxDesktopWidth } from '@lego/b2b-unicorn-ui-constants';

import { NotificationType } from '../NotificationType';
import NotificationIcon from './NotificationIcon';

const iconContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const contentStyle = css({
  width: '100%',
  maxWidth: maxDesktopWidth,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: designToken.text.default,
  fontSize: font.size.small,
  padding: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  '> p': {
    all: 'unset',
    display: 'block',
    alignSelf: 'center',
    lineHeight: 1.3,
  },
});

interface IInlineNotification {
  message: string;
  type?: NotificationType;
  outlineIcon?: boolean;
}

const InlineNotification = ({
  message,
  type = NotificationType.DEFAULT,
  outlineIcon = false,
}: IInlineNotification) => {
  return message && type ? (
    <div css={contentStyle}>
      <div css={iconContainerStyle}>
        <NotificationIcon
          type={type}
          small
          outline={outlineIcon}
        />
      </div>
      <p>{message}</p>
    </div>
  ) : null;
};

export default InlineNotification;
