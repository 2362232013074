import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { LaunchCartProduct } from '@lego/b2b-unicorn-data-access-layer';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  TableCell,
  TableCellName,
  TableCellNumber,
  TableRow,
  Typography,
} from '@lego/b2b-unicorn-shared/ui';
import React, { useMemo } from 'react';

import { ProductName } from '../../../../../components/ProductName/ProductName';

interface LaunchBlockedItemRowProps {
  product: LaunchCartProduct;
}

export const LaunchBlockedItemRow: React.FC<LaunchBlockedItemRowProps> = ({ product }) => {
  const { theme, recommended_retail_price, price_per_piece, launch_date } = useLabels();
  const selectedCustomer = useSelectedCustomer();
  const launchDate = useMemo(() => {
    if (product.__typename !== 'Box' && product.__typename !== 'BillOfMaterial') {
      return undefined;
    }

    return product.launchDate;
  }, [product]);

  const itemNumber = product.__typename === 'Box' ? product.itemNumber : undefined;
  const recommendedRetailPrice =
    product.__typename === 'Box' ? product.recommendedRetailPrice : undefined;

  return (
    <TableRow>
      <TableCellNumber align={'left'}>
        <Typography weight={'bold'}>{itemNumber}</Typography>
      </TableCellNumber>
      <TableCellName>
        <ProductName name={product.name} />
      </TableCellName>
      <TableCell colName={theme}>{product.theme}</TableCell>
      <TableCellNumber colName={recommended_retail_price}>
        <Price locale={selectedCustomer.locale}>{recommendedRetailPrice}</Price>
      </TableCellNumber>
      <TableCellNumber colName={price_per_piece}>
        <Price locale={selectedCustomer.locale}>{product.price}</Price>
      </TableCellNumber>
      <TableCellNumber colName={launch_date}>
        <Date locale={selectedCustomer.locale}>{launchDate}</Date>
      </TableCellNumber>
    </TableRow>
  );
};
