/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { afterFrame } from '@elastic/apm-rum-core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import type { match } from 'react-router-dom';

import { ApmService } from './';
import { isReactClassComponent } from './isReactClassComponent';

type ApmTransactionProps = {
  children: React.ReactNode;
  match: match | null;
};

type ApmTransactionContext = {
  startTransaction?: () => void;
  endTransaction?: () => void;
};
const ApmTransactionContext = React.createContext<ApmTransactionContext>({});

export const useApmTransaction = () => {
  const { endTransaction, startTransaction } = useContext(ApmTransactionContext);
  return { endTransaction, startTransaction };
};

export const ApmPageTransaction: React.FC<ApmTransactionProps> = ({ match, children }) => {
  const [apm] = useState(() => {
    return ApmService.instance;
  });

  if (!apm || isReactClassComponent(children) || !match) {
    return <>{children}</>;
  }

  apm.startTransaction(match.path, 'route-change');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const timeoutRef = useRef<number>();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    afterFrame(() => {
      if (timeoutRef.current && timeoutRef.current > 0) {
        window.clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        const transaction = apm.getTransaction(match.path);
        // @ts-ignore
        transaction && transaction.detectFinish();
      }, 1500);
    });
    return () => {
      /**
       * In case the transaction is never ended, we check if the transaction
       * can be closed during unmount phase
       *
       * We call detectFinish instead of forcefully ending the transaction
       * since it could be a redirect route, and we might prematurely close
       * the currently running transaction
       */
      if (timeoutRef.current && timeoutRef.current > 0) {
        window.clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        const transaction = apm.getTransaction(match.path);
        // @ts-ignore
        transaction && transaction.detectFinish();
      }, 1500);
    };
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const endTransaction = useCallback(() => {
    apm.endTransaction(match.path);
  }, [apm, match.path]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const startTransaction = useCallback(() => {
    apm.startTransaction(match.path, 'route-change');
  }, [apm, match.path]);

  return (
    <ApmTransactionContext.Provider value={{ endTransaction, startTransaction }}>
      {children}
    </ApmTransactionContext.Provider>
  );
};
