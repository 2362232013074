import {
  OrderFulfillmentStatus,
  OrdersFilters,
  OrderStatus,
  UpcomingDeliveriesFilters,
} from '@lego/b2b-unicorn-data-access-layer';
import { stringifyValue } from '@lego/b2b-unicorn-shared/helpers';
import { designToken } from '@lego/b2b-unicorn-shared/ui';

import { IStatusLabelColorSetup } from '../../../components/StatusLabel/StatusLabel';
import { dateRangeFromHumanReadable, FiltersInURL } from '../../../utils/DateRanges';

const orderFulfillmentStatusColorValues = new Map<OrderFulfillmentStatus, IStatusLabelColorSetup>([
  [
    OrderFulfillmentStatus.StatusOpen,
    {
      backgroundColor: designToken.information.muted,
      textColor: designToken.information.dark,
    },
  ],
  [
    OrderFulfillmentStatus.StatusPartiallyConfirmed,
    {
      backgroundColor: designToken.warning.muted,
      textColor: designToken.warning.dark,
    },
  ],
  [
    OrderFulfillmentStatus.StatusFullyConfirmed,
    {
      backgroundColor: designToken.success.muted,
      textColor: designToken.success.dark,
    },
  ],
  [
    OrderFulfillmentStatus.StatusRejected,
    {
      backgroundColor: designToken.error.muted,
      textColor: designToken.error.dark,
    },
  ],
]);

export const orderStatusLabelColors: IStatusLabelColorSetup = {
  backgroundColor: designToken.information.muted,
  textColor: designToken.information.dark,
};

export const orderStatusLabelColorsForHeader: IStatusLabelColorSetup = {
  backgroundColor: 'white',
  textColor: designToken.text.default,
};

export const orderItemRejectedStatusLabelColors: IStatusLabelColorSetup = {
  backgroundColor: designToken.error.muted,
  textColor: designToken.error.dark,
};

export const mapOrderFulfillmentStatusToColor = (status: OrderFulfillmentStatus) => {
  return (
    orderFulfillmentStatusColorValues.get(status) || {
      backgroundColor: 'white',
      textColor: designToken.text.default,
    }
  );
};

/** STATUS_PROCESSING exist in the backend, but does not make sense from a user perspective, so it is mapped to STATUS_OPEN */
export const mapBackendOrderStatusToFrontendLabel = (status: OrderStatus) => {
  if (status === OrderStatus.StatusProcessing) {
    return OrderStatus.StatusOpen;
  }
  return status;
};

/**
 * Mapping our filtering model to the GraphQL filter model.
 * */
export const parseOrderFilterParams = (
  filter?: FiltersInURL<OrdersFilters | UpcomingDeliveriesFilters>
): OrdersFilters | UpcomingDeliveriesFilters => {
  const { toDate, fromDate } = dateRangeFromHumanReadable(filter?.dateRange);

  const result: OrdersFilters | UpcomingDeliveriesFilters = {
    toDate,
    fromDate,
  };

  if (!filter) {
    return result;
  }

  Object.assign(result, { ...filter });

  const maybeOrdersFilters = filter as OrdersFilters;
  if (maybeOrdersFilters.orderStatuses) {
    /**
     * To end users, STATUS_PROCESSING makes little sense and is shown as
     * STATUS_OPEN. So when STATUS_OPEN is selected, we must remember to
     * submit both values to the backend.
     */
    const statuses = [...maybeOrdersFilters.orderStatuses];
    if (maybeOrdersFilters.orderStatuses.find((status) => status === OrderStatus.StatusOpen)) {
      statuses.push(OrderStatus.StatusProcessing);
    }

    Object.assign(result, {
      orderStatuses: statuses,
    });
  }
  if (maybeOrdersFilters.query) {
    Object.assign(result, {
      query: stringifyValue(maybeOrdersFilters.query),
    });
  }

  const maybeUpcomingDeliveriesFilters = filter as UpcomingDeliveriesFilters;
  if (maybeUpcomingDeliveriesFilters.itemQuery) {
    Object.assign(result, {
      itemQuery: stringifyValue(maybeUpcomingDeliveriesFilters.itemQuery),
    });
  }
  if (maybeUpcomingDeliveriesFilters.orderQuery) {
    Object.assign(result, {
      orderQuery: stringifyValue(maybeUpcomingDeliveriesFilters.orderQuery),
    });
  }

  // Typing not correct
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (result as any).dateRange;

  return result;
};
