import { Icon, IconType, Tag, TagType } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

export const FoundInCartTag = () => {
  return (
    <Tag type={TagType.BLUE_BOLD}>
      <Icon type={IconType.SHOPPING_CART} />
    </Tag>
  );
};
