import React from 'react';

import { tableRowCellStyle } from './styles';

type TableCellProps = {
  children?: React.ReactNode;
  colName?: string;
  width?: number | string;
  align?: React.CSSProperties['textAlign'];
  noPadding?: boolean;
};

export const TableCell: React.FC<TableCellProps> = ({
  children,
  colName,
  width,
  align,
  noPadding,
}) => {
  return (
    <td
      data-col={colName}
      css={tableRowCellStyle(width, align, noPadding)}
    >
      {children}
    </td>
  );
};
