import { css } from '@emotion/react';
import React, { useCallback, useContext } from 'react';

import { KEYCODE_STRING } from '../../constants';
import { PopoverOpenContext, PopoverRefContext } from './Popover';

const popoverWrapperStyle = css({
  display: 'inline-flex',
  position: 'relative',
  outline: 'none',
});

type PopoverContainerProps = {
  children: React.ReactNode;
};

export const PopoverContainer: React.FC<PopoverContainerProps> = ({ children }) => {
  const { listContainerRef, triggerRef } = useContext(PopoverRefContext);
  const { setPopoverOpen, popoverOpen, focusedElementBeforeOpen, setFocusedElementBeforeOpen } =
    useContext(PopoverOpenContext);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      const itemHasFocus = listContainerRef.current?.contains(document.activeElement);

      switch (e.code) {
        case KEYCODE_STRING.ESCAPE: {
          e.preventDefault();
          if (popoverOpen) {
            setPopoverOpen(false);
            triggerRef.current?.focus();
          }
          break;
        }
        case KEYCODE_STRING.ENTER:
        case KEYCODE_STRING.ARROW_DOWN: {
          e.preventDefault();
          if (!popoverOpen) {
            setPopoverOpen(true);
            (listContainerRef.current?.children[0] as HTMLDivElement).focus();
          } else {
            itemHasFocus && (document.activeElement?.nextElementSibling as HTMLDivElement).focus();
          }
          break;
        }
        case KEYCODE_STRING.ARROW_UP: {
          e.preventDefault();
          itemHasFocus &&
            (document.activeElement?.previousElementSibling as HTMLDivElement).focus();
          break;
        }
      }
    },
    [listContainerRef, popoverOpen, setPopoverOpen, triggerRef]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent) => {
      if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget as HTMLElement)) {
        setPopoverOpen(false);
        if (focusedElementBeforeOpen instanceof HTMLElement) {
          const { top, bottom } = focusedElementBeforeOpen.getBoundingClientRect();
          if (top >= 0 && bottom <= window.innerHeight) {
            focusedElementBeforeOpen.focus();
          }
        }
        setFocusedElementBeforeOpen(undefined);
      }
    },
    [focusedElementBeforeOpen, setFocusedElementBeforeOpen, setPopoverOpen]
  );

  return (
    <div
      css={popoverWrapperStyle}
      onKeyDown={handleOnKeyDown}
      onBlur={handleOnBlur}
      tabIndex={-1}
    >
      {children}
    </div>
  );
};
