import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { notificationIconStyle } from '../notificationStyle';
import { NotificationType } from '../NotificationType';

interface NotificationIconProps {
  type: NotificationType;
  small?: boolean;
  outline?: boolean;
}
const NotificationIcon: React.FC<NotificationIconProps> = ({
  type,
  small = false,
  outline = false,
}) => {
  return (
    <>
      {type === NotificationType.DEFAULT && (
        <span css={notificationIconStyle(type, small)}>
          <Icon type={outline ? IconType.INFO_OUTLINE : IconType.INFO} />
        </span>
      )}
      {type === NotificationType.POSITIVE && (
        <span css={notificationIconStyle(type, small)}>
          <Icon type={outline ? IconType.CHECK_OUTLINE : IconType.CHECK} />
        </span>
      )}
      {type === NotificationType.WARNING && (
        <span css={notificationIconStyle(type, small)}>
          <Icon type={outline ? IconType.ALERT_OUTLINE : IconType.ALERT} />
        </span>
      )}
      {type === NotificationType.ERROR && (
        <span css={notificationIconStyle(type, small)}>
          <Icon type={outline ? IconType.ALERT_OUTLINE : IconType.ALERT} />
        </span>
      )}
    </>
  );
};

export default NotificationIcon;
