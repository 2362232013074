import { gql } from '@apollo/client';

export const CREATE_CLAIM_FILE_RESOURCE = gql`
  mutation createClaimFileResource($customerId: Int!, $input: CreateClaimFileResourceInput!) {
    createClaimFileResource(customerId: $customerId, input: $input) {
      uri
      uuid
    }
  }
`;
