import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import type { Order } from '@lego/b2b-unicorn-data-access-layer';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import React, { Fragment } from 'react';

import InfoWithAdditionalLines from '../../../components/InfoWithAdditionalLines/InfoWithAdditionalLines';
import StatusLabel from '../../../components/StatusLabel/StatusLabel';
import StatusSummary from '../../../components/StatusSummary/StatusSummary';
import StatusSummaryItem from '../../../components/StatusSummary/StatusSummaryItem';
import { orderStatusLabelColorsForHeader } from '../OrderList/utils';

type OrderStatusSummaryProps = {
  order?: Order;
  locale: string;
};

const OrderStatusSummary: React.FC<OrderStatusSummaryProps> = ({ order, locale }) => {
  const labelsContext = useLabels();
  const { getLabelByKey } = labelsContext;

  return (
    <StatusSummary>
      {order && (
        <Fragment>
          <StatusSummaryItem label={labelsContext.order_type}>
            {order.type && getLabelByKey(order.type.toLowerCase())}
          </StatusSummaryItem>
          <StatusSummaryItem label={labelsContext.order_date}>
            <Date locale={locale}>{order.orderDate}</Date>
          </StatusSummaryItem>
          <StatusSummaryItem label={labelsContext.delivery_date_requested}>
            <Date locale={locale}>{order.requestedDeliveryDate}</Date>
          </StatusSummaryItem>
          <StatusSummaryItem label={labelsContext.shipment_info}>
            {order.shipmentInfo?.length ? (
              <InfoWithAdditionalLines
                headline={labelsContext.shipment_info}
                lines={order.shipmentInfo}
              />
            ) : (
              '-'
            )}
          </StatusSummaryItem>
          <StatusSummaryItem label={labelsContext.shipping_address}>
            {order.shipTo.id}-{order.shipTo.city}
          </StatusSummaryItem>
          <StatusSummaryItem label={labelsContext.order_total}>
            <Price locale={locale}>{order.orderTotal}</Price>
          </StatusSummaryItem>
          <StatusSummaryItem label={labelsContext.status}>
            <StatusLabel
              label={getLabelByKey(order.orderStatus.toLowerCase())}
              colors={orderStatusLabelColorsForHeader}
            />
          </StatusSummaryItem>
        </Fragment>
      )}
    </StatusSummary>
  );
};

export default OrderStatusSummary;
