import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Button, ButtonType, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

type ResetActionButtonProps = {
  disabled: boolean;
  onClick: () => void;
  resetActionType: 'REMOVE_ALL' | 'RESET';
};
export const ResetActionButton: React.FC<ResetActionButtonProps> = ({
  disabled,
  onClick,
  resetActionType,
}) => {
  const { button_remove_all, button_reset } = useLabels();
  return (
    <Button
      type={ButtonType.GHOST}
      size="small"
      disabled={disabled}
      onClick={onClick}
    >
      <>
        <Icon type={resetActionType === 'REMOVE_ALL' ? IconType.DELETE : IconType.RESET} />
        <span>{resetActionType === 'REMOVE_ALL' ? button_remove_all : button_reset}</span>
      </>
    </Button>
  );
};
