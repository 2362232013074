import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';

declare global {
  interface Window {
    firstLoaderId: string;
  }
}

const globalLoading = GlobalLoadingService;
const firstLoaderId = globalLoading.add();
window.firstLoaderId = firstLoaderId;
