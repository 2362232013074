import { UpdateChecker } from '@lego/b2b-unicorn-update-checker';

const currentVersion = process.env['APP_VERSION'] || '';
const updateChecker = UpdateChecker.getInstance(currentVersion);
updateChecker.start().subscribe({
  error: (err) => {
    // eslint-disable-next-line no-console
    console.error(`Failed to check for updates: ${err.message}`, err);
  },
});
