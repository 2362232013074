import { css } from '@emotion/react';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { COOKIE_DOMAIN } from '@lego/b2b-unicorn-bootstrap/constants';
import { CartReferenceCartType } from '@lego/b2b-unicorn-data-access-layer';
import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';
import { BodyWrapper, Button, ButtonType, Card, Container } from '@lego/b2b-unicorn-shared/ui';
import {
  CheckoutPage,
  CheckoutStep,
  IOrderConfirmationInfo,
  OrderConfirmation,
} from '@lego/b2b-unicorn-ui-checkout-flow';
import { Banner } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { PromotionDetailsHeader } from './components/PromotionDetails/components';
import { PromotionRouterDetailsParams } from './PromotionRouter';

const backToShopStyle = css({
  textDecoration: 'none',
  display: 'contents',
});

export const PromotionCheckout: React.FC = () => {
  const { promotionId: rawPromotionId } = useParams<PromotionRouterDetailsParams>();
  const { endTransaction } = useApmTransaction();
  const promotionId = parseInt(rawPromotionId);
  const history = useHistory();
  const [orderConfirmationInfo, setOrderConfirmationInfo] = useState<IOrderConfirmationInfo>({});
  const [checkoutStep, setCheckoutStep] = useState(CheckoutStep.CHECKOUT);
  const { button_back_to_promotions } = useLabels();

  const cartReference = useMemo(
    () => ({
      cartType: CartReferenceCartType.Promotion,
      promotion: {
        promotionId,
      },
    }),
    [promotionId]
  );

  const handleOnCheckoutLoaded = useCallback(() => {
    endTransaction && endTransaction();
  }, [endTransaction]);

  const handleGoBackClick = () => {
    history.replace(`/promotion/${promotionId}`);
  };

  const handleOrderCreationSuccess = (orderNumber: number, orderType: CartReferenceCartType) => {
    setOrderConfirmationInfo({ orderNumber, orderType });
    setCheckoutStep(CheckoutStep.CONFIRMATION);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Topbar>
        <Logo />
      </Topbar>
      <Banner />
      {checkoutStep === CheckoutStep.CHECKOUT && (
        <BodyWrapper>
          <PromotionDetailsHeader promotionId={promotionId} />
          <Container padding={{ paddingBottom: baseSpacing * 15 }}>
            <Card>
              <CheckoutPage
                cartReference={cartReference}
                goBackHandler={handleGoBackClick}
                cookieDomain={COOKIE_DOMAIN}
                orderCreationSuccessHandler={handleOrderCreationSuccess}
                onEmptyCart={handleGoBackClick}
                onCheckoutLoaded={handleOnCheckoutLoaded}
              />
            </Card>
          </Container>
        </BodyWrapper>
      )}
      {checkoutStep === CheckoutStep.CONFIRMATION && (
        <OrderConfirmation
          orderNumber={orderConfirmationInfo.orderNumber}
          orderType={orderConfirmationInfo.orderType}
        >
          <div css={backToShopStyle}>
            <AppLink
              app="shop"
              to="/promotion"
              css={backToShopStyle}
            >
              <Button type={ButtonType.PRIMARY_INVERTED}>{button_back_to_promotions}</Button>
            </AppLink>
          </div>
        </OrderConfirmation>
      )}
    </>
  );
};
