import { tokens } from '@lego/core-colors';

export const colors = {
  white: tokens.color.core.white,
  // deprecated, use designToken or tokens instead
  klik: {
    lightBlue10: '#F9FBFC',
    lightBlue30: '#F5F8FA',
    lightBlue50: '#E8F2FA',
    lightBlue100: '#CAE5FA',
    lightBlue200: '#A6D3F7',
    lightBlue300: '#79BCF2',
    lightBlue400: '#4599DE',
    lightBlue500: '#247BBF',
    lightBlue600: '#1766A6',
    lightBlue700: '#0F538A',
    lightBlue800: '#0C416B',
    lightBlue900: '#0A2C47',
    slate10: '#FCFCFC',
    slate30: '#F8F8F8',
    slate50: '#F1F1F1',
    slate100: '#E3E3E1',
    slate200: '#CFCFCC',
    slate300: '#B2B2B0',
    slate400: '#969694',
    slate500: '#767674',
    slate600: '#61615F',
    slate700: '#4D4D4B',
    slate800: '#3B3B39',
    slate900: '#292928',
  },
};
