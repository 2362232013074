import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { InfoItemListsItem } from '@lego/b2b-unicorn-data-access-layer';
import { useInfo } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import {
  BodyWrapper,
  ContentSystemFeedback,
  designToken,
  SystemFeedbackType,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, maxDesktopWidth, media, mediaSizes } from '@lego/b2b-unicorn-ui-constants';
import React, { useEffect } from 'react';

import SkeletonInfoList from '../../../components/SkeletonLoaders/SkeletonInfoList';
import { INFO_TILE_WIDTH } from '../../../constants';
import InfoListFile from './InfoListFile';
import InfoListLink from './InfoListLink';
import InfoListTile from './InfoListTile';

const tilesStyle = css({
  maxWidth: INFO_TILE_WIDTH + baseSpacing * 4,
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
  paddingBottom: baseSpacing * 4,
  paddingLeft: baseSpacing * 2,
  color: designToken.text.default,
  [media.small]: {
    paddingTop: baseSpacing * 4,
    paddingBottom: 120,
    maxWidth: maxDesktopWidth + baseSpacing * 4,
  },
  [`@media (min-width: ${mediaSizes.large + baseSpacing * 4}px)`]: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: maxDesktopWidth,
  },
});

const formatFileSize = (fileSizeInBytes: number) => {
  const sizeInMb = fileSizeInBytes / 1_000_000;
  return sizeInMb > 0.1 ? sizeInMb.toFixed(1) : '0.1';
};

type InfoListProps = {
  onInfoLoaded?: () => void;
};

const InfoList: React.FC<InfoListProps> = ({ onInfoLoaded }) => {
  const { id: customerId } = useSelectedCustomer();
  const { content_system_feedback_error, content_system_feedback_no_data } = useLabels();

  const { data: infoContent, error: infoContentError } = useInfo(customerId);
  useEffect(() => {
    if (infoContent || infoContentError) {
      onInfoLoaded && onInfoLoaded();
    }
  }, [infoContent, infoContentError, onInfoLoaded]);
  useOnPreferredLanguageChange(['infoItems']);

  const renderInfoListTile = (info: InfoItemListsItem) => {
    return (
      <InfoListTile
        title={info.title}
        description={info.description}
        imageUrl={info.image}
        customerId={customerId}
        key={info.title}
      >
        {info.links.length > 0 &&
          info.links.map((link) => {
            return (
              <li key={link.title}>
                <InfoListLink
                  title={link.title}
                  url={link.url}
                  assetUid={link.assetUid}
                />
              </li>
            );
          })}
        {info.files.length > 0 &&
          info.files.map((asset) => {
            return (
              <li key={asset.title}>
                <InfoListFile
                  title={asset.title}
                  updated={asset.updated}
                  size={formatFileSize(asset.size)}
                  fileType={asset.filename.split('.').pop() || ''}
                  url={asset.url}
                  assetUid={asset.assetUid}
                  customerId={customerId}
                />
              </li>
            );
          })}
      </InfoListTile>
    );
  };

  return (
    <BodyWrapper>
      <div css={tilesStyle}>
        {!infoContentError && !infoContent && <SkeletonInfoList />}
        {infoContent &&
          (infoContent.getInfoItems.length ? (
            infoContent.getInfoItems.map((info) => renderInfoListTile(info))
          ) : (
            <ContentSystemFeedback text={content_system_feedback_no_data} />
          ))}
        {infoContentError && (
          <ContentSystemFeedback
            type={SystemFeedbackType.ERROR}
            text={content_system_feedback_error}
          />
        )}
      </div>
    </BodyWrapper>
  );
};

export default InfoList;
