import { gql } from '@apollo/client';

export const REPLENISHMENT_PRODUCT = gql`
  query replenishmentProduct($customerId: Int!, $materialId: Int!) {
    product(customerId: $customerId, materialId: $materialId) {
      materialId
      ean
      dimensions {
        height
        length
        unit
        width
      }
      weight {
        amount
        unit
      }
      available
      name
      openOrderInfo {
        shippingDate
        shippingQuantity
        totalPendingQuantity
      }
      piecesPerCasePack
      price {
        currency
        estimatedNetInvoicedPrice
        grossPrice
      }
      stockStatus {
        status
        expectedBackDate
      }
      lifecycles
      tags
      theme
      ... on Box {
        itemNumber
        launchDate
        exitDate
        ageGroup
        description
        pieces
        recommendedRetailPrice {
          amount
          currency
        }
      }
      ... on Supplementary {
        recommendedRetailPrice {
          amount
          currency
        }
      }
    }
  }
`;
