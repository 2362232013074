import React from 'react';

import { Button, ButtonType } from '../../ui/Button';
import { Modal, ModalActions, ModalBody, ModalHeader } from '../../ui/Modal';
import { useClaimItemFormContext } from './ClaimItemFormContext';

type ClaimItemFormProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};
export const ClaimItemForm: React.FC<ClaimItemFormProps> = ({ open, onClose, children }) => {
  const {
    translations: { header, buttons },
    invoiceItem: { itemNumber, name },
    formik,
    resetForm,
    claimItem,
  } = useClaimItemFormContext();

  const handleCloseClick = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal isOpen={open}>
      <ModalHeader subHeader={`${itemNumber} - ${name}`}>{header}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalActions>
        <Button
          onClick={handleCloseClick}
          type={ButtonType.SECONDARY}
          size={'small'}
        >
          {buttons.close}
        </Button>
        <Button
          onClick={() => formik.handleSubmit()}
          size={'small'}
          // claimed pieces exceeding invoiced pieces value is in formik.status
          disabled={
            !formik.values.claimType || !formik.isValid || formik.isSubmitting || !!formik.status
          }
        >
          {claimItem ? buttons.updateClaim : buttons.addClaim}
        </Button>
      </ModalActions>
    </Modal>
  );
};
