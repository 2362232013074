export enum ToolTipDirection {
  Up = 'up',
  Down = 'down',
  Left = 'left',
  Right = 'right',
  UpLeft = 'up_left',
  UpRight = 'up_right',
  DownLeft = 'down_left',
  DownRight = 'down_right',
}
