import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';

import EmptyBox from '../../../common/EmptyBox';
import { productBckgStyle } from '../../ProductDetailsPage';
import {
  IMAGE_CONTAINER_HEIGHT,
  THUMBNAIL_CONTAINER_HEIGHT,
  THUMBNAIL_CONTAINER_WIDTH,
} from '../ProductCarousel';
import { SkeletonContainerHeader, SkeletonProductDetailsTile } from './';

const productPageStyle = css({
  maxWidth: maxDesktopWidth,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'grid',
  paddingTop: 75,
  justifyItems: 'center',

  [media.medium]: {
    gridTemplateColumns: 'auto 344px',
    gridTemplateRows: '118px auto',
    gridColumnGap: baseSpacing * 2,
    paddingTop: 92,
  },

  [media.large]: {
    gridTemplateColumns: '1064px 344px',
    gridTemplateRows: '118px auto',
    gridColumnGap: baseSpacing * 2,
  },
});

const carouselStyle = css({
  maxWidth: 1064,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [media.medium]: {
    flexDirection: 'row',
    backgroundColor: designToken.transparent.default,
    alignItems: 'flex-start',
  },
});

const featuresStyle = css({
  width: '100%',
});

const detailsStyle = css({
  paddingTop: 24,
  width: '100%',
  marginBottom: baseSpacing * 4,
  [media.medium]: {
    paddingTop: 0,
    borderTop: 'none',
  },
});

const imageContainerStyle = css({
  backgroundColor: colors.white,
  borderRadius: 8,
  height: 375,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: baseSpacing * 2,
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
  paddingBottom: baseSpacing / 2,
  marginBottom: 20,
  '> svg': {
    height: 'auto',
    width: '100%',
    zIndex: 2,
  },
  [media.medium]: {
    height: IMAGE_CONTAINER_HEIGHT,
    borderRadius: '6px 0 0 6px',
  },
});

const thumbnailsStyle = css({
  marginBottom: baseSpacing * 2,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [media.medium]: {
    flexDirection: 'column',
    marginBottom: 0,
  },
});

const thumbStyle = css({
  width: baseSpacing * 7,
  height: baseSpacing * 7,
  borderRadius: 6,
  padding: 2,
  backgroundColor: colors.white,
  marginRight: baseSpacing,
  marginLeft: baseSpacing,
  '&:focus': {
    outline: 'none',
  },
  [media.medium]: {
    borderRadius: 0,
    width: THUMBNAIL_CONTAINER_WIDTH,
    height: THUMBNAIL_CONTAINER_HEIGHT,
    borderLeftColor: designToken.background.gray,
    borderLeftStyle: 'solid',
    borderLeftWidth: 3,
    margin: 0,
  },
});

export const SkeletonProductDetailsPage = () => (
  <div css={productBckgStyle}>
    <div css={productPageStyle}>
      <div css={featuresStyle}>
        <div css={carouselStyle}>
          <div css={imageContainerStyle}>
            <EmptyBox />
          </div>
          <div css={thumbnailsStyle}>
            {[...Array(6)].map((_, i) => {
              return (
                <div
                  css={thumbStyle}
                  key={i}
                />
              );
            })}
          </div>
        </div>
        <SkeletonContainerHeader open={false} />
      </div>
      <div css={detailsStyle}>
        <SkeletonContainerHeader
          open={true}
          display={false}
        />
        <SkeletonProductDetailsTile />
      </div>
    </div>
  </div>
);
