import { gql } from '@apollo/client';

export const TOPBAR = gql`
    query topbar($customerId: Int!, $cartReference: CartReferenceInput!) {
        getCart(customerId: $customerId, cartReference: $cartReference) {
            ...cartKey
            items {
                ...minimumCartItem
            }
        }
        promotions(customerId: $customerId) {
            availableForOrderPromotions {
                id
            }
            noLongerAvailableForOrderPromotions {
                id
            }
        }
    }
`;
