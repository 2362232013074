import { ApmService } from '@lego/b2b-unicorn-apm';
import { Customer } from '@lego/b2b-unicorn-data-access-layer';
import { defaultsDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { USERSNAP_SPACE_KEY } from '../constants';
import {
  GlobalLoadingService,
  mapLocaleForUsersnap,
  UserService,
  UsersnapService,
} from '../services';

type BootstrapUsersnapProps = {
  children: React.ReactNode;
};

const usersnapOptsDefaults: NonNullable<
  Parameters<typeof UsersnapService.getInstance>[0]
>['globalUsersnapOpts'] = {
  collectGeoLocation: 'none',
  useLocalStorage: false,

  custom: {
    environment: process.env.APP_ENVIRONMENT || 'localhost',
  },
};

export const BootstrapUsersnap: React.FC<BootstrapUsersnapProps> = ({ children }) => {
  const globalLoaderId = useRef<string>();
  const [userService] = useState(() => {
    return UserService.getInstance();
  });
  const [selectedCustomer, setSelectedCustomer] = useState(userService.selectedCustomer);
  const usersnapLoaded = useRef(false);

  const handleUsersnapLoaded = () => {
    usersnapLoaded.current = true;
    removeGlobalLoader();
  };

  const removeGlobalLoader = () => {
    if (globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
    }
  };

  const [usersnapService] = useState(() => {
    return UsersnapService.getInstance({
      globalApiKey: USERSNAP_SPACE_KEY,
      onLoaded: handleUsersnapLoaded,
      onLoadError: removeGlobalLoader,
      onError: (error: Error) => {
        // eslint-disable-next-line no-console
        console.error('Usersnap error:', error);
        ApmService.instance?.captureError(error);
      },

      globalUsersnapOpts: defaultsDeep(usersnapOptsDefaults, {
        locale: mapLocaleForUsersnap(selectedCustomer?.locale),
        user: {
          email: userService.user?.email,
          userId: selectedCustomer?.id,
        },
      }),
    });
  });

  const handleUsersServiceCustomerSelected = useCallback(
    (customer: Customer) => {
      if (!usersnapLoaded.current) {
        return;
      }

      usersnapService?.updateGlobalUsersnapOptions({
        locale: mapLocaleForUsersnap(customer.locale),
        user: {
          email: userService.user?.email,
          userId: customer.id,
        },
      });
      setSelectedCustomer(customer);
    },
    [userService.user?.email, usersnapService]
  );

  useEffect(() => {
    const customerSelectedHandlerId = userService.addEventListener(
      'customer-selected',
      handleUsersServiceCustomerSelected
    );

    return () => {
      userService.removeListener('customer-selected', customerSelectedHandlerId);
    };
  }, [handleUsersServiceCustomerSelected, userService]);

  useEffect(() => {
    if (!globalLoaderId.current && USERSNAP_SPACE_KEY) {
      globalLoaderId.current = GlobalLoadingService.add('usersnap');
    }

    return () => {
      if (globalLoaderId.current) {
        GlobalLoadingService.remove(globalLoaderId.current);
      }
    };
  }, []);

  return <>{children}</>;
};
