import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Lifecycle } from '@lego/b2b-unicorn-data-access-layer';
import { Tag, TagType } from '@lego/b2b-unicorn-shared/ui';

export const LifecycleTag = ({ type }: { type: Lifecycle }) => {
  const { coming_soon, new: new_tag, retiring_soon, retired } = useLabels();
  return {
    [Lifecycle.ComingSoon]: (
      <Tag
        type={TagType.BLUE_INVERTED}
        fixedWidth={false}
      >
        {coming_soon}
      </Tag>
    ),
    [Lifecycle.New]: (
      <Tag
        type={TagType.BLUE_INVERTED}
        fixedWidth={false}
      >
        {new_tag}
      </Tag>
    ),
    [Lifecycle.RetiringSoon]: (
      <Tag
        type={TagType.ORANGE_INVERTED}
        fixedWidth={false}
      >
        {retiring_soon}
      </Tag>
    ),
    [Lifecycle.Retired]: (
      <Tag
        type={TagType.ORANGE_INVERTED}
        fixedWidth={false}
      >
        {retired}
      </Tag>
    ),
    // No tag for carry-over products as that is a non-exceptional status.
    [Lifecycle.CarryOver]: <></>,
  }[type];
};
