import { css } from '@emotion/react';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';

interface IStatusLabel {
  label: string;
  /** Hexcode for label color */
  colors: IStatusLabelColorSetup;
}

export interface IStatusLabelColorSetup {
  backgroundColor: string;
  textColor: string;
}

const createStyle = (colors: IStatusLabelColorSetup) =>
  css({
    backgroundColor: colors.backgroundColor,
    color: colors.textColor,
    display: 'inline-block',
    fontWeight: 'normal',
    paddingLeft: baseSpacing,
    paddingRight: baseSpacing,
    lineHeight: '24px',
    borderRadius: 12,
    fontSize: '14px',
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  });

const StatusLabel = (props: IStatusLabel) => {
  return (
    <span
      css={createStyle(props.colors)}
      className="unicorn-status-label"
    >
      {props.label}
    </span>
  );
};

export default StatusLabel;
