import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';

export const containerStyle = css({
  paddingTop: baseSpacing * 3,
  height: 92,
  position: 'relative',
  color: designToken.text.default,
  cursor: 'pointer',
  svg: {
    width: 20,
    height: 20,
    position: 'absolute',
    right: baseSpacing * 2,
    top: baseSpacing * 1.5,
    zIndex: 21,
  },
  [media.medium]: {
    paddingTop: baseSpacing * 2,
    height: 54,
  },
});

export const optionsContainerDesktopStyle = css({
  [media.medium]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    svg: {
      display: 'none',
    },
  },
});

export const initialVisibilityStyle = (isSelected: boolean) =>
  css({
    // On mobile, the tabs are a dropdown, so they are hidden unless dropdown is open
    display: 'none',
    [media.medium]: {
      // On desktop they are tabs, so always visible
      display: 'block',
      flexBasis: 0,
      flexGrow: 1,
      paddingLeft: baseSpacing,
      paddingRight: baseSpacing,
      '&:hover, :focus': {
        backgroundColor: 'transparent',
      },
      '&:first-of-type': {
        paddingLeft: 0,
      },
      '&:last-of-type': {
        paddingRight: 0,
      },
      'a, span': {
        backgroundColor: isSelected
          ? designToken.background.offWhite
          : designToken.interactive.default,
        color: isSelected ? 'inherit' : colors.white,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderColor: colors.white,
        borderStyle: 'solid',
        borderWidth: 1,
        textAlign: 'center',
        fontSize: '18px',
        lineHeight: '31px',
        outline: 'none',
        '&:focus, :hover': {
          backgroundColor: isSelected ? colors.klik.slate10 : designToken.interactive.active,
          color: isSelected ? designToken.interactive.active : colors.white,
        },
      },
    },
  });

export const isVisibleStyle = css({
  display: 'block',
});
