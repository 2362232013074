import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media, mediaSizes } from '@lego/b2b-unicorn-ui-constants';

export const tableContainerStyle = css({
  paddingBottom: baseSpacing * 4,
  position: 'relative',
  '> h2': {
    padding: '.5em 0',
    fontSize: font.size.medium,
    fontWeight: font.weight.bold,
    lineHeight: 2,
  },
  table: {
    tr: {
      height: 'auto',
      // Without this property, rows with alert icons would be slightly higher than other rows
      // (Using height since min-height has no effect on <tr>)
      [media.medium]: {
        height: 55,
      },
    },
    'td:first-of-type': {
      height: 55,
      [media.medium]: {
        height: 'auto',
      },
    },
    'td:nth-of-type(-n+3)': {
      paddingTop: 12,
      [media.medium]: {
        paddingTop: 8,
      },
    },
  },
  tbody: {
    td: {
      svg: {
        verticalAlign: 'middle',
      },
      a: {
        color: designToken.interactive.default,
        '&: hover': {
          color: designToken.interactive.hover,
        },
        textDecoration: 'none',
        svg: {
          width: 24,
          height: 24,
        },
      },
      [media.medium]: {
        '&:nth-of-type(2)': {
          maxWidth: 300,
        },
      },
    },
  },
});

export const topRowOfOrderItemStyle = css({
  '&:before': {
    display: 'none',
  },
  display: 'flex',
  width: 'auto',
  alignItems: 'center',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: colors.klik.slate100,
  paddingBottom: baseSpacing,
  paddingTop: baseSpacing * 1.5,
  marginRight: 1,
});

export const numericContentStyle = css({
  fontFeatureSettings: 'tnum',
  fontVariantNumeric: 'tabular-nums',
  textAlign: 'right',
  lineHeight: 'inherit',
});

export const orderListTableResponsivenessStyle = css({
  [`@media not all and (min-width: ${mediaSizes.medium}px)`]: {
    table: {
      thead: {
        display: 'none',
      },
      tbody: {
        tr: {
          td: {
            '&:nth-of-type(1)': [
              topRowOfOrderItemStyle,
              {
                order: 1,
                flexGrow: 1,
              },
            ],
            '&:nth-of-type(2)': {
              order: 5,
              width: '100%',
              paddingTop: baseSpacing,
            },
            '&:nth-of-type(3)': {
              order: 6,
              width: '100%',
            },
            '&:nth-of-type(4)': {
              order: 7,
              width: '100%',
            },
            '&:nth-of-type(5)': {
              order: 8,
              width: '100%',
            },
            '&:nth-of-type(6)': {
              order: 9,
              width: '100%',
              paddingBottom: baseSpacing,
            },
            '&:nth-of-type(7)': [
              topRowOfOrderItemStyle,
              {
                order: 2,
              },
            ],
            '&:nth-of-type(8)': [
              topRowOfOrderItemStyle,
              {
                order: 3,
              },
            ],
            '&:nth-of-type(9)': [
              topRowOfOrderItemStyle,
              {
                order: 4,
              },
            ],
            '.orderlist-amount-container': {
              display: 'inline-block',
              textAlign: 'left',
              paddingRight: 0,
            },
          },
        },
      },
    },
  },
});
