import { css } from '@emotion/react';
import React, { Fragment } from 'react';

import { Illustration } from '../Illustration/Illustration';
import { IllustrationType } from '../Illustration/IllustrationType';
import { baseSpacing, designToken, font, media } from '../theme';
import { SystemFeedbackType } from './FeedbackType';

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'center',
  width: 'auto',
  marginTop: '15vh',
  marginBottom: 200,
  marginLeft: baseSpacing * 2,
  marginRight: baseSpacing * 2,
  '> p': {
    flex: 1,
  },
  '> p span': {
    fontWeight: font.weight.bold,
    fontSize: font.size.medium,
    color: designToken.text.default,
    display: 'block',
    textAlign: 'center',
  },
  [media.small]: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 200,
    marginBottom: 200,
    '> p span': {
      textAlign: 'left',
    },
  },
  [media.medium]: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    '> p span:first-of-type': {
      fontSize: font.size.large,
    },
  },
});

const imageBackgroundStyle = (type: SystemFeedbackType) =>
  css({
    position: 'relative',
    width: 260,
    height: 260,
    marginRight: baseSpacing * 2,
    flex: '0 0 260px',
    backgroundImage: `
      radial-gradient(
        circle closest-side,
        ${
          {
            [SystemFeedbackType.ERROR]: designToken.error.subdued,
            [SystemFeedbackType.MESSAGE]: designToken.information.subdued,
            [SystemFeedbackType.NOT_FOUND]: designToken.warning.subdued,
          }[type]
        } calc(100% - 1px),
        transparent 100%
      )`,
  });

const imageStyle = (type: SystemFeedbackType) =>
  css({
    position: 'absolute',
    left: {
      [SystemFeedbackType.ERROR]: baseSpacing * 10,
      [SystemFeedbackType.MESSAGE]: -baseSpacing * 4,
      [SystemFeedbackType.NOT_FOUND]: baseSpacing * 2,
    }[type],
    top: {
      [SystemFeedbackType.ERROR]: baseSpacing * 5,
      [SystemFeedbackType.MESSAGE]: -baseSpacing * 3,
      [SystemFeedbackType.NOT_FOUND]: -baseSpacing * 8,
    }[type],
    svg: {
      height: {
        [SystemFeedbackType.ERROR]: 220,
        [SystemFeedbackType.MESSAGE]: 300,
        [SystemFeedbackType.NOT_FOUND]: 340,
      }[type],
      width: 'auto',
      opacity: 0.4,
      '> *': {
        fill: {
          [SystemFeedbackType.ERROR]: designToken.error.dark,
          [SystemFeedbackType.MESSAGE]: designToken.information.dark,
          [SystemFeedbackType.NOT_FOUND]: designToken.warning.dark,
        }[type],
      },
    },
  });

interface ContentSystemFeedbackProps {
  type?: SystemFeedbackType;
  text: string;
  children?: React.ReactNode;
}

export const ContentSystemFeedback = ({
  type = SystemFeedbackType.MESSAGE,
  text,
  children,
}: ContentSystemFeedbackProps) => {
  const illustration = {
    [SystemFeedbackType.ERROR]: IllustrationType.DETECTIVE,
    [SystemFeedbackType.MESSAGE]: IllustrationType.ASTRONAUT,
    [SystemFeedbackType.NOT_FOUND]: IllustrationType.DIVER,
  }[type];

  return (
    <Fragment>
      <div css={containerStyle}>
        <div css={imageBackgroundStyle(type)}>
          <div css={imageStyle(type)}>
            <Illustration type={illustration} />
          </div>
        </div>
        <p>
          <span>{text}</span>
          {children}
        </p>
      </div>
    </Fragment>
  );
};
