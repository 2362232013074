import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ReplenishmentProductsProduct } from '@lego/b2b-unicorn-data-access-layer';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';

import { MIN_SEARCH_CHARS, RESULTS_LIMIT } from './common/quickAddConstants';
import QuickAddSearchResultsItem from './QuickAddSearchResultsItem';

const dropdownStyle = css({
  position: 'absolute',
  top: 0,
  paddingTop: baseSpacing * 5,
  borderRadius: 4,
  left: 0,
  right: 0,
  zIndex: 100,
  backgroundColor: colors.white,
  boxShadow: '0 0 10px 2px rgba(0,0,0,0.2)',
  '&:empty': {
    display: 'none',
  },
  [media.medium]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 934,
    paddingTop: baseSpacing * 7,
  },
});

const feedbackStyle = css({
  fontSize: font.size.small,
  color: designToken.text.default,
  padding: baseSpacing * 2,
  paddingTop: baseSpacing * 2.5,
  '&:empty': {
    display: 'none',
  },
});

interface IQuickAddSearchResults {
  selectedProduct?: ReplenishmentProductsProduct;
  query: string;
  isSearchError: boolean;
  isSearchLoading: boolean;
  isSearchDebounced: boolean;
  results: ReplenishmentProductsProduct[];
  selectProduct: (product: ReplenishmentProductsProduct) => void;
}

const QuickAddSearchResults = ({
  selectedProduct,
  query,
  isSearchError,
  isSearchLoading,
  isSearchDebounced,
  results,
  selectProduct,
}: IQuickAddSearchResults) => {
  const { search_min_chars, search_error, search_loading, quick_order_search_no_results } =
    useLabels();

  let searchFeedbackContent;

  if (query && query.length < MIN_SEARCH_CHARS) {
    searchFeedbackContent = search_min_chars;
  } else if (isSearchError) {
    searchFeedbackContent = search_error;
  } else if (isSearchLoading || isSearchDebounced) {
    searchFeedbackContent = search_loading;
  } else if (results?.length === 0) {
    searchFeedbackContent = quick_order_search_no_results;
  }
  const shouldShowFeedback = query && searchFeedbackContent && !selectedProduct;

  const shouldShowResults = !shouldShowFeedback && !selectedProduct && query && results?.length > 0;

  return query ? (
    <div css={dropdownStyle}>
      {shouldShowFeedback && (
        <div
          css={feedbackStyle}
          id="quickAddSearchFeedback"
        >
          {searchFeedbackContent}
        </div>
      )}
      {shouldShowResults &&
        results.slice(0, RESULTS_LIMIT).map((product, index) => (
          <QuickAddSearchResultsItem
            selectThisItem={() => selectProduct(product)}
            key={product.materialId}
            index={index}
            product={product}
            numberOfResults={results.length}
          />
        ))}
    </div>
  ) : null;
};

export default QuickAddSearchResults;
