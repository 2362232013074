import { useSelectedCustomer, useUser } from '@lego/b2b-unicorn-shared/components/UserContext';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import { FeatureToggleProvider } from '@lego/b2b-unicorn-ui-featuretogglecontext';
import { LogLevel } from '@optimizely/js-sdk-logging';
import { createInstance } from '@optimizely/react-sdk';
import React, { useEffect, useRef } from 'react';

import { APP_ENVIRONMENT, ASSETS_URL, OPTIMIZELY_KEY } from '../constants';
import { GlobalLoadingService } from '../services';

const loggerForOptimizely = logger.createLogger('optimizely');
const loggerWrapperForOptimizely = (level: LogLevel, message: string) => {
  switch (level) {
    case LogLevel.INFO:
      loggerForOptimizely.info(message);
      break;
    case LogLevel.DEBUG:
      // eslint-disable-next-line testing-library/no-debugging-utils
      loggerForOptimizely.debug(message);
      break;
    case LogLevel.WARNING:
      loggerForOptimizely.warning(message);
      break;
    case LogLevel.ERROR:
      loggerForOptimizely.error(message);
      break;
  }
};

const optimizelyClient = createInstance({
  sdkKey: OPTIMIZELY_KEY,
  logLevel: APP_ENVIRONMENT === 'local' ? LogLevel.INFO : LogLevel.WARNING,
  logger: {
    log: loggerWrapperForOptimizely,
  },
  errorHandler: {
    handleError: (e) => loggerForOptimizely.error(e),
  },
  datafileOptions: {
    urlTemplate: `${ASSETS_URL}/optimizely/datafiles/${OPTIMIZELY_KEY}.json`,
  },
  eventBatchSize: 10,
  eventFlushInterval: 1000,
});

type BootstrapOptimizelyProps = {
  children: React.ReactNode;
};
const BootstrapOptimizely: React.FC<BootstrapOptimizelyProps> = ({ children }) => {
  const globalLoaderId = useRef<string>();

  const user = useUser();
  const selectedCustomer = useSelectedCustomer();

  const handleOnReady = () => {
    if (globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
    }
  };

  useEffect(() => {
    if (!globalLoaderId.current) {
      globalLoaderId.current = GlobalLoadingService.add('optimizely');
    }

    return () => {
      if (globalLoaderId.current) {
        GlobalLoadingService.remove(globalLoaderId.current);
      }
    };
  }, []);

  return (
    <FeatureToggleProvider
      optimizelyClient={optimizelyClient}
      customerId={selectedCustomer.id}
      salesOrg={selectedCustomer.salesOrg}
      parentC6Id={selectedCustomer.parentC6Id}
      email={user.email}
      onReadyHandler={handleOnReady}
    >
      {children}
    </FeatureToggleProvider>
  );
};

export default BootstrapOptimizely;
