import { css } from '@emotion/react';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import { designToken } from '@lego/b2b-unicorn-shared/ui';

export const formCommonInputStyle = css({
  border: 0,
  outline: 0,
  color: designToken.text.default,
  fontSize: font.size.small,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: baseSpacing * 3,
  lineHeight: `${baseSpacing * 2}px`,
  height: baseSpacing * 5,
  backgroundColor: colors.white,
  borderRadius: 4,
  width: '100%',
  [media.medium]: {
    width: '100%',
    height: baseSpacing * 7,
  },
});
