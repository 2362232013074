import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { NetworkStatus } from '@apollo/client/core/networkStatus';
import { deepEqual } from 'fast-equals';
import { GraphQLFormattedError } from 'graphql/index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { combineLatest, distinctUntilChanged, map, ReplaySubject } from 'rxjs';

import { CartReferenceInput, PaymentApolloError } from '../../';
import { CheckoutDataContext } from '../../context/Checkout/CheckoutDataContext';
import { CART } from '../../context/Checkout/queries/cart';
import { ExtraOptions } from '../../context/GenericContextTypes';
import {
  CartWithSimulationDetailsQuery,
  CartWithSimulationDetailsQueryVariables,
  CheckoutCartQuery,
  CheckoutCartQueryVariables,
  CreateBrickBankOrderMutation,
  CreateOrderMutation,
  EmptyCartMutation,
  EmptyCartMutationVariables,
} from '../../generated-types/graphql';
import { BrickBankPaymentInfoInput } from '../../generated-types/types';
import { useDataAccessLayer } from '../';
import { useDataAccessLayerMutation } from '../helpers/useDataAccessLayerMutation';
import { useDataAccessLayerQuery } from '../helpers/useDataAccessLayerQuery';

type GetCartParameters = Parameters<typeof CheckoutDataContext.prototype.getCart>;
export const useGetCart = (...parameters: GetCartParameters) => {
  const dataAccessLayer = useDataAccessLayer();
  const queryResult = useMemo(() => {
    return dataAccessLayer.checkout.getCart(parameters[0], parameters[1], parameters[2], {
      ...parameters[3],
    });
  }, [dataAccessLayer.checkout, parameters]);

  return useQuery(queryResult.query, {
    variables: queryResult.variables,
    notifyOnNetworkStatusChange: true,
    client: dataAccessLayer.apolloClient,
    ...parameters[3],
  });
};

export const useIsUpdatingCart = () => {
  const dataAccessLayer = useDataAccessLayer();
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);

  useEffect(() => {
    const subscription = dataAccessLayer.checkout.isUpdatingCart.subscribe((isUpdatingCart) => {
      setIsUpdatingCart(isUpdatingCart);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [dataAccessLayer.checkout.isUpdatingCart]);

  return isUpdatingCart;
};

export const useOptimisticUpdateCart = (
  customerId: number,
  cartReference: CartReferenceInput,
  onError?: (
    error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
  ) => void
) => {
  const dataAccessLayer = useDataAccessLayer();

  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.checkout.updateCart(customerId, cartReference, true, true, onError);
  }, [cartReference, customerId, dataAccessLayer.checkout, onError]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};

type EmptyCartParameters = Parameters<typeof CheckoutDataContext.prototype.emptyCartLazy>;
type EmptyMutationType = ReturnType<
  typeof useMutation<EmptyCartMutation, EmptyCartMutationVariables>
>;

export const useOptimisticEmptyCart = (...parameters: EmptyCartParameters) => {
  const dataAccessLayer = useDataAccessLayer();
  const lazyMutation = useMemo(() => {
    return dataAccessLayer.checkout.emptyCartLazy(...parameters);
  }, [dataAccessLayer.checkout, parameters]);

  const [actualMutateFn, results] = useMutation<EmptyCartMutation, EmptyCartMutationVariables>(
    lazyMutation.mutation,
    {
      client: dataAccessLayer.apolloClient,
      variables: {
        customerId: parameters[0],
        cartReference: parameters[1],
      },
    }
  );

  const mutate = useCallback<EmptyMutationType[0]>(
    (options) => {
      dataAccessLayer.checkout.cartPolicy?.emptyCartOptimistic({
        query: CART,
        variables: {
          customerId: parameters[0],
          cartReference: parameters[1],
          removeObsoleteItems: true,
        },
      });

      return actualMutateFn(options);
    },
    [actualMutateFn, dataAccessLayer.checkout.cartPolicy, parameters]
  );

  return [mutate, results] as EmptyMutationType;
};

export const useCartWithSimulationDetails = (
  customerId: number,
  cartReference: CartReferenceInput,
  extraOptions?: ExtraOptions
) => {
  const dataAccessLayer = useDataAccessLayer();
  const lazyQuery = useMemo(() => {
    return dataAccessLayer.checkout.cartWithSimulationDetailsLazy(
      customerId,
      cartReference,
      extraOptions
    );
  }, [cartReference, customerId, dataAccessLayer.checkout, extraOptions]);

  return useLazyQuery<CartWithSimulationDetailsQuery, CartWithSimulationDetailsQueryVariables>(
    lazyQuery.query,
    {
      client: dataAccessLayer.apolloClient,
      fetchPolicy: 'network-only',
      ...extraOptions,
    }
  );
};

export const useCheckoutCart = (
  customerId: number,
  cartReference: CartReferenceInput,
  removeObsoleteItems: boolean = false,
  extraOptions?: ExtraOptions
) => {
  const dataAccessLayer = useDataAccessLayer();
  const queryResult = useMemo(() => {
    return dataAccessLayer.checkout.checkoutCart(
      customerId,
      cartReference,
      removeObsoleteItems,
      extraOptions
    );
  }, [cartReference, customerId, dataAccessLayer.checkout, extraOptions, removeObsoleteItems]);

  return useQuery<CheckoutCartQuery, CheckoutCartQueryVariables>(queryResult.query, {
    client: dataAccessLayer.apolloClient,
    fetchPolicy: 'network-only',
    variables: {
      customerId,
      cartReference,
      removeObsoleteItems,
    },
    ...extraOptions,
  });
};

export const useBrickBankPaymentMethods = (customerId: number, extraOptions?: ExtraOptions) => {
  const dataAccessLayer = useDataAccessLayer();
  const queryResult = useMemo(() => {
    return dataAccessLayer.checkout.brickBankPaymentMethods(customerId);
  }, [customerId, dataAccessLayer.checkout]);

  return useQuery(queryResult.query, {
    client: dataAccessLayer.apolloClient,
    fetchPolicy: 'no-cache',
    variables: {
      customerId,
    },
    ...extraOptions,
  });
};

export const useRemoveBrickBankStoredCard = (customerId: number) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.checkout.removeBrickBankStoredCard(customerId);
  }, [dataAccessLayer.checkout, customerId]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};

type CreateOrderData = Omit<CreateOrderMutation['createOrder'], '__typename'>;
type CreateOrderResult = {
  loading: boolean;
  status: NetworkStatus;
  error: Error | ApolloError | PaymentApolloError | null;
  data: CreateOrderData | null | undefined;
};
export const useCreateOrder = (customerId: number) => {
  const dataAccessLayer = useDataAccessLayer();
  const [subjects] = useState(() => {
    const status = new ReplaySubject<NetworkStatus>(1);
    status.next(NetworkStatus.ready);
    const error = new ReplaySubject<Error | ApolloError | PaymentApolloError | null>(1);
    error.next(null);
    const mutation = new ReplaySubject<
      CreateOrderMutation | CreateBrickBankOrderMutation | null | undefined
    >(1);
    mutation.next(undefined);

    return {
      status,
      error,
      mutation,
    };
  });
  const [result, setResult] = useState<CreateOrderResult>({
    loading: false,
    status: NetworkStatus.ready,
    data: undefined,
    error: null,
  });
  const [observable] = useState(() => {
    return combineLatest([subjects.status, subjects.error, subjects.mutation]).pipe(
      map(([status, error, mutation]) => {
        const result: CreateOrderResult = {
          loading: status === NetworkStatus.loading,
          status,
          error,
          data: undefined,
        };

        if (mutation) {
          if ('createOrder' in mutation) {
            Object.assign(result, {
              data: mutation.createOrder,
            });
          } else if ('createBrickBankOrder' in mutation) {
            Object.assign(result, {
              data: mutation.createBrickBankOrder,
            });
          }

          if (status === NetworkStatus.loading) {
            subjects.status.next(NetworkStatus.ready);
          }
        }

        return result;
      }),
      distinctUntilChanged(deepEqual)
    );
  });

  useEffect(() => {
    const subscription = observable.subscribe((result) => {
      setResult(result);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [observable]);

  const mutate = useCallback(
    (
      shipToId: number,
      requestedDeliveryDate: Date,
      cartReference: CartReferenceInput,
      orderName: string,
      brickBankPaymentInfo?: BrickBankPaymentInfoInput
    ) => {
      subjects.status.next(NetworkStatus.loading);
      subjects.error.next(null);

      dataAccessLayer.checkout
        .createOrder(
          customerId,
          shipToId,
          requestedDeliveryDate,
          cartReference,
          orderName,
          brickBankPaymentInfo
        )
        .catch((error) => {
          subjects.status.next(NetworkStatus.error);
          subjects.error.next(error);
        })
        .then((result) => {
          if (result) {
            subjects.mutation.next(result.data);
            subjects.error.next(new ApolloError({ graphQLErrors: result.errors }));
          }
        });
    },
    [customerId, dataAccessLayer.checkout, subjects.error, subjects.mutation, subjects.status]
  );

  return [mutate, result] as const;
};

export const useCheckoutRecommendations = (customerId: number) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.checkout.recommendations(customerId);
  }, [customerId, dataAccessLayer.checkout]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};
