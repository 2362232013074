import { css } from '@emotion/react';
import { FooterSectionItem } from '@lego/b2b-unicorn-data-access-layer';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import { FunctionComponent, ReactNode } from 'react';

const sectionStyle = css({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'start',
  marginBottom: baseSpacing * 3,

  '> *': {
    lineHeight: 1.2,
  },
  '> h3': {
    fontSize: font.size.medium,
    paddingBottom: baseSpacing * 2,
  },
  '> *:first-child': {
    paddingBottom: 10,
  },
  [media.medium]: {
    '> h3': {
      paddingBottom: baseSpacing * 4,
    },
  },
});

interface FooterSectionProps {
  title: FooterSectionItem['title'];
  children: ReactNode;
}

const FooterSection: FunctionComponent<FooterSectionProps> = ({ title, children }) => {
  const label = `sectionheading-${title}`;
  return (
    <li
      css={sectionStyle}
      aria-labelledby={label}
    >
      <h3 id={label}>{title}</h3>
      {children}
    </li>
  );
};

export default FooterSection;
