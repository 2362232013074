import OrderList from '../../pages/manage/OrderList/OrderList';
import ManagePageLayout from './ManagePageLayout';

const OrderListLayout = () => {
  return (
    <ManagePageLayout>
      <OrderList />
    </ManagePageLayout>
  );
};

export default OrderListLayout;
