import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CheckoutRecommendationItem } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { NotificationType, useNotifications } from '@lego/b2b-unicorn-ui-components';
import { font } from '@lego/b2b-unicorn-ui-constants';
import React, { Fragment, useRef, useState } from 'react';

import { RecommendationProductTile } from './RecommendationProductTile';
import { useRecommendations } from './RecommendationsProvider';

type ReplenishmentCartRecommendationProductTileProps = {
  product: CheckoutRecommendationItem;
};

const snackbarProductNameStyle = css({
  fontWeight: font.weight.bold,
  display: '-webkit-box',
  WebkitLineClamp: '1',
  overflow: 'hidden',
});

export const ReplenishmentCartRecommendationProductTile: React.FC<
  ReplenishmentCartRecommendationProductTileProps
> = ({ product }) => {
  const [show, setShow] = useState(true);

  const { onAddToCart, onGotoProductDetails, updateCart, onUpdateError } = useRecommendations();
  const { locale } = useSelectedCustomer();

  const { snackbar_add_product_error } = useLabels();
  const { addSnackbar, snackbar } = useNotifications();
  const addToCartCalled = useRef(false);

  const addToCart = (quantity: number) => {
    addToCartCalled.current = true;
    setShow(false);
    const cartProduct = {
      ...product,
      __typename: 'Box' as const,
    };
    updateCart({
      productOrMaterialId: { ...cartProduct },
      quantity,
    }).catch(() => {
      const errorText = (
        <Fragment>
          <span css={snackbarProductNameStyle}>
            {product.materialId} — {product.name}
          </span>{' '}
          {snackbar_add_product_error}
        </Fragment>
      );

      onUpdateError();
      addSnackbar({
        type: NotificationType.WARNING,
        content: errorText,
        showDismissButton: true,
        isStacked: !!snackbar,
      });
    });
  };

  const onTransitionEnd = () => {
    if (addToCartCalled.current) {
      onAddToCart(product);
    }
  };

  return (
    <RecommendationProductTile
      product={product}
      locale={locale}
      onAddToCart={addToCart}
      onGotoProductDetails={onGotoProductDetails}
      cssStyle={{ transition: 'opacity 0.25s', opacity: show ? 1 : 0 }}
      onTransitionEnd={onTransitionEnd}
    />
  );
};
