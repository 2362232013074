import type { SerializedStyles } from '@emotion/react';
import React from 'react';

import { Icon, IconType } from '../../../ui/Icon';

type CounterMinusButtonProps = {
  onClick: (e: React.UIEvent) => void;
  disabled: boolean;
  cssStyles: SerializedStyles;
  ref: React.Ref<HTMLButtonElement>;
};

export const CounterMinusButton: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<CounterMinusButtonProps> & React.RefAttributes<HTMLButtonElement>
> = React.forwardRef<HTMLButtonElement, CounterMinusButtonProps>(
  ({ onClick, disabled, cssStyles }, ref) => {
    return (
      <button
        css={cssStyles}
        onClick={onClick}
        aria-label="Subtract quantity of product."
        disabled={disabled}
        tabIndex={-1}
        ref={ref}
      >
        <Icon type={IconType.MINUS} />
      </button>
    );
  }
);
CounterMinusButton.displayName = 'CounterMinusButton';
