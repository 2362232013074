// @TODO Refactoring needed, should be moved or kept more generic

import { ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';

interface ProductSortingItem {
  product: {
    theme: string;
    itemNumber?: number | null;
    materialId: number;
    price: {
      currency: string;
      estimatedNetInvoicedPrice: number;
    };
    piecesPerCasePack?: number;
  };
  quantity: number;
  expectedDeliveryDate?: string | null;
}

export function sortAscending<T extends ProductSortingItem>(
  items: Array<T>,
  column?: ProductTableColumnType
) {
  const sortedProductList = [...items];

  if (column === ProductTableColumnType.THEME) {
    return sortedProductList.sort((a, b) => {
      const valueA = a.product.theme.toUpperCase();
      const valueB = b.product.theme.toUpperCase();
      return valueA < valueB ? -1 : 1;
    });
  }
  if (column === ProductTableColumnType.ITEM_ID) {
    return sortedProductList.sort((a, b) => {
      const valueA = a.product.itemNumber || a.product.materialId;
      const valueB = b.product.itemNumber || b.product.materialId;
      return valueA - valueB;
    });
  }
  if (
    column === ProductTableColumnType.YOUR_PRICE &&
    !sortedProductList.some(
      (p) => p.quantity === undefined || p.product.piecesPerCasePack === undefined
    )
  ) {
    return sortedProductList.sort((a, b) => {
      return (
        a.product.price.estimatedNetInvoicedPrice * a.product.piecesPerCasePack! * a.quantity! -
        b.product.price.estimatedNetInvoicedPrice * b.product.piecesPerCasePack! * b.quantity!
      );
    });
  }
  if (column === ProductTableColumnType.EXPECTED_DELIVERY) {
    return sortedProductList.sort((a, b) => {
      const dateA = a.expectedDeliveryDate && new Date(a.expectedDeliveryDate);
      const dateB = b.expectedDeliveryDate && new Date(b.expectedDeliveryDate);
      if (dateA && dateB) {
        return dateA < dateB ? -1 : 1;
      }
      return 1;
    });
  }
  return items;
}

export function sortDescending<T extends ProductSortingItem>(
  items: T[],
  column?: ProductTableColumnType
) {
  return sortAscending(items, column).reverse();
}
