import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { LaunchCartReferenceInput } from '@lego/b2b-unicorn-data-access-layer';
import { CartItemWithLaunchProduct } from '@lego/b2b-unicorn-data-access-layer/context/LaunchWindows/LaunchWindowsDataContext';
import { useOptimisticUpdateLaunchWindowWithMultiple } from '@lego/b2b-unicorn-data-access-layer/react';
import { BulkQuickAdd } from '@lego/b2b-unicorn-shared/components/BulkQuickAdd/BulkQuickAdd';
import {
  BulkQuickAddContext,
  FinalRowItem,
  RowStatusReason,
} from '@lego/b2b-unicorn-shared/components/BulkQuickAdd/types';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { pasteEventListener } from '../../../shared/Utils/functions/pasteEventListener';
import { BaseBulkQuickAdd } from './BaseBulkQuickAdd';

const launchWindowConsumerPasteEventListener = (event: HTMLElementEventMap['paste']) =>
  pasteEventListener(event, BulkQuickAddContext.LAUNCH);

type LaunchWindowBulkQuickAddConsumerProps = {
  launchWindowProducts: React.ComponentProps<typeof BulkQuickAdd>['products'];
  open: boolean;
  closeModal: () => void;
  cartReference: LaunchCartReferenceInput;
  onUpdatingCart?: () => void;
  onError?: () => void;
  onHasPreviousSession: () => void;
};

export const LaunchWindowBulkQuickAddConsumer: React.FC<LaunchWindowBulkQuickAddConsumerProps> = ({
  launchWindowProducts,
  open,
  closeModal,
  cartReference,
  onError,
  onHasPreviousSession,
}) => {
  const { button_cancel, button_add_to_order, replenish_bulk_quick_add_modal_header } = useLabels();
  const { id: customerId } = useSelectedCustomer();
  const { trackEvent } = useAnalytics();
  const storageKey = `bulkQuickAdd-${customerId}-launch-${cartReference.launch.month}-${cartReference.launch.year}`;
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const submitErrorRef = useRef<boolean>(false);

  // Internal state
  const [items, setItems] = useState<FinalRowItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    mutate: addToCart,
    reset: addToCartReset,
    error: submittingError,
  } = useOptimisticUpdateLaunchWindowWithMultiple(
    customerId,
    cartReference.launch.year,
    cartReference.launch.month,
    true
  );

  useEffect(() => {
    if (launchWindowProducts.length > 0) {
      setIsLoading(false);
    }
  }, [launchWindowProducts]);

  useEffect(() => {
    if (open) {
      document.addEventListener('paste', launchWindowConsumerPasteEventListener);
    }

    return () => {
      document.removeEventListener('paste', launchWindowConsumerPasteEventListener);
    };
  }, [open]);

  const handleOnAddToCartClick = () => {
    setIsLoading(true);
    trackEvent({
      event: 'addToCartFromBulkQuickAddLaunchWindow',
      name: `Adding ${validItems.length} items to cart`,
      value: validItems.length,
    });
    submitErrorRef.current = false;
    addToCart({
      items: validItems,
    })
      .then(() => {
        sessionStorage.removeItem(storageKey);
        closeModal();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnCancelClick = useCallback(() => {
    sessionStorage.removeItem(storageKey);
    submitErrorRef.current = false;
    addToCartReset();
    closeModal();
  }, [addToCartReset, closeModal, storageKey]);

  const validItems = useMemo<CartItemWithLaunchProduct[]>(() => {
    return items.reduce<CartItemWithLaunchProduct[]>((result, item) => {
      if (!item.rowStatusReason || !item.product || item.quantity < 1) {
        return result;
      }

      result.push({
        product: item.product,
        quantity: item.quantity,
      });

      return result;
    }, []);
  }, [items]);

  useEffect(() => {
    if (submittingError && !submitErrorRef.current) {
      addButtonRef.current?.blur();
      submitErrorRef.current = true;
      addToCartReset();
      onError && onError();
    }
  }, [submittingError, onError, addToCartReset]);

  const isAddToCartDisabled =
    validItems.length === 0 ||
    items.some((item) => item.rowStatusReason !== RowStatusReason.Valid || item.quantity < 1);

  return (
    <BaseBulkQuickAdd
      context={BulkQuickAddContext.LAUNCH}
      sessionStorageKey={storageKey}
      onHasPreviousSession={onHasPreviousSession}
      open={open}
      products={launchWindowProducts}
      modalHeaderContent={replenish_bulk_quick_add_modal_header}
      isLoading={isLoading}
      submittingError={submitErrorRef.current}
      addToCartDisabled={isAddToCartDisabled}
      onItemsChange={setItems}
    >
      <Button
        type={ButtonType.SECONDARY}
        onClick={handleOnCancelClick}
      >
        {button_cancel}
      </Button>
      <Button
        ref={addButtonRef}
        onClick={handleOnAddToCartClick}
        disabled={isAddToCartDisabled}
        warning={submitErrorRef.current}
      >
        {button_add_to_order}
      </Button>
    </BaseBulkQuickAdd>
  );
};
