import { gql } from '@apollo/client';

export const FRAGMENT_REMOVED_CART_ITEM = gql`
  fragment removedCartItem on RemovedCartItem {
    materialId
    itemNumber
    name
    quantity
    reason
  }
`;
