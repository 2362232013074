import { css } from '@emotion/react';

import { baseSpacing, colors, designToken, font, maxDesktopWidth, media } from '../theme';
import kaktus from './assets/kaktus.svg';
import rocket from './assets/rocket-light.svg';

const genericEmptyPreviewStyle = css({
  position: 'relative',
  width: '100%',
  height: 560,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  paddingTop: baseSpacing * 3,
  color: designToken.text.default,
  backgroundColor: designToken.background.blue,
  '> p': {
    fontSize: font.size.medium,
    marginBlock: 0,
    marginInline: 0,
  },
  [media.small]: {
    height: 300,
    justifyContent: 'center',
    borderRadius: 8,
  },
  [media.medium]: {
    height: 380,
    maxWidth: maxDesktopWidth,
    padding: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export const kaktusEmptyPreviewStyle = css(genericEmptyPreviewStyle, {
  height: '100vh',
  backgroundImage: `url(${kaktus})`,
  backgroundPosition: `bottom 30% center`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 50%',

  [media.small]: {
    height: 'calc(100vh - 195px)',
    justifyContent: 'unset',
    paddingTop: 70,
  },

  [media.medium]: {
    height: 400,
    width: `calc(100% - ${baseSpacing} * 6px)`,
    margin: baseSpacing * 3,
    marginTop: 0,
    marginBottom: `calc(100vh - 714px)`,
    borderRadius: 6,
    justifyContent: 'center',
    backgroundSize: 'auto 105%',
    backgroundPosition: `top -36px left 5%`,
    paddingTop: 'unset',
  },
});

export const emptyPreviewStyle = (bgUrl: string) =>
  css(genericEmptyPreviewStyle, {
    backgroundImage: `url(${bgUrl})`,
    backgroundPosition: `bottom ${baseSpacing * 2}px left`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 50%',
    '> *': {
      marginLeft: baseSpacing * 3,
      marginRight: baseSpacing * 3,
    },
    '> p': {
      marginTop: baseSpacing * 2,
    },
    [media.small]: {
      backgroundSize: 'auto 90%',
      backgroundPosition: `center left -30px`,
      '> p': {
        marginLeft: 200,
      },
      '>div:last-of-type': {
        display: 'none',
      },
    },
    [media.medium]: {
      width: `calc(100% - ${baseSpacing * 4}px)`,
      margin: baseSpacing * 2,
      padding: 0,
      borderRadius: 6,
      backgroundPosition: `center left`,
      '> h3': {
        fontSize: 60,
      },
      '> p': {
        marginTop: -2,
        marginBottom: baseSpacing * 4,
      },
    },
  });

export const rocketEmptyPreviewStyle = css(genericEmptyPreviewStyle, {
  backgroundImage: `url(${rocket})`,
  backgroundPosition: `top right ${baseSpacing}px`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 130%',
  backgroundColor: colors.klik.lightBlue100,
  [media.small]: {
    backgroundSize: 'auto 210%',
    backgroundPosition: `top -100px right ${baseSpacing * 4}px`,
    justifyContent: 'center',
    alignItems: 'start',
    '> p': {
      width: '60%',
      textAlign: 'left',
      marginTop: -baseSpacing * 2,
      marginLeft: baseSpacing * 4,
    },
  },
  [media.medium]: {
    backgroundSize: 'auto 210%',
    backgroundPosition: `top -120px right ${baseSpacing * 4}px`,
    '> p': {
      marginLeft: '10%',
    },
  },
});
