import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useState } from 'react';

import { Arrows } from './';

export const IMAGE_CONTAINER_HEIGHT = 568;
export const THUMBNAIL_CONTAINER_HEIGHT = IMAGE_CONTAINER_HEIGHT / 6;
export const THUMBNAIL_CONTAINER_WIDTH = 140;

const containerStyle = css({
  maxWidth: 1064,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [media.medium]: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
  },
});

const imageContainerStyle = css({
  backgroundColor: colors.white,
  borderRadius: 6,
  height: 375,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  padding: baseSpacing * 8,
  '> img, svg': {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  [media.medium]: {
    height: IMAGE_CONTAINER_HEIGHT,
    borderRadius: '6px 0 0 6px',
  },
});

const thumbnailsStyle = css({
  marginTop: baseSpacing * 2,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [media.medium]: {
    flexDirection: 'column',
    marginTop: 0,
    '> button': {
      borderRadius: 0,
    },
  },
});

const thumbStyle = css({
  width: baseSpacing * 7,
  height: baseSpacing * 7,
  borderRadius: 8,
  padding: 2,
  backgroundColor: colors.white,
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  marginRight: baseSpacing,
  marginLeft: baseSpacing,
  borderLeftColor: designToken.background.gray,
  borderLeftStyle: 'solid',
  borderLeftWidth: 3,
  '> img, svg': {
    display: 'block',
    maxHeight: '98%',
    maxWidth: '98%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 2,
  },
  '&:focus': {
    outline: 'none',
  },
  '&:hover > img, &:hover > svg': {
    transition: 'transform 125ms ease-in',
    transform: 'scale(1.05)',
  },
  [media.medium]: {
    width: THUMBNAIL_CONTAINER_WIDTH,
    height: THUMBNAIL_CONTAINER_HEIGHT,
    margin: 0,
    '> img, svg': {
      display: 'block',
      maxHeight: '90%',
      maxWidth: '90%',
    },
  },
});

const activeThumbStyle = css(thumbStyle, {
  borderColor: designToken.secondary.default,
  borderStyle: 'solid',
  borderWidth: 3,
  '> img, svg': {
    transform: 'scale(1.1)',
  },
});

type ProductCarouselProps = {
  isOfTypeBox: boolean;
  images: string[];
};

export const ProductCarousel: React.FC<ProductCarouselProps> = ({ isOfTypeBox, images }) => {
  const [activeImgIndex, setActiveImgIndex] = useState(0);

  return (
    <div css={containerStyle}>
      <div css={imageContainerStyle}>
        <ProductImage
          src={isOfTypeBox ? images[activeImgIndex] : images[0]}
          alt="Product presentation."
        />
        {isOfTypeBox && (
          <Arrows
            activeImgIndex={activeImgIndex}
            setActiveImgIndex={setActiveImgIndex}
            maxIndex={images.length - 1}
          />
        )}
      </div>
      {isOfTypeBox && (
        <div css={thumbnailsStyle}>
          {images.map((image, index) => {
            return (
              <button
                key={index}
                css={index === activeImgIndex ? activeThumbStyle : thumbStyle}
                onClick={() => setActiveImgIndex(index)}
                aria-label="thumbnail"
              >
                <ProductImage
                  src={image}
                  alt={`Product thumbnail ${index + 1}.`}
                />
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};
