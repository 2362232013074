import '../public/pendingAnimation.css';

interface IPendingAnimation {
  fillColor?: string;
}

const PendingAnimation = ({ fillColor }: IPendingAnimation) => {
  return (
    <svg
      id="eippttty3hnm1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g id="eippttty3hnm2_to" transform="translate(7,29)">
        <g id="eippttty3hnm2_ts" transform="scale(1,1)">
          <circle
            id="eippttty3hnm2"
            r="7"
            transform="translate(0,0)"
            fill={fillColor}
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="eippttty3hnm3_to" transform="translate(7,7)">
        <g id="eippttty3hnm3_ts" transform="scale(1,1)">
          <circle
            id="eippttty3hnm3"
            r="7"
            transform="translate(-0,-0)"
            fill={fillColor}
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="eippttty3hnm4_to" transform="translate(29,29)">
        <g id="eippttty3hnm4_ts" transform="scale(1,1)">
          <circle
            id="eippttty3hnm4"
            r="7"
            transform="translate(0,-0)"
            fill={fillColor}
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="eippttty3hnm5_to" transform="translate(29,7)">
        <g id="eippttty3hnm5_ts" transform="scale(1,1)">
          <circle
            id="eippttty3hnm5"
            r="7"
            transform="translate(0,0)"
            fill={fillColor}
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default PendingAnimation;
