import { tokens } from '@lego/core-colors';

import { baseSpacing, designToken, font, media, mediaSizes } from '../theme';

export const buttonStyle = (
  type = 'primary',
  size = 'medium',
  disabled = false,
  warning = false,
  fullWidth = true
) => {
  return {
    fontFamily: 'inherit',
    fontSize: {
      tiny: font.size.small,
      small: font.size.small_mobile,
      medium: font.size.medium,
      large: font.size.medium,
    }[size],
    fontWeight: {
      tiny: font.weight.medium,
      small: font.weight.medium,
      medium: font.weight.normal,
      large: font.weight.normal,
    }[size],
    color: disabled
      ? tokens.color.core.gray[800]
      : {
          primary: tokens.color.core.gray[10],
          primary_inverted: designToken.interactive.default,
          secondary: warning ? designToken.warning.default : designToken.interactive.default,
          secondary_inverted: tokens.color.core.gray[10],
          ghost: warning ? designToken.warning.default : designToken.interactive.default,
          ghost_inverted: tokens.color.core.gray[10],
        }[type],
    backgroundColor: disabled
      ? {
          primary: tokens.color.core.gray[300],
          primary_inverted: tokens.color.core.gray[10],
          secondary: 'transparent',
          secondary_inverted: tokens.color.core.gray[10],
          ghost: 'transparent',
          ghost_inverted: 'transparent',
        }[type]
      : {
          primary: warning ? designToken.warning.default : designToken.interactive.default,
          primary_inverted: tokens.color.core.gray[10],
          secondary: 'transparent',
          secondary_inverted: 'transparent',
          ghost: 'transparent',
          ghost_inverted: 'transparent',
        }[type],
    height: {
      tiny: 'auto',
      small: 32,
      medium: 56,
      large: 72,
    }[size],
    width: fullWidth ? '100%' : 'auto',
    border: disabled
      ? {
          primary: 'none',
          primary_inverted: 'none',
          secondary: `solid 2px ${tokens.color.core.gray[300]}`,
          secondary_inverted: 'none',
          ghost: 'none',
          ghost_inverted: 'none',
        }[type]
      : {
          primary: 'none',
          primary_inverted: 'none',
          secondary: `solid 2px ${
            warning ? designToken.warning.default : designToken.interactive.default
          }`,
          secondary_inverted: `solid 2px ${tokens.color.core.gray[10]}`,
          ghost: 'none',
          ghost_inverted: 'none',
        }[type],
    borderRadius: 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: {
      tiny: baseSpacing,
      small: baseSpacing * 2,
      medium: baseSpacing * 3,
      large: baseSpacing * 3,
    }[size],
    paddingRight: {
      tiny: baseSpacing,
      small: baseSpacing * 2,
      medium: baseSpacing * 3,
      large: baseSpacing * 3,
    }[size],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: disabled ? 'initial' : 'pointer',
    transitionProperty: 'all',
    transitionDuration: '125ms',
    svg: {
      tiny: {
        height: 16,
        width: 16,
        marginRight: baseSpacing / 2,
        transform: 'translateY(-1px)',
      },
      small: {
        height: 18,
        width: 18,
        marginRight: baseSpacing / 2,
        transform: 'translateY(-1px)',
      },
      medium: {
        height: 22,
        width: 22,
        marginRight: baseSpacing,
        transform: 'translateY(-1px)',
      },
      large: {
        height: 22,
        width: 22,
        marginRight: baseSpacing,
        transform: 'translateY(-1px)',
      },
    }[size],
    '&:hover:enabled': {
      backgroundColor: {
        primary: warning ? designToken.warning.default : designToken.interactive.hover,
        primary_inverted: tokens.color.core.azur[50],
        secondary: warning ? designToken.warning.muted : tokens.color.core.azur[75],
        secondary_inverted: 'rgba(29, 29, 29, 0.1)',
        ghost: 'rgba(41, 41, 40, 0.05)',
        ghost_inverted: 'rgba(41, 41, 40, 0.05)',
      }[type],
      opacity: {
        primary: 0.7,
      }[type],
    },
    '&:focus': {
      outline: 'none',
      color: {
        primary: tokens.color.core.gray[10],
        primary_inverted: designToken.interactive.active,
        secondary: warning ? designToken.warning.default : designToken.interactive.default,
        secondary_inverted: 'rgba(29, 29, 29, 0.2)',
        ghost: warning ? designToken.warning.default : designToken.interactive.active,
      }[type],
      backgroundColor: {
        primary: warning ? designToken.warning.emphasis : designToken.interactive.active,
        primary_inverted: tokens.color.core.azur[50],
        secondary: warning ? designToken.warning.muted : tokens.color.core.azur[100],
        secondary_inverted: tokens.color.core.gray[10],
        ghost: 'rgba(41, 41, 40, 0.05)',
        ghost_inverted: 'rgba(41, 41, 40, 0.05)',
      }[type],
    },
    [media.small]: {
      width: 'auto',
    },
    '&:active': {
      ['@media (hover: hover)']: {
        transform: 'translateY(1px)',
        boxShadow: {
          primary: 'inset 0px 2px 0px rgba(0, 0, 0, 0.1)',
          primary_inverted: 'inset 0px 2px 0px rgba(0, 0, 0, 0.1)',
          ghost: 'inset 0px 2px 0px rgba(0, 0, 0, 0.1)',
          ghost_inverted: 'inset 0px 2px 0px rgba(0, 0, 0, 0.1)',
        }[type],
        backgroundColor: {
          secondary: 'rgba(41, 41, 40, 0.05)',
          secondary_inverted: 'rgba(41, 41, 40, 0.05)',
          ghost: 'rgba(41, 41, 40, 0.05)',
          ghost_inverted: 'rgba(41, 41, 40, 0.05)',
        }[type],
      },
    },
    '*:last-child:not(:only-child)': {
      tiny: {
        marginLeft: baseSpacing / 2,
      },
      small: {
        marginLeft: baseSpacing / 2,
      },
      medium: {
        marginLeft: baseSpacing,
      },
      large: {
        marginLeft: baseSpacing,
      },
    }[size],
    ':has(svg:only-child)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '> svg:only-child': {
      marginRight: 0,
      minWidth: '2em',
    },
    [`@media not all and (min-width: ${mediaSizes.medium}px)`]: {
      '> span': {
        display: 'none',
      },
    },
  };
};
