import { css } from '@emotion/react';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import { Fragment } from 'react';

import { leftArrowStyle, rightArrowStyle } from '../../../styles';

const buttonStyle = css({
  bottom: 0,
  top: 0,
});

const localLeftArrowStyle = css(leftArrowStyle, buttonStyle, {
  left: baseSpacing,
  paddingRight: baseSpacing / 2,
  [media.medium]: {
    left: baseSpacing * 3,
  },
});

const localRightArrowStyle = css(rightArrowStyle, buttonStyle, {
  right: baseSpacing,
  paddingLeft: baseSpacing / 2,
  '> svg': {
    transform: 'translateX(-1px)',
  },
  [media.medium]: {
    right: baseSpacing * 3,
  },
});

interface IArrows {
  activeImgIndex: number;
  setActiveImgIndex: (index: number) => void;
  maxIndex: number;
}

export const Arrows = ({ activeImgIndex, setActiveImgIndex, maxIndex }: IArrows) => {
  const handleLeftArr = () => {
    activeImgIndex > 0 ? setActiveImgIndex(activeImgIndex - 1) : setActiveImgIndex(maxIndex);
  };

  const handleRightArr = () => {
    activeImgIndex < maxIndex ? setActiveImgIndex(activeImgIndex + 1) : setActiveImgIndex(0);
  };

  return (
    <Fragment>
      <button
        css={localLeftArrowStyle}
        onClick={handleLeftArr}
      >
        <Icon type={IconType.CHEVRON_LEFT} />
      </button>
      <button
        css={localRightArrowStyle}
        onClick={handleRightArr}
      >
        <Icon type={IconType.CHEVRON_RIGHT} />
      </button>
    </Fragment>
  );
};
