import { gql } from '@apollo/client';

export const INVOICE = gql`
  query invoice($invoiceNumber: Int!, $customerId: Int!) {
    invoice(customerId: $customerId, invoiceNumber: $invoiceNumber) {
      invoiceNumber
      invoiceDate
      invoiceNetValue {
        amount
        currency
      }
      hasPdf
      orders {
        orderNumber
        orderName
        items {
          itemNumber
          materialId
          name
          theme
          quantity
          invoiceLineNumber
          price {
            currency
            estimatedNetInvoicedPrice
            netInvoicedPrice
          }
        }
      }
    }
    products(customerId: $customerId) {
      products {
        materialId
      }
    }
  }
`;
