import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.css';

import { isAfter, isBefore } from 'date-fns';
import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { getLocaleForDatePicker } from '../../../../../src/shared/CheckoutFlow/common/utils';
import { Icon, IconType } from '../Icon';
import { Input } from '../Input';

type DateInputProps = {
  minDate?: Date;
  maxDate?: Date;
  date: Date | null;
  onDateChange: (date: Date | null) => void;
  disabled: boolean;
  locale: string;
  excludeDateIntervals?: React.ComponentProps<typeof DatePicker>['excludeDateIntervals'];
  placeholder?: string;
};

const DateInput: React.FC<DateInputProps> = ({
  minDate,
  maxDate,
  date,
  onDateChange,
  disabled = false,
  locale,
  excludeDateIntervals,
  placeholder,
}) => {
  useEffect(() => {
    registerLocale(locale, getLocaleForDatePicker(locale));
  }, [locale]);

  useEffect(() => {
    if (!date) {
      return;
    }

    if (minDate && isBefore(date, minDate)) {
      onDateChange(minDate);
    } else if (maxDate && isAfter(date, maxDate)) {
      onDateChange(maxDate);
    }
  }, [onDateChange, maxDate, minDate, date]);

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <DatePicker
      customInput={<Input rightElement={<Icon type={IconType.CALENDAR} />} />}
      disabled={disabled}
      selected={date}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(date) => onDateChange(date)}
      onChangeRaw={(e) => e?.preventDefault()}
      locale={locale}
      dateFormat="P"
      disabledKeyboardNavigation
      filterDate={isWeekday}
      calendarClassName="calendarClassName"
      enableTabLoop={false}
      excludeDateIntervals={excludeDateIntervals}
      placeholderText={placeholder}
    />
  );
};

export default DateInput;
