import { gql } from '@apollo/client';

export const FOOTER = gql`
  query footer($customerId: Int!) {
    getFooter(customerId: $customerId) {
      legalInformation {
        topText
        links {
          title
          url
          assetUid
        }
        bottomText
        reachLink {
          title
          url
        }
        pricingDisclaimer
      }
      sections {
        title
        items {
          title
          url
          assetUid
        }
      }
    }
  }
`;
