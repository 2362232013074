import { css } from '@emotion/react';
import { colors } from '@lego/b2b-unicorn-ui-constants';

export const orderIndicatorStyle = css({
  color: colors.klik.slate500,
  textAlign: 'right',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
