export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
};

export type AssetToken = {
  __typename?: 'AssetToken';
  assetUid: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type AvailabilityFacet = {
  __typename?: 'AvailabilityFacet';
  availability: StockStatusTag;
  hitsCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type BillOfMaterial = Product & {
  __typename?: 'BillOfMaterial';
  available: Scalars['Boolean']['output'];
  customerId: Scalars['Int']['output'];
  dimensions?: Maybe<Dimension>;
  ean: Scalars['String']['output'];
  launchDate?: Maybe<Scalars['String']['output']>;
  lifecycles: Array<Lifecycle>;
  materialId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  openOrderInfo?: Maybe<OpenOrderInfo>;
  piecesPerCasePack: Scalars['Int']['output'];
  price: Price;
  stockStatus: StockStatus;
  tags: Array<ProductTag>;
  theme: Scalars['String']['output'];
  weight?: Maybe<Weight>;
};

export type Box = Product & {
  __typename?: 'Box';
  ageGroup?: Maybe<Scalars['String']['output']>;
  available: Scalars['Boolean']['output'];
  customerId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<Dimension>;
  ean: Scalars['String']['output'];
  exitDate?: Maybe<Scalars['String']['output']>;
  itemNumber?: Maybe<Scalars['Int']['output']>;
  launchDate?: Maybe<Scalars['String']['output']>;
  lifecycles: Array<Lifecycle>;
  materialId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  openOrderInfo?: Maybe<OpenOrderInfo>;
  pieces?: Maybe<Scalars['Int']['output']>;
  piecesPerCasePack: Scalars['Int']['output'];
  price: Price;
  recommendedRetailPrice?: Maybe<Money>;
  stockStatus: StockStatus;
  tags: Array<ProductTag>;
  theme: Scalars['String']['output'];
  weight?: Maybe<Weight>;
};

export type BrickBankInfoPaymentMethod = {
  brand?: InputMaybe<Scalars['String']['input']>;
  encryptedCardNumber?: InputMaybe<Scalars['String']['input']>;
  encryptedExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  encryptedExpiryYear?: InputMaybe<Scalars['String']['input']>;
  encryptedSecurityCode?: InputMaybe<Scalars['String']['input']>;
  holderName?: InputMaybe<Scalars['String']['input']>;
  storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type BrickBankPaymentInfo = {
  browserInfo: BrowserInfo;
  paymentMethod: BrickBankInfoPaymentMethod;
  storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrickBankPaymentInfoInput = {
  browserInfo: BrowserInfo;
  paymentMethod: BrickBankInfoPaymentMethod;
  storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrickBankPaymentMethod = {
  __typename?: 'BrickBankPaymentMethod';
  brands: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type BrickBankPaymentMethodsResponse = {
  __typename?: 'BrickBankPaymentMethodsResponse';
  paymentMethods?: Maybe<Array<BrickBankPaymentMethod>>;
  storedPaymentMethods?: Maybe<Array<BrickBankStoredPaymentMethod>>;
};

export type BrickBankStoredPaymentMethod = {
  __typename?: 'BrickBankStoredPaymentMethod';
  brand: Scalars['String']['output'];
  expiryMonth: Scalars['String']['output'];
  expiryYear: Scalars['String']['output'];
  holderName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastFour: Scalars['String']['output'];
  name: Scalars['String']['output'];
  supportedShopperInteractions: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type BrowserInfo = {
  acceptHeader: Scalars['String']['input'];
  colorDepth: Scalars['Int']['input'];
  javaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  screenHeight: Scalars['Int']['input'];
  screenWidth: Scalars['Int']['input'];
  timeZoneOffset: Scalars['Int']['input'];
  userAgent: Scalars['String']['input'];
};

export type Cart = {
  __typename?: 'Cart';
  cartReference: CartReference;
  customerId: Scalars['Int']['output'];
  deliveryDates: CartDeliveryDates;
  id: Scalars['String']['output'];
  items: Array<CartItem>;
  minimumOrderValue: Money;
  removedItems: Array<RemovedCartItem>;
};

export type CartDeliveryDates = {
  __typename?: 'CartDeliveryDates';
  earliestDate?: Maybe<Scalars['String']['output']>;
  latestDate?: Maybe<Scalars['String']['output']>;
  rddNotAvailableDates: Array<CartRddNotAvailableDate>;
};

export type CartItem = {
  __typename?: 'CartItem';
  product: Product;
  quantity: Scalars['Int']['output'];
};

export type CartItemWithSimulationDetails = {
  __typename?: 'CartItemWithSimulationDetails';
  expectedDeliveryDate?: Maybe<Scalars['String']['output']>;
  product: Product;
  quantity: Scalars['Int']['output'];
  simulationPrice: Money;
};

export type CartRddNotAvailableDate = {
  __typename?: 'CartRddNotAvailableDate';
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type CartReference = {
  __typename?: 'CartReference';
  cartLaunchMonth?: Maybe<Scalars['Int']['output']>;
  cartLaunchYear?: Maybe<Scalars['Int']['output']>;
  cartPromotionId?: Maybe<Scalars['Int']['output']>;
  cartType: CartReferenceCartType;
};

export enum CartReferenceCartType {
  Launch = 'LAUNCH',
  Promotion = 'PROMOTION',
  Replenish = 'REPLENISH'
}

export type CartReferenceInput = {
  cartType: CartReferenceCartType;
  launch?: InputMaybe<LaunchCartReference>;
  promotion?: InputMaybe<PromotionCartReference>;
};

export type CartWithSimulationDetails = {
  __typename?: 'CartWithSimulationDetails';
  cartReference: CartReference;
  customerId: Scalars['Int']['output'];
  deliveryDates: CartDeliveryDates;
  details?: Maybe<SimulationPriceDetails>;
  id: Scalars['String']['output'];
  items: Array<CartItemWithSimulationDetails>;
  itemsWithSimulationDetails?: Maybe<Array<CartItemWithSimulationDetails>>;
  removedItems: Array<RemovedCartItem>;
  totalListPrice: Money;
  totalSimulationPrice: Money;
};

export type CartWithSimulationDetailsResponse = Cart | CartWithSimulationDetails;

export type Claim = {
  __typename?: 'Claim';
  id: Scalars['Int']['output'];
};

export type ClaimFileResource = {
  __typename?: 'ClaimFileResource';
  fileName: Scalars['String']['output'];
  uri: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type ClaimItemInput = {
  claimType: ClaimType;
  comment?: InputMaybe<Scalars['String']['input']>;
  expectedPrice?: InputMaybe<Scalars['Float']['input']>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceLineNumber: Scalars['Int']['input'];
  materialId: Scalars['Int']['input'];
  orderNumber: Scalars['Int']['input'];
  pieces: Scalars['Int']['input'];
};

export enum ClaimType {
  DamagedGoods = 'DAMAGED_GOODS',
  DeliveryShortage = 'DELIVERY_SHORTAGE',
  Misc = 'MISC',
  OverUnderSub = 'OVER_UNDER_SUB',
  PriceDisputes = 'PRICE_DISPUTES'
}

export type CreateClaimFileResourceInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  invoiceNumber: Scalars['Int']['input'];
  md5Checksum: Scalars['String']['input'];
};

export type CreateClaimInput = {
  claimItems: Array<ClaimItemInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceNumber: Scalars['Int']['input'];
};

export type CreateOrderResponse = {
  __typename?: 'CreateOrderResponse';
  orderName?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['Int']['output'];
};

export type CreditCard = {
  __typename?: 'CreditCard';
  expiryDate: Scalars['String']['output'];
  lastFourDigits: Scalars['String']['output'];
  token: Scalars['String']['output'];
  type: CreditCardType;
};

export type CreditCardSession = {
  __typename?: 'CreditCardSession';
  accessToken: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum CreditCardType {
  Amex = 'AMEX',
  Discover = 'Discover',
  MasterCard = 'MasterCard',
  Visa = 'VISA'
}

export type Customer = {
  __typename?: 'Customer';
  availableLanguages?: Maybe<Array<Language>>;
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  functionalDiscount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  inactive: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orderBlock: Scalars['Boolean']['output'];
  parentC6Id?: Maybe<Scalars['Int']['output']>;
  paymentMethod: PaymentMethod;
  salesOrg: Scalars['Int']['output'];
  showSimulationDetails: Scalars['Boolean']['output'];
};

export type Dimension = {
  __typename?: 'Dimension';
  height: Scalars['Float']['output'];
  length: Scalars['Float']['output'];
  unit: LengthUnit;
  width: Scalars['Float']['output'];
};

export type Footer = {
  __typename?: 'Footer';
  legalInformation?: Maybe<LegalInformation>;
  sections: Array<FooterSection>;
};

export type FooterSection = {
  __typename?: 'FooterSection';
  items: Array<Item>;
  title?: Maybe<Scalars['String']['output']>;
};

export type FutureLaunchWindowItem = {
  __typename?: 'FutureLaunchWindowItem';
  month: Scalars['Int']['output'];
  orderEndInstant: Scalars['String']['output'];
  productsCount: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type GetCreditCardsResponse = {
  __typename?: 'GetCreditCardsResponse';
  cards: Array<CreditCard>;
};

export enum IFrameEnvType {
  Default = 'DEFAULT',
  Launch = 'LAUNCH',
  Localhost = 'LOCALHOST',
  LocalhostLaunch = 'LOCALHOST_LAUNCH'
}

export type Image = {
  __typename?: 'Image';
  url?: Maybe<Scalars['String']['output']>;
};

export type InfoAsset = {
  __typename?: 'InfoAsset';
  assetUid?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updated: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type InfoItem = {
  __typename?: 'InfoItem';
  description?: Maybe<Scalars['String']['output']>;
  files: Array<InfoAsset>;
  image?: Maybe<Scalars['String']['output']>;
  links: Array<Item>;
  title: Scalars['String']['output'];
};

export type InvalidItem = {
  __typename?: 'InvalidItem';
  cartReference?: Maybe<CartReference>;
  itemId?: Maybe<Scalars['BigInt']['output']>;
  itemNumber?: Maybe<Scalars['Int']['output']>;
  materialId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  reason: InvalidItemReason;
};

export enum InvalidItemReason {
  DeadlineOverdue = 'DEADLINE_OVERDUE',
  MarkedNotAvailable = 'MARKED_NOT_AVAILABLE',
  MaxQuantityExceeded = 'MAX_QUANTITY_EXCEEDED',
  NotInCatalog = 'NOT_IN_CATALOG',
  NotInWindow = 'NOT_IN_WINDOW',
  OrderBlocked = 'ORDER_BLOCKED',
  OutOfStock = 'OUT_OF_STOCK',
  ReplenishItem = 'REPLENISH_ITEM',
  Retired = 'RETIRED'
}

export type Invoice = {
  __typename?: 'Invoice';
  hasPdf?: Maybe<Scalars['Boolean']['output']>;
  invoiceDate: Scalars['String']['output'];
  invoiceNetValue: Money;
  invoiceNumber: Scalars['Int']['output'];
  orders: Array<InvoiceOrder>;
};

export type InvoiceList = {
  __typename?: 'InvoiceList';
  invoices: Array<Invoice>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type InvoiceOrder = {
  __typename?: 'InvoiceOrder';
  items: Array<InvoiceOrderItem>;
  orderName?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['Int']['output'];
};

export type InvoiceOrderItem = {
  __typename?: 'InvoiceOrderItem';
  invoiceLineNumber: Scalars['Int']['output'];
  itemNumber?: Maybe<Scalars['Int']['output']>;
  materialId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Price;
  quantity: Scalars['Int']['output'];
  theme: Scalars['String']['output'];
};

export type InvoiceSearchFilter = {
  invoiceNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Item = {
  __typename?: 'Item';
  assetUid?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type LanguageInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type LaunchCartReference = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type LaunchWindow = {
  __typename?: 'LaunchWindow';
  orderBlockedProducts: Array<Product>;
  orderEndInstant?: Maybe<Scalars['String']['output']>;
  products: Array<Product>;
  windowType: WindowType;
};

export type LaunchWindowItem = {
  __typename?: 'LaunchWindowItem';
  cartHasItems: Scalars['Boolean']['output'];
  month: Scalars['Int']['output'];
  orderEndInstant: Scalars['String']['output'];
  ordersCreated: Array<Order>;
  products: Array<Product>;
  productsCount: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type LaunchWindows = {
  __typename?: 'LaunchWindows';
  futureWindows?: Maybe<Array<FutureLaunchWindowItem>>;
  openWindows: Array<LaunchWindowItem>;
  previousWindows: Array<LaunchWindowItem>;
};

export type LegalInformation = {
  __typename?: 'LegalInformation';
  bottomText?: Maybe<Scalars['String']['output']>;
  links: Array<Item>;
  pricingDisclaimer?: Maybe<Scalars['String']['output']>;
  reachLink?: Maybe<Item>;
  topText?: Maybe<Scalars['String']['output']>;
};

export enum LengthUnit {
  Cm = 'CM',
  Inch = 'INCH',
  Mm = 'MM'
}

export enum Lifecycle {
  CarryOver = 'CARRY_OVER',
  ComingSoon = 'COMING_SOON',
  New = 'NEW',
  Retired = 'RETIRED',
  RetiringSoon = 'RETIRING_SOON'
}

export type LifecycleFacet = {
  __typename?: 'LifecycleFacet';
  hitsCount: Scalars['Int']['output'];
  lifecycle: Lifecycle;
  totalCount: Scalars['Int']['output'];
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsOfSales: Scalars['Boolean']['output'];
  createBrickBankOrder: CreateOrderResponse;
  createClaim: Scalars['Int']['output'];
  createClaimFileResource: ClaimFileResource;
  createOrder: CreateOrderResponse;
  emptyCart: Cart;
  removeBrickBankStoredCard: Scalars['Boolean']['output'];
  updateCart: Cart;
  updateCartWithMultipleItems: Cart;
  updateUserPreferencesForCustomer: User;
};


export type MutationAcceptTermsOfSalesArgs = {
  customerId: Scalars['Int']['input'];
  version: Scalars['String']['input'];
};


export type MutationCreateBrickBankOrderArgs = {
  brickBankPaymentInfo: BrickBankPaymentInfoInput;
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
  orderName?: InputMaybe<Scalars['String']['input']>;
  requestedDeliveryDate: Scalars['String']['input'];
  shipToId: Scalars['Int']['input'];
};


export type MutationCreateClaimArgs = {
  createClaimData: CreateClaimInput;
  customerId: Scalars['Int']['input'];
};


export type MutationCreateClaimFileResourceArgs = {
  customerId: Scalars['Int']['input'];
  input: CreateClaimFileResourceInput;
};


export type MutationCreateOrderArgs = {
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
  orderName?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  requestedDeliveryDate: Scalars['String']['input'];
  shipToId: Scalars['Int']['input'];
};


export type MutationEmptyCartArgs = {
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
};


export type MutationRemoveBrickBankStoredCardArgs = {
  cardId: Scalars['String']['input'];
  customerId: Scalars['Int']['input'];
};


export type MutationUpdateCartArgs = {
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
  materialId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationUpdateCartWithMultipleItemsArgs = {
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
  items: Array<UpdateCartItemInput>;
};


export type MutationUpdateUserPreferencesForCustomerArgs = {
  customerId: Scalars['Int']['input'];
  preferences: UserPreferencesForCustomerInput;
};

export type OpenOrderInfo = {
  __typename?: 'OpenOrderInfo';
  shippingDate?: Maybe<Scalars['String']['output']>;
  shippingQuantity?: Maybe<Scalars['Int']['output']>;
  totalPendingQuantity?: Maybe<Scalars['Int']['output']>;
};

export type Order = {
  __typename?: 'Order';
  cartReference?: Maybe<Scalars['String']['output']>;
  fulfillmentStatus: OrderFulfillmentStatus;
  hasPdfOutput?: Maybe<Scalars['Boolean']['output']>;
  items: Array<OrderItem>;
  launchWindowId?: Maybe<Scalars['String']['output']>;
  orderDate: Scalars['String']['output'];
  orderName: Scalars['String']['output'];
  orderNumber: Scalars['Int']['output'];
  orderStatus: OrderStatus;
  orderTotal?: Maybe<Price>;
  requestedDeliveryDate: Scalars['String']['output'];
  shipTo: ShipTo;
  shipmentInfo: Array<Scalars['String']['output']>;
  type?: Maybe<OrderType>;
};

export enum OrderFulfillmentStatus {
  StatusFullyConfirmed = 'STATUS_FULLY_CONFIRMED',
  StatusOpen = 'STATUS_OPEN',
  StatusPartiallyConfirmed = 'STATUS_PARTIALLY_CONFIRMED',
  StatusRejected = 'STATUS_REJECTED'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  confirmedPieces?: Maybe<Scalars['Int']['output']>;
  expectedDeliveryDate: Array<OrderItemDeliveryDate>;
  itemNumber?: Maybe<Scalars['Int']['output']>;
  materialId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orderedPieces?: Maybe<Scalars['Int']['output']>;
  price: Price;
  rejected: Scalars['Boolean']['output'];
  rejectedPieces?: Maybe<Scalars['Int']['output']>;
  theme: Scalars['String']['output'];
};

export type OrderItemDeliveryDate = {
  __typename?: 'OrderItemDeliveryDate';
  date: Scalars['String']['output'];
  pieces?: Maybe<Scalars['Int']['output']>;
};

export type OrderList = {
  __typename?: 'OrderList';
  orders: Array<Order>;
  totalCount: Scalars['Int']['output'];
};

export type OrderSearchFilter = {
  cartReference?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  fulfillmentStatuses?: InputMaybe<Array<OrderFulfillmentStatus>>;
  launchWindowId?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderStatuses?: InputMaybe<Array<OrderStatus>>;
  query?: InputMaybe<Scalars['String']['input']>;
  shipToIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sort?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderStatus {
  StatusCompleted = 'STATUS_COMPLETED',
  StatusInProgress = 'STATUS_IN_PROGRESS',
  StatusOpen = 'STATUS_OPEN',
  StatusProcessing = 'STATUS_PROCESSING'
}

export enum OrderType {
  OrderTypeExtended = 'ORDER_TYPE_EXTENDED',
  OrderTypeLaunch = 'ORDER_TYPE_LAUNCH',
  OrderTypePromotion = 'ORDER_TYPE_PROMOTION',
  OrderTypeReplenishment = 'ORDER_TYPE_REPLENISHMENT',
  OrderTypeUnknown = 'ORDER_TYPE_UNKNOWN'
}

export enum PageType {
  Checkout = 'CHECKOUT',
  Home = 'HOME'
}

export type Pagination = {
  __typename?: 'Pagination';
  lastPage: Scalars['Boolean']['output'];
};

export type PaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export enum PaymentMethod {
  Credit = 'CREDIT',
  CreditCard = 'CREDIT_CARD'
}

export type PdfAttachmentResponse = {
  __typename?: 'PdfAttachmentResponse';
  fileDataAsBase64: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

export type Price = {
  __typename?: 'Price';
  currency: Scalars['String']['output'];
  estimatedNetInvoicedPrice: Scalars['Float']['output'];
  grossPrice?: Maybe<Scalars['Float']['output']>;
  listPrice?: Maybe<Scalars['Float']['output']>;
  netInvoicedPrice?: Maybe<Scalars['Float']['output']>;
};

export type PriceRangesFacet = {
  __typename?: 'PriceRangesFacet';
  from: Scalars['Float']['output'];
  hitsCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  to?: Maybe<Scalars['Float']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type Product = {
  available: Scalars['Boolean']['output'];
  customerId: Scalars['Int']['output'];
  dimensions?: Maybe<Dimension>;
  ean: Scalars['String']['output'];
  lifecycles: Array<Lifecycle>;
  materialId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  openOrderInfo?: Maybe<OpenOrderInfo>;
  piecesPerCasePack: Scalars['Int']['output'];
  price: Price;
  stockStatus: StockStatus;
  tags: Array<ProductTag>;
  theme: Scalars['String']['output'];
  weight?: Maybe<Weight>;
};

export type ProductList = {
  __typename?: 'ProductList';
  metadata: ProductListMetadata;
  pagination: Pagination;
  products: Array<Product>;
};

export type ProductListFacets = {
  __typename?: 'ProductListFacets';
  availabilities: Array<AvailabilityFacet>;
  lifecycles: Array<LifecycleFacet>;
  priceRanges: Array<PriceRangesFacet>;
  themes: Array<TopThemeFacet>;
};

export type ProductListFilterInput = {
  availabilities?: InputMaybe<Array<StockStatusTag>>;
  lifecycles?: InputMaybe<Array<Lifecycle>>;
  priceRanges?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<ProductTag>>;
  themes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductListMetadata = {
  __typename?: 'ProductListMetadata';
  facets: ProductListFacets;
  hitsCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export enum ProductTag {
  RareItem = 'RARE_ITEM',
  RecommendedAssortment = 'RECOMMENDED_ASSORTMENT'
}

export type ProductValidationInput = {
  itemId: Scalars['BigInt']['input'];
  quantity: Scalars['Int']['input'];
};

export type ProductsInput = {
  availabilities?: InputMaybe<Array<StockStatusTag>>;
  lifecycles?: InputMaybe<Array<Lifecycle>>;
  priceRanges?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<ProductTag>>;
  themes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductsValidation = {
  __typename?: 'ProductsValidation';
  invalidItems: Array<InvalidItem>;
  validItems: Array<ValidItem>;
};

export type PromotionCartReference = {
  promotionId: Scalars['Int']['input'];
};

export type PromotionDeliveryDates = {
  __typename?: 'PromotionDeliveryDates';
  earliestDate: Scalars['String']['output'];
  latestDate: Scalars['String']['output'];
};

export type PromotionDetail = {
  __typename?: 'PromotionDetail';
  availableForOrder: Scalars['Boolean']['output'];
  deliveryDates: PromotionDeliveryDates;
  description: Scalars['String']['output'];
  executionDates: PromotionExecutionDates;
  id: Scalars['Int']['output'];
  orderDeadlineDate?: Maybe<Scalars['String']['output']>;
  productConditions?: Maybe<Array<PromotionProductCondition>>;
  products: Array<Product>;
};

export type PromotionExecutionDates = {
  __typename?: 'PromotionExecutionDates';
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type PromotionProductCondition = {
  __typename?: 'PromotionProductCondition';
  materialId: Scalars['Int']['output'];
  minimumCasePacks?: Maybe<Scalars['Int']['output']>;
};

export type PromotionsOverview = {
  __typename?: 'PromotionsOverview';
  availableForOrderPromotions: Array<PromotionsOverviewItem>;
  noLongerAvailableForOrderPromotions: Array<PromotionsOverviewItem>;
};

export type PromotionsOverviewItem = {
  __typename?: 'PromotionsOverviewItem';
  cartHasItems: Scalars['Boolean']['output'];
  deliveryDates: PromotionDeliveryDates;
  description: Scalars['String']['output'];
  executionDates: PromotionExecutionDates;
  id: Scalars['Int']['output'];
  orderDeadlineDate: Scalars['String']['output'];
  ordersCreated: Array<Order>;
  productsCount: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  cart: Cart;
  cartWithSimulationDetails: CartWithSimulationDetailsResponse;
  getAssetToken: AssetToken;
  getBrickBankPaymentMethods: BrickBankPaymentMethodsResponse;
  /** @deprecated Use cart query instead */
  getCart: Cart;
  /** @deprecated Use cartWithSimulationDetails query instead */
  getCartWithSimulationDetails: CartWithSimulationDetailsResponse;
  getFooter?: Maybe<Footer>;
  getInfoItems: Array<InfoItem>;
  /** @deprecated Use invoice query instead */
  getInvoice?: Maybe<Invoice>;
  getInvoicePdf: PdfAttachmentResponse;
  getInvoices: InvoiceList;
  /** @deprecated Use launchWindow query instead */
  getLaunchWindow: LaunchWindow;
  /** @deprecated Use launchWindows query instead */
  getLaunchWindows: LaunchWindows;
  getOrderPdf: PdfAttachmentResponse;
  getShipTos: Array<ShipTo>;
  getTermsOfSales: TermsOfSales;
  getTermsOfSalesAcceptanceState: Scalars['Boolean']['output'];
  getUser?: Maybe<User>;
  invoice?: Maybe<Invoice>;
  launchWindow: LaunchWindow;
  launchWindows: LaunchWindows;
  order?: Maybe<Order>;
  orders: OrderList;
  product?: Maybe<Product>;
  products: ProductList;
  promotion: PromotionDetail;
  promotions: PromotionsOverview;
  recommendations: Array<RecommendationItem>;
  searchProducts: Array<Product>;
  upcomingDeliveries: UpcomingDeliveryList;
  validateProducts: ProductsValidation;
};


export type QueryCartArgs = {
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
  removeObsoleteItems?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCartWithSimulationDetailsArgs = {
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
  removeObsoleteItems?: InputMaybe<Scalars['Boolean']['input']>;
  requestedDeliveryDate: Scalars['String']['input'];
  shipToId: Scalars['Int']['input'];
};


export type QueryGetAssetTokenArgs = {
  assetUid: Scalars['String']['input'];
  customerId: Scalars['Int']['input'];
};


export type QueryGetBrickBankPaymentMethodsArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryGetCartArgs = {
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
  removeObsoleteItems?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetCartWithSimulationDetailsArgs = {
  cartReference: CartReferenceInput;
  customerId: Scalars['Int']['input'];
  removeObsoleteItems?: InputMaybe<Scalars['Boolean']['input']>;
  requestedDeliveryDate: Scalars['String']['input'];
  shipToId: Scalars['Int']['input'];
};


export type QueryGetFooterArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryGetInfoItemsArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryGetInvoiceArgs = {
  customerId: Scalars['Int']['input'];
  invoiceNumber: Scalars['Int']['input'];
};


export type QueryGetInvoicePdfArgs = {
  customerId: Scalars['Int']['input'];
  invoiceNumber: Scalars['Int']['input'];
};


export type QueryGetInvoicesArgs = {
  customerId: Scalars['Int']['input'];
  filter?: InputMaybe<InvoiceSearchFilter>;
};


export type QueryGetLaunchWindowArgs = {
  customerId: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type QueryGetLaunchWindowsArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryGetOrderPdfArgs = {
  customerId: Scalars['Int']['input'];
  orderNumber: Scalars['Int']['input'];
};


export type QueryGetShipTosArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryGetTermsOfSalesArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryGetTermsOfSalesAcceptanceStateArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryInvoiceArgs = {
  customerId: Scalars['Int']['input'];
  invoiceNumber: Scalars['Int']['input'];
};


export type QueryLaunchWindowArgs = {
  customerId: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type QueryLaunchWindowsArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryOrderArgs = {
  customerId: Scalars['Int']['input'];
  orderNumber: Scalars['Int']['input'];
};


export type QueryOrdersArgs = {
  customerId: Scalars['Int']['input'];
  filter?: InputMaybe<OrderSearchFilter>;
};


export type QueryProductArgs = {
  customerId: Scalars['Int']['input'];
  materialId: Scalars['Int']['input'];
};


export type QueryProductsArgs = {
  customerId: Scalars['Int']['input'];
  filters?: InputMaybe<ProductListFilterInput>;
  input?: InputMaybe<ProductsInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryPromotionArgs = {
  customerId: Scalars['Int']['input'];
  promotionId: Scalars['Int']['input'];
};


export type QueryPromotionsArgs = {
  customerId: Scalars['Int']['input'];
};


export type QueryRecommendationsArgs = {
  customerId: Scalars['Int']['input'];
  page?: InputMaybe<PageType>;
};


export type QuerySearchProductsArgs = {
  customerId: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};


export type QueryUpcomingDeliveriesArgs = {
  customerId: Scalars['Int']['input'];
  filter?: InputMaybe<UpcomingDeliverySearchFilter>;
};


export type QueryValidateProductsArgs = {
  cartReference?: InputMaybe<CartReferenceInput>;
  customerId: Scalars['Int']['input'];
  items: Array<ProductValidationInput>;
};

export type RecommendationItem = {
  __typename?: 'RecommendationItem';
  itemNumber: Scalars['Int']['output'];
  materialId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  openOrderInfo?: Maybe<OpenOrderInfo>;
  piecesPerCasePack: Scalars['Int']['output'];
  price: Price;
  rank: Scalars['Int']['output'];
  recommendedRetailPrice?: Maybe<Money>;
  stockStatus: StockStatus;
  theme: Scalars['String']['output'];
};

export type RemovedCartItem = {
  __typename?: 'RemovedCartItem';
  itemNumber?: Maybe<Scalars['Int']['output']>;
  materialId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  reason: RemovedCartItemReason;
};

export enum RemovedCartItemReason {
  MarkedNotAvailable = 'MARKED_NOT_AVAILABLE',
  NotInCatalog = 'NOT_IN_CATALOG',
  OutOfStock = 'OUT_OF_STOCK',
  RemovedByPricingService = 'REMOVED_BY_PRICING_SERVICE'
}

export type ShipTo = {
  __typename?: 'ShipTo';
  city?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  leadtimeInDays: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SimulationPriceDetails = {
  __typename?: 'SimulationPriceDetails';
  totalDiscount?: Maybe<Money>;
  totalItemSurcharge?: Maybe<Money>;
};

export type StockStatus = {
  __typename?: 'StockStatus';
  expectedBackDate?: Maybe<Scalars['String']['output']>;
  status: StockStatusTag;
};

export enum StockStatusTag {
  Available = 'AVAILABLE',
  ExpectedBackInStock = 'EXPECTED_BACK_IN_STOCK',
  LowStock = 'LOW_STOCK',
  NotAvailable = 'NOT_AVAILABLE',
  OutOfStock = 'OUT_OF_STOCK'
}

export type SubThemeFacet = {
  __typename?: 'SubThemeFacet';
  hitsCount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Supplementary = Product & {
  __typename?: 'Supplementary';
  available: Scalars['Boolean']['output'];
  customerId: Scalars['Int']['output'];
  dimensions?: Maybe<Dimension>;
  ean: Scalars['String']['output'];
  lifecycles: Array<Lifecycle>;
  materialId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  openOrderInfo?: Maybe<OpenOrderInfo>;
  piecesPerCasePack: Scalars['Int']['output'];
  price: Price;
  recommendedRetailPrice?: Maybe<Money>;
  stockStatus: StockStatus;
  tags: Array<ProductTag>;
  theme: Scalars['String']['output'];
  weight?: Maybe<Weight>;
};

export type TermsOfSales = {
  __typename?: 'TermsOfSales';
  buttonText: Scalars['String']['output'];
  checkboxText: Scalars['String']['output'];
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type TopThemeFacet = {
  __typename?: 'TopThemeFacet';
  hitsCount: Scalars['Int']['output'];
  subThemes: Array<SubThemeFacet>;
  title: Scalars['String']['output'];
  totalCount: Scalars['Int']['output'];
};

export type UpcomingDelivery = {
  __typename?: 'UpcomingDelivery';
  expectedDeliveryDate: OrderItemDeliveryDate;
  itemName: Scalars['String']['output'];
  itemNumber?: Maybe<Scalars['Int']['output']>;
  materialId: Scalars['Int']['output'];
  orderName: Scalars['String']['output'];
  orderNumber: Scalars['Int']['output'];
  orderedPieces?: Maybe<Scalars['Int']['output']>;
  shipTo: ShipTo;
};

export type UpcomingDeliveryList = {
  __typename?: 'UpcomingDeliveryList';
  totalCount: Scalars['Int']['output'];
  upcomingDeliveries: Array<UpcomingDelivery>;
};

export type UpcomingDeliverySearchFilter = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  itemQuery?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderQuery?: InputMaybe<Scalars['String']['input']>;
  shipToIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shipToQuery?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCartItemInput = {
  materialId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type User = {
  __typename?: 'User';
  customers: Array<Customer>;
  email: Scalars['String']['output'];
  preferencesForCustomers?: Maybe<Array<UserPreferencesForCustomer>>;
};

export type UserPreferencesForCustomer = {
  __typename?: 'UserPreferencesForCustomer';
  customerId: Scalars['Int']['output'];
  preferredLanguage?: Maybe<Language>;
};

export type UserPreferencesForCustomerInput = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
  preferredLanguage?: InputMaybe<LanguageInput>;
};

export type ValidItem = {
  __typename?: 'ValidItem';
  cartReference?: Maybe<CartReference>;
  product: Product;
  quantity: Scalars['Int']['output'];
};

export type Weight = {
  __typename?: 'Weight';
  amount: Scalars['Float']['output'];
  unit: WeightUnit;
};

export enum WeightUnit {
  Kg = 'KG',
  Lb = 'LB'
}

export enum WindowType {
  Future = 'FUTURE',
  Open = 'OPEN',
  Previous = 'PREVIOUS'
}
