import { ExtraOptions } from '@lego/b2b-unicorn-data-access-layer/context/GenericContextTypes';
import { deepEqual } from 'fast-equals';
import { GraphQLFormattedError } from 'graphql/index';
import { useEffect, useMemo, useState } from 'react';

import { useDataAccessLayer } from '../DataAccessLayerProvider';
import { useDataAccessLayerMutation } from '../helpers/useDataAccessLayerMutation';
import { useDataAccessLayerQuery } from '../helpers/useDataAccessLayerQuery';

export const useLaunchWindowsSummary = (customerId: number, extraOptions?: ExtraOptions) => {
  const dataAccessLayer = useDataAccessLayer();
  const [stableExtraOptions, setStableExtraOptions] = useState(extraOptions);

  useEffect(() => {
    if (!deepEqual(extraOptions, stableExtraOptions)) {
      setStableExtraOptions(extraOptions);
    }
  }, [extraOptions, stableExtraOptions]);

  const rawDataAccessLayer = useMemo(() => {
    return dataAccessLayer.launchWindows.getWindowsOverview(customerId, {
      fetchPolicy: 'network-only',
      ...stableExtraOptions,
    });
  }, [customerId, dataAccessLayer, stableExtraOptions]);

  return useDataAccessLayerQuery(rawDataAccessLayer);
};

export const useLaunchWindow = (customerId: number, year: number, month: number) => {
  const dataAccessLayer = useDataAccessLayer();
  const queryResult = useMemo(() => {
    return dataAccessLayer.launchWindows.getLaunchWindow(customerId, year, month, {
      fetchPolicy: 'network-only',
    });
  }, [dataAccessLayer.launchWindows, customerId, month, year]);

  return useDataAccessLayerQuery(queryResult);
};

export const useOptimisticUpdateLaunchWindow = (
  customerId: number,
  year: number,
  month: number,
  onError?: (
    error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
  ) => void
) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.launchWindows.updateLaunchWindow(customerId, year, month, true, onError);
  }, [dataAccessLayer.launchWindows, customerId, year, month, onError]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};

export const useOptimisticEmptyLaunchWindow = (customerId: number, year: number, month: number) => {
  const dataAccessLayer = useDataAccessLayer();
  const lazyMutation = useMemo(() => {
    return dataAccessLayer.launchWindows.emptyLaunchWindow(customerId, year, month, true);
  }, [customerId, dataAccessLayer.launchWindows, month, year]);

  return useDataAccessLayerMutation(lazyMutation);
};

export const useOptimisticUpdateLaunchWindowWithMultiple = (
  customerId: number,
  year: number,
  month: number,
  optimistic?: boolean,
  onError?: (
    error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
  ) => void
) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.launchWindows.updateLaunchWindowWithMultipleItems(
      customerId,
      year,
      month,
      optimistic,
      onError
    );
  }, [customerId, dataAccessLayer.launchWindows, month, year, onError, optimistic]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};
