import {
  Customer,
  Lifecycle,
  ProductTag,
  StockStatusTag,
} from '@lego/b2b-unicorn-data-access-layer';
import { formatCurrency } from '@lego/b2b-unicorn-shared/helpers';

import { FilterType } from '../../../constants';

const convertStringifiedArrayToValues = <Output>(stringifiedArray: string | null) => {
  let result;
  if (stringifiedArray?.includes('[') && stringifiedArray.includes(']')) {
    result = stringifiedArray
      .slice(1, -1)
      .split(',')
      .map((item) => decodeURI(item));
  } else {
    result = stringifiedArray?.split(',').map((item) => decodeURI(item));
  }
  return (result || []) as Output;
};

export const getProductsFiltersFromUrl = (urlParams: string) => {
  const queryParams = new URLSearchParams(urlParams);
  const themes = queryParams.get(FilterType.THEMES);
  const lifecycles = queryParams.get(FilterType.LIFECYCLES);
  const priceRanges = queryParams.get(FilterType.PRICE_RANGES);
  const availabilities = queryParams.get(FilterType.AVAILABILITIES);
  const tags = queryParams.get(FilterType.TAGS);
  const query = queryParams.get(FilterType.QUERY);

  return {
    themes: convertStringifiedArrayToValues<string[]>(themes),
    lifecycles: convertStringifiedArrayToValues<Lifecycle[]>(lifecycles),
    availabilities: convertStringifiedArrayToValues<StockStatusTag[]>(availabilities),
    priceRanges: convertStringifiedArrayToValues<string[]>(priceRanges),
    tags: convertStringifiedArrayToValues<ProductTag[]>(tags),
    query: query ? decodeURI(query) : '',
  };
};

export const getProductsPriceRangeOptionName = (
  priceRange: {
    id: string;
    from: number;
    to?: number | null;
    totalCount: number;
    hitsCount: number;
  },
  selectedCustomer: Customer
) => {
  const from = formatCurrency(
    selectedCustomer.locale,
    priceRange.from,
    selectedCustomer.currency || ''
  );

  const to = priceRange.to
    ? formatCurrency(selectedCustomer.locale, priceRange.to, selectedCustomer?.currency || '')
    : '*';

  return `${from} - ${to}`;
};
