import { useContext } from 'react';

import { AuthenticationContext } from '../components/BootstrapAuthentication';

export const useAuthentication = () => {
  const authenticationContext = useContext(AuthenticationContext);
  if (!authenticationContext) {
    throw Error(`No AuthenticationContext found!`);
  }

  return authenticationContext;
};
