import { css, keyframes } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { colors, media } from '@lego/b2b-unicorn-ui-constants';

import SkeletonInfoTile from './SkeletonInfoTile';

const pulse = keyframes`
  100% {transform: translateX(100%)}
`;

const containerStyle = css({
  position: 'relative',
  overflow: 'hidden',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    top: 0,
    width: '100%',
    height: '100%',
    transform: 'translateX(-100%)',
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${designToken.transparent.default} 30%, ${colors.klik.slate10} 50%,${designToken.transparent.default} 70%, ${designToken.transparent.default} 100%)`,
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    [media.medium]: {
      animationDuration: '1.8s',
    },
  },
});

const gradientStyle = css({
  height: '100%',
  width: '100%',
  position: 'relative',
  '&:before': {
    zIndex: 10,
    position: 'absolute',
    display: 'block',
    content: '""',
    width: '100vw',
    height: 'calc(100vh - 120px)',
    top: 0,
    background: `linear-gradient(transparent 0%, transparent 40%, ${colors.klik.slate50} 93%)`,
    [media.small]: {
      height: 'calc(100vh - 200px)',
    },
    [media.medium]: {
      height: '100%',
    },
    [media.large]: {
      height: 920,
    },
  },
});

const notMobileStyle = css({
  display: 'none',
  [media.small]: {
    display: 'block',
  },
});

const SkeletonInfoList = () => {
  return (
    <div css={containerStyle}>
      <div css={gradientStyle}>
        <div css={notMobileStyle}>
          <SkeletonInfoTile numberOfFiles={1} />
          <SkeletonInfoTile numberOfFiles={2} />
        </div>
        <SkeletonInfoTile numberOfFiles={1} />
        <SkeletonInfoTile numberOfFiles={2} />
      </div>
    </div>
  );
};

export default SkeletonInfoList;
