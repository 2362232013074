import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';

import { CheckoutStep } from '../common/types';

const activeColor = designToken.primary.default;
const inactivePrimaryColor = tokens.color.core.gray[400];
const inactiveSecondaryColor = tokens.color.core.gray[100];
const stepsContainerStyle = css({
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
  width: '100%',
  minHeight: 120,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [media.small]: {
    paddingLeft: baseSpacing * 4,
    paddingRight: baseSpacing * 4,
  },
});

const numberStyle = {
  color: inactivePrimaryColor,
  borderRadius: '50%',
  height: baseSpacing * 4,
  width: baseSpacing * 4,
  paddingBottom: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
};

const defaultStepsItemStyle = css({
  fontSize: font.size.medium,
  fontWeight: font.weight.normal,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '> span:first-of-type': {
    ...numberStyle,
    color: inactivePrimaryColor,
    backgroundColor: designToken.background.offWhite,
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: inactiveSecondaryColor,
    marginRight: 0,
    '> svg': {
      height: 20,
      width: 20,
      fill: colors.white,
    },

    [media.medium]: {
      marginRight: baseSpacing,
    },
  },
  '> span:last-of-type': {
    display: 'none',
  },

  [media.medium]: {
    '> span:last-of-type': {
      display: 'block',
      color: inactivePrimaryColor,
    },
  },
});

const activeStepsItemStyle = css(defaultStepsItemStyle, {
  '> span: first-of-type': {
    ...numberStyle,
    color: activeColor,
    borderColor: activeColor,
    marginRight: baseSpacing,
  },
  '> span:last-of-type': {
    display: 'block',
    color: activeColor,
  },
});

const finishedStepsItemStyle = css(defaultStepsItemStyle, {
  '> span: first-of-type': {
    ...numberStyle,
    color: colors.white,
    backgroundColor: activeColor,
    border: 'none',
    marginRight: baseSpacing,
  },
});

const dashStyle = css({
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: inactiveSecondaryColor,
  width: 290,
  height: 0,
  display: 'block',
  marginLeft: baseSpacing,
  marginRight: baseSpacing,
  borderRadius: 4,

  [media.small]: {
    marginLeft: baseSpacing * 4,
    marginRight: baseSpacing * 4,
  },
});

const activeDashStyle = css(dashStyle, {
  borderColor: activeColor,
});

interface ICheckoutSteps {
  checkoutStep: CheckoutStep;
}

const CheckoutSteps = ({ checkoutStep }: ICheckoutSteps) => {
  const { cart, button_checkout, confirmation } = useLabels();

  return (
    <div css={stepsContainerStyle}>
      <div
        css={checkoutStep === CheckoutStep.PREVIEW ? activeStepsItemStyle : finishedStepsItemStyle}
      >
        <span>
          {checkoutStep === CheckoutStep.PREVIEW ? '1' : <Icon type={IconType.CONTROL_CHECK} />}
        </span>
        <span>{cart}</span>
      </div>
      <span css={checkoutStep === CheckoutStep.PREVIEW ? dashStyle : activeDashStyle}></span>
      <div
        css={
          checkoutStep === CheckoutStep.CHECKOUT
            ? activeStepsItemStyle
            : checkoutStep === CheckoutStep.PREVIEW
              ? defaultStepsItemStyle
              : finishedStepsItemStyle
        }
      >
        <span>2</span>
        <span>{button_checkout}</span>
      </div>
      <span css={checkoutStep === CheckoutStep.CONFIRMATION ? activeDashStyle : dashStyle}></span>
      <div
        css={
          checkoutStep === CheckoutStep.CONFIRMATION ? activeStepsItemStyle : defaultStepsItemStyle
        }
      >
        <span>3</span>
        <span>{confirmation}</span>
      </div>
    </div>
  );
};

export default CheckoutSteps;
