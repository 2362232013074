import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React, { useMemo } from 'react';

import { baseSpacing, font } from '../theme';

type TableHeaderProps = {
  children?: React.ReactNode;
  width?: number;
  align?: React.CSSProperties['textAlign'];
  sortable?: boolean;
  sticky?: boolean;
};

const tableHeaderStyle: Parameters<typeof css>[0] = {
  fontWeight: font.weight.bold,
  background: tokens.color.core.gray[25],
  height: 50,
  padding: baseSpacing,
  textAlign: 'left',
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: tokens.color.core.gray[50],
};

const tableHeaderSortableStyle: Parameters<typeof css>[0] = {
  display: 'flex',
  alignItems: 'center',
  '> button': {
    marginRight: baseSpacing / 2,
  },
};

export const TableHeaderCell: React.FC<TableHeaderProps> = ({
  children,
  width,
  align,
  sortable,
  sticky,
}) => {
  const cssStyle = useMemo(() => {
    const style: Parameters<typeof css>[0] = {};
    Object.assign(style, tableHeaderStyle);
    if (width) {
      Object.assign(style, {
        width,
      });
    }
    if (align) {
      Object.assign(style, {
        textAlign: align,
      });
    }

    if (sticky) {
      Object.assign(style, {
        position: 'sticky',
        top: 0,
        zIndex: 1,
      });
    }

    return css(style);
  }, [align, sticky, width]);

  const cssSortableStyle = useMemo(() => {
    if (!sortable) {
      return css({});
    }
    const style: Parameters<typeof css>[0] = {};
    Object.assign(style, tableHeaderSortableStyle);
    if (align === 'right') {
      Object.assign(style, {
        justifyContent: 'end',
      });
    }

    return css(style);
  }, [align, sortable]);

  return (
    <th css={cssStyle}>
      {!sortable && children}
      {sortable && <div css={cssSortableStyle}>{children}</div>}
    </th>
  );
};
