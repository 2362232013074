import { deepEqual } from 'fast-equals';
import React from 'react';
import type { CellBase } from 'react-spreadsheet';

import { Icon, IconType } from '../../../../ui/Icon';
import { baseSpacing } from '../../../../ui/theme';
import { useBulkQuickAddContext } from '../../BulkQuickAddContext';

type DeleteCellProps = {
  cell?: CellBase<number>;
};

export const DeleteCell: React.FC<DeleteCellProps> = React.memo(
  ({ cell }) => {
    const { removeRow } = useBulkQuickAddContext();

    const handleOnClick = () => {
      if (cell && cell.value) {
        removeRow(cell.value - 1);
      }
    };

    if (!cell || !cell.value) {
      return null;
    }

    return (
      <div css={{ position: 'relative' }}>
        <Icon
          type={IconType.DELETE_OUTLINE}
          onClick={handleOnClick}
          width={baseSpacing * 2}
        />
        <Icon
          type={IconType.DELETE}
          onClick={handleOnClick}
          width={baseSpacing * 2}
        />
      </div>
    );
  },
  (prevProps, nextProps) => deepEqual(prevProps.cell, nextProps.cell)
);
DeleteCell.displayName = 'DeleteCell';
