import { css } from '@emotion/react';
import { Counter } from '@lego/b2b-unicorn-shared/components/Counter';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { CART_MAX_ITEM_QUANTITY } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const customStyle = css({
  width: '50%',
  marginRight: baseSpacing,
});

type ProductTileCounterProps = {
  disabled: boolean;
  quantity: number;
  setQuantity: (quantity: number) => void;
};

const ProductTileCounter: React.FC<ProductTileCounterProps> = ({
  disabled,
  quantity,
  setQuantity,
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(e.target.value));
  };

  return (
    <Counter
      value={quantity}
      disabled={disabled}
      onChange={handleOnChange}
      customStyle={customStyle}
      maxValue={CART_MAX_ITEM_QUANTITY}
      minValue={1}
      testDataName="product-tile-counter-input"
    />
  );
};

export default ProductTileCounter;
