import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ReplenishmentCartProduct } from '@lego/b2b-unicorn-data-access-layer/context/Replenishment/ReplenishmentDataContext';
import {
  useMaterialIdInCart,
  useOptimisticUpdateProductQuantityReplenishmentCart,
  useReplenishmentCartRefetch,
} from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { NotificationType, useNotifications } from '@lego/b2b-unicorn-ui-components';
import { font } from '@lego/b2b-unicorn-ui-constants';
import React, { Fragment, useCallback, useMemo } from 'react';

const snackbarProductNameStyle = css({
  fontWeight: font.weight.bold,
  display: '-webkit-box',
  WebkitLineClamp: '1',
  overflow: 'hidden',
});

type ProductTileProduct = ReplenishmentCartProduct;

export const useProductTile = <P extends ProductTileProduct>(
  product: P,
  beforeAddToCart: (product: P) => void
) => {
  const selectedCustomer = useSelectedCustomer();
  const { snackbar_add_product_error } = useLabels();
  const refetchCart = useReplenishmentCartRefetch(selectedCustomer.id, { fetchPolicy: 'no-cache' });
  const { mutate: updateQuantity } = useOptimisticUpdateProductQuantityReplenishmentCart(
    selectedCustomer.id,
    product
  );
  const inCart = useMaterialIdInCart(selectedCustomer.id, product.materialId, {
    fetchPolicy: 'cache-only',
  });
  const { addSnackbar, snackbar } = useNotifications();

  const addToCart = useCallback(
    (quantity: number) => {
      beforeAddToCart(product);

      updateQuantity({
        quantity: inCart.quantity + quantity,
      }).catch(() => {
        const errorText = (
          <Fragment>
            <span css={snackbarProductNameStyle}>
              {product.materialId} — {product.name}
            </span>{' '}
            {snackbar_add_product_error}
          </Fragment>
        );

        addSnackbar({
          type: NotificationType.WARNING,
          content: errorText,
          showDismissButton: true,
          isStacked: !!snackbar,
        });
        refetchCart();
      });
    },
    [
      addSnackbar,
      beforeAddToCart,
      inCart,
      refetchCart,
      product,
      snackbar_add_product_error,
      updateQuantity,
      selectedCustomer,
    ]
  );

  return useMemo(
    () => ({
      addToCart,
    }),
    [addToCart]
  );
};
