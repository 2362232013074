import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import type {
  UpdateUserPreferencesForCustomerMutation,
  UpdateUserPreferencesForCustomerMutationVariables,
} from '../../generated-types/graphql';
import { useDataAccessLayer } from '../DataAccessLayerProvider';

export const useUpdateUserPreferencesForCustomer = () => {
  const dataAccessLayer = useDataAccessLayer();
  const lazyMutation = useMemo(() => {
    return dataAccessLayer.user.updateUserPreferencesForCustomerLazy();
  }, [dataAccessLayer.user]);

  return useMutation<
    UpdateUserPreferencesForCustomerMutation,
    UpdateUserPreferencesForCustomerMutationVariables
  >(lazyMutation.mutation, {
    client: dataAccessLayer.apolloClient,
  });
};
