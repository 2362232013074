import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, media } from '../theme';

const tilesGridStyle = css({
  all: 'unset',
  display: 'grid',
  gap: baseSpacing * 2,
  justifyContent: 'center',
  gridTemplateColumns: `repeat(auto-fill, 340px)`,
  marginTop: baseSpacing * 2,
  '> li': {
    all: 'unset',
    zIndex: 1,
  },
  [media.small]: {
    justifyContent: 'start',
  },
});

type TileGridProps = {
  children: React.ReactNode;
};
export const TileGrid: React.FC<TileGridProps> = ({ children }) => {
  return <ul css={tilesGridStyle}>{children}</ul>;
};
