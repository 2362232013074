import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';
import { LEGOLogo } from '@lego/klik-ui';

const LogoGraphic = () => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        textDecoration: 'none',
      }}
    >
      <LEGOLogo boxSize={{ base: '32px', md: '48px' }} />
      <p
        css={{
          fontWeight: font.weight.black,
          fontSize: font.size.small_mobile,
          marginLeft: baseSpacing,
        }}
      >
        B2B Webshop
      </p>
    </div>
  );
};

export default LogoGraphic;
