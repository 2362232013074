export enum StockStatusTag {
  Available = 'AVAILABLE',
  ExpectedBackInStock = 'EXPECTED_BACK_IN_STOCK',
  LowStock = 'LOW_STOCK',
  NotAvailable = 'NOT_AVAILABLE',
  OutOfStock = 'OUT_OF_STOCK',
}

export interface IStockStatus {
  status: StockStatusTag;
  expectedBackDate?: string | null;
}
