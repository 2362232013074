import { deepEqual } from 'fast-equals';
import { GraphQLFormattedError } from 'graphql/index';
import { cloneDeep } from 'lodash';
import { hash } from 'ohash';
import { distinctUntilChanged, finalize, Subject } from 'rxjs';

import { CartItemInputWithProduct } from '../../';
import {
  ReplenishmentCartSummaryQuery,
  ReplenishmentCartSummaryQueryVariables,
  ReplenishmentProductQuery,
  ReplenishmentProductQuery_product,
  ReplenishmentProductQueryVariables,
  ReplenishmentProductsQuery,
  ReplenishmentProductsQuery_products_ProductList_products,
  ReplenishmentProductsQueryVariables,
  ReplenishmentUpdateCartWithMultipleItemsMutation,
  ReplenishmentUpdateCartWithMultipleItemsMutationVariables,
  UpdateReplenishmentCartMutation,
  UpdateReplenishmentCartMutationVariables,
} from '../../generated-types/graphql';
import { ProductListFilterInput } from '../../generated-types/types';
import { exhaustiveSwitchCheck, ExtractElementType } from '../../utils/TypeScriptHelpers';
import { ContextAbstract, MutationObservable } from '../ContextAbstract';
import { ExtraOptions } from '../GenericContextTypes';
import {
  ReplenishmentProductBillOfMaterial,
  ReplenishmentProductBox,
  ReplenishmentProductsBillOfMaterial,
  ReplenishmentProductsBox,
  ReplenishmentProductsSupplementary,
  ReplenishmentProductSupplementary,
} from './entities';
import { REPLENISHMENT_UPDATE_CART_WITH_MULTIPLE_ITEMS } from './mutations/replenishmentUpdateCartWithMultipleItems';
import { UPDATE_REPLENISHMENT_CART } from './mutations/updateCart';
import { REPLENISHMENT_CART_SUMMARY } from './queries/replenishmentCartSummary';
import { REPLENISHMENT_PRODUCT } from './queries/replenishmentProduct';
import { REPLENISHMENT_PRODUCTS } from './queries/replenishmentProducts';

type CartItem = ExtractElementType<ReplenishmentCartSummaryQuery['getCart']['items']>;
export type ReplenishmentCartProduct = CartItem['product'];

export type ReplenishmentProductsProduct =
  | ReplenishmentProductsSupplementary
  | ReplenishmentProductsBillOfMaterial
  | ReplenishmentProductsBox;
export {
  ReplenishmentProductBillOfMaterial,
  ReplenishmentProductBox,
  ReplenishmentProductsBillOfMaterial,
  ReplenishmentProductsBox,
  ReplenishmentProductsSupplementary,
  ReplenishmentProductSupplementary,
};
export type ReplenishmentProductsMetadataFacets =
  ReplenishmentProductsQuery['products']['metadata']['facets'];

interface ReplenishmentProductsMappedQueryProducts
  extends Omit<ReplenishmentProductsQuery['products'], 'products'> {
  products: Array<ReplenishmentProductsProduct>;
}
interface ReplenishmentProductsMappedQuery extends Omit<ReplenishmentProductsQuery, 'products'> {
  products: ReplenishmentProductsMappedQueryProducts;
}

export type ReplenishmentProduct =
  | ReplenishmentProductBox
  | ReplenishmentProductSupplementary
  | ReplenishmentProductBillOfMaterial;

interface ReplenishmentProductMappedQuery extends Omit<ReplenishmentProductQuery, 'product'> {
  product?: ReplenishmentProduct | null;
}
export class ReplenishmentDataContext extends ContextAbstract {
  public updateProductQuantityReplenishmentCart(
    customerId: number,
    product: ReplenishmentCartProduct,
    removeObsoleteItems: boolean = false,
    optimistic: boolean = false
  ): MutationObservable<UpdateReplenishmentCartMutation, { quantity: number }> {
    if (!this.cartPolicy) {
      throw new Error('ReplenishmentDataContext has not been created with a type policy!');
    }

    const observableKey = `updateReplenishmentCart-${customerId}-${product.materialId}`;
    const [mutate, reset, results] = this.mutationObservable<
      UpdateReplenishmentCartMutation,
      UpdateReplenishmentCartMutationVariables
    >(observableKey, UPDATE_REPLENISHMENT_CART, 'materialId');
    const updateCartOptimistic = this.cartPolicy.updateCartOptimistic.bind(this);

    return [
      ({ quantity }) => {
        if (optimistic) {
          updateCartOptimistic(product, quantity, {
            query: REPLENISHMENT_CART_SUMMARY,
            variables: {
              customerId,
              removeObsoleteItems,
            },
          });
        }

        return mutate({
          customerId,
          materialId: product.materialId,
          quantity,
        });
      },
      reset,
      results,
    ];
  }

  public updateReplenishmentCart(
    customerId: number,
    removeObsoleteItems: boolean = false,
    optimistic: boolean = false
  ): MutationObservable<
    UpdateReplenishmentCartMutation,
    { quantity: number; productOrMaterialId: number | ReplenishmentCartProduct }
  > {
    if (!this.cartPolicy) {
      throw new Error('ReplenishmentDataContext has not been created with a type policy!');
    }

    const observableKey = `updateReplenishmentCart-${customerId}`;

    const [mutate, reset, results] = this.mutationObservable<
      UpdateReplenishmentCartMutation,
      UpdateReplenishmentCartMutationVariables
    >(observableKey, UPDATE_REPLENISHMENT_CART, 'materialId');
    const updateCartOptimistic = this.cartPolicy.updateCartOptimistic.bind(this);

    return [
      ({ quantity, productOrMaterialId }) => {
        if (optimistic && typeof productOrMaterialId !== 'number') {
          updateCartOptimistic(productOrMaterialId, quantity, {
            query: REPLENISHMENT_CART_SUMMARY,
            variables: {
              customerId,
              removeObsoleteItems,
            },
          });
        }

        return mutate({
          customerId,
          materialId:
            typeof productOrMaterialId === 'number'
              ? productOrMaterialId
              : productOrMaterialId.materialId,
          quantity,
        });
      },
      reset,
      results,
    ];
  }

  public replenishmentCartSummary(customerId: number, extraOptions?: ExtraOptions) {
    const observableKey = `replenishmentCartSummary-${customerId}`;
    return this.queryObservable<
      ReplenishmentCartSummaryQuery,
      ReplenishmentCartSummaryQueryVariables
    >(observableKey, REPLENISHMENT_CART_SUMMARY, { customerId }, extraOptions);
  }

  public materialIdInCart(customerId: number, materialId: number, extraOptions?: ExtraOptions) {
    const subject = new Subject<{ found: boolean; quantity: number }>();
    const apolloSubscription = this._apolloClient
      .watchQuery<ReplenishmentCartSummaryQuery, ReplenishmentCartSummaryQueryVariables>({
        query: REPLENISHMENT_CART_SUMMARY,
        variables: {
          customerId,
        },
        ...extraOptions,
      })
      .subscribe((result) => {
        if (!result || !result.data || !result.data.getCart || !result.data.getCart.items) {
          subject.next({
            found: false,
            quantity: 0,
          });

          return;
        }

        const itemInCart = result.data.getCart.items.find(
          (i) => i.product.materialId === materialId
        );

        subject.next({
          found: !!itemInCart,
          quantity: itemInCart ? itemInCart.quantity : 0,
        });
      });
    return subject.pipe(
      distinctUntilChanged(deepEqual),
      finalize(() => apolloSubscription.unsubscribe())
    );
  }

  public isCartEmpty(customerId: number, extraOptions?: ExtraOptions) {
    return this._apolloClient
      .watchQuery<ReplenishmentCartSummaryQuery, ReplenishmentCartSummaryQueryVariables>({
        query: REPLENISHMENT_CART_SUMMARY,
        variables: {
          customerId,
        },
        ...extraOptions,
      })
      .map((result) => {
        return (
          !result ||
          !result.data ||
          !result.data.getCart ||
          !result.data.getCart.items ||
          result.data.getCart.items.length === 0
        );
      });
  }

  static ReplenishmentProductsProductDtoToEntity(
    p: ReplenishmentProductsQuery_products_ProductList_products
  ) {
    switch (p.__typename) {
      case 'Box':
        return new ReplenishmentProductsBox(p);
      case 'BillOfMaterial':
        return new ReplenishmentProductsBillOfMaterial(p);
      case 'Supplementary':
        return new ReplenishmentProductsSupplementary(p);
      default:
        exhaustiveSwitchCheck(p.__typename);
    }
  }

  public products(
    customerId: number,
    filters?: ProductListFilterInput,
    noInitialTrigger?: boolean,
    extraOptions?: ExtraOptions
  ) {
    const observableKey = !extraOptions
      ? `replenishmentProducts-${customerId}`
      : `replenishmentProducts-${customerId}-${hash(extraOptions)}`;

    return this.queryObservable<
      ReplenishmentProductsQuery,
      ReplenishmentProductsQueryVariables,
      ReplenishmentProductsQueryVariables,
      ReplenishmentProductsMappedQuery
    >(
      observableKey,
      REPLENISHMENT_PRODUCTS,
      { customerId, filters },
      {
        noInitialTrigger,
        queryMapper: (data) => {
          const clonedData = cloneDeep(data);
          return {
            ...clonedData,
            products: {
              ...clonedData.products,
              products: clonedData.products.products.map((p) => {
                return ReplenishmentDataContext.ReplenishmentProductsProductDtoToEntity(p);
              }),
            },
          };
        },
        ...extraOptions,
      }
    );
  }

  static ReplenishmentProductDtoToEntity(p: ReplenishmentProductQuery_product) {
    switch (p.__typename) {
      case 'Box':
        return new ReplenishmentProductBox(p);
      case 'BillOfMaterial':
        return new ReplenishmentProductBillOfMaterial(p);
      case 'Supplementary':
        return new ReplenishmentProductSupplementary(p);
      default:
        exhaustiveSwitchCheck(p.__typename);
    }
  }

  public product(customerId: number, materialId: number, noInitialTrigger?: boolean) {
    const observableKey = `replenishmentProduct-${customerId}-${materialId}`;

    return this.queryObservable<
      ReplenishmentProductQuery,
      ReplenishmentProductQueryVariables,
      void,
      ReplenishmentProductMappedQuery
    >(
      observableKey,
      REPLENISHMENT_PRODUCT,
      {
        customerId,
        materialId,
      },
      {
        noInitialTrigger,
        queryMapper: (data) => {
          const clonedData = cloneDeep(data);
          if (!clonedData || !clonedData.product) {
            return {
              ...clonedData,
            };
          }
          return {
            ...clonedData,
            product: ReplenishmentDataContext.ReplenishmentProductDtoToEntity(clonedData.product),
          };
        },
      }
    );
  }

  public updateCartWithMultipleItems(
    customerId: number,
    optimistic: boolean = false,
    onError?: (
      error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
    ) => void
  ): MutationObservable<
    ReplenishmentUpdateCartWithMultipleItemsMutation,
    { items: CartItemInputWithProduct[] }
  > {
    if (!this.cartPolicy) {
      throw new Error('ReplenishmentDataContext has not been created with a type policy!');
    }

    const observableKey = `updateCartWithMultipleItems-${customerId}-REPLENISH`;
    const [mutate, reset, results] = this.mutationObservable<
      ReplenishmentUpdateCartWithMultipleItemsMutation,
      ReplenishmentUpdateCartWithMultipleItemsMutationVariables
    >(observableKey, REPLENISHMENT_UPDATE_CART_WITH_MULTIPLE_ITEMS, undefined, onError);

    const updateCartWithMultipleOptimistic =
      this.cartPolicy.updateCartWithMultipleOptimistic.bind(this);

    return [
      ({ items }) => {
        if (optimistic) {
          updateCartWithMultipleOptimistic(items, {
            query: REPLENISHMENT_CART_SUMMARY,
            variables: {
              customerId,
            },
          });
        }

        return mutate({
          customerId,
          items: items.map((item) => ({
            materialId: item.product.materialId,
            quantity: item.quantity,
          })),
        });
      },
      reset,
      results,
    ];
  }
}
