import { ApolloError } from '@apollo/client';
import {
  ProductTag,
  ReplenishmentProductsMetadataFacets,
  ReplenishmentProductsProduct,
} from '@lego/b2b-unicorn-data-access-layer';
import { useReplenishmentProducts } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useStateWithUrl } from '@lego/b2b-unicorn-ui-utils';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  DefaultProductListSorting,
  FROM,
  PRODUCT_PER_PAGE,
  ProductListSortingOption,
  URL_SEARCH_PARAM,
} from '../../constants';
import { IProductSearchAndFilterModel } from './types';
import { getProductsFiltersFromUrl, sortProductList } from './utils';

interface IProductListContext {
  productsLoading: boolean;
  productsError: boolean;
  productsResponse: boolean;
  productListItems: ReplenishmentProductsProduct[];
  filtersList: ReplenishmentProductsMetadataFacets;
  selectedFilters?: IProductSearchAndFilterModel;
  setSelectedFilters: (filters: IProductSearchAndFilterModel | undefined) => void;
  showRecommendedProductsSwitch: boolean;
  showRareItemsSwitch: boolean;
  loadMoreVisible: boolean;
  materialIdMarker: number | null;
  handleSortingChange: (value: ProductListSortingOption | ProductListSortingOption[]) => void;
  handleProductTagsChange: (value: ProductTag) => void;
  removeAllTags: () => void;
  loadMore: () => void;
}
interface IProductListProviderProps {
  onProductsLoaded?: () => void;
  children: React.ReactNode;
}

const ProductListContext = createContext<IProductListContext | undefined>(undefined);

export const useProductListContext = () => {
  const context = React.useContext(ProductListContext);
  if (!context) {
    throw new Error('useProductListContext must be used within a ProductListProvider');
  }
  return context;
};

// Variables to handle scrolling to previously clicked product when navigating back to this page:
let visitedProduct = 0;
let productsToShowOnFirstRender = PRODUCT_PER_PAGE;

export const ProductListProvider: React.FC<IProductListProviderProps> = ({
  onProductsLoaded,
  children,
}) => {
  const location = useLocation();
  const history = useHistory();

  const selectedCustomer = useSelectedCustomer();
  const [productListItems, setProductListItems] = useState<ReplenishmentProductsProduct[]>([]);
  const [filtersList, setFiltersList] = useState<ReplenishmentProductsMetadataFacets>({
    themes: [],
    availabilities: [],
    lifecycles: [],
    priceRanges: [],
  });
  const [selectedFilters, setSelectedFilters] = useStateWithUrl<IProductSearchAndFilterModel>({
    arrayOnlyParams: ['tags', 'themes', 'availabilities', 'lifecycles', 'priceRanges'],
  });
  const [numberOfVisibleProducts, setNumberOfVisibleProducts] = useState(
    productsToShowOnFirstRender
  );
  const [materialIdMarker, setMaterialIdMarker] = useState<number>(visitedProduct);

  const {
    data,
    error,
    loading,
    refetch: fetchProducts,
  } = useReplenishmentProducts(selectedCustomer.id, selectedFilters, true);

  // This approach to showRecommendedProductsToggle and showRareItemsToggle will not work if we implement pagination on products.
  // If that happens, we should implement tags in metadata.facets (which has been skipped or forgotten)
  const showRecommendedProductsSwitch =
    (data?.products &&
      data.products.products.length > 0 &&
      data.products.products.some((product) =>
        product.tags.includes(ProductTag.RecommendedAssortment)
      )) ||
    selectedFilters?.tags?.includes(ProductTag.RecommendedAssortment) ||
    false;
  const showRareItemsSwitch =
    (data?.products &&
      data.products.products.length > 0 &&
      data.products.products.some((product) => product.tags.includes(ProductTag.RareItem))) ||
    selectedFilters?.tags?.includes(ProductTag.RareItem) ||
    false;

  const handleSortingChange = (value: ProductListSortingOption | ProductListSortingOption[]) => {
    const updatedFilters = { ...selectedFilters };
    if (value === DefaultProductListSorting) {
      delete updatedFilters.sort;
    } else {
      if (!Array.isArray(value)) {
        updatedFilters.sort = value;
      }
    }
    setSelectedFilters(updatedFilters);
  };

  const handleProductTagsChange = (value: ProductTag) => {
    const updatedFilters = { ...selectedFilters };
    if (!updatedFilters.tags) {
      updatedFilters.tags = [];
    }
    if (updatedFilters.tags.includes(value)) {
      updatedFilters.tags.splice(updatedFilters.tags.indexOf(value), 1);
    } else {
      updatedFilters.tags.push(value);
    }
    setSelectedFilters(updatedFilters);
  };

  const removeAllTags = useCallback(() => {
    let updatedFilters = undefined;
    if (selectedFilters?.sort) {
      updatedFilters = { sort: selectedFilters.sort };
    }
    if (selectedFilters?.tags) {
      updatedFilters = { ...updatedFilters, tags: selectedFilters.tags };
    }

    setSelectedFilters(updatedFilters);
  }, [selectedFilters, setSelectedFilters]);

  useEffect(() => {
    fetchProducts({
      customerId: selectedCustomer.id,
      filters: getProductsFiltersFromUrl(location.search),
    }).finally(() => {
      onProductsLoaded && onProductsLoaded();
    });
  }, [fetchProducts, selectedCustomer, location, onProductsLoaded]);

  useEffect(() => {
    // Updating the variable to use when navigating back to this page (with the browsers back arrow) after visiting the PDP.
    productsToShowOnFirstRender = numberOfVisibleProducts;
  }, [numberOfVisibleProducts]);

  useEffect(() => {
    productsToShowOnFirstRender = PRODUCT_PER_PAGE;

    const listenerUnregisterCallback = history.listen((location) => {
      // Listen to browser history to catch the materialId of the product the user navigated to.
      const urlSplit = location.pathname.split('/catalog/');
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.get(URL_SEARCH_PARAM.FROM) !== FROM.RECOMMENDATIONS && urlSplit.length > 1) {
        // User has navigated to PDP. Save materialId in this scope:
        visitedProduct = parseInt(urlSplit[1]);
        setMaterialIdMarker(visitedProduct);
      }
    });

    return () => {
      // Unregister the listener when unmounting this page:
      // (Basically this means only listening to the first page change, which is fine.)
      listenerUnregisterCallback();
    };
  }, []);

  useEffect(() => {
    if (data && data.products) {
      const sortedProducts = sortProductList(
        [...data.products.products],
        selectedFilters?.sort || DefaultProductListSorting
      );

      setProductListItems(sortedProducts.slice(0, numberOfVisibleProducts));
      setFiltersList(data.products.metadata.facets);
    }
  }, [data, numberOfVisibleProducts, location, selectedFilters]);

  useEffect(() => {
    if (!error || !(error instanceof ApolloError)) {
      return;
    }
    if (error.graphQLErrors.some((error) => error.extensions?.code === 'BAD_USER_INPUT')) {
      removeAllTags();
    }
  }, [error, removeAllTags]);

  const loadMoreVisible = data
    ? productListItems.length > 0 && numberOfVisibleProducts < data?.products?.products?.length
    : false;

  const loadMore = () => {
    setNumberOfVisibleProducts(numberOfVisibleProducts + PRODUCT_PER_PAGE);
  };

  return (
    <ProductListContext.Provider
      value={{
        productsResponse: !!data,
        productsError: !!error,
        productsLoading: loading,
        productListItems,
        filtersList,
        selectedFilters,
        setSelectedFilters,
        showRecommendedProductsSwitch,
        showRareItemsSwitch,
        loadMoreVisible,
        materialIdMarker,
        handleSortingChange,
        handleProductTagsChange,
        removeAllTags,
        loadMore,
      }}
    >
      {children}
    </ProductListContext.Provider>
  );
};
