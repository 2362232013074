import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { OpenLaunchWindow } from '@lego/b2b-unicorn-data-access-layer';
import { DateTime } from '@lego/b2b-unicorn-shared/components/Date';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { designToken, Tile, TileBottom, TileButton } from '@lego/b2b-unicorn-shared/ui';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import { differenceInDays } from 'date-fns';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { orderIndicatorStyle } from '../styles';
import { LaunchWindowTileHeader } from './LaunchWindowTileHeader';

const deadlineStyle = (warning: boolean) =>
  css({
    color: warning ? designToken.error.emphasis : colors.klik.slate500,
    lineHeight: 1.1,
  });

export const OpenLaunchWindowTile = ({
  openLaunchWindow,
}: {
  openLaunchWindow: OpenLaunchWindow;
}) => {
  const {
    launch_window_deadline_text,
    launch_window_finish_order,
    launch_window_new_order,
    launch_window_order_now,
    launch_window_existing_orders,
  } = useLabels();
  const selectedCustomer = useSelectedCustomer();
  const isActive = true;
  const isDeadlineDateClose =
    differenceInDays(new Date(openLaunchWindow.orderEndInstant), new Date()) < 14;
  const ordersCreatedCount = openLaunchWindow.ordersCreated.length || 0;

  const openWindowLinkLabel = useMemo(() => {
    if (openLaunchWindow.cartHasItems) {
      return launch_window_finish_order;
    } else {
      return ordersCreatedCount === 0 ? launch_window_new_order : launch_window_order_now;
    }
  }, [
    launch_window_finish_order,
    launch_window_new_order,
    launch_window_order_now,
    openLaunchWindow.cartHasItems,
    ordersCreatedCount,
  ]);

  const history = useHistory();
  const handleClick = () => {
    history.push(`/launch/${openLaunchWindow.year}/${openLaunchWindow.month}`);
  };

  return (
    <Tile
      isActive={isActive}
      onClick={handleClick}
    >
      <LaunchWindowTileHeader
        month={openLaunchWindow.month}
        year={openLaunchWindow.year}
        productsCount={openLaunchWindow.productsCount}
        isActive={isActive}
      />
      <TileBottom>
        <div>
          {openLaunchWindow.orderEndInstant && (
            <span css={deadlineStyle(isDeadlineDateClose)}>
              {launch_window_deadline_text}:{' '}
              <DateTime locale={selectedCustomer.locale}>
                {openLaunchWindow.orderEndInstant}
              </DateTime>
            </span>
          )}
          <span css={orderIndicatorStyle}>
            {ordersCreatedCount > 0 && `${ordersCreatedCount} ${launch_window_existing_orders}`}
          </span>
        </div>
        <TileButton withAlert={openLaunchWindow.cartHasItems}>{openWindowLinkLabel}</TileButton>
      </TileBottom>
    </Tile>
  );
};
