import { wrap } from 'comlink';

import type { imageConversionWorker } from './imageConversion.worker';

export const imageConversion = async (
  file: File,
  conversionOpts: Parameters<(typeof imageConversionWorker)['convert']>[1]
): Promise<Blob> => {
  // Create a conversion worker
  const worker = new Worker(new URL('./imageConversion.worker.ts', import.meta.url), {
    type: 'module',
  });
  const conversionWorker = wrap<typeof imageConversionWorker>(worker);

  const convertedFile = await conversionWorker.convert(file, conversionOpts);

  worker.terminate();

  return convertedFile;
};
