import { Money as MoneyType, Price as PriceType } from '@lego/b2b-unicorn-data-access-layer';
import React from 'react';

import { Price } from '../../components/Price';
import { AfterPrice } from './AfterPrice';
import { BeforePrice } from './BeforePrice';

type DiscountedPriceProps = {
  children: PriceType | MoneyType;
  locale: string;
  multiplier?: number;
};

export const DiscountedPrice: React.FC<DiscountedPriceProps> = ({
  children: priceOrMoneyObject,
  locale,
  multiplier = 1,
}) => {
  return (
    <Price
      locale={locale}
      multiplier={multiplier}
      wrappers={{
        nipPrice: AfterPrice,
        grossPrice: BeforePrice,
      }}
      priceTypes={multiplier === 0 ? ['nipPrice'] : ['grossPrice', 'nipPrice']}
    >
      {priceOrMoneyObject}
    </Price>
  );
};
