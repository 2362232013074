import { css } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import { useCallback, useEffect, useMemo, useState } from 'react';

const goTopWrapperStyle = (isVisible: boolean, loadMoreVisible: boolean) =>
  css({
    zIndex: isVisible ? 10 : -1,
    position: 'sticky',
    height: 65,
    width: 74,
    bottom: 20,
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: loadMoreVisible ? -90 : -106,
    marginBottom: 40,
    overflow: 'hidden',
    [media.small]: {
      width: 90,
    },
  });

const topButtonStyle = (isVisible: boolean) =>
  css({
    height: 60,
    width: 60,
    borderRadius: '50%',
    borderWidth: 2,
    borderColor: colors.white,
    borderStyle: 'solid',
    backgroundColor: designToken.interactive.default,
    opacity: 0.8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transitionProperty: 'opacity, transform',
    transitionDuration: '250ms',
    cursor: 'pointer',
    boxShadow: `0 10px 4px -6px ${colors.klik.lightBlue200}`,
    transform: isVisible ? 'translate(0, 0)' : 'translate(160px, 0)',
    '> svg': { fill: colors.white, marginBottom: 4 },
    '> *': {
      height: baseSpacing * 3,
      width: 'auto',
      padding: 0,
    },
    '&:hover': {
      opacity: 1,
    },
    '&:focus': {
      outline: 'none',
    },
  });

interface IGoToTop {
  loadMoreVisible?: boolean;
}

const GoToTop = ({ loadMoreVisible = false }: IGoToTop) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const listener = () => {
      window.pageYOffset > 500 ? setIsVisible(true) : setIsVisible(false);
    };
    document.addEventListener('scroll', listener);
    return () => document.removeEventListener('scroll', listener);
  }, []);

  const gotoWrapperCss = useMemo(
    () => goTopWrapperStyle(isVisible, loadMoreVisible),
    [isVisible, loadMoreVisible]
  );
  const buttonCss = useMemo(() => topButtonStyle(isVisible), [isVisible]);

  const goToTop = useCallback(() => {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div css={gotoWrapperCss}>
      <button
        css={buttonCss}
        onClick={goToTop}
      >
        <Icon type={IconType.CHEVRON_UP} />
      </button>
    </div>
  );
};

export default GoToTop;
