import type { CSSInterpolation } from '@emotion/serialize';
import type { Customer } from '@lego/b2b-unicorn-data-access-layer';
import { List, ListItem } from '@lego/b2b-unicorn-shared/ui';
import React, { useMemo } from 'react';

type SelectCustomerProps = {
  customers: Customer[];
  onCustomerClick: (customer: Customer) => void;
  extraCss?: CSSInterpolation;
};
export const SelectCustomer = ({ customers, onCustomerClick, extraCss }: SelectCustomerProps) => {
  const sortedCustomers = useMemo(() => {
    const clonedCustomers = JSON.parse(JSON.stringify(customers)) as Customer[];
    return clonedCustomers
      .sort((customer, nextCustomer) => {
        if (!customer.name || !nextCustomer.name) {
          return 0;
        }

        return customer.name.toLowerCase() > nextCustomer.name.toLowerCase() ? 1 : -1;
      })
      .reduce((acc, customer) => {
        if (Array.isArray(acc) && !acc.find((c) => c.id === customer.id)) {
          acc.push(customer);
        }

        return acc;
      }, [] as Customer[]);
  }, [customers]);

  return (
    <List extraCss={extraCss}>
      {sortedCustomers.map((customer) => (
        <ListItem
          key={`customer-select-${customer.id}`}
          onClick={() => onCustomerClick(customer)}
        >
          <>
            {`${customer.id} - ${customer.name} - `}
            <wbr />
            {customer.city}
          </>
        </ListItem>
      ))}
    </List>
  );
};
