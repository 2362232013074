import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font } from '@lego/b2b-unicorn-ui-constants';

export const disabledButtonStyle = (pending: boolean) =>
  css({
    '&:disabled': {
      pointerEvents: 'none',
      color: pending ? colors.klik.lightBlue200 : colors.klik.slate300,
      '> svg': {
        fill: colors.klik.slate300,
      },
    },
  });

export const infoListItemHoverStyle = css({
  '&:hover, :focus': {
    color: designToken.interactive.hover,
    outline: 'none',
    '> svg': {
      fill: designToken.interactive.hover,
    },
  },
});

export const infoListItemStyle = css({
  display: 'flex',
  flexDirection: 'row',
  fontSize: 16,
  fontWeight: font.weight.normal,
  color: designToken.interactive.default,
  background: designToken.transparent.default,
  width: '100%',
  textAlign: 'left',
  border: 'none',
  padding: 0,
  marginBottom: baseSpacing * 2,
  cursor: 'pointer',
  transitionProperty: 'color',
  transitionDuration: '120ms',
  '> span': {
    lineHeight: 1.2,
  },
  '> svg': {
    fill: designToken.interactive.default,
    height: 24,
    width: 24,
    minWidth: 24,
    marginRight: baseSpacing,
    marginTop: -3,
    transitionProperty: 'fill',
    transitionDuration: '120ms',
  },
});
