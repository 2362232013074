import { css } from '@emotion/react';
import React from 'react';

import { FlexBox } from '../FlexBox';
import { Spacer } from '../Spacer';
import { baseSpacing, font } from '../theme';
import { useModalContext } from './ModalContext';

const modalHeaderStyle = css({
  fontSize: font.size.large,
  fontWeight: font.weight.light,
  lineHeight: 1.25,
  marginBottom: baseSpacing,
  display: 'inline-flex',
  '> svg': {
    height: 36,
    width: 36,
    marginRight: baseSpacing,
    transform: 'translateY(-2px)',
  },
});

type ModalHeaderProps = {
  children: React.ReactNode;
  subHeader?: React.ReactNode;
};
export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, subHeader }) => {
  const modalContext = useModalContext();

  return (
    <FlexBox
      flexDirection={'column'}
      ref={modalContext.setModalHeaderRef}
    >
      <div css={modalHeaderStyle}>{children}</div>
      {subHeader}
      {!modalContext.condensed && <Spacer />}
    </FlexBox>
  );
};
