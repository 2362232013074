export enum HumanReadableDateRange {
  TODAY = 'TODAY',
  LAST_SEVEN_DAYS = 'LAST_SEVEN_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
  LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  NEXT_SEVEN_DAYS = 'NEXT_SEVEN_DAYS',
  NEXT_14_DAYS = 'NEXT_14_DAYS',
  NEXT_30_DAYS = 'NEXT_30_DAYS',
}

export interface DateRange {
  fromDate: string;
  toDate: string;
}

export const dateRangeFromHumanReadable = (
  humanReadableDateRange?: HumanReadableDateRange | null
): DateRange => {
  if (!humanReadableDateRange) {
    return {
      toDate: '',
      fromDate: '',
    };
  }
  const to = new Date();
  const from = new Date();
  switch (humanReadableDateRange) {
    case HumanReadableDateRange.TODAY:
      break;
    case HumanReadableDateRange.LAST_SEVEN_DAYS:
      from.setDate(from.getDate() - 7);
      break;
    case HumanReadableDateRange.LAST_30_DAYS:
      from.setDate(from.getDate() - 30);
      break;
    case HumanReadableDateRange.LAST_THREE_MONTHS:
      from.setMonth(from.getMonth() - 3);
      break;
    case HumanReadableDateRange.LAST_SIX_MONTHS:
      from.setMonth(from.getMonth() - 6);
      break;
    case HumanReadableDateRange.LAST_12_MONTHS:
      from.setMonth(from.getMonth() - 12);
      break;
    case HumanReadableDateRange.NEXT_SEVEN_DAYS:
      to.setDate(to.getDate() + 7);
      break;
    case HumanReadableDateRange.NEXT_14_DAYS:
      to.setDate(to.getDate() + 14);
      break;
    case HumanReadableDateRange.NEXT_30_DAYS:
      to.setDate(to.getDate() + 30);
      break;
    default:
      throw Error(`Invalid HumanReadableDateRange.`);
  }
  const toDate = to.toISOString().split('T')[0];
  const fromDate = from.toISOString().split('T')[0];
  return {
    fromDate,
    toDate,
  };
};

interface FilterWithDateRange {
  dateRange?: HumanReadableDateRange;
}

export type FiltersInURL<T> = Omit<T, 'toDate' | 'fromDate'> & FilterWithDateRange;
