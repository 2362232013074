import { css } from '@emotion/react';
import { UpdateCartInMultipleWindowsResultItem } from '@lego/b2b-unicorn-data-access-layer';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import {
  idWidthStyle,
  nameWidthStyle,
  priceWidthStyle,
  quantityWidthStyle,
  themeWidthStyle,
} from './tableStyles';

const tableRowStyle = (showRow: boolean) =>
  css({
    display: 'none',
    td: {
      display: 'table-cell',
      borderBottomWidth: 1,
      borderBottomColor: colors.klik.slate100,
      padding: baseSpacing,
    },
    [media.medium]: {
      display: showRow ? 'table-row' : 'none',
      position: 'initial',
      height: 50,
    },
  });

const numberCellStyle = css({
  fontFeatureSettings: '"tnum"',
  fontVariantNumeric: 'tabular-nums',
});

const numberRightCellStyle = css(numberCellStyle, {
  textAlign: 'right',
});

const idStyle = css(numberCellStyle, {
  fontWeight: font.weight.bold,
  textAlign: 'left',
});

interface SuccessfulBulkCartUpdateSummaryItemRowProps {
  quantity: UpdateCartInMultipleWindowsResultItem['quantity'];
  product: UpdateCartInMultipleWindowsResultItem['product'];
  showRow: boolean;
}

export const SuccessfulBulkCartUpdateSummaryItemRow: React.FC<
  SuccessfulBulkCartUpdateSummaryItemRowProps
> = ({ quantity, product, showRow = true }) => {
  const { locale } = useSelectedCustomer();

  return (
    <tr css={tableRowStyle(showRow)}>
      <td css={[idStyle, idWidthStyle]}>{product.__typename === 'Box' && product.itemNumber}</td>
      <td css={nameWidthStyle}>{product.name}</td>
      <td css={themeWidthStyle}>{product.theme}</td>
      <td css={[numberRightCellStyle, quantityWidthStyle]}>{quantity}</td>
      <td css={[numberRightCellStyle, priceWidthStyle]}>
        <Price
          locale={locale}
          multiplier={quantity * product.piecesPerCasePack}
        >
          {product.price}
        </Price>
      </td>
    </tr>
  );
};
