export function removeEmptyFromObject<T = object>(obj: T) {
  const cleanObj = {} as T;
  for (const key in obj) {
    const currentValue = obj[key];
    if (
      (Array.isArray(currentValue) && currentValue.length === 0) ||
      (typeof currentValue === 'string' && currentValue === '')
    ) {
      continue;
    }

    Object.defineProperty(cleanObj, key, {
      value: obj[key],
      writable: false,
      enumerable: true,
    });
  }

  return cleanObj;
}
