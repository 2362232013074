import { useDataAccessLayer } from '@lego/b2b-unicorn-data-access-layer/react';
import { useEffect, useRef } from 'react';

import { useSelectedCustomer, useUserPreferences } from '../';

export const useOnPreferredLanguageChange = (queriesToRefetch: string[]) => {
  const { apolloClient } = useDataAccessLayer();

  const { preferredLanguage } = useUserPreferences();
  const prevPreferredLanguage = useRef(preferredLanguage);

  const selectedCustomer = useSelectedCustomer();
  const prevSelectedCustomer = useRef(selectedCustomer);

  useEffect(() => {
    if (prevSelectedCustomer.current.id !== selectedCustomer.id) {
      prevSelectedCustomer.current = selectedCustomer;
      prevPreferredLanguage.current.id = preferredLanguage.id;
      return;
    }

    if (prevPreferredLanguage.current.id !== preferredLanguage.id) {
      apolloClient.refetchQueries({
        include: queriesToRefetch,
      });
      prevPreferredLanguage.current = preferredLanguage;
    }
  }, [apolloClient, preferredLanguage, queriesToRefetch, selectedCustomer]);
};
