import { gql } from '@apollo/client';

export const EMPTY_CART = gql`
  mutation emptyCart($customerId: Int!, $cartReference: CartReferenceInput!) {
    emptyCart(customerId: $customerId, cartReference: $cartReference) {
      id
      items {
        ...minimumCartItem
      }
      ...cartKey
    }
  }
`;
