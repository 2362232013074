import { mergeDeep } from '@apollo/client/utilities';
import { DataAccessLayer, Promotion, PromotionProduct } from '@lego/b2b-unicorn-data-access-layer';
import { ObservableResult } from '@lego/b2b-unicorn-data-access-layer/context/ContextAbstract';
import {
  PromotionQuery_promotion_PromotionDetail_productConditions_PromotionProductCondition,
  UpdateCartMutation_Mutation,
} from '@lego/b2b-unicorn-data-access-layer/generated-types/graphql';
import { CartMath } from '@lego/b2b-unicorn-ui-utils';
import { deepEqual } from 'fast-equals';
import { GraphQLFormattedError } from 'graphql/index';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  Subscription,
} from 'rxjs';

export type ProductConditionResult = {
  materialId: number;
  valid: boolean;
};

const promotionDetailsModelInstances = new Map<string, PromotionDetailsModel>();

export class PromotionDetailsModel {
  /*
   * Holds latest value of checkoutPossible state which is used to determine if the user can proceed to checkout
   */
  private _checkoutPossible: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /*
   * Holds latest value of promotion state
   */
  private _promotion: BehaviorSubject<Promotion | null | undefined> = new BehaviorSubject<
    Promotion | null | undefined
  >(undefined);
  /*
   * Holds latest value of isLoading state, which is used to determine if the model is still loading data
   * or if the data is ready to be displayed. Updating the cart does not trigger this loading state
   */
  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  /*
   * Holds latest results of product conditions validation, currently only contains the failed product conditions
   */
  private _productConditionsResults = new BehaviorSubject<ProductConditionResult[]>([]);
  /*
   * Holds latest value of initialCartDoesNotFulfillProductConditions state, which is used to determine if the cart
   * is initialized and does not fulfill the product conditions on initial load of the promotion
   */
  private _initialCartDoesNotFulfillProductConditions = new BehaviorSubject<boolean>(false);
  /*
   * Holds latest value of error state, if any error occurs during the model lifecycle
   */
  private _error = new BehaviorSubject<
    Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[] | null
  >(null);

  private _dataAccessLayerClient: DataAccessLayer;
  private _innerSubscription: Subscription | null = null;
  private _isInitialized = false;
  private _initializing = false;
  private readonly _customerId: number;
  private readonly _promotionId: number;

  public result: Observable<{
    checkoutPossible: boolean;
    promotion: Promotion | null | undefined;
    isLoading: boolean;
    productConditionsResults: ProductConditionResult[];
    error:
      | Error
      | readonly Error[]
      | GraphQLFormattedError
      | readonly GraphQLFormattedError[]
      | null;
    initialCartDoesNotFulfillProductConditions: boolean;
  }>;

  public refetch: ReturnType<typeof DataAccessLayer.prototype.promotions.getPromotion>[0] | null =
    null;

  constructor(dataAccessLayerClient: DataAccessLayer, customerId: number, promotionId: number) {
    this._dataAccessLayerClient = dataAccessLayerClient;
    this._customerId = customerId;
    this._promotionId = promotionId;

    /*
     * Combine all the BehaviorSubjects into a single Observable that emits the latest values of all the BehaviorSubjects
     * so only one subscription is needed to get all the values
     */
    this.result = combineLatest([
      this._checkoutPossible,
      this._promotion,
      this._isLoading,
      this._productConditionsResults,
      this._initialCartDoesNotFulfillProductConditions,
      this._error,
    ]).pipe(
      map(
        ([
          checkoutPossible,
          promotion,
          isLoading,
          productConditionsResults,
          initialCartDoesNotFulfillProductConditions,
          error,
        ]) => ({
          checkoutPossible,
          promotion,
          isLoading,
          productConditionsResults,
          initialCartDoesNotFulfillProductConditions,
          error,
        })
      ),
      distinctUntilChanged(deepEqual),
      debounceTime(50)
    );
  }

  /*
   * Subscribe to the promotion and cart data from the data access layer
   */
  public subscribe() {
    const [refetch, , response] = this._dataAccessLayerClient.promotions.getPromotion(
      this._customerId,
      this._promotionId
    );

    this.refetch = refetch;

    this._innerSubscription = combineLatest([
      response,
      this._dataAccessLayerClient.promotions.isUpdatingPromotionCart,
    ]).subscribe({
      next: async ([result, isUpdatingCart]) => {
        // If the model is still initializing, we don't want to do anything
        if (this._initializing) {
          return;
        }

        // If the model is not initialized, and we got data from data access layer, we want to check if we need to initialize the promotion
        if (!this._isInitialized && result.data) {
          this._initializing = true;
          // Check if the promotion has any productConditions and if the cart is empty
          const isInitializationNeeded = this.isInitializationNeeded(result.data);
          if (isInitializationNeeded) {
            // If the promotion has productConditions and the cart is empty, we need to initialize the promotion
            await this.initialize(result.data);
          } else {
            // If the promotion has productConditions and the cart is not empty, we need to validate the cart against the product conditions
            this._initialCartDoesNotFulfillProductConditions.next(
              this.validateProductConditions(result.data).length > 0
            );
          }
          this._initializing = false;
          this._isInitialized = true;
        }

        // When ever we get new data from the data access layer, we want to validate the promotion cart
        this.validationSteps(result.data);

        // If the cart is updating, we want to disable the checkout button
        if (isUpdatingCart) {
          this._checkoutPossible.next(false);
        }

        // Update the BehaviorSubjects with the new data
        this._promotion.next(result.data);
        this._isLoading.next(result.loading);
        this._error.next(result.error);
      },
      error: (error) => {
        this._error.next(error);
      },
    });
  }

  public static instance(
    dataAccessLayerClient: DataAccessLayer,
    customerId: number,
    promotionId: number
  ): PromotionDetailsModel {
    const cacheKey = `${customerId}-${promotionId}`;
    if (!promotionDetailsModelInstances.has(cacheKey)) {
      promotionDetailsModelInstances.set(
        cacheKey,
        new PromotionDetailsModel(dataAccessLayerClient, customerId, promotionId)
      );
    }

    const model = promotionDetailsModelInstances.get(cacheKey)!;
    model.subscribe();

    return model;
  }

  /*
   * If a promotion has any productConditions and there is no items in the promotion cart, we need to initialize the promotion
   */
  private async initialize(promotion: Promotion) {
    // Initialize promotion logic
    const cartUpdates = [];
    for (const productCondition of promotion.promotion.productConditions!) {
      if (!productCondition.minimumCasePacks) {
        continue;
      }

      const product = this.findProductInPromotionByProductCondition(promotion, productCondition);

      cartUpdates.push({ product, quantity: productCondition.minimumCasePacks });
    }
    if (cartUpdates.length > 0) {
      const [updateCart] = this._dataAccessLayerClient.promotions.updatePromotionWithMultipleItems(
        this._customerId,
        this._promotionId,
        true
      );
      const results = await updateCart({ items: cartUpdates });
      if (
        results.data?.updateCartWithMultipleItems.items &&
        results.data?.updateCartWithMultipleItems.items.length > 0
      ) {
        Object.assign(promotion, {
          getCart: {
            ...promotion.getCart,
            items: mergeDeep(
              promotion.getCart.items,
              results.data.updateCartWithMultipleItems.items
            ),
          },
        });
      }
    }
  }

  private isInitializationNeeded(promotion: Promotion): boolean {
    if (
      !promotion ||
      !promotion.promotion ||
      !promotion.promotion.productConditions ||
      promotion.getCart.items.length > 0
    ) {
      return false;
    }

    return true;
  }

  private findProductInPromotionByProductCondition(
    promotion: Promotion,
    productCondition: PromotionQuery_promotion_PromotionDetail_productConditions_PromotionProductCondition
  ) {
    const product = promotion.promotion.products.find(
      (p) => p.materialId === productCondition.materialId
    );
    if (!product) {
      throw new Error('There is a product condition without a product');
    }
    return product;
  }

  /*
   * Validate the promotion cart against product conditions of the promotion and the minimum order value
   */
  private validationSteps(promotion: Promotion | null | undefined): void {
    if (!promotion) {
      this._checkoutPossible.next(false);
      return;
    }

    const productConditionResults = this.validateProductConditions(promotion);
    const aboveMinimumOrderValue = this.validateMOV(promotion);

    this._checkoutPossible.next(productConditionResults.length === 0 && aboveMinimumOrderValue);
  }

  private validateMOV(promotion: Promotion) {
    const totalPrice = CartMath.sumEstimatedNetInvoicedPrice(
      promotion.getCart.items
    ).estimatedNetInvoicedPrice;
    return totalPrice > 0 ? totalPrice >= promotion.getCart.minimumOrderValue.amount : false;
  }

  /*
   * Validate the promotion cart against product conditions of the promotion
   */
  private validateProductConditions(promotion: Promotion) {
    const productConditionResults: ProductConditionResult[] = [];
    if (promotion.promotion.productConditions && promotion.promotion.productConditions.length > 0) {
      for (const productCondition of promotion.promotion.productConditions) {
        if (!productCondition.minimumCasePacks) {
          continue;
        }

        const product = promotion.promotion.products.find(
          (p) => p.materialId === productCondition.materialId
        );
        if (!product) {
          continue;
        }

        const itemInCart = promotion.getCart.items.find(
          (item) => item.product.materialId === product.materialId
        );
        if (!itemInCart || itemInCart.quantity < productCondition.minimumCasePacks) {
          this._checkoutPossible.next(false);
          productConditionResults.push({
            materialId: productCondition.materialId,
            valid: false,
          });
        }
      }

      this._productConditionsResults.next(productConditionResults);
    }

    return productConditionResults;
  }

  /*
   * Add a product to the cart
   */
  public updateCart(
    product: PromotionProduct,
    quantity: number
  ): Promise<ObservableResult<UpdateCartMutation_Mutation>> {
    const [updateCart] = this._dataAccessLayerClient.promotions.updatePromotionProductInCart(
      this._customerId,
      this._promotionId,
      product,
      true,
      (error) => this._error.next(error)
    );
    return updateCart({ quantity });
  }

  /*
   * Reset the cart by removing all items from the cart and adding the minimumCasePacks of each productCondition to the cart
   */
  public async resetCart() {
    this._isLoading.next(true);
    const [emptyCart] = this._dataAccessLayerClient.promotions.emptyPromotionCart(
      this._customerId,
      this._promotionId,
      false,
      (error) => this._error.next(error)
    );
    await emptyCart();
    const emptyCartPromotion = {
      ...this._promotion.getValue()!,
      getCart: {
        ...this._promotion.getValue()!.getCart,
        items: [],
      },
    };

    if (this.isInitializationNeeded(emptyCartPromotion)) {
      await this.initialize(emptyCartPromotion);
    }
    this._isLoading.next(false);
  }

  /*
   * Dispose the model, clean up the started subscriptions
   */
  public dispose(): void {
    this._innerSubscription?.unsubscribe();
  }
}
