import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing } from '../theme';

const tileBottomStyle = css({
  height: 100,
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  textAlign: 'left',
  borderStyle: 'solid',
  borderTop: 'none',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  paddingTop: baseSpacing / 2,
  paddingLeft: baseSpacing,
  paddingBottom: 10,
  paddingRight: baseSpacing,
});

type TileBottomProps = {
  children: React.ReactNode;
};

export const TileBottom: React.FC<TileBottomProps> = ({ children }) => {
  return <div css={tileBottomStyle}>{children}</div>;
};
