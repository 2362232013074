import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { usePromotion } from '@lego/b2b-unicorn-data-access-layer/react';
import { Date as DateComponent, DateTime } from '@lego/b2b-unicorn-shared/components/Date';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  AnimatedDots,
  Container,
  GoBackLink,
  Heading,
  Paragraph,
  Spacer,
  Typography,
} from '@lego/b2b-unicorn-shared/ui';
import { NotificationBanner } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import { isAfter } from 'date-fns/isAfter';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { PromotionConditionsSummary } from './PromotionConditionsSummary';

type PromotionDetailsHeaderProps = {
  promotionId: number;
  warningMessage?: string;
  backToOverviewButton?: boolean;
};
export const PromotionDetailsHeader: React.FC<PromotionDetailsHeaderProps> = ({
  promotionId,
  warningMessage,
  backToOverviewButton,
}) => {
  const {
    button_go_to_overview,
    promotions_execution_dates,
    promotions_delivery_dates,
    launch_window_deadline_text,
  } = useLabels();
  const selectedCustomer = useSelectedCustomer();
  const { data, loading } = usePromotion(selectedCustomer.id, promotionId);

  const history = useHistory();

  const handleGoBackClick = () => {
    history.push('/promotion');
  };

  const orderDeadlineDate = data?.promotion.orderDeadlineDate;
  const isOrderDeadlineClose =
    orderDeadlineDate && !isAfter(new Date(), new Date(orderDeadlineDate));

  return (
    <Container
      padding={{
        paddingTop: baseSpacing,
        paddingBottom: baseSpacing * 4,
        paddingRight: baseSpacing * 2,
        paddingLeft: baseSpacing * 2,
      }}
      paddingMedium={{
        [media.medium]: {
          paddingRight: baseSpacing * 2,
          paddingLeft: baseSpacing * 2,
        },
      }}
      flex={{ flexDirection: 'column' }}
    >
      <Spacer multiplier={2} />
      <GoBackLink
        onClick={handleGoBackClick}
        text={button_go_to_overview}
        visibility={backToOverviewButton ? 'visible' : 'hidden'}
      />
      <Spacer multiplier={4} />
      {!data && loading && (
        <>
          <Spacer multiplier={9} />
          <AnimatedDots
            lightDesignToken={'information.subdued'}
            darkDesignToken={'information.default'}
            mediumSize
          />
          <Spacer multiplier={9} />
        </>
      )}
      {data && (
        <Container
          flexMedium={{
            [media.medium]: { flexDirection: 'row', justifyContent: 'space-between' },
          }}
          flex={{ flexDirection: 'column', gap: baseSpacing * 2 }}
        >
          <div>
            <Heading level={1}>{data.promotion.description}</Heading>
            <Container
              flex={{ gap: baseSpacing * 2 }}
              padding={{ paddingBottom: 0, paddingLeft: 0 }}
            >
              <div>
                <Paragraph
                  fontWeight={'semibold'}
                  fontSize={'small'}
                >
                  {promotions_execution_dates}:
                  <Spacer multiplier={2} />
                  {promotions_delivery_dates}:
                  {isOrderDeadlineClose && (
                    <Typography
                      weight={'bold'}
                      designToken={'text.informative'}
                    >
                      <Spacer multiplier={2} />
                      {launch_window_deadline_text}:
                    </Typography>
                  )}
                </Paragraph>
              </div>
              <div>
                <Paragraph fontSize={'small'}>
                  <DateComponent locale={selectedCustomer.locale}>
                    {data.promotion.executionDates.startDate}
                  </DateComponent>{' '}
                  &ndash;{' '}
                  <DateComponent locale={selectedCustomer.locale}>
                    {data.promotion.executionDates.endDate}
                  </DateComponent>
                  <Spacer multiplier={2} />
                  <DateComponent locale={selectedCustomer.locale}>
                    {data.promotion.deliveryDates.earliestDate}
                  </DateComponent>{' '}
                  &ndash;{' '}
                  <DateComponent locale={selectedCustomer.locale}>
                    {data.promotion.deliveryDates.latestDate}
                  </DateComponent>
                  {isOrderDeadlineClose && (
                    <Typography
                      weight={'bold'}
                      designToken={'text.informative'}
                    >
                      <Spacer multiplier={2} />
                      <DateTime locale={selectedCustomer.locale}>{orderDeadlineDate}</DateTime>
                    </Typography>
                  )}
                </Paragraph>
              </div>
            </Container>
            {warningMessage && (
              <>
                <NotificationBanner message={warningMessage} />
                <Spacer multiplier={1} />
              </>
            )}
          </div>
          <PromotionConditionsSummary promotionId={promotionId} />
        </Container>
      )}
    </Container>
  );
};
