import { css, keyframes } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ButtonType, designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import { useEffect, useRef, useState } from 'react';

const addButtonStyle = (buttonType: ButtonType.PRIMARY | ButtonType.SECONDARY) =>
  css({
    fontSize: font.size.medium,
    fontWeight: font.weight.normal,
    color:
      buttonType === ButtonType.PRIMARY
        ? tokens.color.core.gray[10]
        : designToken.interactive.default,
    backgroundColor:
      buttonType === ButtonType.PRIMARY ? designToken.interactive.default : 'transparent',
    height: baseSpacing * 5,
    width: '50%',
    border:
      buttonType === ButtonType.PRIMARY ? 'none' : `solid 2px ${designToken.interactive.default}`,
    borderRadius: 4,
    padding: 0,
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    transitionProperty: 'all',
    transitionDuration: '125ms',
    '&:hover:enabled': {
      backgroundColor:
        buttonType === ButtonType.PRIMARY
          ? designToken.interactive.hover
          : tokens.color.core.azur[75],
    },
    '&:focus': {
      outline: 'none',
    },
    '&::before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: designToken.success.emphasis,
      top: 60,
      left: 0,
    },
  });

const disabledAddButtonStyle = (buttonType: ButtonType.PRIMARY | ButtonType.SECONDARY) =>
  css(addButtonStyle(buttonType), {
    color: tokens.color.core.gray[800],
    backgroundColor:
      buttonType === ButtonType.PRIMARY ? tokens.color.core.gray[300] : 'transparent',
    border: buttonType === ButtonType.PRIMARY ? 'none' : `solid 2px ${tokens.color.core.gray[300]}`,
  });

const iconStyle = css({
  strokeWidth: 2,
  width: 24,
  height: 24,
  position: 'absolute',
  left: 70,
  transform: 'translateY(60px)',
  zIndex: 10,
  '> svg': {
    fill: colors.white,
  },
});

const background = keyframes`
  0% { top: 60px;  }
  15% { top: 0; }
  85% { top: 0; }
  100% { top: -60px; }
`;

const success = keyframes`
  0% { transform: translateY(60px); }
  10% { transform: translateY(3px); }
  15% { transform: translateY(0); }
  80% { transform: translateY(0); }
  85% { transform: translateY(3px); }
  100% { transform: translateY(60px); }
`;

const addButtonAnimatedStyle = (buttonType: ButtonType.PRIMARY | ButtonType.SECONDARY) =>
  css(addButtonStyle(buttonType), {
    '&::before': {
      animationName: background,
      animationDuration: '1.4s',
      animationDirection: 'forwards',
      animationTimingFunction: 'ease-in-out',
    },
  });

const iconAnimatedStyle = css(iconStyle, {
  animationName: success,
  animationDuration: '1.4s',
  animationDirection: 'forwards',
  animationTimingFunction: 'cubic-bezier(0,.47,.99,.6)',
});

type AddButtonProps = {
  disabled: boolean;
  handleOnClick?: () => void;
  buttonType?: ButtonType.PRIMARY | ButtonType.SECONDARY;
};
const AddButton = ({
  disabled,
  handleOnClick,
  buttonType = ButtonType.PRIMARY,
}: AddButtonProps) => {
  const [animate, setAnimate] = useState(false);
  const [addingBlock, setAddingBlock] = useState(false);
  const { button_add } = useLabels();
  const addingBlockTimeout = useRef<ReturnType<typeof setTimeout>>();

  const onClickHandler = () => {
    setAnimate(true);
    setAddingBlock(true);
    handleOnClick && handleOnClick();
  };

  useEffect(() => {
    clearTimeout(addingBlockTimeout.current);
    if (addingBlock) {
      addingBlockTimeout.current = setTimeout(() => {
        setAddingBlock(false);
      }, 1400);
    }
    return () => clearTimeout(addingBlockTimeout.current);
  }, [addingBlock]);

  useEffect(() => {
    disabled && setAnimate(false);
  }, [disabled]);

  return disabled ? (
    <button css={disabledAddButtonStyle(buttonType)}>{button_add}</button>
  ) : (
    <button
      css={animate ? addButtonAnimatedStyle(buttonType) : addButtonStyle(buttonType)}
      type="submit"
      onClick={onClickHandler}
      onAnimationEnd={() => {
        setAnimate(false);
      }}
      disabled={addingBlock}
      tabIndex={-1}
    >
      <span>{button_add}</span>
      <span css={animate ? iconAnimatedStyle : iconStyle}>
        <Icon type={IconType.CHECK} />
      </span>
    </button>
  );
};

export default AddButton;
