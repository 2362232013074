import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React, { Fragment } from 'react';

import { commonProductTileStyle } from '../../../../styles';
import { THEME_TILE_HEIGHT } from './ThemeTile';

const skeletonTileStyle = css(commonProductTileStyle, {
  height: THEME_TILE_HEIGHT,
  background: tokens.color.core.blue[100],
});

export const SkeletonThemes: React.FC = () => {
  return (
    <Fragment>
      {[...Array(8)].map((_, i) => {
        return (
          <div
            css={skeletonTileStyle}
            key={i}
          />
        );
      })}
    </Fragment>
  );
};
