import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, mediaSizes } from '@lego/b2b-unicorn-ui-constants';

export const pageAlignmentStyle = css({
  width: '100%',
  flexGrow: 1,
  maxWidth: mediaSizes.large,
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
});

export const basicPageStyle = css(pageAlignmentStyle, {
  paddingTop: 0,
  paddingBottom: baseSpacing * 4,
  color: designToken.text.default,
});
