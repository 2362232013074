import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { font } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import React, { useEffect, useState } from 'react';

import { commonProductTileStyle } from '../../../../styles';
import { ITheme } from '../../types';

export const THEME_TILE_HEIGHT = 100;

const themeTileStyle = (isHighlighted: boolean) =>
  css(commonProductTileStyle, {
    height: THEME_TILE_HEIGHT,
    fontSize: font.size.large,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: font.weight.medium,
    color: designToken.primary.default,
    backgroundColor: tokens.color.core.blue[100],
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: isHighlighted ? 1 : 0.35,
    position: 'relative',
    '> img': {
      maxHeight: THEME_TILE_HEIGHT - 24,
      maxWidth: 240,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: tokens.color.core.blue[100],
      opacity: 0,
    },
    '&:hover': {
      color: designToken.interactive.hover,
      opacity: 1,
      transition: 'all 200ms cubic-bezier(0,.73,.55,.55)',

      '> img': {
        transition: 'transform 200ms cubic-bezier(0,.73,.55,.55)',
        transform: 'scale(1.08)',
      },
      '&:after': {
        opacity: 0.1,
        transition: 'opacity 75ms cubic-bezier(0,.73,.55,.55)',
      },
    },
    '&:active': {
      color: designToken.interactive.active,
      transition: 'transform 75ms ease-in-out',
      '> img': {
        transform: 'scale(1.04)',
        transition: 'transform 75ms ease-in-out',
      },
    },
  });

type ThemeTileProps = {
  theme: ITheme;
  onChange: (theme: ITheme) => void;
  isHighlighted: boolean;
};

export const ThemeTile: React.FC<ThemeTileProps> = ({ onChange, theme, isHighlighted = true }) => {
  const [image, setImage] = useState<string | undefined>(undefined);
  const loadImage = async () =>
    await import(`../../../../../assets/images/themes/${theme?.title.toUpperCase()}.png`)
      .then((image) => setImage(image.default))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Issue loading image for: ', theme?.title);
        return null;
      });

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <button
      css={themeTileStyle(isHighlighted)}
      tabIndex={-1}
      onClick={() => onChange(theme)}
    >
      {image ? (
        <img
          src={image}
          alt={theme?.title}
        />
      ) : (
        theme?.title
      )}
    </button>
  );
};
