import type { InferType } from 'yup';
import { array, number, object, string } from 'yup';

import { FileUploadItemStatus } from '../FileUpload/service/FileUploadItem';

export const claimTypes = [
  'PRICE_DISPUTES',
  'DAMAGED_GOODS',
  'MISC',
  'DELIVERY_SHORTAGE',
  'OVER_UNDER_SUB',
] as const;

const documentationFile = object({
  status: number()
    .test('only-success', (val) => val === FileUploadItemStatus.Success)
    .required(),
  fileSize: number().min(0).required(),
  id: string().required(),
});

export const claimItemSchema = object().shape({
  materialId: number().required(),
  orderNumber: number().required(),
  invoiceLineNumber: number().required(),
  claimType: string().oneOf(claimTypes).required(),
  pieces: number().min(1).required(),
  expectedPrice: number()
    .strict(true)
    .when('claimType', {
      is: 'PRICE_DISPUTES',
      then: (schema) => schema.min(0).required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  comment: string().when('claimType', {
    is: (claimType: (typeof claimTypes)[number]) => ['MISC', 'PRICE_DISPUTES'].includes(claimType),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  documentationFiles: array()
    .of(documentationFile)
    .when('claimType', {
      is: (claimType: (typeof claimTypes)[number]) => ['DAMAGED_GOODS'].includes(claimType),
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.min(0),
    })
    .required(),
});

export const claimSchema = object().shape({
  invoiceNumber: number().required(),
  claimItems: array().of(claimItemSchema).min(1).required(),
  documentationFiles: array().of(documentationFile).min(0).required(),
  comment: string().notRequired(),
  filesTotalFileSize: number().max(10 * 1024 * 1024), // 10 MB
});

export type ClaimItemFromValidationSchema = InferType<typeof claimItemSchema>;
export type ClaimFromValidationSchema = InferType<typeof claimSchema>;
