import { css } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font } from '@lego/b2b-unicorn-ui-constants';
import React, { useRef } from 'react';

const popupStyle = (direction: 'top' | 'bottom', icon: boolean) =>
  css({
    boxSizing: 'border-box',
    fontSize: font.size.small,
    color: designToken.text.default,
    width: 250,
    display: 'none',
    position: 'absolute',
    bottom: direction === 'top' ? '100%' : 'auto',
    top: direction === 'bottom' ? '100%' : 'auto',
    right: icon ? -22 : 0,
    backgroundColor: colors.white,
    borderRadius: 4,
    borderWidth: 0,
    paddingTop: baseSpacing * 2,
    paddingBottom: baseSpacing * 2,
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 3.5,
    filter: 'drop-shadow(0 2px 8px rgba(0, 0, 0, 0.25))',
    willChange: 'filter',
    transform: `translateY(${direction === 'top' ? '-10' : '10'}px)`,
    cursor: 'initial',
    zIndex: 10,

    '&:after': {
      content: '""',
      position: 'absolute',
      right: baseSpacing * 3,
      top: direction === 'top' ? '100%' : 'auto',
      bottom: direction === 'bottom' ? '100%' : 'auto',
      backgroundColor: colors.white,
      width: baseSpacing,
      height: baseSpacing,
      boxSizing: 'border-box',
      transform: `rotate(45deg) translate(${
        direction === 'top' ? '-3.5px, -3.5px' : '3.5px, 3.5px'
      })`,
    },
  });

const closeButtonStyle = css({
  backgroundColor: 'rgba(0, 0, 0, 0)',
  color: designToken.text.default,
  padding: baseSpacing,
  margin: 0,
  border: 'none',
  borderRadius: '50%',
  position: 'absolute',
  top: baseSpacing,
  right: baseSpacing,
  cursor: 'pointer',
  transitionProperty: 'background-color',
  transitionDuration: '150ms',
  height: 30,
  width: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '> svg': {
    height: 12,
    width: 12,
    path: {
      fill: designToken.text.default,
    },
  },
  '&:hover, :active': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
});

interface IDialogPopup {
  direction?: 'top' | 'bottom';
  icon?: boolean;
  children: React.ReactNode;
}

const DialogPopup = ({ direction = 'top', icon = false, children }: IDialogPopup) => {
  const ref = useRef<HTMLDivElement>(null);

  /**
   * Fallback function to handle removal of focus on parent element, as original implementation seems to no longer work in iOS Safari.
   */
  const closePopup = (event: React.MouseEvent) => {
    const wrapper = ref.current?.parentElement;
    if (wrapper) {
      // force focus removal on IOS
      event.preventDefault();
      wrapper.blur();
    }
  };

  return (
    <div
      css={popupStyle(direction, icon)}
      role="dialog"
      ref={ref}
    >
      <button
        onMouseDown={closePopup}
        css={closeButtonStyle}
        type="button"
        aria-label="Close dialog popup."
      >
        <Icon type={IconType.CLOSE} />
      </button>
      {children}
    </div>
  );
};

export default DialogPopup;
