import { css } from '@emotion/react';
import React, { forwardRef, MouseEvent } from 'react';

import { designToken } from '../theme';

type BackdropProps = {
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  children: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
};

const backdropStyle = css({
  background: designToken.transparent.darkOverlay,
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
});

export const Backdrop: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<BackdropProps> & React.RefAttributes<HTMLDivElement>
> = forwardRef<HTMLDivElement, BackdropProps>(({ children, onClick }, ref) => {
  return (
    <div
      css={backdropStyle}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </div>
  );
});
Backdrop.displayName = 'Backdrop';
