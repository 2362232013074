import type { NextLink, ObservableSubscription, Operation } from '@apollo/client';
import { ApolloLink, Observable } from '@apollo/client';

const setJwtTokenHeader = async (
  operation: Operation,
  forward: NextLink,
  getJwtToken: () => Promise<string>
) => {
  const jwtToken = await getJwtToken();
  const origContext = operation.getContext();
  const headers = {
    ...origContext.headers,
    ['Authorization']: jwtToken,
  };

  operation.setContext({
    ...origContext,
    headers,
  });

  return forward(operation);
};

export const createAuthLink = (jwtToken: () => Promise<string>) => {
  return new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      // Holds the observer subscription, will be populated inside the promise for getting the jwtToken
      let subscription: undefined | ObservableSubscription;

      const promise = setJwtTokenHeader(operation, forward, jwtToken);
      promise.then((observable) => {
        subscription = observable.subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  });
};
