import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';

const topbarButtonStyle = {
  width: baseSpacing * 4,
  height: baseSpacing * 4,
  border: 0,
  padding: 0,
  marginRight: 5, //adjusted for search input magglas
  marginLeft: baseSpacing,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  transitionDuration: '150ms',
  position: 'relative',
  boxShadow: 'none',
  outline: 0,
  borderRadius: baseSpacing * 2,
  '> svg': {
    width: 20,
    height: 20,
    fill: colors.klik.slate10,
    transitionProperty: 'fill',
    transitionDuration: '150ms',
  },
  '&:active': {
    outline: 'none',
  },
  '&:hover, &:focus': {
    color: colors.klik.lightBlue100,
    backgroundColor: colors.klik.lightBlue800,
    '> svg': {
      fill: colors.klik.lightBlue100,
    },
  },
};

export default topbarButtonStyle;
