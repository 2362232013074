import { CheckoutRecommendationItem } from '@lego/b2b-unicorn-data-access-layer';
import { useOptimisticUpdateReplenishmentCart } from '@lego/b2b-unicorn-data-access-layer/react';
import { useOptimisticUpdateCart } from '@lego/b2b-unicorn-data-access-layer/react/hooks/checkout';
import React, { useCallback, useMemo, useState } from 'react';

import { SkeletonRecommendations } from './SkeletonLoader';

type RecommendationsProviderProps<T extends Array<CheckoutRecommendationItem>> = {
  children: React.ReactNode;

  recommendationsLoading: boolean;
} & RecommendationsProviderContextType<T>;

type RecommendationsMinimumProduct = CheckoutRecommendationItem;

type RecommendationsProviderContextType<T extends Array<CheckoutRecommendationItem>> = {
  recommendations: T | null | undefined;

  updateCart:
    | ReturnType<typeof useOptimisticUpdateReplenishmentCart>['mutate']
    | ReturnType<typeof useOptimisticUpdateCart>['mutate'];
  onUpdateError: () => void;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  onAddToCart: (product: RecommendationsMinimumProduct) => void;
  onGotoProductDetails: (product: RecommendationsMinimumProduct) => void;
};

const RecommendationsProviderContext = React.createContext<
  RecommendationsProviderContextType<CheckoutRecommendationItem[]> | undefined
>(undefined);

export const useRecommendations = () => {
  const context = React.useContext(RecommendationsProviderContext);

  if (!context) {
    throw new Error('useRecommendations must be used within a RecommendationsProvider');
  }

  return context;
};

export function RecommendationsProvider<T extends Array<CheckoutRecommendationItem>>({
  recommendations: rawRecommendations,
  recommendationsLoading,
  onSwipeLeft,
  onSwipeRight,
  children,
  onGotoProductDetails,
  onAddToCart: rawOnAddToCart,
  updateCart,
  onUpdateError,
}: RecommendationsProviderProps<T>) {
  const [optimisticallyAddedToCart, setOptimisticallyAddedToCart] = useState<number[]>([]);

  const onAddToCart = useCallback(
    (product: RecommendationsMinimumProduct) => {
      rawOnAddToCart(product);
      setOptimisticallyAddedToCart((prev) => [...prev, product.materialId]);
    },
    [rawOnAddToCart]
  );

  const recommendations = useMemo(() => {
    return rawRecommendations?.filter(
      (product) => !optimisticallyAddedToCart.includes(product.materialId)
    );
  }, [optimisticallyAddedToCart, rawRecommendations]);

  return (
    <RecommendationsProviderContext.Provider
      value={{
        recommendations,
        onSwipeLeft,
        onSwipeRight,
        onGotoProductDetails,
        onAddToCart,
        updateCart,
        onUpdateError,
      }}
    >
      {recommendationsLoading && <SkeletonRecommendations />}
      {recommendations && recommendations.length > 0 && !recommendationsLoading && children}
    </RecommendationsProviderContext.Provider>
  );
}
