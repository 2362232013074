/* eslint-disable @typescript-eslint/no-explicit-any */
import { nanoid } from 'nanoid';

interface UsersnapInitOptions {
  custom?: Record<string, unknown>;
  user?: {
    email?: string;
    userId?: number;
  };
  locale?: string;
  useSystemFonts?: boolean;
  useLocalStorage?: boolean;
  nativeScreenshot?: boolean;
  collectGeoLocation?: 'all' | 'none';
}

interface UsersnapWidgetInitOptions extends UsersnapInitOptions {
  mountNode: HTMLElement;
}

interface UsersnapGlobalApi {
  init: (options: UsersnapInitOptions) => Promise<void>;
  destroy: () => Promise<void>;
  logEvent: (eventName: string) => Promise<void>;
  on: (eventName: string, callback: (event: any) => void) => void;
  off: (eventName: string, callback: (event: any) => void) => void;
}

interface UsersnapInlineApi {
  init: (options: UsersnapWidgetInitOptions) => Promise<void>;
  on: (eventName: string, callback: (event: any) => void) => void;
  off: (eventName: string, callback: (event: any) => void) => void;
}

type UsersnapInlineWidgetOptions = {
  onError: (error: Error) => void;
  onLoaded: () => void;
  onLoadError: () => void;

  usersnapOpts: UsersnapWidgetInitOptions;
};

type UsersnapServiceOptions = {
  globalApiKey?: string;
  onError: (error: Error) => void;
  onLoaded: () => void;
  onLoadError: () => void;

  globalUsersnapOpts: UsersnapInitOptions;
};

export class UsersnapService {
  private static _instance: UsersnapService;

  private GLOBAL_USERSNAP_SCRIPT_ID = 'usersnap-global';
  private _onLoadCallbackNames: Map<string, string> = new Map();
  private _inlineWidgetApis: Map<string, UsersnapInlineApi> = new Map();
  private readonly _onLoadGlobalCallbackName;

  private _opts: UsersnapServiceOptions;
  private _globalApi?: UsersnapGlobalApi;

  constructor(opts: UsersnapServiceOptions) {
    this._opts = opts;

    if (opts.globalApiKey) {
      this._onLoadGlobalCallbackName = `onGlobalUsersnapLoaded-${nanoid(5)}`;

      // Assign the global callback function
      Object.assign(window, {
        [this._onLoadGlobalCallbackName]: this.onGlobalUsersnapLoaded.bind(this),
      });

      // Load the Usersnap script
      try {
        this.loadGlobalUsersnapScript(opts);
      } catch (e) {
        opts.onLoadError();
        opts.onError(e as Error);
      }
    }

    UsersnapService._instance = this;
  }

  public static getInstance(opts?: UsersnapServiceOptions) {
    if (!UsersnapService._instance && !opts) {
      throw new Error('UsersnapService is not initialized');
    }
    if (!UsersnapService._instance) {
      UsersnapService._instance = new UsersnapService(opts!);
    }

    return UsersnapService._instance;
  }

  public mountInlineWidget(widgetKey: string, opts: UsersnapInlineWidgetOptions) {
    if (this._onLoadCallbackNames.has(widgetKey)) {
      this.destroyInlineWidget(widgetKey);
    }

    const callbackName = `onInlineWidgetLoad-${widgetKey}`;

    // Assign the callback function
    Object.assign(window, {
      [callbackName]: this.onLoadInlineWidget.bind(this, widgetKey, opts),
    });

    const scriptElm = document.createElement('script');
    scriptElm.id = callbackName;
    scriptElm.defer = true;
    scriptElm.crossOrigin = '';
    scriptElm.onerror = opts.onLoadError;
    scriptElm.src = `https://widget.usersnap.com/embed/load/${widgetKey}?onload=${callbackName}`;
    document.getElementsByTagName('head')[0].appendChild(scriptElm);
  }

  public updateGlobalUsersnapOptions(opts: UsersnapInitOptions) {
    if (!this._globalApi) {
      throw new Error('Global Usersnap API is not initialized');
    }

    this._globalApi
      .destroy()
      .then(() => {
        this._globalApi!.init(opts).catch(this._opts.onError);
      })
      .catch(this._opts.onError);
  }

  private onLoadInlineWidget(
    widgetKey: string,
    opts: UsersnapInlineWidgetOptions,
    api: UsersnapInlineApi
  ) {
    api
      .init(opts.usersnapOpts)
      .then(() => {
        this._inlineWidgetApis.set(widgetKey, api);
        opts.onLoaded();
      })
      .catch((error) => {
        opts.onError(error);
        this._opts.onError(error);
      });
  }

  public destroyInlineWidget(widgetKey: string) {
    if (this._onLoadCallbackNames.has(widgetKey)) {
      const callbackName = this._onLoadCallbackNames.get(widgetKey)!;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      delete window[callbackName];
      this._onLoadCallbackNames.delete(widgetKey);
      this._inlineWidgetApis.delete(widgetKey);
      document.getElementById(callbackName)?.remove();
    }
  }

  private loadGlobalUsersnapScript(opts: UsersnapServiceOptions) {
    const scriptElm = document.createElement('script');
    scriptElm.id = this.GLOBAL_USERSNAP_SCRIPT_ID;
    scriptElm.defer = true;
    scriptElm.crossOrigin = '';
    scriptElm.onerror = opts.onLoadError;
    scriptElm.src = `https://widget.usersnap.com/global/load/${opts.globalApiKey}?onload=${this._onLoadGlobalCallbackName}`;
    document.getElementsByTagName('head')[0].appendChild(scriptElm);
  }

  private onGlobalUsersnapLoaded(api: UsersnapGlobalApi) {
    api
      .init(this._opts.globalUsersnapOpts)
      .then(() => {
        this._opts.onLoaded();
        this._globalApi = api;
      })
      .catch(this._opts.onError);
  }
}
