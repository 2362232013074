import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { UserService } from '@lego/b2b-unicorn-bootstrap/services/user-service/user-service';
import { Customer, User } from '@lego/b2b-unicorn-data-access-layer';
import {
  ComponentType,
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type CustomerSelectorComponentProps = {
  customers: Customer[];
  onCustomerClick: (customer: Customer) => void;
};
type CustomerSelectorComponent = ComponentType<CustomerSelectorComponentProps>;
interface IUserProviderProps {
  customerSelectorComponent: CustomerSelectorComponent;
  children: ReactNode;
}

const updateDocumentLanguage = (lang: string) => {
  window.document.documentElement.lang = lang;
};

type UserContext = {
  user: User | null;
  selectedCustomer: Customer | null;
  loading: boolean;
};
const UserContext = createContext<UserContext | undefined>(undefined);

const UserProvider: FunctionComponent<IUserProviderProps> = (props) => {
  const { children, customerSelectorComponent: CustomerSelectorComponent } = props;

  const globalLoaderId = useRef<string>();
  const [userService] = useState(() => {
    return UserService.getInstance();
  });
  const [user, setUser] = useState(() => {
    return userService.user;
  });
  const [loading, setLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(() => userService.selectedCustomer);

  const handleUserLoaded = useCallback(
    (user: User) => {
      setUser(user);
      setSelectedCustomer(userService.selectedCustomer);
    },
    [userService.selectedCustomer]
  );

  const handleUserLoading = useCallback((loading: boolean) => {
    if (loading && !globalLoaderId.current) {
      globalLoaderId.current = GlobalLoadingService.add('user-provider');
    }
    if (!loading && globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
      globalLoaderId.current = undefined;
    }
    setLoading(loading);
  }, []);

  const handleCustomerSelected = useCallback((customer: Customer | null) => {
    updateDocumentLanguage(customer?.language.toLowerCase() || 'en');
    setSelectedCustomer(customer);
  }, []);

  useEffect(() => {
    const userLoadedHandlerId = userService.addEventListener('user-loaded', handleUserLoaded);
    const userUpdatedHandlerId = userService.addEventListener('user-updated', handleUserLoaded);
    const userLoadingHandlerId = userService.addEventListener('user-loading', handleUserLoading);
    const customerSelectedHandlerId = userService.addEventListener(
      'customer-selected',
      handleCustomerSelected
    );

    return () => {
      userService.removeListener('user-loaded', userLoadedHandlerId);
      userService.removeListener('user-updated', userUpdatedHandlerId);
      userService.removeListener('user-loading', userLoadingHandlerId);
      userService.removeListener('customer-selected', customerSelectedHandlerId);
    };
  }, [handleCustomerSelected, handleUserLoaded, handleUserLoading, userService]);

  useEffect(() => {
    globalLoaderId.current = GlobalLoadingService.add('user-provider');

    return () => {
      if (globalLoaderId.current) {
        GlobalLoadingService.remove(globalLoaderId.current);
        globalLoaderId.current = undefined;
      }
    };
  }, []);

  const handleCustomerClick = useCallback(
    (customer: Customer) => {
      userService.selectCustomer(customer);
    },
    [userService]
  );

  const contextValue = useMemo<UserContext>(() => {
    return {
      loading,
      user,
      selectedCustomer,
    };
  }, [loading, selectedCustomer, user]);

  if (contextValue && !contextValue.loading && !contextValue.selectedCustomer) {
    return (
      <CustomerSelectorComponent
        customers={user?.customers || []}
        onCustomerClick={handleCustomerClick}
      />
    );
  }

  if (!contextValue || contextValue.loading) {
    return null;
  }

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  if (!context.user) {
    throw new Error(`No user found in context`);
  }

  return context.user;
}

function useSelectedCustomer(allowNone?: boolean): Customer;
function useSelectedCustomer(allowNone: true): Customer | undefined;
function useSelectedCustomer(allowNone: false): Customer;
function useSelectedCustomer(allowNone?: boolean) {
  const context = useContext(UserContext);
  if (context === undefined && !allowNone) {
    throw new Error('useSelectedCustomer must be used within a UserProvider');
  }

  if (!context?.selectedCustomer && !allowNone) {
    throw new Error(`No customer was selected - This should not happen`);
  }

  switch (allowNone) {
    case true: {
      return context?.selectedCustomer;
    }
    default: {
      return context!.selectedCustomer;
    }
  }
}

export { UserContext, UserProvider, useSelectedCustomer, useUser };
