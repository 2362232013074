import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import React, { useState } from 'react';

import { ContainerHeader } from '../../ContainerHeader';

const featureContainerStyle = css({
  marginTop: baseSpacing * 2,
  marginBottom: baseSpacing * 3,
  borderBottomColor: tokens.color.core.gray[200],
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  maxWidth: 1064,
  [media.medium]: {
    borderBottom: 'none',
    paddingLeft: baseSpacing * 2,
  },
});

const longTextStyle = (open: boolean) =>
  css({
    fontSize: font.size.normal,
    fontWeight: font.weight.normal,
    color: designToken.text.default,
    margin: 0,
    height: open ? 'auto' : 68,
    overflow: 'hidden',
    marginBottom: baseSpacing * 2,
    position: 'relative',
    '&:before': {
      zIndex: 10,
      position: 'absolute',
      display: open ? 'none' : 'block',
      content: '""',
      width: '100vw',
      height: baseSpacing * 3,
      bottom: 0,

      background: `linear-gradient(${designToken.transparent.default} 0%, ${designToken.background.gray} 100%)`,
    },
  });

interface IFeaturesProps {
  description?: string | null;
  isOfTypeBox: boolean;
}

export const Features: React.FC<IFeaturesProps> = ({ description, isOfTypeBox }) => {
  const [open, setOpen] = useState(true);
  const { product_features, product_description_missing } = useLabels();

  return (
    <div css={featureContainerStyle}>
      <ContainerHeader
        text={product_features}
        open={open}
        setOpen={setOpen}
        withDisplayToggle={isOfTypeBox}
        withLargeText={false}
        mobileOnlyToggle={false}
      />
      <p
        css={longTextStyle(open)}
        dangerouslySetInnerHTML={{
          __html: description ?? product_description_missing,
        }}
      ></p>
    </div>
  );
};
