/* eslint-disable testing-library/no-debugging-utils */
import {
  Authentication,
  BrowserCacheLocation,
  InteractionStatus,
  LogLevel,
} from '@lego/b2b-unicorn-authentication';
import {
  APP_ENVIRONMENT,
  GRAPHQL_SERVER_URL,
  PARTNER_IDP_CLIENT_ID,
  PARTNER_IDP_REDIRECT_URI,
} from '@lego/b2b-unicorn-bootstrap/constants';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { DataAccessLayerOptions } from '@lego/b2b-unicorn-data-access-layer/DataAccessLayer';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import React, { createContext, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Subscription } from 'rxjs';

interface IAuthenticationContext {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getToken: () => Promise<string>;
  isAuthenticated: boolean;
  isLoading: boolean;
  apolloOptions: DataAccessLayerOptions;
}

const PartnerIDPEnvironmentsConfig = {
  local: {
    authorityDomain: 'id.dev.partner.lego.com',
    tentanId: '99709d40-d12c-4ed1-8f60-deca0d19bbfe',
  },
  dev: {
    authorityDomain: 'id.dev.partner.lego.com',
    tentanId: '99709d40-d12c-4ed1-8f60-deca0d19bbfe',
  },
  perf: {
    authorityDomain: 'id.qa.partner.lego.com',
    tentanId: '54ff7523-a8d8-4904-af6a-a8cf92de54eb',
  },
  prod: {
    authorityDomain: 'id.partner.lego.com',
    tentanId: '4fc321ae-becf-478f-8f0d-16e80db75278',
  },
};

const authLogger = logger.createLogger('Authentication');
export const AuthenticationContext = createContext<IAuthenticationContext | undefined>(undefined);
Authentication.createInstance({
  auth: {
    clientId: PARTNER_IDP_CLIENT_ID,
    authority: `https://${PartnerIDPEnvironmentsConfig[APP_ENVIRONMENT].authorityDomain}/${PartnerIDPEnvironmentsConfig[APP_ENVIRONMENT].tentanId}/B2C_1A_SIGNIN`,
    knownAuthorities: [PartnerIDPEnvironmentsConfig[APP_ENVIRONMENT].authorityDomain],
    redirectUri: PARTNER_IDP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        const [logDetails, ...logMessage] = message.split(' - ');
        const onlyLogMessage = logMessage.join(' - ');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_logTime, logCorrelationId, logPackage] = logDetails.split(' : ');
        const extra = {
          idp_correlation_id: logCorrelationId.slice(1, -1),
          package: logPackage,
        };
        switch (level) {
          case LogLevel.Error:
            authLogger.error(onlyLogMessage, extra);
            return;
          case LogLevel.Warning:
            authLogger.warning(onlyLogMessage, extra);
            return;
          case LogLevel.Info:
            authLogger.info(onlyLogMessage, extra);
            return;
          case LogLevel.Trace:
          case LogLevel.Verbose:
            authLogger.debug(onlyLogMessage, extra);
            return;
        }
      },
      logLevel: window.__DEV__ ? LogLevel.Trace : LogLevel.Warning,
    },
  },
});
Authentication.instance.initialize();

type BootstrapAuthenticationProps = {
  children: ReactNode;
};

export const BootstrapAuthentication: React.FC<BootstrapAuthenticationProps> = ({ children }) => {
  const loaderIdRef = useRef<string | undefined>();
  const [isAuthenticated] = useState(() => {
    return !!Authentication.instance.account;
  });
  const inProgressSubscriptionRef = useRef<Subscription>();
  const [interactionStatus, setInteractionStatus] = useState<InteractionStatus>(
    InteractionStatus.Startup
  );

  const isLoading = useMemo(() => {
    if (
      interactionStatus === InteractionStatus.Startup ||
      interactionStatus === InteractionStatus.HandleRedirect ||
      interactionStatus === InteractionStatus.Login ||
      interactionStatus === InteractionStatus.Logout
    ) {
      return true;
    }

    return false;
  }, [interactionStatus]);

  useEffect(() => {
    if (isLoading && !loaderIdRef.current) {
      loaderIdRef.current = GlobalLoadingService.add('auth');
    } else if (!isLoading && loaderIdRef.current) {
      GlobalLoadingService.remove(loaderIdRef.current);
      loaderIdRef.current = undefined;
    }
  }, [isLoading]);

  useEffect(() => {
    inProgressSubscriptionRef.current = Authentication.instance.inProgressObs.subscribe(
      (status) => {
        setInteractionStatus(status);
      }
    );

    return () => {
      if (!inProgressSubscriptionRef.current?.closed) {
        inProgressSubscriptionRef.current?.unsubscribe();
      }

      if (loaderIdRef.current) {
        GlobalLoadingService.remove(loaderIdRef.current);
        loaderIdRef.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      Authentication.instance.login();
    }
  }, [isAuthenticated]);

  const apolloOptions = useMemo<DataAccessLayerOptions>(() => {
    return {
      serverUrl: GRAPHQL_SERVER_URL,
      authOptions: {
        jwtToken: Authentication.instance.getIdToken.bind(Authentication.instance),
        logout: Authentication.instance.logout.bind(Authentication.instance),
        login: Authentication.instance.login.bind(Authentication.instance),
      },
    };
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        logout: Authentication.instance.logout.bind(Authentication.instance),
        login: Authentication.instance.login.bind(Authentication.instance),
        getToken: Authentication.instance.getIdToken.bind(Authentication.instance),
        apolloOptions,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
