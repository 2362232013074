import { css, keyframes } from '@emotion/react';
import type { CSSInterpolation } from '@emotion/serialize';
import { tokens } from '@lego/core-colors';

import { baseSpacing, colors, font } from '../theme';
import { TriggerPosition } from './TooltipContent';
import { ToolTipDirection } from './ToolTipDirection';
import {
  getArrowTransformFromDirection,
  getHoveredTransformFromDirection,
  getTooltipArrowPosition,
  getTooltipPosition,
  getTransformFromDirection,
} from './utils';

export const wrapperStyle = (fullHeight?: boolean) =>
  css({
    display: 'inline-block',
    padding: 0,
    margin: 0,
    height: fullHeight ? '100%' : undefined,
  });

const animateTooltipIn = (transformValue: string) => keyframes`
    0% { opacity: 0 }
    100% { opacity: 1; transform: ${transformValue} }
`;
const animateTooltipOut = (transformValue: string) => keyframes`
    0% { opacity: 1; transform: ${transformValue} }
    100% { opacity: 0 }
`;

export const toolTipStyle = (direction: ToolTipDirection, position: TriggerPosition) => {
  const tooltipPosition = getTooltipPosition(direction, position);
  const initialTransform = getTransformFromDirection(direction);
  const arrowPosition = getTooltipArrowPosition(direction);
  const arrowTransform = getArrowTransformFromDirection(direction);
  const style: CSSInterpolation = {
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 1001,
    backgroundColor: tokens.color.core.gray[1200],
    color: colors.white,
    fontSize: font.size.small,
    paddingTop: baseSpacing * 1.5,
    paddingBottom: baseSpacing * 1.5,
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
    borderRadius: baseSpacing / 2,
    filter: 'drop-shadow(0 2px 0 rgba(0, 0, 0, 0.1))',
    width: 'max-content',
    top: tooltipPosition.top,
    left: tooltipPosition.left,
    transform: initialTransform,
    opacity: 0,
    animationDuration: '100ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in-out',
    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      backgroundColor: tokens.color.core.gray[1200],
      width: 20,
      height: 20,
      borderRadius: 6,
      transform: arrowTransform,
      left: arrowPosition.left,
      top: arrowPosition.top,
    },
  };
  return css(style);
};

export const animateInStyle = (direction: ToolTipDirection) => {
  const hoverTransform = getHoveredTransformFromDirection(direction);
  const style: CSSInterpolation = { animationName: animateTooltipIn(hoverTransform) };
  return css(style);
};

export const animateOutStyle = (direction: ToolTipDirection) => {
  const hoverTransform = getHoveredTransformFromDirection(direction);
  const style: CSSInterpolation = { animationName: animateTooltipOut(hoverTransform) };
  return css(style);
};
