import { css } from '@emotion/react';
import React from 'react';

import { useModalContext } from './ModalContext';

type ModalBodyProps = {
  children: React.ReactNode;
};

const modalBodyStyle = (maxHeight: string) =>
  css({
    maxHeight,
    overflowY: 'auto',
  });

export const ModalBody: React.FC<ModalBodyProps> = ({ children }) => {
  const modalContext = useModalContext();

  return <section css={modalBodyStyle(modalContext.modalBodyMaxHeight)}>{children}</section>;
};
