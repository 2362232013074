export enum ProductListSortingOption {
  DATE_DESC = 'DATE_DESC',
  DATE_ASC = 'DATE_ASC',
  ITEM_NUMBER_ASC = 'ITEM_NUMBER_ASC',
  ITEM_NUMBER_DESC = 'ITEM_NUMBER_DESC',
  THEME_ASC = 'THEME_ASC',
  THEME_DESC = 'THEME_DESC',
  EXIT_DATE_ASC = 'EXIT_DATE_ASC',
  EXIT_DATE_DESC = 'EXIT_DATE_DESC',
}

export const DefaultProductListSorting = ProductListSortingOption.DATE_DESC;
