import { css, keyframes } from '@emotion/react';
import { tokens } from '@lego/core-colors';

import { baseSpacing, colors, designToken } from '../theme';

const pulse = keyframes`
  100% { background-position: 300px 0; }
`;

const tileStyle = css({
  position: 'relative',
  width: 340,
  height: 240,
  color: designToken.text.default,
  backgroundColor: colors.white,
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: tokens.color.core.gray[75],
  borderRadius: 8,
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${designToken.skeleton.default} 50%, ${designToken.transparent.default} 100%)`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-300px 0',
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  },
});

const tileTopStyle = css({
  width: '100%',
  height: 140,
  backgroundColor: designToken.skeleton.default,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
});

const buttonPlaceholderStyle = css({
  width: 120,
  height: baseSpacing * 3,
  borderRadius: baseSpacing * 2,
  backgroundColor: designToken.skeleton.default,
  bottom: 12,
  right: baseSpacing,
  position: 'absolute',
});

export const SkeletonTile = () => (
  <div css={tileStyle}>
    <div css={tileTopStyle} />
    <div css={buttonPlaceholderStyle} />
  </div>
);
