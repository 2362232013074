import { gql } from '@apollo/client';

export const ORDERS_PRODUCTS = gql`
  query ordersProducts($customerId: Int!) {
    products(customerId: $customerId) {
      products {
        materialId
        piecesPerCasePack
      }
    }
  }
`;
