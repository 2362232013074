import { buttonStyle } from '@lego/b2b-unicorn-shared/ui';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import { FunctionComponent } from 'react';

import { ContentstackAsset } from '../ContentstackAsset';

export interface ILink {
  title: string;
  url?: string;
  assetUid?: string;
}

export const ContentstackLink: FunctionComponent<ILink> = ({ title, url, assetUid }) => {
  const renderLink = () => {
    if (assetUid && assetUid !== null) {
      return (
        <ContentstackAsset
          url={url}
          assetUid={assetUid}
        >
          {title}
        </ContentstackAsset>
      );
    } else {
      return (
        <a
          css={[
            buttonStyle('ghost_inverted', 'tiny'),
            {
              justifyContent: 'start',
            },
          ]}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
      );
    }
  };

  return url ? (
    renderLink()
  ) : (
    <p
      css={[
        buttonStyle('ghost_inverted', 'tiny', true),
        {
          justifyContent: 'start',
          color: colors.klik.lightBlue100,
        },
      ]}
    >
      {title}
    </p>
  );
};
