import { css, keyframes } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const pulse = keyframes`
  100% { background-position: 300px 0; }
`;

const containerHeaderStyle = (display: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: baseSpacing * 3,

    '> button': {
      height: 26,
      width: 26,
      backgroundColor: 'transparent',
      padding: 1,
      borderWidth: 2,
      borderRadius: 4,
      borderStyle: 'solid',
      borderColor: designToken.border.muted,
      '> svg': { fill: designToken.border.muted },
      '&:focus': {
        outline: 'none',
      },
    },

    [media.medium]: {
      display: display ? 'flex' : 'none',
      '> button': {
        visibility: 'hidden',
      },
    },
  });

const placeholderStyle = css({
  backgroundColor: designToken.background.gray,
  borderRadius: 4,
  position: 'relative',
  height: 24,
  width: 145,
  margin: 0,
  overflow: 'hidden',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    width: 145,
    height: 24,
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${designToken.background.white} 50%, ${designToken.transparent.default} 100%)`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-300px 0',
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  },
  [media.medium]: {
    marginLeft: baseSpacing * 2,
    height: 32,
    width: 240,
    '&:after': {
      height: 32,
      width: 240,
    },
  },
});

interface ISkeletonContainerHeaderProps {
  open: boolean;
  display?: boolean;
}
export const SkeletonContainerHeader: React.FC<ISkeletonContainerHeaderProps> = ({
  open,
  display = true,
}) => {
  return (
    <div css={containerHeaderStyle(display)}>
      <span css={placeholderStyle} />
      <button>
        <Icon type={open ? IconType.MINUS : IconType.PLUS} />
      </button>
    </div>
  );
};
