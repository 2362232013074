import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { baseSpacing, designToken, font } from '../theme';

const tileHeaderStyle = (active: boolean) =>
  css({
    height: 140,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: active ? tokens.color.core.azur[900] : tokens.color.core.gray[100],
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    h1: {
      fontSize: font.size.large,
      fontWeight: font.weight.normal,
      color: active ? tokens.color.core.gray[25] : designToken.text.default,
      // TODO: next 3 lines maybe should be implementation detail
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      paddingLeft: baseSpacing * 2,
      paddingRight: baseSpacing * 2,
      textTransform: 'uppercase',
    },
    span: {
      fontSize: font.size.medium,
      color: active ? tokens.color.core.azur[200] : tokens.color.core.gray[900],
      marginBottom: baseSpacing,
    },
  });

type TileHeaderProps = {
  active: boolean;
  children: React.ReactNode;
};

export const TileHeader: React.FC<TileHeaderProps> = ({ active, children }) => {
  return <div css={tileHeaderStyle(active)}>{children}</div>;
};
