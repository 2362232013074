import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { LaunchCartReferenceInput, LaunchProduct } from '@lego/b2b-unicorn-data-access-layer';
import { BulkQuickAddTriggerButton } from '@lego/b2b-unicorn-shared/components/BulkQuickAdd';
import { ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React, { Fragment, useCallback, useState } from 'react';

import { LaunchWindowBulkQuickAddConsumer } from '../../../../../components/BulkQuickAdd';

type LaunchWindowBulkAddActionProps = {
  launchWindowProducts: LaunchProduct[];
  cartReference: LaunchCartReferenceInput;
  disabled?: boolean;
  onError: () => void;
};
export const LaunchWindowBulkAddAction: React.FC<LaunchWindowBulkAddActionProps> = ({
  launchWindowProducts,
  cartReference,
  disabled = false,
  onError,
}) => {
  const { button_bulk_quick_add } = useLabels();
  const [showBulkQuickAdd, setShowBulkQuickAdd] = useState(false);
  const { trackEvent } = useAnalytics();

  const handleOnBulkQuickAddOpenClick = useCallback(() => {
    trackEvent({
      event: 'openBulkQuickAddLaunchWindow',
      name: `Open launch window bulk quick add modal`,
    });
    setShowBulkQuickAdd(true);
  }, [trackEvent]);

  const handleOnBulkQuickAddClose = useCallback(() => {
    setShowBulkQuickAdd(false);
  }, []);

  const handleOnBulkQuickAddHasPreviousSession = useCallback(() => {
    trackEvent({
      event: 'reopenBulkQuickAddLaunchWindow',
      name: `Open launch window bulk quick add modal with previous session`,
    });
    setShowBulkQuickAdd(true);
  }, []);

  return (
    <Fragment>
      <BulkQuickAddTriggerButton
        handleOnBulkQuickAddOpenClick={handleOnBulkQuickAddOpenClick}
        translations={{ button_bulk_quick_add }}
        buttonSize={'small'}
        buttonType={ButtonType.GHOST}
        disabled={disabled}
      />
      <LaunchWindowBulkQuickAddConsumer
        launchWindowProducts={launchWindowProducts}
        open={showBulkQuickAdd}
        closeModal={handleOnBulkQuickAddClose}
        cartReference={cartReference}
        onError={onError}
        onHasPreviousSession={handleOnBulkQuickAddHasPreviousSession}
      />
    </Fragment>
  );
};
