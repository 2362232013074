import { css } from '@emotion/react';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import { useState } from 'react';

import { SortingDirection } from '../../utils/Sorting';

interface ISortingArrows {
  /** Set to true if this button belongs to the property currently being sorted on. */
  isActive: boolean;
  /** Current sorting direction. Defaults to 'off'. */
  sortingDirection?: SortingDirection;
  /*** Callback function that will receive the selected sort direction as a number (-1 for ascending, 1 for descending and 0 for no sorting) */
  onClickCallback?: (direction: SortingDirection) => void;
}

const style = (isActive: boolean) =>
  css({
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '> svg': {
      height: 24,
      width: 24,
      fill: isActive ? colors.klik.lightBlue500 : colors.klik.slate400,
    },
    '&:focus': {
      '> svg': {
        fill: colors.klik.lightBlue500,
      },
    },
  });

const renderArrows = (isActive: boolean, direction: SortingDirection) => {
  if (direction == SortingDirection.OFF || !isActive) {
    return <Icon type={IconType.SORT_DEFAULT} />;
  } else if (isActive) {
    if (direction === SortingDirection.DESC) {
      return <Icon type={IconType.SORT_DESC} />;
    } else {
      return <Icon type={IconType.SORT_ASC} />;
    }
  }
};

const SortingArrows = (props: ISortingArrows) => {
  const [direction, setDirection] = useState<SortingDirection>(props.sortingDirection || 0);

  const toggleDirection = () => {
    let newDirection = direction;
    if (direction === SortingDirection.OFF) {
      newDirection = SortingDirection.DESC;
    } else if (direction === SortingDirection.DESC) {
      newDirection = SortingDirection.ASC;
    } else {
      newDirection = props.isActive ? SortingDirection.OFF : SortingDirection.DESC; // Only allow setting to 'off' state if button is already active.
    }
    setDirection(newDirection);
    if (props.onClickCallback) {
      props.onClickCallback(newDirection);
    }
  };

  /** Sorting direction can (but doesn't necessarily) come from props.
   *  (If e.g. sorting is handled by outside logic.)
   *  If the prop exists, it takes precedence. */
  const renderDirection = props.sortingDirection || direction;

  return (
    <button
      css={style(props.isActive)}
      className="unicorn-sorting-arrow"
      onClick={toggleDirection}
    >
      {renderArrows(props.isActive, renderDirection)}
    </button>
  );
};

export default SortingArrows;
