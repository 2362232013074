import { gql } from '@apollo/client';

export const EMPTY_CART = gql`
  mutation checkoutEmptyCart($customerId: Int!, $cartReference: CartReferenceInput!) {
    emptyCart(customerId: $customerId, cartReference: $cartReference) {
      items {
        ...minimumCartItem
      }
      ...cartKey
    }
  }
`;
