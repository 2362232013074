import { css } from '@emotion/react';
import React from 'react';

import { Button, ButtonType } from '../../ui/Button';
import { Icon, IconType } from '../../ui/Icon';
import { designToken } from '../../ui/theme';

const style = css({
  color: designToken.interactive.default,
  display: 'inline-flex',
  alignItems: 'center',
  paddingLeft: 8,
  paddingRight: 8,
  '> svg': {
    height: 16,
    width: 16,
    fill: designToken.interactive.default,
    transitionDuration: '150ms',
    transitionProperty: 'all',
    transitionTimingFunction: 'ease-in-out',
    transform: 'translateX(0)',
  },
  '&:hover, :focus': {
    cursor: 'pointer',
    color: designToken.interactive.hover,
    '> svg': {
      transform: 'translateX(4px)',
      fill: designToken.interactive.hover,
    },
  },
});

type LinkWithArrowProps = {
  children: React.ReactNode;
  onClick: () => void;
};
export const LinkWithArrow: React.FC<LinkWithArrowProps> = ({ children, onClick }) => {
  return (
    <Button
      type={ButtonType.GHOST}
      size={'small'}
      onClick={onClick}
    >
      <span css={style}>
        {children} <Icon type={IconType.ARROW_RIGHT} />
      </span>
    </Button>
  );
};
