import { gql } from '@apollo/client';

export const PDF_ORDER = gql`
  query pdfOrder($orderNumber: Int!, $customerId: Int!) {
    getOrderPdf(customerId: $customerId, orderNumber: $orderNumber) {
      fileName
      fileDataAsBase64
    }
  }
`;
