import { Price } from '@lego/b2b-unicorn-data-access-layer';

interface MinimalOrderItem {
  quantity: number;
  product: {
    piecesPerCasePack: number;
    price: {
      listPrice?: number | null;
      grossPrice?: number | null;
      estimatedNetInvoicedPrice: number;
      netInvoicedPrice?: number | null;
      currency: string;
    };
  };
}

type PriceSum = Price & {
  listPrice: NonNullable<Price['listPrice']>;
  grossPrice: NonNullable<Price['grossPrice']>;
  netInvoicedPrice: NonNullable<Price['netInvoicedPrice']>;
  estimatedNetInvoicedPrice: NonNullable<Price['estimatedNetInvoicedPrice']>;
};

type PriceSumGrossPrice = Price & {
  grossPrice: NonNullable<Price['grossPrice']>;
};

type PriceSumListPrice = Price & {
  listPrice: NonNullable<Price['listPrice']>;
};

type PriceSumNetInvoicePrice = Price & {
  netInvoicedPrice: NonNullable<Price['netInvoicedPrice']>;
};

type PriceSumEstimatedNetInvoicePrice = Price & {
  estimatedNetInvoicedPrice: NonNullable<Price['estimatedNetInvoicedPrice']>;
};

export class CartMath {
  /**
   * Sum all into one Price
   * @param items - Cart items
   */
  static sumPrice<T extends MinimalOrderItem>(items: T[]): PriceSum {
    const currency = items[0]?.product.price.currency || '';

    return {
      __typename: 'Price',
      listPrice: CartMath.sumListPrice(items, true).listPrice,
      grossPrice: CartMath.sumGrossPrice(items, true).grossPrice,
      netInvoicedPrice: CartMath.sumNetInvoicedPrice(items, true).netInvoicedPrice,
      estimatedNetInvoicedPrice:
        CartMath.sumEstimatedNetInvoicedPrice(items).estimatedNetInvoicedPrice,
      currency,
    };
  }

  /**
   * Sum of all list prices
   * @param items - Cart items
   * @param allowEmpty - Allow items to have no list price, otherwise every item must have a list price
   */
  static sumListPrice<T extends MinimalOrderItem>(
    items: T[],
    allowEmpty = false
  ): PriceSumListPrice {
    if (
      items.length > 0 &&
      items.every(
        (item) =>
          item.product.price.listPrice === null || item.product.price.listPrice === undefined
      ) &&
      !allowEmpty
    ) {
      throw Error(`No item in items has a 'listPrice'!`);
    }

    let currency = '';
    const sum = items.reduce((sum, item) => {
      if (!item.product.price.listPrice) {
        return sum;
      }

      currency = item.product.price.currency;

      return sum + item.quantity * item.product.piecesPerCasePack * item.product.price.listPrice;
    }, 0);

    return {
      __typename: 'Price',
      estimatedNetInvoicedPrice: sum,
      listPrice: sum,
      currency,
    };
  }

  /**
   * Sum of all gross prices
   * @param items - Cart items
   * @param allowEmpty - Allow items to have no gross price, otherwise every item must have a gross price
   */
  static sumGrossPrice<T extends MinimalOrderItem>(
    items: T[],
    allowEmpty = false
  ): PriceSumGrossPrice {
    if (
      items.length > 0 &&
      items.every(
        (item) =>
          item.product.price.grossPrice === null || item.product.price.grossPrice === undefined
      ) &&
      !allowEmpty
    ) {
      throw Error(`No item in items has a 'grossPrice'!`);
    }

    let currency = '';
    const sum = items.reduce((sum, item) => {
      if (!item.product.price.grossPrice) {
        return sum;
      }

      currency = item.product.price.currency;

      return sum + item.quantity * item.product.piecesPerCasePack * item.product.price.grossPrice;
    }, 0);

    return {
      __typename: 'Price',
      estimatedNetInvoicedPrice: sum,
      grossPrice: sum,
      currency,
    };
  }

  /**
   * Sum of all net invoiced prices.
   * @param items - Cart items
   * @param allowEmpty - Allow items to have no net invoiced price, otherwise every item must have a net invoiced price
   */
  static sumNetInvoicedPrice<T extends MinimalOrderItem>(
    items: T[],
    allowEmpty = false
  ): PriceSumNetInvoicePrice {
    if (
      items.length > 0 &&
      items.every(
        (item) =>
          item.product.price.netInvoicedPrice === null ||
          item.product.price.netInvoicedPrice === undefined
      ) &&
      !allowEmpty
    ) {
      throw Error(`No item in items has a 'netInvoicedPrice'!`);
    }

    let currency = '';
    const sum = items.reduce((sum, item) => {
      const itemPrice = item.product.price.netInvoicedPrice;
      if (!itemPrice) {
        return sum;
      }

      currency = item.product.price.currency;

      return sum + item.quantity * item.product.piecesPerCasePack * itemPrice;
    }, 0);

    return {
      __typename: 'Price',
      netInvoicedPrice: sum,
      estimatedNetInvoicedPrice: sum,
      currency,
    };
  }

  /**
   * Sum of all estimated net invoiced prices.
   * @param items - Cart items
   * @param allowEmpty - Allow items to have no estimated net invoiced price, otherwise every item must have an estimated invoiced price
   */
  static sumEstimatedNetInvoicedPrice<T extends MinimalOrderItem>(
    items: T[],
    allowEmpty = false
  ): PriceSumEstimatedNetInvoicePrice {
    if (
      items.length > 0 &&
      items.every(
        (item) =>
          item.product.price.estimatedNetInvoicedPrice === null ||
          item.product.price.estimatedNetInvoicedPrice === undefined
      ) &&
      !allowEmpty
    ) {
      throw Error(`No item in items has a 'estimatedNetInvoicedPrice'!`);
    }

    let currency = '';
    const sum = items.reduce((sum, item) => {
      const itemPrice = item.product.price.estimatedNetInvoicedPrice;
      if (!itemPrice) {
        return sum;
      }

      currency = item.product.price.currency;

      return sum + item.quantity * item.product.piecesPerCasePack * itemPrice;
    }, 0);

    return {
      __typename: 'Price',
      estimatedNetInvoicedPrice: sum,
      currency,
    };
  }

  /**
   * Sum of all cases (quantity sum)
   * @param items - Cart items
   */
  static sumCases<T extends MinimalOrderItem>(items: T[]) {
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  /**
   * Sum of all units (quantity * piecesPerCasePack sum)
   * E.g. sum of physical number of LEGO sets
   * @param items - Cart items
   */
  static sumUnits<T extends MinimalOrderItem>(items: T[]) {
    return items.reduce((acc, item) => acc + item.quantity * item.product.piecesPerCasePack, 0);
  }
}
