import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import React, { Fragment, useEffect, useState } from 'react';

import {
  fourColMedia,
  fourColWidth,
  threeColMedia,
  threeColWidth,
  twoColMedia,
  twoColWidth,
} from '../../../constants';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { leftArrowStyle, rightArrowStyle } from '../../../styles';
import { adjustedWidthStyle } from '../styles';
import { useRecommendations } from './RecommendationsProvider';
import { ReplenishmentCartRecommendationProductTile } from './ReplenishmentCartRecommendationProductTile';

const carouselStyle = css(adjustedWidthStyle, {
  overflow: 'hidden',
});

const arrowsWrapperStyle = css({
  display: 'none',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  [`@media (min-width: ${twoColMedia}px)`]: {
    display: 'block',
    minWidth: twoColWidth,
    maxWidth: twoColWidth + baseSpacing * 16,
  },
  [`@media (min-width: ${threeColMedia}px)`]: {
    minWidth: threeColWidth,
    maxWidth: threeColWidth + baseSpacing * 16,
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    minWidth: fourColWidth,
    maxWidth: fourColWidth + baseSpacing * 16,
  },
});

const arrowStyle = css({
  bottom: 0,
  top: -260,
});

const localLeftArrowStyle = css(arrowStyle, leftArrowStyle, {
  paddingRight: baseSpacing / 2,
  left: baseSpacing * 2,
});

const localRightArrowStyle = css(arrowStyle, rightArrowStyle, {
  paddingLeft: baseSpacing / 2,
  right: baseSpacing * 2,
});

const hintStyle = css({
  color: colors.klik.slate400,
  fontSize: 14,
  marginTop: baseSpacing,
  marginRight: 'auto',
  marginLeft: 'auto',
  display: 'block',
  textAlign: 'center',
  [`@media (min-width: ${twoColMedia}px)`]: {
    display: 'none',
  },
});

const productsContainerStyle = (carouselIndex: number) =>
  css({
    width: '100%',
    display: 'grid',
    gridGap: baseSpacing * 2,
    justifyContent: 'left',
    gridAutoFlow: 'column',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    scrollSnapPointsX: 'repeat(100%)',
    [`@media (min-width: ${twoColMedia}px)`]: {
      overflowX: 'initial',
      transform: `translateX(-${carouselIndex * 100}%) translateX(-${
        carouselIndex * baseSpacing * 2
      }px)`,
      transitionDuration: '300ms',
      transitionProperty: 'transform',
      transitionTimingFunction: 'ease-in-out',
    },
    [`@media (min-width: ${threeColMedia}px)`]: {
      transitionDuration: '450ms',
    },
    [`@media (min-width: ${fourColMedia}px)`]: {
      transitionDuration: '600ms',
    },
  });

const MIN_INDEX = 0;

type ProductsCarouselProps = {
  tileComponent: typeof ReplenishmentCartRecommendationProductTile;
};

export const RecommendationsProductsCarousel: React.FC<ProductsCarouselProps> = ({
  tileComponent: TileComponent,
}) => {
  const { recommendations, onSwipeRight, onSwipeLeft } = useRecommendations();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [maxIndex, setMaxIndex] = useState<number>();
  const minIndex = MIN_INDEX;
  const windowWidth = useWindowWidth();
  const { swipe_hint } = useLabels();

  const numberOfProducts = recommendations?.length || 0;

  // setting max index on window resize
  useEffect(() => {
    const checkAndSetNewMaxIndex = (numberOfRows: number) => {
      const index = Math.ceil(numberOfProducts / numberOfRows) - 1;
      if (maxIndex !== index) {
        setMaxIndex(index);
        return;
      }
    };

    if (numberOfProducts > 0) {
      if (windowWidth >= fourColMedia) {
        checkAndSetNewMaxIndex(4);
      } else if (windowWidth >= threeColMedia) {
        checkAndSetNewMaxIndex(3);
      } else if (windowWidth >= twoColMedia) {
        checkAndSetNewMaxIndex(2);
      } else {
        checkAndSetNewMaxIndex(1);
      }
    }
  }, [windowWidth, maxIndex, numberOfProducts]);

  // reseting carousel active index on window resize if MAX_INDEX changes
  useEffect(() => {
    if (maxIndex !== undefined && activeIndex > maxIndex) {
      setActiveIndex(activeIndex - 1);
    }
  }, [maxIndex, activeIndex]);

  return (
    <Fragment>
      <div css={carouselStyle}>
        <div css={productsContainerStyle(activeIndex)}>
          {recommendations?.map((product) => (
            <TileComponent
              key={product.materialId}
              product={product}
            />
          ))}
        </div>
      </div>
      <div css={arrowsWrapperStyle}>
        <button
          css={localLeftArrowStyle}
          onClick={() => {
            setActiveIndex(activeIndex - 1);
            onSwipeLeft();
          }}
          disabled={activeIndex === minIndex}
        >
          <Icon type={IconType.CHEVRON_LEFT} />
        </button>
        <button
          css={localRightArrowStyle}
          onClick={() => {
            setActiveIndex(activeIndex + 1);
            onSwipeRight();
          }}
          disabled={activeIndex === maxIndex}
        >
          <Icon type={IconType.CHEVRON_RIGHT} />
        </button>
      </div>
      <span css={hintStyle}>{swipe_hint}</span>
    </Fragment>
  );
};
