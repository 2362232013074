import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ReplenishmentProductBox } from '@lego/b2b-unicorn-data-access-layer';
import { useReplenishmentProduct } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  ContentSystemFeedback,
  designToken,
  SystemFeedbackType,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrls, ImageType } from '@lego/b2b-unicorn-ui-utils';
import { tokens } from '@lego/core-colors';
import { Fragment, useEffect, useState } from 'react';

import {
  Features,
  ProductCarousel,
  ProductDetails,
  SkeletonProductDetailsPage,
} from './components/';

export const productBckgStyle = css({
  color: designToken.text.default,
  width: '100%',
  minHeight: '100vh',
  background: `linear-gradient(${designToken.secondary.default} 0%, ${designToken.secondary.default})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 187px',
  backgroundColor: tokens.color.core.gray[50],

  [`@media (min-width: 360px)`]: {
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
  },
});

export const productPageStyle = css({
  maxWidth: maxDesktopWidth,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'grid',

  [media.medium]: {
    gridTemplateColumns: 'auto 344px ',
    gridTemplateRows: '92px auto',
    gridColumnGap: baseSpacing * 2,
  },

  [media.large]: {
    gridTemplateColumns: '1064px 344px ',
    gridTemplateRows: '92px auto',
    gridColumnGap: baseSpacing * 2,
  },
});

const headerStyle = css({
  fontSize: font.size.medium,
  fontWeight: font.weight.bold,
  color: colors.white,
  paddingTop: baseSpacing * 3,
  paddingBottom: baseSpacing * 3,
  margin: 0,
  '> span': {
    color: designToken.primary.hover,
  },

  [media.medium]: {
    gridColumn: '1 / -1',
    fontSize: font.size.large,
    paddingTop: baseSpacing * 3,
    paddingBottom: baseSpacing * 3,
  },
});

const imagesTypes = [
  ImageType.MAIN_BOX,
  ImageType.MAIN_BOX_BACK,
  ImageType.MODEL_IMAGE,
  ImageType.ALT_MODEL_LINE_UP,
  ImageType.ALT_MODEL_PRIMARY,
  ImageType.ALT_MODEL_SECONDARY,
];

interface IProductDetailsPage {
  materialId: number;
  onProductLoaded?: () => void;
}

const ProductDetailsPage = ({ materialId, onProductLoaded }: IProductDetailsPage) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const selectedCustomer = useSelectedCustomer();
  const locale = selectedCustomer.locale;
  const { content_system_feedback_error } = useLabels();

  const {
    data: productResponse,
    loading: productLoading,
    error: productError,
    refetch: fetchProduct,
  } = useReplenishmentProduct(selectedCustomer.id, materialId, true);

  useEffect(() => {
    if (materialId) {
      setImageUrls([]);
      const loadImages = () => {
        const images = getImageUrls(materialId, imagesTypes, 600);
        images.length && setImageUrls(images);
      };
      loadImages();
    }
  }, [materialId]);

  useEffect(() => {
    fetchProduct().finally(() => {
      onProductLoaded && onProductLoaded();
    });
  }, [selectedCustomer, materialId, fetchProduct, onProductLoaded]);

  useEffect(() => {
    productResponse && !productResponse.product && location.replace('/404-page-not-found');
  }, [productResponse]);

  return (
    <Fragment>
      {productLoading && <SkeletonProductDetailsPage />}
      {productResponse?.product && !productLoading && (
        <div css={productBckgStyle}>
          <div css={productPageStyle}>
            {productResponse.product instanceof ReplenishmentProductBox ? (
              <h3
                css={headerStyle}
                dangerouslySetInnerHTML={{
                  __html: `<span>${productResponse.product.itemNumber}</span> ${productResponse.product.name}`,
                }}
              />
            ) : (
              <h3
                css={headerStyle}
                dangerouslySetInnerHTML={{
                  __html: productResponse.product.name ?? '-',
                }}
              />
            )}
            <div>
              <ProductCarousel
                isOfTypeBox={productResponse.product instanceof ReplenishmentProductBox}
                images={imageUrls}
              />
              <Features
                description={
                  productResponse.product instanceof ReplenishmentProductBox
                    ? productResponse.product.description
                    : ''
                }
                isOfTypeBox={productResponse.product instanceof ReplenishmentProductBox}
              />
            </div>
            {locale && (
              <ProductDetails
                product={productResponse.product}
                locale={locale}
              />
            )}
          </div>
        </div>
      )}
      {productError && (
        <ContentSystemFeedback
          type={SystemFeedbackType.ERROR}
          text={content_system_feedback_error}
        />
      )}
    </Fragment>
  );
};

export default ProductDetailsPage;
