import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useTopbar } from '@lego/b2b-unicorn-data-access-layer/react';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { CartMath } from '@lego/b2b-unicorn-ui-utils';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CartIndicator from '../components/CartIndicator/CartIndicator';

const CartIndicatorImplementation = () => {
  const [cartUpdated, setCartUpdated] = useState(false);
  const initialCartUpdated = useRef(true);
  const { cart: cartTranslation } = useLabels();
  const { id, locale } = useSelectedCustomer();
  const history = useHistory();
  const { data: topbarResponse } = useTopbar(id);
  const hasProductsInCart =
    !!topbarResponse && topbarResponse?.getCart?.items?.length > 0;
  useEffect(() => {
    if (!topbarResponse) {
      return;
    }
    if (initialCartUpdated.current) {
      initialCartUpdated.current = false;
      return;
    }
    setCartUpdated(true);
  }, [topbarResponse]);

  const totalPrice = topbarResponse?.getCart.items
    ? CartMath.sumEstimatedNetInvoicedPrice(topbarResponse?.getCart.items)
    : null;
  const goToCart = () => {
    history.push('/checkout');
  };

  const handleOnAnimationEnd = () => {
    setCartUpdated(false);
  };

  return (
    <CartIndicator
      cartUpdated={cartUpdated}
      hasProductsInCart={hasProductsInCart}
      price={locale && <Price locale={locale}>{totalPrice}</Price>}
      translations={{ cart: cartTranslation }}
      onClick={goToCart}
      onAnimationEnd={handleOnAnimationEnd}
    />
  );
};

export default CartIndicatorImplementation;
