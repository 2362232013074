import { css } from '@emotion/react';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import {
  skeletonBottomContainerStyle,
  skeletonPlaceholderButtonStyle,
  skeletonPlaceholderStyle,
  skeletonProductTileStyle,
  skeletonStatusStyle,
} from '../../../../styles';

const productTileStyle = css(skeletonProductTileStyle, {
  height: 568,
  marginLeft: 'auto',
  marginRight: 'auto',
  '&:after': {
    height: 568,
  },
});

const columnsStyle = css({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 50,
});

export const SkeletonProductDetailsTile: React.FC = () => {
  return (
    <article css={productTileStyle}>
      <div css={skeletonStatusStyle} />
      <div
        css={[skeletonPlaceholderStyle, { height: 20, width: 90, marginBottom: 8, marginTop: 8 }]}
      />
      <div css={[skeletonPlaceholderStyle, { height: 16, width: 200, marginBottom: 48 }]} />
      <div css={columnsStyle}>
        <div
          css={[
            skeletonPlaceholderStyle,
            { height: 294, width: '50%', marginRight: 8, backgroundColor: colors.klik.slate30 },
          ]}
        />
        <div
          css={[
            skeletonPlaceholderStyle,
            { height: 294, width: '50%', backgroundColor: colors.klik.slate30 },
          ]}
        />
      </div>
      <div css={[skeletonPlaceholderStyle, { height: 30, width: 140, marginBottom: 8 }]} />
      <div css={skeletonBottomContainerStyle}>
        <div css={[skeletonPlaceholderStyle, { height: 42, width: '50%', marginRight: 8 }]} />
        <div css={skeletonPlaceholderButtonStyle} />
      </div>
    </article>
  );
};
