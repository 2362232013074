import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import type { Invoice } from '@lego/b2b-unicorn-data-access-layer';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { Heading } from '@lego/b2b-unicorn-shared/ui';
import React, { Fragment } from 'react';

import InfoWithAdditionalLines from '../../../components/InfoWithAdditionalLines/InfoWithAdditionalLines';
import StatusSummary from '../../../components/StatusSummary/StatusSummary';
import StatusSummaryItem from '../../../components/StatusSummary/StatusSummaryItem';

type OrderStatusSummaryProps = {
  invoice?: Invoice;
  locale: string;
};

const InvoiceStatusSummary: React.FC<OrderStatusSummaryProps> = ({ invoice, locale }) => {
  const { invoice_summary, invoice_date, order, invoice_total } = useLabels();

  return (
    <StatusSummary>
      {invoice && (
        <Fragment>
          <Heading level={2}>{invoice_summary}</Heading>
          <StatusSummaryItem label={invoice_date}>
            <Date locale={locale}>{invoice.invoiceDate}</Date>
          </StatusSummaryItem>
          <StatusSummaryItem label={order}>
            {invoice.orders?.length ? (
              <InfoWithAdditionalLines
                headline={order}
                lines={invoice.orders.map((order) => order.orderNumber.toString())}
              />
            ) : (
              '-'
            )}
          </StatusSummaryItem>
          <StatusSummaryItem label={invoice_total}>
            <Price locale={locale}>{invoice.invoiceNetValue}</Price>
          </StatusSummaryItem>
        </Fragment>
      )}
    </StatusSummary>
  );
};

export default InvoiceStatusSummary;
