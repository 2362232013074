import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';

import { ExtractElementType } from '../..//utils/TypeScriptHelpers';
import { FooterQuery, FooterQueryVariables } from '../../generated-types/graphql';
import { ContextAbstract } from '../ContextAbstract';
import { FOOTER } from './queries/footer';

export type Footer = FooterQuery['getFooter'];
export type LegalInformation = NonNullable<FooterQuery['getFooter']>['legalInformation'];
export type FooterSectionItem = ExtractElementType<
  NonNullable<NonNullable<FooterQuery['getFooter']>['sections']>
>;

export class FooterDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {};

  public getContent(customerId: number) {
    return this._apolloClient.watchQuery<FooterQuery, FooterQueryVariables>({
      query: FOOTER,
      variables: {
        customerId,
      },
    });
  }
}
