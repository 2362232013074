import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useClaim } from '@lego/b2b-unicorn-shared/components/Claim';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { AnimatedDots, Button } from '@lego/b2b-unicorn-shared/ui';
import { actionsStyle, submittingAnimationStyle } from '@lego/b2b-unicorn-ui-checkout-flow';
import { InlineNotification, NotificationType } from '@lego/b2b-unicorn-ui-components';
import { media } from '@lego/b2b-unicorn-ui-constants';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import StatusSummary from '../../../../components/StatusSummary/StatusSummary';
import StatusSummaryItem from '../../../../components/StatusSummary/StatusSummaryItem';
import { CreateClaimInvoiceInformation } from './CreateClaimInvoiceInformation';

const actionButtonWithWarningStyle = css(actionsStyle, {
  [media.medium]: { maxHeight: 70 },
});
export const ClaimSummary: React.FC = () => {
  const {
    invoiceDetails: { invoiceNumber },
    totalClaimAmount,
    hasError,
    submitClaim,
    submitting,
    setSubmitting,
    validationErrors,
  } = useClaim();
  const { locale } = useSelectedCustomer();
  const {
    total_claim_amount,
    button_submit_claim,
    button_submitting,
    notification_submit_claim_error,
    excel_upload_header_file_too_large,
  } = useLabels();
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  const exceedsTotalFileLimit = validationErrors.some(
    (error) => error.path === 'filesTotalFileSize'
  );

  const onSubmitClaim = () => {
    setSubmitting(true);
    setError(null);
    submitClaim()
      .then((result) => {
        result && history.replace(`/manage/invoice/${invoiceNumber}/create-claim/confirm`);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <StatusSummary>
      <CreateClaimInvoiceInformation invoiceNumber={invoiceNumber} />
      <StatusSummaryItem label={total_claim_amount}>
        <Price locale={locale}>{totalClaimAmount}</Price>
      </StatusSummaryItem>
      <div css={actionButtonWithWarningStyle}>
        <Button
          onClick={onSubmitClaim}
          disabled={hasError}
          warning={!!error}
        >
          {submitting ? (
            <div css={submittingAnimationStyle}>
              <>
                {button_submitting} <AnimatedDots />
              </>
            </div>
          ) : (
            button_submit_claim
          )}
        </Button>
        {error && (
          <InlineNotification
            message={notification_submit_claim_error}
            type={NotificationType.WARNING}
            outlineIcon
          />
        )}
        {exceedsTotalFileLimit && (
          <InlineNotification
            message={excel_upload_header_file_too_large}
            type={NotificationType.WARNING}
            outlineIcon
          />
        )}
      </div>
    </StatusSummary>
  );
};
