import { ApolloError, NetworkStatus } from '@apollo/client';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ADYEN_CLIENT_KEY, ADYEN_ENVIRONMENT } from '@lego/b2b-unicorn-bootstrap/constants';
import { BrickBankPaymentInfoInput } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';
import { useBrickBankPaymentMethods } from '@lego/b2b-unicorn-data-access-layer/react';
import { useRemoveBrickBankStoredCard } from '@lego/b2b-unicorn-data-access-layer/react/hooks/checkout';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import { NotificationType, useNotifications } from '@lego/b2b-unicorn-ui-components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CardPaymentBrickBankDropin from './CardPaymentBrickBankDropin';

type CardPaymentBaseProps = {
  customerId: number;
  onPaymentInfoChange: (isValid: boolean, paymentInfo?: BrickBankPaymentInfoInput) => void;
  onError: (error: ApolloError) => void;
  onReady: () => void;
  loaderElm: JSX.Element;
  disabled?: boolean;
};

type CardPaymentBrickBankProps = CardPaymentBaseProps & {
  locale: string;
  translations: { payment_method_add_card: string };
  onDropinError: (e: string | Error) => void;
};

type AdyenConfiguration = React.ComponentProps<
  typeof CardPaymentBrickBankDropin
>['adyenConfiguration'];
type DropinOptions = React.ComponentProps<typeof CardPaymentBrickBankDropin>['dropinOptions'];

const cardPaymentLogger = logger.createLogger('CardPayment');

const RETRY = 3;
const CardPaymentBrickBank = (props: CardPaymentBrickBankProps) => {
  const {
    customerId,
    onPaymentInfoChange,
    onError,
    onReady,
    locale,
    translations,
    loaderElm,
    onDropinError,
  } = props;
  const { snackbar_remove_creditcard_error } = useLabels();

  const retryCount = useRef(0);
  const retryTimeout = useRef<number>();
  const [retrying, setRetrying] = useState(false);
  const {
    data: paymentMethods,
    error: paymentMethodsError,
    networkStatus: paymentMethodsStatus,
    loading: paymentMethodsLoading,
    refetch: refetchPaymentMethods,
  } = useBrickBankPaymentMethods(customerId);
  const { mutate: removeCard } = useRemoveBrickBankStoredCard(customerId);
  const { addSnackbar, snackbar } = useNotifications();

  const isLoading = useMemo(() => {
    return (
      paymentMethodsLoading ||
      (paymentMethodsStatus !== NetworkStatus.ready && retryCount.current < RETRY)
    );
  }, [paymentMethodsLoading, paymentMethodsStatus]);

  /** The Adyen setup sometimes errors out, but works again one the second run, so we just retry a couple of times,
   * before we actually show the user an error.
   **/
  useEffect(() => {
    if (retryCount.current < RETRY && !retrying && paymentMethodsStatus !== NetworkStatus.ready) {
      setRetrying(true);
      retryTimeout.current = window.setTimeout(async () => {
        retryCount.current++;
        try {
          await refetchPaymentMethods();
          // eslint-disable-next-line no-empty
        } catch {}
        setRetrying(false);
      }, 5000 * retryCount.current);
    } else if (!retrying && paymentMethodsError) {
      cardPaymentLogger.error(paymentMethodsError);
      onError(paymentMethodsError);
    }
  }, [paymentMethodsError, retrying, paymentMethodsStatus, refetchPaymentMethods, onError]);

  useEffect(() => {
    if (paymentMethodsStatus === NetworkStatus.ready) {
      clearTimeout(retryTimeout.current);
      retryTimeout.current = undefined;
      onReady();
    }
  }, [paymentMethodsStatus]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDropinChange = (state: any) => {
    // Remove some unnecessary bits.
    // Our backend doesn't need them,
    // so they have been omitted from the GraphQL schema.
    const cleanedPaymentInfo: BrickBankPaymentInfoInput = state.data
      ? {
          browserInfo: state.data.browserInfo,
          storePaymentMethod: state.data.storePaymentMethod,
          paymentMethod: {
            brand: state.data.paymentMethod?.brand,
            storedPaymentMethodId: state.data.paymentMethod?.storedPaymentMethodId,
            encryptedCardNumber: state.data.paymentMethod?.encryptedCardNumber,
            encryptedExpiryMonth: state.data.paymentMethod?.encryptedExpiryMonth,
            encryptedExpiryYear: state.data.paymentMethod?.encryptedExpiryYear,
            encryptedSecurityCode: state.data.paymentMethod?.encryptedSecurityCode,
            holderName: state.data.paymentMethod?.holderName,
            type: state.data.paymentMethod?.type,
          },
        }
      : ({} as BrickBankPaymentInfoInput);
    onPaymentInfoChange(state.isValid, cleanedPaymentInfo);
  };

  const handleRemoveStoredCard = (cardId: string, resolve: () => void, reject: () => void) => {
    removeCard({ cardId })
      .then((result) => {
        if (result.error) {
          addSnackbar({
            type: NotificationType.WARNING,
            content: snackbar_remove_creditcard_error,
            showDismissButton: true,
            isStacked: !!snackbar,
          });
        }
        if (result.data) {
          resolve();
        }
      })
      .catch(() => {
        reject();
        addSnackbar({
          type: NotificationType.WARNING,
          content: snackbar_remove_creditcard_error,
          showDismissButton: true,
          isStacked: !!snackbar,
        });
      });
  };

  const handleOnDropinError = useCallback(
    (e: string | Error) => {
      cardPaymentLogger.error(e);
      onDropinError(e);
    },
    [onDropinError]
  );

  const paymentMethodsResponse: AdyenConfiguration['paymentMethodsResponse'] = useMemo(() => {
    if (paymentMethods && paymentMethods.getBrickBankPaymentMethods) {
      return {
        ...paymentMethods.getBrickBankPaymentMethods,
        paymentMethods: paymentMethods.getBrickBankPaymentMethods.paymentMethods || [],
        storedPaymentMethods: paymentMethods.getBrickBankPaymentMethods.storedPaymentMethods || [],
      };
    }
    return undefined;
  }, [paymentMethods]);

  const adyenConfiguration: AdyenConfiguration = {
    paymentMethodsResponse,
    clientKey: ADYEN_CLIENT_KEY,
    locale,
    environment: ADYEN_ENVIRONMENT,
    onChange: handleDropinChange,
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        enableStoreDetails: true,
        hideCVC: false,
        name: translations.payment_method_add_card,
      },
      storedCard: {
        hideCVC: false,
      },
    },
  };

  const dropinOptions: DropinOptions = {
    // If set to true, the dropin will render with the first payment method open
    openFirstPaymentMethod: false,
    // Allows the shopper to remove a stored payment method. Must also implement the onDisableStoredPaymentMethod callback.
    showRemovePaymentMethodButton: true,
    // 'onSelect' function is called whenever the user switches between cards.
    onSelect: (component) => {
      handleDropinChange(component.state);
    },
    onDisableStoredPaymentMethod: handleRemoveStoredCard,
    onError: handleOnDropinError,
  };

  return (
    <>
      {isLoading && loaderElm}
      {paymentMethods && (
        <CardPaymentBrickBankDropin
          adyenConfiguration={adyenConfiguration}
          dropinOptions={dropinOptions}
          onError={handleOnDropinError}
        />
      )}
    </>
  );
};

export default CardPaymentBrickBank;
