import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';

export const carouselArrowStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  border: 'none',
  cursor: 'pointer',
  position: 'absolute',
  width: baseSpacing * 3,
  height: baseSpacing * 3,
  margin: 'auto',
  transitionDuration: '100ms',
  transitionProperty: 'background-color',
  outline: 'none',
  outlineStyle: 'solid',
  outlineColor: designToken.interactive.default,
  outlineWidth: 2,
  outlineOffset: -2,
  backgroundColor: designToken.transparent.default,
  '> svg': {
    height: baseSpacing * 2.5,
    width: baseSpacing * 2.5,
    fill: designToken.interactive.default,
  },
  '&:hover': {
    backgroundColor: tokens.color.core.blue[100],
  },
  '&:focus': {
    backgroundColor: tokens.color.core.blue[100],
  },
  '&:disabled': {
    visibility: 'hidden',
  },
});

export const leftArrowStyle = css(carouselArrowStyle, {
  '> svg': {
    transform: 'translateX(1px)',
  },
});

export const rightArrowStyle = css(carouselArrowStyle, {
  '> svg': {
    transform: 'translateX(-1px)',
  },
});
