import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { hasNotFoundError } from '@lego/b2b-unicorn-data-access-layer/utils/hasNotFoundError';
import { useOnCustomerChangeRedirect } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { GoBackLink } from '@lego/b2b-unicorn-shared/ui';
import { Banner } from '@lego/b2b-unicorn-ui-components';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { PageNotFound } from '../../../shared/layouts/PageNotFound';
import { usePromotionDetails } from './components/PromotionDetails/hooks/usePromotionDetails';
import { PromotionDetails } from './components/PromotionDetails/PromotionDetails';
import { PromotionRouterDetailsParams } from './PromotionRouter';

export const PromotionDetailsPage: React.FC = () => {
  useOnCustomerChangeRedirect('/promotion');

  const history = useHistory();
  const { promotionId: rawPromotionId } = useParams<PromotionRouterDetailsParams>();
  const promotionId = parseInt(rawPromotionId);

  const { button_go_to_overview } = useLabels();

  const promotionDetails = usePromotionDetails(promotionId);

  const notFound = useMemo(() => {
    if (hasNotFoundError(promotionDetails.error as Error)) {
      return true;
    }
  }, [promotionDetails]);

  useEffect(() => {
    if (isNaN(promotionId)) {
      history.replace('/promotion'); // Redirect in case of malformed url.
    }
  }, [promotionId, history]);

  return (
    <>
      <Topbar>
        <Logo />
      </Topbar>
      <Banner />
      {notFound && (
        <PageNotFound noTopbar>
          <GoBackLink
            onClick={() => history.replace('/promotion')}
            text={button_go_to_overview}
          />
        </PageNotFound>
      )}
      {!notFound && <PromotionDetails promotionDetails={promotionDetails} />}
    </>
  );
};
