import { NetworkStatus } from '@apollo/client';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useLaunchWindow } from '@lego/b2b-unicorn-data-access-layer/react';
import { hasNotFoundError } from '@lego/b2b-unicorn-data-access-layer/utils/hasNotFoundError';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnCustomerChangeRedirect } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { GoBackLink } from '@lego/b2b-unicorn-shared/ui';
import { Banner } from '@lego/b2b-unicorn-ui-components';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { PageNotFound } from '../../../shared/layouts/PageNotFound';
import LaunchWindowDetails from './components/LaunchWindowDetails/LaunchWindowDetails';
import { LaunchWindowDetailsParams } from './LaunchRouter';

export const LaunchWindowDetailsPage: React.FC = () => {
  useOnCustomerChangeRedirect('/launch');
  const { button_go_to_overview } = useLabels();
  const selectedCustomer = useSelectedCustomer();
  const { year: rawYear, month: rawMonth } = useParams<LaunchWindowDetailsParams>();
  const year = parseInt(rawYear, 10);
  const month = parseInt(rawMonth, 10);

  const history = useHistory();

  const { data, error, status, loading, refetch } = useLaunchWindow(
    selectedCustomer.id,
    year,
    month
  );

  const notFound = useMemo(() => {
    if (status === NetworkStatus.error && hasNotFoundError(error as Error)) {
      return true;
    }
  }, [status, error]);

  const handleCartUpdateError = () => {
    refetch();
  };

  return (
    <>
      <Topbar>
        <Logo />
      </Topbar>
      <Banner />
      {notFound && (
        <PageNotFound noTopbar>
          <GoBackLink
            onClick={() => history.replace('/launch')}
            text={button_go_to_overview}
          />
        </PageNotFound>
      )}
      {!notFound && (
        <LaunchWindowDetails
          launchWindowReference={{ month, year }}
          launchWindowData={data}
          launchWindowError={error}
          isLoading={loading}
          onCartUpdateError={handleCartUpdateError}
        />
      )}
    </>
  );
};
