import type { Invoice, Price } from '@lego/b2b-unicorn-data-access-layer';
import { ExtractElementType } from '@lego/b2b-unicorn-shared/helpers';

type InvoiceOrder = ExtractElementType<Invoice['orders']>;
export type InvoiceOrderItem = ExtractElementType<InvoiceOrder['items']>;
type AlteredInvoiceListItemOrder = {
  orderName: InvoiceOrder['orderName'];
  orderNumber: InvoiceOrder['orderNumber'];
};
type AlteredInvoiceListItem = InvoiceOrderItem & {
  orders: AlteredInvoiceListItemOrder[];
};

export const extractAllItems = (orders: InvoiceOrder[]) => {
  return orders.flatMap((order) => order.items);
};

export const reduceToUniqueItems = (items: InvoiceOrderItem[]) => {
  const foundItemIds: number[] = [];
  return items.filter((item) => {
    if (item.itemNumber === null) {
      return true;
    }
    if (item.itemNumber && foundItemIds.indexOf(item.itemNumber) === -1) {
      foundItemIds.push(item.itemNumber);
      return true;
    }
  });
};

const sortByItemNumber = (items: InvoiceOrderItem[]) => {
  return [...items].sort((a, b) => {
    return (a.itemNumber || 0) - (b.itemNumber || 0);
  });
};

export const findOrdersWithItem = (orders: InvoiceOrder[], itemNumberToFind?: number | null) => {
  return orders.filter((order) => order.items.find((item) => item.itemNumber === itemNumberToFind));
};

export const findItemsWithId = (items: InvoiceOrderItem[], itemNumberToFind?: number | null) => {
  return items.filter((thisItem) => thisItem.itemNumber === itemNumberToFind);
};

export const calculateTotalPriceOfItems = (items: InvoiceOrderItem[]): Price => {
  const currency = items[0].price.currency;
  const totalAmount = items
    .map((item) => item.price.estimatedNetInvoicedPrice)
    .reduce((previous, current) => previous + current);
  return {
    estimatedNetInvoicedPrice: totalAmount,
    currency: currency,
    __typename: 'Price',
  };
};

export const calculateTotalQuantityOfItems = (items: InvoiceOrderItem[]): number => {
  return items.map((item) => item.quantity).reduce((previous, current) => previous + current);
};

/**
 * Parsing goes on here.
 * Data model from API has orders containing items.
 * We need to show them as a list of items with a sublist of one or more orders.
 * */
export const buildInvoiceItemList = (orders: Invoice['orders']) => {
  const allItems = extractAllItems(orders);
  const sortedUniqueItems = sortByItemNumber(reduceToUniqueItems(allItems));
  const parsedItems: AlteredInvoiceListItem[] = [];
  sortedUniqueItems.forEach((item) => {
    const ordersWithThisItem = findOrdersWithItem(orders, item.itemNumber);
    const allOccurrencesOfThisItem = findItemsWithId(allItems, item.itemNumber);
    parsedItems.push({
      itemNumber: item.itemNumber,
      materialId: item.materialId,
      name: item.name,
      theme: item.theme,
      quantity: calculateTotalQuantityOfItems(allOccurrencesOfThisItem),
      price: calculateTotalPriceOfItems(allOccurrencesOfThisItem),
      invoiceLineNumber: item.invoiceLineNumber,
      orders: ordersWithThisItem.map((order) => {
        return {
          orderNumber: order.orderNumber,
          orderName: order.orderName,
        };
      }),
    });
  });
  return parsedItems;
};
