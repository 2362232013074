import { gql } from '@apollo/client';

export const EMPTY_LAUNCH_WINDOW = gql`
  mutation emptyLaunchWindow($customerId: Int!, $year: Int!, $month: Int!) {
    emptyCart(
      customerId: $customerId
      cartReference: { cartType: LAUNCH, launch: { year: $year, month: $month } }
    ) {
      ...cartKey
      items {
        product {
          materialId
        }
        quantity
      }
    }
  }
`;
