import { NetworkStatus } from '@apollo/client';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useInvoice } from '@lego/b2b-unicorn-data-access-layer/react';
import { hasNotFoundError } from '@lego/b2b-unicorn-data-access-layer/utils/hasNotFoundError';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnCustomerChangeRedirect } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { GoBackLink } from '@lego/b2b-unicorn-shared/ui';
import { GoToTop } from '@lego/b2b-unicorn-ui-components';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Banner } from '../../../shared/Components/Notifications';
import { PageNotFound } from '../../../shared/layouts/PageNotFound';
import { CreateClaim } from './CreateClaim/CreateClaim';

interface IInvoiceDetailsRouteParams {
  invoiceNumber: string;
}

const CreateClaimPage = () => {
  useOnCustomerChangeRedirect('/manage/invoices');

  const { endTransaction } = useApmTransaction();
  const { id: customerId } = useSelectedCustomer();
  const { button_go_to_overview, content_system_feedback_error, page_not_found } = useLabels();

  const { invoiceNumber: rawInvoiceNumber } = useParams<IInvoiceDetailsRouteParams>();
  const invoiceNumber = parseInt(rawInvoiceNumber);
  const { loading, data, error, status } = useInvoice(invoiceNumber, customerId);

  const history = useHistory();

  const notFound = useMemo(() => {
    if (status === NetworkStatus.error && hasNotFoundError(error as Error)) {
      return true;
    }
  }, [status, error]);

  useEffect(() => {
    if (isNaN(invoiceNumber)) {
      history.replace('/manage/invoices'); // Redirect in case of malformed url.
    }
  }, [invoiceNumber, history]);

  useEffect(() => {
    if (data || error) {
      endTransaction && endTransaction();
    }
  }, [data, error, endTransaction]);

  return (
    <Fragment>
      <TopbarImplementation />
      <Banner />
      {(notFound || error) && (
        <PageNotFound
          noTopbar
          text={notFound ? page_not_found : content_system_feedback_error}
        >
          <GoBackLink
            onClick={() => history.replace('/manage/invoices')}
            text={button_go_to_overview}
          />
        </PageNotFound>
      )}
      {!notFound && (
        <CreateClaim
          invoiceData={data}
          customerId={customerId}
          isLoading={loading}
        />
      )}
      <GoToTop />
    </Fragment>
  );
};

export default CreateClaimPage;
