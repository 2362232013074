import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  itemIDHeaderStyle,
  tableHeaderRightStyle,
  tableHeaderSortOptionRightStyle,
  tableHeaderSortOptionStyle,
  tableHeaderStyle,
  tableHeadStyle,
} from '@lego/b2b-unicorn-ui-checkout-flow';
import { SortingButton } from '@lego/b2b-unicorn-ui-components';
import { ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';

interface ITableHeader {
  activeSortingField?: ProductTableColumnType;
  setActiveSortingField: (column: ProductTableColumnType) => void;
  isAscending: boolean;
  setIsAscending: (isAscending: boolean) => void;
  hideExpectedDeliveryDate?: boolean;
}

const TableHeader = ({
  activeSortingField,
  setActiveSortingField,
  isAscending,
  setIsAscending,
  hideExpectedDeliveryDate = false,
}: ITableHeader) => {
  const {
    item_id,
    name,
    theme,
    price_per_piece,
    quantity,
    delivery_date_expected,
    total_pieces,
    your_price,
  } = useLabels();

  const updateSorting = (column: ProductTableColumnType, isAscending: boolean) => {
    setActiveSortingField(column);
    setIsAscending(isAscending);
  };

  return (
    <thead css={tableHeadStyle}>
      <tr>
        <th css={itemIDHeaderStyle}>
          <div css={tableHeaderSortOptionStyle}>
            <SortingButton
              isActive={ProductTableColumnType.ITEM_ID === activeSortingField}
              isAscending={isAscending}
              columnName={ProductTableColumnType.ITEM_ID}
              onClickHandler={updateSorting}
            />
            {item_id}
          </div>
        </th>
        <th css={tableHeaderStyle}>{name}</th>
        <th css={tableHeaderStyle}>
          <div css={tableHeaderSortOptionStyle}>
            <SortingButton
              isActive={ProductTableColumnType.THEME === activeSortingField}
              isAscending={isAscending}
              columnName={ProductTableColumnType.THEME}
              onClickHandler={updateSorting}
            />
            {theme}
          </div>
        </th>
        {!hideExpectedDeliveryDate && (
          <th css={tableHeaderRightStyle}>
            <div css={tableHeaderSortOptionStyle}>
              <SortingButton
                isActive={ProductTableColumnType.EXPECTED_DELIVERY === activeSortingField}
                isAscending={isAscending}
                columnName={ProductTableColumnType.EXPECTED_DELIVERY}
                onClickHandler={updateSorting}
              />
              {delivery_date_expected}
            </div>
          </th>
        )}
        <th css={tableHeaderRightStyle}>{price_per_piece}</th>
        <th css={tableHeaderRightStyle}>{quantity}</th>
        <th css={tableHeaderRightStyle}>{total_pieces}</th>
        <th css={tableHeaderRightStyle}>
          <div css={tableHeaderSortOptionRightStyle}>
            <SortingButton
              isActive={ProductTableColumnType.YOUR_PRICE === activeSortingField}
              isAscending={isAscending}
              columnName={ProductTableColumnType.YOUR_PRICE}
              onClickHandler={updateSorting}
            />
            {your_price}
          </div>
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
