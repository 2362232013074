import { css } from '@emotion/react';
import { colors, font } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

type BeforeDiscountPriceProps = {
  children: React.ReactNode;
};

const beforeDiscountPriceCss = css({
  textDecoration: 'line-through',
  fontSize: font.size.medium,
  color: colors.klik.slate400,
  display: 'block',
  fontWeight: 400,

  '&:only-child': {
    textDecoration: 'none',
  },
});

export const BeforeDiscountPrice: React.FC<BeforeDiscountPriceProps> = ({ children }) => {
  return <span css={beforeDiscountPriceCss}>{children}</span>;
};
