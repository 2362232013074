import { CartItemCounter } from '@lego/b2b-unicorn-ui-checkout-flow';
import React from 'react';

import { counterStyle } from './CartPreviewItemRowStyles';

interface ICartPreviewItemCounter {
  quantity: number;
  onChange: (quantity: number) => void;
}

const CartPreviewItemCounter = ({ quantity, onChange }: ICartPreviewItemCounter) => {
  return (
    <CartItemCounter
      quantity={quantity}
      onChange={onChange}
      customStyle={counterStyle}
    />
  );
};

export default CartPreviewItemCounter;
