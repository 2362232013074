import React, { createContext, ReactNode, useEffect, useRef, useState } from 'react';

import { PopoverContainer } from './PopoverContainer';

interface PopoverRefContextValue {
  listContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  triggerRef: React.MutableRefObject<HTMLButtonElement | null>;
}
export const PopoverRefContext = createContext<PopoverRefContextValue>(
  {} as PopoverRefContextValue
);

interface PopoverOpenContextValue {
  popoverOpen: boolean;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  focusedElementBeforeOpen: unknown;
  setFocusedElementBeforeOpen: React.Dispatch<React.SetStateAction<unknown>>;
}
export const PopoverOpenContext = createContext<PopoverOpenContextValue>({
  popoverOpen: false,
} as PopoverOpenContextValue);

type PopoverProps = {
  children: ReactNode;
  open?: boolean;
  onOpened?: () => void;
  onClosed?: () => void;
};
export const Popover: React.FC<PopoverProps> = ({ children, onOpened, onClosed, open }) => {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(!!open);
  const [focusedElementBeforeOpen, setFocusedElementBeforeOpen] = useState<unknown>();

  useEffect(() => {
    setPopoverOpen(!!open);
  }, [open]);

  useEffect(() => {
    if (popoverOpen) {
      onOpened && onOpened();
    } else {
      onClosed && onClosed();
    }
  }, [onClosed, onOpened, popoverOpen]);

  return (
    <PopoverRefContext.Provider value={{ listContainerRef, triggerRef }}>
      <PopoverOpenContext.Provider
        value={{
          popoverOpen,
          setPopoverOpen,
          focusedElementBeforeOpen,
          setFocusedElementBeforeOpen,
        }}
      >
        <PopoverContainer>{children}</PopoverContainer>
      </PopoverOpenContext.Provider>
    </PopoverRefContext.Provider>
  );
};
