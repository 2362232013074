import { css } from '@emotion/react';
import React from 'react';

import { GoBackLink } from '../GoBackLink';
import { baseSpacing, media } from '../theme';

const subpageHeaderNavStyle = css({
  display: 'flex',
  alignItems: 'center',

  'h1, h2, h3, h4, h5, h6': {
    flexGrow: 1,
    margin: 0,
    [media.medium]: {
      paddingLeft: baseSpacing * 3,
    },
  },
  /* Hide 'back' button on mobile: */
  '> div:first-of-type': {
    display: 'none',
    [media.medium]: {
      display: 'flex',
    },
  },
  '&:last-child': {
    button: {
      width: 58,
      [media.medium]: {
        width: 'auto',
      },
    },
  },
});

type SubpageHeaderNavProps = {
  children: React.ReactNode;

  goBackText: string;
  onGoBack: () => void;
};

export const SubpageHeaderNav: React.FC<SubpageHeaderNavProps> = ({
  children,
  onGoBack,
  goBackText,
}) => {
  return (
    <div css={subpageHeaderNavStyle}>
      <GoBackLink
        text={goBackText}
        onClick={onGoBack}
      />
      {children}
    </div>
  );
};
