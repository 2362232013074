import { css } from '@emotion/react';
import React from 'react';

import { designToken, font } from '../theme';

type LinearProgressProps = {
  value: number | undefined;
  indeterminate?: boolean;
};

const linearProgressContainerStyle = css({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: designToken.skeleton.default,
  height: font.size.tiny / 2,
  borderRadius: font.size.tiny,
});

const linearProgressStyle = (value: number | undefined) =>
  css({
    height: '100%',
    width: value ? `${value}%` : 0,
    backgroundColor: designToken.primary.active,
    borderRadius: font.size.tiny,
    transition: 'width 0.3s ease',
  });

const linearProgressIndeterminateStyle = css({
  '@keyframes linearProgressIndeterminateAnimation': {
    '0%': {
      left: '-100%',
    },
    '100%': {
      left: 0,
    },
  },

  height: '100%',
  position: 'absolute',
  willChange: 'left',
  minWidth: '200% !important',
  animationTimeline: 'auto !important',
  animationRangeStart: 'normal !important',
  animationRangeEnd: 'normal !important',
  transition: 'all 0.3s ease 0s',
  background:
    'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06) 1rem, rgba(0, 0, 0, 0) 1rem, rgba(0, 0, 0, 0) 2rem) 0% 0% / 200%',
  borderRadius: font.size.tiny,
  animation:
    '7s linear 0s infinite normal none running linearProgressIndeterminateAnimation !important',
});

export const LinearProgress: React.FC<LinearProgressProps> = ({ value, indeterminate }) => {
  return (
    <div css={linearProgressContainerStyle}>
      <div
        css={
          value === undefined || indeterminate
            ? linearProgressIndeterminateStyle
            : linearProgressStyle(value)
        }
        role="progressbar"
        aria-valuenow={value}
        aria-valuemax={100}
        aria-valuemin={0}
      />
    </div>
  );
};
