import { deepEqual } from 'fast-equals';
import React from 'react';
import type { CellBase } from 'react-spreadsheet';

import { AvailabilityStatus } from '../../../../ui/AvailabilityStatus';
import { useBulkQuickAddContext } from '../../BulkQuickAddContext';
import { IStockStatus } from '../../types';

type AvailabilityCellProps = {
  cell?: CellBase<IStockStatus | ''>;
};

export const AvailabilityCell: React.FC<AvailabilityCellProps> = React.memo(
  ({ cell }) => {
    const context = useBulkQuickAddContext();

    if (!cell || !cell.value) {
      return null;
    }

    const stockStatus = cell.value;

    return (
      <AvailabilityStatus
        status={stockStatus.status}
        expectedBackDate={stockStatus.expectedBackDate}
        translations={context.translations.availabilityTranslations}
        locale={context.locale}
      />
    );
  },
  (prevProps, nextProps) => deepEqual(prevProps.cell, nextProps.cell)
);
AvailabilityCell.displayName = 'AvailabilityCell';
