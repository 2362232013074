import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

export const useOnCustomerChangeRedirect = (redirectPath: string) => {
  const history = useHistory();
  const selectedCustomer = useSelectedCustomer();
  const prevSelectedCustomer = useRef(selectedCustomer);

  useEffect(() => {
    if (selectedCustomer.id !== prevSelectedCustomer.current.id) {
      history.replace(redirectPath);
      prevSelectedCustomer.current = selectedCustomer;
    }
  }, [history, redirectPath, selectedCustomer]);
};
