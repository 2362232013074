import { gql } from '@apollo/client';

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($customerId: Int!, $query: String!) {
    searchProducts(customerId: $customerId, query: $query) {
      name
      materialId
      stockStatus {
        status
        expectedBackDate
      }
      tags
      ... on Box {
        itemNumber
      }
    }
  }
`;
