import { BehaviorSubject } from 'rxjs';

import { getFileType } from './utils/getFileType';
import { imageConversion } from './workers/imageConversion';
import { md5ChecksumAsBase64 } from './workers/md5ChecksumAsBase64';

export enum FileUploadItemStatus {
  Idle,
  Processing,
  Converting,
  Integrity,
  Checksum,
  Uploading,
  Success,

  Cancelled,
  Failed,
  Unsupported,
  FileSizeExceeded,
  TotalFileSizeExceeded,
}

type MetadataKey = 'Content-MD5' | 'Content-Type' | string;

type SetUploadUrlResult = {
  url: string;
  metadata?: Record<MetadataKey, string>;
};

export class FileUploadItem {
  public readonly uniqueHashValue: string;
  public readonly stack?: string;
  public state = new BehaviorSubject({
    progress: 0,
    sizeBytes: 0,
    bytesUploaded: 0,
    status: FileUploadItemStatus.Idle,
  });
  private _file: File;
  private readonly _getUploadUrl: (
    file: File,
    metadata: Record<MetadataKey, string>
  ) => Promise<SetUploadUrlResult>;
  private xhr: XMLHttpRequest | null = null;
  private _metadata: Record<MetadataKey, string> = {};

  constructor(
    uniqueHashValue: string,
    file: File,
    setUploadUrl: (
      file: File,
      metadata: Record<MetadataKey, string>
    ) => Promise<SetUploadUrlResult>,
    stack?: string
  ) {
    this.uniqueHashValue = uniqueHashValue;
    this._file = file;
    this._getUploadUrl = setUploadUrl;
    this.stack = stack;

    const state = this.state.value;
    state.sizeBytes = file.size;
    this.state.next(state);
  }

  public async mimeType(): Promise<string> {
    return getFileType(this._file);
  }

  public setStatus(status: FileUploadItemStatus) {
    const state = this.state.value;
    if (state.status === status) {
      return;
    }

    this.state.next({
      ...state,
      status,
    });
  }

  public get status() {
    return this.state.value.status;
  }

  public get file() {
    return this._file;
  }

  public async convertTo(options: Parameters<typeof imageConversion>[1]): Promise<void> {
    this.setStatus(FileUploadItemStatus.Converting);
    return imageConversion(this._file, options)
      .then(this.imageConversionHandler.bind(this, options))
      .catch((error) => {
        throw error;
      });
  }

  private imageConversionHandler(options: Parameters<typeof imageConversion>[1], blob: Blob) {
    const fileNameWithoutExtension = this._file.name.split('.').slice(0, -1).join('.');

    this._file = new File([blob], `${fileNameWithoutExtension}.jpg`, {
      type: options.imageType,
    });

    const state = this.state.value;
    this.state.next({
      ...state,
      sizeBytes: this._file.size,
    });
  }

  /*
   * Calculate the MD5 hash of the file content, returns as a Base64 encoded string
   * This is used for an AWS pre-signed URL to upload the file
   */
  public async awsContentMd5(): Promise<string> {
    return md5ChecksumAsBase64(this._file);
  }

  public async upload(extraHeaders?: Record<string, string>): Promise<void> {
    if (this.status! < FileUploadItemStatus.Uploading) {
      throw new Error('File is not in a state to upload');
    }

    const uploadUrl = await this._getUploadUrl(this.file, this.metadata);
    if (uploadUrl.metadata) {
      Object.entries(uploadUrl.metadata).forEach(([key, value]) => {
        this.setMetadata(key, value);
      });
    }
    this.setStatus(FileUploadItemStatus.Uploading);
    this.xhr = new XMLHttpRequest();
    this.xhr.withCredentials = !!extraHeaders || false;

    const uploadPromiseCallback = (resolve: () => void, reject: (error: Error) => void) => {
      this.xhr!.upload.onprogress = (event) => {
        const state = this.state.value;
        this.state.next({
          ...state,
          progress: Math.round((event.loaded / event.total) * 100),
          bytesUploaded: event.loaded,
        });
      };

      this.xhr!.open('PUT', uploadUrl.url);

      this.xhr!.onerror = () => {
        this.setStatus(FileUploadItemStatus.Failed);
        reject(new Error('Failed to upload file'));
      };

      this.xhr!.onloadend = () => {
        if (this.xhr!.status === 200) {
          this.setStatus(FileUploadItemStatus.Success);
          resolve();
        }

        this.setStatus(FileUploadItemStatus.Failed);
        reject(new Error('Failed to upload file'));
      };

      this.xhr!.onabort = () => {
        this.setStatus(FileUploadItemStatus.Cancelled);
        reject(new Error('Upload cancelled'));
      };

      if (extraHeaders) {
        Object.entries(extraHeaders).forEach(([key, value]) => {
          this.xhr!.setRequestHeader(key, value);
        });
      }

      this.xhr!.send(this._file);
    };

    return new Promise(uploadPromiseCallback.bind(this));
  }

  public cancel() {
    if (this.status === FileUploadItemStatus.Uploading) {
      this.xhr?.abort();
    }
    this.setStatus(FileUploadItemStatus.Cancelled);
  }

  public setMetadata(key: MetadataKey, value: string) {
    this._metadata[key] = value;
  }

  public get metadata() {
    return this._metadata;
  }

  public toString() {
    return `FileUploadItem-${this.uniqueHashValue}-${this.status}`;
  }
}
