import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { optionsContainerStyle, optionWithLinkStyle } from '../../styles/selectoptions';
import {
  containerStyle,
  initialVisibilityStyle,
  isVisibleStyle,
  optionsContainerDesktopStyle,
} from './NavigationTabsStyle';

interface INavigationTabsProps {
  /** Array of tabs to show. */
  tabs: {
    /** Label for the tab. */
    label: string;
    /** Click will navigate to this path using React Router */
    url: string;
  }[];
}

/** Tabbed navigation component. Will transform into dropdown menu on smaller screens. */
export const NavigationTabs = ({ tabs }: INavigationTabsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      setIsOpen(true);
    }
  };

  const containerMouseUpHandler = () => {
    /** Open container when clicking it... */
    setIsOpen(true);
  };

  const tabMouseUpHandler = (e: React.MouseEvent<HTMLDivElement>, linkUrl: string) => {
    if (isOpen && linkUrl === location.pathname) {
      /** Close dropdown when tapping already active link. */
      setIsOpen(false);
      /**
       * Prevent event from bubbling up to 'containerMouseUpHandler'
       * as this would re-open the dropdown. */
      e.stopPropagation();
    }
  };

  const containerBlurHandler = (e: React.FocusEvent<HTMLDivElement>) => {
    /**
     * When container loses focus, e.g. by tapping outside, close the dropdown.
     * UNLESS it loses focus to one of its children
     * (in that case, closing the dropdown would prevent the <Link> element from triggering. */
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <div css={containerStyle}>
      <div
        css={[optionsContainerStyle, optionsContainerDesktopStyle]}
        role="button"
        tabIndex={-1}
        onMouseUp={containerMouseUpHandler}
        onBlur={containerBlurHandler}
        onKeyDown={onKeyDownHandler}
      >
        {tabs.map((tab) => {
          const tabStyle = [
            optionWithLinkStyle,
            initialVisibilityStyle(tab.url === location.pathname),
          ];
          if (isOpen || tab.url === location.pathname) {
            tabStyle.push(isVisibleStyle);
          }
          return (
            <div
              key={'nav-tab-' + tab.url}
              css={tabStyle}
              onMouseUp={(e) => {
                tabMouseUpHandler(e, tab.url);
              }}
              role="presentation"
            >
              <Link to={tab.url}>{tab.label}</Link>
            </div>
          );
        })}
        <Icon type={IconType.CHEVRON_DOWN} />
      </div>
    </div>
  );
};

export default NavigationTabs;
