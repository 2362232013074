import { css } from '@emotion/react';
import { colors, media } from '@lego/b2b-unicorn-ui-constants';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { topbarButtonStyle } from '../../styles';
import { IMenuLink } from './MenuItem';

const menuIconStyle = css({
  color: colors.klik.slate10,
  textDecoration: 'none',
  padding: 0,
  display: 'flex',
  borderWidth: 0,
  borderStyle: 'solid',
  borderColor: 'transparent',
  boxSizing: 'border-box',

  [media.small]: {
    alignItems: 'center',
    boxShadow: 'none',
    borderWidth: 0,
    height: 72,
  },
  '&:hover, &:focus': {
    outline: 'none',
    color: colors.klik.lightBlue200,
    '> div': {
      '> div:first-of-type': {
        outline: 'none',
        ...topbarButtonStyle['&:hover, &:focus'],
      },
    },
  },
});

const MenuIconLink: FunctionComponent<IMenuLink> = ({ children, path, dataE2e }) => {
  return (
    <Link
      to={path}
      data-e2e={dataE2e}
      css={menuIconStyle}
      tabIndex={0}
    >
      {children}
    </Link>
  );
};

export default MenuIconLink;
