export const reduceArrayToUniqueItemsByProperty = <T, K extends keyof T>(
  array: T[],
  property: K
) => {
  const uniqueItems = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item[property])) {
      map.set(item[property], true);
      uniqueItems.push(item);
    }
  }
  return uniqueItems;
};
