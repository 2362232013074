import { NetworkStatus } from '@apollo/client';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useInvoices } from '@lego/b2b-unicorn-data-access-layer/react';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { ExtractElementType } from '@lego/b2b-unicorn-shared/helpers';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import {
  Container,
  ContentSystemFeedback,
  FlexBox,
  Icon,
  IconType,
  Menu,
  MenuItem,
  SystemFeedbackType,
  Tag,
  TagType,
} from '@lego/b2b-unicorn-shared/ui';
import {
  LoadMoreButton,
  NotificationType,
  useNotifications,
} from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';
import { useHasFeature } from '@lego/b2b-unicorn-ui-featuretogglecontext';
import { useStateWithUrl } from '@lego/b2b-unicorn-ui-utils';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import SkeletonTable from '../../../components/SkeletonLoaders/SkeletonTable';
import SortableTable from '../../../components/SortableTable/SortableTable';
import { ColumnDefinitionType } from '../../../components/SortableTable/types';
import { TABLE_ROWS_PER_PAGE } from '../../../constants';
import { basicPageStyle } from '../../../styles/general';
import { tableStyle } from '../../../styles/table';
import {
  getSortingColumKeyFromUrlParam,
  getSortingDirectionFromUrlParam,
  SortingDirection,
} from '../../../utils/Sorting';
import { numericContentStyle, tableContainerStyle } from '../OrderList/styles';
import { InvoiceDownloadPdfMenuItem } from './components/InvoiceDownloadPdfMenuItem';
import { invoiceListStyles, invoiceListTableResponsivenessStyle } from './styles';

// Inferred types from the DataAccessLayer hook
type InvoicesFilter = Parameters<typeof useInvoices>[1];
type Invoice = ExtractElementType<
  NonNullable<ReturnType<typeof useInvoices>['data']>['getInvoices']['invoices']
>;

const invoiceValueSortingFunction = (a: Invoice, b: Invoice, direction: SortingDirection) =>
  (a.invoiceNetValue.amount - b.invoiceNetValue.amount) * direction;

const InvoiceList = () => {
  const { startTransaction, endTransaction } = useApmTransaction();
  const [loadMoreInProgress, setLoadMoreInProgress] = useState(false);
  const [selectedFilters, setSelectedFilters] = useStateWithUrl<InvoicesFilter>();
  const [initialFilters] = useState(() => {
    if (selectedFilters?.offset) {
      delete selectedFilters.offset;
      setSelectedFilters(selectedFilters);
    }

    return selectedFilters;
  });

  const history = useHistory();
  const selectedCustomer = useSelectedCustomer();
  const showClaims = useHasFeature('claims', false);

  const {
    create_claim,
    snackbar_download_file_error,
    invoice_number,
    order_number,
    order_name,
    invoice_date,
    total_price,
    actions,
    invoices_all,
    error_occurred,
    quick_order_search_no_results,
    button_load_more,
    new_tag,
  } = useLabels();
  const { snackbar, addSnackbar } = useNotifications();

  const { refetch, fetchMore, error, networkStatus, data, loading } = useInvoices(
    selectedCustomer.id,
    initialFilters
      ? {
          ...initialFilters,
          limit: TABLE_ROWS_PER_PAGE,
          offset: initialFilters?.offset || 0,
        }
      : undefined
  );
  const totalCount = data?.getInvoices.totalCount || 0;

  useEffect(() => {
    if ((data || error) && !loading && networkStatus !== NetworkStatus.setVariables) {
      endTransaction && endTransaction();
    }
  }, [data, endTransaction, error, loading, networkStatus]);

  useEffect(() => {
    error && logger.error(error);
  }, [error]);

  const handleLoadMoreClick = () => {
    if (totalCount > (selectedFilters?.offset || TABLE_ROWS_PER_PAGE)) {
      const updatedFilters = { ...selectedFilters };
      updatedFilters.limit = TABLE_ROWS_PER_PAGE;
      updatedFilters.offset = data?.getInvoices.invoices.length;
      setSelectedFilters(updatedFilters);
      setLoadMoreInProgress(true);
      startTransaction && startTransaction();
      fetchMore({
        variables: {
          filter: {
            ...updatedFilters,
          },
        },
      }).finally(() => {
        setLoadMoreInProgress(false);
      });
    }
  };

  const handlePdfDownloadError = (error: Error) => {
    logger.error(error);
    addSnackbar({
      type: NotificationType.WARNING,
      content: snackbar_download_file_error,
      showDismissButton: true,
      isStacked: !!snackbar,
    });
  };

  const handleSortingChange = (key: keyof Invoice, direction: SortingDirection) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.offset = 0;
    updatedFilters.limit = TABLE_ROWS_PER_PAGE;

    if (direction === SortingDirection.OFF) {
      delete updatedFilters.sort;
    } else {
      updatedFilters.sort = [`${key}:${SortingDirection[direction].toLowerCase()}`];
    }
    setSelectedFilters(updatedFilters);
    startTransaction && startTransaction();
    refetch({ filter: updatedFilters });
  };

  const invoiceActionsRenderFunction = (row: Invoice) => {
    // Only show the menu "..." button if it'll contain any actions.
    const showMenuButton = row.hasPdf || showClaims;

    return (
      <Fragment>
        <Container
          flex={{ alignItems: 'flex-end', flexDirection: 'column' }}
          padding={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
        >
          {showMenuButton && (
            <Menu>
              <MenuItem>
                <Icon
                  type={IconType.ELLIPSIS}
                  width={font.size.large}
                />
                <Menu>
                  {row.hasPdf && (
                    <InvoiceDownloadPdfMenuItem
                      invoiceNumber={row.invoiceNumber}
                      onError={handlePdfDownloadError}
                    />
                  )}
                  {showClaims && (
                    <MenuItem
                      onClick={() => {
                        history.push(`/manage/invoice/${row.invoiceNumber}/create-claim`);
                      }}
                    >
                      <a
                        href={'#'}
                        onClick={(e) => e.preventDefault()}
                      >
                        <FlexBox
                          gap={baseSpacing}
                          alignItems={'center'}
                        >
                          {create_claim}{' '}
                          <Tag
                            fixedWidth={false}
                            type={TagType.ORANGE_BOLD}
                          >
                            {new_tag}
                          </Tag>
                        </FlexBox>
                      </a>
                    </MenuItem>
                  )}
                </Menu>
              </MenuItem>
            </Menu>
          )}
        </Container>
      </Fragment>
    );
  };

  const colHeaders: ColumnDefinitionType<Invoice, keyof Invoice>[] = [
    {
      key: 'invoiceNumber',
      header: invoice_number,
      isSortable: true,
      renderFunction: (row) => (
        <Link to={'/manage/invoice/' + row.invoiceNumber}>{row.invoiceNumber}</Link>
      ),
    },
    {
      key: 'orders',
      header: order_number,
      renderFunction: (row) => (
        <div>
          {row.orders.map((order) => (
            <div key={'orderno-' + order.orderNumber}>{order.orderNumber}</div>
          ))}
        </div>
      ),
    },
    {
      key: 'orders',
      header: order_name,
      renderFunction: (row) => (
        <div>
          {row.orders.map((order) => (
            <div key={'orderno-' + order.orderName}>{order.orderName}</div>
          ))}
        </div>
      ),
    },
    {
      key: 'invoiceDate',
      header: invoice_date,
      isSortable: true,
      renderFunction: (row) => (
        <div css={numericContentStyle}>
          <Date locale={selectedCustomer.locale}>{row.invoiceDate}</Date>
        </div>
      ),
    },
    {
      key: 'invoiceNetValue',
      header: total_price,
      isSortable: true,
      renderFunction: (row) => (
        <div css={numericContentStyle}>
          <Price locale={selectedCustomer.locale}>{row.invoiceNetValue}</Price>
        </div>
      ),
      sortingFunction: invoiceValueSortingFunction,
    },
    {
      key: 'hasPdf',
      header: actions,
      textAlignHeader: 'center',
      renderFunction: (row) => invoiceActionsRenderFunction(row),
    },
  ];

  const initialLoading = networkStatus === NetworkStatus.loading;
  const refetching =
    networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.setVariables;
  const fetchingMore = networkStatus === NetworkStatus.fetchMore;

  const sortFromUrlParam = selectedFilters?.sort?.slice(0, 1).pop();
  const currentOffset = selectedFilters?.offset || 0;

  const showTable = (!initialLoading && !refetching) || fetchingMore;
  const showLoadMore =
    (!error &&
      !initialLoading &&
      !refetching &&
      totalCount > (currentOffset + TABLE_ROWS_PER_PAGE || TABLE_ROWS_PER_PAGE)) ||
    loadMoreInProgress;

  return (
    <div css={[basicPageStyle, tableStyle, invoiceListStyles, invoiceListTableResponsivenessStyle]}>
      <div css={tableContainerStyle}>
        {(initialLoading || refetching) && (
          <Fragment>
            <h2>{invoices_all}</h2>
            <SkeletonTable rowsToRender={10} />
          </Fragment>
        )}
        {error ? (
          <ContentSystemFeedback
            type={SystemFeedbackType.ERROR}
            text={error_occurred}
          />
        ) : (
          data &&
          data.getInvoices.invoices?.length === 0 && (
            <ContentSystemFeedback text={quick_order_search_no_results} />
          )
        )}
        {data && data.getInvoices.invoices && data.getInvoices.invoices.length > 0 && showTable && (
          <Fragment>
            <h2>
              {invoices_all} ({data.getInvoices.totalCount})
            </h2>
            <SortableTable
              columns={colHeaders}
              rows={data.getInvoices.invoices}
              onSortCallbackHandler={handleSortingChange}
              columnKeyToSortBy={getSortingColumKeyFromUrlParam(sortFromUrlParam)}
              sortingDirection={getSortingDirectionFromUrlParam(sortFromUrlParam)}
            />
          </Fragment>
        )}
      </div>
      {showLoadMore && (
        <LoadMoreButton
          handleLoadMoreClick={handleLoadMoreClick}
          loadMoreInProgress={loadMoreInProgress}
          text={button_load_more}
        />
      )}
    </div>
  );
};

export default InvoiceList;
