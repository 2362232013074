import type { Client } from '@optimizely/optimizely-sdk';
import type { ReactSDKClient } from '@optimizely/react-sdk';
import { OptimizelyProvider, useDecision } from '@optimizely/react-sdk';
import { PropsWithChildren, useEffect } from 'react';

type OnReadyResponse = Awaited<ReturnType<Client['onReady']>>;

interface IFeatureToggleProvider {
  optimizelyClient: ReactSDKClient;
  customerId: number;
  salesOrg: number;
  parentC6Id?: number | null;
  email: string;
  onReadyHandler?: (response: OnReadyResponse) => void;
}

const FeatureToggleProvider = (props: PropsWithChildren<IFeatureToggleProvider>) => {
  const { children, optimizelyClient, customerId, salesOrg, parentC6Id, email, onReadyHandler } =
    props;

  useEffect(() => {
    let response: OnReadyResponse;
    const addOnReadyHandler = async () => {
      if (onReadyHandler) {
        response = await optimizelyClient.onReady();
      }
    };

    addOnReadyHandler().finally(() => {
      onReadyHandler && onReadyHandler(response);
    });
  }, [optimizelyClient, onReadyHandler]);

  const userAttributes = {
    customerId,
    salesOrg,
    parentC6Id: parentC6Id ?? null,
    email,
  };

  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{ id: 'webshop-user', attributes: userAttributes }}
    >
      {children}
    </OptimizelyProvider>
  );
};

const useHasFeature = (featureName: string, fallbackValue: boolean) => {
  const [decision, clientReady] = useDecision(featureName, { autoUpdate: true });

  if (!clientReady) {
    return fallbackValue;
  }

  return decision.enabled;
};

export { FeatureToggleProvider, useHasFeature };
