import {
  skeletonBottomContainerStyle,
  skeletonImageStyle,
  skeletonPlaceholderButtonStyle,
  skeletonPlaceholderStyle,
  skeletonProductTileStyle,
  skeletonStatusStyle,
} from '../../../../styles';
import EmptyBox from '../../../common/EmptyBox';

export const SkeletonShopTile = () => (
  <article css={skeletonProductTileStyle}>
    <div css={skeletonStatusStyle} />
    <div css={skeletonImageStyle}>
      <EmptyBox />
    </div>
    <div css={[skeletonPlaceholderStyle, { height: 20, width: 90, marginBottom: 10 }]} />
    <div css={[skeletonPlaceholderStyle, { height: 16, width: 200, marginBottom: 64 }]} />
    <div css={[skeletonPlaceholderStyle, { height: 30, width: '100%', marginBottom: 8 }]} />
    <div css={[skeletonPlaceholderStyle, { height: 30, width: 140, marginBottom: 8 }]} />
    <div css={skeletonBottomContainerStyle}>
      <div css={[skeletonPlaceholderStyle, { height: 42, width: '50%', marginRight: 8 }]} />
      <div css={skeletonPlaceholderButtonStyle} />
    </div>
  </article>
);
