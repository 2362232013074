import { USERSNAP_CLAIMS_SATISFACTION_WIDGET_KEY } from '@lego/b2b-unicorn-bootstrap/constants';
import React from 'react';

import { InlineUsersnapWidget } from '../../../../components/InlineUsersnapWidget/InlineUsersnapWidget';

type ClaimInlineUsersnapWidgetProps = {
  invoiceNumber: number;
};
export const ClaimInlineUsersnapWidget: React.FC<ClaimInlineUsersnapWidgetProps> = ({
  invoiceNumber,
}) => {
  return (
    <InlineUsersnapWidget
      widgetKey={USERSNAP_CLAIMS_SATISFACTION_WIDGET_KEY}
      usersnapOptsCustomParams={{ invoiceNumber: invoiceNumber }}
    />
  );
};
