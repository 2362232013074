import { gql } from '@apollo/client';

export const INVOICES = gql`
  query invoices($customerId: Int!, $filter: InvoiceSearchFilter) {
    getInvoices(customerId: $customerId, filter: $filter) {
      totalCount
      invoices {
        hasPdf
        invoiceNumber
        invoiceDate
        invoiceNetValue {
          amount
          currency
        }
        orders {
          orderNumber
          orderName
        }
      }
    }
  }
`;
