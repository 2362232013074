import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

type AfterDiscountPriceProps = {
  children: React.ReactNode;
};

const afterDiscountPriceCss = css({
  color: designToken.text.default,
  display: 'block',
  lineHeight: 1,

  '&:only-child': {
    color: 'inherit',
  },
});

export const AfterDiscountPrice: React.FC<AfterDiscountPriceProps> = ({ children }) => {
  return <span css={afterDiscountPriceCss}>{children}</span>;
};
