import { cloneDeep } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { LaunchCartProduct } from '@lego/b2b-unicorn-data-access-layer';
import { Spacer } from '@lego/b2b-unicorn-shared/ui';
import {
  commonSubHeaderStyle,
  pagePaddingDesktopStyle,
  tableStyle,
} from '@lego/b2b-unicorn-ui-checkout-flow';
import { baseSpacing, font, ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';
import { sortAscending, sortDescending } from '@lego/b2b-unicorn-ui-utils';
import React, { useMemo, useState } from 'react';

import { LaunchBlockedItemRow } from './LaunchBlockedItemRow';
import { LaunchTableHeader } from './LaunchTableHeader';

interface LaunchOrderBlockedItems {
  products: LaunchCartProduct[];
}

const secondSubHeaderStyle = css(commonSubHeaderStyle, pagePaddingDesktopStyle);
const textStyle = css(pagePaddingDesktopStyle, {
  fontSize: font.size.small,
  marginBottom: baseSpacing * 4,
});

export const LaunchOrderBlockedItems: React.FC<LaunchOrderBlockedItems> = ({
  products: propsProducts,
}) => {
  // Component internal required properties
  const { launch_window_order_blocked_header, launch_window_order_blocked_text } = useLabels();

  // Components internal states
  // By default, sort items by item id ascending.
  const [activeSortingField, setActiveSortingField] = useState<ProductTableColumnType>(
    ProductTableColumnType.ITEM_ID
  );
  const [isAscending, setIsAscending] = useState<boolean>(true);

  const products = useMemo(() => {
    const clonedProducts = cloneDeep(propsProducts).map((p) => ({ product: p, quantity: 0 }));
    return isAscending
      ? sortAscending(clonedProducts, activeSortingField)
      : sortDescending(clonedProducts, activeSortingField);
  }, [propsProducts, isAscending, activeSortingField]);

  return (
    <div>
      <Spacer />
      <div css={secondSubHeaderStyle}>{launch_window_order_blocked_header}</div>
      <div css={textStyle}>{launch_window_order_blocked_text}</div>
      <div css={pagePaddingDesktopStyle}>
        <table css={tableStyle(true)}>
          <LaunchTableHeader
            activeSortingField={activeSortingField}
            setActiveSortingField={setActiveSortingField}
            isAscending={isAscending}
            setIsAscending={setIsAscending}
            isBlockedProductsTable
          />
          <tbody>
            {products.map(({ product }) => (
              <LaunchBlockedItemRow
                key={product.materialId}
                product={product}
              />
            ))}
          </tbody>
        </table>
        <Spacer />
      </div>
    </div>
  );
};
