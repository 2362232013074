import { css } from '@emotion/react';
import { baseSpacing, colors, media, mediaSizes } from '@lego/b2b-unicorn-ui-constants';

import { numericContentStyle } from '../OrderList/styles';

export const orderDetailsPageStyle = css({
  paddingTop: baseSpacing * 4,
});

export const imageWrapperStyle = css([
  numericContentStyle,
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    svg: {
      height: 30,
      width: 40,
    },
    img: {
      height: 30,
    },
  },
]);

export const imageContainerStyle = css({
  flexGrow: 1,
  textAlign: 'center',
  paddingRight: baseSpacing / 2,
  fontSize: 0,
  img: {
    verticalAlign: 'middle',
  },
});

export const orderDetailsTableContainerStyle = css({
  table: {
    tr: {
      td: {
        verticalAlign: 'top',
        [media.medium]: {
          lineHeight: '38px',
          '&:nth-of-type(1)': {
            width: 100,
          },
          '&:nth-of-type(6)': {
            width: 130,
          },
          div: {
            lineHeight: 'inherit',
          },
        },
      },
    },
    thead: {
      tr: {
        td: {
          verticalAlign: 'middle',
        },
      },
    },
    tbody: {
      tr: {
        td: {
          [`@media not all and (min-width: ${mediaSizes.medium}px)`]: {
            '&:nth-of-type(1), &:nth-of-type(2)': {
              '&:before': {
                display: 'none',
              },
              display: 'flex',
              width: 'auto',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: colors.klik.slate100,
            },
            '&:nth-of-type(1)': {
              width: '30%',
            },
            '&:nth-of-type(2)': {
              paddingLeft: '1em',
              alignItems: 'center',
              flexGrow: 1,
            },
            '&:nth-of-type(3)': {
              paddingTop: baseSpacing,
            },
            '.unicorn-status-label': {
              width: 'auto',
            },
          },
        },
      },
    },
  },
});
