export const convertDateStringToDateOrNull = (date?: string | null) => {
  if (!date) {
    return null;
  }

  return convertDateStringToDate(date);
};

export const convertDateStringToDate = (date: string) => {
  const [year, month, day] = date.split('-');

  // Date expects month to be zero indexed, hence the '- 1'
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};
