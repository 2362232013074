import { gql } from '@apollo/client';

export const REPLENISHMENT_PRODUCTS = gql`
  query replenishmentProducts($customerId: Int!, $filters: ProductListFilterInput) {
    products(customerId: $customerId, filters: $filters) {
      products {
        lifecycles
        materialId
        name
        openOrderInfo {
          shippingDate
          shippingQuantity
          totalPendingQuantity
        }
        piecesPerCasePack
        price {
          currency
          estimatedNetInvoicedPrice
          grossPrice
        }
        stockStatus {
          status
          expectedBackDate
        }
        tags
        theme
        ean
        ... on Box {
          itemNumber
          launchDate
          exitDate
        }
      }
      metadata {
        facets {
          priceRanges {
            id
            from
            to
            totalCount
            hitsCount
          }
          availabilities {
            availability
            totalCount
            hitsCount
          }
          lifecycles {
            lifecycle
            totalCount
            hitsCount
          }
          themes {
            title
            subThemes {
              title
              totalCount
              hitsCount
            }
            totalCount
            hitsCount
          }
        }
      }
    }
  }
`;
