import {
  GlobalLoadingService,
  LabelsService,
  UserService,
} from '@lego/b2b-unicorn-bootstrap/services';
import { Customer } from '@lego/b2b-unicorn-data-access-layer';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { setLocale } from 'yup';

import { ASSETS_URL } from '../constants';

const labelsLogger = logger.createLogger('Labels');
const overrideLabels = new URLSearchParams(window.location.search).get('overrideLabels');
const labelsService = new LabelsService(labelsLogger, {
  baseUrl: `${ASSETS_URL}/router/labels/`,
  keysOnly: overrideLabels !== null,
});

export type LabelsDict = typeof labelsService.labels;
const LabelsContext = createContext<LabelsDict>({} as LabelsDict);

type BootstrapLabelsProps = {
  children: ReactNode;
};
const BootstrapLabels: React.FC<BootstrapLabelsProps> = ({ children }) => {
  const globalLoaderId = useRef<string>();
  const [userService] = useState(() => {
    return UserService.getInstance();
  });
  const [selectedCustomer, setSelectedCustomer] = useState(userService.selectedCustomer);
  const [labels, setLabels] = useState<LabelsDict>({} as LabelsDict);
  const [labelsAreReady, setLabelsAreReady] = useState<boolean>(false);

  const handleUsersServiceCustomerSelected = useCallback((customer: Customer) => {
    labelsService.setLocale(customer.locale);
    setSelectedCustomer(customer);
  }, []);

  const handleLabelsServiceLoading = useCallback((loading: boolean) => {
    if (loading && !globalLoaderId.current) {
      globalLoaderId.current = GlobalLoadingService.add('labels');
    }
    if (!loading && globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
      globalLoaderId.current = undefined;
    }
  }, []);

  const handleLabelsServiceDone = useCallback(() => {
    setLabels(labelsService.labels);
    setLocale({
      mixed: {
        required: labelsService.labels.required_fields,
      },
    });
    setLabelsAreReady(true);
    if (globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
      globalLoaderId.current = undefined;
    }
  }, []);

  const handleUsersServiceUserUpdated = useCallback(() => {
    if (
      !userService.selectedCustomer ||
      !userService.selectedCustomerPreferences ||
      !userService.selectedCustomerPreferences.preferredLanguage
    ) {
      return;
    }

    // After a preferences update, the `locale` property on a given customer does not always reflect the preferred language
    const constructedLocale = `${userService.selectedCustomerPreferences.preferredLanguage.code.toUpperCase()}-${userService.selectedCustomer.country.toUpperCase()}`;
    labelsService.setLocale(constructedLocale);
  }, [userService]);

  useEffect(() => {
    const loadingHandlerId = labelsService.addListener('loading', handleLabelsServiceLoading);
    const doneHandlerId = labelsService.addListener('done', handleLabelsServiceDone);
    const customerSelectedHandlerId = userService.addEventListener(
      'customer-selected',
      handleUsersServiceCustomerSelected
    );
    const userUpdatedHandlerId = userService.addEventListener(
      'user-updated',
      handleUsersServiceUserUpdated
    );

    return () => {
      labelsService.removeListener('loading', loadingHandlerId);
      labelsService.removeListener('done', doneHandlerId);
      userService.removeListener('customer-selected', customerSelectedHandlerId);
      userService.removeListener('user-updated', userUpdatedHandlerId);
    };
  }, [
    handleLabelsServiceDone,
    handleLabelsServiceLoading,
    handleUsersServiceCustomerSelected,
    handleUsersServiceUserUpdated,
    userService,
  ]);

  useEffect(() => {
    if (!selectedCustomer) {
      const localeFromQuery = new URLSearchParams(window.location.search).get('locale');
      labelsService.setLocale(localeFromQuery || 'EN-US');
    } else {
      labelsService.setLocale(selectedCustomer.locale);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (!globalLoaderId.current) {
      globalLoaderId.current = GlobalLoadingService.add('labels');
    }

    return () => {
      if (globalLoaderId.current) {
        GlobalLoadingService.remove(globalLoaderId.current);
      }
    };
  }, []);

  return (
    <LabelsContext.Provider value={labels}>
      <LabelsContext.Consumer>
        {() => <>{labelsAreReady && <>{children}</>}</>}
      </LabelsContext.Consumer>
    </LabelsContext.Provider>
  );
};

export function useLabels() {
  const context = useContext(LabelsContext);
  if (context === undefined) {
    throw new Error('useLabels must be used within a BootstrapLabels');
  }
  return context;
}

export default BootstrapLabels;
