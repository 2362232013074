export const authorizedFetchCall = async <T>(
  url: string,
  customerId: number,
  getIdToken: () => Promise<string>,
  onError: (e: unknown) => void,
  requestInit?: RequestInit
): Promise<Response | null> => {
  try {
    const token = await getIdToken();
    if (!token) {
      return null;
    }

    const fetchRequestInit = {
      ...requestInit,
      headers: {
        ...requestInit?.headers,
        Authorization: `Bearer ${token}`,
        'lego-b2b-customer-id': customerId.toString(),
      },
    };

    const response = await fetch(url, fetchRequestInit);

    if (response.status !== 200) {
      return null;
    }

    return response;
  } catch (e) {
    onError(e);

    return null;
  }
};
