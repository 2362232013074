import { NetworkStatus } from '@apollo/client';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useOrder } from '@lego/b2b-unicorn-data-access-layer/react';
import { hasNotFoundError } from '@lego/b2b-unicorn-data-access-layer/utils/hasNotFoundError';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnCustomerChangeRedirect } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { GoBackLink } from '@lego/b2b-unicorn-shared/ui';
import { Banner, GoToTop } from '@lego/b2b-unicorn-ui-components';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageNotFound } from '../../../shared/layouts/PageNotFound';
import OrderDetails from './OrderDetails/OrderDetails';

interface IOrderDetailsRouteParams {
  orderNumber: string;
}

const OrderDetailsPage = () => {
  useOnCustomerChangeRedirect('/manage');

  const history = useHistory();
  const { orderNumber: rawOrderNumber } = useParams<IOrderDetailsRouteParams>();
  const orderNumber = parseInt(rawOrderNumber);
  const { endTransaction } = useApmTransaction();

  const { button_go_to_overview, order_error_not_found } = useLabels();

  const { id: customerId } = useSelectedCustomer();
  const { data, error, loading, status } = useOrder(orderNumber, customerId);

  const notFound = useMemo(() => {
    if (status === NetworkStatus.error && hasNotFoundError(error as Error)) {
      return true;
    }
  }, [status, error]);

  useEffect(() => {
    if (isNaN(orderNumber)) {
      history.replace('/manage'); // Redirect in case of malformed url.
    }
  }, [orderNumber, history]);

  useEffect(() => {
    if (data || error) {
      endTransaction && endTransaction();
    }
  }, [data, error, endTransaction]);

  return (
    <Fragment>
      <TopbarImplementation />
      <Banner />
      {notFound && (
        <PageNotFound
          noTopbar
          text={order_error_not_found}
        >
          <GoBackLink
            onClick={() => history.replace('/manage')}
            text={button_go_to_overview}
          />
        </PageNotFound>
      )}
      {!notFound && (
        <OrderDetails
          orderNumber={orderNumber}
          orderData={data}
          isLoading={loading}
        />
      )}
      <GoToTop />
    </Fragment>
  );
};

export default OrderDetailsPage;
