import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media, mediaSizes } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import { AvailabilityFilter, LifecyclesFilter, PriceRangesFilter, ThemesFilter } from './';

const filtersStyle = css({
  marginBottom: 0,
});

const filterContainerStyle = css({
  display: 'grid',
  gap: baseSpacing,
  maxWidth: mediaSizes.large,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,
  padding: 0,
});

const filterListStyle = css({
  display: 'flex',
  position: 'relative',
  zIndex: 11,
  width: '100%',
  margin: 0,
  flexDirection: 'column',
  flexWrap: 'nowrap',
  marginBottom: 0,
  paddingTop: baseSpacing,
  color: designToken.text.default,
  [media.medium]: {
    flexDirection: 'row',
    marginBottom: baseSpacing * 2,
    '> div:not(:last-of-type)': {
      marginRight: baseSpacing * 2,
    },
  },
});

export const ProductFilterSelectors: React.FC = () => {
  return (
    <div css={filtersStyle}>
      <div css={filterContainerStyle}>
        <div css={filterListStyle}>
          <ThemesFilter />
          <LifecyclesFilter />
          <PriceRangesFilter />
          <AvailabilityFilter />
        </div>
      </div>
    </div>
  );
};
