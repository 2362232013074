import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';
import { deepEqual } from 'fast-equals';
import { cloneDeep } from 'lodash';

import {
  InvoiceQuery,
  InvoiceQueryVariables,
  InvoicesQuery,
  InvoicesQueryVariables,
  PdfInvoiceQuery,
  PdfInvoiceQueryVariables,
} from '../../generated-types/graphql';
import { ContextAbstract } from '../ContextAbstract';
import { INVOICE } from './queries/invoice';
import { INVOICES } from './queries/invoices';
import { PDF_INVOICE } from './queries/pdfInvoice';

export type InvoiceFilters = NonNullable<InvoicesQueryVariables['filter']>;
export type InvoiceList = InvoicesQuery['getInvoices'];
export type Invoice = NonNullable<InvoiceQuery['invoice']>;

export class InvoicesDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {
    Query: {
      fields: {
        getInvoices: {
          keyArgs: ['customerId'],
          merge(existing: InvoiceList | undefined, incoming: InvoiceList, { variables }) {
            const merged = existing ? cloneDeep(existing) : cloneDeep(incoming);

            if (incoming && !deepEqual(merged, incoming)) {
              const typedVariables = variables as InvoicesQueryVariables;
              if (
                typedVariables &&
                typedVariables.filter &&
                typedVariables.filter.offset !== null
              ) {
                const { offset = 0 } = typedVariables.filter;
                for (let i = 0; i < incoming.invoices.length; ++i) {
                  merged.invoices[offset + i] = incoming.invoices[i];
                }
              }
            }

            return merged;
          },
          read(existing: InvoiceList | undefined) {
            return existing;
          },
        },
      },
    },
  };

  public getAll(customerId: number, filter: InvoiceFilters = { limit: 0, offset: 0 }) {
    return this._apolloClient.watchQuery<InvoicesQuery, InvoicesQueryVariables>({
      query: INVOICES,
      variables: {
        customerId,
        filter,
      },
    });
  }

  public getById(invoiceNumber: number, customerId: number) {
    const observableKey = `invoice-${invoiceNumber}-${customerId}`;
    return this.queryObservable<InvoiceQuery, InvoiceQueryVariables>(observableKey, INVOICE, {
      invoiceNumber,
      customerId,
    });
  }

  public getPdfInvoice(invoiceNumber: number, customerId: number) {
    return this._apolloClient.query<PdfInvoiceQuery, PdfInvoiceQueryVariables>({
      query: PDF_INVOICE,
      variables: {
        invoiceNumber,
        customerId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  public getPdfInvoiceLazy() {
    const query = PDF_INVOICE;
    const execute = (invoiceNumber: number, customerId: number) =>
      this.getPdfInvoice(invoiceNumber, customerId);

    return {
      execute,
      query,
    };
  }
}
