import type { TypePolicies } from '@apollo/client';
import type { AsStoreObject } from '@apollo/client/utilities';

import {
  BillOfMaterial as IBillOfMaterial,
  Box as IBox,
  Supplementary as ISupplementary,
} from '../../generated-types/types';
import { TypePolicyAbstract } from '../TypePolicyAbstract';

type ProductCacheObj = Readonly<AsStoreObject<IBox | IBillOfMaterial | ISupplementary>>;

export class ProductPolicy extends TypePolicyAbstract {
  static TypePolicies: TypePolicies = {
    Product: {
      keyFields: (obj) => {
        const productObj = obj as ProductCacheObj;

        return `Product:${productObj.materialId}`;
      },
      merge: (existing, incoming, { mergeObjects }) => {
        return mergeObjects(existing, incoming);
      },
    },
    Box: {
      keyFields: (obj) => {
        const productObj = obj as ProductCacheObj;

        return `Box:${productObj.materialId}`;
      },
      merge: (existing, incoming, { mergeObjects }) => {
        return mergeObjects(existing, incoming);
      },
    },
    BillOfMaterial: {
      keyFields: (obj) => {
        const productObj = obj as ProductCacheObj;

        return `BillOfMaterial:${productObj.materialId}`;
      },
      merge: (existing, incoming, { mergeObjects }) => {
        return mergeObjects(existing, incoming);
      },
    },
    Supplementary: {
      keyFields: (obj) => {
        const productObj = obj as ProductCacheObj;

        return `Supplementary:${productObj.materialId}`;
      },
      merge: (existing, incoming, { mergeObjects }) => {
        return mergeObjects(existing, incoming);
      },
    },
  };

  static Fragments = [];
}
