import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { TILE_WIDTH } from '../constants';

export const oneColWidth = TILE_WIDTH;
export const oneColMedia = oneColWidth + 36;
export const twoColWidth = TILE_WIDTH * 2 + baseSpacing * 2;
export const twoColMedia = twoColWidth + 36;
export const threeColWidth = TILE_WIDTH * 3 + baseSpacing * 4;
export const threeColMedia = threeColWidth + 36;
export const fourColWidth = TILE_WIDTH * 4 + baseSpacing * 6;
export const fourColMedia = fourColWidth + 36;
