import { deepEqual } from 'fast-equals';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { CellBase, Matrix } from 'react-spreadsheet';

import { fillArrayToMinimumLength } from '../helpers/fillArrayToMinimumLength';
import { rawDataReducer } from '../hooks/utils';
import { DataStructure, IProduct, RowStatusReason } from '../types';

type UseBulkQuickAddParameters = {
  /*
   * The initial data used.
   * NB: Will only use this on initial mount, to update use `setData`
   */
  initialData: Matrix<CellBase>;

  /*
   * Minimum number of rows
   */
  minimumRows?: number;

  /*
   * Array of products to lookup in
   */
  products: IProduct[];

  /*
   * translation
   */
  product_not_found_feedback: string;
};

type UseBulkQuickAddReturn = {
  setRawData: (nextData: DataStructure['dataRows']) => void;
  data: DataStructure;
  removeRow: (rowIndex: number) => void;
};

export const useBulkQuickAdd = ({
  initialData,
  products,
  minimumRows = 10,
  product_not_found_feedback,
}: UseBulkQuickAddParameters): UseBulkQuickAddReturn => {
  const previousRawData = useRef(initialData);
  const [internalData, setInternalData] = useState<DataStructure>(() => {
    const processRawData = rawDataReducer(products, product_not_found_feedback);

    return initialData.reduce(processRawData, {
      statusRows: [],
      dataRows: [],
      productRows: [],
      finalRows: [],
    });
  });
  const setRawData = useCallback(
    (nextRawData: Matrix<CellBase>) => {
      if (deepEqual(nextRawData, previousRawData.current)) {
        return;
      }

      const processRawData = rawDataReducer(products, product_not_found_feedback);
      const nextInternalData = nextRawData.reduce<DataStructure>(processRawData, {
        statusRows: [],
        dataRows: [],
        productRows: [],
        finalRows: [],
      });

      setInternalData(nextInternalData);
      previousRawData.current = nextRawData;
    },
    [product_not_found_feedback, products]
  );

  const removeRow = useCallback(
    (rowIndex: number) => {
      const rawDataCopy = cloneDeep(previousRawData.current);
      rawDataCopy.splice(rowIndex, 1);
      setRawData(rawDataCopy);
    },
    [setRawData]
  );

  const data = useMemo(() => {
    const statusRows = fillArrayToMinimumLength(
      internalData.statusRows,
      minimumRows,
      RowStatusReason.None
    );
    const dataRows = fillArrayToMinimumLength(internalData.dataRows, minimumRows, [
      undefined,
      undefined,
    ]);
    const productRows = fillArrayToMinimumLength(internalData.productRows, minimumRows, [
      { readOnly: true, value: '' },
      { readOnly: true, value: '' },
      { readOnly: true, value: '' },
      { readOnly: true, value: '' },
      { readOnly: true, value: '' },
      { readOnly: true, value: '' },
    ]);

    // Always make sure we have an empty row at the end
    const lastDataRow = dataRows[dataRows.length - 1];
    if (
      dataRows.length >= minimumRows &&
      lastDataRow[0] &&
      lastDataRow[0].value !== undefined &&
      lastDataRow[0].value !== ''
    ) {
      statusRows.push(RowStatusReason.None);
      dataRows.push([undefined, undefined]);
      productRows.push([
        { readOnly: true, value: '' },
        { readOnly: true, value: '' },
        { readOnly: true, value: '' },
        { readOnly: true, value: '' },
        { readOnly: true, value: '' },
        { readOnly: true, value: '' },
      ]);
    }

    return {
      statusRows,
      dataRows,
      productRows,
      finalRows: internalData.finalRows,
    };
  }, [internalData, minimumRows]);

  return {
    data,
    setRawData,
    removeRow,
  };
};
