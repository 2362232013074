import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';

import { skeletonGradientAnimation } from '../../styles/skeleton';

const skeletonTableStyle = css(skeletonGradientAnimation, {
  thead: {
    td: {
      backgroundColor: designToken.skeleton.default,
    },
  },
  tbody: {
    tr: {
      borderBottomWidth: '0 !important',
      td: {
        backgroundColor: designToken.skeleton.secondary,
        borderBottomWidth: '0 !important',
        width: '100% !important',
      },
    },
    '&:after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: 200,
      background: `linear-gradient(0deg, ${colors.klik.slate10} 0%, ${designToken.transparent.default} 100%)`,
      position: 'absolute',
      bottom: 0,
    },
  },
});

const mobileTableRowStyle = css({
  backgroundColor: `${colors.white} !important`,
  margin: '0 !important',
  padding: '0 !important',
  paddingTop: `12px !important`,
  paddingLeft: '20% !important',
  width: '100% important',
  span: {
    backgroundColor: designToken.skeleton.secondary,
    height: baseSpacing * 2,
    width: '40%',
    minWidth: '200px !important',
  },
  '&:before': {
    display: 'none !important',
  },
  [media.medium]: {
    display: 'none !important',
  },
});

const lastMobileTableRowStyle = css({
  paddingBottom: `${baseSpacing}px !important`,
});

interface ISkeletonTable {
  rowsToRender: number;
}

const SkeletonTable = (props: ISkeletonTable) => {
  return (
    <table css={skeletonTableStyle}>
      <thead>
        <tr>
          <td>&nbsp;</td>
        </tr>
      </thead>
      <tbody>
        {Array(props.rowsToRender)
          .fill(0)
          .map((_, idx) => (
            <tr key={'skeleton-tr-' + idx}>
              <td>&nbsp;</td>
              <td css={mobileTableRowStyle}>
                <span />
              </td>
              <td css={mobileTableRowStyle}>
                <span />
              </td>
              <td css={mobileTableRowStyle}>
                <span />
              </td>
              <td css={mobileTableRowStyle}>
                <span />
              </td>
              <td css={[mobileTableRowStyle, lastMobileTableRowStyle]}>
                <span />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default SkeletonTable;
