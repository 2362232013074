import { css } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';

import defaultImg from '../../assets/placeholder-img.png';
import { INFO_TILE_WIDTH } from '../../constants';

interface ISkeletonInfoTile {
  numberOfFiles: number;
}

const tileStyle = css({
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  maxWidth: INFO_TILE_WIDTH + baseSpacing * 4,
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: baseSpacing * 4,
  color: designToken.text.default,
  backgroundColor: colors.white,
  borderRadius: 8,
  marginBottom: baseSpacing,
  [media.small]: {
    paddingBottom: 38,
    maxWidth: maxDesktopWidth,
    flexDirection: 'row',
  },
});

const placeholderStyle = css({
  backgroundColor: designToken.skeleton.default,
  borderRadius: 4,
});

const imageStyle = css({
  width: '100%',
  height: 153,
  objectFit: 'cover',
  marginBottom: baseSpacing * 2,
  backgroundColor: designToken.skeleton.secondary,
  borderRadius: 8,
  [media.small]: {
    width: 272,
    marginRight: baseSpacing * 2,
    marginBottom: 0,
  },
  [media.medium]: {
    marginRight: baseSpacing * 4,
  },
});

const contentStyle = css({
  flex: '1 1 150px',
});

const subHeaderStyle = css(placeholderStyle, {
  height: 18,
  width: 210,
  marginTop: baseSpacing,
  marginBottom: 12,
  padding: 0,
  [media.small]: {
    marginTop: 0,
    marginBottom: 10,
    height: 20,
  },
});

const textStyle = css(placeholderStyle, {
  height: 14,
  width: 125,
  marginBottom: 30,
  [media.small]: {
    paddingRight: baseSpacing * 3,
    marginBottom: 30,
  },
});

const assetStyle = css({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  textAlign: 'left',
  border: 'none',
  padding: 0,
  marginBottom: baseSpacing * 2,
  '> svg': {
    fill: designToken.skeleton.default,
    height: 24,
    width: 24,
    minWidth: 24,
    marginRight: baseSpacing,
    marginTop: -6,
    transitionProperty: 'fill',
    transitionDuration: '120ms',
  },
});

const assetTextStyleStyle = css(placeholderStyle, {
  height: 12,
  width: 125,
  [media.small]: {
    height: 14,
  },
});

const SkeletonInfoTile = (props: ISkeletonInfoTile) => {
  return (
    <div css={tileStyle}>
      <img
        css={imageStyle}
        src={defaultImg}
        alt={'fallbackImage'}
      />
      <div css={contentStyle}>
        <div css={subHeaderStyle} />
        <div css={textStyle} />
        {[...Array(props.numberOfFiles)].map((_, i) => {
          return (
            <div
              css={assetStyle}
              key={i}
            >
              <Icon type={IconType.FILE} />
              <div css={assetTextStyleStyle} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SkeletonInfoTile;
