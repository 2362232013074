import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { FutureLaunchWindow } from '@lego/b2b-unicorn-data-access-layer';
import { Tile, TileBottom, TileButton } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { LaunchWindowTileHeader } from './LaunchWindowTileHeader';

export const FutureLaunchWindowTile = ({
  futureLaunchWindow,
}: {
  futureLaunchWindow: FutureLaunchWindow;
}) => {
  const { launch_window_open } = useLabels();
  const isActive = false;
  const history = useHistory();
  const handleClick = () => {
    history.push(`/launch/${futureLaunchWindow.year}/${futureLaunchWindow.month}`);
  };

  return (
    <Tile
      isActive={isActive}
      onClick={handleClick}
    >
      <LaunchWindowTileHeader
        month={futureLaunchWindow.month}
        year={futureLaunchWindow.year}
        productsCount={futureLaunchWindow.productsCount}
        isActive={isActive}
      />
      <TileBottom>
        <TileButton withAlert={false}>{launch_window_open}</TileButton>
      </TileBottom>
    </Tile>
  );
};
