import { css, Global } from '@emotion/react';
import React, { ReactNode, useContext } from 'react';

import { Icon, IconType } from '../Icon';
import { baseSpacing, colors, media } from '../theme';
import { PopoverOpenContext, PopoverRefContext } from './Popover';

const wrapperDialogStyle = css([
  {
    boxShadow: '0 0 15px 5px rgba(0,0,0,0.2)',
    borderRadius: 5,
    position: 'absolute',
    top: 'calc(100% + 4px)',
    right: -baseSpacing / 2,
    bottom: 'auto',
    left: 'auto',
    borderWidth: 0,
    flexDirection: 'column',
    padding: 0,
    width: 400,
    maxWidth: '80vw',
    maxHeight: 518,

    '&:before': {
      position: 'absolute',
      top: -baseSpacing / 2,
      right: 26,
      [media.medium]: {
        right: 11,
      },
      content: '""',
      width: 10,
      height: 10,
      backgroundColor: colors.white,
      boxShadow: `-1px -1px 0 ${colors.klik.slate100}`,
      transform: 'rotate(45deg)',
    },
  },
]);

const wrapperResponsiveStyle = css([
  {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    [media.medium]: wrapperDialogStyle,
  },
]);

const wrapperStyle = (listOpened: boolean) => {
  /**
   * When opening the selector with keyboard navigation (e.g. arrow down), we want to move
   * focus to the first child element (just like a "normal" <select> element).
   * Javascript won't let us do that if the child elements are hidden.
   * Hence we hide them with 'opacity' rather than 'display' or 'visibility'.
   */
  const commonStyle = css({
    opacity: listOpened ? 1 : 0,
    pointerEvents: listOpened ? 'all' : 'none',
    display: listOpened ? 'flex' : 'none',
    flexDirection: 'column',
    backgroundColor: colors.white,
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
    borderTopWidth: baseSpacing,
    borderTopStyle: 'solid',
    zIndex: 600,
  });

  return css(commonStyle, wrapperResponsiveStyle);
};

const closeButtonStyle = css({
  background: 'transparent',
  border: 0,
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  lineHeight: 0,
  outline: 0,
  svg: {
    width: 20,
    height: 20,
    fill: colors.klik.slate900,
  },
  [media.medium]: {
    display: 'none',
  },
});

const nonScrollableBodyWhenMobileOpenedStyle = css`
  html,
  body {
    height: 100vh;
    overflow: hidden;
  }
  ${media.medium} {
    html,
    body {
      height: initial;
      overflow: auto;
    }
  }
`;

type PopoverContentProps = {
  children: ReactNode;
};
export const PopoverContent: React.FC<PopoverContentProps> = ({ children }) => {
  const { listContainerRef } = useContext(PopoverRefContext);
  const { popoverOpen, setPopoverOpen } = useContext(PopoverOpenContext);

  return (
    <div
      css={wrapperStyle(popoverOpen)}
      ref={listContainerRef}
    >
      <>
        {popoverOpen && <Global styles={nonScrollableBodyWhenMobileOpenedStyle} />}
        <button
          css={closeButtonStyle}
          type="button"
          onClick={() => setPopoverOpen(false)}
        >
          <Icon type={IconType.CLOSE} />
        </button>
        {children}
      </>
    </div>
  );
};
