import { ClaimType } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';
import { ClaimFromValidationSchema } from '@lego/b2b-unicorn-shared/components/Claim/validation';

export const mapClaimFromSchemaToCreateClaimInput = (
  validationResult: ClaimFromValidationSchema
) => {
  return {
    fileIds: validationResult.documentationFiles
      .map((file) => file.id)
      .filter((id) => id !== undefined) as string[],
    invoiceNumber: validationResult.invoiceNumber,
    comment: validationResult.comment,
    claimItems: validationResult.claimItems.map((item) => ({
      claimType: item.claimType as ClaimType,
      comment: item.comment,
      expectedPrice: item.expectedPrice,
      fileIds: item.documentationFiles
        .map((file) => file.id)
        .filter((id) => id !== undefined) as string[],
      invoiceLineNumber: item.invoiceLineNumber,
      materialId: item.materialId,
      orderNumber: item.orderNumber,
      pieces: item.pieces,
    })),
  };
};
