import { BootstrapPhase1, BootstrapPhase2 } from '@lego/b2b-unicorn-bootstrap/components';
import React from 'react';

type BootstrapProps = {
  customerSelectorComponent: React.ComponentProps<
    typeof BootstrapPhase2
  >['customerSelectorComponent'];
  children: React.ReactNode;
  termsOfSalesConsentComponent: React.ComponentProps<
    typeof BootstrapPhase2
  >['termsOfSalesConsentComponent'];
  fatalErrorBoundaryComponent: React.ComponentType<{ children: React.ReactNode }>;
};

const Bootstrap: React.FC<BootstrapProps> = ({
  children,
  customerSelectorComponent,
  termsOfSalesConsentComponent,
  fatalErrorBoundaryComponent,
}) => {
  return (
    <BootstrapPhase1 errorBoundary={fatalErrorBoundaryComponent}>
      <BootstrapPhase2
        customerSelectorComponent={customerSelectorComponent}
        termsOfSalesConsentComponent={termsOfSalesConsentComponent}
      >
        {children}
      </BootstrapPhase2>
    </BootstrapPhase1>
  );
};

export default Bootstrap;
