import { css } from '@emotion/react';
import { InvoiceOrderItem, useClaim } from '@lego/b2b-unicorn-shared/components/Claim';
import { designToken, List, Section } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useState } from 'react';

import { ContainerHeader } from '../../../../components/ContainerHeader';
import { findClaimByInvoicedItemInClaims } from '../helpers';
import { listContainerStyle } from '../styles';
import { InvoicedItem } from './';

const wrapperWithAfterStyle = css({
  display: 'contents',
  position: 'relative',
  [media.medium]: {
    display: 'block',
    height: 'calc(100vh - 440px)',
    overflowY: 'auto',
    '&:after': {
      pointerEvents: 'none',
      content: '""',
      display: 'block',
      width: `100%`,
      height: 'calc(100vh - 440px)',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      boxShadow: `0 ${baseSpacing * 2}px ${baseSpacing}px -${baseSpacing}px ${
        designToken.background.offWhite
      } inset,
                0 -${baseSpacing * 7}px ${baseSpacing * 3}px -${baseSpacing * 3}px ${
                  designToken.background.offWhite
                } inset`,
    },
  },
});

const listStyle = (display: boolean) =>
  css({
    display: display ? 'block' : 'none',
    [media.medium]: {
      display: 'block',
    },
  });
export const InvoicedItemsList: React.FC = () => {
  const [showItemList, setShowItemList] = useState(true);
  const { claimItems, setClaimItems, invoiceItems, fileService } = useClaim();

  const handleRemoveClaimItem = (item: InvoiceOrderItem) => {
    setClaimItems(
      claimItems.filter((claimItem) => claimItem.invoiceLineNumber !== item.invoiceLineNumber)
    );
    fileService.removeFilesByStack(item.invoiceLineNumber.toString());
  };

  return (
    <Section>
      <ContainerHeader
        text={'Invoiced items'}
        open={showItemList}
        setOpen={() => setShowItemList(!showItemList)}
        withLargeText={false}
        mobileOnlyToggle={true}
      />
      <div css={wrapperWithAfterStyle}>
        <div css={listContainerStyle}>
          <List extraCss={listStyle(showItemList)}>
            {invoiceItems.map((item) => {
              const claimItem = findClaimByInvoicedItemInClaims(item, claimItems);

              return (
                <InvoicedItem
                  key={item.invoiceLineNumber}
                  invoiceItem={item}
                  claimItem={claimItem}
                  removeClaimItem={handleRemoveClaimItem}
                  isMultiplePresent={
                    invoiceItems.filter((invoice) => invoice.materialId === item.materialId)
                      .length > 1
                  }
                />
              );
            })}
          </List>
        </div>
      </div>
    </Section>
  );
};
