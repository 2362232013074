import classNames from 'classnames';
import * as React from 'react';
import type { ColumnIndicatorProps } from 'react-spreadsheet';

import { useBulkQuickAddContext } from '../BulkQuickAddContext';
import { ProductColumnHeaderCell } from './cells';

export const BulkQuickAddEditableColumnIndicator: React.FC<ColumnIndicatorProps> = ({ column }) => {
  const {
    translations: {
      columnLabels: { quantity },
    },
  } = useBulkQuickAddContext();

  const columnIndexToLabel = (column: number): React.ReactNode => {
    if (column === 0) {
      return <ProductColumnHeaderCell />;
    }
    if (column === 1) {
      return quantity;
    }
    return null;
  };

  return (
    <th
      className={classNames('Spreadsheet__header')}
      tabIndex={-1}
    >
      {columnIndexToLabel(column)}
    </th>
  );
};
