import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';

export const backdropStyle = css({
  background: colors.klik.lightBlue900 + 70,
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 501,
});

export const closeButtonStyle = css({
  backgroundColor: 'transparent',
  color: designToken.text.default,
  padding: 0,
  margin: 0,
  border: 'none',
  position: 'absolute',
  top: baseSpacing * 2,
  right: baseSpacing * 3,
  cursor: 'pointer',
  zIndex: 501,

  '> svg': {
    height: 24,
    width: 24,
    fill: designToken.text.default,
  },
});
