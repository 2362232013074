import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, designToken, font, maxDesktopWidth, media } from '../theme';

type FlexInput = {
  alignItems?: React.CSSProperties['alignItems'];
  flexDirection?: React.CSSProperties['flexDirection'];
  justifyContent?: React.CSSProperties['justifyContent'];
  gap?: number;
};

type PaddingInput = {
  paddingTop?: React.CSSProperties['paddingTop'];
  paddingBottom?: React.CSSProperties['paddingBottom'];
  paddingLeft?: React.CSSProperties['paddingLeft'];
  paddingRight?: React.CSSProperties['paddingRight'];
};

type MarginInput = {
  marginTop?: React.CSSProperties['marginTop'];
  marginBottom?: React.CSSProperties['marginBottom'];
  marginLeft?: React.CSSProperties['marginLeft'];
  marginRight?: React.CSSProperties['marginRight'];
};

type MediaInput<T> = {
  [media: string]: T;
};

const flexStyle = (flex?: FlexInput | boolean) => {
  if (typeof flex === 'boolean') {
    return css({
      display: 'flex',
    });
  }
  return css({
    display: 'flex',
    ...flex,
  });
};
const flexMediumStyle = (flex?: MediaInput<FlexInput>) => css(flex);

const paddingStyle = (padding?: PaddingInput) =>
  css({
    paddingTop: padding && padding.paddingTop !== undefined ? padding.paddingTop : undefined,
    paddingRight:
      padding && padding.paddingRight !== undefined ? padding.paddingRight : baseSpacing * 2,
    paddingLeft:
      padding && padding.paddingLeft !== undefined ? padding.paddingLeft : baseSpacing * 2,
    paddingBottom:
      padding && padding.paddingBottom !== undefined ? padding.paddingBottom : undefined,

    [media.medium]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  });

const paddingMediumStyle = (padding?: MediaInput<PaddingInput>) => css(padding);

const marginStyle = (margin?: MarginInput) =>
  css({
    marginTop: margin && margin.marginTop !== undefined ? margin.marginTop : undefined,
    marginBottom: margin && margin.marginBottom !== undefined ? margin.marginBottom : undefined,
    marginLeft: margin && margin.marginLeft !== undefined ? margin.marginLeft : 'auto',
    marginRight: margin && margin.marginRight !== undefined ? margin.marginRight : 'auto',
  });

const marginMediumStyle = (margin?: MediaInput<MarginInput>) => css(margin);

const bodyStyle = (width?: React.CSSProperties['width']) =>
  css({
    maxWidth: maxDesktopWidth,
    color: designToken.text.default,
    fontSize: font.size.small,
    width: width || '100%',
  });

type ContainerProps = {
  padding?: PaddingInput;
  paddingMedium?: MediaInput<PaddingInput>;
  margin?: MarginInput;
  marginMedium?: MediaInput<MarginInput>;
  flex?: FlexInput | boolean;
  flexMedium?: MediaInput<FlexInput>;
  className?: string;
  width?: React.CSSProperties['width'];

  children: React.ReactNode;
};

export const Container: React.FC<ContainerProps> = ({
  children,
  padding,
  paddingMedium,
  margin,
  marginMedium,
  flex,
  flexMedium,
  className,
  width,
}) => {
  return (
    <div
      css={[
        bodyStyle(width),
        paddingStyle(padding),
        paddingMediumStyle(paddingMedium),
        marginStyle(margin),
        marginMediumStyle(marginMedium),
        (flex || flexMedium) && flexStyle(flex),
        flexMedium && flexMediumStyle(flexMedium),
      ]}
      className={className}
    >
      {children}
    </div>
  );
};
