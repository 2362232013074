import { NetworkStatus } from '@apollo/client';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  CartReferenceCartType,
  OrdersFilters,
  OrdersItem,
} from '@lego/b2b-unicorn-data-access-layer';
import { OrderType } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';
import { useOrders } from '@lego/b2b-unicorn-data-access-layer/react';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { contentHubProductsUrl } from '@lego/b2b-unicorn-shared/helpers';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import {
  Container,
  ContentSystemFeedback,
  Icon,
  IconType,
  Menu,
  MenuItem,
  SystemFeedbackType,
} from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import {
  LoadMoreButton,
  NotificationType,
  useNotifications,
} from '@lego/b2b-unicorn-ui-components';
import { font } from '@lego/b2b-unicorn-ui-constants';
import { removeEmptyFromObject, useStateWithUrl } from '@lego/b2b-unicorn-ui-utils';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import SkeletonTable from '../../../components/SkeletonLoaders/SkeletonTable';
import SortableTable from '../../../components/SortableTable/SortableTable';
import { ColumnDefinitionType } from '../../../components/SortableTable/types';
import StatusLabel from '../../../components/StatusLabel/StatusLabel';
import TruncatedText from '../../../components/TruncatedText/TruncatedText';
import { TABLE_ROWS_PER_PAGE } from '../../../constants';
import { basicPageStyle } from '../../../styles/general';
import { tableStyle } from '../../../styles/table';
import { FiltersInURL } from '../../../utils/DateRanges';
import {
  getSortingColumKeyFromUrlParam,
  getSortingDirectionFromUrlParam,
  SortingDirection,
} from '../../../utils/Sorting';
import {
  CopyOrderItemsToCart,
  CopyOrderItemsToCartMenuItem,
} from '../components/CopyOrderItemsToCart';
import { DownloadPdfMenuItem } from './components/DownloadPdfMenuItem';
import OrderSearchAndFilters from './OrderSearchAndFilters';
import {
  numericContentStyle,
  orderListTableResponsivenessStyle,
  tableContainerStyle,
} from './styles';
import {
  mapBackendOrderStatusToFrontendLabel,
  mapOrderFulfillmentStatusToColor,
  orderStatusLabelColors,
  parseOrderFilterParams,
} from './utils';

const orderTotalRenderFunction = (row: OrdersItem, locale: string) => (
  <div css={numericContentStyle}>
    <Price locale={locale}>{row.orderTotal}</Price>
  </div>
);

const dateRenderFunction = (content: string, locale: string) => {
  return (
    <div css={numericContentStyle}>
      <Date locale={locale}>{content}</Date>
    </div>
  );
};

const OrderList = () => {
  const { startTransaction, endTransaction } = useApmTransaction();
  const { trackEvent } = useAnalytics();
  const [selectedFilters, setSelectedFilters] = useStateWithUrl<FiltersInURL<OrdersFilters>>();
  const [initialFilters] = useState(() => {
    if (selectedFilters?.offset) {
      delete selectedFilters.offset;
      setSelectedFilters(selectedFilters);
    }

    return selectedFilters;
  });
  const selectedCustomer = useSelectedCustomer();

  const { refetch, fetchMore, error, data, status, loading } = useOrders(
    selectedCustomer.id,
    initialFilters
      ? {
          ...removeEmptyFromObject(parseOrderFilterParams(initialFilters)),
          limit: TABLE_ROWS_PER_PAGE,
          offset: initialFilters.offset || 0,
        }
      : undefined
  );

  const cartReference = useMemo(
    () => ({
      cartType: CartReferenceCartType.Replenish,
    }),
    []
  );

  useEffect(() => {
    if ((data || error) && !loading && status !== NetworkStatus.setVariables) {
      endTransaction && endTransaction();
    }
  }, [data, endTransaction, error, loading, status]);

  const labelsContext = useLabels();
  const {
    getLabelByKey,
    content_hub_link,
    button_copy_items_to_cart,
    copy_items_to_cart_tooltip_disabled,
    button_copy_unfulfilled_items_to_cart,
    copy_unfulfilled_items_to_cart_tooltip_disabled,
  } = labelsContext;

  const history = useHistory();

  const { snackbar, addSnackbar } = useNotifications();

  const totalCount = data?.orders.totalCount || 0;

  const handleLoadMoreClick = () => {
    if (totalCount > (selectedFilters?.offset || TABLE_ROWS_PER_PAGE)) {
      const updatedFilters = { ...selectedFilters };
      updatedFilters.limit = TABLE_ROWS_PER_PAGE;
      updatedFilters.offset = data?.orders.orders.length;
      setSelectedFilters(updatedFilters);
      startTransaction && startTransaction();
      fetchMore({
        customerId: selectedCustomer.id,
        filter: {
          ...removeEmptyFromObject(parseOrderFilterParams(updatedFilters)),
        },
      });
    }
  };

  const handleSortingChange = (key: keyof OrdersItem, direction: SortingDirection) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.offset = 0;
    updatedFilters.limit = TABLE_ROWS_PER_PAGE;

    if (direction === SortingDirection.OFF) {
      delete updatedFilters.sort;
    } else {
      updatedFilters.sort = [`${key}:${SortingDirection[direction].toLowerCase()}`];
    }
    setSelectedFilters(updatedFilters);
    startTransaction && startTransaction();
    refetch({
      customerId: selectedCustomer.id,
      filter: {
        ...removeEmptyFromObject(parseOrderFilterParams(updatedFilters)),
      },
    });
  };

  const handleFilterChange = (nextSelectedFilters: typeof selectedFilters) => {
    const updatedFilters = { ...nextSelectedFilters };
    updatedFilters.offset = 0;
    updatedFilters.limit = TABLE_ROWS_PER_PAGE;

    setSelectedFilters(updatedFilters);

    startTransaction && startTransaction();
    refetch({
      customerId: selectedCustomer.id,
      filter: {
        ...removeEmptyFromObject(parseOrderFilterParams(updatedFilters)),
      },
    });
  };

  const handlePdfDownloadError = (error: Error) => {
    logger.error(error);
    addSnackbar({
      type: NotificationType.WARNING,
      content: labelsContext.snackbar_download_file_error,
      showDismissButton: true,
      isStacked: !!snackbar,
    });
  };

  const orderActionsRenderFunction = (row: OrdersItem) => {
    const contentHubUrl = contentHubProductsUrl(row.items.map((item) => item.materialId));

    const handleContentHubLinkOnClick = () => {
      trackEvent({
        event: 'clickOnContentHubLink',
        name: 'User clicked on Content Hub link from order list',
      });
      window.open(contentHubUrl, '_blank');
    };

    const isReplenOrder = row.type === OrderType.OrderTypeReplenishment;

    return (
      <Fragment>
        <Container
          flex={{ alignItems: 'flex-end', flexDirection: 'column' }}
          padding={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
        >
          <Menu>
            <MenuItem>
              <Icon
                type={IconType.ELLIPSIS}
                width={font.size.large}
              />
              <Menu>
                {row.hasPdfOutput && (
                  <DownloadPdfMenuItem
                    orderNumber={row.orderNumber}
                    onError={handlePdfDownloadError}
                  />
                )}
                <MenuItem onClick={handleContentHubLinkOnClick}>
                  <a
                    href={'#'}
                    onClick={(e) => e.preventDefault()}
                  >
                    {content_hub_link}
                  </a>
                </MenuItem>
                <CopyOrderItemsToCart
                  items={row.items.map((i) => {
                    return {
                      itemId: i.materialId,
                      pieces: i.orderedPieces || 0,
                      name: i.name,
                    };
                  })}
                  cartReference={cartReference}
                >
                  <CopyOrderItemsToCartMenuItem
                    disabled={!isReplenOrder}
                    disabledTooltipMessage={copy_items_to_cart_tooltip_disabled}
                    buttonText={button_copy_items_to_cart}
                  />
                </CopyOrderItemsToCart>
                <CopyOrderItemsToCart
                  items={row.items
                    .filter((i) => i.rejectedPieces && i.rejectedPieces > 0)
                    .map((i) => {
                      return {
                        itemId: i.materialId,
                        pieces: i.rejectedPieces || 0,
                        name: i.name,
                      };
                    })}
                  cartReference={cartReference}
                >
                  <CopyOrderItemsToCartMenuItem
                    disabled={!isReplenOrder || !row.items.some((i) => i.rejectedPieces)}
                    disabledTooltipMessage={
                      !isReplenOrder
                        ? copy_items_to_cart_tooltip_disabled
                        : copy_unfulfilled_items_to_cart_tooltip_disabled
                    }
                    buttonText={button_copy_unfulfilled_items_to_cart}
                  />
                </CopyOrderItemsToCart>
              </Menu>
            </MenuItem>
          </Menu>
        </Container>
      </Fragment>
    );
  };

  const orderNumberRenderFunction = (row: OrdersItem) => (
    <a
      href={'/manage/order/' + row.orderNumber}
      onClick={(e) => {
        e.preventDefault();
        history.push('/manage/order/' + row.orderNumber, {
          navigatedFromOrderList: true,
        });
      }}
    >
      {row.orderNumber}
    </a>
  );

  const colHeaders: ColumnDefinitionType<OrdersItem>[] = [
    {
      key: 'orderNumber',
      header: labelsContext.order_number,
      renderFunction: orderNumberRenderFunction,
    },
    {
      key: 'orderName',
      header: labelsContext.order_name,
      renderFunction: (row) => <TruncatedText>{row.orderName}</TruncatedText>,
    },
    {
      key: 'shipTo',
      header: labelsContext.shipping_address,
      renderFunction: (row) => `${row.shipTo.id} ${row.shipTo.city}`,
    },
    {
      key: 'orderDate',
      header: labelsContext.order_date,
      isSortable: true,
      renderFunction: (row) => dateRenderFunction(row.orderDate, selectedCustomer.locale),
    },
    {
      key: 'requestedDeliveryDate',
      header: labelsContext.delivery_date_requested,
      renderFunction: (row) =>
        dateRenderFunction(row.requestedDeliveryDate, selectedCustomer.locale),
    },
    {
      key: 'orderTotal',
      header: labelsContext.order_total,
      renderFunction: (row) => orderTotalRenderFunction(row, selectedCustomer.locale || ''),
    },
    {
      key: 'orderStatus',
      header: labelsContext.order_status,
      isSortable: true,
      renderFunction: (row) => (
        <StatusLabel
          label={getLabelByKey(mapBackendOrderStatusToFrontendLabel(row.orderStatus).toLowerCase())}
          colors={orderStatusLabelColors}
        />
      ),
    },
    {
      key: 'fulfillmentStatus',
      header: labelsContext.fulfillment_status,
      isSortable: true,
      renderFunction: (row) => (
        <StatusLabel
          label={getLabelByKey(row.fulfillmentStatus.toLowerCase())}
          colors={mapOrderFulfillmentStatusToColor(row.fulfillmentStatus)}
        />
      ),
    },
    {
      key: 'hasPdfOutput',
      header: labelsContext.actions,
      textAlignHeader: 'center',
      renderFunction: orderActionsRenderFunction,
    },
  ];

  const initialLoading = status === NetworkStatus.loading;
  const refetching = status === NetworkStatus.refetch || status === NetworkStatus.setVariables;
  const fetchingMore = status === NetworkStatus.fetchMore;

  const sortFromUrlParam = selectedFilters?.sort?.slice(0, 1).pop();
  const currentOffset = selectedFilters?.offset || 0;

  const showTable = (!initialLoading && !refetching) || fetchingMore;
  const showLoadMore =
    !error &&
    !initialLoading &&
    !refetching &&
    totalCount > (currentOffset + TABLE_ROWS_PER_PAGE || TABLE_ROWS_PER_PAGE);

  return (
    <div css={[basicPageStyle, tableStyle]}>
      <OrderSearchAndFilters
        selectedFilters={selectedFilters}
        updateCallback={handleFilterChange}
        customerId={selectedCustomer.id}
        locale={selectedCustomer.locale}
        shipTos={data?.getShipTos || []}
      />
      <div css={[tableContainerStyle, orderListTableResponsivenessStyle]}>
        {(initialLoading || refetching) && (
          <Fragment>
            <h2>{labelsContext.orders_all}</h2>
            <SkeletonTable rowsToRender={10} />
          </Fragment>
        )}
        {error ? (
          <ContentSystemFeedback
            type={SystemFeedbackType.ERROR}
            text={labelsContext.error_occurred}
          />
        ) : (
          data &&
          data.orders.orders.length === 0 && (
            <ContentSystemFeedback text={labelsContext.quick_order_search_no_results} />
          )
        )}
        {data && data.orders.orders.length > 0 && showTable && (
          <Fragment>
            <h2>
              {labelsContext.orders_all} ({totalCount})
            </h2>
            <SortableTable
              columns={colHeaders}
              rows={data.orders.orders}
              onSortCallbackHandler={handleSortingChange}
              columnKeyToSortBy={getSortingColumKeyFromUrlParam(sortFromUrlParam)}
              sortingDirection={getSortingDirectionFromUrlParam(sortFromUrlParam)}
            />
          </Fragment>
        )}
      </div>
      {showLoadMore && (
        <LoadMoreButton
          handleLoadMoreClick={handleLoadMoreClick}
          loadMoreInProgress={loading}
          text={labelsContext.button_load_more}
        />
      )}
    </div>
  );
};

export default OrderList;
