/// <reference types="vite-plugin-svgr/client" />

import AccountProfileSvg from '@lego/icons/src/svgs/account-profile.svg?react';
import ArrowDownSolidSvg from '@lego/icons/src/svgs/arrow-down-solid.svg?react';
import ArrowDownToLineSvg from '@lego/icons/src/svgs/arrow-down-to-line.svg?react';
import ArrowRightSolidSvg from '@lego/icons/src/svgs/arrow-right-solid.svg?react';
import ArrowUpSolidSvg from '@lego/icons/src/svgs/arrow-up-solid.svg?react';
import ArrowUpToLineSvg from '@lego/icons/src/svgs/arrow-up-to-line.svg?react';
import BarsSolidSvg from '@lego/icons/src/svgs/bars-solid.svg?react';
import BellSolidSvg from '@lego/icons/src/svgs/bell-solid.svg?react';
import BoxMagnifyingSolidSvg from '@lego/icons/src/svgs/box-magnifying-solid.svg?react';
import CalendarSvg from '@lego/icons/src/svgs/calendar.svg?react';
import ChevronDownSolidSvg from '@lego/icons/src/svgs/chevron-down-solid.svg?react';
import ChevronLeftSolidSvg from '@lego/icons/src/svgs/chevron-left-solid.svg?react';
import ChevronRightSolidSvg from '@lego/icons/src/svgs/chevron-right-solid.svg?react';
import ChevronUpSolidSvg from '@lego/icons/src/svgs/chevron-up-solid.svg?react';
import CircleInfoSvg from '@lego/icons/src/svgs/circle-info.svg?react';
import CircleInfoSolidSvg from '@lego/icons/src/svgs/circle-info-solid.svg?react';
import CircleQuestionSvg from '@lego/icons/src/svgs/circle-question.svg?react';
import CircleQuestionSolidSvg from '@lego/icons/src/svgs/circle-question-solid.svg?react';
import ControlCheckSolidSvg from '@lego/icons/src/svgs/control-check-solid.svg?react';
import ControlCloseSolidSvg from '@lego/icons/src/svgs/control-close-solid.svg?react';
import ControlMinusSolidSvg from '@lego/icons/src/svgs/control-minus-solid.svg?react';
import ControlPlusSolidSvg from '@lego/icons/src/svgs/control-plus-solid.svg?react';
import DoubleChevronSolidSvg from '@lego/icons/src/svgs/double-chevron-solid.svg?react';
import EllipsisSvg from '@lego/icons/src/svgs/ellipsis.svg?react';
import FileDocSolidSvg from '@lego/icons/src/svgs/file-doc-solid.svg?react';
import FilePdfSolidSvg from '@lego/icons/src/svgs/file-pdf-solid.svg?react';
import FilePptSolidSvg from '@lego/icons/src/svgs/file-ppt-solid.svg?react';
import FileTextSolidSvg from '@lego/icons/src/svgs/file-text-solid.svg?react';
import FileXlsSolidSvg from '@lego/icons/src/svgs/file-xls-solid.svg?react';
import GemSvg from '@lego/icons/src/svgs/gem.svg?react';
import ImageSvg from '@lego/icons/src/svgs/image.svg?react';
import LinkSolidSvg from '@lego/icons/src/svgs/link-solid.svg?react';
import ListPlusCircleSolidSvg from '@lego/icons/src/svgs/list-plus-circle-solid.svg?react';
import MagnifyingGlassSolidSvg from '@lego/icons/src/svgs/magnifying-glass-solid.svg?react';
import RotateRightSolidSvg from '@lego/icons/src/svgs/rotate-right-solid.svg?react';
import ShoppingCartSvg from '@lego/icons/src/svgs/shopping-cart.svg?react';
import ShoppingCartSolidSvg from '@lego/icons/src/svgs/shopping-cart-solid.svg?react';
import StatusCircleCheck from '@lego/icons/src/svgs/status-circle-check.svg?react';
import StatusCircleCheckSolid from '@lego/icons/src/svgs/status-circle-check-solid.svg?react';
import StatusCircleExclamationSvg from '@lego/icons/src/svgs/status-circle-exclamation.svg?react';
import StatusCircleExclamationSolidSvg from '@lego/icons/src/svgs/status-circle-exclamation-solid.svg?react';
import StatusTriangleExclamationSolidSvg from '@lego/icons/src/svgs/status-triangle-exclamation-solid.svg?react';
import SubtitleSvg from '@lego/icons/src/svgs/subtitle.svg?react';
import TrashSvg from '@lego/icons/src/svgs/trash.svg?react';
import TrashSolidSvg from '@lego/icons/src/svgs/trash-solid.svg?react';
import TruckSolidSvg from '@lego/icons/src/svgs/truck-solid.svg?react';
import React from 'react';

import { exhaustiveSwitchCheck } from '../../utils';
import { IconType } from './IconType';

type IconProps = React.SVGProps<SVGSVGElement> & {
  type: IconType;
};

export const Icon: React.FC<IconProps> = ({ type, ...props }) => {
  switch (type) {
    case IconType.ALERT:
      return <StatusCircleExclamationSolidSvg {...props} />;
    case IconType.ALERT_OUTLINE:
      return <StatusCircleExclamationSvg {...props} />;
    case IconType.ARROW_RIGHT:
      return <ArrowRightSolidSvg {...props} />;
    case IconType.BOX_MAGNIFYING:
      return <BoxMagnifyingSolidSvg {...props} />;
    case IconType.BELL:
      return <BellSolidSvg {...props} />;
    case IconType.CALENDAR:
      return <CalendarSvg {...props} />;
    case IconType.CHECK:
      return <StatusCircleCheckSolid {...props} />;
    case IconType.CHECK_OUTLINE:
      return <StatusCircleCheck {...props} />;
    case IconType.CHECKMARK:
      return <ControlCheckSolidSvg {...props} />;
    case IconType.CHEVRON_UP:
      return <ChevronUpSolidSvg {...props} />;
    case IconType.CHEVRON_DOWN:
      return <ChevronDownSolidSvg {...props} />;
    case IconType.CHEVRON_LEFT:
      return <ChevronLeftSolidSvg {...props} />;
    case IconType.CHEVRON_RIGHT:
      return <ChevronRightSolidSvg {...props} />;
    case IconType.CLEAR:
    case IconType.CLOSE:
      return <ControlCloseSolidSvg {...props} />;
    case IconType.CONTROL_CHECK:
      return <ControlCheckSolidSvg {...props} />;
    case IconType.DELETE:
      return <TrashSolidSvg {...props} />;
    case IconType.DELETE_OUTLINE:
      return <TrashSvg {...props} />;
    case IconType.DIAMOND:
      return <GemSvg {...props} />;
    case IconType.DOWNLOAD:
      return <ArrowDownToLineSvg {...props} />;
    case IconType.IMAGE:
      return <ImageSvg {...props} />;
    case IconType.INFO:
      return <CircleInfoSolidSvg {...props} />;
    case IconType.INFO_OUTLINE:
      return <CircleInfoSvg {...props} />;
    case IconType.INVALID:
      return <StatusTriangleExclamationSolidSvg {...props} />;
    case IconType.ELLIPSIS:
      return <EllipsisSvg {...props} />;
    case IconType.FILE:
      return <FileTextSolidSvg {...props} />;
    case IconType.FILE_DOC:
      return <FileDocSolidSvg {...props} />;
    case IconType.FILE_PDF:
      return <FilePdfSolidSvg {...props} />;
    case IconType.FILE_PPT:
      return <FilePptSolidSvg {...props} />;
    case IconType.FILE_XLS:
      return <FileXlsSolidSvg {...props} />;
    case IconType.LINK:
      return <LinkSolidSvg {...props} />;
    case IconType.LIST_PLUS:
      return <ListPlusCircleSolidSvg {...props} />;
    case IconType.MENU:
      return <BarsSolidSvg {...props} />;
    case IconType.MINUS:
      return <ControlMinusSolidSvg {...props} />;
    case IconType.PLUS:
      return <ControlPlusSolidSvg {...props} />;
    case IconType.RESET:
      return <RotateRightSolidSvg {...props} />;
    case IconType.SEARCH:
      return <MagnifyingGlassSolidSvg {...props} />;
    case IconType.SHOPPING_CART:
      return <ShoppingCartSolidSvg {...props} />;
    case IconType.SHOPPING_CART_OUTLINE:
      return <ShoppingCartSvg {...props} />;
    case IconType.SORT_ASC:
      return <ArrowUpSolidSvg {...props} />;
    case IconType.SORT_DEFAULT:
      return <DoubleChevronSolidSvg {...props} />;
    case IconType.SORT_DESC:
      return <ArrowDownSolidSvg {...props} />;
    case IconType.SUBTITLE:
      return <SubtitleSvg {...props} />;
    case IconType.TRUCK:
      return <TruckSolidSvg {...props} />;
    case IconType.QUESTION:
      return <CircleQuestionSolidSvg {...props} />;
    case IconType.QUESTION_OUTLINE:
      return <CircleQuestionSvg {...props} />;
    case IconType.UPLOAD:
      return <ArrowUpToLineSvg {...props} />;
    case IconType.USER:
      return <AccountProfileSvg {...props} />;
    default:
      exhaustiveSwitchCheck(type);
  }
};
