import { gql } from '@apollo/client';

export const PDF_INVOICE = gql`
  query pdfInvoice($invoiceNumber: Int!, $customerId: Int!) {
    getInvoicePdf(customerId: $customerId, invoiceNumber: $invoiceNumber) {
      fileName
      fileDataAsBase64
    }
  }
`;
