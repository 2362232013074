import { css } from '@emotion/react';
import React from 'react';

import { Table, TableHead, TableHeaderCell, TableRow } from '../Table';
import { useModalContext } from './ModalContext';

type ModalTableBodyProps = {
  children: React.ReactNode;

  headers: string[];
};

const modalTableBodyContainerStyle = (maxHeight: string) =>
  css({
    maxHeight,
    display: 'block',
    maxWidth: '100%',
    overflow: 'auto',
    position: 'relative',
  });

/**
 * Modal table body component
 *
 * Special component for modals that has a table as the main content
 * The children should be the rows of the table, without a <tbody /> wrapper
 *
 * The headers prop is used to render the table headers and will be sticky on scroll
 *
 * @param children
 * @param headers
 * @constructor
 */
export const ModalTableBody: React.FC<ModalTableBodyProps> = ({ children, headers }) => {
  const modalContext = useModalContext();
  const modalSectionRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);

  return (
    <section
      css={modalTableBodyContainerStyle(modalContext.modalBodyMaxHeight)}
      ref={modalSectionRef}
    >
      <Table ref={tableRef}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableHeaderCell
                key={index}
                sticky
              >
                {header}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <tbody>{children}</tbody>
      </Table>
    </section>
  );
};
