import { css } from '@emotion/react';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import { NotificationType } from './NotificationType';
import { designToken } from '@lego/b2b-unicorn-shared/ui';

export const notificationIconStyle = (type, small) =>
  css({
    height: small ? 28 : 36,
    minWidth: small ? 28 : 36,
    backgroundColor: designToken.transparent.default,
    marginRight: small ? baseSpacing / 2 : baseSpacing,
    marginLeft: small ? 0 : baseSpacing * 1.5,
    '> svg': {
      height: small ? 24 : 36,
      width: small ? 24 : 36,
      fill: {
        [NotificationType.DEFAULT]: designToken.information.default,
        [NotificationType.ERROR]: designToken.error.default,
        [NotificationType.POSITIVE]: designToken.success.default,
        [NotificationType.WARNING]: designToken.warning.default,
      }[type],
    },
  });
