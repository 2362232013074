import { gql } from '@apollo/client';

export const LAUNCH_WINDOWS_OVERVIEW = gql`
  query launchWindowsOverview($customerId: Int!) {
    launchWindows(customerId: $customerId) {
      openWindows {
        productsCount
        month
        year
        orderEndInstant
        ordersCreated {
          orderNumber
        }
        cartHasItems
        products {
          ... on Box {
            itemNumber
          }
          ean
          materialId
          name
          theme
          stockStatus {
            status
          }
          piecesPerCasePack
          price {
            currency
            estimatedNetInvoicedPrice
          }
        }
      }
      previousWindows {
        productsCount
        month
        year
        ordersCreated {
          orderNumber
        }
      }
      futureWindows {
        productsCount
        month
        year
      }
    }
  }
`;
