import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';

export const headerStyle = css({
  color: designToken.text.default,
  fontSize: font.size.large,
  fontWeight: font.weight.medium,
  marginTop: baseSpacing,
  marginBottom: 0,
  textAlign: 'left',
  flexShrink: 0,
  [media.small]: {
    marginTop: 0,
  },
});

export const subHeaderStyle = css({
  marginTop: 0,
  color: designToken.text.default,
  fontSize: font.size.medium,
  fontWeight: font.weight.bold,
  textAlign: 'left',
});

export const scrollBoxContainer = css({
  borderTop: `3px solid ${colors.klik.slate50}`,
  borderBottom: `3px solid ${colors.klik.slate50}`,
  marginTop: baseSpacing * 3,
  marginBottom: baseSpacing * 2,
  flexShrink: 1,
  overflowY: 'hidden',
  position: 'relative',
  height: 440,
  '&:after': {
    pointerEvents: 'none',
    content: '""',
    display: 'block',
    width: `calc(100% - ${baseSpacing * 2}px)`,
    height: '100%',
    position: 'sticky',
    top: 0,
    right: baseSpacing * 2,
    bottom: 0,
    left: 0,
    boxShadow: `0 ${baseSpacing * 2}px ${baseSpacing}px -${baseSpacing}px ${
      colors.klik.slate10
    } inset,
                0 -${baseSpacing * 6}px ${baseSpacing * 3}px -${baseSpacing * 2}px ${
                  colors.klik.slate10
                } inset`,
  },
});

export const scrollBox = css({
  paddingTop: baseSpacing * 3,
  paddingBottom: baseSpacing * 3,
  paddingRight: baseSpacing * 2,
  height: 'calc(100% - 1px)',
  overflow: 'auto',
  '> p': {
    marginBottom: baseSpacing * 1.5,
  },
  strong: {
    fontWeight: font.weight.medium,
  },
  a: {
    color: designToken.interactive.default,
    fontWeight: font.weight.medium,
    transitionProperty: 'color',
    transitionDuration: '125ms',
    position: 'relative',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    '&:hover': {
      color: designToken.interactive.hover,
    },
    '&:focus': {
      color: colors.klik.lightBlue800,
      outline: 'none',
      textDecorationColor: colors.klik.lightBlue300,
    },
  },
});

export const footerStyle = css({
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: baseSpacing,
  paddingTop: baseSpacing,
  paddingBottom: baseSpacing * 2,
  flexWrap: 'wrap',
  [media.small]: {
    flexWrap: 'unset',
    padding: 0,
    alignItems: 'center',
    alignSelf: 'flex-start',
    paddingTop: baseSpacing * 2,
  },
});

export const buttonStyle = css({
  marginTop: baseSpacing * 3,
  width: '100%',
  '> div': {
    marginTop: baseSpacing * 2,
  },
  [media.small]: {
    marginTop: 0,
    width: 'auto',
    alignSelf: 'flex-end',
    '> button': {
      width: 220,
    },
    '> div': {
      marginTop: baseSpacing,
      width: 220,
    },
  },
});

export const checkboxLabelStyle = {
  fontSize: font.size.medium,
  fontWeight: font.weight.medium,
  marginLeft: baseSpacing * 2,
  lineHeight: 1.3,
};

export const labelStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: baseSpacing,
});
