import UpcomingDeliveries from '../../pages/manage/UpcomingDeliveries/UpcomingDeliveries';
import ManagePageLayout from './ManagePageLayout';

const UpcomingDeliveriesLayout = () => {
  return (
    <ManagePageLayout>
      <UpcomingDeliveries />
    </ManagePageLayout>
  );
};

export default UpcomingDeliveriesLayout;
