import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { BulkQuickAddTriggerButton } from '@lego/b2b-unicorn-shared/components/BulkQuickAdd';
import { ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useCallback, useRef, useState } from 'react';

import { MultipleLaunchWindowBulkQuickAdd } from '../../../../components/BulkQuickAdd';

const containerStyle = css({
  display: 'none',
  '> button': {
    marginTop: baseSpacing * 2,
    marginBottom: baseSpacing * 2,
  },
  [media.medium]: {
    display: 'flex',
    padding: 0,
    marginTop: 3,
  },
});

type LaunchWindowsBulkQuickAddProps = {
  onCartUpdated: () => void;
};

export const LaunchWindowsBulkQuickAddAction: React.FC<LaunchWindowsBulkQuickAddProps> = ({
  onCartUpdated,
}) => {
  const { button_bulk_quick_add } = useLabels();
  const [showBulkQuickAdd, setShowBulkQuickAdd] = useState(false);
  const cartUpdated = useRef(false);

  const { trackEvent } = useAnalytics();

  const handleCartUpdated = useCallback(() => {
    cartUpdated.current = true;
  }, []);

  const handleOnBulkQuickAddOpenClick = useCallback(() => {
    trackEvent({
      event: 'reopenBulkQuickAddLaunchWindowMultiple',
      name: `Open multiple launch window bulk quick add modal`,
    });
    setShowBulkQuickAdd(true);
  }, [trackEvent]);

  const handleOnBulkQuickAddClose = useCallback(() => {
    if (cartUpdated.current) {
      onCartUpdated();
    }
    setShowBulkQuickAdd(false);
  }, [onCartUpdated]);

  const handleOnBulkQuickAddHasPreviousSession = useCallback(() => {
    trackEvent({
      event: 'reopenBulkQuickAddLaunchWindowMultiple',
      name: `Open multiple launch window bulk quick add modal with previous session`,
    });
    setShowBulkQuickAdd(true);
  }, []);

  return (
    <div css={containerStyle}>
      <BulkQuickAddTriggerButton
        handleOnBulkQuickAddOpenClick={handleOnBulkQuickAddOpenClick}
        translations={{ button_bulk_quick_add }}
        buttonSize={'small'}
        buttonType={ButtonType.SECONDARY}
      />
      <MultipleLaunchWindowBulkQuickAdd
        open={showBulkQuickAdd}
        closeModal={handleOnBulkQuickAddClose}
        onHasPreviousSession={handleOnBulkQuickAddHasPreviousSession}
        onCartUpdated={handleCartUpdated}
      />
    </div>
  );
};
