import { logger } from '@lego/b2b-unicorn-shared/logger';

const requiredEnvVariables = [
  'COOKIE_DOMAIN',
  'ASSETS_URL',
  'GRAPHQL_SERVER_URL',
  'OPTIMIZELY_KEY',
  'ADYEN_CLIENT_KEY',
  'ADYEN_ENVIRONMENT',
  'PARTNER_IDP_REDIRECT_URI',
  'PARTNER_IDP_CLIENT_ID',
];

const missingEnvVariables: string[] = [];
for (const variable of requiredEnvVariables) {
  if (!process.env[variable]) {
    missingEnvVariables.push(variable);
  }
}

type ApplicationEnvironment = 'dev' | 'perf' | 'prod' | 'local';
const APP_ENVIRONMENT = (process.env.APP_ENVIRONMENT as ApplicationEnvironment) || 'local';

const bootstrapLogger = logger.createLogger('bootstrapping');

if (missingEnvVariables.length > 0) {
  const e = new Error(`Invalid configuration: Missing ${missingEnvVariables.join(', ')}`);
  bootstrapLogger.fatal(e);
  throw e;
}

const GRAPHQL_SERVER_URL = process.env.GRAPHQL_SERVER_URL!;

const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN!;
const ASSETS_URL = process.env.ASSETS_URL!;

const OPTIMIZELY_KEY = process.env.OPTIMIZELY_KEY!;
const USERSNAP_SPACE_KEY = process.env.USERSNAP_SPACE_KEY!;
const USERSNAP_SATISFACTION_WIDGET_KEY = process.env.USERSNAP_SATISFACTION_WIDGET_KEY!;
const USERSNAP_CLAIMS_SATISFACTION_WIDGET_KEY =
  process.env.USERSNAP_CLAIMS_SATISFACTION_WIDGET_KEY!;

const ADYEN_CLIENT_KEY = process.env.ADYEN_CLIENT_KEY!;
const ADYEN_ENVIRONMENT = process.env.ADYEN_ENVIRONMENT!;

const PARTNER_IDP_REDIRECT_URI = process.env.PARTNER_IDP_REDIRECT_URI!;
const PARTNER_IDP_CLIENT_ID = process.env.PARTNER_IDP_CLIENT_ID!;

export {
  ADYEN_CLIENT_KEY,
  ADYEN_ENVIRONMENT,
  APP_ENVIRONMENT,
  ASSETS_URL,
  bootstrapLogger,
  COOKIE_DOMAIN,
  GRAPHQL_SERVER_URL,
  OPTIMIZELY_KEY,
  PARTNER_IDP_CLIENT_ID,
  PARTNER_IDP_REDIRECT_URI,
  USERSNAP_CLAIMS_SATISFACTION_WIDGET_KEY,
  USERSNAP_SATISFACTION_WIDGET_KEY,
  USERSNAP_SPACE_KEY,
};
