import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { media } from '@lego/b2b-unicorn-ui-constants';
import { Children, FunctionComponent, ReactNode } from 'react';

import { skeletonGradientAnimation } from '../../styles/skeleton';

const containerStyle = css({
  padding: 16,
  background: designToken.background.blue,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  minHeight: 130,
  h2: {
    fontWeight: 400,
    width: '100%',
    marginBottom: 16,
  },
  [media.medium]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingTop: 40,
    paddingRight: 32,
    paddingBottom: 40,
    paddingLeft: 32,
    h2: {
      width: 'auto',
      margin: 0,
    },
  },
});

interface StatusSummaryProps {
  children: ReactNode;
}

const StatusSummary: FunctionComponent<StatusSummaryProps> = (props) => {
  const styles = [containerStyle];

  if (Children.count(props.children) === 0) {
    styles.push(skeletonGradientAnimation);
  }

  return <div css={styles}>{props.children}</div>;
};

export default StatusSummary;
