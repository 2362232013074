import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Button, ButtonType, designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { CartItemCounter } from '@lego/b2b-unicorn-ui-checkout-flow';
import React from 'react';

interface CartPreviewItemCounterWithButtonProps {
  quantity: number;
  onChange: (quantity: number) => void;
  minValue?: number;
  minValueHelpText?: string;
  isInvalid?: boolean;
}

const customStyle = css({
  width: 154,
  marginRight: 0,
  backgroundColor: designToken.background.offWhite,
});

export const CartPreviewItemCounterWithButton: React.FC<CartPreviewItemCounterWithButtonProps> = ({
  quantity,
  onChange,
  minValue,
  minValueHelpText,
  isInvalid,
}) => {
  const { button_add_to_order } = useLabels();

  return quantity < 1 && !isInvalid ? (
    <Button
      type={ButtonType.SECONDARY}
      size="small"
      onClick={() => {
        onChange(1);
      }}
    >
      <Icon type={IconType.SHOPPING_CART_OUTLINE} />
      {button_add_to_order}
    </Button>
  ) : (
    <CartItemCounter
      quantity={quantity}
      onChange={onChange}
      customStyle={customStyle}
      allowResetByZero
      minValue={minValue}
      minValueHelpText={minValueHelpText}
      isInvalid={isInvalid}
    />
  );
};
