import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { colors, font } from '@lego/b2b-unicorn-ui-constants';

const adjustAdyenStyle = css({
  '.adyen-checkout__label': {
    fontSize: font.size.small,
  },
  '.adyen-checkout__label--focused': {
    '.adyen-checkout__label__text': {
      color: 'inherit',
    },
  },
  '.adyen-checkout__input': {
    borderRadius: 4,
    fontSize: font.size.small,
  },
  '.adyen-checkout__input--focus, .adyen-checkout__input:focus': {
    borderColor: colors.klik.slate200,
    boxShadow: 'none',
  },
  '.adyen-checkout__input--disabled': {
    borderWidth: 0,
    color: colors.klik.slate400,
  },
  '.adyen-checkout__payment-method, .adyen-checkout__payment-method--selected': {
    borderWidth: 0,
    margin: 0,
    backgroundColor: 'transparent',
  },
  '.adyen-checkout__button--pay': {
    display: 'none',
  },
  '.adyen-checkout__payment-method__header, .adyen-checkout__payment-method__details': {
    paddingLeft: 24,
    paddingTop: 0,
  },
  '.adyen-checkout__payment-method__header__title': {
    fontSize: font.size.medium,
  },
  '.adyen-checkout__payment-method__radio': {
    left: 0,
  },
  '.adyen-checkout__label__text--error, .adyen-checkout__error-text': {
    color: designToken.error.default,
  },
  '.adyen-checkout__input, .adyen-checkout__input--valid': {
    borderColor: colors.klik.slate200,
  },
  '.adyen-checkout__input--invalid': {
    borderColor: designToken.error.default,
  },
  '.adyen-checkout-input__inline-validation img': {
    display: 'block',
  },
  '.adyen-checkout__payment-method:last-child': {
    '> .adyen-checkout__payment-method__header': {
      display: 'block',

      '> .adyen-checkout__payment-method__brands': {
        // Card icons are 40 px, plus 8 px, plus 4 for alignment
        paddingLeft: 40 + 8 + 4,
      },
    },
  },
  '.adyen-checkout__button--link': {
    borderColor: designToken.interactive.default,
    paddingLeft: 4,
    paddingRight: 4,
    color: designToken.interactive.default,
    '&:hover': {
      textDecoration: 'none',
      color: designToken.interactive.hover,
      borderColor: designToken.interactive.hover,
      backgroundColor: designToken.background.blue,
    },
    '&:focus': {
      color: designToken.interactive.active,
      borderColor: designToken.interactive.active,
      boxShadow: `0 0 0 2px ${designToken.interactive.active}`,
    },
  },
});

export default adjustAdyenStyle;
