import { gql } from '@apollo/client';

export const CART_ITEM_BASICS = gql`
  fragment CartItemBasics on CartItem {
    product {
      materialId
      ... on Box {
        itemNumber
      }
      name
      theme
      piecesPerCasePack
      price {
        amount
        currency
        grossPrice
        estimatedNetInvoicedPrice
      }
    }
    quantity
  }
`;
