import { nanoid } from 'nanoid';
import React, { ReactElement, useEffect, useState } from 'react';

import { wrapperStyle } from './styles';
import { TooltipContent } from './TooltipContent';
import { ToolTipDirection } from './ToolTipDirection';
import { useDelayUnmount } from './useDelayUnmount';

// CSS media query 'pointer: coarse' means the device's primary pointing device is inaccurate.
// In other words, the device does not have mouse. So we can somewhat safely assume it is a phone or tablet.
const isTouchDevice = window.matchMedia('(pointer:coarse)').matches;

type ToolTipProps = {
  children?: ReactElement | string;
  /** Message to show in the tooltip. */
  message: React.ReactNode;

  /** Direction the tooltip will point, relative to the child element. Default is up and to the right. */
  direction?: ToolTipDirection;

  /** If provided, the tooltip will be controlled by this prop. */
  open?: boolean;

  /** If provided, the tooltip trigger will be rendered with full height */
  triggerFullHeight?: boolean;
};

export const ToolTip: React.FC<ToolTipProps> = ({
  children,
  message,
  direction = ToolTipDirection.UpRight,
  open,
  triggerFullHeight,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(open || false);
  const shouldRenderTooltip = useDelayUnmount(showTooltip, 500);
  const externallyControlled = open !== undefined;

  useEffect(() => {
    if (externallyControlled) {
      setShowTooltip(open);
    }
  }, [open]);

  const [id] = useState(() => nanoid());
  const triggerId = `tooltip-trigger-${id}`;
  const trigger = document.getElementById(triggerId)?.getBoundingClientRect();

  // window.scrollX and window.scrollY added to adjust position when page is scrolled.
  let position = undefined;
  if (trigger?.top && trigger?.right && trigger?.bottom && trigger?.right) {
    position = {
      top: trigger.top + window.scrollY,
      right: trigger.right,
      bottom: trigger.bottom,
      left: trigger.left + window.scrollX,
      width: trigger.width || 0,
      height: trigger.height || 0,
    };
  }

  if (isTouchDevice) {
    // Tooltip is exclusively for use on hover, so this is
    // to avoid it showing up on touch devices when tapping.
    return children;
  } else {
    return (
      <span
        id={triggerId}
        css={wrapperStyle(triggerFullHeight)}
        onMouseEnter={() => {
          if (!externallyControlled) {
            setShowTooltip(true);
          }
        }}
        onMouseLeave={() => {
          if (!externallyControlled) {
            setShowTooltip(false);
          }
        }}
      >
        {children}
        {shouldRenderTooltip && (
          <TooltipContent
            message={message}
            direction={direction}
            triggerPosition={position}
            unmounting={!showTooltip}
          />
        )}
      </span>
    );
  }
};
