import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ProductTag } from '@lego/b2b-unicorn-data-access-layer';
import { Switch } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

import { useProductListContext } from '../../ProductListContext';

export const RecommendedProductsSwitch: React.FC = () => {
  const { toggle_label_recommended } = useLabels();
  const { selectedFilters, showRecommendedProductsSwitch, handleProductTagsChange } =
    useProductListContext();

  return showRecommendedProductsSwitch ? (
    <Switch
      onToggle={() => handleProductTagsChange(ProductTag.RecommendedAssortment)}
      isOn={selectedFilters?.tags?.includes(ProductTag.RecommendedAssortment) || false}
      label={toggle_label_recommended}
    />
  ) : null;
};
