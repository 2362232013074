import { gql } from '@apollo/client';

export const UPDATE_USER_PREFERENCES_FOR_CUSTOMER = gql`
  mutation updateUserPreferencesForCustomer(
    $customerId: Int!
    $preferences: UserPreferencesForCustomerInput!
  ) {
    updateUserPreferencesForCustomer(customerId: $customerId, preferences: $preferences) {
      email
      customers {
        id
        locale
      }
      preferencesForCustomers {
        customerId
        preferredLanguage {
          id
          code
        }
      }
    }
  }
`;
