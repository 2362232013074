import { gql } from '@apollo/client';

export const REPLENISHMENT_CART_SUMMARY = gql`
  query replenishmentCartSummary($customerId: Int!) {
    getCart(customerId: $customerId, cartReference: { cartType: REPLENISH }) {
      ...cartKey
      items {
        ...minimumCartItem
      }
    }
  }
`;
