import { getCookieByName } from '@lego/b2b-unicorn-ui-utils';

interface ILegoCookiePreference {
  key: 'analytics' | 'legomarketing' | 'thirdparty' | 'necessary';
  value: boolean;
  channelID: number;
  statementID: number;
}

interface ILegoCookie {
  preferences: ILegoCookiePreference[];
  reconsentDate: string;
}

export const checkUserConsent = () => {
  const consentCookie = getCookieByName<ILegoCookie>('LEGO_COOKIE_SETTINGS');

  if (consentCookie) {
    const analyticsContent = consentCookie.preferences?.find(
      (consent) => consent.key === 'analytics'
    );
    return !!analyticsContent?.value;
  }
  return false;
};
