import { css, keyframes } from '@emotion/react';
import { designToken, Spacer } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';

const pulse = keyframes`
  100% {transform: translateX(100%)}
`;

const tableContainerStyle = css({
  position: 'relative',
  overflow: 'hidden',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    top: 0,
    width: '100%',
    height: '100%',
    transform: 'translateX(-100%)',
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${designToken.transparent.default} 30%, ${designToken.background.offWhite} 50%,${designToken.transparent.default} 70%, ${designToken.transparent.default} 100%)`,
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    [media.medium]: {
      animationDuration: '1.8s',
    },
  },
});

const tableStyle = css({
  width: '100%',
  display: 'table',
  marginBottom: baseSpacing * 4,
  paddingLeft: 0,
  paddingRight: 0,
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    [media.medium]: {
      zIndex: 10,
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '100vw',
      height: 278,
      top: 0,

      background: `linear-gradient(${designToken.transparent.default} 0%, ${designToken.background.offWhite} 100%)`,
    },
  },

  [media.small]: {
    paddingLeft: baseSpacing * 4,
    paddingRight: baseSpacing * 4,
  },
  [media.medium]: {
    padding: 0,
    marginBottom: 0,
  },
});

const tableHeaderStyle = css({
  width: '100%',
  height: 65,
  backgroundColor: designToken.skeleton.default,
  borderBottomWidth: 2,
  borderBottomColor: designToken.border.inverse,
  borderBottomStyle: 'solid',
  [media.medium]: {
    height: 50,
  },
});

const rowsContainerStyle = css({
  paddingTop: baseSpacing,
  [media.medium]: {
    display: 'contents',
  },
});

const productRowStyle = css({
  width: '50%',
  marginLeft: '20%',
  marginTop: 10,
  marginBottom: 10,
  height: baseSpacing * 2,
  backgroundColor: designToken.skeleton.secondary,

  [media.medium]: {
    width: '100%',
    height: 57,
    margin: 0,
    borderBottomWidth: 2,
    borderBottomColor: designToken.border.inverse,
    borderBottomStyle: 'solid',
  },
});

export const SkeletonItemsTable = () => {
  return (
    <div css={tableContainerStyle}>
      <div css={tableStyle}>
        <div css={tableHeaderStyle} />
        <div css={rowsContainerStyle}>
          {[...Array(4)].map((_, i) => {
            return (
              <div
                css={productRowStyle}
                key={i}
              />
            );
          })}
        </div>
      </div>
      <Spacer multiplier={8} />
    </div>
  );
};
