import { NotificationType } from '@lego/b2b-unicorn-ui-components';
import React, { createContext, useContext, useState } from 'react';

interface ISnackbar {
  type: NotificationType;
  content: string | React.ReactNode;
  showDismissButton: boolean;
  isStacked: boolean;
  timeout?: number;
}

interface INotificationContext {
  snackbar: ISnackbar | null;
  addSnackbar: (snackbar: ISnackbar) => void;
  removeSnackbar: () => void;
}
const NotificationsContext = createContext<INotificationContext | undefined>(undefined);

interface NotificationsProviderProps {
  children: React.ReactNode;
}
const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children }) => {
  const [snackbar, addSnackbar] = useState<ISnackbar | null>(null);

  const removeSnackbar = () => addSnackbar(null);

  const contextValue = {
    snackbar,
    addSnackbar,
    removeSnackbar,
  };

  return (
    <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>
  );
};
const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};

export { NotificationsContext, NotificationsProvider, useNotifications };
