import { deepEqual } from 'fast-equals';
import React from 'react';
import type { CellBase } from 'react-spreadsheet';

import { DiscountedPrice } from '../../../../ui/DiscountedPrice';
import { useBulkQuickAddContext } from '../../BulkQuickAddContext';

interface IPrice {
  __typename?: 'Price';
  currency: string;
  estimatedNetInvoicedPrice: number;
  grossPrice?: number | null;
  netInvoicedPrice?: number | null;
}

type PricePerPieceCellProps = {
  cell?: CellBase<IPrice | ''>;
};

export const PricePerPieceCell: React.FC<PricePerPieceCellProps> = React.memo(
  ({ cell }) => {
    const { locale } = useBulkQuickAddContext();

    if (!cell || !cell.value) {
      return null;
    }

    const price = cell.value;

    return <DiscountedPrice locale={locale}>{price}</DiscountedPrice>;
  },
  (prevProps, nextProps) => deepEqual(prevProps.cell, nextProps.cell)
);
PricePerPieceCell.displayName = 'PricePerPieceCell';
