import { css } from '@emotion/react';
import { ApmService } from '@lego/b2b-unicorn-apm';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { mapLocaleForUsersnap, UsersnapService } from '@lego/b2b-unicorn-bootstrap/services';
import { useSelectedCustomer, useUser } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Loader } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import React, { useEffect, useState } from 'react';

const widgetContainerStyle = css({
  width: '100%',
  minHeight: 250,
  marginBottom: baseSpacing * 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type CustomParams = {
  [key: string]: string | number;
};
type InlineUsersnapWidgetProps = {
  widgetKey: string;
  usersnapOptsCustomParams: CustomParams;
};

export const InlineUsersnapWidget: React.FC<InlineUsersnapWidgetProps> = ({
  widgetKey,
  usersnapOptsCustomParams,
}) => {
  const { loading } = useLabels();
  const user = useUser();
  const selectedCustomer = useSelectedCustomer();

  // Internal component state
  const [usersnapHasError, setUsersnapHasError] = useState(false);

  // Handlers
  const handleUsersnapError = (error: Error) => {
    setUsersnapHasError(true);
    ApmService.instance?.captureError(error);
  };
  const handleUsersnapScriptError = () => {
    setUsersnapHasError(true);
    ApmService.instance?.captureError(new Error(`Failed to load Usersnap widget '${widgetKey}'`));
  };

  const userSnapContainerId = 'widgetContainer';

  useEffect(() => {
    UsersnapService.getInstance().mountInlineWidget(widgetKey, {
      onError: handleUsersnapError,
      onLoadError: handleUsersnapScriptError,
      onLoaded: () => {
        setUsersnapHasError(false);
      },
      usersnapOpts: {
        mountNode: document.getElementById(userSnapContainerId)!,
        collectGeoLocation: 'none',
        useLocalStorage: false,
        locale: mapLocaleForUsersnap(selectedCustomer.locale),
        user: {
          email: user.email,
          userId: selectedCustomer.id,
        },
        custom: {
          salesOrg: selectedCustomer.salesOrg,
          environment: process.env.APP_ENVIRONMENT || 'localhost',
          customerId: selectedCustomer.id,
          ...usersnapOptsCustomParams,
        },
      },
    });

    return () => {
      UsersnapService.getInstance().destroyInlineWidget(widgetKey);
    };
  }, [
    usersnapOptsCustomParams,
    selectedCustomer.id,
    selectedCustomer.locale,
    selectedCustomer.salesOrg,
    user.email,
  ]);

  return !usersnapHasError ? (
    <div
      id={userSnapContainerId}
      css={widgetContainerStyle}
    >
      {/* Starts with a Loader component but will be overwritten by the Usersnap script because of the div's id. */}
      <Loader translations={{ loading }} />
    </div>
  ) : null;
};
