import { FunctionComponent, ReactNode } from 'react';

import MenuIconLink from './MenuIconLink';
import MenuTextLink from './MenuTextLink';

interface IMenuItem {
  type?: 'icon' | 'text';
  path: string;
  dataE2e?: string;
  children: ReactNode;
}

export interface IMenuLink {
  isActive: boolean;
  path: string;
  dataE2e?: string;
  children: ReactNode;
}

const isActive = (path: string) => {
  const currentLocationPath = window.location.pathname;
  if (path === '/launch') {
    return currentLocationPath.startsWith('/launch');
  }

  if (path === '/promotion') {
    return currentLocationPath.startsWith('/promotion');
  }

  if (path === '/manage') {
    return currentLocationPath.startsWith('/manage');
  }

  if (path === '/info') {
    return currentLocationPath.startsWith('/info');
  }

  if (path === '/') {
    return (
      !currentLocationPath.startsWith('/launch') &&
      !currentLocationPath.startsWith('/promotion') &&
      !currentLocationPath.startsWith('/manage') &&
      !currentLocationPath.startsWith('/info')
    );
  }

  return true;
};

const MenuItem: FunctionComponent<IMenuItem> = ({ children, path, dataE2e, type = 'text' }) => {
  const isItemActive = isActive(path);

  return type === 'icon' ? (
    <MenuIconLink
      isActive={isItemActive}
      path={path}
      dataE2e={dataE2e}
    >
      {children}
    </MenuIconLink>
  ) : (
    <MenuTextLink
      isActive={isItemActive}
      path={path}
      dataE2e={dataE2e}
    >
      {children}
    </MenuTextLink>
  );
};

export default MenuItem;
