import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { NotificationType } from '@lego/b2b-unicorn-ui-components';
import { isAfter, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import NotificationBanner from './components/NotificationBanner';
import { ILink } from './NotificationType';
import { getLatestBanner } from './utils';

export type IBanner = {
  message: string;
  type: NotificationType.DEFAULT | NotificationType.WARNING | NotificationType.ERROR;
  link?: ILink;
  updated: string;
};

const Banner = () => {
  const [banner, setBanner] = useState<IBanner | null>(null);
  const selectedCustomer = useSelectedCustomer();
  const sessionStorageKey = `${selectedCustomer.id}-removedBannerDate`;

  const handleDismissClick = () => {
    sessionStorage.setItem(sessionStorageKey, banner?.updated || '');
    setBanner(null);
  };

  useEffect(() => {
    getLatestBanner(selectedCustomer.id).then((latestBanner) => {
      if (!latestBanner) {
        return;
      }

      let showBanner = true;
      const removedBannerDate = sessionStorage.getItem(sessionStorageKey);
      if (removedBannerDate) {
        showBanner = isAfter(parseISO(latestBanner.updated), parseISO(removedBannerDate));
      }

      if (showBanner) {
        setBanner(latestBanner);
      }
    });
  }, [selectedCustomer, sessionStorageKey]);

  return (
    banner && (
      <NotificationBanner
        message={banner.message}
        link={banner.link}
        type={banner.type}
        onDismissClick={handleDismissClick}
      />
    )
  );
};

export default Banner;
