import {
  da,
  de,
  enAU,
  enCA,
  enGB,
  enNZ,
  enUS,
  es,
  fi,
  fr,
  frCA,
  frCH,
  it,
  ja,
  nb,
  nlBE,
  pl,
  pt,
  ptBR,
  sv,
  uk,
  zhCN,
  zhTW,
} from 'date-fns/locale';

export const getLocaleForDatePicker = (locale: string) => {
  switch (locale.toLowerCase()) {
    case 'da-dk':
      return da;
    case 'de-at':
    case 'de-ch':
    case 'de-de':
      return de;
    case 'en-au':
      return enAU;
    case 'en-ca':
      return enCA;
    case 'en-ae':
    case 'en-be':
    case 'en-gb':
    case 'en-ie':
    case 'en-my':
    case 'en-nl':
    case 'en-sg':
      return enGB;
    case 'en-nz':
      return enNZ;
    case 'en-us':
      return enUS;
    case 'es-es':
    case 'es-mx':
      return es;
    case 'fi-fi':
      return fi;
    case 'fr-ca':
      return frCA;
    case 'fr-ch':
      return frCH;
    case 'fr-BE':
    case 'fr-fr':
      return fr;
    case 'it-it':
      return it;
    case 'ja-jp':
      return ja;
    case 'nl-be':
      return nlBE;
    case 'no-no':
      return nb;
    case 'pl-pl':
      return pl;
    case 'pt-br':
      return ptBR;
    case 'pt-pt':
      return pt;
    case 'sv-se':
      return sv;
    case 'uk-ua':
      return uk;
    case 'zh-hk':
      return zhCN;
    case 'zh-tw':
      return zhTW;
    default:
      return enUS;
  }
};
