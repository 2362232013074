import { css } from '@emotion/react';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { COOKIE_DOMAIN } from '@lego/b2b-unicorn-bootstrap/constants';
import { CartReferenceCartType } from '@lego/b2b-unicorn-data-access-layer';
import { AppLink } from '@lego/b2b-unicorn-shared/components/AppLink';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import {
  CheckoutPage,
  CheckoutStep,
  IOrderConfirmationInfo,
  OrderConfirmation,
} from '@lego/b2b-unicorn-ui-checkout-flow';
import { createDocumentTitle, GoToTop } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import { Logo, Topbar } from '@lego/b2b-unicorn-ui-topbar';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import ReplenishmentCartPreview from '../components/ReplenishmentCartPreview/ReplenishmentCartPreview';
import { DOCUMENT_TITLE } from '../constants';

const backgroundStyle = css({
  color: colors.klik.slate700,
  width: '100%',
  height: '100%',
});

const backToShopStyle = {
  marginBottom: baseSpacing * 5,
};

const CheckoutFlowLayout = () => {
  const { startTransaction, endTransaction } = useApmTransaction();
  const { customTrackPageView } = useAnalytics();
  const { button_back_to_shop } = useLabels();

  const [checkoutStep, setCheckoutStep] = useState(CheckoutStep.PREVIEW);
  const [orderConfirmationInfo, setConfirmationOrderInfo] = useState<IOrderConfirmationInfo>({});

  useEffect(() => {
    const documentTitles = {
      [CheckoutStep.PREVIEW]: [DOCUMENT_TITLE.CART.PREVIEW, DOCUMENT_TITLE.MAIN],
      [CheckoutStep.CHECKOUT]: [DOCUMENT_TITLE.CART.CHECKOUT, DOCUMENT_TITLE.MAIN],
      [CheckoutStep.CONFIRMATION]: [DOCUMENT_TITLE.CART.ORDER_CONFIRMATION, DOCUMENT_TITLE.MAIN],
    }[checkoutStep];
    const currentDocumentTitle = createDocumentTitle(documentTitles);

    if (currentDocumentTitle !== document.title) {
      document.title = currentDocumentTitle;
    }
    customTrackPageView(currentDocumentTitle);
    startTransaction && startTransaction();
  }, [checkoutStep, customTrackPageView, startTransaction]);

  const handleOnOrderCreationSuccessHandler = (
    orderNumber: number,
    orderType: CartReferenceCartType
  ) => {
    setConfirmationOrderInfo({ orderNumber, orderType });
    setCheckoutStep(CheckoutStep.CONFIRMATION);
    window.scrollTo(0, 0);
  };

  const handleOnLoaded = useCallback(() => {
    endTransaction && endTransaction();
  }, [endTransaction]);

  const cartReference = useMemo(
    () => ({
      cartType: CartReferenceCartType.Replenish,
    }),
    []
  );

  return (
    <Fragment>
      <Topbar>
        <Logo />
      </Topbar>
      <div css={backgroundStyle}>
        {
          {
            PREVIEW: (
              <ReplenishmentCartPreview
                onGoToCheckoutClick={() => setCheckoutStep(CheckoutStep.CHECKOUT)}
                onCartLoaded={handleOnLoaded}
              />
            ),
            CHECKOUT: (
              <CheckoutPage
                goBackHandler={() => setCheckoutStep(CheckoutStep.PREVIEW)}
                orderCreationSuccessHandler={handleOnOrderCreationSuccessHandler}
                cookieDomain={COOKIE_DOMAIN}
                cartReference={cartReference}
                onCheckoutLoaded={handleOnLoaded}
              />
            ),
            CONFIRMATION: (
              <OrderConfirmation
                orderNumber={orderConfirmationInfo.orderNumber}
                orderType={orderConfirmationInfo.orderType}
              >
                <div css={backToShopStyle}>
                  <AppLink
                    app="shop"
                    to="/"
                  >
                    <Button type={ButtonType.SECONDARY}>{button_back_to_shop}</Button>
                  </AppLink>
                </div>
              </OrderConfirmation>
            ),
          }[checkoutStep]
        }
      </div>
      {checkoutStep !== CheckoutStep.CONFIRMATION && <GoToTop />}
    </Fragment>
  );
};

export default CheckoutFlowLayout;
