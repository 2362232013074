import { css } from '@emotion/react';
import { StandardLonghandProperties } from 'csstype';
import React from 'react';

import { baseSpacing, media } from '../theme';

export const tableStyle = (showTable: boolean, width: StandardLonghandProperties['width']) =>
  css({
    width,
    display: showTable ? 'table' : 'none',
    paddingLeft: 0,
    paddingRight: 0,
    borderCollapse: 'collapse',
    [media.small]: {
      paddingLeft: baseSpacing * 4,
      paddingRight: baseSpacing * 4,
    },
    [media.medium]: {
      padding: 0,
      display: 'table',
    },
  });

type TableProps = {
  showTable?: boolean;
  width?: StandardLonghandProperties['width'];
  children: React.ReactNode;
};

export const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ showTable = true, children, width = '100%' }, ref) => {
    return (
      <table
        css={tableStyle(showTable, width)}
        aria-label="table"
        ref={ref}
      >
        {children}
      </table>
    );
  }
);
Table.displayName = 'Table';
