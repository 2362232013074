export const mapLocaleForUsersnap = (locale?: string) => {
  if (!locale) {
    return;
  } else if (locale === 'PT-BR' || locale === 'ZH-TW') {
    const arr = locale.split('-');
    return `${arr[0].toLowerCase()}-${arr[1]}`;
  } else {
    return locale.split('-')[0].toLowerCase();
  }
};
