import { gql } from '@apollo/client';

export const PROMOTIONS = gql`
    query promotions($customerId: Int!) {
        promotions(customerId: $customerId) {
            availableForOrderPromotions {
                ...promotionOverviewItem
            }
            noLongerAvailableForOrderPromotions {
                ...promotionOverviewItem
            }
        }
    }
`;
