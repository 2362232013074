import { css } from '@emotion/react';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import { useRef, useState } from 'react';

import { optionsContainerStyle, optionStyle } from '../../styles/selectoptions';

interface ISelectBoxValues<T> {
  /** The visible text in the select option. */
  displayText: string;
  /** The value that gets returned to the 'optionsSelectHandler' function. */
  value: T;
}

interface ISelectBoxProps<T> {
  /** All visible values for the select box. */
  values: ISelectBoxValues<T>[];
  /** The values that will show as selected. */
  selectedValues?: T | T[];
  /** Function that will receive the selected value(s) when they change. */
  optionsChangeHandler: (values: T | T[]) => void;
  /**
   * Text to show in select box.
   * (If multi is false and an option is selected, the option will be shown.)
   * */
  label: string;
  /** Allow selection of multiple values. Default is false. */
  allowMultiple?: boolean;
}

const selectContainerStyle = css({
  position: 'relative',
  outline: 'none',
  cursor: 'pointer',
  '&:focus > div': {
    borderColor: colors.klik.lightBlue400,
  },
  '> svg': {
    fill: colors.klik.slate700,
    width: 20,
    height: 20,
    position: 'absolute',
    top: baseSpacing * 1.5,
    right: baseSpacing * 1.5,
    transform: 'rotate(180deg)',
  },
});

const selectBoxStyle = css({
  boxShadow: 'none',
  borderWidth: 1,
  borderRadius: 4,
  borderStyle: 'solid',
  borderColor: colors.klik.slate200,
  backgroundColor: colors.white,
  fontSize: 14,
  height: 40,
  lineHeight: '38px',
  paddingLeft: baseSpacing,
  boxSizing: 'border-box',
});

const selectOptionStyle = css(optionStyle, {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  '> svg': {
    fill: colors.klik.lightBlue400,
    width: 16,
  },
});

const selectBoxOptionsContainerStyle = (show: boolean) =>
  css(optionsContainerStyle, {
    /**
     * When opening the selector with keyboard navigation (e.g. arrow down), we want to move
     * focus to the first child element (just like a "normal" <select> element).
     * Javascript won't let us do that if the child elements are hidden.
     * Hence we hide them with 'opacity' rather than 'display' or 'visibility'.
     */
    opacity: show ? 1 : 0,
    pointerEvents: show ? 'all' : 'none',
  });

function SelectBox<T>({
  values,
  selectedValues,
  optionsChangeHandler,
  label,
  allowMultiple = false,
}: ISelectBoxProps<T>) {
  const [isOpen, setIsOpen] = useState(false);

  const firstOptionRef = useRef<HTMLDivElement>(null);

  const containerKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KEYCODE_STRING.ESCAPE) {
      setIsOpen(false);
    }
    if ((e.key === KEYCODE_STRING.ENTER || e.key === KEYCODE_STRING.ARROW_DOWN) && !isOpen) {
      setIsOpen(true);
      firstOptionRef.current?.focus();
      e.preventDefault();
    }
  };

  const optionKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>, value: T) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      optionSelectHandler(value);
    }
    const currentActive = document.activeElement;
    if (e.key === KEYCODE_STRING.ARROW_DOWN) {
      (currentActive?.nextElementSibling as HTMLDivElement)?.focus();
      e.preventDefault();
    }
    if (e.key === KEYCODE_STRING.ARROW_UP) {
      (currentActive?.previousElementSibling as HTMLDivElement)?.focus();
      e.preventDefault();
    }
  };

  const containerMouseUpHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    /** Toggles open state.
     *  Will only re-close when clicking container/header and not the options.  */
    if ((e.target as HTMLDivElement).parentNode === e.currentTarget) {
      setIsOpen(!isOpen);
    }
  };

  const containerBlurHandler = (e: React.FocusEvent<HTMLDivElement>) => {
    /**
     * When container loses focus, e.g. by clicking outside, close the dropdown.
     * UNLESS it loses focus to one of its children. */
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  const optionSelectHandler = (value: T) => {
    if (allowMultiple && Array.isArray(selectedValues)) {
      const updatedValues = [...selectedValues];
      if (updatedValues.includes(value)) {
        updatedValues.splice(updatedValues.indexOf(value), 1);
      } else {
        updatedValues.push(value);
      }
      optionsChangeHandler(updatedValues);
    } else {
      optionsChangeHandler(value);
      setIsOpen(false);
    }
  };

  const isOptionSelected = (option: T) => {
    if (Array.isArray(selectedValues)) {
      return selectedValues.includes(option);
    } else {
      return option === selectedValues;
    }
  };

  const outputLabel = () => {
    if (!allowMultiple && selectedValues && !isOpen) {
      return values.find((options) => options.value === selectedValues)?.displayText;
    } else {
      return label;
    }
  };

  return (
    <div
      role="listbox"
      tabIndex={0}
      onMouseUp={containerMouseUpHandler}
      onBlur={containerBlurHandler}
      onKeyDown={containerKeyDownHandler}
      css={selectContainerStyle}
    >
      <div css={selectBoxStyle}>{outputLabel()}</div>
      <div css={selectBoxOptionsContainerStyle(isOpen)}>
        {values.map((option, idx) => {
          return (
            <div
              ref={idx === 0 ? firstOptionRef : null}
              css={selectOptionStyle}
              onClick={() => {
                optionSelectHandler(option.value);
              }}
              key={option.displayText}
              role="option"
              tabIndex={-1}
              aria-selected={isOptionSelected(option.value)}
              onKeyDown={(e) => {
                optionKeyDownHandler(e, option.value);
              }}
            >
              {option.displayText}
              {isOptionSelected(option.value) && <Icon type={IconType.CHECK} />}
            </div>
          );
        })}
      </div>
      <Icon type={IconType.CHEVRON_UP} />
    </div>
  );
}

export default SelectBox;
