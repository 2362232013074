import { css } from '@emotion/react';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';

import { baseSpacing, colors, designToken, font } from '../theme';

const labelStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: font.size.small,
  '> span': {
    marginLeft: baseSpacing / 2,
  },
});

const inputStyle = css({
  appearance: 'unset',
  width: 32,
  height: 18,
  backgroundColor: colors.klik.slate300,
  borderRadius: baseSpacing * 2,
  paddingRight: baseSpacing,
  paddingLeft: baseSpacing,
  marginRight: baseSpacing / 2,
  position: 'relative',
  transitionDuration: '125ms',
  '&:checked': {
    '&:before': {
      left: 17,
    },
  },
  '&:before': {
    content: '""',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: colors.white,
    position: 'absolute',
    top: 3,
    left: 3,
    transitionDuration: '125ms',
  },
});

const blueInputStyle = css(inputStyle, {
  '&:checked': {
    backgroundColor: designToken.secondary.default,
  },
});

const orangeInputStyle = css(inputStyle, {
  '&:checked': {
    backgroundColor: designToken.warning.default,
  },
});

const greenInputStyle = css(inputStyle, {
  '&:checked': {
    backgroundColor: designToken.success.default,
  },
});

interface ISwitchProps {
  onToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isOn: boolean;
  label?: string;
  type?: 'blue' | 'orange' | 'green';
}

export const Switch = ({ onToggle, isOn, label, type = 'blue' }: ISwitchProps) => {
  const [id] = useState(() => nanoid());
  return (
    <label
      css={labelStyle}
      htmlFor={id}
    >
      <input
        type={'checkbox'}
        checked={isOn}
        id={id}
        onChange={onToggle}
        css={{ blue: blueInputStyle, orange: orangeInputStyle, green: greenInputStyle }[type]}
      />
      {label && <span>{label}</span>}
    </label>
  );
};
