import { css } from '@emotion/react';
import React, { useRef } from 'react';

import { KEYCODE_STRING } from '../../constants';
import { colors, designToken } from '../theme';

const checkboxContainerStyle = css({
  display: 'flex',
});

const iconStyle = css({
  fill: 'none',
  stroke: 'white',
  strokeWidth: 2,
});

const checkboxStyle = (checked: boolean, disabled: boolean) =>
  css({
    display: 'inline-block',
    width: 16,
    height: 16,
    background: disabled ? colors.klik.slate100 : checked ? colors.klik.lightBlue400 : colors.white,
    border: checked ? 'none' : `1px solid ${colors.klik.slate400}`,
    borderRadius: 3,
    transition: 'all 150ms',
    svg: {
      visibility: checked ? 'visible' : 'hidden',
    },
  });

const hiddenCheckboxStyle = (disabled: boolean) =>
  css({
    border: 0,
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: 1,
    '&:focus + div': {
      boxShadow: disabled ? 'none' : `0 0 0 3px ${colors.klik.slate100}`,
    },
    '&:hover + div': {
      boxShadow: disabled ? 'none' : `0 0 0 3px ${colors.klik.slate100}`,
    },
    '&:focus': {
      backgroundColor: disabled ? 'none' : designToken.interactive.active,
    },
    '&:hover': {
      backgroundColor: disabled ? 'none' : designToken.interactive.hover,
    },
  });

interface ICheckboxProps {
  className?: string;
  checked: boolean;
  id?: string;
  name?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({
  className,
  checked,
  disabled = false,
  id,
  name,
  onChange,
}: ICheckboxProps) => {
  const checkboxInputRef = useRef<HTMLInputElement>(null);
  const checkboxRef = useRef<HTMLDivElement>(null);
  const checkboxSvgRef = useRef<SVGSVGElement>(null);
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      onChange && onChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.target === checkboxRef.current || e.target === checkboxSvgRef.current) {
      e.preventDefault();
      checkboxInputRef.current?.click();
    }
  };

  return (
    <div
      css={checkboxContainerStyle}
      className={className}
      onClick={handleOnClick}
    >
      <input
        css={hiddenCheckboxStyle(disabled)}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDownHandler}
        ref={checkboxInputRef}
      />
      <div
        css={checkboxStyle(checked, disabled)}
        ref={checkboxRef}
      >
        <svg
          css={iconStyle}
          viewBox="0 0 24 24"
          ref={checkboxSvgRef}
        >
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </div>
    </div>
  );
};
