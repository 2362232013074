import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Money, Price as PriceType, StockStatusTag } from '@lego/b2b-unicorn-data-access-layer';
import { StockStatus } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { Fragment, useEffect, useRef, useState } from 'react';

import { bigTextStyle, rowStyle, smallTextStyle } from '../../../styles';
import AddButton from '../AddButton';
import ProductTileCounter from './ProductTileCounter';

interface IProductTileBottom {
  stockStatus?: StockStatus | null;
  price?: Money | PriceType | null;
  piecesPerCasePack: number;
  locale: string;
  withInfo?: boolean;
  onAddToCart: (quantity: number) => void;
  buttonType?: ButtonType.PRIMARY | ButtonType.SECONDARY;
}

const ProductTileBottom = ({
  locale,
  withInfo = true,
  stockStatus,
  price,
  piecesPerCasePack,
  onAddToCart,
  buttonType,
}: IProductTileBottom) => {
  const { total_price, pcs } = useLabels();
  const [quantity, setQuantity] = useState(1);
  const animationTimeout = useRef<ReturnType<typeof setTimeout>>();

  const shouldBeDisabled =
    stockStatus?.status === StockStatusTag.OutOfStock ||
    stockStatus?.status === StockStatusTag.NotAvailable;

  useEffect(() => () => clearTimeout(animationTimeout.current), []);

  const handleAddToCart = () => {
    onAddToCart(quantity);
    setQuantity(1);
  };

  return (
    <Fragment>
      {withInfo && <p css={smallTextStyle}>{total_price}:</p>}
      <p css={bigTextStyle}>
        <Price
          locale={locale}
          multiplier={piecesPerCasePack * quantity}
          wrappers={{
            nipPrice: ({ children }) => <span css={{ lineHeight: 1.2 }}>{children}</span>,
          }}
        >
          {price}
        </Price>{' '}
        / {piecesPerCasePack * quantity}
        {' ' + pcs}
      </p>

      <section css={rowStyle}>
        <ProductTileCounter
          disabled={shouldBeDisabled}
          quantity={quantity}
          setQuantity={setQuantity}
        />
        <AddButton
          disabled={shouldBeDisabled}
          handleOnClick={handleAddToCart}
          buttonType={buttonType}
        />
      </section>
    </Fragment>
  );
};

export default ProductTileBottom;
