import type { SerializedStyles } from '@emotion/react';
import { forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';

interface IAppLink extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  app: string;
  redirectTriggerFlag?: boolean;
  children?: React.ReactNode;
  css?: SerializedStyles;
  onClick?: () => void;
}

interface ILocalhostHostAppsPorts {
  [key: string]: number;
}

const localhostAppsPorts: ILocalhostHostAppsPorts = {
  login: 4000,
  shop: 4001,
  manage: 4002,
  info: 4003,
  launch: 4004,
};

const AppLink = forwardRef<HTMLAnchorElement, IAppLink>(
  ({ to: goTo, app: targetApp, redirectTriggerFlag = false, children, onClick, ...props }, ref) => {
    const currentApp = window.location.host.split('.')[0];
    const isLocalhost = window.location.hostname === 'localhost';
    const featureBranchNumber = currentApp.split('-')[1];
    const origin = window.location.origin;
    const port = window.location.port;

    const isSameApp =
      currentApp.search(
        isLocalhost
          ? localhostAppsPorts[targetApp].toString()
          : featureBranchNumber
            ? [targetApp, featureBranchNumber].join('-')
            : targetApp
      ) >= 0;

    const newOrigin =
      isLocalhost && port
        ? origin.replace(port, localhostAppsPorts[targetApp].toString())
        : origin.replace(currentApp, targetApp);

    const cssWithInherit = [
      {
        color: 'inherit',
        textDecoration: 'inherit',
      },
      props?.css,
    ];

    const onClickAndScroll = () => {
      if (onClick) {
        onClick();
      }
      scrollTo(0, 0);
    };

    const withinAppNavigation = redirectTriggerFlag ? (
      <Redirect to={goTo} />
    ) : (
      <Link
        to={goTo}
        onClick={onClickAndScroll}
        css={cssWithInherit}
        {...props}
        ref={ref}
      >
        {children}
      </Link>
    );

    if (isSameApp) {
      return withinAppNavigation;
    }

    if (redirectTriggerFlag) {
      location.assign(newOrigin + goTo);
      return null;
    }

    return (
      <a
        href={newOrigin + goTo}
        css={cssWithInherit}
        ref={ref}
        {...props}
      >
        {children}
      </a>
    );
  }
);
AppLink.displayName = 'AppLink';

export default AppLink;
