import { wrap } from 'comlink';

import { md5ChecksumAsBase64Worker } from './md5ChecksumAsBase64.worker';

export const md5ChecksumAsBase64 = async (file: File): Promise<string> => {
  const worker = new Worker(new URL('./md5ChecksumAsBase64.worker.ts', import.meta.url), {
    type: 'module',
  });
  const checksumWorker = wrap<typeof md5ChecksumAsBase64Worker>(worker);

  const checksum = await checksumWorker.checksum(file);

  worker.terminate();

  return checksum;
};
