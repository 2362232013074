import { deepEqual } from 'fast-equals';
import React from 'react';
import type { CellBase } from 'react-spreadsheet';

interface ITotalPiecesValue {
  casePackSize: number;
  quantity?: number;
}

type TotalPiecesCellProps = {
  cell?: CellBase<ITotalPiecesValue | ''>;
};

export const TotalPiecesCell: React.FC<TotalPiecesCellProps> = React.memo(
  ({ cell }) => {
    if (!cell || !cell.value) {
      return null;
    }

    if (!cell.value.quantity || cell.value.quantity < 1) {
      return '-';
    }

    return <>{cell.value.casePackSize * cell.value.quantity}</>;
  },
  (prevProps, nextProps) => deepEqual(prevProps.cell, nextProps.cell)
);
TotalPiecesCell.displayName = 'TotalPiecesCell';
