import { useMemo } from 'react';

import { useDataAccessLayer } from '../';
import { useDataAccessLayerMutation } from '../helpers/useDataAccessLayerMutation';

export const useCreateClaim = (customerId: number) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayer = useMemo(() => {
    return dataAccessLayer.claims.createClaim(customerId);
  }, [customerId, dataAccessLayer.claims]);

  return useDataAccessLayerMutation(rawDataAccessLayer);
};

export const useCreateClaimFileResource = (customerId: number) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayer = useMemo(() => {
    return dataAccessLayer.claims.createClaimFileResource(customerId);
  }, [customerId, dataAccessLayer.claims]);

  return useDataAccessLayerMutation(rawDataAccessLayer);
};
