import type { AgentConfigOptions, Transaction } from '@elastic/apm-rum';
import { ApmBase, apmBase } from '@elastic/apm-rum';

declare global {
  interface Window {
    apmAgent: ApmBase | undefined;
  }
}

// Simple transaction type, missing quite a few properties, but contains what was needed to do filtering
export type FilterSimpleTransaction = {
  name: string;
  outcome: 'success' | 'failure';
  context?: {
    page?: {
      url: string;
    };
  };
};

export class ApmService {
  private activeTransactions = new Map<string, Transaction>();
  private currentTransaction: Transaction | undefined;
  private static _instance: ApmService;
  private apmAgent: ApmBase;

  public constructor(apmAgent: ApmBase) {
    this.apmAgent = apmAgent;
  }

  public static initialize(
    config: AgentConfigOptions,
    labels?: Parameters<typeof ApmBase.prototype.addLabels>[0]
  ) {
    if (window.apmAgent && window.apmAgent.isActive()) {
      throw Error(`There is already an APM agent `);
    }
    window.apmAgent = apmBase.init(config);
    labels && window.apmAgent.addLabels(labels);

    window.apmAgent.setInitialPageLoadName(
      `Initial page load - ${window.location.pathname}${window.location.search}`
    );

    ApmService._instance = new ApmService(window.apmAgent);
  }

  public static get instance() {
    if (!window.apmAgent || !window.apmAgent.isActive() || !ApmService._instance) {
      // eslint-disable-next-line no-console
      console.warn(`APM Agent is not initialized, run 'ApmService.initialize()'`);

      return;
    }

    return ApmService._instance;
  }

  public addFilter(fn: Parameters<typeof ApmBase.prototype.addFilter>[0]) {
    return this.apmAgent.addFilter(fn);
  }

  public getTransaction(name: string) {
    const transaction = this.activeTransactions.get(name);
    if (transaction && !transaction.isFinished()) {
      return transaction;
    }

    return undefined;
  }

  public getCurrentTransaction() {
    return this.currentTransaction;
  }

  public startTransaction(name: string, type: 'route-change' | 'page-load' | 'app') {
    if (this.activeTransactions.has(name)) {
      const transaction = this.activeTransactions.get(name);
      if (transaction) {
        if (!transaction.isFinished()) {
          return transaction;
        } else {
          this.activeTransactions.delete(name);
        }
      }
    }

    const transaction = this.apmAgent.startTransaction(name, type, {
      managed: true,
    });
    if (!transaction) {
      // eslint-disable-next-line no-console
      console.warn(`Transaction ${name} could not be started`);
      return;
    }

    this.activeTransactions.set(name, transaction);
    this.currentTransaction = transaction;

    return transaction;
  }

  public endTransaction(name: string) {
    const transaction = this.activeTransactions.get(name);
    if (transaction) {
      transaction.end();
      this.currentTransaction = undefined;
      this.activeTransactions.delete(name);
    }
  }

  public captureError(error: Error | string) {
    this.apmAgent.captureError(error);
  }
}
