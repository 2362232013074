import { deepEqual } from 'fast-equals';
import { GraphQLFormattedError } from 'graphql/index';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ExtraOptions } from '../../context/GenericContextTypes';
import { ReplenishmentCartProduct } from '../../context/Replenishment/ReplenishmentDataContext';
import { ProductListFilterInput } from '../../generated-types/types';
import { useDataAccessLayer } from '../DataAccessLayerProvider';
import { useDataAccessLayerMutation } from '../helpers/useDataAccessLayerMutation';
import { useDataAccessLayerQuery } from '../helpers/useDataAccessLayerQuery';

export const useReplenishmentCartSummary = (customerId: number, extraOptions?: ExtraOptions) => {
  const extraOptionsRef = useRef(extraOptions);
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.replenishment.replenishmentCartSummary(
      customerId,
      extraOptionsRef.current
    );
  }, [customerId, dataAccessLayer.replenishment]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};

export const useReplenishmentCartRefetch = (customerId: number, extraOptions?: ExtraOptions) => {
  const extraOptionsRef = useRef(extraOptions);
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.replenishment.replenishmentCartSummary(
      customerId,
      extraOptionsRef.current
    );
  }, [customerId, dataAccessLayer.replenishment]);

  return rawDataAccessLayerQuery[0];
};

export const useOptimisticUpdateProductQuantityReplenishmentCart = (
  customerId: number,
  product: ReplenishmentCartProduct
) => {
  const dataAccessLayer = useDataAccessLayer();
  const [stableProduct, setStableProduct] = useState(product);
  useEffect(() => {
    if (!deepEqual(product, stableProduct)) {
      setStableProduct(product);
    }
  }, [product, stableProduct]);

  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.replenishment.updateProductQuantityReplenishmentCart(
      customerId,
      stableProduct,
      false,
      true
    );
  }, [customerId, dataAccessLayer.replenishment, stableProduct]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};

export const useOptimisticUpdateReplenishmentCart = (customerId: number) => {
  const dataAccessLayer = useDataAccessLayer();

  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.replenishment.updateReplenishmentCart(customerId, false, true);
  }, [customerId, dataAccessLayer.replenishment]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};

export const useMaterialIdInCart = (
  customerId: number,
  materialId: number,
  extraOptions?: ExtraOptions
) => {
  const dataAccessLayer = useDataAccessLayer();
  const [stableExtraOptions, setStableExtraOptions] = useState(extraOptions);
  useEffect(() => {
    if (!deepEqual(extraOptions, stableExtraOptions)) {
      setStableExtraOptions(extraOptions);
    }
  }, [extraOptions, stableExtraOptions]);

  const [foundInCart, setFoundInCart] = useState<{ found: boolean; quantity: number }>({
    found: false,
    quantity: 0,
  });

  useEffect(() => {
    const subscription = dataAccessLayer.replenishment
      .materialIdInCart(customerId, materialId, stableExtraOptions)
      .subscribe((inCart) => {
        setFoundInCart(inCart);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [customerId, dataAccessLayer.replenishment, stableExtraOptions, materialId]);

  return foundInCart;
};

export const useIsReplenishmentCartEmpty = (customerId: number, extraOptions?: ExtraOptions) => {
  const dataAccessLayer = useDataAccessLayer();
  const [isCartEmpty, setIsCartEmpty] = useState<boolean>(true);

  useEffect(() => {
    const subscription = dataAccessLayer.replenishment
      .isCartEmpty(customerId, extraOptions)
      .subscribe((isEmpty) => {
        setIsCartEmpty(isEmpty);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [customerId, dataAccessLayer.replenishment, extraOptions]);

  return isCartEmpty;
};

export const useReplenishmentUpdateCartWithMultipleItems = (
  customerId: number,
  optimistic?: boolean,
  onError?: (
    error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
  ) => void
) => {
  const dataAccessLayer = useDataAccessLayer();

  const rawDataAccessLayerMutation = useMemo(() => {
    return dataAccessLayer.replenishment.updateCartWithMultipleItems(
      customerId,
      optimistic,
      onError
    );
  }, [customerId, dataAccessLayer.replenishment, onError, optimistic]);

  return useDataAccessLayerMutation(rawDataAccessLayerMutation);
};

export const useReplenishmentProducts = (
  customerId: number,
  filters?: ProductListFilterInput,
  noInitialTrigger?: boolean,
  opts?: ExtraOptions
) => {
  const initialFilters = useRef(filters);
  const extraOptions = useRef(opts);

  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.replenishment.products(
      customerId,
      initialFilters.current,
      noInitialTrigger,
      extraOptions.current
    );
  }, [customerId, dataAccessLayer.replenishment, noInitialTrigger]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};

export const useReplenishmentProduct = (
  customerId: number,
  materialId: number,
  noInitialTrigger?: boolean
) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.replenishment.product(customerId, materialId, noInitialTrigger);
  }, [customerId, dataAccessLayer.replenishment, materialId, noInitialTrigger]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};
