/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { DefaultContext, OperationVariables } from '@apollo/client';
import { ApolloClient, ApolloError, NetworkStatus, ObservableQuery } from '@apollo/client';
import type { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { TriggerAbort } from '@lego/b2b-unicorn-data-access-layer/context/TriggerAbort';
import { deepEqual } from 'fast-equals';
import { GraphQLError, GraphQLFormattedError } from 'graphql/index';
import { DocumentNode } from 'graphql/language/ast';
import { hash } from 'ohash';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  ReplaySubject,
  shareReplay,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import type { Subscription as ZenSubscription } from 'zen-observable-ts';

import { PaymentApolloError } from '..';
import { CartPolicy } from '../type-policies/CartPolicy';
import { ProductPolicy } from '../type-policies/ProductPolicy';
import { CommonApolloError } from './GenericContextTypes';

export type ErrorType =
  | Error
  | ApolloError
  | CommonApolloError
  | GraphQLError
  | GraphQLFormattedError
  | PaymentApolloError;
export type ContextError = ErrorType | readonly ErrorType[] | null;
export type PayloadArguments<T> = T extends (...args: infer U) => any ? U : void;

type SubjectsSet<T = object, V = PayloadArguments<unknown>, TMapped = T> = {
  status: BehaviorSubject<NetworkStatus>;
  error: BehaviorSubject<ContextError>;
  result: BehaviorSubject<T | null | undefined>;
  initialTrigger: ReplaySubject<void>;
  trigger: Subject<V>;
  triggerMore: Subject<V>;
  queryResponse: ReplaySubject<ObservableResult<T | TMapped>>;
};

export type ObservableResult<T = object> = {
  initialLoading: boolean;
  loading: boolean;
  status: NetworkStatus;
  error: ContextError;
  data: T | null | undefined;
};

export type QueryObservable<T extends object, V = PayloadArguments<unknown>> = [
  (payload: V) => Promise<ObservableResult<T>>, // refetch/trigger
  (payload: V) => Promise<ObservableResult<T>>, // fetchMore
  ReplaySubject<ObservableResult<T>>,
];

export type MutationObservable<T extends object, V = PayloadArguments<unknown>> = [
  (payload: V) => Promise<ObservableResult<T>>, // trigger
  () => void, // reset
  Observable<ObservableResult<T>>,
];

type ContextOpts = {
  cartPolicy?: boolean;
  productPolicy?: boolean;
  debounceTimeout?: number;
};

export type QueryObservableOptions<TQuery = object, TMappedQuery = TQuery> = {
  queryMapper?: (data: TQuery) => TMappedQuery;
  fetchPolicy?: WatchQueryFetchPolicy;
  noInitialTrigger?: boolean;
};

export abstract class ContextAbstract {
  private _subjectSets: Map<string, SubjectsSet<any, any>> = new Map();
  private _triggerObservable: Map<string, Observable<any>> = new Map();
  private _triggerMoreObservable: Map<string, Observable<any>> = new Map();
  private _mergedObservable: Map<string, Observable<any>> = new Map();
  private _apolloWatchSubject: Map<string, Subject<any>> = new Map();
  private _apolloWatchQuery: Map<string, ObservableQuery<any, any>> = new Map();
  private _apolloWatchSubscriptions: Map<string, ZenSubscription> = new Map();
  private _mutationPromises: Map<string, Promise<any>> = new Map();
  private _innerSubscriptions: Map<string, Subscription> = new Map();
  private _fetchPromises: Map<string, Promise<any>> = new Map();
  private _fetchAbortControllers: Map<string, AbortController> = new Map();
  private _queryVariables: Map<string, OperationVariables> = new Map();

  private _mutationAbortControllers: Map<string, AbortController> = new Map();
  protected activeMutationAbortControllers: BehaviorSubject<number> = new BehaviorSubject(
    this._mutationAbortControllers.size
  );

  protected _apolloClient: ApolloClient<unknown>;
  public cartPolicy?: CartPolicy;
  public productPolicy?: ProductPolicy;

  constructor(apolloClient: ApolloClient<unknown>, opts?: ContextOpts) {
    this._apolloClient = apolloClient;

    if (opts?.cartPolicy) {
      this.cartPolicy = new CartPolicy(this._apolloClient);
    }
    if (opts?.productPolicy) {
      this.productPolicy = new ProductPolicy(this._apolloClient);
    }
  }

  protected subjectsSet<T extends object, V = PayloadArguments<unknown>, TMapped extends T = T>(
    key: string
  ) {
    if (!this._subjectSets.has(key)) {
      const subjects: SubjectsSet<T, V> = {
        status: new BehaviorSubject<NetworkStatus>(NetworkStatus.ready),
        error: new BehaviorSubject<ContextError>(null),
        result: new BehaviorSubject<T | null | undefined>(null),
        initialTrigger: new ReplaySubject<void>(1),
        trigger: new Subject<V>(),
        triggerMore: new Subject<V>(),
        queryResponse: new ReplaySubject<ObservableResult<T | TMapped>>(1),
      };

      this._subjectSets.set(key, subjects);
    }

    return this._subjectSets.get(key) as SubjectsSet<T, V>;
  }

  protected triggerObservable<T extends object, V = PayloadArguments<unknown>>(
    key: string,
    triggerCallback: (payload: V) => Promise<ObservableResult<T>>
  ) {
    if (!this._triggerObservable.has(key)) {
      const subjects = this.subjectsSet<T, V>(key);
      this._triggerObservable.set(
        key,
        subjects.trigger.pipe(
          tap((payload: V) => {
            subjects.status.next(NetworkStatus.loading);
            subjects.error.next(null);

            triggerCallback(payload).catch((error) => {
              if (error instanceof TriggerAbort) {
                return;
              }
              throw error;
            });
          })
        )
      );
    }

    return this._triggerObservable.get(key) as Observable<[NetworkStatus, ContextError, T]>;
  }

  private triggerMoreObservable<T extends object, V = PayloadArguments<unknown>>(
    key: string,
    triggerMore: (payload: V) => Promise<ObservableResult<T>>
  ) {
    if (!this._triggerMoreObservable.has(key)) {
      const subjects = this.subjectsSet<T, V>(key);
      this._triggerMoreObservable.set(
        key,
        subjects.triggerMore.pipe(
          tap((payload: V) => {
            subjects.status.next(NetworkStatus.loading);
            subjects.error.next(null);

            triggerMore(payload).catch((error) => {
              if (error instanceof TriggerAbort) {
                return;
              }
              throw error;
            });
          })
        )
      );
    }

    return this._triggerMoreObservable.get(key) as Observable<[NetworkStatus, ContextError, T]>;
  }

  private apolloWatchSubject<TQuery extends object>(key: string) {
    if (!this._apolloWatchSubject.has(key)) {
      this._apolloWatchSubject.set(
        key,
        new Subject<[NetworkStatus, ObservableResult<TQuery>['error'], TQuery]>()
      );
    }

    return this._apolloWatchSubject.get(key)! as Subject<
      [NetworkStatus, ObservableResult<TQuery>['error'], TQuery]
    >;
  }

  private apolloWatchQuery<TQuery extends object, TVariables extends OperationVariables>(
    observableKey: string,
    query: DocumentNode,
    variables: TVariables,
    fetchPolicy?: WatchQueryFetchPolicy,
    initialFetchPolicy?: WatchQueryFetchPolicy
  ) {
    if (!this._apolloWatchQuery.has(observableKey)) {
      this._apolloWatchQuery.set(
        observableKey,
        this._apolloClient.watchQuery<TQuery, TVariables>({
          query,
          variables,
          notifyOnNetworkStatusChange: true,
          initialFetchPolicy,
          fetchPolicy,
        })
      );
    }

    return this._apolloWatchQuery.get(observableKey)! as ObservableQuery<TQuery, TVariables>;
  }

  private subscribeToApolloWatchQuery<TQuery extends object>(observableKey: string) {
    const apolloWatchSubject = this.apolloWatchSubject<TQuery>(observableKey);

    if (!this._apolloWatchSubscriptions.has(observableKey)) {
      const subscription = this._apolloWatchQuery.get(observableKey)!.subscribe(
        (result) => {
          apolloWatchSubject.next([result.networkStatus, result.error || null, result.data]);
        },
        (error) => {
          apolloWatchSubject.next([NetworkStatus.error, error, null as unknown as TQuery]);
        }
      );

      const currentResult = this._apolloWatchQuery.get(observableKey)!.getCurrentResult();
      apolloWatchSubject.next([
        currentResult.networkStatus,
        currentResult.error || null,
        currentResult.data,
      ]);
      this._apolloWatchSubscriptions.set(observableKey, subscription);
    }
  }

  private dataParser<TQuery extends object, TMappedQuery extends object = TQuery>(
    data: TQuery | null | undefined,
    mapper?: (data: TQuery) => TMappedQuery
  ) {
    if ((data && !Array.isArray(data) && Object.keys(data).length === 0) || data === undefined) {
      return undefined;
    }

    if (data === null) {
      return null;
    }

    return mapper ? mapper(data) : data;
  }

  protected queryObservable<
    TQuery extends object,
    TVariables extends OperationVariables = OperationVariables,
    TFetchPayload = PayloadArguments<unknown>,
    TMappedQuery extends object = TQuery,
  >(
    observableKey: string,
    query: DocumentNode,
    variables: TVariables,
    opts?: QueryObservableOptions<TQuery, TMappedQuery>
  ) {
    const subjects = this.subjectsSet<TQuery, TFetchPayload>(observableKey);
    const fetchSubject = subjects.trigger;
    const fetchMoreSubject = subjects.triggerMore;

    const apolloWatchQuery = this.apolloWatchQuery<TQuery, TVariables>(
      observableKey,
      query,
      variables,
      opts?.fetchPolicy,
      opts?.noInitialTrigger ? 'standby' : undefined
    );

    if (!this._mergedObservable.has(observableKey)) {
      const apolloWatchSubject = this.apolloWatchSubject<TQuery>(observableKey);
      const triggerObservable = this.triggerObservable<TQuery | TMappedQuery, TVariables>(
        observableKey,
        (variables) => {
          const controller = this._fetchAbortControllers.get(observableKey);
          if (controller && !controller.signal.aborted) {
            controller.abort(`${observableKey} cancelled by new trigger`);
          }

          if (!opts?.noInitialTrigger) {
            this.subscribeToApolloWatchQuery(observableKey);
          }

          this._queryVariables.set(observableKey, variables);

          const promise = new Promise<ObservableResult<TQuery | TMappedQuery>>(
            (resolve, reject) => {
              const abortController = new AbortController();
              this._fetchAbortControllers.set(observableKey, abortController);

              const abortListener = ({ target }: any) => {
                abortController.signal.removeEventListener('abort', abortListener);
                reject(new TriggerAbort(target.reason));
              };
              abortController.signal.addEventListener('abort', abortListener);

              apolloWatchQuery
                .refetch(variables)
                .then((result) => {
                  const apolloWatchSubject = this.apolloWatchSubject<TQuery>(observableKey);
                  apolloWatchSubject.next([
                    result.networkStatus || NetworkStatus.ready,
                    result.error || result.errors || null,
                    result.data,
                  ]);

                  resolve({
                    initialLoading: false,
                    loading: false,
                    status: result.networkStatus || NetworkStatus.ready,
                    error: result.error || result.errors || null,
                    data: this.dataParser(result.data, opts?.queryMapper),
                  });
                })
                .catch((error) => {
                  if (error.toString().indexOf('cancelled by new trigger') > -1) {
                    return;
                  }

                  apolloWatchSubject.next([NetworkStatus.error, error, null as unknown as TQuery]);

                  subjects.status.next(NetworkStatus.error);
                  subjects.error.next(error);
                  reject(error);
                })
                .finally(() => {
                  this._fetchPromises.delete(observableKey);
                  this._fetchAbortControllers.delete(observableKey);
                });

              this.subscribeToApolloWatchQuery(observableKey);
            }
          );

          this._fetchPromises.set(observableKey, promise);

          return promise;
        }
      );

      const triggerMoreObservable = this.triggerMoreObservable<TQuery | TMappedQuery, TVariables>(
        observableKey,
        (variables) => {
          const controller = this._fetchAbortControllers.get(observableKey);
          if (controller && !controller.signal.aborted) {
            controller.abort(`${observableKey} fetchMore triggered`);
          }

          const promise = new Promise<ObservableResult<TQuery | TMappedQuery>>(
            (resolve, reject) => {
              const abortController = new AbortController();
              this._fetchAbortControllers.set(observableKey, abortController);

              const abortListener = ({ target }: any) => {
                abortController.signal.removeEventListener('abort', abortListener);
                reject(new TriggerAbort(target.reason));
              };
              abortController.signal.addEventListener('abort', abortListener);

              apolloWatchQuery
                .fetchMore({ variables })
                .then((result) => {
                  const apolloWatchSubject = this.apolloWatchSubject<TQuery>(observableKey);
                  apolloWatchSubject.next([
                    result.networkStatus || NetworkStatus.ready,
                    result.error || result.errors || null,
                    result.data,
                  ]);

                  resolve({
                    initialLoading: false,
                    loading: false,
                    status: result.networkStatus || NetworkStatus.ready,
                    error: result.error || result.errors || null,
                    data: this.dataParser(result.data, opts?.queryMapper),
                  });
                })
                .catch((error) => {
                  apolloWatchSubject.next([NetworkStatus.error, error, null as unknown as TQuery]);

                  subjects.status.next(NetworkStatus.error);
                  subjects.error.next(error);
                  reject(error);
                })
                .finally(() => {
                  this._fetchPromises.delete(observableKey);
                  this._fetchAbortControllers.delete(observableKey);
                });
            }
          );

          this._fetchPromises.set(observableKey, promise);

          return promise;
        }
      );

      const mergedObservable = merge(
        triggerObservable,
        triggerMoreObservable,
        subjects.initialTrigger
      ).pipe(
        switchMap(() => apolloWatchSubject),
        map(([status, error, result]) => {
          const loading =
            status === NetworkStatus.loading ||
            status === NetworkStatus.refetch ||
            status === NetworkStatus.fetchMore ||
            status === NetworkStatus.setVariables;
          const resultObj: ObservableResult<TQuery | TMappedQuery> = {
            initialLoading: !result && loading,
            loading,
            status,
            error,
            data: this.dataParser(result, opts?.queryMapper),
          };

          return resultObj;
        }),
        distinctUntilChanged(deepEqual)
      );

      // Set initial query result if we need to make an initial request
      if (!opts?.noInitialTrigger) {
        subjects.queryResponse.next({
          initialLoading: true,
          loading: true,
          status: NetworkStatus.loading,
          error: null,
          data: null,
        });
      }

      // Inner subscription to avoid memory leaks
      this._innerSubscriptions.set(
        observableKey,
        mergedObservable.subscribe((result) => {
          // @ts-ignore
          return subjects.queryResponse.next(result);
        })
      );

      this._mergedObservable.set(observableKey, mergedObservable);
    }

    const queryObservable: QueryObservable<TMappedQuery, TFetchPayload> = [
      async (payload: TFetchPayload) => {
        fetchSubject.next(payload);

        if (!this._fetchPromises.has(observableKey)) {
          throw Error(`Observable not started!`);
        }
        try {
          return await this._fetchPromises.get(observableKey)!;
        } catch (error) {
          if (error instanceof TriggerAbort) {
            return;
          }

          throw error;
        }
      },
      async (payload: TFetchPayload) => {
        fetchMoreSubject.next(payload);

        if (!this._fetchPromises.has(observableKey)) {
          throw Error(`Observable not started!`);
        }
        try {
          return await this._fetchPromises.get(observableKey)!;
        } catch (error) {
          if (error instanceof TriggerAbort) {
            return;
          }

          throw error;
        }
      },
      // @ts-ignore
      subjects.queryResponse as ReplaySubject<ObservableResult<TMappedQuery>>,
    ];

    if (!opts?.noInitialTrigger) {
      subjects.initialTrigger.next();
      this.subscribeToApolloWatchQuery(observableKey);
    }

    if (
      !opts?.noInitialTrigger &&
      (opts?.fetchPolicy === 'network-only' ||
        opts?.fetchPolicy === 'no-cache' ||
        !deepEqual(variables, this._queryVariables.get(observableKey)))
    ) {
      // @ts-ignore
      subjects.trigger.next(variables);
    }

    return queryObservable;
  }

  /**
   * Mutation Observable factory method
   *
   * @param {string} observableKey - Unique key for the observable
   * @param {DocumentNode} mutation - GraphQL mutation document
   * @param {string} abortableProperty - If set, the observable will be abortable.
   * The abortableProperty is which property of the variables object that will be used to create the abort key, to lookup if a request is already active.
   * @param onError - Callback function to handle errors
   * @protected
   */
  protected mutationObservable<
    TMutation extends object,
    TMutationVariables extends OperationVariables,
  >(
    observableKey: string,
    mutation: DocumentNode,
    abortableProperty?: keyof TMutationVariables,
    onError?: (
      error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
    ) => void
  ) {
    const subjects = this.subjectsSet<TMutation, TMutationVariables>(observableKey);
    const mutationSubject = subjects.trigger;

    if (!this._mergedObservable.has(observableKey)) {
      const observable = this.triggerObservable<TMutation, TMutationVariables>(
        observableKey,
        (variables: TMutationVariables) => {
          const context: DefaultContext = {};
          const abortKey = abortableProperty
            ? `${observableKey}-${hash(variables[abortableProperty])}`
            : null;
          if (abortableProperty && abortKey) {
            // Abort previous calls, if there is any
            if (this._mutationAbortControllers.has(abortKey)) {
              this._mutationAbortControllers.get(abortKey)!.abort();
            }

            // Create new/replace abort controller
            this._mutationAbortControllers.set(abortKey, new AbortController());
            this.activeMutationAbortControllers.next(this._mutationAbortControllers.size);

            // Add signal to context
            Object.assign(context, {
              fetchOptions: {
                signal: this._mutationAbortControllers.get(abortKey)!.signal,
              },
            });
          }

          subjects.status.next(NetworkStatus.loading);

          const promise = new Promise<ObservableResult<TMutation>>((resolve, reject) => {
            this._apolloClient
              .mutate<TMutation, TMutationVariables>({
                mutation,
                variables,
                context,
              })
              .then((result) => {
                if (result.errors && result.errors.length > 0) {
                  const err = new ApolloError({ graphQLErrors: result.errors });
                  subjects.error.next(err);
                  resolve({
                    initialLoading: false,
                    loading: false,
                    status: NetworkStatus.error,
                    error: err,
                    data: null,
                  });
                }

                if (result.data) {
                  subjects.result.next(result.data);
                  resolve({
                    initialLoading: false,
                    loading: false,
                    status: NetworkStatus.ready,
                    error: null,
                    data: result.data,
                  });
                }

                if (abortKey) {
                  this._mutationAbortControllers.delete(abortKey);
                  this.activeMutationAbortControllers.next(this._mutationAbortControllers.size);
                }

                if (result.errors && result.errors.length > 0) {
                  onError && onError(result.errors);
                  subjects.status.next(NetworkStatus.error);
                } else if (result.data) {
                  subjects.status.next(NetworkStatus.ready);
                }
              })
              .catch((error) => {
                if (
                  error instanceof ApolloError &&
                  error.networkError &&
                  error.networkError instanceof DOMException &&
                  error.networkError.name === 'AbortError'
                ) {
                  return;
                }

                if (abortKey) {
                  this._mutationAbortControllers.delete(abortKey);
                  this.activeMutationAbortControllers.next(this._mutationAbortControllers.size);
                }
                onError && onError(error);
                subjects.status.next(NetworkStatus.error);
                subjects.error.next(error);
                reject(error);
              });
          });
          this._mutationPromises.set(observableKey, promise);

          return promise;
        }
      );

      const mergedObservable = combineLatest([
        subjects.status,
        subjects.error,
        subjects.result,
        observable,
      ]).pipe(
        map(([status, error, result]) => {
          const loading = status === NetworkStatus.loading;
          const resultObj: ObservableResult<TMutation> = {
            initialLoading: !result && loading,
            loading,
            status,
            error,
            data: result,
          };

          return resultObj;
        }),
        distinctUntilChanged(deepEqual),
        shareReplay(1, 1000) // To mitigate multiple calls to the same mutation within 1 second
      );

      // Internal subscription to avoid memory leaks
      this._innerSubscriptions.set(
        observableKey,
        mergedObservable.subscribe((result) => {
          subjects.queryResponse.next(result);
        })
      );

      this._mergedObservable.set(observableKey, mergedObservable);
    }

    const tupleObservable: MutationObservable<TMutation, TMutationVariables> = [
      (payload: TMutationVariables) => {
        mutationSubject.next(payload);
        if (!this._mutationPromises.has(observableKey)) {
          throw Error(`Observable not started!`);
        }

        return this._mutationPromises.get(observableKey)!;
      },
      () => {
        subjects.status.next(NetworkStatus.ready);
        subjects.error.next(null);
        subjects.result.next(null);
      },
      this._mergedObservable.get(observableKey) as Observable<ObservableResult<TMutation>>,
    ];

    return tupleObservable;
  }
}
