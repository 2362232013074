import { css } from '@emotion/react';
import React from 'react';

import { colors } from '../theme';

type BeforePriceProps = {
  children: React.ReactNode;
};

const beforePriceCss = css({
  textDecoration: 'line-through',
  color: colors.klik.slate500,

  '&:only-child': {
    textDecoration: 'none',
  },
});

export const BeforePrice: React.FC<BeforePriceProps> = ({ children }) => {
  return <span css={beforePriceCss}>{children}</span>;
};
