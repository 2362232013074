import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { LinkWithArrow } from '@lego/b2b-unicorn-shared/components/LinkWithArrow';
import { Heading, PageBody, Spacer } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import costumeBanana from '../../../assets/costume-banana.svg';
import { ClaimInlineUsersnapWidget } from './components';

const imageBackgroundStyle = css({
  minHeight: 'calc(100vh - 240px)',
  width: '100%',
  backgroundImage: `url(${costumeBanana})`,
  backgroundPosition: `bottom right ${baseSpacing * 2}px`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 30vh',
  '> *': {
    textAlign: 'center',
  },
  '> h2 button': {
    display: 'inline',
  },
  [media.small]: {
    backgroundPosition: `bottom right ${baseSpacing * 3}px`,
    backgroundSize: 'auto 40vh',
  },
  [media.medium]: {
    backgroundPosition: `center right ${baseSpacing * 3}px`,
  },
  [media.large]: {
    backgroundPosition: `center right`,
  },
});

type CreateClaimRouteParams = {
  invoiceNumber: string;
};
export const CreateClaimConfirmation: React.FC = () => {
  const { claim_confirmation_header, claim_confirmation_text } = useLabels();
  const { invoiceNumber } = useParams<CreateClaimRouteParams>();
  const history = useHistory();
  return (
    <>
      <TopbarImplementation />
      <PageBody>
        <div css={imageBackgroundStyle}>
          <Spacer multiplier={15} />
          <Heading
            level={1}
            noMargin
          >
            {claim_confirmation_header}
          </Heading>
          <Spacer multiplier={3} />
          <Heading
            level={2}
            noMargin
            noPadding
          >
            {claim_confirmation_text}{' '}
            <LinkWithArrow onClick={() => history.push(`/manage/invoice/${invoiceNumber}`)}>
              #{invoiceNumber}
            </LinkWithArrow>
          </Heading>
          <Spacer multiplier={6} />
          <ClaimInlineUsersnapWidget invoiceNumber={parseInt(invoiceNumber, 10)} />
        </div>
      </PageBody>
    </>
  );
};
