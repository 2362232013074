import { css } from '@emotion/react';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import { ProductFilterSelectors, ProductListFilterTags } from './Filters';
import { ProductListContainer } from './ProductListContainer';
import { ProductListSorting } from './ProductListSorting';
import { RareItemsSwitch, RecommendedProductsSwitch } from './Switchers';

const sortingAndSwitchWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  [media.medium]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const switchWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: baseSpacing * 2,
  marginRight: baseSpacing,
  marginBottom: baseSpacing,
  '> label': {
    flexDirection: 'row-reverse',
    '> input': {
      marginLeft: baseSpacing,
    },
  },
  [media.medium]: {
    flexDirection: 'row',
    gap: baseSpacing * 3,
    margin: 0,
    '> label': {
      flexDirection: 'row',
      '> input': {
        marginLeft: 0,
      },
    },
  },
});

export const ProductListFiltersAndSorting: React.FC = () => {
  return (
    <>
      <ProductListContainer>
        <>
          <ProductFilterSelectors />
          <ProductListFilterTags />
        </>
      </ProductListContainer>
      <ProductListContainer>
        <div css={sortingAndSwitchWrapperStyle}>
          <div css={switchWrapperStyle}>
            <RecommendedProductsSwitch />
            <RareItemsSwitch />
          </div>
          <ProductListSorting />
        </div>
      </ProductListContainer>
    </>
  );
};
