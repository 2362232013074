import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, designToken } from '../theme';
import { MenuList } from './MenuList';

type MenuProps = {
  children: React.ReactNode;
  open?: boolean;
  isSubMenu?: boolean;
};

const menuStyle = css({
  '@keyframes slideDown': {
    '0%': {
      transform: 'scaleY(0)',
    },
    '80%': {
      transform: 'scaleY(1.1)',
    },
    '100%': {
      transform: 'scaleY(1)',
    },
  },

  position: 'relative',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  color: designToken.text.default,

  '& li': {
    display: 'inline-block',
    textAlign: 'left',
  },

  '> li': {
    '&:first-of-type, &:last-of-type': {
      padding: baseSpacing,
    },

    '&:only-child': {
      padding: 0,
    },
  },
});

const submenuStyle = (open: boolean) =>
  css({
    display: open ? 'block' : 'none',
    position: 'absolute',

    top: '100%',
    right: 0,
    whiteSpace: 'nowrap',

    listStyle: 'none',
    margin: 0,
    padding: 0,
    borderColor: designToken.border.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: baseSpacing / 2,
    color: designToken.text.default,
    backgroundColor: designToken.background.offWhite,
    zIndex: 1,
    boxShadow:
      '0px 8px 15px 5px rgb(0 0 0 / 10%), 8px 15px 15px 5px rgb(0 0 0 / 5%), -8px 15px 15px 5px rgb(0 0 0 / 5%)',

    '& li': {
      display: 'block',
      textAlign: 'left',
      width: 'auto',

      '&:first-of-type': {
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
      },

      '&:last-of-type': {
        borderBottomLeftRadius: 'inherit',
        borderBottomRightRadius: 'inherit',
      },

      '&:hover': {
        backgroundColor: designToken.background.gray,
      },
    },
  });

export const Menu: React.FC<MenuProps> = ({ children, open = true, isSubMenu = false }) => {
  return <MenuList cssStyle={isSubMenu ? submenuStyle(open) : menuStyle}>{children}</MenuList>;
};
