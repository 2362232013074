import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  portalId: string;
  children: React.ReactNode;
};

export const Portal: React.FC<PortalProps> = ({ portalId, children }) => {
  const elRef = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    const portalElRef = elRef.current;
    let portalRoot = document.getElementById(portalId);

    if (!portalRoot) {
      const portalDiv = document.createElement('div');
      portalDiv.id = portalId;
      document.body.prepend(portalDiv);
      portalRoot = portalDiv;
    }
    portalRoot.appendChild(portalElRef);
    const firstElement = portalElRef.firstElementChild;
    if (firstElement instanceof HTMLDivElement) {
      firstElement.focus({ preventScroll: true });
    }

    return () => {
      portalRoot!.removeChild(portalElRef);
    };
  }, []);

  return createPortal(<div tabIndex={-1}>{children}</div>, elRef.current);
};
