import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef } from 'react';

import { InfoDataContext } from '../../context/Info/InfoDataContext';
import { useDataAccessLayer } from '../DataAccessLayerProvider';

type GetContentParameters = Parameters<typeof InfoDataContext.prototype.getContent>;
export const useInfo = (customerId: GetContentParameters[0]) => {
  const dataAccessLayer = useDataAccessLayer();
  const prevCustomerId = useRef(customerId);

  const observablesQueryResult = useMemo(() => {
    return dataAccessLayer.info.getContent(customerId);
  }, [dataAccessLayer.info, customerId]);

  const queryResult = useQuery(observablesQueryResult.query, {
    variables: observablesQueryResult.variables,
    client: dataAccessLayer.apolloClient,
  });

  useEffect(() => {
    if (prevCustomerId.current !== customerId) {
      queryResult.refetch(observablesQueryResult.variables);
      prevCustomerId.current = customerId;
    }
  }, [customerId, observablesQueryResult.variables, queryResult]);

  return queryResult;
};
