import React, { forwardRef } from 'react';

import { buttonStyle } from './buttonStyle';
import { ButtonType } from './ButtonType';

type ButtonProps = {
  children?: React.ReactNode;
  type?: ButtonType;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  tabIndex?: number;
  warning?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  fullWidth?: boolean;
};

export const Button: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<ButtonProps> & React.RefAttributes<HTMLButtonElement>
> = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      type = ButtonType.PRIMARY,
      size = 'medium',
      disabled,
      onClick,
      onKeyDown,
      tabIndex,
      warning = false,
      fullWidth = true,
    },
    ref
  ) => {
    return (
      <button
        css={buttonStyle(type, size, disabled, warning, fullWidth)}
        disabled={disabled}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);
Button.displayName = 'Button';
