import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { baseSpacing, media } from '../theme';

const sectionStyle = css({
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 2,
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderBottomColor: tokens.color.core.gray[200],

  [media.small]: {
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
  },

  'p+p': {
    marginTop: baseSpacing * 1.5,
  },

  'p:last-child': {
    marginBottom: baseSpacing * 2,
  },

  'p:only-child': {
    marginTop: baseSpacing * 2,
  },

  '&:last-of-type': {
    borderBottomWidth: 0,
  },
});

const mainSectionStyle = css(sectionStyle, {
  marginTop: '4vh',
  marginBottom: '4vh',
});

type SectionProps = {
  children: React.ReactNode;
  main?: boolean;
};
export const Section: React.FC<SectionProps> = ({ main = false, children }) => {
  return <section css={main ? mainSectionStyle : sectionStyle}>{children}</section>;
};
