import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import {
  useSelectedCustomer,
  useUserPreferences,
} from '@lego/b2b-unicorn-shared/components/UserContext';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { media } from '@lego/b2b-unicorn-ui-constants';
import { useEffect, useRef, useState } from 'react';

import LanguageSelectorButton from './LanguageSelectorButton';
import LanguageSelectorOption from './LanguageSelectorOption';

const languageSelectorGroupStyle = css({
  display: 'contents',
  [media.large]: {
    position: 'relative',
    display: 'block',
  },
});

const optionsWrapperStyle = css({
  display: 'contents',
  [media.large]: {
    position: 'absolute',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
});

interface IObjectKeys {
  [key: string]: string;
}

const LanguageSelector = () => {
  const globalLoaderId = useRef<string>();
  const { id } = useSelectedCustomer();
  const {
    preferredLanguage,
    updateUserPreferencesForCustomer,
    availableLanguages,
    preferencesUpdating,
  } = useUserPreferences();
  const { language_selector_label, language_en, language_fr } = useLabels();
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (preferencesUpdating && !globalLoaderId.current) {
      globalLoaderId.current = GlobalLoadingService.add('preferences-update');
    }
    if (!preferencesUpdating && globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
      globalLoaderId.current = undefined;
    }
  }, [preferencesUpdating]);

  const mapLanguageCodeToLabel = (languageCode: string) => {
    const languages: IObjectKeys = {
      EN: language_en,
      FR: language_fr,
    };
    return languages[languageCode];
  };

  const closeDropdownOnBlur = (e: React.FocusEvent<HTMLElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowOptions(false);
    }
  };

  const closeDropdownOnEscape = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KEYCODE_STRING.ESCAPE) {
      setShowOptions(false);
    }
  };

  if (availableLanguages && availableLanguages.length > 1) {
    return (
      /* This div is catching all children ESCAPE keypress */
      <div
        css={languageSelectorGroupStyle}
        onBlur={closeDropdownOnBlur}
        onKeyDown={closeDropdownOnEscape}
        tabIndex={-1}
      >
        <LanguageSelectorButton
          preferredLanguageName={mapLanguageCodeToLabel(preferredLanguage.code)}
          translations={{ buttonLabel: language_selector_label }}
          toggleCustomerSelection={() => setShowOptions(!showOptions)}
        />
        {showOptions && (
          <div css={optionsWrapperStyle}>
            {availableLanguages.map((language) => {
              return (
                <LanguageSelectorOption
                  key={language.id}
                  onClickHandler={() => {
                    updateUserPreferencesForCustomer({
                      customerId: id,
                      preferredLanguage: language,
                    });
                    setShowOptions(false);
                  }}
                  languageName={mapLanguageCodeToLabel(language.code)}
                  isChecked={language.id === preferredLanguage.id}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default LanguageSelector;
