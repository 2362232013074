export enum SortingDirection {
  ASC = 1,
  DESC = -1,
  OFF = 0,
}

export const getSortingDirectionFromUrlParam = (urlParam: string | undefined | null) => {
  if (!urlParam) {
    return SortingDirection.OFF;
  }

  if (urlParam.includes(':')) {
    return urlParam.split(':')[1] === 'asc' ? SortingDirection.ASC : SortingDirection.DESC;
  }

  return SortingDirection.OFF;
};

export const getSortingColumKeyFromUrlParam = (urlParam: string | undefined | null) => {
  if (!urlParam) {
    return;
  }

  if (urlParam.includes(':')) {
    return urlParam.split(':')[0];
  }
};
