import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';

import { InfoItemsQuery, InfoItemsQueryVariables } from '../../generated-types/graphql';
import { ExtractElementType } from '../../utils/TypeScriptHelpers';
import { ContextAbstract } from '../ContextAbstract';
import { INFO_ITEMS } from './queries/infoItems';

export type InfoItemList = InfoItemsQuery['getInfoItems'];
export type InfoItemListsItem = ExtractElementType<NonNullable<InfoItemsQuery['getInfoItems']>>;
export class InfoDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {};

  public getContent(customerId: number) {
    return this._apolloClient.watchQuery<InfoItemsQuery, InfoItemsQueryVariables>({
      query: INFO_ITEMS,
      variables: {
        customerId,
      },
    });
  }
}
