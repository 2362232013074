import { Global } from '@emotion/react';
import React, { useMemo } from 'react';
import type { Matrix } from 'react-spreadsheet';
import Spreadsheet from 'react-spreadsheet';

import { useBulkQuickAddContext } from '../../BulkQuickAddContext';
import {
  bulkQuickAddPreviewClassName,
  globalPreviewCellCss,
  lastColCss,
  previewHeaderCellCss,
} from '../../styles';
import { BulkQuickAddCell } from '../../types';
import { BulkQuickAddColumnIndicator } from '../BulkQuickAddColumnIndicator';

type BulkAddReadOnlySpreadsheetProps = {
  data: Matrix<BulkQuickAddCell>;
};

export const BulkAddReadOnlySpreadsheet: React.FC<BulkAddReadOnlySpreadsheetProps> = ({ data }) => {
  const { translations } = useBulkQuickAddContext();
  const feedbackColumnsLabels = useMemo(
    () => [
      translations.columnLabels.name,
      translations.columnLabels.theme,
      translations.columnLabels.filter_availability,
      translations.columnLabels.total_pieces,
      translations.columnLabels.price_per_piece,
      translations.columnLabels.your_price,
    ],
    [translations]
  );

  return (
    <>
      <Global styles={[previewHeaderCellCss, globalPreviewCellCss, lastColCss]} />
      <Spreadsheet
        data={data}
        columnLabels={feedbackColumnsLabels}
        className={bulkQuickAddPreviewClassName}
        hideRowIndicators
        ColumnIndicator={BulkQuickAddColumnIndicator}
      />
    </>
  );
};
