import { css } from '@emotion/react';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React, { useEffect, useState } from 'react';

const searchContainerStyle = css({
  position: 'relative',
  width: '100%',
  [media.medium]: {
    maxWidth: 340,
  },
  svg: {
    width: 20,
    height: 20,
    position: 'absolute',
    top: baseSpacing * 1.5,
    right: baseSpacing * 1.5,
    pointerEvents: 'none',
  },
});

const searchInputStyle = css({
  borderWidth: 1,
  borderRadius: 4,
  borderStyle: 'solid',
  borderColor: designToken.border.default,
  outline: 'none',
  fontSize: 14,
  padding: baseSpacing,
  paddingRight: baseSpacing * 5,
  width: '100%',
  height: 40,
  lineHeight: '40px',
  '&:focus': {
    borderColor: designToken.border.information,
  },
});

export interface ISearchFieldBaseProps {
  /** Text shown in empty field as instruction to user. */
  placeholder: string;
  /** Value of the field is sent to this function on ENTER press. */
  searchValueSubmitHandler: (value: string) => void;
  /** Initial value. E.g. for the value of the current search. Optional. */
  initValue?: string;
  /** Clear the input of the field after pressing ENTER. Optional. (Defaults to false.) */
  clearAfterSubmit?: boolean;
}

interface ISearchFieldProps extends ISearchFieldBaseProps {
  /** Content of the search field is passed to this function on every keystroke. */
  searchValueUpdateHandler?: (value: string, keyEvent: React.KeyboardEvent) => void;
}

const SearchField = (props: ISearchFieldProps) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    /** Explicit check for undefined because empty string is falsy. */
    if (props.initValue !== undefined) {
      setInputValue(props.initValue);
    }
  }, [props.initValue]);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === KEYCODE_STRING.ENTER || e.code === KEYCODE_STRING.NUMPAD_ENTER) {
      props.searchValueSubmitHandler(inputValue);
      if (props.clearAfterSubmit) {
        setInputValue('');
      }
    } else if (props.searchValueUpdateHandler) {
      props.searchValueUpdateHandler(inputValue, e);
    }
  };

  return (
    <div css={searchContainerStyle}>
      <input
        css={searchInputStyle}
        type="text"
        value={inputValue}
        placeholder={props.placeholder}
        onChange={(e) => {
          setInputValue(e.currentTarget.value);
        }}
        onKeyUp={handleKeyUp}
      />
      <Icon type={IconType.SEARCH} />
    </div>
  );
};

export default SearchField;
