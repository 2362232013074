import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, font, media } from '../theme';
import { TableCell } from './TableCell';

type TableCellItemNumberProps = {
  children: React.ReactNode;
  colName?: string;
};

const tableCellItemNumberStyle = css({
  width: 100,
  position: 'absolute',
  right: '70%',
  top: 20,
  backgroundColor: 'transparent',
  border: 'none',
  textAlign: 'right',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: baseSpacing,

  fontFeatureSettings: '"tnum"',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: font.weight.bold,
  marginLeft: baseSpacing / 2,

  [media.medium]: {
    position: 'static',
  },
  '&:focus': {
    outline: 'none',
  },
});

export const TableCellItemNumber: React.FC<TableCellItemNumberProps> = ({ children, colName }) => {
  return (
    <TableCell colName={colName}>
      <div css={tableCellItemNumberStyle}>{children}</div>
    </TableCell>
  );
};
