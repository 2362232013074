import { css } from '@emotion/react';
import { AnimatedDots, designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';

const loadMoreButtonStyle = css({
  fontSize: font.size.medium,
  fontWeight: font.weight.normal,
  color: designToken.interactive.default,
  backgroundColor: designToken.background.offWhite,
  height: baseSpacing * 7,
  width: '100%',
  borderRadius: 4,
  borderColor: designToken.interactive.default,
  borderWidth: 1,
  borderStyle: 'solid',
  padding: 0,
  cursor: 'pointer',
  transitionProperty: 'background-color',
  transitionDuration: '200ms',

  '&:hover:enabled': {
    backgroundColor: designToken.background.blue,
  },

  '&:focus': {
    outline: 'none',
  },
});

interface ILoadMoreButton {
  handleLoadMoreClick: () => void;
  loadMoreInProgress?: boolean;
  text: string;
  customStyle?: ReturnType<typeof css>;
}

const LoadMoreButton = ({
  handleLoadMoreClick,
  customStyle,
  loadMoreInProgress = false,
  text,
}: ILoadMoreButton) => (
  <button
    css={[loadMoreButtonStyle, customStyle]}
    onClick={handleLoadMoreClick}
  >
    {loadMoreInProgress ? <AnimatedDots /> : text}
  </button>
);

export default LoadMoreButton;
