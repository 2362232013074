import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';

import NavigationTabs from '../../components/NavigationTabs/NavigationTabs';

const ManageNavigationTabs = () => {
  const { orders_history, invoices, upcoming_deliveries } = useLabels();

  return (
    <NavigationTabs
      tabs={[
        { label: orders_history, url: '/manage' },
        { label: invoices, url: '/manage/invoices' },
        { label: upcoming_deliveries, url: '/manage/upcoming-deliveries' },
      ]}
    />
  );
};

export default ManageNavigationTabs;
