import { useClaim } from '@lego/b2b-unicorn-shared/components/Claim';
import React, { useCallback, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

export const ClaimWarningPrompt: React.FC = () => {
  const { blockNavigation } = useClaim();

  // Start: BlockNavigation Code Block
  // preventing default browser behaviour resulting in displaying confirm prompt
  const handleBeforeUnload = useCallback((e: Event) => {
    // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = true;
  }, []);

  // adding/removing onbeforeunload listener
  useEffect(() => {
    if (blockNavigation) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [blockNavigation, handleBeforeUnload]);
  // End: BlockNavigation Code Block

  return (
    <Prompt
      when={blockNavigation}
      message={'You have unsaved changes. Are you sure you want to leave?'}
    />
  );
};
