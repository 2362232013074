import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';

import { AssetTokenQuery, AssetTokenQueryVariables } from '../../generated-types/graphql';
import { useDataAccessLayer } from '../DataAccessLayerProvider';

export const useAssetToken = () => {
  const dataAccessLayer = useDataAccessLayer();
  const lazyQuery = useMemo(() => {
    return dataAccessLayer.utils.getAssetTokenLazy();
  }, [dataAccessLayer.utils]);

  return useLazyQuery<AssetTokenQuery, AssetTokenQueryVariables>(lazyQuery.query, {
    client: dataAccessLayer.apolloClient,
    fetchPolicy: 'no-cache',
  });
};
