import { css } from '@emotion/react';
import React, { useMemo } from 'react';

import { assignDesignToken, ObjectPathLeavesOnly } from '../../utils';
import { designToken, font } from '../theme';

type ParagraphProps = {
  children: React.ReactNode;
  fontSize?: keyof typeof font.size;
  fontWeight?: keyof typeof font.weight;
  designToken?: ObjectPathLeavesOnly<typeof designToken>;
};

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  designToken,
  fontWeight = 'normal',
  fontSize = 'normal',
}) => {
  const myDesignToken = designToken ? assignDesignToken(designToken) : undefined;

  const styles = useMemo(
    () =>
      css({
        color: myDesignToken,
        fontSize: font.size[fontSize],
        fontWeight: font.weight[fontWeight],
        marginBottom: 0,

        ':last-of-type': {
          marginBottom: '1em',
        },

        '+ p': {
          marginTop: 0,
        },
      }),
    [fontSize, fontWeight, myDesignToken]
  );

  return <p css={styles}>{children}</p>;
};
