export * from './documentTitle';
export * from './filters';
export * from './product';
export * from './productColumnsWidth';
export * from './sorting';
export * from './urlSearchParams';

export const TABLE_ROWS_PER_PAGE = 15;
export const INFO_TILE_WIDTH = 343;
export const PRODUCT_PER_PAGE = 36;
