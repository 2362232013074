const BASE_DOCUMENT_TITLE = 'LEGO® B2B Web Shop';

const TITLE_SEPARATOR = ' | ';

export const createDocumentTitle = (parts: Array<string> = []) => {
  const allParts = [...parts, BASE_DOCUMENT_TITLE].filter((part) => !!part);
  const newDocumentTitle = allParts.join(TITLE_SEPARATOR);

  return newDocumentTitle;
};
