import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Input } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { useState } from 'react';

import { bigTextStyle } from '../../../common/styles/checkoutFlowStyles';
import { inputContainerStyle } from '../../../common/styles/inputStyles';

const labelStyle = css({
  marginBottom: baseSpacing,
});

interface IOrderName {
  value?: string;
  onChange: (isValid: boolean, value: string) => void;
}

const restrictedCharacters = ['\\', '|', '"'];

const OrderName = ({ value, onChange }: IOrderName) => {
  const { order_name, order_name_select_placeholder, order_name_hint } = useLabels();
  const [isInvalid, setIsInvalid] = useState(false);

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isInvalid = restrictedCharacters.some((char) => e.target.value.includes(char));
    setIsInvalid(isInvalid);
    onChange(!isInvalid, e.target.value);
  };

  return (
    <div css={inputContainerStyle}>
      <h4 css={bigTextStyle}>{order_name}</h4>
      <div css={labelStyle}>{order_name_select_placeholder}</div>
      <Input
        onChange={handleInputOnChange}
        value={value}
        // maxLength in SAP is 35 chars
        maxLength={35}
        isInvalid={isInvalid}
        invalidMessage={`${order_name_hint}: ${restrictedCharacters.join(', ')}`}
      />
    </div>
  );
};

export default OrderName;
