import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Button, ButtonType, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { exhaustiveSwitchCheck } from '@lego/b2b-unicorn-shared/utils';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React, { useMemo } from 'react';

type ContentHubButtonProps = {
  materialId: number | number[];
  // Where is this button added, this sets the needed tracking events
  context: 'orderDetails' | 'productDetails';
  type?: ButtonType;
};

export const ContentHubButton: React.FC<ContentHubButtonProps> = ({
  materialId,
  type = ButtonType.GHOST,
  context,
}) => {
  const { content_hub_link } = useLabels();
  const { trackEvent } = useAnalytics();

  const contentHubUrl = useMemo(() => {
    const contentHubUrl = new URL('https://content.lego.com/products');
    if (!Array.isArray(materialId) || materialId.length === 1) {
      const singleMaterialId = Array.isArray(materialId) ? materialId[0] : materialId;
      contentHubUrl.pathname = `/products/${singleMaterialId}`;
    } else {
      contentHubUrl.searchParams.set('q', materialId.join(' '));
    }

    return contentHubUrl.toString();
  }, [materialId]);

  const handleOnClick = () => {
    let trackingEvent: Parameters<typeof trackEvent>[0];
    switch (context) {
      case 'orderDetails':
        trackingEvent = {
          event: 'contentHubLinkOrderDetails',
          name: 'User clicked on Content Hub link in order details',
        };
        break;
      case 'productDetails':
        trackingEvent = {
          event: 'contentHubLinkProductDetails',
          name: 'User clicked on Content Hub link in product details',
        };
        break;
      default:
        exhaustiveSwitchCheck(context);
    }

    trackEvent(trackingEvent);

    window.open(contentHubUrl, '_blank');
  };

  return (
    <Button
      type={type}
      size={'small'}
      onClick={handleOnClick}
    >
      <Icon type={IconType.BOX_MAGNIFYING} /> <span>{content_hub_link}</span>
    </Button>
  );
};
