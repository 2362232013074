import { css } from '@emotion/react';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import { useHistory } from 'react-router-dom';

import LogoGraphic from './LogoGraphic';

const Logo = ({ alternateTextColor = false }: { alternateTextColor?: boolean }) => {
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const handleOnClick = () => {
    history.push('/');
    trackEvent({
      event: 'clickOnMainBrandLogo',
      name: 'User clicked main brand logo',
    });
  };

  return (
    <button
      css={css({
        color: alternateTextColor ? colors.white : colors.klik.slate10,
        '&:hover, :focus': {
          color: colors.klik.lightBlue200,
          outline: 'none',
        },
      })}
      onClick={handleOnClick}
    >
      <LogoGraphic />
    </button>
  );
};

export default Logo;
