import { ReplenishmentProductQuery_product_Box } from '../../../generated-types/graphql';
import { Lifecycle, ProductTag } from '../../../generated-types/types';
import { Box } from '../../../type-policies/ProductPolicy/entities';

export class ReplenishmentProductBox extends Box implements ReplenishmentProductQuery_product_Box {
  itemNumber?: number | null | undefined;
  launchDate?: string | null | undefined;
  exitDate?: string | null | undefined;
  ageGroup?: string | null | undefined;
  description?: string | null | undefined;
  pieces?: number | null | undefined;
  materialId: number;
  ean: string;
  available: boolean;
  name?: string | null | undefined;
  piecesPerCasePack: number;
  lifecycles: Lifecycle[];
  tags: ProductTag[];
  theme: string;
  recommendedRetailPrice?: ReplenishmentProductQuery_product_Box['recommendedRetailPrice'];
  dimensions?: ReplenishmentProductQuery_product_Box['dimensions'];
  weight?: ReplenishmentProductQuery_product_Box['weight'];
  openOrderInfo?: ReplenishmentProductQuery_product_Box['openOrderInfo'];
  price: ReplenishmentProductQuery_product_Box['price'];
  stockStatus: ReplenishmentProductQuery_product_Box['stockStatus'];

  constructor(product: ReplenishmentProductQuery_product_Box) {
    super();
    this.itemNumber = product.itemNumber;
    this.launchDate = product.launchDate;
    this.exitDate = product.exitDate;
    this.ageGroup = product.ageGroup;
    this.description = product.description;
    this.pieces = product.pieces;
    this.materialId = product.materialId;
    this.ean = product.ean;
    this.available = product.available;
    this.name = product.name;
    this.piecesPerCasePack = product.piecesPerCasePack;
    this.lifecycles = product.lifecycles;
    this.tags = product.tags;
    this.theme = product.theme;
    this.recommendedRetailPrice = product.recommendedRetailPrice;
    this.dimensions = product.dimensions;
    this.weight = product.weight;
    this.openOrderInfo = product.openOrderInfo;
    this.price = product.price;
    this.stockStatus = product.stockStatus;
  }
}
