import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { EmptyFeedback, GoBackLink, Heading, Paragraph, Spacer } from '@lego/b2b-unicorn-shared/ui';
import { font, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const EmptyReplenishmentCartPreview: React.FC = () => {
  const { button_go_to_shop, cart_empty_header, cart_empty_content } = useLabels();
  const history = useHistory();

  return (
    <>
      <Spacer spacingType={'padding'} />
      <EmptyFeedback type={'kaktus'}>
        <Heading
          fontSize={font.size.large}
          fontSizeMedia={{ [media.medium]: 60 }}
        >
          {cart_empty_header}
        </Heading>
        <Paragraph>{cart_empty_content}</Paragraph>
        <Spacer multiplier={2} />
        <GoBackLink
          text={button_go_to_shop}
          onClick={() => history.push('/')}
        />
      </EmptyFeedback>
    </>
  );
};
