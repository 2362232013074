/*
 * Extracts the element type in an array
 * Eg.
 *  Input: string[]
 *  Output: string
 */
export type ExtractElementType<T> = T extends readonly (infer ElementType)[] ? ElementType : never;
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/*
 * Removes undefined and null from properties
 * Eg.
 *  Input: { name?: string | null, age: number | null }
 *  Output: { name: string, age: number }
 */
export type MarkRequired<P> = {
  [K in keyof P]-?: NonNullable<P[K]>;
};

export function exhaustiveSwitchCheck(input: never | undefined): never {
  throw new Error(`Didn't expect ${input} in this switch statement`);
}

export function isNullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[],
];

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

export type ObjectPathLeavesOnly<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: Join<K, ObjectPathLeavesOnly<T[K], Prev[D]>> }[keyof T]
    : '';
