import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  Price,
  PromotionProduct,
  PromotionProductCondition,
} from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  DiscountedPrice,
  TableCell,
  TableCellItemNumber,
  TableCellName,
  TableCellNumber,
  TableCellQuantityButton,
  TableImageWrapperInCell,
  TableRow,
} from '@lego/b2b-unicorn-shared/ui';
import { useCartItemRow } from '@lego/b2b-unicorn-ui-checkout-flow';
import { NotificationType, ProductImage, useNotifications } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';
import { deepEqual } from 'fast-equals';
import { GraphQLFormattedError } from 'graphql/index';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { ProductName } from '../../../../../components/ProductName/ProductName';
import { CartPreviewItemCounterWithButton } from '../../../../../components/ReplenishmentCartPreview/CartPreviewItemCounterWithButton';

const tableCellOrderStyle = css({
  'td:nth-of-type(n+3)': {
    minHeight: 25,
    paddingLeft: `calc(30% + ${baseSpacing * 2}px)`,
    [media.medium]: {
      paddingLeft: baseSpacing,
    },
  },
  '> td:nth-of-type(1)': {
    order: 1,
  },
  '> td:nth-of-type(2)': {
    order: 2,
  },
  '> td:nth-of-type(3)': {
    order: 3,
  },
  '> td:nth-of-type(4)': {
    order: 8,
    '&:after': {
      display: 'none !important',
    },
  },
  '> td:nth-of-type(5)': {
    order: 7,
  },
  '> td:nth-of-type(6)': {
    order: 4,
  },
  '> td:nth-of-type(7)': {
    order: 5,
  },
  '> td:nth-of-type(8)': {
    order: 6,
  },
});

const snackbarProductNameStyle = css({
  fontWeight: font.weight.bold,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '1',
  overflow: 'hidden',
});

const calculatePricePerCasePack = (price: Price, piecesPerCasePack: number): Price => ({
  __typename: 'Price',
  currency: price.currency,
  listPrice: price.listPrice && price.listPrice * piecesPerCasePack,
  grossPrice: price.grossPrice && price.grossPrice * piecesPerCasePack,
  estimatedNetInvoicedPrice: price.estimatedNetInvoicedPrice * piecesPerCasePack,
  netInvoicedPrice: price.netInvoicedPrice && price.netInvoicedPrice * piecesPerCasePack,
});

type PromotionTableRowProps = {
  product: PromotionProduct;
  quantity: number;
  inactive: boolean;
  onError: (error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]) => void;
  isInvalid?: boolean;
  updateCart: (product: PromotionProduct, quantity: number) => Promise<unknown>;

  productConditions?: PromotionProductCondition;
};
export const PromotionTableRow: React.FC<PromotionTableRowProps> = React.memo(
  ({
    product,
    productConditions,
    quantity: externalQuantity,
    onError,
    updateCart,
    inactive,
    isInvalid,
  }) => {
    const {
      total_pieces,
      your_price,
      snackbar_update_product_error,
      price_per_piece,
      theme,
      pcs,
      price_pieces_per_case_pack,
      product_condition_minimum_case_packs,
    } = useLabels();
    const selectedCustomer = useSelectedCustomer();
    const [quantity, setQuantity] = useState<number>(externalQuantity);
    const { addSnackbar, snackbar } = useNotifications();

    const handleOnUpdateError = useCallback(
      (
        error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
      ) => {
        onError(error);
        setQuantity(0);

        const errorText = (
          <Fragment>
            <span css={snackbarProductNameStyle}>
              {product.__typename === 'Box' && product.itemNumber} — {product.name}
            </span>{' '}
            {snackbar_update_product_error}
          </Fragment>
        );
        addSnackbar({
          type: NotificationType.WARNING,
          content: errorText,
          showDismissButton: false,
          isStacked: !!snackbar,
        });
      },
      [onError]
    );

    const onUpdate = useCallback(
      (product: PromotionProduct, quantity: number) => {
        updateCart(product, quantity).catch(handleOnUpdateError);
      },
      [updateCart, handleOnUpdateError]
    );

    useEffect(() => {
      setQuantity(externalQuantity);
    }, [externalQuantity]);

    const { handleCounterUpdate, totalPieces } = useCartItemRow<PromotionProduct>({
      onUpdate,
      quantity,
      product,
      snackbar_error_message: snackbar_update_product_error,
    });

    const imageUrl = getImageUrl(product.materialId, ImageType.MAIN_BOX, 30);
    const itemNumber = product.__typename === 'Box' ? product.itemNumber : null;

    return (
      <TableRow
        highlight={quantity > 0}
        extraCss={tableCellOrderStyle}
      >
        <TableCellItemNumber>
          <TableImageWrapperInCell>
            <ProductImage
              src={imageUrl}
              alt="box image"
            />
          </TableImageWrapperInCell>
          {itemNumber || product.materialId}
        </TableCellItemNumber>
        <TableCellName>
          <ProductName name={product.name} />
        </TableCellName>
        <TableCell colName={theme}>{product.theme}</TableCell>
        {!inactive && (
          <>
            <TableCellQuantityButton>
              <CartPreviewItemCounterWithButton
                isInvalid={isInvalid}
                quantity={quantity}
                onChange={handleCounterUpdate}
                minValue={productConditions?.minimumCasePacks || undefined}
                minValueHelpText={`${product_condition_minimum_case_packs} ${productConditions?.minimumCasePacks}`}
              />
            </TableCellQuantityButton>
            <TableCellNumber
              colName={total_pieces}
              align={'center'}
            >
              {totalPieces}
            </TableCellNumber>
          </>
        )}
        <TableCellNumber
          colName={price_per_piece}
          align={'right'}
        >
          <DiscountedPrice locale={selectedCustomer.locale}>{product.price}</DiscountedPrice>
        </TableCellNumber>
        <TableCellNumber
          colName={price_pieces_per_case_pack}
          align={'right'}
        >
          <DiscountedPrice locale={selectedCustomer.locale}>
            {calculatePricePerCasePack(product.price, product.piecesPerCasePack)}
          </DiscountedPrice>{' '}
          /&nbsp;{product.piecesPerCasePack}&nbsp;{pcs}
        </TableCellNumber>
        {!inactive && (
          <TableCellNumber
            colName={your_price}
            align={'right'}
          >
            <DiscountedPrice
              locale={selectedCustomer.locale}
              multiplier={totalPieces}
            >
              {product.price}
            </DiscountedPrice>
          </TableCellNumber>
        )}
      </TableRow>
    );
  },
  (prevProps, nextProps) => {
    return deepEqual(prevProps, nextProps);
  }
);
PromotionTableRow.displayName = 'PromotionItemRow';
