import { CART_MAX_ITEM_QUANTITY } from '@lego/b2b-unicorn-ui-constants';
import { ChangeEvent } from 'react';

const quantityOnChangeHandler = (
  quantity: number,
  setFunction: (value: number) => void,
  e: ChangeEvent<HTMLInputElement>
) => {
  const value = e.target.validity.valid
    ? quantity <= CART_MAX_ITEM_QUANTITY
      ? parseInt(e.target.value, 10)
      : CART_MAX_ITEM_QUANTITY
    : 1;
  setFunction(isNaN(value) ? 0 : value);
};

export default quantityOnChangeHandler;
