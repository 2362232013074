import { css } from '@emotion/react';
import React from 'react';

import { Button, ButtonType } from '../Button';
import { Icon, IconType } from '../Icon';
import { baseSpacing, designToken } from '../theme';

const backLinkStyle = (visibility: 'hidden' | 'visible') =>
  css({
    visibility,
    '> button': {
      '> svg': {
        width: 20,
        height: 20,
        transform: 'rotate(-90deg) translateX(-1px)',
        path: {
          fill: designToken.interactive.default,
        },
        marginRight: baseSpacing / 2,
      },
    },
  });

type GoBackLinkProps = {
  onClick: () => void;
  text: string;
  withBorder?: boolean;
  visibility?: 'visible' | 'hidden';
};

export const GoBackLink: React.FC<GoBackLinkProps> = ({
  onClick,
  text,
  withBorder = false,
  visibility = 'visible',
}) => (
  <div css={backLinkStyle(visibility)}>
    <Button
      onClick={onClick}
      type={withBorder ? ButtonType.SECONDARY : ButtonType.PRIMARY_INVERTED}
      size="small"
    >
      <Icon type={IconType.SORT_ASC} />
      <span>{text}</span>
    </Button>
  </div>
);
