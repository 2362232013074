import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';

import { clearAllTagStyle } from './styles';

interface IFilterTagProps {
  onClickHandler: () => void;
  text: string;
}

const ClearAllTag = (props: IFilterTagProps) => {
  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    if (e.code === KEYCODE_STRING.ENTER) {
      props.onClickHandler();
    }
  };

  return (
    <span
      onClick={() => props.onClickHandler()}
      onKeyDown={(e) => onKeyDownHandler(e)}
      aria-label={`Remove all filters`}
      role="button"
      css={clearAllTagStyle}
      tabIndex={0}
    >
      {props.text}
    </span>
  );
};

export default ClearAllTag;
