import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';

import { FormControlInvalid } from '../FormControl';
import { baseSpacing, designToken, font } from '../theme';

const textareaStyle = () =>
  css({
    width: `100%`,
    minWidth: 0,
    outline: `transparent solid 2px`,
    outlineOffset: 2,
    position: `relative`,
    appearance: `none`,
    transition: `all 0.2s ease 0s`,
    borderRadius: `0.25rem`,
    fontSize: font.size.small,
    lineHeight: font.lineHeight,
    padding: baseSpacing,
    borderWidth: 1,
    borderStyle: `solid`,
    borderColor: designToken.border.default,
    color: designToken.text.muted,
    background: designToken.background.white,
    display: `block`,
    fontFamily: font.family,

    '&[aria-invalid="true"]': {
      borderColor: designToken.border.error,
      boxShadow: `${designToken.border.error} 0 0 0 1px`,
    },

    '&:focus': {
      borderColor: designToken.border.focus,
      boxShadow: `${designToken.border.focus} 0 0 0 1px`,
    },

    '&:hover:not(:focus,[aria-invalid="true"])': {
      borderColor: tokens.color.core.slate['300'],
      boxShadow: `${tokens.color.core.slate['300']} 0 0 0 1px`,
    },

    '&:disabled': {
      transition: 'none',
      backgroundColor: tokens.color.core.gray['100'],
      borderColor: designToken.border.subdued,
      color: designToken.text.disabled,
      userSelect: `none`,
    },
  });

type TextareaProps = {
  name?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;

  rows?: number;
  isInvalid?: boolean;
  invalidMessage?: string;
};

export const Textarea: React.FC<TextareaProps> = ({
  name,
  value,
  onChange,
  onBlur,
  rows = 3,
  invalidMessage,
  isInvalid,
  disabled,
}) => {
  // Generate a unique id for this textarea element, prepend the id with 'a' to ensure it's a valid id
  const [textareaId] = useState(() => `a${nanoid(10)}`);

  return (
    <div>
      <textarea
        css={textareaStyle()}
        rows={rows}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        aria-invalid={isInvalid}
        aria-describedby={isInvalid ? `${textareaId}-invalid` : undefined}
        name={name}
      />
      {isInvalid && invalidMessage && (
        <FormControlInvalid
          invalidMessage={invalidMessage}
          ariaId={`${textareaId}-invalid`}
        />
      )}
    </div>
  );
};
