import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';
import { FunctionComponent } from 'react';

import { imageContainerStyle, imageWrapperStyle } from '../../pages/manage/OrderDetails/styles';

interface IProductIdAndImage {
  materialId: number;
  itemNumber?: number | null;
}

const ProductIdAndImage: FunctionComponent<IProductIdAndImage> = ({ materialId, itemNumber }) => {
  const imageUrl = getImageUrl(materialId, ImageType.MAIN_BOX, 120);

  return (
    <div
      css={imageWrapperStyle}
      key={'order-details-image-div-' + itemNumber}
    >
      <div css={imageContainerStyle}>
        <ProductImage
          src={imageUrl}
          alt={`Product ${materialId} box.`}
        />
      </div>
      {itemNumber}
    </div>
  );
};

export default ProductIdAndImage;
