import { sanitizePathname } from '@lego/b2b-unicorn-ui-analyticscontext';
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';

import { createDocumentTitle } from './utils';

interface ITitles {
  [key: string]: Array<string>;
}
interface IDocumentTitle {
  titles: ITitles;
}

const DocumentTitle: FunctionComponent<IDocumentTitle> = ({ titles }) => {
  const location = useLocation();
  const sanitizedPathname = sanitizePathname(location.pathname);

  const createCurrentDocumentTitle = (allTitles: ITitles) => {
    const currentTitleParts = allTitles[sanitizedPathname];
    return createDocumentTitle(currentTitleParts);
  };

  const title = createCurrentDocumentTitle(titles);
  if (title !== document.title) {
    document.title = title;
  }

  return null;
};

export default DocumentTitle;
