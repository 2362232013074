import { ApmPageTransaction } from '@lego/b2b-unicorn-apm';
import { useHasFeature } from '@lego/b2b-unicorn-ui-featuretogglecontext';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PageNotFound } from '../../../shared/layouts/PageNotFound';
import InvoiceListLayout from '../../layouts/manage/InvoiceListLayout';
import OrderListLayout from '../../layouts/manage/OrderListLayout';
import UpcomingDeliveriesLayout from '../../layouts/manage/UpcomingDeliveriesLayout';
import { CreateClaimConfirmation } from './CreateClaim/CreateClaimConfirmation';
import CreateClaimPage from './CreateClaimPage';
import InvoiceDetailsPage from './InvoiceDetailsPage';
import OrderDetailsPage from './OrderDetailsPage';

export const ManageRouter = () => {
  const showClaims = useHasFeature('claims', false);

  return (
    <Switch>
      <Route
        exact
        path="/manage"
      >
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <OrderListLayout />
          </ApmPageTransaction>
        )}
      </Route>
      <Route
        exact
        path={'/manage/order/:orderNumber'}
      >
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <OrderDetailsPage />
          </ApmPageTransaction>
        )}
      </Route>
      <Route
        exact
        path={'/manage/invoices'}
      >
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <InvoiceListLayout />
          </ApmPageTransaction>
        )}
      </Route>
      <Route
        exact
        path={'/manage/invoice/:invoiceNumber'}
      >
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <InvoiceDetailsPage />
          </ApmPageTransaction>
        )}
      </Route>
      {showClaims && (
        <Route
          exact
          path={'/manage/invoice/:invoiceNumber/create-claim'}
        >
          {({ match }) => (
            <ApmPageTransaction match={match}>
              <CreateClaimPage />
            </ApmPageTransaction>
          )}
        </Route>
      )}
      {showClaims && (
        <Route
          exact
          path={'/manage/invoice/:invoiceNumber/create-claim/confirm'}
        >
          {({ match }) => (
            <ApmPageTransaction match={match}>
              <CreateClaimConfirmation />
            </ApmPageTransaction>
          )}
        </Route>
      )}
      <Route
        exact
        path={'/manage/upcoming-deliveries'}
      >
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <UpcomingDeliveriesLayout />
          </ApmPageTransaction>
        )}
      </Route>
      <Route>
        {({ match }) => (
          <ApmPageTransaction match={match}>
            <PageNotFound />
          </ApmPageTransaction>
        )}
      </Route>
    </Switch>
  );
};
