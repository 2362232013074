import React from 'react';
import type { CellBase } from 'react-spreadsheet';

import { useBulkQuickAddContext } from '../..//BulkQuickAddContext';
import { bulkQuickAddPreviewClassName } from '../../styles';
import { RowStatusReason } from '../../types';

type NameCellProps = {
  cell?: CellBase<string>;
  row: number;
};

export const NameCell: React.FC<NameCellProps> = React.memo(
  ({ cell, row }) => {
    const { rowStatuses } = useBulkQuickAddContext();
    const rowStatus = rowStatuses[row];
    const rowTr = document.querySelector(`.${bulkQuickAddPreviewClassName} tr[row="${row}"]`);
    // Number of cells in the row is the same as the number of children in the row
    const numberOfCells = rowTr?.children.length || 0;
    if (cell?.value) {
      rowTr?.children[0].setAttribute('title', cell?.value);
    }

    if (rowStatus === RowStatusReason.NotInCatalog) {
      // Set colspan on first cell (name cell) to span the entire row
      rowTr?.children[0].setAttribute('colspan', `${numberOfCells - 1}`);
      // Hide all cells except the first and last one
      for (let i = 1; i < numberOfCells - 1; i++) {
        rowTr?.children[i].setAttribute('style', 'display: none');
      }
    } else {
      // Reset colspan and show all cells
      rowTr?.children[0].removeAttribute('colspan');
      for (let i = 1; i < numberOfCells - 1; i++) {
        rowTr?.children[i].removeAttribute('style');
      }
    }

    return <>{cell?.value}</>;
  },
  (prevProps, nextProps) => prevProps.cell?.value === nextProps.cell?.value
);
NameCell.displayName = 'NameCell';
