import { css, keyframes } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import { useEffect, useRef } from 'react';

const buttonStyle = css({
  fontSize: font.size.medium,
  fontWeight: font.weight.normal,
  color: designToken.text.inverse,
  backgroundColor: designToken.interactive.default,
  height: baseSpacing * 4,
  width: '100%',
  borderWidth: 1,
  borderColor: designToken.border.inverse,
  borderStyle: 'solid',
  marginTop: baseSpacing * 2,
  borderRadius: 4,
  padding: 0,
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  transitionProperty: 'all',
  transitionDuration: '200ms',
  '&:hover:enabled': {
    backgroundColor: designToken.interactive.hover,
  },
  '&:focus': {
    outline: 'none',
    backgroundColor: designToken.interactive.hover,
  },
  '&::before': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: designToken.success.emphasis,
    top: 60,
    left: 0,
  },
  '&:disabled': {
    cursor: 'initial',
  },
  [media.small]: {
    flexBasis: `calc(50% - ${baseSpacing / 2}px)`,
    marginTop: 0,
    height: 40,
    width: 180,
  },
  [media.medium]: {
    flexBasis: 'auto',
    height: 50,
    minWidth: 124,
  },
  svg: {
    left: -48,
    width: '100%',
  },
});

const iconStyle = css({
  fill: colors.white,
  strokeWidth: 2,
  width: 24,
  height: 24,
  position: 'absolute',
  left: 'calc(50% - 8px)',
  transform: 'translateY(60px)',
});

const background = keyframes`
  0% { top: 60px;  }
  15% { top: 0; }
  85% { top: 0; }
  100% { top: -60px; }
`;

const success = keyframes`
  0% { transform: translateY(60px); }
  10% { transform: translateY(3px); }
  15% { transform: translateY(0); }
  80% { transform: translateY(0); }
  85% { transform: translateY(3px); }
  100% { transform: translateY(60px); }
`;

const buttonAnimatedStyle = css(buttonStyle, {
  '&::before': {
    animationName: background,
    animationDuration: '1s',
    animationDirection: 'forwards',
    animationTimingFunction: 'ease-in-out',
  },
});

const iconAnimatedStyle = css(iconStyle, {
  animationName: success,
  animationDuration: '1s',
  animationDirection: 'forwards',
  animationTimingFunction: 'cubic-bezier(0,.47,.99,.6)',
});

const disabledButtonStyle = css(buttonStyle, {
  backgroundColor: tokens.color.core.slate[800],
  color: tokens.color.core.slate[100],
  cursor: 'initial',
});

interface IQuickAddButtonProps {
  disabled: boolean;
  handleOnClick: () => void;
  animate: boolean;
  setAnimate: (value: boolean) => void;
  addingBlock: boolean;
  setAddingBlock: (value: boolean) => void;
}

const QuickAddButton = ({
  disabled,
  handleOnClick,
  animate,
  setAnimate,
  addingBlock,
  setAddingBlock,
}: IQuickAddButtonProps) => {
  const { button_add } = useLabels();
  const addingBlockTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    clearTimeout(addingBlockTimeout.current);
    addingBlockTimeout.current = setTimeout(() => {
      setAddingBlock(false);
    }, 1000);
  }, [addingBlock]);

  useEffect(() => {
    disabled && setAnimate(false);
  }, [disabled]);

  return disabled ? (
    <button
      data-test="addToCartButtonDisabled"
      css={disabledButtonStyle}
      disabled
    >
      {button_add}
    </button>
  ) : (
    <button
      data-test="addToCartButton"
      css={animate ? buttonAnimatedStyle : buttonStyle}
      type="submit"
      onClick={handleOnClick}
      onAnimationEnd={() => {
        setAnimate(false);
      }}
      disabled={addingBlock}
    >
      <span>{button_add}</span>
      <span css={animate ? iconAnimatedStyle : iconStyle}>
        <Icon type={IconType.CHECK} />
      </span>
    </button>
  );
};

export default QuickAddButton;
