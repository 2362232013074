import { useLazyQuery, useQuery } from '@apollo/client';
import { useDataAccessLayerQuery } from '@lego/b2b-unicorn-data-access-layer/react/helpers/useDataAccessLayerQuery';
import { useEffect, useMemo, useRef } from 'react';

import { InvoicesDataContext } from '../../context/Invoices/InvoicesDataContext';
import { PdfInvoiceQuery, PdfInvoiceQueryVariables } from '../../generated-types/graphql';
import { useDataAccessLayer } from '../DataAccessLayerProvider';

type GetAllParameters = Parameters<typeof InvoicesDataContext.prototype.getAll>;
export const useInvoices = (customerId: GetAllParameters[0], filter: GetAllParameters[1]) => {
  const dataAccessLayer = useDataAccessLayer();
  const prevCustomerId = useRef(customerId);

  const observablesQueryResult = useMemo(() => {
    return dataAccessLayer.invoices.getAll(customerId, filter);
  }, [dataAccessLayer.invoices, customerId, filter]);

  const queryResult = useQuery(observablesQueryResult.query, {
    variables: observablesQueryResult.variables,
    notifyOnNetworkStatusChange: true,
    client: dataAccessLayer.apolloClient,
  });

  useEffect(() => {
    if (prevCustomerId.current !== customerId) {
      queryResult.refetch(observablesQueryResult.variables);
      prevCustomerId.current = customerId;
    }
  }, [customerId, observablesQueryResult.variables, queryResult]);

  return queryResult;
};

type GetByIdParameters = Parameters<typeof InvoicesDataContext.prototype.getById>;
export const useInvoice = (
  invoiceNumber: GetByIdParameters[0],
  customerId: GetByIdParameters[1]
) => {
  const dataAccessLayer = useDataAccessLayer();
  const rawDataAccessLayerQuery = useMemo(() => {
    return dataAccessLayer.invoices.getById(invoiceNumber, customerId);
  }, [customerId, dataAccessLayer.invoices, invoiceNumber]);

  return useDataAccessLayerQuery(rawDataAccessLayerQuery);
};

export const usePdfInvoice = () => {
  const dataAccessLayer = useDataAccessLayer();
  const lazyQuery = useMemo(() => {
    return dataAccessLayer.invoices.getPdfInvoiceLazy();
  }, [dataAccessLayer.invoices]);

  return useLazyQuery<PdfInvoiceQuery, PdfInvoiceQueryVariables>(lazyQuery.query, {
    client: dataAccessLayer.apolloClient,
    fetchPolicy: 'no-cache',
  });
};
