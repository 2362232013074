import { useState, useEffect, useRef, useContext, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { css } from '@emotion/react';

import { colors, baseSpacing, font, mediaSizes } from '@lego/b2b-unicorn-ui-constants';
import SearchItem from './SearchItem';
import SearchFeedback from './SearchFeedback';
import SearchInput from './SearchInput';
import {
  RESULTS_LIMIT,
  MIN_CHARS,
  CUSTOM_MIN_DROPDOWN_MEDIA,
  SearchTranslationsContext,
  getSearchFromUrl,
} from './searchCommons';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { tokens } from '@lego/core-colors';
import { useSearchProducts } from '@lego/b2b-unicorn-data-access-layer/react';

const searchProductsWrapperStyle = (searchInput, results, resultsShown) =>
  css({
    borderRadius: 5,
    boxShadow:
      resultsShown && (searchInput || (searchInput.length >= MIN_CHARS && results?.length))
        ? '0 0 10px rgba(0,0,0,0.1)'
        : 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      resultsShown && (searchInput || (searchInput.length >= MIN_CHARS && results?.length))
        ? colors.klik.slate100
        : 'transparent',
    position: 'relative',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
    width: 280,
    height: 'auto',
    background: 'transparent',
    [`@media (max-width: ${mediaSizes.klik.md})`]: {
      width: 140,
    },
  });

const searchProductsWrapperResultsShownStyle = css({
  [`@media (max-width: ${mediaSizes.klik.md})`]: {
    '&, *': {
      boxSizing: 'border-box',
    },
    position: 'absolute',
    top: baseSpacing,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    background: colors.white,
    borderWidth: baseSpacing * 2,
    borderStyle: 'solid',
    borderColor: colors.white,
    boxShadow: `0 -${baseSpacing}px 0 ${tokens.color.core.yellow['200']}`,
    zIndex: 400,
    outline: 'none',
  },
});

const searchProductsStyle = css({
  borderBottomLeftRadius: 3,
  borderBottomRightRadius: 3,
  width: '100%',
  backgroundColor: colors.white,
  display: 'flex',
  flexDirection: 'column',
  marginTop: 90,
  overflowX: 'auto',
  scrollSnapType: 'y mandatory',
  maxHeight: `calc(100vh - ${baseSpacing * 19}px)`,
  overscrollBehavior: 'contain',
  [CUSTOM_MIN_DROPDOWN_MEDIA]: {
    marginTop: 80,
    maxHeight: 254,
  },
});

const mobileTitleStyle = (resultsShown) =>
  css({
    display: resultsShown ? 'flex' : 'none',
    justifyContent: 'space-between',
    fontSize: font.size.large,
    fontWeight: font.weight.light,
    height: baseSpacing * 5,
    '> span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    button: {
      outline: 0,
      border: 0,
      margin: 0,
      padding: 0,
      background: 'transparent',
      cursor: 'pointer',
    },
    svg: {
      fill: colors.klik.slate900,
      height: 20,
      width: 20,
      margin: 11,
    },
    [CUSTOM_MIN_DROPDOWN_MEDIA]: {
      display: 'none',
    },
  });

const SearchBox = ({
  closeSearch,
  customerId,
  fetchProductImageUrl,
  locale,
  onSearchFocus,
  searchShown,
}) => {
  const location = useLocation();
  const [searchInput, setSearchInput] = useState('');
  const [resultsShown, setResultsShown] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [error, setError] = useState(false);

  const searchBoxRef = useRef();
  const inputRef = useRef();
  const debounceTimeout = useRef();

  const { search_header_and_placeholder } = useContext(SearchTranslationsContext);

  // SearchInput handlers
  const searchInputOnChangeHandler = (input) => {
    setSearchInput(input);
    setError(false);
  };

  const searchInputOnBlurHandler = (e) => {
    if (!e.relatedTarget || (e.relatedTarget && !searchBoxRef.current?.contains(e.relatedTarget))) {
      setResultsShown(false);
    }
  };

  const searchInputOnFocusHandler = (e) => {
    onSearchFocus();
    setResultsShown(true);
  };

  const {
    data: searchResponse,
    refetch: getSearchProducts,
    error: searchError,
  } = useSearchProducts(customerId);

  useEffect(() => {
    setSearchInput(getSearchFromUrl(location));
  }, [location]);

  useEffect(() => {
    if (searchInput.length >= MIN_CHARS) {
      setResultsLoading(true);
      clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(() => {
        getSearchProducts({
          customerId: customerId,
          query: searchInput,
        });
      }, 500);
    } else {
      clearTimeout(debounceTimeout.current);
    }
  }, [customerId, searchInput, getSearchProducts]);

  useEffect(
    () => () => {
      clearTimeout(debounceTimeout.current);
    },
    []
  );

  useEffect(() => {
    !!searchResponse && setResultsLoading(false);
    searchError && (setError(true), console.error('search error'));
  }, [searchResponse, searchError]);

  const onBlurHandler = (e) => {
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) {
      e.stopPropagation();
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.key === KEYCODE_STRING.ESCAPE) {
      itemOnClickHandler();
    }
  };

  const shownResultsLength = (searchInput, results, resultsLoading) => {
    if (searchInput.length < MIN_CHARS || resultsLoading || !searchResponse) return 0;
    return results.length;
  };

  const itemOnClickHandler = () => {
    closeSearch();
    setSearchInput('');
    inputRef.current?.blur();
  };

  useEffect(() => {
    setResultsShown(searchShown);

    if (!searchShown) {
      itemOnClickHandler();
    }
  }, [searchShown]);

  const content = (
    <Fragment>
      {/* This div is catching all children ENTER keypress */}
      <div
        css={[
          searchProductsWrapperStyle(searchInput, searchResponse?.searchProducts, resultsShown),
          resultsShown && searchProductsWrapperResultsShownStyle,
        ]}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        tabIndex="-1"
        ref={searchBoxRef}
      >
        <div css={mobileTitleStyle(resultsShown)}>
          <span>{search_header_and_placeholder}</span>
          <button
            onClick={closeSearch}
            aria-label="Close product search."
          >
            <Icon type={IconType.CLOSE} />
          </button>
        </div>
        <div css={searchProductsStyle}>
          <SearchInput
            searchInput={searchInput}
            inputOnChangeHandler={searchInputOnChangeHandler}
            inputRef={inputRef}
            closeSearch={closeSearch}
            numberOfResults={searchResponse?.searchProducts?.length}
            fetchProductImageUrl={fetchProductImageUrl}
            onFocus={searchInputOnFocusHandler}
            onBlur={searchInputOnBlurHandler}
            resultsShown={resultsShown}
          />
          <Fragment>
            {resultsShown &&
              shownResultsLength(searchInput, searchResponse?.searchProducts, resultsLoading) > 0 &&
              searchResponse?.searchProducts.slice(0, RESULTS_LIMIT).map((product) => (
                <SearchItem
                  key={product.materialId}
                  product={product}
                  locale={locale}
                  isLengthScrollable={searchResponse?.searchProducts?.length > 4}
                  onClickHandler={itemOnClickHandler}
                  fetchProductImageUrl={fetchProductImageUrl}
                />
              ))}
          </Fragment>
          {resultsShown && searchInput.length > 0 && (
            <SearchFeedback
              loading={searchInput.length < MIN_CHARS || resultsLoading || !searchResponse}
              searchInput={searchInput}
              numberOfResults={
                searchResponse &&
                shownResultsLength(searchInput, searchResponse?.searchProducts, resultsLoading)
              }
              error={error}
            />
          )}
        </div>
      </div>
    </Fragment>
  );

  return content;
};

SearchBox.propTypes = {
  searchShown: PropTypes.bool.isRequired,
  closeSearch: PropTypes.func.isRequired,
  customerId: PropTypes.number,
  fetchProductImageUrl: PropTypes.func,
  locale: PropTypes.string,
};

export default SearchBox;
