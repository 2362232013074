import 'json-bigint-patch';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

window.__DEV__ = process.env.NODE_ENV !== 'production';

import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

if (window.__DEV__) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const root = createRoot(document.getElementById('root')!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
