import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Button, ButtonType, ListItem } from '@lego/b2b-unicorn-shared/ui';
import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import React from 'react';

import { skeletonPlaceholderStyle } from '../../../../styles';
import { imageWrapperStyle, productListRowStyle } from '../styles';

export const SkeletonInvoicedItem: React.FC = () => {
  const { button_add } = useLabels();
  return (
    <ListItem extraCss={productListRowStyle(false)}>
      <div css={imageWrapperStyle}>
        <ProductImage
          src={''}
          alt="box image"
        />
      </div>
      <div css={[skeletonPlaceholderStyle, { height: 14, width: 305 }]} />
      <Button
        disabled={true}
        size={'small'}
        onClick={() => {}}
        type={ButtonType.SECONDARY}
      >
        {button_add}
      </Button>
    </ListItem>
  );
};
