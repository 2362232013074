export const KEYCODE = {
  ESCAPE: 27,
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
};

export const KEYCODE_STRING = {
  BACKSPACE: 'Backspace',
  ESCAPE: 'Escape',
  ENTER: 'Enter',
  NUMPAD_ENTER: 'NumpadEnter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  TAB: 'Tab',
  SPACE: 'Space',
};
