import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ClaimItem, InvoiceOrderItem } from '@lego/b2b-unicorn-shared/components/Claim';
import { claimTypes } from '@lego/b2b-unicorn-shared/components/Claim/validation';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  Button,
  ButtonType,
  Icon,
  IconType,
  ListItem,
  Spacer,
  Typography,
} from '@lego/b2b-unicorn-shared/ui';
import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';
import React from 'react';

import { imageWrapperStyle, productListRowStyle } from '../styles';
import { ClaimItemFormAction } from './ClaimItemFormAction';

const localProductListRowStyle = (isHighlighted: boolean) =>
  css(productListRowStyle(isHighlighted), {
    minHeight: baseSpacing * 8,
    height: 'auto',
    '> button:first-of-type': {
      marginRight: 0,
    },
    '> button:last-of-type': {
      marginRight: baseSpacing,
    },
  });

const infoLineStyle = css({
  display: 'inline-flex',
  '> span': {
    marginRight: baseSpacing / 2,
  },
});

const additionalInfoLineStyle = css(infoLineStyle, {
  display: 'block',
  lineHeight: 1.2,
  '*': { lineHeight: 1.2 },
});

const itemNumberStyle = css({
  width: 50,
  fontFeatureSettings: '"tnum"',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: font.weight.black,
  marginRight: baseSpacing / 2,
});

type ClaimTypes = (typeof claimTypes)[number];
type ClaimTypeTranslations = {
  [K in ClaimTypes]: string;
};
type InvoicedItemProps = {
  invoiceItem: InvoiceOrderItem;
  removeClaimItem: (item: InvoiceOrderItem) => void;
  claimItem?: ClaimItem;
  isMultiplePresent?: boolean;
};
export const InvoicedItem: React.FC<InvoicedItemProps> = ({
  invoiceItem,
  removeClaimItem,
  claimItem,
  isMultiplePresent = false,
}) => {
  const imageUrl = getImageUrl(invoiceItem.materialId, ImageType.MAIN_BOX, 30);
  const { locale } = useSelectedCustomer();
  const {
    claimed_value,
    pieces,
    expected_price_summary,
    order_number,
    price_disputes,
    damaged_goods,
    misc,
    delivery_shortage,
    over_under_sub,
  } = useLabels();
  const claimTypeOptions: ClaimTypeTranslations = {
    PRICE_DISPUTES: price_disputes,
    DAMAGED_GOODS: damaged_goods,
    MISC: misc,
    DELIVERY_SHORTAGE: delivery_shortage,
    OVER_UNDER_SUB: over_under_sub,
  };

  return (
    <ListItem extraCss={localProductListRowStyle(!!claimItem)}>
      <div css={imageWrapperStyle}>
        <ProductImage
          src={imageUrl}
          alt="box image"
        />
      </div>
      <div>
        <div css={infoLineStyle}>
          <div css={itemNumberStyle}>{invoiceItem.itemNumber || '-'}</div>
          <Typography
            weight={'medium'}
            designToken={'text.subdued'}
          >
            {invoiceItem.name}
          </Typography>
          {isMultiplePresent && (
            <>
              <Spacer
                direction={'vertical'}
                multiplier={2}
              />
              <Typography
                weight={'medium'}
                designToken={'text.informative'}
              >
                {order_number}:&nbsp;{invoiceItem.orderNumber}
              </Typography>
            </>
          )}
        </div>
        {claimItem && (
          <div css={additionalInfoLineStyle}>
            <Typography designToken={'text.muted'}>
              {claimTypeOptions[claimItem.claimType as ClaimTypes]}
            </Typography>
            {claimItem.claimType !== 'PRICE_DISPUTES' ? (
              <Typography designToken={'text.muted'}>
                - {pieces}:&nbsp;{claimItem.pieces}
              </Typography>
            ) : (
              <Typography designToken={'text.muted'}>
                - {expected_price_summary}:&nbsp;
                <Price locale={locale}>{claimItem.expectedPrice}</Price>
              </Typography>
            )}
            {claimItem.comment && (
              <Typography designToken={'text.muted'}>
                - <Icon type={IconType.SUBTITLE} />
              </Typography>
            )}
            {claimItem.documentationFiles.length > 0 && (
              <Typography designToken={'text.muted'}>
                - {claimItem.documentationFiles.length} <Icon type={IconType.IMAGE} />
              </Typography>
            )}
            <Typography designToken={'text.muted'}>
              - {claimed_value}:&nbsp;<Price locale={locale}>{claimItem.totalClaimPrice}</Price>
            </Typography>
          </div>
        )}
      </div>
      <ClaimItemFormAction invoiceItem={invoiceItem} />
      <Button
        disabled={!claimItem}
        size={'small'}
        onClick={() => removeClaimItem(invoiceItem)}
        type={ButtonType.GHOST}
      >
        {claimItem ? <Icon type={IconType.DELETE} /> : null}
      </Button>
    </ListItem>
  );
};
