import { gql } from '@apollo/client';

export const CREATE_BRICK_BANK_ORDER = gql`
  mutation createBrickBankOrder(
    $customerId: Int!
    $shipToId: Int!
    $requestedDeliveryDate: String!
    $orderName: String
    $brickBankPaymentInfo: BrickBankPaymentInfoInput!
    $cartReference: CartReferenceInput!
  ) {
    createBrickBankOrder(
      customerId: $customerId
      shipToId: $shipToId
      requestedDeliveryDate: $requestedDeliveryDate
      orderName: $orderName
      brickBankPaymentInfo: $brickBankPaymentInfo
      cartReference: $cartReference
    ) {
      orderNumber
    }
  }
`;
