import { gql } from '@apollo/client';

export const FRAGMENT_CART_ITEM = gql`
  fragment cartItem on CartItem {
    ...minimumCartItem
    product {
      ... on Box {
        itemNumber
        recommendedRetailPrice {
          amount
          currency
        }
      }
      name
      theme
    }
  }
`;
