interface ParsedDate {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
}

const ISO9075dateMatcher = new RegExp(/^(\d{4})-([01]\d)-([0-3]\d)$/);
const ISO8601dateMatcher = new RegExp(
  /(^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$)|(^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)$)|(^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)$)/
);
export const parseDateStringToLocalDate = (dateStr: string): ParsedDate => {
  const isISO9075 = ISO9075dateMatcher.exec(dateStr);
  if (isISO9075) {
    return {
      year: parseInt(isISO9075[1]),
      month: parseInt(isISO9075[2]),
      day: parseInt(isISO9075[3]),
      hour: 0,
      minute: 0,
      second: 0,
    };
  }

  const isISO8601 = ISO8601dateMatcher.exec(dateStr);
  if (isISO8601) {
    const dateFromStr = new Date(dateStr);
    return {
      year: dateFromStr.getFullYear(),
      month: dateFromStr.getMonth() + 1,
      day: dateFromStr.getDate(),
      hour: dateFromStr.getHours(),
      minute: dateFromStr.getMinutes(),
      second: dateFromStr.getSeconds(),
    };
  }

  throw Error(`Unrecognized dateStr: '${dateStr}'`);
};
