export const font = {
  family: 'Cera Pro',
  lineHeight: 1.5,
  size: {
    tiny: 11,
    small: 14,
    small_mobile: 16,
    normal: 16,
    medium: 18,
    large: 28,
  },
  weight: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
};

export const mediaSizes = {
  small: 576,
  medium: 992,
  large: 1440,
  klik: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
  },
};

export const media = {
  small: `@media (min-width: ${mediaSizes.small}px)`,
  medium: `@media (min-width: ${mediaSizes.medium}px)`,
  large: `@media (min-width: ${mediaSizes.large}px)`,
  klik: {
    sm: `@media (min-width: ${mediaSizes.klik.sm})`,
    md: `@media (min-width: ${mediaSizes.klik.md})`,
    lg: `@media (min-width: ${mediaSizes.klik.lg})`,
    xl: `@media (min-width: ${mediaSizes.klik.xl})`,
  },
};

export const baseSpacing = 8;
export const maxDesktopWidth = 1440;
