import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { baseSpacing, designToken, media } from '../theme';
import { tableRowCellStyle } from './styles';

type TableCellNameProps = {
  children: React.ReactNode;
  colName?: string;
};

const tableCellNameStyle = css(tableRowCellStyle(), {
  paddingTop: baseSpacing * 2.5,
  paddingBottom: baseSpacing * 2.5,
  paddingLeft: `calc(30% + ${baseSpacing * 2}px)`,
  paddingRight: 40,
  marginBottom: baseSpacing,
  borderTopColor: tokens.color.core.blue[200],
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderBottomColor: designToken.border.subdued,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  [media.small]: {
    paddingRight: 100,
  },
  [media.medium]: {
    minWidth: 200,
    padding: 0,
    margin: 0,
    borderTop: 'none',
  },

  '> a': {
    textDecoration: 'none',
    color: designToken.interactive.default,
    marginRight: baseSpacing / 2,
    '&:hover': {
      color: designToken.interactive.hover,
    },
    '&:focus': {
      color: designToken.interactive.active,
      outline: 'none',
      textDecorationLine: 'underline',
      textUnderlineOffset: 1,
    },
  },
  '> span': {
    transform: 'translateY(4px)',
  },
});

export const TableCellName: React.FC<TableCellNameProps> = ({ children, colName }) => {
  return (
    <td
      data-col={colName}
      css={tableCellNameStyle}
    >
      {children}
    </td>
  );
};
