import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';

export const pagePaddingStyle = {
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
};

export const pagePaddingDesktopStyle = {
  [media.small]: {
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
  },
};

export const commonBodyStyle = {
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: baseSpacing * 4,
  maxWidth: maxDesktopWidth,
  color: designToken.text.default,
  fontSize: font.size.small,
  [media.medium]: {
    marginBottom: 120,
  },
};

export const commonSubHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 80,
  fontSize: font.size.medium,
  fontWeight: font.weight.bold,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: baseSpacing * 2,
  marginRight: baseSpacing * 2,
  [media.small]: {
    fontSize: font.size.large,
    marginLeft: baseSpacing * 4,
    marginRight: baseSpacing * 4,
  },
  [media.medium]: {
    margin: 0,
  },
};

export const bigTextStyle = css({
  fontSize: font.size.medium,
  fontWeight: font.weight.bold,
  margin: 0,
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 3,
  [media.medium]: {
    fontSize: font.size.large,
    paddingTop: 0,
    paddingBottom: 0,
  },
  '> small': {
    display: 'block',
    fontSize: font.size.small,
    fontWeight: font.weight.medium,
    color: designToken.error.default,
    paddingTop: baseSpacing * 3,
  },
});

export const bodyStyle = css(commonBodyStyle, {
  display: 'flex',
  flexDirection: 'column',
  '> div:first-of-type': {
    order: 1,
  },
  '> div:nth-of-type(2)': {
    order: 3,
    marginBottom: baseSpacing * 3,
  },
  '> div:nth-of-type(3)': {
    order: 2,
  },
  '> div:nth-of-type(4)': {
    order: 4,
  },
  [media.medium]: {
    display: 'block',
    '> div:nth-of-type(2)': {
      order: 3,
      marginBottom: 0,
    },
  },
});

export const borderTopStyle = css({
  borderTopWidth: 1,
  borderTopColor: colors.klik.slate100,
  borderTopStyle: 'solid',
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,
});

export const submitInfoWrapperStyle = css({
  position: 'relative',
  marginBottom: baseSpacing,
  [media.medium]: {
    marginBottom: 0,
  },
});

export const submitInfoStyle = css({
  position: 'absolute',
  width: '100%',
  maxWidth: '100%',
  fontSize: font.size.tiny,
  textAlign: 'right',
  top: baseSpacing,
  [media.medium]: {
    width: 320,
    right: baseSpacing,
  },
});

export const submitInfoWarningStyle = css(submitInfoStyle, {
  color: designToken.error.emphasis,
});

export const formWrapperStyle = css({
  paddingTop: baseSpacing * 4,
  paddingBottom: baseSpacing * 5,
  [media.medium]: {
    display: 'flex',
    justifyContent: 'space-between',
    '> div, > section': {
      width: '25%',
    },
  },
});

export const actionsStyle = css({
  width: '100%',
  '> button': {
    marginBottom: baseSpacing,
  },
  '> span': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 1.3,
    fontSize: font.size.tiny,
    '> svg': {
      minHeight: baseSpacing * 3,
      minWidth: baseSpacing * 3,
      height: baseSpacing * 3,
      width: baseSpacing * 3,
      marginRight: baseSpacing,
    },
  },
  [media.medium]: {
    width: 'calc(100% / 4)',
    '> span': {
      fontSize: font.size.small,
      '> svg': {
        minHeight: baseSpacing * 4,
        minWidth: baseSpacing * 4,
        height: baseSpacing * 4,
        width: baseSpacing * 4,
      },
    },
  },
});

export const submittingAnimationStyle = css({
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  color: tokens.color.core.azur[800],
  columnGap: baseSpacing / 2,
  alignItems: 'baseline',
});

export const mobileCollapseStyle = (isOpen: boolean) =>
  css({
    display: isOpen ? 'block' : 'none',
    [media.medium]: {
      display: 'block',
    },
  });

export const contentStyle = css({
  [media.medium]: {
    marginTop: baseSpacing * 3,
  },
});
