import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql/index';

import { ContextError, ErrorType } from '../context/ContextAbstract';

const isNotFoundError = (error: Readonly<GraphQLError>): boolean => {
  return error.extensions?.code === 'NOT_FOUND';
};

/**
 * Checks GraphQL error(s) to see if it is a not found error, and returns the path(s) of the error.
 *
 * @param error Error(s) to check.
 * @returns The location of the error, or false if it is not a not found error.
 */
export const hasNotFoundError = (error: ContextError): readonly (string | number)[] | false => {
  // If the error is an ApolloError, check if it is a GraphQLError and return the path if it is a not found error.
  if (error instanceof ApolloError) {
    return hasNotFoundError(error.graphQLErrors);
  }

  // If the error is not a GraphQLError, or if it is an array of errors that are not GraphQLErrors, return false.
  if (Array.isArray(error)) {
    if (error.every((e) => !Object.hasOwn(e, 'extensions'))) {
      return false;
    }

    return error.reduce((acc: (string | number)[], e: ErrorType) => {
      if (!Object.hasOwn(e, 'extensions')) {
        return acc;
      }

      if (!Object.hasOwn(e, 'path')) {
        throw Error("Malformed GraphQL error, 'path' is missing.");
      }

      if (isNotFoundError(e as GraphQLError)) {
        const graphQLError = e as GraphQLError;
        return [...acc, ...graphQLError.path!];
      }

      return acc;
    }, []);
  }

  if (!error || !Object.hasOwn(error, 'extensions')) {
    return false;
  }

  if (!Object.hasOwn(error, 'path')) {
    throw Error("Malformed GraphQL error, 'path' is missing.");
  }

  if (isNotFoundError(error as GraphQLError)) {
    return (error as GraphQLError).path!;
  }

  return false;
};
