import { css, keyframes } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';

import { TILE_WIDTH } from '../constants';

const pulse = keyframes`
  100% { background-position: 300px 0; }
`;

export const skeletonProductTileStyle = css({
  position: 'relative',
  width: TILE_WIDTH,
  height: 460,
  padding: baseSpacing,
  paddingBottom: 10,
  textAlign: 'left',
  backgroundColor: colors.white,
  borderRadius: 8,
  margin: 0,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    width: TILE_WIDTH,
    height: 444,
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${colors.white} 50%, ${designToken.transparent.default} 100%)`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-300px 0',
    animationName: pulse,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  },
});

export const skeletonStatusStyle = css({
  width: 70,
  height: baseSpacing * 3,
  borderRadius: baseSpacing * 2,
  backgroundColor: designToken.skeleton.secondary,
  marginTop: 2,
  marginLeft: 2,
});

export const skeletonImageStyle = css({
  height: 156,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 12,
  marginBottom: 20,
  '> svg': {
    height: 156,
    width: 'auto',
  },
});

export const skeletonPlaceholderStyle = css({
  backgroundColor: designToken.skeleton.default,
  borderRadius: 8,
});

export const skeletonBottomContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
});

export const skeletonPlaceholderButtonStyle = css({
  height: 42,
  width: '50%',
  backgroundColor: designToken.skeleton.primary,
  zIndex: 2,
  borderRadius: 4,
});
