import Bowser from 'bowser';
import type { Observer } from 'rxjs';
import {
  from,
  interval,
  map,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { fromFetch } from 'rxjs/internal/observable/dom/fetch';

import type { AutoUpdateDataFile, AutoUpdateOptions, NavigationEvent } from './types';

const autoUpdateOptionsDefaults: AutoUpdateOptions = {
  updateFilePath: '/update-checker.json',
  interval: 60000,
  forceFallback: false,
};

export class UpdateChecker {
  private static _instance: UpdateChecker | null = null;

  private readonly _currentVersion: string;
  private _opts: AutoUpdateOptions;
  private _obs: Observable<boolean> | null = null;
  private _terminationSubject = new Subject<true>();

  constructor(currentVersion: string, opts?: AutoUpdateOptions) {
    this._currentVersion = currentVersion;
    this._opts = { ...autoUpdateOptionsDefaults, ...opts };
  }

  public static getInstance(currentVersion: string, opts?: AutoUpdateOptions) {
    if (!UpdateChecker._instance) {
      UpdateChecker._instance = new UpdateChecker(currentVersion, opts);
    }

    return UpdateChecker._instance;
  }

  private doFetch() {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store',
    });
    const req = new Request(this._opts.updateFilePath, {
      method: 'GET',
      mode: 'same-origin',
      headers,
    });

    return fromFetch(req).pipe(
      switchMap((res) => {
        if (res.ok) {
          return from(res.json() as Promise<AutoUpdateDataFile>);
        }

        throw Error(`Error ${res.status}`);
      })
    );
  }

  private isNewVersion(body: AutoUpdateDataFile) {
    return body.version !== this._currentVersion;
  }

  private createObservable(): Observable<boolean> {
    return interval(this._opts.interval).pipe(
      switchMap(this.doFetch.bind(this)),
      map<AutoUpdateDataFile, boolean>(this.isNewVersion.bind(this)),
      tap((isNewVersion) => {
        if (!isNewVersion) {
          return;
        }

        this.setupNavigationEventListener.bind(this)();
        this._terminationSubject.next(true);
      }),
      takeUntil(this._terminationSubject),
      shareReplay(1)
    );
  }

  private setupNavigationEventListener() {
    const browserSupported = Bowser.getParser(window.navigator.userAgent).satisfies({
      chrome: '>=105',
      edge: '>=105',
    });

    if (!browserSupported || this._opts.forceFallback) {
      this.fallbackHistoryPolyfill();
      return;
    }

    window.navigation.addEventListener('navigate', this.navigationEventListener.bind(this));
  }

  private navigationEventListener(event: NavigationEvent) {
    if (!event.canIntercept || !event.destination.sameDocument) {
      return;
    }

    const state = event.destination.getState();

    // If the destination has a state associated with it, we cannot reload the page
    if (state) {
      return;
    }

    event.intercept({
      handler: async () => {
        window.location.assign(event.destination.url);
      },
    });
  }

  /**
   * This polyfill is needed because the `navigate` event is not supported in all browsers.
   *
   * NB: This does not support the user clicking the back button in their browser, which the Navigation API does.
   * @private
   */
  private fallbackHistoryPolyfill() {
    const history = window.history;
    const originalPushState = history.pushState.bind(history);
    const originalReplaceState = history.replaceState.bind(history);

    history.pushState = function pushState(
      data: { key?: string; state?: unknown } | null,
      unused: string,
      url?: string | URL | null
    ) {
      if ((data && data.state) || !url) {
        return originalPushState(data, unused, url);
      }

      window.location.assign(url);
    };

    history.replaceState = function replaceState(
      data: { key?: string; state?: unknown } | null,
      unused: string,
      url?: string | URL | null
    ) {
      if ((data && data.state) || !url) {
        return originalReplaceState(data, unused, url);
      }

      window.location.assign(url);
    };
  }

  public start() {
    if (this._obs) {
      return this;
    }

    this._obs = this.createObservable();

    return this;
  }

  public subscribe(observer?: Partial<Observer<boolean>>) {
    if (!this._obs) {
      throw Error(`Update checker not started!`);
    }

    return this._obs.subscribe(observer);
  }
}
