import { css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo } from 'react';

import { baseSpacing, designToken } from '../theme';
import { Menu } from './Menu';

type MenuItemProps = {
  children: React.ReactNode;
  disabled?: boolean;
};

type MenuItemCleanProps = MenuItemProps & {
  onClick?: never;
  onClickAsync?: never;
  onAsyncInFlight?: never;
  onAsyncCompleted?: never;
  onAsyncError?: never;
};

type MenuItemSyncProps = MenuItemProps & {
  onClick: (e?: React.MouseEvent<HTMLLIElement>) => void;
  onClickAsync?: never;
  onAsyncInFlight?: never;
  onAsyncCompleted?: never;
  onAsyncError?: never;
};

type MenuItemAsyncProps<T = void> = MenuItemProps & {
  onClick?: never;
  onClickAsync: (e?: React.MouseEvent<HTMLLIElement>) => Promise<T>;
  onAsyncInFlight?: () => void;
  onAsyncCompleted?: (result: T) => void;
  onAsyncError?: (e: Error) => void;
};

type MenuItemConditionalProps<T = void> =
  | MenuItemCleanProps
  | MenuItemSyncProps
  | MenuItemAsyncProps<T>;

const listItemStyle = (disabled: boolean = false) =>
  css({
    cursor: disabled ? 'default' : 'pointer',
    paddingTop: baseSpacing,
    paddingBottom: baseSpacing,
    paddingLeft: baseSpacing * 2,
    paddingRight: baseSpacing * 2,
    position: 'relative',

    color: disabled ? designToken.text.muted : undefined,

    '&:hover': {
      color: disabled ? undefined : designToken.interactive.hover,
    },
  });

export const MenuItem = <T = unknown,>({
  children,
  onClick,
  onClickAsync,
  onAsyncInFlight,
  onAsyncError,
  onAsyncCompleted,
  disabled,
}: MenuItemConditionalProps<T>) => {
  const itemRef = React.useRef<HTMLLIElement>(null);
  const hasSubmenu = useMemo(
    () =>
      React.Children.toArray(children).some(
        (child) => React.isValidElement(child) && child.type === Menu
      ),
    [children]
  );
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!(event.target instanceof Node)) {
        return;
      }

      if (!itemRef.current?.contains(event.target)) {
        setSubMenuOpen(false);
      }
    };

    if (subMenuOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [subMenuOpen]);

  const childrenWithMarkedSubmenu = useMemo(
    () =>
      React.Children.toArray(children).map((child) => {
        if (React.isValidElement(child)) {
          if (child.type === Menu) {
            return React.cloneElement(
              child as React.ReactElement<React.ComponentProps<typeof Menu>>,
              {
                open: subMenuOpen,
                isSubMenu: true,
              }
            );
          }
        }

        return child;
      }),
    [children, subMenuOpen]
  );

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      if (disabled) {
        e.preventDefault();
        return;
      }

      if (hasSubmenu) {
        setSubMenuOpen(!subMenuOpen);
      }

      onClick && onClick(e);

      if (onClickAsync) {
        onAsyncInFlight && onAsyncInFlight();
        onClickAsync(e).then(onAsyncCompleted).catch(onAsyncError);
      }
    },
    [
      disabled,
      hasSubmenu,
      onAsyncCompleted,
      onAsyncError,
      onAsyncInFlight,
      onClick,
      onClickAsync,
      subMenuOpen,
    ]
  );

  return (
    <li
      css={listItemStyle(disabled)}
      onClick={handleOnClick}
      ref={itemRef}
    >
      {childrenWithMarkedSubmenu}
    </li>
  );
};
