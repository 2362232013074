import { css } from '@emotion/react';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { DialogPopup, DialogWrapper } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, font } from '@lego/b2b-unicorn-ui-constants';
import { tokens } from '@lego/core-colors';
import React from 'react';

import { adjustedWidthStyle } from '../../styles';

const wrapperStyle = (backgroundColor: string, withBorder: boolean) =>
  css({
    width: '100%',
    height: 340,
    paddingTop: baseSpacing / 2,
    backgroundColor: backgroundColor,
    position: 'relative',
    borderTopStyle: 'solid',
    borderTopWidth: withBorder ? 1 : 0,
    borderTopColor: tokens.color.core.blue[200],
  });

const headerStyle = css(adjustedWidthStyle, {
  color: designToken.primary.default,
  marginRight: 'auto',
  marginLeft: 'auto',
  fontSize: font.size.large,
  fontWeight: font.weight.normal,
  marginTop: 0,
});

const infoStyle = css({
  width: baseSpacing * 3,
  height: baseSpacing * 3,
  display: 'inline-block',
  marginLeft: baseSpacing,
  transform: 'translateY(3px)',
  '> svg': {
    fill: designToken.primary.default,
    transitionDuration: '125ms',
    transitionProperty: 'fill',
    transitionTimingFunction: 'ease-out',
  },
  p: {
    fontSize: font.size.small,
    marginBlockStart: baseSpacing,
    marginBlockEnd: baseSpacing,
  },
  '>div[role=dialog]': {
    maxWidth: 240,
    left: -50,
    right: 'auto',
    '&:after': {
      left: 58,
      right: 'auto',
    },
  },
});

type RecommendationsContainerProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  withBorder?: boolean;
  header?: string;
  info?: string;
};

export const RecommendationsContainer: React.FC<RecommendationsContainerProps> = ({
  children,
  backgroundColor = designToken.background.offWhite,
  withBorder = true,
  header,
  info,
}) => {
  return (
    <section css={wrapperStyle(backgroundColor, withBorder)}>
      <h2 css={headerStyle}>
        {header}
        {info && (
          <DialogWrapper styles={infoStyle}>
            <Icon type={IconType.QUESTION_OUTLINE} />
            <DialogPopup icon>
              <p>{info}</p>
            </DialogPopup>
          </DialogWrapper>
        )}
      </h2>
      {children}
    </section>
  );
};
