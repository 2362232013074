import { gql } from '@apollo/client';

export const FRAGMENT_MINIMUM_CART_ITEM = gql`
  fragment minimumCartItem on CartItem {
    quantity
    product {
      name
      materialId
      piecesPerCasePack
      price {
        currency
        grossPrice
        estimatedNetInvoicedPrice
      }
    }
  }
`;
