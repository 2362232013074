import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, designToken, font, media } from '../theme';
import { tableRowCellStyle } from './styles';

type TableCellQuantityButtonProps = {
  children: React.ReactNode;
};

const tableCellQuantityButtonStyle = css(tableRowCellStyle(), {
  '> button': {
    fontSize: font.size.small,
    color: designToken.interactive.default,
    width: 154,
    lineHeight: 1.1,
    '> svg': {
      height: baseSpacing * 2,
      minHeight: baseSpacing * 2,
      width: baseSpacing * 2,
      minWidth: baseSpacing * 2,
      marginRight: baseSpacing,
      fill: designToken.interactive.default,
    },
  },
  '&:after': {
    display: 'none !important',
  },
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 3,
  [media.medium]: {
    width: 170,
  },
});

export const TableCellQuantityButton: React.FC<TableCellQuantityButtonProps> = ({ children }) => {
  return <td css={tableCellQuantityButtonStyle}>{children}</td>;
};
