import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  CartReferenceCartType,
  LaunchProduct,
  UpdateCartInMultipleWindowsItems,
} from '@lego/b2b-unicorn-data-access-layer';
import { UpdateCartInMultipleWindowsResult } from '@lego/b2b-unicorn-data-access-layer/context/LaunchWindows/LaunchWindowsDataContext';
import { UpdateCartInWindowMutation } from '@lego/b2b-unicorn-data-access-layer/generated-types/graphql';
import {
  useDataAccessLayer,
  useLaunchWindowsSummary,
} from '@lego/b2b-unicorn-data-access-layer/react';
import {
  BulkQuickAddContext,
  FinalRowItem,
  RowStatusReason,
} from '@lego/b2b-unicorn-shared/components/BulkQuickAdd/types';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { ExtractElementType } from '@lego/b2b-unicorn-shared/helpers';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { NotificationType, useNotifications } from '@lego/b2b-unicorn-ui-components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { pasteEventListener } from '../../../shared/Utils/functions/pasteEventListener';
import {
  SuccessfulBulkCartUpdateSummary,
  SuccessfulBulkCartUpdateSummarySubtable,
} from '../../pages/launch/components/LaunchWindowsOverview';
import { BaseBulkQuickAdd } from './BaseBulkQuickAdd';

const launchWindowMultipleConsumerPasteEventListener = (event: HTMLElementEventMap['paste']) =>
  pasteEventListener(event, BulkQuickAddContext.LAUNCH_MULTIPLE);

type LaunchProductWithCartReference = LaunchProduct & {
  cartReference: {
    cartType: CartReferenceCartType.Launch;
    launch: {
      month: number;
      year: number;
    };
  };
};

type SuccessfulCartUpdateItems = NonNullable<
  ExtractElementType<Awaited<UpdateCartInMultipleWindowsResult>>['data']
>['updateCartWithMultipleItems'][];

type MultipleLaunchWindowBulkQuickAddProps = {
  open: boolean;
  closeModal: () => void;
  onHasPreviousSession: () => void;
  onCartUpdated: () => void;
};

export const MultipleLaunchWindowBulkQuickAdd: React.FC<MultipleLaunchWindowBulkQuickAddProps> = ({
  open,
  onHasPreviousSession,
  closeModal,
  onCartUpdated,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const {
    replenish_bulk_quick_add_modal_header,
    button_cancel,
    button_add_to_order,
    snackbar_excel_upload_add_error,
  } = useLabels();
  const { trackEvent } = useAnalytics();
  const storageKey = `bulkQuickAddMultiple-${selectedCustomer.id}`;
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const submitErrorRef = useRef<boolean>(false);

  const { addSnackbar, snackbar } = useNotifications();
  const dataAccessLayer = useDataAccessLayer();

  // Internal state
  const [items, setItems] = useState<FinalRowItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [successfulCartUpdates, setSuccessfulCartUpdates] = useState<SuccessfulCartUpdateItems>([]);

  const { data: launchWindowSummaryResponse, loading: launchWindowsSummaryIsLoading } =
    useLaunchWindowsSummary(selectedCustomer.id, {
      cacheKey: `launchWindowsOverview-${selectedCustomer.id}`,
      fetchPolicy: 'cache-first',
    });

  const openLaunchWindows = useMemo(() => {
    if (!launchWindowSummaryResponse) {
      return [];
    }

    return launchWindowSummaryResponse.launchWindows.openWindows;
  }, [launchWindowSummaryResponse]);

  const launchWindowsProductsWithCartReference = useMemo(() => {
    if (openLaunchWindows.length === 0) {
      return [];
    }

    return openLaunchWindows.reduce<LaunchProductWithCartReference[]>((result, window) => {
      const windowProducts = window.products.map<LaunchProductWithCartReference>((product) => {
        return {
          ...product,
          cartReference: {
            cartType: CartReferenceCartType.Launch,
            launch: {
              month: window.month,
              year: window.year,
            },
          },
        } as LaunchProductWithCartReference;
      });
      return [...result, ...windowProducts];
    }, []);
  }, [openLaunchWindows]);

  useEffect(() => {
    if (launchWindowsProductsWithCartReference.length > 0) {
      setIsLoading(false);
    }
  }, [launchWindowsProductsWithCartReference]);

  useEffect(() => {
    if (open) {
      document.addEventListener('paste', launchWindowMultipleConsumerPasteEventListener);
    }

    if (!open) {
      setSuccessfulCartUpdates([]);
    }

    return () => {
      document.removeEventListener('paste', launchWindowMultipleConsumerPasteEventListener);
    };
  }, [open]);

  const handleOnCancelClick = useCallback(() => {
    sessionStorage.removeItem(storageKey);
    submitErrorRef.current = false;
    closeModal();
  }, [closeModal, storageKey]);

  const handleOnSuccessfulCartUpdateClose = useCallback(() => {
    setSuccessfulCartUpdates([]);
    sessionStorage.removeItem(storageKey);
    closeModal();
  }, [closeModal, storageKey]);

  const validItemsByLaunchWindow = useMemo<UpdateCartInMultipleWindowsItems>(() => {
    return items.reduce<UpdateCartInMultipleWindowsItems>((groupsByLaunch, item) => {
      if (!item.rowStatusReason || !item.product || item.quantity < 1) {
        return groupsByLaunch;
      }

      const productWithCartReference = launchWindowsProductsWithCartReference.find(
        (product) => product.materialId === item.product?.materialId
      );
      if (!productWithCartReference) {
        return groupsByLaunch;
      }

      const updateCartItem = {
        quantity: item.quantity,
        materialId: productWithCartReference.materialId,
      };

      const groupIndex = groupsByLaunch.findIndex(
        (i) =>
          i.cartReference.launch.month === productWithCartReference.cartReference.launch.month &&
          i.cartReference.launch.year === productWithCartReference.cartReference.launch.year
      );

      if (groupIndex === -1) {
        groupsByLaunch.push({
          cartReference: productWithCartReference.cartReference,
          items: [updateCartItem],
        });
      } else {
        if (!Array.isArray(groupsByLaunch[groupIndex].items)) {
          groupsByLaunch[groupIndex].items = [];
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - TS is not able to infer that the items array is defined
        groupsByLaunch[groupIndex].items.push(updateCartItem);
      }

      return groupsByLaunch;
    }, []);
  }, [items, launchWindowsProductsWithCartReference]);

  const handleOnAddToCartClick = useCallback(() => {
    setIsLoading(true);
    submitErrorRef.current = false;
    const numberOfItemsAdded = validItemsByLaunchWindow.reduce(
      (acc, group) => acc + (Array.isArray(group.items) ? group.items.length : 1),
      0
    );
    trackEvent({
      event: 'addToCartFromBulkQuickAddLaunchWindowMultiple',
      name: `Adding ${numberOfItemsAdded} items to cart`,
      value: numberOfItemsAdded,
    });

    const cartUpdatesPromise = dataAccessLayer.launchWindows.updateCartInMultipleWindows(
      selectedCustomer.id,
      validItemsByLaunchWindow
    );
    cartUpdatesPromise
      .then((results) => {
        if (results.some((result) => result.errors)) {
          addSnackbar({
            type: NotificationType.WARNING,
            content: snackbar_excel_upload_add_error,
            showDismissButton: true,
            isStacked: !!snackbar,
          });
          submitErrorRef.current = true;
        }

        const items: UpdateCartInWindowMutation['updateCartWithMultipleItems'][] = [];
        results.forEach((result) => {
          if (!result.data) {
            return;
          }

          items.push({
            ...result.data.updateCartWithMultipleItems,
          });
        });
        setSuccessfulCartUpdates(items);
        onCartUpdated();
      })
      .catch(() => {
        addButtonRef.current?.blur();
        submitErrorRef.current = true;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    dataAccessLayer.launchWindows,
    selectedCustomer.id,
    snackbar,
    snackbar_excel_upload_add_error,
    validItemsByLaunchWindow,
    onCartUpdated,
  ]);

  const isAddToCartDisabled =
    validItemsByLaunchWindow.length === 0 ||
    items.some((item) => item.rowStatusReason !== RowStatusReason.Valid || item.quantity < 1);

  return (
    <>
      <BaseBulkQuickAdd
        context={BulkQuickAddContext.LAUNCH_MULTIPLE}
        sessionStorageKey={storageKey}
        onHasPreviousSession={onHasPreviousSession}
        open={open}
        products={launchWindowsProductsWithCartReference}
        modalHeaderContent={replenish_bulk_quick_add_modal_header}
        isLoading={launchWindowsSummaryIsLoading || isLoading}
        submittingError={submitErrorRef.current}
        addToCartDisabled={isAddToCartDisabled}
        onItemsChange={setItems}
      >
        <Button
          type={ButtonType.SECONDARY}
          onClick={handleOnCancelClick}
        >
          {button_cancel}
        </Button>
        <Button
          ref={addButtonRef}
          onClick={handleOnAddToCartClick}
          disabled={isAddToCartDisabled}
          warning={submitErrorRef.current}
        >
          {button_add_to_order}
        </Button>
      </BaseBulkQuickAdd>
      {successfulCartUpdates.length > 0 && (
        <SuccessfulBulkCartUpdateSummary onClose={handleOnSuccessfulCartUpdateClose}>
          {successfulCartUpdates.map((window) => {
            return (
              <SuccessfulBulkCartUpdateSummarySubtable
                items={window.items}
                key={`${window.cartReference.cartLaunchYear}-${window.cartReference.cartLaunchMonth}`}
                month={window.cartReference.cartLaunchMonth!}
                year={window.cartReference.cartLaunchYear!}
              />
            );
          })}
        </SuccessfulBulkCartUpdateSummary>
      )}
    </>
  );
};
