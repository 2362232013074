import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import {
  useDataAccessLayer,
  useTermsOfSalesContent,
} from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { AnimatedDots, Button, Checkbox } from '@lego/b2b-unicorn-shared/ui';
import { SimpleLayout } from '@lego/b2b-unicorn-shared/ui/layouts';
import { InlineNotification, NotificationType } from '@lego/b2b-unicorn-ui-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  buttonStyle,
  checkboxLabelStyle,
  footerStyle,
  headerStyle,
  labelStyle,
  scrollBox,
  scrollBoxContainer,
  subHeaderStyle,
} from './TermsOfSalesPageStyles';

export const TermsOfSalesPage = () => {
  const [termsCheckboxChecked, setTermsCheckboxChecked] = useState(false);
  const [failureCount, setFailureCount] = useState(0);

  const globalLoaderId = useRef<string>();

  const { id: customerId, name, city } = useSelectedCustomer();
  const { terms_of_sales_acceptance_error } = useLabels();
  const { data: contentData, loading: contentLoading } = useTermsOfSalesContent(customerId);
  const [acceptTermsOfSalesStatus, setAcceptTermsOfSalesStatus] = useState({
    loading: false,
    error: null,
  });

  const { bootstrap } = useDataAccessLayer();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GlobalLoadingService.remove((window as any).firstLoaderId);
  }, []);

  useEffect(() => {
    if (contentLoading && !globalLoaderId.current) {
      globalLoaderId.current = GlobalLoadingService.add('term-of-sales');
    }
    if (!contentLoading && globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
      globalLoaderId.current = undefined;
    }
  }, [contentLoading]);

  const acceptTermsOfSales = useCallback(() => {
    setAcceptTermsOfSalesStatus((prev) => ({ ...prev, loading: true }));
    bootstrap
      .acceptTermsOfSalesForCustomer(customerId, contentData?.getTermsOfSales.version || '', true)
      .then(() => {
        setAcceptTermsOfSalesStatus((prev) => ({ ...prev, loading: false }));
      })
      .catch((e) => {
        setAcceptTermsOfSalesStatus({ loading: false, error: e });
      });
  }, [bootstrap, contentData, customerId]);

  useEffect(() => {
    if (acceptTermsOfSalesStatus.error) {
      setFailureCount(failureCount + 1);
    }
    if (acceptTermsOfSalesStatus.error && failureCount < 1) {
      acceptTermsOfSales();
    }
  }, [acceptTermsOfSales, acceptTermsOfSalesStatus.error, failureCount]);

  return (
    <SimpleLayout>
      {contentData && (
        <>
          <h1
            css={headerStyle}
            data-e2e="termsOfSalesHeader"
          >
            {contentData?.getTermsOfSales.title}
          </h1>
          <h2 css={subHeaderStyle}>
            {`${customerId} - ${name} `}
            <span> - {city}</span>
          </h2>
          <div css={scrollBoxContainer}>
            <div
              css={scrollBox}
              dangerouslySetInnerHTML={{
                __html: contentData?.getTermsOfSales.description || '',
              }}
            />
          </div>
          <div css={footerStyle}>
            <label
              htmlFor="acceptTermsCheckbox"
              css={labelStyle}
            >
              <Checkbox
                checked={termsCheckboxChecked}
                id="acceptTermsCheckbox"
                name="acceptTermsCheckbox"
                onChange={() => setTermsCheckboxChecked(!termsCheckboxChecked)}
              />
              <span css={checkboxLabelStyle}>{contentData?.getTermsOfSales.checkboxText}</span>
            </label>
            <div css={buttonStyle}>
              <Button
                size="medium"
                disabled={!termsCheckboxChecked || acceptTermsOfSalesStatus.loading}
                onClick={acceptTermsOfSales}
              >
                {acceptTermsOfSalesStatus.loading ? (
                  <AnimatedDots />
                ) : (
                  contentData?.getTermsOfSales.buttonText || 'Continue'
                )}
              </Button>
              {acceptTermsOfSalesStatus.error &&
                failureCount > 1 &&
                !acceptTermsOfSalesStatus.loading && (
                  <InlineNotification
                    message={terms_of_sales_acceptance_error}
                    type={NotificationType.WARNING}
                  />
                )}
            </div>
          </div>
        </>
      )}
    </SimpleLayout>
  );
};
