import { css } from '@emotion/react';
import React from 'react';

import { font } from '../theme';

const sectionTitleStyle = css({
  all: 'unset',
  display: 'block',
  fontSize: font.size.large,
  fontWeight: font.weight.bold,
  position: 'relative',
});

type SectionTitleProps = {
  children: React.ReactNode;
};

export const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => {
  return <h4 css={sectionTitleStyle}>{children}</h4>;
};
