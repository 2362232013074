import { NetworkStatus } from '@apollo/client';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { useTermsOfSalesAcceptanceState } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import React, { useEffect, useRef } from 'react';

const termsOfSalesLogger = logger.createLogger('termsOfSalesCheck');

type BootstrapTermsOfSalesAcceptedCheckProps = {
  termsOfSalesConsentComponent: React.ComponentType;
  children: React.ReactNode;
};
const BootstrapTermsOfSalesAcceptedCheck: React.FC<BootstrapTermsOfSalesAcceptedCheckProps> = ({
  termsOfSalesConsentComponent: TermsOfSalesConsentComponent,
  children,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const { networkStatus, data, error, loading } = useTermsOfSalesAcceptanceState(
    selectedCustomer.id
  );
  const globalLoaderId = useRef<string>();

  useEffect(() => {
    if (loading && !globalLoaderId.current) {
      globalLoaderId.current = GlobalLoadingService.add('get-terms-of-sales');
    }
    if (!loading && globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
      globalLoaderId.current = undefined;
    }
  }, [loading]);

  if (error) {
    termsOfSalesLogger.error(error);
    return <TermsOfSalesConsentComponent />;
  } else if (
    networkStatus === NetworkStatus.ready &&
    data &&
    !data.getTermsOfSalesAcceptanceState
  ) {
    termsOfSalesLogger.info('Terms of sales not accepted, rendering TermsOfSalesPage.');
    return <TermsOfSalesConsentComponent />;
  } else if (networkStatus === NetworkStatus.loading) {
    termsOfSalesLogger.debug('Terms of sales query is loading');
    return null;
  }

  return <>{children}</>;
};

export default BootstrapTermsOfSalesAcceptedCheck;
