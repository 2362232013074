import { css } from '@emotion/react';
import {
  oneColWidth,
  twoColWidth,
  twoColMedia,
  threeColWidth,
  threeColMedia,
  fourColWidth,
  fourColMedia,
} from '../../constants';

export const adjustedWidthStyle = css({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: oneColWidth,
  [`@media (min-width: ${twoColMedia}px)`]: {
    width: twoColWidth,
  },
  [`@media (min-width: ${threeColMedia}px)`]: {
    width: threeColWidth,
  },
  [`@media (min-width: ${fourColMedia}px)`]: {
    width: fourColWidth,
  },
});
