import { cx } from '@emotion/css';
import React, { useEffect, useMemo, useState } from 'react';

import { Container } from '../../ui/Container';
import { baseSpacing } from '../../ui/theme';
import { exhaustiveSwitchCheck } from '../../utils';
import { BulkQuickAddContextProvider } from './BulkQuickAddContext';
import { BulkAddEditableSpreadsheet } from './components/BulkAddEditableSpreadsheet';
import { BulkAddReadOnlySpreadsheet } from './components/BulkAddReadOnlySpreadsheet';
import { DeleteCell } from './components/cells';
import { useBulkQuickAdd } from './hooks/useBulkQuickAdd';
import { deleteTableClassName } from './styles';
import { BulkQuickAddContext, FinalRowItem, IProduct, RowStatusReason } from './types';

type InitialData = Array<{ itemId: number; quantity?: number }>;
type BulkQuickAddProps = {
  products: IProduct[];
  context?: BulkQuickAddContext;
  columnLabels: {
    product: string;
    product_column_info: string;
    quantity: string;
    name: string;
    theme: string;
    filter_availability: string;
    total_pieces: string;
    price_per_piece: string;
    your_price: string;
  };
  availabilityTranslations: {
    available: string;
    low_stock: string;
    expected_back_in_stock: string;
    out_of_stock: string;
    not_available: string;
    bulk_add_product_not_found: string;
    not_in_window: string;
    not_in_promotion: string;
  };
  locale: string;
  onChange?: (items: FinalRowItem[]) => void;
  onRawChange?: (items: Array<{ itemId: number; quantity?: number }>) => void;
  initialData?: InitialData;

  onEditContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;
  contextMenuToolTipLabel: string;
};

export const BulkQuickAdd: React.FC<BulkQuickAddProps> = ({
  products,
  context = BulkQuickAddContext.REPLENISHMENT,
  columnLabels,
  availabilityTranslations,
  locale,
  onChange,
  onRawChange,
  initialData = [],
  onEditContextMenu,
  contextMenuToolTipLabel,
}) => {
  const [mappedInitialData] = useState(() =>
    initialData.map((row) => [
      {
        value: row.itemId,
      },
      { value: row.quantity },
    ])
  );

  const product_not_found_feedback = useMemo(() => {
    switch (context) {
      case BulkQuickAddContext.REPLENISHMENT:
        return availabilityTranslations.bulk_add_product_not_found;
      case BulkQuickAddContext.LAUNCH:
      case BulkQuickAddContext.LAUNCH_MULTIPLE:
        return availabilityTranslations.not_in_window;
      case BulkQuickAddContext.PROMOTION:
        return availabilityTranslations.not_in_promotion;
      default:
        exhaustiveSwitchCheck(context);
    }
  }, [context]);

  const { data, setRawData, removeRow } = useBulkQuickAdd({
    products,
    minimumRows: 50,
    initialData: mappedInitialData,
    product_not_found_feedback,
  });

  useEffect(() => {
    if (onChange) {
      onChange(data.finalRows);
    }

    if (onRawChange) {
      onRawChange(
        data.dataRows.reduce<Array<{ itemId: number; quantity?: number }>>((result, item) => {
          if (!item[0]?.value && !item[1]?.value) {
            return result;
          }

          result.push({
            itemId: item[0]?.value as number,
            quantity: item[1]?.value as number,
          });

          return result;
        }, [])
      );
    }
  }, [onChange, data, onRawChange]);

  return (
    <BulkQuickAddContextProvider
      translations={{ columnLabels, availabilityTranslations }}
      locale={locale}
      rowStatuses={data.statusRows}
      removeRow={removeRow}
    >
      <Container
        flex={{ gap: baseSpacing }}
        padding={{ paddingBottom: baseSpacing * 3 }}
      >
        <BulkAddEditableSpreadsheet
          dataRows={data.dataRows}
          onChange={setRawData}
          onContextMenu={onEditContextMenu}
          contextMenuToolTipLabel={contextMenuToolTipLabel}
        />
        <div css={{ flexGrow: 1 }}>
          <BulkAddReadOnlySpreadsheet data={data.productRows} />
        </div>
        <div>
          <div className={cx(['Spreadsheet', deleteTableClassName])}>
            <table className={'Spreadsheet__table'}>
              <colgroup>
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th className={'Spreadsheet__header'}>&nbsp;</th>
                </tr>
                {data.statusRows.map((rowStatusReason, index) => {
                  // show delete action only if at least one value is set
                  const showDelete =
                    rowStatusReason === RowStatusReason.Valid ||
                    rowStatusReason !== RowStatusReason.None;
                  return (
                    <tr key={`delete-row-${index}`}>
                      <td className={'Spreadsheet__cell'}>
                        {showDelete ? <DeleteCell cell={{ value: index + 1 }} /> : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </BulkQuickAddContextProvider>
  );
};
