import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';

const arrowKeypressNavigation = (e) => {
  if (e.key === KEYCODE_STRING.ARROW_UP || e.key === KEYCODE_STRING.ARROW_DOWN) {
    const navigableArray = document.querySelectorAll('[data-arrow-keypress-navigable-index]');
    const currentIndex = parseInt(e.nativeEvent.target.dataset.arrowKeypressNavigableIndex, 10);
    if (navigableArray.length > 1) {
      if (e.key === KEYCODE_STRING.ARROW_UP && currentIndex > 0) {
        e.preventDefault();
        navigableArray[currentIndex - 1].focus();
      } else if (e.key === KEYCODE_STRING.ARROW_DOWN && currentIndex + 1 < navigableArray.length) {
        e.preventDefault();
        navigableArray[currentIndex + 1].focus();
      }
    }
  }
};

export default arrowKeypressNavigation;
