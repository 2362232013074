import React, { useMemo } from 'react';

import { exhaustiveSwitchCheck } from '../../utils/TypeScriptHelpers';
import construction from './assets/construction.svg';
import { emptyPreviewStyle, kaktusEmptyPreviewStyle, rocketEmptyPreviewStyle } from './styles';

type FeedbackType = 'rocket' | 'kaktus' | 'construction';

type EmptyFeedbackProps = {
  children: React.ReactNode;
  type?: FeedbackType;
};

export const EmptyFeedback: React.FC<EmptyFeedbackProps> = ({ children, type = 'kaktus' }) => {
  const cssStyles = useMemo(() => {
    switch (type) {
      case 'rocket':
        return rocketEmptyPreviewStyle;
      case 'kaktus':
        return kaktusEmptyPreviewStyle;
      case 'construction':
        return emptyPreviewStyle(construction);
      default:
        exhaustiveSwitchCheck(type);
    }
  }, [type]);

  return <div css={cssStyles}>{children}</div>;
};
