import { USERSNAP_SATISFACTION_WIDGET_KEY } from '@lego/b2b-unicorn-bootstrap/constants';
import { CartReferenceCartType } from '@lego/b2b-unicorn-data-access-layer';
import React from 'react';

import { InlineUsersnapWidget } from '../../../shop/components/InlineUsersnapWidget/InlineUsersnapWidget';

type OrderInlineUsersnapWidgetProps = {
  orderNumber: number;
  orderType: CartReferenceCartType;
};
export const OrderInlineUsersnapWidget: React.FC<OrderInlineUsersnapWidgetProps> = ({
  orderNumber,
  orderType,
}) => {
  return (
    <InlineUsersnapWidget
      widgetKey={USERSNAP_SATISFACTION_WIDGET_KEY}
      usersnapOptsCustomParams={{ orderNumber, orderType }}
    />
  );
};
