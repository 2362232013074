import { css } from '@emotion/react';
import React, { useMemo } from 'react';

import { tableRowCellNumberStyle, tableRowCellStyle } from './styles';

type TableCellNumberProps = {
  colName?: string;
  children: React.ReactNode;
  align?: React.CSSProperties['textAlign'];
};

export const TableCellNumber: React.FC<TableCellNumberProps> = ({ children, colName, align }) => {
  const cssStyles = useMemo(
    () => css(tableRowCellNumberStyle, tableRowCellStyle(undefined, align)),
    [align]
  );

  return (
    <td
      data-col={colName}
      css={cssStyles}
    >
      {children}
    </td>
  );
};
