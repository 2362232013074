export enum TagType {
  'BLUE' = 'BLUE',
  'BLUE_INVERTED' = 'BLUE_INVERTED',
  'BLUE_BOLD' = 'BLUE_BOLD',
  'GREEN' = 'GREEN',
  'GREEN_INVERTED' = 'GREEN_INVERTED',
  'GREEN_BOLD' = 'GREEN_BOLD',
  'ORANGE' = 'ORANGE',
  'ORANGE_INVERTED' = 'ORANGE_INVERTED',
  'ORANGE_BOLD' = 'ORANGE_BOLD',
}
