import { css } from '@emotion/react';
import type { Property } from 'csstype';
import { defaults } from 'lodash-es';
import React from 'react';

import { assignDesignToken, ObjectPathLeavesOnly } from '../../utils';
import { baseSpacing, colors, designToken, font, maxDesktopWidth } from '../theme';

type PaddingInput = {
  top?: Property.PaddingTop<string | number>;
  bottom?: Property.PaddingBottom<string | number>;
  left?: Property.PaddingLeft<string | number>;
  right?: Property.PaddingRight<string | number>;
};

const paddingDefault = { top: baseSpacing * 2, bottom: baseSpacing * 2 };

const cardStyle = (
  centered?: boolean,
  borderType?: 'solid' | 'dashed' | 'dotted',
  borderColor: ObjectPathLeavesOnly<typeof designToken> = 'text.secondary',
  backgroundColor: ObjectPathLeavesOnly<typeof designToken> = 'background.offWhite',
  padding: PaddingInput = paddingDefault,
  boxShadow?: keyof typeof colors.klik,
  height?: React.CSSProperties['height']
) => {
  const paddingResult = defaults(padding, paddingDefault);

  return css({
    paddingTop: paddingResult.top,
    paddingBottom: paddingResult.bottom,
    paddingLeft: paddingResult.left,
    paddingRight: paddingResult.right,
    marginLeft: centered ? 'auto' : undefined,
    marginRight: centered ? 'auto' : undefined,
    height,
    maxWidth: maxDesktopWidth,
    color: designToken.text.default,
    fontSize: font.size.small,
    backgroundColor: assignDesignToken(backgroundColor),
    borderRadius: baseSpacing,
    border: borderType ? `1px ${borderType} ${assignDesignToken(borderColor)}` : undefined,
    boxShadow: boxShadow ? `${colors.klik[boxShadow]} 0 0 4px 0` : undefined,
    transition: `all 0.2s ease 0s`,
  });
};

type CardProps = {
  children: React.ReactNode;
  centered?: boolean;
  borderType?: 'solid' | 'dashed' | 'dotted';
  borderColor?: ObjectPathLeavesOnly<typeof designToken>;
  backgroundColor?: ObjectPathLeavesOnly<typeof designToken>;
  boxShadow?: keyof typeof colors.klik;
  padding?: PaddingInput;
  height?: React.CSSProperties['height'];
};

export const Card: React.FC<CardProps> = ({
  centered = false,
  children,
  borderType,
  padding,
  borderColor,
  backgroundColor,
  boxShadow,
  height,
}) => {
  return (
    <div
      css={cardStyle(
        centered,
        borderType,
        borderColor,
        backgroundColor,
        padding,
        boxShadow,
        height
      )}
    >
      {children}
    </div>
  );
};
