import classNames from 'classnames';
import * as React from 'react';
import type { ColumnIndicatorProps } from 'react-spreadsheet';

export const BulkQuickAddColumnIndicator: React.FC<ColumnIndicatorProps> = ({ label }) => {
  return (
    <th
      className={classNames('Spreadsheet__header')}
      tabIndex={-1}
    >
      {label}
    </th>
  );
};
