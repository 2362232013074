import React from 'react';

import { Icon, IconType } from '../../../../ui/Icon';
import { ToolTip } from '../../../../ui/ToolTip';
import { useBulkQuickAddContext } from '../../BulkQuickAddContext';

export const ProductColumnHeaderCell = (): React.ReactNode => {
  const {
    translations: {
      columnLabels: { product, product_column_info },
    },
  } = useBulkQuickAddContext();

  return (
    <>
      <span>{product}</span>
      <ToolTip message={product_column_info}>
        <Icon type={IconType.INFO_OUTLINE} />
      </ToolTip>
    </>
  );
};
