import {
  CartReferenceCartType,
  PromotionCartReferenceInput,
} from '@lego/b2b-unicorn-data-access-layer';
import { useDataAccessLayer, usePromotion } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useEffect, useMemo, useState } from 'react';

import { ProductConditionResult, PromotionDetailsModel } from '../model/PromotionDetailsModel';

export const usePromotionDetails = (promotionId: number) => {
  const selectedCustomer = useSelectedCustomer();
  const dataAccessLayerClient = useDataAccessLayer();

  // Get model instance
  const [promotionDetailsModel] = useState(() =>
    PromotionDetailsModel.instance(dataAccessLayerClient, selectedCustomer.id, promotionId)
  );

  // States
  const [promotionData, setPromotionData] = useState<ReturnType<typeof usePromotion>['data']>();
  const [productConditionsResults, setProductConditionsResults] = useState<
    ProductConditionResult[]
  >([]);
  const [checkoutPossible, setCheckoutPossible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<ReturnType<typeof usePromotion>['error']>();
  const [
    initialCartDoesNotFulfillProductConditions,
    setInitialCartDoesNotFulfillProductConditions,
  ] = useState<boolean>(false);

  // Subscribe
  useEffect(() => {
    const resultSubscription = promotionDetailsModel.result.subscribe((result) => {
      setPromotionData(result.promotion);
      setProductConditionsResults(result.productConditionsResults);
      setCheckoutPossible(result.checkoutPossible);
      setIsLoading(result.isLoading);
      setError(result.error);
      setInitialCartDoesNotFulfillProductConditions(
        result.initialCartDoesNotFulfillProductConditions
      );
    });

    return () => {
      resultSubscription.unsubscribe();
      promotionDetailsModel.dispose();
    };
  }, [promotionDetailsModel]);

  const productConditions = useMemo(() => {
    if (!promotionData) {
      return [];
    }
    return promotionData.promotion.productConditions;
  }, [promotionData]);

  const cartReference = useMemo<PromotionCartReferenceInput>(() => {
    return {
      cartType: CartReferenceCartType.Promotion,
      promotion: { promotionId },
    };
  }, [promotionId]);

  return useMemo(
    () => ({
      promotionId,
      promotionDetailsModel,
      promotionData,
      productConditionsResults,
      checkoutPossible,
      isLoading,
      productConditions,
      cartReference,
      error,
      initialCartDoesNotFulfillProductConditions,
    }),
    [
      promotionId,
      promotionDetailsModel,
      promotionData,
      productConditionsResults,
      checkoutPossible,
      isLoading,
      productConditions,
      cartReference,
      error,
      initialCartDoesNotFulfillProductConditions,
    ]
  );
};
