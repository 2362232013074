import { css } from '@emotion/react';
import { mobileCellWithColDataStyle, tableRowCellStyle } from '@lego/b2b-unicorn-ui-checkout-flow';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';

export const tableRowOrderStyle = css({
  'td:nth-of-type(n+3):nth-last-of-type(n+2)': {
    minHeight: 25,
    paddingLeft: `calc(30% + ${baseSpacing * 2}px)`,
    [media.medium]: {
      paddingLeft: baseSpacing,
    },
  },
  '> td:nth-of-type(1)': {
    order: 1,
  },
  '> td:nth-of-type(2)': {
    order: 2,
  },
  '> td:nth-of-type(3)': {
    order: 3,
  },
  '> td:nth-of-type(4)': {
    order: 9,
    paddingTop: baseSpacing * 2,
    [media.medium]: {
      padding: 0,
    },
    '&:after': {
      display: 'none !important',
    },
  },
  '> td:nth-of-type(5)': {
    order: 8,
  },
  '> td:nth-of-type(6)': {
    order: 4,
  },
  '> td:nth-of-type(7)': {
    order: 5,
  },
  '> td:nth-of-type(8)': {
    order: 6,
  },
  '> td:last-of-type': {
    order: 7,
  },
});

export const cartTableRowCellStyle = css(tableRowCellStyle, {
  ':nth-of-type(n+3):nth-last-of-type(n+2)': {
    ...mobileCellWithColDataStyle,
    [media.medium]: {
      paddingLeft: baseSpacing,
    },
  },
});

export const tableRowCellCenterStyle = css(cartTableRowCellStyle, {
  [media.medium]: {
    textAlign: 'center',
  },
});

export const counterStyle = css({
  width: 146,
  marginRight: 0,
});

export const removeCellStyle = css(tableRowCellCenterStyle, {
  position: 'absolute',
  top: baseSpacing * 2,
  right: baseSpacing,
  [media.medium]: {
    position: 'static',
    '> button': {
      margin: 'auto',
    },
    svg: {
      height: 18,
      width: 18,
    },
  },
});
