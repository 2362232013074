import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { colors, media } from '@lego/b2b-unicorn-ui-constants';
import { Fragment } from 'react';

import sanitizeItemNumber from '../../utils/sanitizeItemNumber';
import { formCommonInputStyle } from './common/quickAddStyles';

const searchInputStyle = [
  formCommonInputStyle,
  css({
    borderRadius: 4,
    boxShadow: 'inset 0px 2px 0px rgba(0, 0, 0, 0.1)',
    paddingRight: 70,
    flexGrow: 1,
    zIndex: 549,
    backgroundColor: designToken.background.blue,
    '&::placeholder': {
      color: colors.klik.slate500,
    },
    [media.medium]: {
      order: -20,
      borderRadius: '4px 0 0 4px',
    },
  }),
];

const resetFormButtonStyle = css({
  position: 'absolute',
  top: 0,
  right: 0,
  lineHeight: 0,
  border: 'none',
  background: designToken.transparent.default,
  padding: 10,
  order: -10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none',
  zIndex: 10,
  cursor: 'pointer',
  [media.medium]: {
    padding: 18,
  },
  svg: {
    fill: colors.klik.slate900,
    width: 20,
    height: 20,
    transitionProperty: 'fill',
    transitionDuration: '150ms',
  },
  '&:hover, &:focus': {
    svg: {
      fill: colors.klik.lightBlue400,
    },
  },
});

interface IQuickAddSearchInput {
  query: string;
  setQuery: (value: string) => void;
  isFocusable: boolean;
  onKeyDownHandler: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlurHandler: () => void;
  searchInputRef?: React.MutableRefObject<HTMLInputElement | null>;
  resetForm: () => void;
}

const QuickAddSearchInput = ({
  query,
  setQuery,
  isFocusable,
  onKeyDownHandler,
  onBlurHandler,
  searchInputRef,
  resetForm,
}: IQuickAddSearchInput) => {
  const { quick_order_input_placeholder } = useLabels();

  const resetFormOnClearKeydown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      resetForm();
    }
  };

  return (
    <Fragment>
      <input
        data-arrow-keypress-navigable-index="0"
        css={searchInputStyle}
        onBlur={onBlurHandler}
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        value={query}
        ref={searchInputRef}
        tabIndex={isFocusable ? -1 : 0}
        placeholder={quick_order_input_placeholder}
        aria-label="Search products by item number."
        aria-describedby="quickAddSearchFeedback"
        onKeyDown={onKeyDownHandler}
        onChange={(e) => setQuery(sanitizeItemNumber(e.target.value))}
      />
      {query && (
        <button
          css={resetFormButtonStyle}
          onClick={resetForm}
          onKeyDown={resetFormOnClearKeydown}
          aria-label="Clear search input."
        >
          <Icon type={IconType.CLEAR} />
        </button>
      )}
    </Fragment>
  );
};

export default QuickAddSearchInput;
