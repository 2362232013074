import { cloneDeep } from '@apollo/client/utilities';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  BodyWrapper,
  Card,
  Container,
  EmptyFeedback,
  GoBackLink,
  Paragraph,
} from '@lego/b2b-unicorn-shared/ui';
import { OrderPreview } from '@lego/b2b-unicorn-ui-checkout-flow';
import { baseSpacing, ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';
import { mapToMoney, sortAscending, sortDescending } from '@lego/b2b-unicorn-ui-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PromotionDetailsHeader, PromotionTableHeader, PromotionTableRow } from './components';
import { PromotionBulkAddAction } from './components/PromotionBulkAddAction';
import { usePromotionDetails } from './hooks/usePromotionDetails';

type PromotionDetailsProps = {
  promotionDetails: ReturnType<typeof usePromotionDetails>;
};

export const PromotionDetails: React.FC<PromotionDetailsProps> = ({ promotionDetails }) => {
  const {
    launch_window_remove_all_header,
    launch_window_remove_all_content,
    promotion_reset_header,
    promotion_reset_content,
    launch_window_go_back,
    promotion_no_products,
    promotion_conditions_change_warning,
  } = useLabels();

  const {
    promotionId,
    productConditionsResults,
    productConditions,
    promotionDetailsModel,
    promotionData,
    checkoutPossible,
    cartReference,
    isLoading,
    error: promotionError,
    initialCartDoesNotFulfillProductConditions: hasProductConditionsChanged,
  } = promotionDetails;
  const history = useHistory();

  const [activeSortingField, setActiveSortingField] = useState<ProductTableColumnType>(
    ProductTableColumnType.ITEM_ID
  );
  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(false);

  const items = useMemo(() => {
    if (!promotionData) {
      return [];
    }

    const clonedItems = cloneDeep(promotionData.promotion.products).map((product) => {
      const itemInCart = promotionData.getCart.items.find((item) => {
        if (!item.product) {
          return false;
        }

        return item.product.materialId === product.materialId;
      });

      return {
        product,
        quantity: itemInCart?.quantity || 0,
      };
    });

    return isAscending
      ? sortAscending(clonedItems, activeSortingField)
      : sortDescending(clonedItems, activeSortingField);
  }, [activeSortingField, promotionData, isAscending]);

  useEffect(() => {
    setIsCheckoutDisabled(
      !promotionData || promotionData.getCart.items.length === 0 || !checkoutPossible
    );
  }, [promotionData, checkoutPossible]);

  const handleGoToCheckoutClick = useCallback(() => {
    history.push(`/promotion/${promotionId}/checkout`, { promotionId });
  }, [history, promotionId]);

  const handleOnReset = useCallback(() => {
    promotionDetailsModel.resetCart().catch(() => {
      const refetch = promotionDetailsModel.refetch;
      if (refetch) {
        refetch();
      }
    });
  }, [promotionDetailsModel]);

  const handleMultipleItemsUploadError = useCallback(() => {
    if (isLoading) {
      return;
    }

    const refetch = promotionDetailsModel.refetch;
    if (refetch) {
      refetch();
    }
  }, [isLoading, promotionDetailsModel]);

  const minimumOrderValue = promotionData?.getCart.minimumOrderValue || mapToMoney(0, '');
  const isPromotionAvailableForOrder = promotionData
    ? promotionData.promotion.availableForOrder
    : true;
  const hasProductConditions = productConditions ? productConditions?.length > 0 : false;

  return (
    <BodyWrapper>
      <PromotionDetailsHeader
        promotionId={promotionId}
        backToOverviewButton
        warningMessage={
          hasProductConditionsChanged ? promotion_conditions_change_warning : undefined
        }
      />
      <Container padding={{ paddingBottom: baseSpacing * 15 }}>
        <Card>
          <OrderPreview
            readonly={!isPromotionAvailableForOrder}
            items={items}
            itemRowHeaderRender={
              <PromotionTableHeader
                activeSortingField={activeSortingField}
                setActiveSortingField={setActiveSortingField}
                isAscending={isAscending}
                inactive={!isPromotionAvailableForOrder}
                setIsAscending={setIsAscending}
              />
            }
            itemRowRender={(item) => (
              <PromotionTableRow
                key={`${item.product.materialId}`}
                quantity={item.quantity}
                product={item.product}
                productConditions={productConditions?.find(
                  (condition) => condition?.materialId === item.product.materialId
                )}
                updateCart={promotionDetails.promotionDetailsModel.updateCart.bind(
                  promotionDetails.promotionDetailsModel
                )}
                inactive={!isPromotionAvailableForOrder}
                onError={handleMultipleItemsUploadError}
                isInvalid={
                  productConditionsResults.find(
                    (result) => result.materialId === item.product.materialId
                  )?.valid === false
                }
              />
            )}
            itemsLoading={isLoading}
            orderPreviewError={!!promotionError}
            isCheckoutDisabled={isCheckoutDisabled}
            onGoToCheckoutClick={handleGoToCheckoutClick}
            minimumOrderValue={minimumOrderValue}
            onResetAction={handleOnReset}
            resetWarningText={
              hasProductConditions
                ? {
                    header: promotion_reset_header,
                    content: promotion_reset_content,
                  }
                : {
                    header: launch_window_remove_all_header,
                    content: launch_window_remove_all_content,
                  }
            }
            resetActionType={hasProductConditions ? 'RESET' : 'REMOVE_ALL'}
            emptyPreviewFeedbackRender={
              <EmptyFeedback type={'construction'}>
                <Paragraph>{promotion_no_products}</Paragraph>
                <GoBackLink
                  onClick={() => history.push(`/promotion`)}
                  text={launch_window_go_back}
                />
              </EmptyFeedback>
            }
            actionsRender={
              isPromotionAvailableForOrder && (
                <PromotionBulkAddAction
                  promotionProducts={promotionData?.promotion.products || []}
                  cartReference={cartReference}
                  disabled={isLoading}
                  onError={handleMultipleItemsUploadError}
                />
              )
            }
          />
        </Card>
      </Container>
    </BodyWrapper>
  );
};
